import React, { Component, createRef } from 'react'
import {
    Button,
    Message,
    Form,
    Select,
    Checkbox,
    Step,
    Table,
    Progress,
    Grid,
    TextArea,
    Ref
} from 'semantic-ui-react'
import { getErrorMessage } from './../util/errors'
import TemperatureUnit from "./forms/checkboxes/TemperatureUnit";
import WindSpeedUnit from "./forms/checkboxes/WindSpeedUnit";
import ShowBimModelType from './forms/checkboxes/ShowBimModelType';
import PropTypes from 'prop-types'
import UserProjectsAndCamerasSelector from "./UserProjectsAndCamerasSelector";
import '../styles/BulkCreateUser.css'

class CreateBulkUsers extends Component {

    constructor(props) {
        super(props)
        this.emailFieldRef = createRef()
        // Let's move the template into the state...
        this.state = Object.assign(
            {},
            props.template,
            {
                currentStep: 1,
                defaultClient: null
            }
        )
    }

    static getDerivedStateFromProps(props, state) {
        if (props.auth && props.auth.user && props.clientId !== state.clients_id && (props.auth.user.roles_id === 4 || props.auth.user.roles_id === 5)) {
            return { clients_id: props.clientId }
        }

        return null
     }

    // componentWillUpdate(nextProps, nextState) {
    //     this.additionalConfig(nextProps, nextState)
    // }

    componentDidUpdate(prevProps, prevState) {
        if (this.props !== prevProps) {
            this.additionalConfig(this.props, this.state)
        }
    }

    componentDidMount() {
        this.props.adminUserRolesRequest()
        // this.props.adminUserStatusesRequest()
        this.props.adminUserClientsRequest()
    }

    getSteps = (status1, status2, status3) => {
        // Probably a better way to do this
        let active1 = status1 === 'active' ? true: false
        let completed1 = status1 === 'completed' ? true: false
        let active2 = status2 === 'active' ? true: false
        let completed2 = status2 === 'completed' ? true: false
        let active3 = status3 === 'active' ? true: false
        let completed3 = status3 === 'completed' ? true: false

        return (
            <Step.Group ordered>
                <Step active={active1} completed={completed1}>
                    <Step.Content>
                        <Step.Title>User Settings</Step.Title>
                        <Step.Description>Decide the settings for the users you wish to create</Step.Description>
                    </Step.Content>
                </Step>
                <Step active={active2} completed={completed2}>
                    <Step.Content>
                        <Step.Title>Assign Projects and Cameras</Step.Title>
                        <Step.Description>Restrict the user to certain cameras</Step.Description>
                    </Step.Content>
                </Step>
                <Step active={active3} completed={completed3}>
                    <Step.Content>
                        <Step.Title>User Details</Step.Title>
                        <Step.Description>Enter email addresses for each new user</Step.Description>
                    </Step.Content>
                </Step>
            </Step.Group>
        )
    }

    goToStep = (step) => {
        if (step === 2 && this.props.auth.user && (this.props.auth.user.roles_id === 4 || this.props.auth.user.roles_id === 5)) {
            this.props.onClientChange(this.state.clients_id)
        }
        this.setState({currentStep: step})
    }

    additionalConfig = (nextProps, nextState) => {
        // We need to ensure that the clientId and rolesId are set...
        // if (!this.state.clients_id && nextProps.clientId) {
        //     this.setState((prevState, props) => {
        //         return {
        //             ...prevState,
        //             clients_id: nextProps.clientId

        //         }
        //     }, () => {
        //         this.onUpdateTemplate()
        //     })
        // }
        // // Fixing invalid clientId persisted
        // if (nextProps.clientId && nextProps.clientId !== this.state.clients_id) {
        //     this.setState((prevState, props) => {
        //         return {
        //             ...prevState,
        //             clients_id: nextProps.clientId
        //         }
        //     }, () => {
        //         this.onUpdateTemplate()
        //     })
        // }
    }

    onUsernameChange = (event) => {
        const val = event.target.value
        let separatedArray = val.split(',');
        this.setState((prevState, props) => {
            return {
                ...prevState,
                username: separatedArray
            }
        })
    }

    onUsernameSubmit = (event) => {
        const val = this.emailFieldRef.current.value
        let separatedArray = val.split(',');
        
        //this.props.onAddingEmailToBulkUsersList(this.props.validateEmailAsUsername.email.trim())

        // this.setState((prevState, props) => {
        //     return {
        //         ...prevState,
        //         username: val
        //     }
        // })
         separatedArray.forEach(element => {
             if (element) {
                this.props.onUsernameChange(element.trim())
             }
        });

        this.emailFieldRef.current.value = ''
    }

    onRoleChange = (event, value) => {
        const val = value.value
        this.setState((prevState, props) => {
            return {
                ...prevState,
                roles_id: val
            }
        }, () => {
            this.onUpdateTemplate()
        })
    }

    onClientChange = (event, value) => {
        const val = value.value
        this.setState((prevState, props) => {
            return {
                ...prevState,
                clients_id: val
            }
        }, () => {
            this.onUpdateTemplate()
            // Added to support ucpa
            this.props.onClientChange(val)
        })
    }

    onStatusChange = (event, value) => {
        const val = value.value
        this.setState((prevState, props) => {
            return {
                ...prevState,
                status: val
            }
        }, () => {
            this.onUpdateTemplate()
        })
    }

    onWeatherSettingsChange = (event, {checked}) => {
        this.setState((prevState, props) => {
            return {
                ...prevState,
                settings: {
                    ...prevState.settings,
                    weather: {
                        ...prevState.settings.weather,
                        enabled: checked
                    }
                }
            }
        }, () => {
            this.onUpdateTemplate()
        })
    }

    onTemperatureUnitChange = (event, value) => {
        this.setState((prevState, props) => {
            return {
                ...prevState,
                settings: {
                    ...prevState.settings,
                    weather: {
                        ...prevState.settings.weather,
                        temperature_unit: value
                    }
                }
            }
        }, () => {
            this.onUpdateTemplate()
        })
    }

    onWindSpeedUnitChange = (event, value) => {
        this.setState((prevState, props) => {
            return {
                ...prevState,
                settings: {
                    ...prevState.settings,
                    weather: {
                        ...prevState.settings.weather,
                        wind_speed_unit: value
                    }
                }
            }
        }, () => {
            this.onUpdateTemplate()
        })
    }

    onAutoRefreshPermissionChange = (event, {checked}) => {
        this.setState((prevState, props) => {
            return {
                ...prevState,
                settings: {
                    ...prevState.settings,
                    permitted_to_auto_refresh: checked
                }
            }
        }, () => {
            this.onUpdateTemplate()
        })
    }

    onHideVirtualCamerasChange = (event, {checked}) => {
        this.setState((prevState, props) => {
            return {
                ...prevState,
                settings: {
                    ...prevState.settings,
                    hide_virtual_cameras: checked
                }
            }
        }, () => {
            this.onUpdateTemplate()
        })
    }

    onBimContractDeliveryChange = (event, value) => {
        this.setState((prevState, props) => {
            return {
                ...prevState,
                settings: {
                    ...prevState.settings,
                    show_bim_model_type: value
                }
            }
        }, () => {
            this.onUpdateTemplate()
        })
    }

    onProcessedChange = (event, {checked}) => {
        this.setState((prevState, props) => {
            return {
                ...prevState,
                processed: checked
            }
        }, () => {
            this.onUpdateTemplate()
        })
    }

    onAllowSavingPermissionChange = (event, {checked}) => {
        this.setState((prevState, props) => {
            return {
                ...prevState,
                settings: {
                    ...prevState.settings,
                    permitted_to_save: checked
                }
            }
        }, () => {
            this.onUpdateTemplate()
        })
    }

    onAllowZoomingPermissionChange = (event, {checked}) => {
        this.setState((prevState, props) => {
            return {
                ...prevState,
                settings: {
                    ...prevState.settings,
                    permitted_to_zoom: checked
                }
            }
        }, () => {
            this.onUpdateTemplate()
        })
    }

    onShowCalendarPermissionChange = (event, {checked}) => {
        this.setState((prevState, props) => {
            return {
                ...prevState,
                settings: {
                    ...prevState.settings,
                    permitted_to_view_calendar: checked
                }
            }
        }, () => {
            this.onUpdateTemplate()
        })
    }

    onShowNavigationPermissionChange = (event, value) => {
        const val = (value.checked) ? "Yes" : "No"
        this.setState((prevState, props) => {
            return {
                ...prevState,
                settings: {
                    ...prevState.settings,
                    show_navigation_permission: val
                }
            }
        }, () => {
            this.onUpdateTemplate()
        })
    }

    onShowThumbnailsPermissionChange = (event, value) => {
        const val = (value.checked) ? "Yes" : "No"
        this.setState((prevState, props) => {
            return {
                ...prevState,
                settings: {
                    ...prevState.settings,
                    show_thumbnails_permission: val
                }
            }
        }, () => {
            this.onUpdateTemplate()
        })
    }

    onViewTimeLapsePermissionChange = (event, {checked}) => {
        this.setState((prevState, props) => {
            return {
                ...prevState,
                settings: {
                    ...prevState.settings,
                    permitted_to_view_timelapse: checked
                }
            }
        }, () => {
            this.onUpdateTemplate()
        })
    }

    onAllowVideoEditingPermissionChange = (event, {checked}) => {
        this.setState((prevState, props) => {
            return {
                ...prevState,
                video_editor: checked
            }
        }, () => {
            this.onUpdateTemplate()
        })
    }

    onApiChange = (event, {checked}) => {
        this.setState((prevState, props) => {
            return {
                ...prevState,
                api: checked
            }
        }, () => {
            this.onUpdateTemplate()
        })
    }

    onCommentChange = (event) => {
        const val = event.target.value
        this.setState((prevState, props) => {
            return {
                ...prevState,
                comment: val
            }
        }, () => {
            this.onUpdateTemplate()
        })
    }

    onLockdownChange = (event, value) => {
        const val = (value.checked) ? "ON" : "OFF"
        this.setState((prevState, props) => {
            return {
                ...prevState,
                lockdown: val
            }
        }, () => {
           // this.onUpdateTemplate()

           this.props.onUpdateTemplate(this.state)
        })
    }

    onDelayChange = (event) => {
        const val = event.target.value
        this.setState((prevState, props) => {
            return {
                ...prevState,
                delay: val
            }
        }, () => {
            this.onUpdateTemplate()
        })
    }

    onLimitHistoryChange = (event) => {
        const val = event.target.value
        this.setState((prevState, props) => {
            return {
                ...prevState,
                limit_history: val
            }
        }, () => {
            this.onUpdateTemplate()
        })
    }

    resetAdditionalConfig = () => {
        this.setState((prevState, props) => {
            return {
                ...prevState,
                clients_id: "",
                roles_id: ""
            }
        })
    }

    onUpdateTemplate = () => {
        this.props.onUpdateTemplate(this.state)
    }

    onCreateAccountsClick = () => {
        this.props.onClick(this.state)
    }

    getCreateAccountsButton = () => {
        return (this.isCompleted()) ? null :
            <Button
                disabled={this.props.emails.length === 0}
                onClick={() => this.onCreateAccountsClick()}
                loading={this.props.processing} primary>
                {(this.props.emails.length === 1)? "Create account" :"Create accounts"}
            </Button>

    }

    getCloseButton = (label = 'Close') => {
        return <Button
            onClick={() => {
                this.props.onClose()
            }}>{label}</Button>
    }

    getCreateAccountsRows = () => {
        return (this.props.emails.map((row, k) => {
            const success = row.status === 'Success'
            const warning = row.status === 'Warning'
            const error = row.status === 'Error'

            return (
                <Table.Row key={k}>
                    <Table.Cell>
                        {row.email}
                    </Table.Cell>
                    <Table.Cell>
                        <Progress percent={row.progress} success={success} warning={warning} error={error}>{row.status}</Progress>
                    </Table.Cell>
                    <Table.Cell>
                        {(!this.props.processing && !this.props.completed) ? <Button style={{float: "right"}} onClick={ () => { this.props.onRemovingEmailToBulkUsersList(row.email) }} icon="trash" />: ""}

                        {(row.progress === 100) ? this.getErrorsAsString(row.errors) : ""}

                    </Table.Cell>
                </Table.Row>
            )

        }))
    }

    getCreateErrorRows = () => {
        return (this.props.errorEmails.map((row, k) => {

            return (
                <Table.Row key={k}>
                    <Table.Cell>
                        {row.email}
                    </Table.Cell>
                    <Table.Cell>
                        {Object.values(row.error.email)[0]}
                    </Table.Cell>
                </Table.Row>
            )

        }))
    }

    getCreateAccounts = () => {

        return (<div>
                {this.getCreateAccountsButton()}
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Email</Table.HeaderCell>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                            {(this.props.processing || this.props.completed) ? <Table.HeaderCell>Details</Table.HeaderCell> : <Table.HeaderCell></Table.HeaderCell>}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.getCreateAccountsRows()}
                    </Table.Body>
                </Table>

            </div>
        )
    }

    getErrorEmails = () => {
        return (<div>
            <p>Errors</p>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Email</Table.HeaderCell>
                            <Table.HeaderCell>Error</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.getCreateErrorRows()}
                    </Table.Body>
                </Table>

            </div>
        )
    }

    getErrorsAsString = (errors) => {
        let message = ""

        if (!errors) {
            return message
        }

        const keys = Object.keys(errors)
        message += keys.map((e) => {
            return   e + " : " + getErrorMessage(errors, e)

        })
        return message
    }

    getSettings = () => {
        let isLobsterUser = false
        if (this.props.auth && this.props.auth.user && (this.props.auth.user.roles_id !== 4 && this.props.auth.user.roles_id !== 5)) {
            isLobsterUser = true;
        }

        return (
            <Form size='large'>
                <h3>Settings to apply to each user</h3>
                <Grid columns='2'>
                    <Grid.Row>
                        <Grid.Column>
                            <div>
                                <Form.Select
                                    disabled={this.isCompleted()}
                                    options={this.props.clients}
                                    placeholder='Client'
                                    label='Client'
                                    onChange={this.onClientChange}
                                    error={(this.props.errors.clients_id) ? true : false}
                                    defaultValue={(!isLobsterUser) ? this.state.clients_id : ''}
                                    search
                                />
                                < Message
                                    error={true}
                                    visible={(this.props.errors.clients_id) ? true : false}
                                    header={'Error'}
                                    content={getErrorMessage(this.props.errors, 'clients_id')}
                                />
                            </div>
                            {isLobsterUser ?
                                <>
                                    <Form.Field
                                        disabled={this.isCompleted()}
                                        control={Select}
                                        options={this.props.roles}
                                        placeholder='Role'
                                        label='Role'
                                        onChange={this.onRoleChange}
                                        error={(this.props.errors.roles_id) ? true : false}
                                        // Next line is a fudge. Sorry
                                        defaultValue={3}
                                    />

                                    <Message
                                        error={true}
                                        visible={(this.props.errors.roles_id) ? true : false}
                                        header={'Error'}
                                        content={getErrorMessage(this.props.errors, 'roles_id')}
                                    />
                                </> : null
                            }

                            <Form.TextArea
                                disabled={this.isCompleted()}
                                label = 'Comments'
                                placeholder='Comments here'
                                onChange={this.onCommentChange}
                                error={(this.props.errors.comment) ? true : false}
                            />
                            {isLobsterUser ?
                                <>
                                    <div className="form-group">
                                        <h3>Lockdown</h3>
                                        <p>If the account is in lockdown mode, the user will not be able to log in.</p>
                                        <Checkbox
                                            disabled={this.isCompleted()}
                                            label={this.props.template.lockdown === 'ON' ? 'Enabled' : 'Disabled'}
                                            defaultChecked={this.props.template.lockdown === 'ON'}
                                            onChange={this.onLockdownChange}
                                            data-testid='bulk-create-user--lockdown'
                                            toggle />
                                    </div>
                                </> : null
                            }
                        </Grid.Column>
                        <Grid.Column>
                            <h3>Overrides</h3>
                            <p>Time delay (in hours)</p>
                            <Form.Input
                                disabled={this.isCompleted()}
                                type="number"
                                placeholder='Time delay'
                                onChange={this.onDelayChange}
                                error={(this.props.errors.delay) ? true : false}
                                defaultValue={this.props.template.delay}
                                min="0"
                            />
                            <Message
                                error={true}
                                visible={(this.props.errors.delay) ? true : false}
                                header={'Error'}
                                content={getErrorMessage(this.props.errors, 'delay')}
                            />

                            <p>Limit access to historical images (in days)</p>
                            <Form.Input
                                disabled={this.isCompleted()}
                                type="number"
                                placeholder='Limit access (in days)'
                                onChange={this.onLimitHistoryChange}
                                error={(this.props.errors.limit_history) ? true : false}
                                defaultValue={this.props.template.limit_history}
                                min="0"
                            />
                            <Message
                                error={true}
                                visible={(this.props.errors.limit_history) ? true : false}
                                header={'Error'}
                                content={getErrorMessage(this.props.errors, 'limit_history')}
                            />
                            {isLobsterUser ?
                                <>
                                    <h3>Processed Images</h3>

                                    <p>Processed</p>
                                    <Checkbox
                                        disabled={this.isCompleted()}
                                        label={this.props.template.processed ? 'Enabled' : 'Disabled'}
                                        defaultChecked={this.props.template.processed}
                                        onChange={this.onProcessedChange}
                                        toggle />

                                    <h3>Weather</h3>
                                    <Checkbox
                                        disabled={this.isCompleted()}
                                        label={this.props.template.settings.weather.enabled ? 'Enabled (Click to disable weather settings)' : 'Disabled (Click to enable weather settings)'}
                                        defaultChecked={this.props.template.settings.weather.enabled}
                                        onChange={this.onWeatherSettingsChange}
                                        toggle />
                                    <p></p><p></p>
                                    <Grid columns='2'>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <TemperatureUnit
                                                    defaultChecked={this.props.template.settings.weather.temperature_unit}
                                                    disabled={this.isCompleted() || !this.props.template.settings.weather.enabled}
                                                    onChange={this.onTemperatureUnitChange}
                                                />
                                            </Grid.Column>
                                            <Grid.Column>
                                                <WindSpeedUnit
                                                    defaultChecked={this.props.template.settings.weather.wind_speed_unit}
                                                    disabled={this.isCompleted() || !this.props.template.settings.weather.enabled}
                                                    onChange={this.onWindSpeedUnitChange}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </> : null
                            }

                            <h3>Permissions</h3>
                            <Grid columns='2'>
                                <Grid.Row>
                                    <Grid.Column>
                                        {isLobsterUser ?
                                            <>
                                                <p className="permissions__title">Show Navigation</p>
                                                <Checkbox
                                                    disabled={this.isCompleted()}
                                                    label={this.state.settings.show_navigation_permission === 'Yes' ? 'Enabled' : 'Disabled'}
                                                    defaultChecked={this.state.settings.show_navigation_permission === 'Yes'}
                                                    onChange={this.onShowNavigationPermissionChange}
                                                    toggle />

                                                <p className="permissions__title">Show Calendar</p>
                                                <Checkbox
                                                    disabled={this.isCompleted()}
                                                    label={this.props.template.settings.permitted_to_view_calendar === true ? 'Enabled' : 'Disabled'}
                                                    defaultChecked={this.props.template.settings.permitted_to_view_calendar === true}
                                                    onChange={this.onShowCalendarPermissionChange}
                                                    toggle />
                                            </> : null
                                        }

                                        <p className="permissions__title">Allow Saving</p>
                                        <Checkbox
                                            disabled={this.isCompleted()}
                                            label={this.props.template.settings.permitted_to_save ? 'Enabled' : 'Disabled'}
                                            defaultChecked={this.props.template.settings.permitted_to_save}
                                            onChange={this.onAllowSavingPermissionChange}
                                            toggle />
                            
                                        {isLobsterUser ?
                                            <>
                                                <p className="permissions__title">Allow Zooming</p>
                                                <Checkbox
                                                    disabled={this.isCompleted()}
                                                    label={this.props.template.settings.permitted_to_zoom ? 'Enabled' : 'Disabled'}
                                                    defaultChecked={this.props.template.settings.permitted_to_zoom}
                                                    onChange={this.onAllowZoomingPermissionChange}
                                                    toggle />

                                                <p className="permissions__title">Show Thumbnails</p>
                                                <Checkbox
                                                    disabled={this.isCompleted()}
                                                    label={this.props.template.settings.show_thumbnails_permission === 'Yes' ? 'Enabled' : 'Disabled'}
                                                    defaultChecked={this.props.template.settings.show_thumbnails_permission === 'Yes'}
                                                    onChange={this.onShowThumbnailsPermissionChange}
                                                    toggle />
                                            </> : null
                                        }
                                    </Grid.Column>
                                    <Grid.Column>
                                        {isLobsterUser ?
                                            <>
                                                <p className="permissions__title">Enable Auto Refresh</p>
                                                <Checkbox
                                                    disabled={this.isCompleted()}
                                                    label={this.props.template.settings.permitted_to_auto_refresh ? 'Enabled' : 'Disabled'}
                                                    defaultChecked={this.props.template.settings.permitted_to_auto_refresh}
                                                    onChange={this.onAutoRefreshPermissionChange}
                                                    toggle />

                                                <p className="permissions__title">View Time Lapse</p>
                                                <Checkbox
                                                    disabled={this.isCompleted()}
                                                    label={this.props.template.settings.permitted_to_view_timelapse ? 'Enabled' : 'Disabled'}
                                                    defaultChecked={this.props.template.settings.permitted_to_view_timelapse}
                                                    onChange={this.onViewTimeLapsePermissionChange}
                                                    toggle />
                                            </> : null
                                        }

                                        <p className="permissions__title">Video Editing</p>
                                        <Checkbox
                                            disabled={this.isCompleted()}
                                            label={this.props.template.video_editor ? 'Enabled' : 'Disabled'}
                                            checked={this.props.template.video_editor}
                                            onChange={this.onAllowVideoEditingPermissionChange}
                                            toggle />

                                        {isLobsterUser ?
                                            <>
                                                <p className="permissions__title">Hide Virtual Cameras</p>
                                                <Checkbox
                                                    disabled={this.isCompleted()}
                                                    label={this.props.template.settings.hide_virtual_cameras ? 'Enabled' : 'Disabled'}
                                                    defaultChecked={this.props.template.settings.hide_virtual_cameras}
                                                    onChange={this.onHideVirtualCamerasChange}
                                                    toggle />
                                                    
                                            </> : null
                                        }
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            {isLobsterUser ?
                                            <>
                                                <ShowBimModelType
                                                    defaultChecked={this.props.template.settings.show_bim_model_type}
                                                    onChange={this.onBimContractDeliveryChange}
                                                />
                                                <p></p>

                                                <h3>Public API</h3>
                                                <p className="permissions__title">Allow API access</p>
                                                <Checkbox
                                                    disabled={this.isCompleted()}
                                                    label={this.props.template.api ? 'Enabled' : 'Disabled'}
                                                    defaultChecked={this.props.template.api}
                                                    onChange={this.onApiChange}
                                                    toggle />
                                                <br />
                                            </> : null
                                        }
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>)
    }

    getEmailRelatedErrorMessage = () => {
        if (this.props.errorEmails) {
            let notUnique = false;

            this.props.errorEmails.every(element => {
                if ('isNotUnique' in element.error.email) {
                    notUnique = true;
                    return false
                }

                return true
            });
            
            if (notUnique) {
                return (
                    <div className="message-wrap">
                        <Message negative compact={true}>
                            <Message.Header>Import Error</Message.Header>
                            <p>You are trying to import a user that already exists. If you are adding this user to a new project please <a href="mailto:support@lobsterpictures.tv">contact us</a>.</p>
                        </Message>
                    </div>
                )
            }
        }
    }

    getUserProjectsAndCameraSelectorWidget = () => {
        return ( <UserProjectsAndCamerasSelector
            auth={this.props.auth}
            disabled={this.props.ucpa_completed}
            loading={this.props.ucpa_loading}
            loadingProjectCameras={this.props.cameras.loading}
            loadingProjectCamerasProjectId={this.props.cameras.projects_id}
            cameras={this.props.cameras.cameras}
            user_cameras={this.props.user_cameras.cameras}
            user_project_cameras={
                (() => {

                    let items = this.props.user_projects.projects.slice()
                    items = items.concat(this.props.add_user_projects)
                    items = items.filter((elem) => {
                        return this.props.remove_user_projects.findIndex((e) => {
                            return e.id === elem.id
                        }) === -1
                    })

                    let cams = this.props.user_cameras.cameras.slice()
                    cams = cams.concat(this.props.add_user_cameras)
                    cams = cams.filter((elem) => {
                        return this.props.remove_user_cameras.findIndex((e) => {
                            return e.id === elem.id
                        }) === -1
                    })

                    items = items.concat(cams)
                    items = items.map((elem) => {
                        return Object.assign(
                            {},
                            elem,
                            {
                                key: elem.id,
                                text: elem.pot_id || elem.name
                            }
                        )
                    })
                    // Okay, this is sorting essentially by anything available...
                    items = items.sort((a, b) => {
                        if ('weight' in a && 'weight' in b) {
                            if (a.weight < b.weight) {
                                return -1
                            }
                            if (a.weight > b.weight) {
                                return 1
                            }
                            return 0
                        } else if ('weight' in a && 'default_weight' in b) {
                            if (a.weight < b.default_weight) {
                                return -1
                            }
                            if (a.weight > b.default_weight) {
                                return 1
                            }
                            return 0
                        } else if ('default_weight' in a && 'weight' in b) {
                            if (a.default_weight < b.weight) {
                                return -1
                            }
                            if (a.default_weight > b.weight) {
                                return 1
                            }
                            return 0
                        } else {
                            if ('default_weight' in a < 'default_weight' in b) {
                                return -1
                            }
                            if (a.default_weight > b.default_weight) {
                                return 1
                            }
                            return 0
                        }
                    })

                    if (this.props.project_camera_weights && this.props.project_camera_weights.length > 0) {
                        // If here, this sorting should ultimately take priority,
                        // as it reflects the very latest local changes
                        items = items.sort((a, b) => {
                            const aWeighting = this.props.project_camera_weights.find((i) => {
                                if ('pot_id' in a || 'pot_id' in i) {
                                    if ('pot_id' in i && 'pot_id' in a) {
                                        return a.id === i.id
                                    } else {
                                        return false
                                    }
                                } else {
                                    return a.id === i.id
                                }
                            })

                            const bWeighting = this.props.project_camera_weights.find((i) => {
                                if ('pot_id' in b || 'pot_id' in i) {
                                    if ('pot_id' in i && 'pot_id' in b) {
                                        return b.id === i.id
                                    } else {
                                        return false
                                    }
                                } else {
                                    return b.id === i.id
                                }
                            })

                            if (aWeighting && bWeighting) {
                                // Should always be the case
                                if (aWeighting.weight < bWeighting.weight) {
                                    return -1
                                }
                                if (aWeighting.weight > bWeighting.weight) {
                                    return 1
                                }
                                return 0

                            } else {
                                return -1
                            }

                        })

                    }
                    return items

                })()
            }

            onLoadProjectCameras={(project) => {
                const clientId = this.state.clients_id
                this.props.onLoadProjectCameras(clientId, project)
            }}
            onResetProjectCameras = {this.props.onResetProjectCameras}
            onAddUserCameras={(cameras) => {

                // Okay, so we only want to add cameras not already previously linked to user
                const camerasFiltered = cameras.filter((elem) => {
                    return this.props.user_cameras.cameras.findIndex((e) => {
                        return elem.id === e.id
                    }) === -1
                })

                // We also only want to add a camera not already added...
                const camerasFilteredUnique = camerasFiltered.filter((camera) => {
                    return (this.props.add_user_cameras.findIndex((elem) => {
                        return elem.id === camera.id
                    }) === -1)
                })

                // Let's add the latest array to the existing one
                const addUserCameras = [...this.props.add_user_cameras, ...camerasFilteredUnique]
                this.props.onAddUserCameras(addUserCameras)

                // Let's also tidy up the remove cameras array, if necessary
                let removeUserCameras = this.props.remove_user_cameras.slice()
                let changed = false

                cameras.forEach((camera) => {
                    const key =
                        removeUserCameras.findIndex((elem) => {
                            return elem.id === camera.id
                        })

                    if (key !== -1) {
                        changed = true
                        // We can tidy up a little...
                        removeUserCameras.splice(key, 1)
                    }
                })

                if (changed) {
                    this.props.onRemoveCameras(removeUserCameras)
                }
            }}

            onAddProject={(project) => {
                if (this.props.add_user_projects.findIndex((elem) => {
                        return elem.id === project.id
                    }) === -1) {

                    // If project is already is linked to user, but now marked for removal
                    // we don't really want to add it here
                    if (this.props.user_projects.projects.findIndex((elem) => {
                            return elem.id === project.id
                        }) === -1) {
                        // Avoids
                        this.props.onAddUserProjects([...this.props.add_user_projects, project])
                    }
                }

                // If we're adding a project, let's clean up the remove projects just in case
                // Also necessary for any project previously marked for removal
                const key = this.props.remove_user_projects.findIndex((elem) => {
                    return elem.id === project.id
                })
                if (key !== -1) {
                    let projs = this.props.remove_user_projects.slice()
                    projs.splice(key, 1)
                    this.props.onRemoveProjects(projs)
                }
            }}

            onRemoveProject={(project) => {
                if (this.props.remove_user_projects.findIndex((elem) => {
                        return elem.id === project.id
                    }) === -1) {

                    // So, we only need to add items that are actually already linked to user
                    if (this.props.user_projects.projects.findIndex((elem) => {
                            return elem.id === project.id
                        }) !== -1) {

                        this.props.onRemoveProjects([...this.props.remove_user_projects, project])

                    }
                }

                // Let's also update the add_user_projects too, if necessary
                const key = this.props.add_user_projects.findIndex((elem) => {
                    return elem.id === project.id
                })
                if (key !== -1) {
                    let projs = this.props.add_user_projects.slice()
                    projs.splice(key, 1)
                    this.props.onAddUserProjects(projs)
                }
            }}

            onRemoveCamera={(camera) => {
                if (this.props.remove_user_cameras.findIndex((elem) => {
                        return elem.id === camera.id
                    }) === -1) {

                    // We only need to add here if the already linked to user..
                    if (this.props.user_cameras.cameras.findIndex((elem) => {
                            return elem.id === camera.id
                        }) !== -1) {

                        this.props.onRemoveCameras([...this.props.remove_user_cameras, camera])
                    }
                }

                // Let's also update add_user_cameras too, if necessary
                const key = (this.props.add_user_cameras.findIndex((elem) => {
                    return elem.id === camera.id
                }))
                if (key !== -1) {
                    let cams = this.props.add_user_cameras.slice()
                    cams.splice(key, 1)
                    this.props.onAddUserCameras(cams)
                }

            }}

            onUpdateProjectCameraWeights={this.props.onUpdateProjectCameraWeights}
            projects={
                (() => {
                    let p = this.props.projects.projects.map((elem, idx) => {
                        return Object.assign(
                            {},
                            elem,
                            {
                                key: idx,
                                text: elem.name
                            }
                        )
                    })

                    p = p.filter((elem) => {

                        const notAddedToUserProjects = this.props.add_user_projects.findIndex((e) => {
                            return elem.id === e.id
                        }) === -1

                        const notMarkedForRemoval = this.props.remove_user_projects.findIndex((e) => {
                            return elem.id === e.id
                        }) === -1

                        const notExistingUserProject = this.props.user_projects.projects.findIndex((e) => {
                            return elem.id === e.id
                        }) === -1

                        if (notAddedToUserProjects && notExistingUserProject) {
                            return true
                        } else if (!notExistingUserProject && !notMarkedForRemoval) {
                            return true
                        } else {
                            return false
                        }

                    })

                    return p

                })()
            }
        />)
    }

    getUserWidget = () => {
        return (<Message

            visible={true}
            content={<div>
                <p><strong>Add new users</strong> <br/>(To add multiple emails please use a comma seperated list)</p>
                <Form>
                    <Ref innerRef={this.emailFieldRef}>
                        <TextArea
                            disabled={this.props.completed}
                            placeholder='E-mail address'
                            className="user-email-input"
                        />
                    </Ref>
                    <Button
                        disabled={this.props.completed}
                        placeholder='E-mail address'
                        onClick={this.onUsernameSubmit}
                    >Add User</Button>
                </Form>
            </div>
            }
        />)
    }

    getUserAccountsWidget = () => {
        if (this.props.emails.length > 0 ) {
            return ( <Message
                    error={this.props.error}
                    success={this.props.success}
                    visible={true}
                    content={this.getCreateAccounts()}
                />
            )
        }
    }

    getErrorAccountsWidget = () => {
        if (this.props.errorEmails && this.props.errorEmails.length > 0) {
            return ( <Message
                    error={true}
                    visible={true}
                    content={this.getErrorEmails()}
                />
            )
        }
    }


    isCompleted = () => {
        return this.props.ucpa_completed
    }

    getCompletedMessage = () => {

        const users = this.props.users
        const emails = this.props.emails

        // emails should be "canonical".  If ID exists in users, it has been created.
        const lengthMatching = emails.length === users.length
        const missingIDs = []
        const withIDs = []
        emails.forEach((item, idx) => {
            const u = users.find((i) => {
                return i.username === item.email
            })

            if (!u || !u.id || item.status === 'Error') {
                missingIDs.push(u)
            } else {
                withIDs.push(u)
            }
        })

        const success = lengthMatching && missingIDs.length === 0
        const failedCount = missingIDs.length
        const successCount = withIDs.length

        let summary = ''
        if (success) {
            summary = <div><p>{`Successfully created ${successCount} user accounts.`}</p></div>
        } else if (successCount > 0) {
            summary = <div><p>{`Successfully created ${successCount} user accounts.`}</p><p>{`Failed to create ${failedCount} user accounts`}</p></div>
        } else {
            summary = <div><p>{`Failed to create ${failedCount} user accounts`}</p></div>
        }

        const details = (failedCount > 0) ?
            <div>
                <p>Details of failed user accounts: </p>
                <ul>
                    {missingIDs.map((item, idx) => {
                        if (item) {
                            return <li key={idx}>{item.username}</li>
                        } else {
                            return <li key={idx}><i>Unknown</i></li>
                        }

                    })}
                </ul>
            </div> : null

        return( <Message
            error={!success}
            success={success}
            visible={this.props.visible}
            header={<h4>Finished processing</h4>}
            content={<div>{summary}{details}</div>}
        />)
    }

    getProcessingMessage = () => {
        return <Message
            error={false}
            success={false}
            visible={true}
            header={<h4>Processing...</h4>}
            content={<p>This might take a few moments</p>}
        />
    }

    render = () => {
        const isCompleted = this.isCompleted()
        let message = null
        if (this.props.processing) {
            message = this.getProcessingMessage()
        } else if (isCompleted) {
            message = this.getCompletedMessage()
        }
        if (this.state.currentStep === 1) {
            return (
                <div className='create-bulk-users'>
                    {this.getSteps('active', null, null)}
                    {message}
                    {this.getSettings()}
                    <div className='modalButtons'>
                        {this.getCloseButton('Cancel')}
                        <Button
                            onClick={() => {this.goToStep(2)}}
                            disabled={!this.state.roles_id || !this.state.clients_id}
                            primary
                        >
                            Assign Cameras
                        </Button>
                    </div>
                </div>
            )
        } else if (this.state.currentStep === 2) {
            return (
                <div className='create-bulk-users'>
                    {this.getSteps('completed', 'active', null)}
                    {message}
                    {this.getUserProjectsAndCameraSelectorWidget()}
                    <div className='modalButtons'>
                        {this.getCloseButton('Cancel')}
                        <Button
                            onClick={() => {this.goToStep(3)}}
                            primary
                        >
                            Enter Emails
                        </Button>
                    </div>
                </div>
            )
        } else if (this.state.currentStep === 3) {
            return (
                <div className='create-bulk-users'>
                    {this.getSteps('completed', 'completed', 'active')}
                    {message}
                    {this.getEmailRelatedErrorMessage()}
                    {this.getUserWidget()}
                    {this.getErrorAccountsWidget()}
                    {this.getUserAccountsWidget()}
                    <div className='modalButtons'>
                        {this.getCloseButton(this.props.ucpa_completed ? 'Close' : 'Cancel')}
                    </div>
                </div>
            )
        } else {
            return (
                <div className='create-bulk-users'>
                    {message}
                </div>
            )
        }
    }
}

CreateBulkUsers.propTypes = {
    onClick: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdateTemplate: PropTypes.func.isRequired,
    emails: PropTypes.array.isRequired,
    completed: PropTypes.bool.isRequired,
    template: PropTypes.shape({
        username: PropTypes.string.isRequired,
        job_title: PropTypes.string.isRequired,
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        roles_id: PropTypes.any.isRequired,
        status: PropTypes.string.isRequired,
        clients_id: PropTypes.any.isRequired,
        lockdown: PropTypes.string.isRequired,
        processed: PropTypes.bool,
        limit_history: PropTypes.number.isRequired,
        delay: PropTypes.number.isRequired,
        settings: PropTypes.shape({
            permitted_to_save: PropTypes.bool.isRequired,
            permitted_to_zoom: PropTypes.bool.isRequired,
            permitted_to_view_calendar: PropTypes.bool.isRequired,
            show_navigation_permission: PropTypes.string.isRequired,
            show_thumbnails_permission: PropTypes.string.isRequired,
            permitted_to_view_timelapse: PropTypes.bool.isRequired,
            permitted_to_auto_refresh: PropTypes.bool.isRequired,
            hide_virtual_cameras: PropTypes.bool.isRequired,
            show_bim_model_type: PropTypes.string.isRequired,
            weather: PropTypes.shape({
                enabled: PropTypes.bool.isRequired,
                temperature_unit: PropTypes.string.isRequired,
                wind_speed_unit: PropTypes.string.isRequired,
            }),
        }),
    }),
    error: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    validateEmailAsUsername: PropTypes.shape({
        success: PropTypes.bool.isRequired,
        error: PropTypes.bool.isRequired,
        errors: PropTypes.any.isRequired,
        email: PropTypes.string.isRequired,
    }),
    projects: PropTypes.shape({
        loading: PropTypes.bool.isRequired,
        projects: PropTypes.array.isRequired
    }),
    cameras: PropTypes.shape({
        loading: PropTypes.bool.isRequired,
        cameras: PropTypes.array.isRequired
    }),
    ucpa_completed: PropTypes.bool.isRequired,
    users: PropTypes.array.isRequired,
    // onRepeatProcess: PropTypes.func.isRequired
}

export default CreateBulkUsers

