import initialState from './initialState';
import {
    EDIT_USER_REQUEST,
    EDIT_USER_RESPONSE,
    EDIT_USER_RESET_ERRORS,
    EDIT_USER_RESPONSE_ERRORS,
    EDIT_USER_RESPONSE_SUCCESS,
    EDIT_USER_DETAILS_REQUEST,
    EDIT_USER_DETAILS_RESPONSE,
    EDIT_USER_BULK_DETAILS_RESPONSE,
    EDIT_USER_ROLES_REQUEST,
    EDIT_USER_ROLES_RESPONSE,
    EDIT_USER_STATUSES_REQUEST,
    EDIT_USER_STATUSES_RESPONSE,
    EDIT_USER_CLIENTS_REQUEST,
    EDIT_USER_CLIENTS_RESPONSE,
    DELETE_USER_RESPONSE_ERRORS,
    DELETE_USER_RESPONSE_SUCCESS,
    DELETE_USER_REQUEST,
    DELETE_USER_RESET_ERRORS,
    SUSPEND_USER_RESPONSE_ERRORS,
    SUSPEND_USER_RESPONSE_SUCCESS,
    SUSPEND_USER_REQUEST,
    SUSPEND_USER_RESET_ERRORS,
    USER_VERIFICATION_EMAIL_RESPONSE_SUCCESS,
    USER_VERIFICATION_EMAIL_RESPONSE_ERRORS,
    USER_VERIFICATION_EMAIL_REQUEST,
    USER_VERIFICATION_EMAIL_RESET_ERRORS,
    EDIT_USER_UPDATE_LOCAL_USER_STATE,
    EDIT_USER_UPDATE_LOCAL_BULK_USER_STATE,
    EDIT_USER_SET_CONTEXT
} from '../actions/actionTypes';

export default function editUser(state = initialState.editUser, action) {

    switch (action.type) {
        case EDIT_USER_REQUEST:
            return Object.assign({}, state, { loading: true })
        case EDIT_USER_RESPONSE:
            return Object.assign(
                {},
                state,
                action.editUser,
                { loading: false }
            )
        case EDIT_USER_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                action.editUser,
                { 
                    loading: false,
                    success: true,
                    error: false,
                    errors: {}
                }
            )
        case EDIT_USER_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                action.editUser,
                {
                    loading: false,
                    success: false,
                    error: true
                }
            )
        case EDIT_USER_RESET_ERRORS:
            return Object.assign({}, initialState.editUser )
        case EDIT_USER_DETAILS_REQUEST:
            return Object.assign({}, state, { loading: true })
        case EDIT_USER_DETAILS_RESPONSE:
            return Object.assign(
                {},
                state,
                {
                    user: {
                        ...action.editUser,
                        settings: {
                            ...state.user.settings,
                            ...action.editUser.settings
                        }
                    },
                    loading: false
                }
            )
        case EDIT_USER_BULK_DETAILS_RESPONSE:
            return Object.assign(
                {},
                state,
                {
                    bulkEditUsers: [
                        ...state.bulkEditUsers,
                        action.editUser
                    ],
                    loading: false
                }
            )
        case EDIT_USER_ROLES_REQUEST:
            return Object.assign({}, state, { roles: [] } )
        case EDIT_USER_ROLES_RESPONSE:
            return Object.assign({}, state, { roles: action.editUser } )
        case EDIT_USER_STATUSES_REQUEST:
            return Object.assign({}, state, { statuses: [] } )
        case EDIT_USER_STATUSES_RESPONSE:
            return Object.assign({}, state, { statuses: action.editUser } )
        case EDIT_USER_CLIENTS_REQUEST:
            return Object.assign({}, state, { clients: [] } )
        case EDIT_USER_CLIENTS_RESPONSE:
            return Object.assign({}, state, { clients: action.editUser } )
        case DELETE_USER_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    delete:
                        {
                            loading: true,
                            errors: {},
                            message: null,
                            users_id: false
                        }
                }
            )
        case DELETE_USER_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    delete:
                        {
                            loading: false,
                            errors: {},
                            message: action.message,
                            users_id : action.users_id
                        }
                }
            )
        case DELETE_USER_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    delete:
                        {
                            loading: false,
                            errors: action.errors,
                            message: action.message,
                            users_id: false
                        }
                }
            )
        case DELETE_USER_RESET_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    delete:
                        {
                            loading: false,
                            errors: {},
                            message: null,
                            users_id: false
                        }
                }
            )
        case SUSPEND_USER_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    suspend:
                        {
                            loading: true,
                            errors: {},
                            message: null,
                            id: false
                        }
                }
            )
        case SUSPEND_USER_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    suspend:
                        {
                            loading: false,
                            errors: {},
                            message: action.message,
                            id : action.id
                        }
                }
            )
        case SUSPEND_USER_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    suspend:
                        {
                            loading: false,
                            errors: action.errors,
                            message: action.message,
                            id: false
                        }
                }
            )
        case SUSPEND_USER_RESET_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    suspend:
                        {
                            loading: false,
                            errors: {},
                            message: null,
                            id: false
                        }
                }
            )
        case USER_VERIFICATION_EMAIL_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    resendEmailVerification:
                        {
                            loading: true,
                            errors: [],
                            message: null,
                            id: false
                        }
                }
            )
        case USER_VERIFICATION_EMAIL_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    resendEmailVerification:
                        {
                            loading: false,
                            errors: {},
                            message: action.message,
                            users_id : action.users_id
                        }
                }
            )
        case USER_VERIFICATION_EMAIL_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    resendEmailVerification:
                        {
                            loading: false,
                            errors: action.errors,
                            message: null,
                            users_id: false
                        }
                }
            )
        case USER_VERIFICATION_EMAIL_RESET_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    resendEmailVerification:
                        {
                            loading: false,
                            errors: {},
                            message: null,
                            users_id: false
                        }
                }
            )
        case EDIT_USER_UPDATE_LOCAL_USER_STATE:
            // action.user.settings isn't always returned
            // this checks its value before adding to the object
            return Object.assign(
                {},
                state,
                {
                    user: {
                        ...state.user,
                        ...action.user,
                        settings: {
                            ...state.user.settings,
                            ...(action.user.settings && action.user.settings),
                            weather: {
                                ...state.user.settings.weather,
                                ...(action.user.settings &&
                                    action.user.settings.weather &&
                                    action.user.settings.weather),
                            }
                        }
                    }
                }
            )
        case EDIT_USER_UPDATE_LOCAL_BULK_USER_STATE:
            return Object.assign(
                {},
                state,
                {   
                    bulkEditUsers: [
                        ...state.bulkEditUsers,
                        action.user
                    ]
                }
            )

        case EDIT_USER_SET_CONTEXT:
            return Object.assign(
                {},
                state,
                {
                    context: action.context
                }
            )

        default:
            return state
    }
}
