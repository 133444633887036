const dev = {
    API_BASE_URL: "http://dev.service.lobstervision3.tv",
    VIEWER_URL: "http://localhost:3001",
    TUSD_URL: "https://pot-upload-dev.lobstervision.tv/upload/"
}

const uat = {
    API_BASE_URL: "https://service-uat.lobstervision.tv",
    VIEWER_URL: "https://viewer-uat.lobstervision.tv",
    TUSD_URL: "https://pot-upload-dev.lobstervision.tv/upload/"
}

const prod = {
    API_BASE_URL: "https://service.lobstervision.tv",
    VIEWER_URL: "https://www.lobstervision.tv",
    TUSD_URL: "https://pot-upload.lobstervision.tv/upload/"
}

let config
if (process.env.NODE_ENV === "production") {
    config = process.env.REACT_APP_STAGE === 'uat' ? uat : prod
} else {
    config = dev
}

export const API_BASE_URL = config["API_BASE_URL"]
export const VIEWER_URL = config["VIEWER_URL"]
export const AWS_SHARE_URL = "http://share.lobsterpictures.tv/"
export const PAGINATION_MAX_ROWS_PER_PAGE = 15
export const PAGINATION_MAX_ROWS_PER_PAGE_PERMISSIONS = 25
export const CAMERA_CONTROL_POLLING_SIGNAL_STRENGTH_IN_MILLISECONDS = 30000
export const CAMERA_CONTROL_POLLING_WIFI_IN_MILLISECONDS = 60000
export const RECAPTCHA_SITE_KEY = '6Lelil8UAAAAAEH1Pr8mSy4gnw1OG2YjoN24HB-q'
export const TUSD_URL = config["TUSD_URL"]
export const LOBSTER_POT_4 = 4
