import * as types from './actionTypes'
import { API_BASE_URL}  from '../config'
import { addMessage } from './messengerActions'
import { log } from './logActions'
import { notAuthorisedResponse } from './authActions'
import { fetchWithToken } from '../util/accessToken'

export function reset() {
    return { type: types.LOGO_RESET }
}

export function uploadLogo(entityId, formData, type) {
    return (dispatch, getState) => {
        dispatch({ type: types.LOGO_UPLOAD_LOGO_REQUEST, keyValue : entityId})
        return fetchWithToken(
            `${API_BASE_URL}/api/${type}/${entityId}/upload-logo`,
            {
                method: 'POST',
                mode: 'cors',
                body: formData
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    // might be forbidden!
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch({ type: types.LOGO_UPLOAD_LOGO_RESPONSE_ERRORS, errors: data.errors })
                            } else {
                                dispatch({ type: types.LOGO_UPLOAD_LOGO_RESPONSE_ERRORS, errors:  { logo: { unknownError: 'Unknown error' }}})
                            }
                        })
                    }
                } else {
                    response.json().then((data) => {
                        // Presumably, errors of some kind...
                        if (data.errors) {
                            dispatch({ type: types.LOGO_UPLOAD_LOGO_RESPONSE_ERRORS, errors: data.errors })
                        } else {
                            dispatch({
                                type: types.LOGO_UPLOAD_LOGO_RESPONSE_SUCCESS,
                                details: data
                            })
                        }
                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(log("error", response))
            })
    }
}

export function resetRemoveLogo() {
    return { type: types.LOGO_REMOVE_LOGO_RESET }
}

export function removeLogo(entityId, type) {
    return (dispatch, getState) => {
        dispatch({ type: types.LOGO_REMOVE_LOGO_REQUEST, keyValue : entityId})
        return fetchWithToken(
            `${API_BASE_URL}/api/${type}/${entityId}/remove-logo`,
            {
                method: 'POST',
                mode: 'cors'
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    // might be forbidden!
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch({ type: types.LOGO_REMOVE_LOGO_RESPONSE_ERRORS, errors: data.errors })
                            } else {
                                dispatch({ type: types.LOGO_REMOVE_LOGO_RESPONSE_ERRORS, errors:  { logo: { unknownError: 'Unknown error' }}})
                            }
                        })
                    }
                } else {

                    if (response.status === 204) {
                        dispatch({ type: types.LOGO_REMOVE_LOGO_RESPONSE_SUCCESS })
                    } else {
                        response.json().then((data) => {
                            // Presumably, errors of some kind...
                            if (data.errors) {
                                dispatch({ type: types.LOGO_REMOVE_LOGO_RESPONSE_ERRORS, errors: data.errors })
                            }
                        })
                    }
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(log("error", response))
            })
    }
}

