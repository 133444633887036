import React from 'react'
import PropTypes from 'prop-types'
import {  Icon, Popup } from 'semantic-ui-react'

const IncreasePriority = (props) => {
    return (
        <Popup
            trigger={
                <Icon
                    name='arrow left'
                    size={props.small ? null: 'large'}
                    disabled={props.disabled}
                    style={{
                        float: !props.small ? 'left' : null,
                        color: '#ededed'
                    }}
                    link
                    onClick={(event) => {
                        event.stopPropagation()
                        props.onClick(
                            props.dayId, props.period)
                    }}
                    data-testid="icon--increase-priority"
                />
            }
            content="Increase the priority of this event"
            basic
        />
    )
}

export default IncreasePriority

IncreasePriority.propTypes = {
    small: PropTypes.bool,
    disabled: PropTypes.bool,
    dayId: PropTypes.number.isRequired,
    period: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired
}
