import React, { Component } from 'react'
import {
    Button,
    Icon,
    Modal,
    Form,
    TextArea
} from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { AWS_SHARE_URL } from '../../config'

class ViewEmbedModal extends Component {

    constructor(props) {
        super(props)
        this.state = { clickThrough: null }
    }

    onClickThroughChange = (event, {value}) => {
        this.setState({ clickThrough: value })
    }

    copyEmbed = () => {
        this.textArea.ref.select()
        document.execCommand('copy')
    }

    render() {
        if (!this.props.embed) {
            return null
        }
        const embed = this.props.embed
        let code, clickThroughInput
        if (embed.format === 'image') {
            code = `<img src="${AWS_SHARE_URL}image/${embed.name}" title="Powered by Lobster Vision - https://lobsterpictures.tv/" width="${embed.width}" height="${embed.height}" />`
            if (this.state.clickThrough) {
                code = `<a href="${this.state.clickThrough}">\n\t${code}\n</a>`
            }
            clickThroughInput = (
                <Form.Input
                    label="Click through (optional)"
                    onChange={this.onClickThroughChange}
                    placeholder='http://example.net'
                />
            )
        } else {
            code = `${AWS_SHARE_URL}video/${embed.name}`
        }

        return (
            <Modal size={"tiny"} open={this.props.visible} onClose={this.props.onClose} closeIcon>
                <Modal.Header>Embed Link</Modal.Header>
                <Modal.Content>
                    <Form>
                        {clickThroughInput}
                        <TextArea autoHeight value={code} ref={(textArea)=>this.textArea = textArea}/>
                    </Form>
                    <Button
                        compact
                        size="mini"
                        onClick={this.copyEmbed}
                    >
                        <Icon name='copy' />
                        Copy to clipboard
                    </Button>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.props.onClose}>Close</Button>
                </Modal.Actions>
            </Modal>
        )
    }
}

ViewEmbedModal.propTypes = {
    embed: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
}

export default ViewEmbedModal
