import initialState from './initialState';
import {
    CAPTCHA_RECAPTCHA_RESPONSE_SUCCESS,
    CAPTCHA_RECAPTCHA_RESPONSE_ERRORS,
    CAPTCHA_RECAPTCHA_RESET_ERRORS,
    CAPTCHA_RECAPTCHA_REQUEST,
    LOGOUT_RESPONSE
} from '../actions/actionTypes';

export default function captcha(state = initialState.captcha, action) {

    switch (action.type) {

        case CAPTCHA_RECAPTCHA_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    isHuman: false,
                    loading: true,
                    errors: {},
                    success: false,
                    error: false,
                }
            )

        case CAPTCHA_RECAPTCHA_RESET_ERRORS:
            return initialState.captcha

        case CAPTCHA_RECAPTCHA_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    isHuman: false,
                    loading: false,
                    errors: action.errors,
                    success: false,
                    error: true,
                }
            )

        case CAPTCHA_RECAPTCHA_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    isHuman: true,
                    loading: false,
                    errors: {},
                    success: true,
                    error: false,
                }
            )

        case LOGOUT_RESPONSE:
            return initialState.captcha


        default:
            return state
    }
}
