import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Message, Grid, Dropdown } from 'semantic-ui-react'

import { getErrorMessage } from './../util/errors'

class Logging extends Component {

    constructor(props) {
        super(props)
        this.state = { logging_level: null}
    }

    onChange = (evt, {value}) => {
        this.setState({logging_level: value})
    }

    getLoggingOptions = () => {
        return this.props.camera_control.system.details.logging.options.map((item) => {
            return {  text: item.toUpperCase(), value: item }
        })
    }

    isProcessing = () => {
        return this.props.camera_control.update_system.processing
            && 'logging_level' in this.props.camera_control.update_system.properties
    }

    isButtonDisabled = () => {
        if (this.isProcessing()) {
            return true
        }
        if (this.state.logging_level === null) {
            return true
        }
        if (this.state.logging_level && this.state.logging_level === this.getValue()) {
            return true
        }
        return false
    }

    onClick = () => {
        const properties = {
            logging_level: this.state.logging_level
        }
        this.props.cameraControlActions.updateSystem(this.props.cameras_id, properties)
            .then(() => {
                this.props.cameraControlActions.fetchSystem(this.props.cameras_id)
            })
    }

    getValue = () => {
        return this.props.camera_control.system.details.logging.value
    }

    isReady = () => {
        return this.props.camera_control.system.details
            && 'logging' in this.props.camera_control.system.details
    }

    getError = () => {
        if (!this.props.camera_control.update_system.error) {
            return null
        }
        let message = getErrorMessage(this.props.camera_control.update_system.errors, 'cameras_id')
        if (!message) {
            message = getErrorMessage(this.props.camera_control.update_system.errors, 'unknownErrorType')
        }
        return (message) ? message : "There was an unexpected error"
    }

    isMessageRelatedToLogging = () => {
        return (this.props.camera_control.update_system.error || this.props.camera_control.update_system.success)
            &&  'logging_level' in this.props.camera_control.update_system.properties
    }

    getMessage = (blurb) => {
        return (this.isMessageRelatedToLogging()) ?
            <Message positive={this.props.camera_control.update_system.success} negative={this.props.camera_control.update_system.error}

                     onDismiss={this.onDismissMessage}

            >
                <Message.Header>{(this.props.camera_control.update_system.error) ? 'Error' : 'Success'}</Message.Header>
                <p>{blurb}</p>
            </Message> : null
    }

    getBlurb = () => {
        if (this.props.camera_control.update_system.error) {
            return this.getError()
        }
        if (this.props.camera_control.update_system.success) {
            return 'Successfully updated logging level'
        }
        return null
    }

    onDismissMessage = () => {
        this.props.cameraControlActions.resetUpdateSystem()
    }

    render = () => {

        if (!this.isReady()) {

            return (
                <div className='logging'>
                    {/*<Dimmer active inverted><Loader content='' /></Dimmer>*/}
                    <Grid columns='1'>
                        <Grid.Row>
                            <Grid.Column>
                                <Grid.Column><div className="animated-blank-line" /></Grid.Column>
                                <Grid.Column><div className="animated-blank-line" /></Grid.Column>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            )

        } else {

            const loggingDropdown =
                <Dropdown
                    fluid
                    selection
                    labeled
                    placeholder='Logging Level'
                    defaultValue={this.props.camera_control.system.details.logging.value}
                    onChange={this.onChange}
                    options={this.getLoggingOptions()}
                />

            return (
                <div className='logging'>
                    {this.getMessage(this.getBlurb())}
                    <Grid columns='1'>
                        <Grid.Row>
                            <Grid.Column>
                                {loggingDropdown}
                                <Button
                                    fluid
                                    loading={this.isProcessing()}
                                    disabled={this.isButtonDisabled()}
                                    onClick={this.onClick}>Update Logging Level</Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            )

        }
    }
}

Logging.propTypes = {
    cameraControlActions: PropTypes.object.isRequired,
    cameras_id: PropTypes.string.isRequired,
    camera_control: PropTypes.object.isRequired
}

export default Logging
