import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, Menu, Icon, Popup, Label } from 'semantic-ui-react'
import { isPermitted } from "./../util/acl"
import { Link } from 'react-router-dom'
import { LOBSTER_POT_4} from '../config'

class CameraListView extends Component {

    componentDidMount() {
        this.props.camerasActions.fetchListViewCameras(this.props.cameras.list_view.currentPage)
    }

    prevPage = (page) => {
        this.props.camerasActions.fetchListViewCameras(page)
    }

    nextPage = (page) => {
        this.props.camerasActions.fetchListViewCameras(page)
    }

    jumpToPage = (page) => {
        this.props.camerasActions.fetchListViewCameras(page)
    }

    getCameraControlLink = (item) => {
        if (item.type === 'Virtual' || item.type === 'Windows') {
            return null
        }

        if (item.pot_version === LOBSTER_POT_4) {
            return null
        }

        let cameraControlLink = null
        if (isPermitted(this.props.auth.user, 'get-cameracontrol-status')) {
            cameraControlLink = (
                <Popup
                    offset={[-13, 0]}
                    trigger={
                        <a
                            target='_blank'
                            rel='noopener noreferrer'
                            className='camera-control-link'
                            href={`/cameras/${item.id}/camera-control`}
                            >
                            <Icon className="iris" />
                        </a>
                    }
                    content='Camera Control'
                />

            )
        }
        return cameraControlLink
    }

    getScheduleLink = (item) => {
        if (item.type === 'Virtual' || item.type === 'Windows') {
            return null
        }
        let scheduleLink = null
        if (isPermitted(this.props.auth.user, 'get-scheduler-schedule')) {
            scheduleLink = (
                <Popup
                    offset={[-13, 0]}
                    trigger={
                        <Link
                            className='camera-scheduler-link'
                            to={`/cameras/${item.id}/schedule`}
                        >
                            <Icon name='calendar' link />
                        </Link>
                    }
                    content='Schedule'
                />
            )
        }
        return scheduleLink
    }

    getUpdatedCameraLink = (item) => {
        let updateCameraLink = null
        if (isPermitted(this.props.auth.user, 'put-cameras-cameras')) {
            updateCameraLink = (

                <Popup
                    offset={[-13, 0]}
                    trigger={<Icon className='camera-modal-link camera-settings-link' aria-label="Edit Camera" link={true} name='cogs' onClick={() => {
                        this.props.onOpenModal(item.id)
                    }}/>}
                    content='Edit'
                />

                )

        }
        return updateCameraLink
    }

    getMonitoringLink = (item) => {
        if (item.type === 'Virtual') {
            return null
        }

        let monitoringLink = null

        if (isPermitted(this.props.auth.user, 'MONITORING_CAMERAS_LINK')) {
            monitoringLink = (
                <Popup
                    offset={[-13, 0]}
                    trigger={
                        <a
                            target='_blank'
                            rel='noopener noreferrer'
                            className='camera-monitoring-link'
                            href={`https://monitoring.vpn.lobsterpictures.tv/monitoring/check_mk/index.py?start_url=%2Fmonitoring%2Fcheck_mk%2Fview.py%3Fhost%3Dlob-pot-${item.pot_id}%26site%3Dmonitoring%26view_name%3Dhost`}
                        >
                            <Icon name='bar chart' link />
                        </a>
                    }
                    content='Monitoring'
                />
            )
        }
        return monitoringLink
    }

    getAuthAsCameraLink = (item) => {
        let authAsCameraLink = null
        if (isPermitted(this.props.auth.user, 'get-authas-camera')) {
            authAsCameraLink = (
                <Popup
                    offset={[-13, 0]}
                    trigger={<Icon link={true} name='computer' onClick={() => {
                        this.props.authAsCamera(item.id)
                    }}/>}
                    content='Auth as camera'
                />
            )
        }
        return authAsCameraLink
    }

    getFileListLink = (item) => {
        if (item.type === 'Windows') {
            return null
        }
        let fileListLink = null
        if (isPermitted(this.props.auth.user, 'get-images-imagelist')) {
            fileListLink = (
                <Popup
                offset={[-13, 0]}
                    trigger={
                        <Link
                            className='camera-filelist-link'
                            to={`/cameras/${item.id}/file-list`}
                        >
                            <Icon name='folder outline' link />
                        </Link>
                    }
                    content='File list'
                />
            )
        }
        return fileListLink
    }

    getCameraTags = (item) => {
        const cameraTags = item.tags
        if (!cameraTags) {
            return null
        }
        return cameraTags.map((tag, idx) => {
            return (<Popup
                key={idx}
                offset={[-13, 0]}
                trigger={<Label circular={true} empty={true} color={tag.color} className={`tags active`} />}
                content={`${tag.name} Tag`}
            />)
        })
    }

    render = () => {

        const cameras = this.props.cameras.list_view.cameras
        const numberPerPage = 20
        const numberOfPages = (this.props.cameras.list_view.total > 0) ? Math.ceil(this.props.cameras.list_view.total / numberPerPage) : 1;
        const currPage = this.props.cameras.list_view.currentPage
        const prevPage = (this.props.cameras.list_view.currentPage > 1) ? this.props.cameras.list_view.currentPage - 1: null
        const nextPage = (this.props.cameras.list_view.currentPage < numberOfPages) ? this.props.cameras.list_view.currentPage + 1: null

        return (
            <div className='camera-list-view'>
                <Table striped={true} fixed={true} compact={true} data-testid="table--cameras-list" >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Pot</Table.HeaderCell>
                            <Table.HeaderCell>Type</Table.HeaderCell>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                            <Table.HeaderCell colSpan='2'>Client</Table.HeaderCell>
                            <Table.HeaderCell colSpan='2'>Project</Table.HeaderCell>
                            <Table.HeaderCell>Tags</Table.HeaderCell>
                            <Table.HeaderCell>Version</Table.HeaderCell>
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                {
                    cameras.map((item, idx) => {

                        const cameraTags = this.getCameraTags(item)
                        const cameraVersion = item.pot_version

                        let cameraControlLink = this.getCameraControlLink(item)
                        let updateCameraLink = this.getUpdatedCameraLink(item)
                        let scheduleLink = this.getScheduleLink(item)
                        let monitoringLink = this.getMonitoringLink(item)
                        let authAsCameraLink = this.getAuthAsCameraLink(item)
                        let fileListLink = this.getFileListLink(item)

                        return (
                            <Table.Row key={idx}>
                                <Table.Cell>{item.pot_id}</Table.Cell>
                                <Table.Cell>{item.type}</Table.Cell>
                                <Table.Cell>{item.status}</Table.Cell>
                                <Table.Cell colSpan='2'>{item.clients_name}</Table.Cell>
                                <Table.Cell colSpan='2'>{item.projects_name}</Table.Cell>
                                <Table.Cell>{cameraTags}</Table.Cell>
                                <Table.Cell>{cameraVersion}</Table.Cell>
                                <Table.Cell>
                                    {cameraControlLink}
                                    {authAsCameraLink}
                                    {scheduleLink}
                                    {monitoringLink}
                                    {fileListLink}
                                    {updateCameraLink}

                                </Table.Cell>
                            </Table.Row>
                        )

                    })

                }

                    </Table.Body>
                    {(numberOfPages > 1) ?
                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan='9'>
                                        <Menu floated='right' pagination>
                                            {
                                                (prevPage) ? <Menu.Item as='a' icon onClick={() => {
                                                    this.prevPage(prevPage)
                                                }}>
                                                    <Icon name='left chevron'/>
                                                </Menu.Item> : null
                                            }
                                            {

                                                    Array(numberOfPages).fill(null).map((u, i) => {
                                                        return (<Menu.Item key={i} as='a' active={currPage === (i + 1)}
                                                                           onClick={() => {
                                                                               const page = i + 1
                                                                               this.jumpToPage(page)
                                                                           }}>{i + 1}</Menu.Item>)
                                                    })
                                            }
                                            {
                                                (nextPage) ?

                                                    <Menu.Item as='a' icon onClick={() => {
                                                        this.nextPage(nextPage)
                                                    }}>
                                                        <Icon name='right chevron'/>
                                                    </Menu.Item> : null
                                            }
                                        </Menu>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer> : null
                    }
                </Table>
            </div>
        )
    }
}

CameraListView.propTypes = {
    loading: PropTypes.bool.isRequired,
    authActions: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    messengerActions: PropTypes.object.isRequired,
    camerasActions: PropTypes.object.isRequired,
    cameras: PropTypes.object.isRequired,
    onOpenModal: PropTypes.func.isRequired,
    authAsCamera: PropTypes.func.isRequired
}

export default CameraListView
