import React, { useState, useEffect, useRef } from "react";
import DocumentTitle from 'react-document-title'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Container, Form, Message, Button, Grid } from 'semantic-ui-react'
import { getErrorMessage } from '../../util/errors'
import { checkAuth } from '../../util/accessToken'
import * as editUserActions from '../../actions/editUserActions'
import * as authActions from '../../actions/authActions'
import * as passwordActions from '../../actions/passwordActions'

const ManageAccount = (props) => {
    const [user, setUser] = useState({})
    const [userChanged, setUserChanged] = useState(false)
    const [password, setPassword] = useState({})
    const [error, setError] = useState(false)
    const firstRender = useRef(true);

    useEffect(() => {
        checkAuth(props.auth, props.authActions, props.history)
    }, [])

    useEffect(() => {
        if (props.auth.user) {
            props.editUserActions.userDetailsRequest(props.auth.user.clients_id, props.auth.user.id)
        }
        
    }, [props.auth.user])

    useEffect(() => {
        setUser(props.editUser.user);
    }, [props])

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
        } else {
            const updateLocalState = setTimeout(() => {
                props.editUserActions.updateLocalUserState(user)
            }, 500);
      
            return () => {
                clearTimeout(updateLocalState);
            };
        }
    }, [user])

    const onEmailChange = (event) => {
        const val = event.target.value
        setUser((prevState) => ({
            ...prevState,
            email: val,
            username: val
        }))
        setUserChanged(true)
    }

    const onFirstnameChange = (event) => {
        const val = event.target.value
        setUser((prevState) => ({
            ...prevState,
            first_name: val
        }))
        setUserChanged(true)
    }

    const onLastnameChange = (event) => {
        const val = event.target.value
        setUser((prevState) => ({
            ...prevState,
            last_name: val
        }))
        setUserChanged(true)
    }

    const handleOldPasswordChange = (e, {value}) => {
        setPassword((prevState) => ({
            ...prevState,
            oldPasswordField: value
        }))
    }
   

    const handlePasswordChange = (e, {value}) => {
        props.passwordActions.passwordInputChange(value, password.confirmNewPasswordField)
        setPassword((prevState) => ({
            ...prevState,
            newPasswordField: value
        }))
    }

    const handleConfirmPasswordChange = (e, {value}) => {
        props.passwordActions.comfirmPasswordInputChange(password.newPasswordField, value)
        setPassword((prevState) => ({
            ...prevState,
            confirmNewPasswordField: value
        }))
    }

    const onSubmit = () => {
        // @TODO waiting for backend work to allow password field to be submitted when email change
        // if (userChanged && password.oldPasswordField) {
        if (userChanged) {
            props.editUserActions.updateUserRequest(null, props.editUser.user.clients_id, props.editUser.user.id, props.editUser.user)
            setUserChanged(false)
        // } else if (userChanged) {
        //     console.log('show error')
        //     setError('missing-password')
        }
        if (Object.keys(password).length > 0) {
            new Promise(function(resolve, reject) {
                const validate = props.passwordActions.validateCurrentPasswordRequest(props.history, user.username, password.oldPasswordField)

                if (validate) {
                    resolve(validate)
                } else {
                    reject('failed')
                }
            }).then(() => {
                props.passwordActions.lp4PasswordUpdateSubmit(
                    password.oldPasswordField,
                    password.newPasswordField,
                    password.confirmNewPasswordField
                )
            }).catch(() => {
                console.log('error')
            })

            setPassword({
                oldPasswordField: '',
                newPasswordField: '',
                confirmNewPasswordField: '',
            });
        }
    }

    const confirmDisabled = () => {
        // @TODO waiting for backend work to allow password field to be submitted when email change
        // If email or names are changed and existing password entered = false
        // if (userChanged && password.oldPasswordField) {
        if (userChanged || (password.oldPasswordField && password.newPasswordField && password.confirmNewPasswordField && !suggestion && props.password.match)) {
            return false
        // if existing, new and confirm && these are legit - diabled = false
        // } else if (password.oldPasswordField && password.newPasswordField && password.confirmNewPasswordField && !suggestion && props.password.match) {
        //     return false
        } else {
            return true
        }
    }

    let suggestion = null
    if (props.password.suggestions.length > 0 || props.password.warning) {
        suggestion = (
            <Message color='yellow'
                icon='idea'
                header={props.password.warning}
                list={props.password.suggestions}
            />
        )
    }

    let success = null
    if (props.password.success) {
        success = (
            <Message
                positive
                icon='thumbs up outline'
                header="Success"
                content='Your password was successfully updated'
            />
        )
    }

    if (props.editUser.success) {
        success = (
            <Message
                positive
                icon='thumbs up outline'
                header="Success"
                content='User was successfully updated'
            />
        )
    }

    return (
        <>
            <DocumentTitle title='Manage Account' />
            <header className="sub-header sub-header--users">
                <Container fluid={true} style={{padding: '0 1.2em'}}>
                    <h2>Account Management</h2>
                </Container>
            </header>
            <Container className="content-container" fluid={true} style={{padding: '0 1.2em'}}>
                <Grid>
                    <Grid.Row>
                        <Grid.Column mobile={16} computer={8}>
                            <Form size='large' >
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Form.Input
                                                placeholder='E-mail address'
                                                label='E-mail address'
                                                onChange={onEmailChange}
                                                error={props.editUser.errors.email ? true : false}
                                                defaultValue={props.editUser.user.email}
                                            />
                                            {/* {userChanged && <Message size='tiny' content='Updating this field will change your login details'/>} */}
                                            <Message
                                                error={true}
                                                visible={props.editUser.errors.email}
                                                header={''}
                                                content={getErrorMessage(props.editUser.errors, 'email')}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns={2}>
                                        <Grid.Column>
                                            <Form.Input
                                                placeholder='First Name'
                                                label='First Name'
                                                error={(props.editUser.errors.first_name) ? true : false}
                                                onChange={onFirstnameChange}
                                                defaultValue={props.editUser.user.first_name}
                                            />
                                            <Message
                                                error={true}
                                                visible={(props.editUser.errors.first_name) ? true : false}
                                                header={"Error"}
                                                content={getErrorMessage(props.editUser.errors, 'first_name')}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Form.Input
                                                placeholder='Last Name'
                                                label='Last Name'
                                                onChange={onLastnameChange}
                                                error={props.editUser.errors.last_name ? true : false}
                                                defaultValue={props.editUser.user.last_name}

                                            />
                                            <Message
                                                error={true}
                                                visible={props.editUser.errors.last_name}
                                                header={''}
                                                content={getErrorMessage(props.editUser.errors, 'last_name')}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Form.Input
                                                label='Existing password *'
                                                type='password'
                                                name='existingPassword'
                                                placeholder='Your existing password'
                                                onChange={handleOldPasswordChange}
                                                value={password.oldPasswordField}
                                                error={error === 'missing-password'}
                                            />
                                            <Message
                                                error={true}
                                                visible={props.password.valdationSuccess === false && Object.keys(props.password.errors).length > 0}
                                                header={''}
                                                content={'Invalid password provided'}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns={2}>
                                        <Grid.Column>
                                            <Form.Input
                                                fluid
                                                label='New password'
                                                type='password'
                                                name='newPassword'
                                                placeholder='Your new password'
                                                onChange={handlePasswordChange}
                                                value={password.newPasswordField}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Form.Input
                                                fluid
                                                label='Confirm new password'
                                                type='password'
                                                name='newPasswordAgain'
                                                placeholder='Confirm new password'
                                                onChange={handleConfirmPasswordChange}
                                                value={password.confirmNewPasswordField}
                                            />
                                            
                                        </Grid.Column>
                                    </Grid.Row>
                                    {password.newPasswordField && <Grid.Row>
                                        <Grid.Column>
                                            <div>{suggestion}</div>
                                        </Grid.Column>
                                    </Grid.Row>}
                                    {password.newPasswordField && !props.password.match &&
                                        <Grid.Row>
                                            <Grid.Column> <p>Passwords do not match</p></Grid.Column>
                                        </Grid.Row>
                                    }
                                </Grid>
                                
                                <Button
                                style={{marginTop: '2rem'}}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        onSubmit();
                                    }}
                                    loading={props.editUser.loading}
                                    positive
                                    disabled={confirmDisabled()}
                                    icon='checkmark'
                                    labelPosition='right'
                                    content='Confirm' />
                            </Form>
                            {success}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </>
    )
}

ManageAccount.propTypes = {
    editUserActions: PropTypes.object,
    editUser: PropTypes.object,
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        editUser: state.editUser,
        password: state.password
    }
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(authActions, dispatch),
        editUserActions: bindActionCreators(editUserActions, dispatch),
        passwordActions: bindActionCreators(passwordActions, dispatch),
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageAccount))