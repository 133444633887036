import initialState from './initialState';
import {
    CLIENTS_REQUEST,
    CLIENTS_RESPONSE_ERRORS,
    CLIENTS_RESPONSE_SUCCESS,
    CLIENTS_RESET,
    CLIENTS_MFA_REQUIREMENTS_REQUEST,
    CLIENTS_MFA_REQUIREMENTS_SUCCESS,
    CLIENTS_MFA_REQUIREMENTS_ERROR,
    CLIENTS_FILTER_BY
} from '../actions/actionTypes';

export default function clients(state = initialState.clients, action) {

    switch (action.type) {
        case CLIENTS_REQUEST:
            return Object.assign({}, state, { loading: true })
        case CLIENTS_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    errors: {error: false},
                    tableData: action.tableData,
                    totalRows: action.totalRows,
                    currentPage: action.currentPage,
                    filtered: action.filtered,
                    sortColumn: action.sortColumn,
                    sortAscending: action.sortAscending,
                    searchTerm: action.searchTerm,
                    loading: false,
                }
            )
        case CLIENTS_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    errors:
                    {
                        ...action.errors
                    },
                    tableData: [],
                    totalRows: 0,
                    loading: false,
                    filtered: action.filtered
                }
            )
        case CLIENTS_RESET:
            return Object.assign({}, state, {
                errors: {error: false},
                loading: false,
                filtered: false,
                searchTerm: null,
                currentPage: 1
            })

        case CLIENTS_MFA_REQUIREMENTS_REQUEST:
            return Object.assign({}, state, { loading: true })
        case CLIENTS_MFA_REQUIREMENTS_SUCCESS:
            return Object.assign({},
                state,
                {
                    mfaRequirements: action.data,
                }
            )
        case CLIENTS_MFA_REQUIREMENTS_ERROR:
            return Object.assign({}, state, {
                errors: {
                    ...action.errors
                },
            })
        case CLIENTS_FILTER_BY:
            return Object.assign(
                {},
                state,
                {
                    searchTerm: action.searchTerm
                }
            )
        default:
            return state
    }
}
