import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Popup } from 'semantic-ui-react'

const DecreasePriority = (props) => {
    return (
        <Popup
            trigger={
                <Icon
                    name='arrow right'
                    size={props.small ? null : 'large'}
                    disabled={props.disabled}
                    style={{
                        float: !props.small ? 'right' : null,
                        color: '#ededed'
                    }}
                    link
                    onClick={(event) => {
                        event.stopPropagation()
                        props.onClick(
                            props.dayId, props.period)
                    }}
                    data-testid="icon--decrease-priority"
                />
            }
            content="Decrease the priority of this event"
            basic
        />
    )
}

export default DecreasePriority

DecreasePriority.propTypes = {
    small: PropTypes.bool,
    disabled: PropTypes.bool,
    dayId: PropTypes.number.isRequired,
    period: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired
}
