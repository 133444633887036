import initialState from './initialState'
import {
    MESSENGER_ADD_MESSAGE,
    MESSENGER_REMOVE_MESSAGE,
    MESSENGER_REMOVE_ALL_MESSAGES
} from '../actions/actionTypes'

const uniqid = require('uniqid')

export default function messenger(state = initialState.messenger, action) {
    switch (action.type) {
        case MESSENGER_ADD_MESSAGE:
            return Object.assign(
                {},
                state,
                {
                    messages: [
                        ...state.messages,
                        {
                            status: action.status,
                            title: action.title,
                            details: action.details,
                            id: uniqid()
                        }

                    ]
                }
            )
        case MESSENGER_REMOVE_MESSAGE:
            return Object.assign(
                {},
                state,
                {
                    messages: state.messages.filter((row) => {
                        if (row.id === action.id) {
                            return false
                        } else {
                            return true
                        }
                    })
                }
            )
        case MESSENGER_REMOVE_ALL_MESSAGES:
            return Object.assign(
                {},
                state,
                {
                    messages: []
                }
            )
        default:
            return state
    }
}
