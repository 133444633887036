import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
    Button,
    Modal,
    Loader,
    Dimmer
} from 'semantic-ui-react'

const AddSubscriptionModal = (props) => {
    const [isSuccess, setIsSuccess] = useState(false)

    useEffect(() => {
        setIsSuccess(props.success)
    }, [props.success]);

    const showButtons = () => {
        return (
            <>
                <Modal.Header>Add a Subscription?</Modal.Header>
                <Modal.Content>
                    <p>This will add another subscription to your invoice, charged at {props.cost}</p>
                    <div className='modalButtons' style={{'position': 'relative'}}>
                        <Button
                            //loading={props.loading}
                            onClick={props.onClose}
                            negative
                            primary
                        >Close</Button>
                       
                        {(props.loading)?
                            <Dimmer active inverted>
                                <Loader size='medium'/>
                            </Dimmer>
                        : null}
                        <Button
                            onClick={props.onSubmit}
                            //loading={props.loading}
                            positive
                            icon='checkmark'
                            labelPosition='right'
                            content='Add Subscription'
                        />
                    </div>
                </Modal.Content>
            </>
        )
    }

    const showSuccess = () => {
        return (
            <>
                <Modal.Header>Success</Modal.Header>
                <Modal.Content>
                    <p>New subscription added.</p>
                    <div className='modalButtons' style={{'position': 'relative'}}>
                        <Button
                            onClick={props.onSuccessClose}
                            positive
                            primary
                        >Close</Button>
                    </div>
                </Modal.Content>
            </>
        )
    }

    const getContent = (success) => {
        if (success) {
            return showSuccess()
        } else {
            return showButtons()
        }
    }

    return (
        <Modal
            size={"large"}
            open={props.open}
            //onOpen={onOpen}
            //onMount={onOpen}
            onClose={props.onClose}
            closeIcon
        >
            {getContent(isSuccess)}
        </Modal>
    )
}

export default AddSubscriptionModal