import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import * as usersActions from '../../actions/usersActions'
import * as authActions from '../../actions/authActions'
import * as editUserActions from '../../actions/editUserActions'
import * as addUserActions from '../../actions/addUserActions'
import * as viewClientActions from '../../actions/viewClientActions'
import * as bulkUsersActions from '../../actions/bulkUsersActions'
import * as logActions from '../../actions/logActions'
import * as messengerActions from '../../actions/messengerActions'
import * as projectsActions from '../../actions/projectsActions'
import * as addProjectActions from '../../actions/addProjectActions'
import * as updateProjectActions from '../../actions/updateProjectActions'
import * as camerasActions from '../../actions/camerasActions'
import * as ucpaActions from '../../actions/userCameraProjectAssociationActions'
import { checkAuth } from '../../util/accessToken'
import { Container, Tab } from 'semantic-ui-react'
import UserList from '../UserList'
import UserGrid from '../UserGrid'
import ProjectList from "../ProjectList";
import ProjectGrid from "../ProjectGrid";

class ViewClient extends Component {

    componentDidMount()
    {
        this.props.viewClientActions.clientDetailsRequest(
            parseInt(this.props.match.params.id, 10)
        )

        return checkAuth(this.props.auth, this.props.authActions, this.props.history)
    }

    getLogo = () => {
        if (this.props.viewClient.logo) {
            return (
                <img
                    src={this.props.viewClient.logo}
                    alt="Client Logo"
                    style={{
                        float: 'right'
                    }}
                />
            )
        }
        return null
    }

    render() {
        const panes = [
            {
                menuItem: { key: 'projects', icon: 'building outline', content: 'Projects' },
                render: () => (
                    <Tab.Pane style={{paddingTop: '2.5em'}}>
                        {this.props.auth.user && this.props.auth.user.roles_id === 5 &&  <ProjectGrid
                            {...this.props}
                            clientId={parseInt(this.props.match.params.id, 10)}
                        />}
                        {this.props.auth.user && this.props.auth.user.roles_id !== 5 &&  <ProjectList
                            {...this.props}
                            clientId={parseInt(this.props.match.params.id, 10)}
                            isClientProjectList={true}
                        />}
                    </Tab.Pane>
                )
            },{
                menuItem: { key: 'users', icon: 'users', content: 'Users' },
                render: () => (
                    <Tab.Pane style={{paddingTop: '2.5em'}}>
                        {this.props.auth.user && this.props.auth.user.roles_id === 5 && <UserGrid
                         {...this.props}
                         clientId={parseInt(this.props.match.params.id, 10)}
                     />}
                         {this.props.auth.user && this.props.auth.user.roles_id !== 5 && <UserList
                            {...this.props}
                            clientId={parseInt(this.props.match.params.id, 10)}
                        />}
                    </Tab.Pane>
                )
            }
        ]

        return (
            <div className='clients-container'>
                <header className="sub-header sub-header--clients">
                    <Container fluid={true} style={{padding: '0 1.2em'}}>
                        <h2>{!this.props.viewClient.loading && this.props.viewClient.name}</h2>
                        {this.getLogo()}
                    </Container>
                </header>
                <Container className="content-container" fluid={true} style={{padding: '0 1.2em'}}>
                    <Tab panes={panes} defaultActiveIndex={0}/>
                </Container>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        users: state.users,
        auth: state.auth,
        editUser: state.editUser,
        addUser: state.addUser,
        viewClient: state.viewClient,
        bulkUsers: state.bulkUsers,
        projects: state.projects,
        addProject: state.addProject,
        updateProject: state.updateProject,
        cameras: state.cameras,
        ucpa: state.ucpa
    }
}

function mapDispatchToProps(dispatch) {
    return {
        usersActions: bindActionCreators(usersActions, dispatch),
        authActions: bindActionCreators(authActions, dispatch),
        editUserActions: bindActionCreators(editUserActions, dispatch),
        addUserActions: bindActionCreators(addUserActions, dispatch),
        viewClientActions: bindActionCreators(viewClientActions, dispatch),
        bulkUsersActions: bindActionCreators(bulkUsersActions, dispatch),
        logActions: bindActionCreators(logActions, dispatch),
        messengerActions: bindActionCreators(messengerActions, dispatch),
        projectsActions: bindActionCreators(projectsActions, dispatch),
        addProjectActions: bindActionCreators(addProjectActions, dispatch),
        updateProjectActions: bindActionCreators(updateProjectActions, dispatch),
        camerasActions: bindActionCreators(camerasActions, dispatch),
        ucpaActions: bindActionCreators(ucpaActions, dispatch)
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewClient))
