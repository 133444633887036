import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Segment, Message, Grid } from 'semantic-ui-react'
import WifiStatus from "./WifiStatus";
import { getErrorMessage } from './../util/errors'

class WifiControl extends Component {

    getError = () => {
        if (!this.props.error) {
            return null
        }
        let message = getErrorMessage(this.props.errors, 'cameras_id')
        if (!message) {
            message = getErrorMessage(this.props.errors, 'unknownErrorType')
        }
        return (message) ? message : "There was an unexpected error"
    }

    getStartButton = () => {
        return (this.props.loading || this.props.status === 'On') ?
            <Button loading={this.props.processing && this.props.action === 'Start'}  disabled={true}>Enable</Button>
            : <Button loading={this.props.processing && this.props.action === 'Start'}  onClick={() => {
                this.props.onStartClick()
            }}>Enable</Button>
    }

    getStopButton = () => {
        return (this.props.loading || this.props.status === 'Off') ?
            <Button loading={this.props.processing && this.props.action === 'Stop'} disabled={true}>Disable</Button>
            : <Button loading={this.props.processing && this.props.action === 'Stop'} onClick={() => {
                this.props.onStopClick()
            }}>Disable</Button>
    }

    getRestartButton = () => {
        return (this.props.loading || this.props.status === 'Off') ?
            <Button loading={this.props.processing && this.props.action === 'Restart'}  disabled={true}>Restart</Button>
            : <Button loading={this.props.processing && this.props.action === 'Restart'} onClick={() => {
                this.props.onRestartClick()
            } }>Restart</Button>
    }

    getMessage = (blurb) => {
        return (this.props.error) ?
            <Message negative>
                <Message.Header>Error</Message.Header>
                <p>{blurb}</p>
            </Message> : null
    }

    getBlurb = () => {
        return (this.props.error) ? this.getError() :  null
    }

    isReady = () => {
        return !this.props.loading
    }

    render = () => {


        if (!this.isReady()) {

            return (
                <div className='wifi-control'>
                    <Segment className='wifi-control-inner-container'>
                        <h4>WiFi</h4>
                        {/*<Dimmer active inverted><Loader content='' /></Dimmer>*/}
                        <Grid columns='1'>
                            <Grid.Row>
                                <Grid.Column>
                                    <Grid.Column><div className="animated-blank-line" /></Grid.Column>
                                    <Grid.Column><div className="animated-blank-line" /></Grid.Column>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                </div>
            )

        } else {

            const startButton = this.getStartButton()
            const stopButton = this.getStopButton()
            const restartButton = this.getRestartButton()
            const message = this.getMessage(this.getBlurb())
            return (
                <div className='wifi-control'>
                    <Segment className='wifi-control-inner-container'>
                        <h4>WiFi</h4>
                        {message}
                        <div className='wifi-control-status-container'>
                        <WifiStatus
                            loading={false}
                            status={this.props.status}
                            legend={this.props.legend}
                            size={'big'}
                            color={this.props.color}/>
                        </div>
                        <div className='wifi-buttons'>
                            {startButton}
                            {stopButton}
                            {restartButton}
                        </div>
                    </Segment>
                </div>
            )

        }
    }
}

WifiControl.propTypes = {
    processing: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    status: PropTypes.string,
    legend: PropTypes.bool.isRequired,
    size: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    error: PropTypes.bool,
    success: PropTypes.bool,
    errors: PropTypes.object,
    onStartClick: PropTypes.func.isRequired,
    onStopClick: PropTypes.func.isRequired,
    onRestartClick: PropTypes.func.isRequired
}

export default WifiControl
