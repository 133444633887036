import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Button,
    Modal
} from 'semantic-ui-react'
import UpdateBulkUsers from '../UpdateBulkUsers'

class UpdateBulkUsersModal extends Component {

    constructor(props) {
        super(props)
        this.bulkEdit = false
        this.state = {
            pane: 'Settings',
            //usersToUpdate: null,
            userFields: null,
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.editUser.success === true && prevProps.editUser.success === false) {
            this.props.onClose()
        }

        this.bulkEdit = (this.props.usersToEdit && this.props.usersToEdit.length > 1) ? true : false
    }

    //componentDidUpdate(prevProps) {
        // if (this.props.userId !== prevProps.userId) {
        //     this.setState({usersToUpdate: [parseInt(this.props.userId)]})
        // } else if (this.props.usersToEdit.length && this.props.usersToEdit !== prevProps.usersToEdit) {
        //     this.setState({usersToUpdate: this.props.usersToEdit})
        //}
        
        
    //}

    onOpen = () => {
        const {clientId} = this.props
        this.props.editUserActions.resetErrors()
        this.props.ucpaActions.resetAll()
        this.props.ucpaActions.fetchProjects(clientId)
        this.props.usersToEdit.forEach((userId) => {
            this.props.editUserActions.userDetailsRequest(clientId, userId, true)
        })
    }

    fetchUserFields = (user) => {
        this.setState({userFields: user})
    }

    render = () => {
        return (
            <Modal
                size={"large"}
                open={this.props.open}
                onOpen={this.onOpen}
                onMount={this.onOpen}
                onClose={this.props.onClose}
                closeIcon
            >
                <Modal.Header>Bulk update users</Modal.Header>
                <Modal.Content>
                    <UpdateBulkUsers
                        onContextChange={(context) => {
                            this.props.editUserActions.setContext(context)
                        }}
                        loading={this.props.editUser.loading}
                        error={this.props.editUser.error}
                        errors={this.props.editUser.errors}
                        clientId={this.props.clientId}
                        userId={this.props.userId}
                        auth={this.props.auth}
                        user={this.props.editUser.user}
                        success={this.props.editUser.success}
                        projects={this.props.ucpa.projects}
                        cameras={this.props.ucpa.cameras}
                        isBulkEdit={this.bulkEdit}
                        onLoadProjectCameras={(projectId) => {
                            this.props.ucpaActions.fetchProjectCameras(this.props.clientId, projectId)
                        }}
                        onResetProjectCameras={this.props.ucpaActions.resetProjectCameras}
                        user_cameras={this.props.ucpa.user_cameras}
                        user_projects={this.props.ucpa.user_projects}
                        add_user_cameras={this.props.ucpa.add_user_cameras}
                        remove_user_cameras={this.props.ucpa.remove_user_cameras}
                        add_user_projects={this.props.ucpa.add_user_projects}
                        remove_user_projects={this.props.ucpa.remove_user_projects}
                        project_camera_weights={this.props.ucpa.project_camera_weights}
                        unassociate_project_with_user={this.props.ucpa.unassociate_project_with_user}
                        unassociate_camera_with_user={this.props.ucpa.unassociate_camera_with_user}
                        associate_project_with_user={this.props.ucpa.associate_project_with_user}
                        associate_camera_with_user={this.props.ucpa.associate_camera_with_user}
                        ucpaActions={this.props.ucpaActions}
                        resetUnassociateProjectWithUser={() => {
                            this.props.ucpaActions.resetUnassociateProjectWithUser()
                        }}
                        resetUnassociateCameraWithUser={() => {
                            this.props.ucpaActions.resetUnassociateCameraWithUser()
                        }}
                        resetAssociateProjectWithUser={() => {
                            this.props.ucpaActions.resetAssociateProjectWithUser()
                        }}
                        resetAssociateCameraWithUser={() => {
                            this.props.ucpaActions.resetAssociateCameraWithUser()
                        }}
                        ucpa_processing={this.props.ucpa.processing}
                        onAddUserProjects={(projects) => {
                            this.props.ucpaActions.addUserProjects(projects)
                        }}
                        onRemoveProjects={(projects) => {
                            this.props.ucpaActions.removeUserProjects(projects)
                        }}
                        onAddUserCameras={(cameras) => {
                            this.props.ucpaActions.addUserCameras(cameras)
                        }}
                        onRemoveCameras={(cameras) => {
                            this.props.ucpaActions.removeUserCameras(cameras)
                        }}
                        onUpdateProjectCameraWeights={(projectCameraWeights) => {
                            this.props.ucpaActions.updateProjectCameraWeights(projectCameraWeights)
                        }}
                        onUpdateLocalUserState={(details) => {
                            this.props.editUserActions.updateLocalUserState(details)
                        }}
                        fetchUserFields={this.fetchUserFields}
                    />
                    <div className='modalButtons'>
                        {this.getButtons(this.props.editUser.error, this.props.editUser.success)}
                    </div>
                </Modal.Content>
            </Modal>
        )
    }

    getButtons = () => {
        return <div>
            <Button
                onClick={() => {
                    const users = this.props.editUser.bulkEditUsers
                    const pane = this.props.editUser.context
                    users.forEach((bulkUser, index) => {
                        let details = this.state.userFields
                        details.id = bulkUser.id
                        details.clients_id = this.props.clientId
                        this.handleUpdateUserConfirmedClick(this.props.clientId, bulkUser.id, details, pane, true, index)
                    })
                }}
                loading={this.props.editUser.loading}
                positive
                icon='checkmark'
                labelPosition='right'
                content='Confirm' />
        </div>
    }

    onContextChange = (context) => {
        this.props.editUser.setContext(context)
    }

    handleUpdateUserConfirmedClick = (clientId, userId, args, context, isBulk, index) => {
        if (context === 'UserProjects') {
            this.props.ucpaActions.clearSuccessAndErrorMessages()

            // Could trigger a global loading state here....to avoid continuous re-rendering inside modal
            this.props.ucpaActions.processing(true)

                const addUserProjects = this.props.ucpa.add_user_projects.map((project) => {
                    const projectId = project.id
                    return this.props.ucpaActions.associateProjectWithUser(clientId, projectId, userId, isBulk)
                })

                Promise.all([...addUserProjects]).then(() => {
                    Promise.all(
                        [
                            this.props.ucpaActions.fetchProjects(clientId),
                            this.props.ucpaActions.fetchUserProjects(clientId, userId)
                        ]).then(() => {
                        // Could unset the loading state here...
                        this.props.ucpaActions.processing(false)
                        if (index < 1) {
                            this.props.onClose()
                        }
                    })
                })
        } else {
            this.props.editUserActions.updateUserRequest(null, clientId, userId, args)
            let searchField = (this.props.user.searchField)? this.props.user.searchField : null
            this.props.usersActions.userListRequest(
                this.rowsPerPage,
                this.currentPage,
                this.props.users.sortColumn,
                this.props.users.sortAscending,
                this.props.users.searchTerm,
                this.props.clientId,
                searchField
            )
        }
    }
}

UpdateBulkUsersModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    editUser: PropTypes.object.isRequired,
    editUserActions: PropTypes.object.isRequired,
    ucpa: PropTypes.object.isRequired,
    ucpaActions: PropTypes.object.isRequired,
    clients: PropTypes.array.isRequired,
    roles: PropTypes.array.isRequired,
    statuses: PropTypes.array.isRequired,
    clientId: PropTypes.any,
    userId: PropTypes.any
}

export default UpdateBulkUsersModal
