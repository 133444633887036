import React, { Component } from 'react'

import DocumentTitle from 'react-document-title'
import {
    Input,
    Menu,
    Table,
    TableRow,
    TableCell,
    Button,
    Modal
} from 'semantic-ui-react'
import moment from 'moment';
import TableColumn from '../TableColumn'
import pagination from '../../util/pagination'

class PotAssign extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showModal: false,
            linkPCID: undefined,
            currentPot: undefined,
            newPot: undefined,
            nextPot: undefined,
            currentPage: 1,
            displayRows: [],
            sortColumn: undefined,
            sortAscending: true,
            filter: undefined
        }
        this.rowsPerPage = 15
        this.columns = [
            new TableColumn("pcid", "PCID", true),
            new TableColumn("time", "Created", true),
            new TableColumn("link", "Lobster Pot", true),
            new TableColumn("actions", "Actions", false)
        ]
    }

    componentDidMount() {
        this.updateTable(this.props)
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.potAssign.success  && !prevProps.potAssign.success) {
            this.updateTable(this.props)
        }
    }

    updateTable = (props) => {
        const pcids = props.potAssign.pcids.slice(0)
        let filtered
        if (this.state.filter === undefined) {
            filtered = pcids
        } else {
            filtered = pcids.filter(
                item => {
                    if (item.pcid !== null) {
                        if (item.pcid.indexOf(this.state.filter) > -1) {
                            return true
                        }
                    }
                    if (item.link !== null) {
                        if (item.link.indexOf(this.state.filter) > -1) {
                            return true
                        }
                    }
                    return false
                }
            )
        }
        let sorted
        if (this.state.sortColumn === undefined) {
            sorted = filtered
        } else {
            sorted = filtered.sort(
                (a,b) => {
                    // Handle nulls
                    let a2 = a[this.state.sortColumn]
                    let b2 = b[this.state.sortColumn]
                    if (a2 === null) { a2 = "" }
                    if (b2 === null) { b2 = "" }
                    const s = a2.localeCompare(b2)
                    if (this.state.sortAscending) {
                        return s
                    } else {
                        return s * -1
                    }
                }
            )
        }
        const start = (this.state.currentPage-1)* this.rowsPerPage
        const end = start + this.rowsPerPage
        const displayRows = sorted.slice(start, end)
        this.setState({
            displayRows: displayRows
        })
    }

    handleFilterChange = (e) => {
        let filter
        if (e.target.value.length > 1) {
            filter = e.target.value
        } else {
            filter = undefined
        }
        this.setState({
            filter: filter,
            currentPage: 1
        }, () => {
            this.updateTable(this.props)
        })
    }

    handleColumnHeadingClick = (key) => {
        let ascending = this.state.sortColumn === key ? !this.state.sortAscending : true
        this.setState({
            sortColumn: key,
            sortAscending: ascending
        }, () => {
            this.updateTable(this.props)
        })
    }

    handlePaginationPrevClick = () => {
        if (this.state.currentPage === 1) {
            return
        }
        const newPage = this.state.currentPage - 1
        this.setState({currentPage: newPage}, () => {
            this.updateTable(this.props)
        })
    }

    handlePaginationNextClick = () => {
        const totalRows = this.props.potAssign.pcids.length
        const totalPages = Math.ceil(totalRows/this.rowsPerPage)
        if (this.state.currentPage === totalPages) {
            return
        }
        const newPage = this.state.currentPage + 1
        this.setState({currentPage: newPage}, () => {
            this.updateTable(this.props)
        })
    }

    handlePaginationPageClick = (e, { name }) => {
        const newPage = parseInt(name, 10)
        this.setState({currentPage: newPage}, () => {
            this.updateTable(this.props)
        })
    }

    formatDate = (date) => {
        return moment(date, 'YYYY-MM-DD HH:mm:ss ZZ').format('MMMM Do YYYY, HH:mm:ss')
    }

    /** Render the table header row using this.columns */
    renderHeader() {
        return (
            <TableRow>
                {this.columns.map((column, k) => {
                    let sortedBy = null
                    if (this.state.sortColumn === column.key) {
                        sortedBy = this.state.sortAscending ? "ascending" : "descending"
                    }
                    let click = null
                    if (column.sortable) {
                        click = (e) => {this.handleColumnHeadingClick(column.key)}
                    }
                    return (
                        <Table.HeaderCell sorted={sortedBy} onClick={click} key={k}>
                            {column.title}
                        </Table.HeaderCell>
                    )
                })}
            </TableRow>
        )
    }

    /**
     * Render a single table row of results
     *
     * @param {Object} row - An object containing one row of results
     * @param {number} index - The current row number being rendered
     */
    renderBodyRow = (row, index) => {
        return (
            <TableRow key={row.pcid}>
                <TableCell>
                <Button
                    compact
                    onClick={() => {
                        this.props.potAssignActions.identify(row.pcid)
                    }}
                    loading={this.props.potAssign.identifying === row.pcid}
                >Identify</Button>
                    {row.pcid}
                </TableCell>
                <TableCell>{this.formatDate(row.time)}</TableCell>
                <TableCell>
                    {row.link}
                </TableCell>
                <TableCell>
                    <Button
                        compact
                        color="blue"
                        onClick={()=>{this.linkPot(row.pcid, row.link)}}
                    >
                        {row.link === null ? "Link" : "Relink"}
                    </Button>
                </TableCell>
            </TableRow>
        )
    }

    /** Render the table footer row */
    renderFooter() {
        if (this.props.potAssign.loading) {
            return (
                <TableRow>
                    <TableCell colSpan={this.columns.length}>
                        Loading...
                    </TableCell>
                </TableRow>
            )
        }
        if (this.props.potAssign.pcids.length === 0) {
            return (
                <TableRow>
                    <TableCell colSpan={this.columns.length}>
                        No results found
                    </TableCell>
                </TableRow>
            )
        }
        const totalRows = this.props.potAssign.pcids.length
        const totalPages = Math.ceil(totalRows/this.rowsPerPage)
        const first = ((this.state.currentPage - 1) * this.rowsPerPage) + 1
        let last = this.state.currentPage * this.rowsPerPage
        if (last > totalRows) last = totalRows
        let pages = pagination(this.state.currentPage, totalPages)
        let menuPagination = (
            <Menu pagination>
                <Menu.Item
                    name="prev"
                    onClick={this.handlePaginationPrevClick}
                    icon="angle left"
                />
                {pages.map((page, key) => {
                    return (
                        <Menu.Item
                            name={page.toString()}
                            key={key}
                            active={this.state.currentPage === page}
                            onClick={this.handlePaginationPageClick}
                        />
                    )
                })}
                <Menu.Item
                    name="next"
                    onClick={this.handlePaginationNextClick}
                    icon="angle right"
                />
            </Menu>
        )
        return (
            <TableRow>
                <TableCell colSpan={this.columns.length}>
                    <div>Showing {first} - {last} of {totalRows}
                        {totalRows === 1 ? "result" : "results"}
                    </div>
                    <div style={{textAlign: 'center'}}>
                        {totalPages > 1 ? menuPagination : null}
                    </div>
                </TableCell>
            </TableRow>
        )
    }

    linkPot = (pcid, pot) => {
        let newPot
        if (pot) {
            newPot = pot
        } else {
            newPot = "lob-pot-"
        }
        this.setState({
            showModal: true,
            linkPCID: pcid,
            currentPot: pot,
            newPot: newPot
        })
    }

    setNextPot = (pot) => {
        this.setState({
            nextPot: pot,
            newPot: "lob-pot-"+pot
        })
    }

    handleNewPotChange = (event, {value}) => {
        this.setState({newPot: value})
    }

    closeModal = () => {
        this.setState({
            showModal: false,
            nextPot: undefined
        })
    }

    getNextPotButton = () => {
        return (
            <Button
                onClick={() => {
                    this.props.potAssignActions.nextPotId(this.setNextPot)
                }}
                loading={this.props.potAssign.gettingNextPot}
                disabled={this.state.nextPot !== undefined}
            >
            Next Pot ID
            </Button>
        )
    }

    getLinkModal = () => {
        let showNextPotButton = false
        if (!this.state.currentPot) {
            showNextPotButton = true
        }
        return (
            <Modal
                open={this.state.showModal}
                onClose={this.closeModal}
            >
                <Modal.Header>
                    Link Pot
                </Modal.Header>
                <Modal.Content>
                    <p>Please enter a Lobster Pot ID</p>
                    <Input
                        type = "text"
                        value={this.state.newPot}
                        onChange={this.handleNewPotChange}
                    />
                    {showNextPotButton ? this.getNextPotButton() : null}
                    <div className='modalButtons'>
                    <Button
                        onClick={this.closeModal}
                        negative
                        content='Cancel'
                    />
                    <Button
                        positive
                        icon='checkmark'
                        labelPosition='right'
                        content='Link'
                        loading={this.props.potAssignActions.assigining}
                        onClick={() => {
                            this.props.potAssignActions.assign(
                                this.state.linkPCID,
                                this.state.newPot,
                                this.closeModal
                            )
                        }}
                    />
                    </div>
                </Modal.Content>
            </Modal>
        )
    }

    render() {
        return (
            <div>
                <DocumentTitle title='Pot Assign' />
                <h2>Pot Assign</h2>
                {this.getLinkModal()}
                <input
                    type="text"
                    placeholder="Filter"
                    onChange={this.handleFilterChange}
                />
                <Table
                    renderBodyRow={this.renderBodyRow}
                    headerRow={this.renderHeader()}
                    tableData={this.state.displayRows}
                    footerRow={this.renderFooter()}
                    data-testid="table--pot-assign"
                    sortable
                >
                </Table>
            </div>
        )
    }
}

export default PotAssign
