import React, { Component } from 'react'
import {
    Divider,
    Form,
    Message,
    Segment
} from 'semantic-ui-react'
import moment from 'moment'
import { API_BASE_URL}  from '../config'
import { isPermitted } from '../util/acl'

class CameraDrop extends Component {

    constructor(props) {
        super(props)
        this.state = {
            filter: null,
            limit: 100
        }
    }

    componentDidMount() {
        this.props.dropActions.load(this.props.cameraId, null, this.state.limit)
    }

    updateFilter = (event, {value}) => {
        this.setState({filter: value})
    }

    updateLimit = (event, {value}) => {
        this.setState({limit: value})
    }

    loadDrop = (event) => {
        this.props.dropActions.load(this.props.cameraId,
                                    this.state.filter, this.state.limit)
    }

    getDropCount = () => {
        if (this.props.drop.loading) {
            return null
        }
        if (this.props.drop.files > 0) {
            return (<Message>{this.props.drop.files} files loaded.</Message>)
        } else {
            return (<Message>No files loaded.</Message>)
        }
    }

    openImage = (filename) => {
        const token = localStorage['accessToken']
        let url = `${API_BASE_URL}/api/cameras/${this.props.cameraId}/`
                +`camera-control/drop/image/${filename}?access_token=${token}`
        var win = window.open(url, '_blank');
        win.focus();
    }

    filenameToDateTime = (filename) => {
        const date = moment(filename, "YYMMDDHHmmss")
        return date.format('llll')
    }

    render() {
        const limitOptions = [
          { key: 'twenty', text: '20', value: 20 },
          { key: 'fifty', text: '50', value: 50 },
          { key: 'hunded', text: '100', value: 100 },
          { key: 'fivehundred', text: '500', value: 500 },
          { key: 'thousand', text: '1,000', value: 1000 },
          { key: 'twothousand', text: '2,000', value: 2000 },
          { key: 'all', text: 'All', value: null }
        ]
        return (
            <div>
                <h4>Drop</h4>
                <Segment>
                    <Form>
                        <Form.Group>
                            <Form.Input
                                width={8}
                                fluid
                                label='Search'
                                placeholder='prefix'
                                onChange={this.updateFilter}
                            />
                            <Form.Select
                                width={3}
                                value={this.state.limit}
                                fluid
                                label='Limit'
                                options={limitOptions}
                                onChange={this.updateLimit}
                            />
                            <Form.Button
                                style={{marginTop: '1.7em'}}
                                loading={this.props.drop.loading}
                                onClick={this.loadDrop}
                            >
                                {this.props.drop.success ? 'Reload' : 'Load'}
                            </Form.Button>
                        </Form.Group>
                    </Form>
                    <Divider />
                    {this.getDropCount()}
                    {this.props.drop.drop.map((file, key) => {
                        let onClick = null
                        let style = null
                        if (isPermitted(this.props.auth.user,
                                        'get-cameradrop-image'))
                        {
                            onClick=() => this.openImage(file)
                            style={cursor: "pointer"}
                        }
                        return (
                            <div key={key}>
                                <a onClick={onClick} style={style}>{file}</a>
                                <span style={{paddingLeft: '2em'}} className="datetime">
                                    {this.filenameToDateTime(file)}
                                </span>
                            </div>
                        )
                    })}
                </Segment>
            </div>
        )
    }
}

export default CameraDrop
