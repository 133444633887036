import React from 'react'

const TemperatureUnit = (props) => {

    const handleChange = (event) => {
        props.onChange(event, event.target.value)
    }

    return (
        <div>
            <h4>Temperature Units</h4>
            <label
                style={{
                    fontSize: "0.88em"
                }}
            >
                <input
                    style={{
                        marginRight: "5px"
                    }}
                    type="radio"
                    name='temperature_unit'
                    value='Celsius'
                    checked={props.checked === 'Celsius'}
                    onChange={handleChange}
                    disabled={props.disabled}
                />
                Celsius
            </label>
            <label
                style={{
                    fontSize: "0.88em",
                }}
            >
                <input
                    style={{
                        marginLeft: "10px",
                        marginRight: "5px"
                    }}
                    type="radio"
                    name='temperature_unit'
                    value='Fahrenheit'
                    checked={props.checked === 'Fahrenheit'}
                    onChange={handleChange}
                    disabled={props.disabled}
                />
                Fahrenheit
            </label>
        </div>
    )
}

export default TemperatureUnit;