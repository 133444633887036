import * as types from './actionTypes'
import { addMessage } from './messengerActions'
import { log } from './logActions'
import { API_BASE_URL }  from '../config'
import { notAuthorisedResponse } from './authActions'
import { fetchWithToken } from '../util/accessToken'
import moment from 'moment';

export function fileListResponse(cameraId, data) {
    if (data.totalItems) {
        return {
            type: types.FILE_LIST_RESPONSE_SUCCESS,
            totalRows: data.totalItems,
            tableData: data.files,
            potId: data.potId,
            minDate: data.minDate,
            maxDate: data.maxDate
        }
    } else {
        return {
            type: types.FILE_LIST_RESPONSE_SUCCESS,
            totalRows: 0,
            tableData: [],
            potId: data.potId,
            minDate: data.minDate,
            maxDate: data.maxDate
        }
    }
}

export function fileListRequest(cameraId, page = null) {
    return (dispatch, getState) => {
        // Set loading state for fidget spinners
        dispatch( { type: types.FILE_LIST_REQUEST, files: { loading: true } })
        const state = getState()
        const currentPage = page === null ? state.fileList.currentPage : page
        const pageSize = state.fileList.pageSize
        let start = state.fileList.start
        let end = state.fileList.end
        const ascending = state.fileList.ascending

        if (start !== null && typeof start === 'object') {
            start = moment(start).format('Y-MM-DD')
        }
        if (end !== null && typeof end === 'object') {
            end = moment(end).format('Y-MM-DD')
        }

        // Build query string from paramters
        let pagination = `pageSize=${pageSize}&currentPage=${currentPage}`
        let sorting = ascending === true ? "" : `&ascending=true`
        let startDate = start !== undefined && start !== null ? `&start=${start}` : ""
        let endDate = end !== undefined && end !== null ? `&end=${end}` : ""

        const url = `${API_BASE_URL}/api/cameras/${cameraId}/images?${pagination}${sorting}${startDate}${endDate}`

        // Perfrom request
        return fetchWithToken(
            url,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    // Remember, we're bouncing out 401s
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.FILE_LIST_RESPONSE_ERRORS,
                                    errors: data.errors,
                                })
                        } else {
                            // Error
                            dispatch(
                                {
                                    type: types.FILE_LIST_RESPONSE_ERRORS,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error"
                                        }
                                    }
                                }
                            )
                        }

                    })
                }
            } else {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.FILE_LIST_RESPONSE_ERRORS,
                                errors: data.errors,
                            })
                    } else {
                        dispatch(fileListResponse(cameraId, data))
                    }

                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function deleteImages(cameraId, filenames, force = false, completedCallback = null) {
    return (dispatch, getState) => {
        dispatch({
            type: types.FILE_LIST_DELETE_REQUEST
        })
        let url = `${API_BASE_URL}/api/cameras/${cameraId}/images`
        if (force) {
            url += '?force=true'
        }
        return fetchWithToken(
            url,
            {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({filenames: filenames})
            },
            dispatch,
            getState
        )
        .then(response => {
            const state = getState()
            let currentPage = state.fileList.currentPage
            const pageSize = state.fileList.pageSize
            const totalRows = state.fileList.totalRows
            let success = false
            if (!response.ok) {
                if (response.status === 401) {
                    // Remember, we're bouncing out 401s
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.message) {
                            dispatch({
                                type: types.FILE_LIST_DELETE_ERRORS,
                                errors: data.message,
                            })
                            dispatch(
                                addMessage(
                                    "negative",
                                    "Unable to delete images",
                                    data.message
                                )
                            )
                        } else {
                            // Error
                            dispatch({
                                type: types.FILE_LIST_DELETE_ERRORS,
                                errors: {
                                    unknownErrorType: {
                                        unknownError: "There was an unexpected error"
                                    }
                                }
                            })
                            dispatch(
                                addMessage(
                                    "negative",
                                    "Unable to delete images",
                                    "There was an unexpected error"
                                )
                            )
                        }

                    })
                }
            } else {
                if (response.status === 204) {
                    dispatch(
                        {
                            type: types.FILE_LIST_DELETE_SUCCESS
                        }
                    )
                    success = true
                } else {
                    response.json().then(data => {
                        if (data.message) {
                            dispatch(
                                {
                                    type: types.FILE_LIST_DELETE_ERRORS,
                                    errors: data.message,
                                }
                            )
                            dispatch(
                                addMessage(
                                    "negative",
                                    "Unable to delete images",
                                    data.message
                                )
                            )
                        }

                    })
                }
            }
            // Jump back a page if deletion would cause the current page to
            // have no results
            if ((currentPage - 1) * pageSize >= (totalRows - filenames.length)) {
                currentPage = currentPage - 1
                if (currentPage < 1) {
                    currentPage = 1
                }
            }
            // Reload list after deletion
            dispatch(fileListRequest(cameraId, currentPage))
            // Dismiss delete modal
            if (completedCallback !== null) {
                completedCallback(success)
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
            if (completedCallback !== null) {
                completedCallback()
            }
        })
    }
}

export function setPage(cameraId, page) {
    return (dispatch, getState) => {
        dispatch({
            type: types.FILE_LIST_SET_PAGE,
            page: page
        })
        const state = getState()
        const pageSize = state.fileList.pageSize
        const start = state.fileList.start
        const end = state.fileList.end
        const ascending = state.fileList.ascending
        dispatch(
            fileListRequest(cameraId, page, pageSize, start, end, ascending)
        )
    }
}

export function setStartDate(cameraId, date) {
    return (dispatch, getState) => {
        dispatch({
            type: types.FILE_LIST_SET_START_DATE,
            start: date
        })
        const state = getState()
        const currentPage = state.fileList.currentPage
        const pageSize = state.fileList.pageSize
        const end = state.fileList.end
        const ascending = state.fileList.ascending
        dispatch(
            fileListRequest(cameraId, currentPage, pageSize, date, end, ascending)
        )
    }
}

export function setEndDate(cameraId, date) {
    return (dispatch, getState) => {
        dispatch({
            type: types.FILE_LIST_SET_END_DATE,
            end: date
        })
        const state = getState()
        const currentPage = state.fileList.currentPage
        const pageSize = state.fileList.pageSize
        const start = state.fileList.start
        const ascending = state.fileList.ascending
        dispatch(
            fileListRequest(cameraId, currentPage, pageSize, start, date, ascending)
        )
    }
}

export function reset() {
    return { type: types.FILE_LIST_RESET }
}
