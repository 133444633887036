import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Segment, Popup, Button } from 'semantic-ui-react'
import ScheduleSegmentV4 from './ScheduleSegmentV4'
import ScheduleSegmentMobileV4 from './ScheduleSegmentMobileV4'

class ScheduleDayV4 extends Component {
    constructor(props) {
        super(props)
        this.columns = []
        this.sortedSchedule = null
    }

    generateColumns = () => {
        this.columns = []

        // If thre's no schedule for this day, do nothing
        if (!this.props.schedule) {
            return
        }

        this.sortedSchedule = this.props.schedule.sort((a, b) => parseInt(a.start) - parseInt(b.start));

        this.sortedSchedule.forEach((period, i) => {
            period.periodIndex = i
            if (this.columns.length ===0) {
                // Nothing in any column yet, so add this to first column
                const column = [period]
                this.columns.push(column)
            } else {
                // Find a suitable home, respecting priorities
                let targetColumn = this.columns.length
                for (var c = this.columns.length-1; c >= 0; c--) {
                    const column = this.columns[c]
                    let clash = false
                    for (var p = 0; p < column.length; p++) {
                        const periodCompare = column[p]
                        if (periodCompare.start < period.stop && periodCompare.stop > period.start) {
                            clash = true
                            break
                        }
                    }
                    if (!clash) {
                        targetColumn = c
                    } else {
                        break
                    }
                }
                if (targetColumn === this.columns.length) {
                    // No suitable existing column found, create a new one
                    const column = [period]
                    this.columns.push(column)
                } else {
                    // Add to existing column
                    let column = this.columns[targetColumn]
                    if (column.start > period.stop) {
                        column.start = period.start
                        column.unshift(period)
                    } else {
                        column.stop = period.stop
                        column.push(period)
                    }
                }
            }
        })
    }


    render() {

        this.generateColumns()

        const tickLabelStyle = {
            fontSize: '0.7em',
            // position: 'absolute',
            // top: '1.3em',
            // left: '-3em'
        }

        const hours = Array(25).fill().map((a,h) => {
            let hour
            if (h < 10) {
                hour = "0"+h
            } else {
                hour = h
            }
            return (
                <div
                    key={`${this.props.dayName}_hour_${hour}`}
                    className='schedule__hour-marker'
                >
                  <p style={tickLabelStyle}>{hour}:00</p>
                </div>
            )
        })

        const dayWrapStyles = (!this.props.isMobile) ? {marginBottom: '1.07142857em'} : {marginBottom: '2.14em'};
        const columnWrapStyles = (!this.props.isMobile) ? {display: 'flex', height: '75px', paddingTop: '0.714em'} : {paddingTop: '0.714em'};
        
        return (
            <div className='schedule__day-wrap'
                style={dayWrapStyles}
            >
                <Segment attached='top' style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '0.3em 1em',
                }}>
                    <div style={{display: 'flex', alignItems: 'center',}}>
                        <h3 style={{margin: '0'}}>{this.props.dayName}</h3>
                        <Button
                                icon={'add circle'}
                                size='medium'
                                circular
                                basic
                                style={{boxShadow: 'none', fontSize: '1.42em', padding: '0.4em', margin: 0}}
                                onClick={() => this.props.actions.openAddPeriodModal(this.props.dayId)}
                            />
                    </div>
                    <div style={{}}>
                        <Popup
                            trigger={
                                <Button
                                    circular
                                    color='red'
                                    icon='trash alternate'
                                    size='small'
                                    onClick={() => {this.props.actions.clearDay(this.props.dayId)}}
                                />
                            }
                            content="Clear the current day"
                            position='top center'
                            basic
                        />
                        {this.props.dayName !== 'Sunday' ?
                            <Popup
                                trigger={
                                    <Button
                                        circular
                                        color='blue'
                                        icon='copy outline'
                                        size='small'
                                        onClick={() => {this.props.actions.copyToNextDay(this.props.dayId)}}
                                    />
                                }
                                content="Copy schedule to the next day"
                                position='top right'
                                basic
                            /> : null
                        }
                    </div>
                </Segment>
                <Segment
                    attached
                    className={this.props.loading ? 'loading' : null}
                    style={{padding: 0}}
                >
                    {this.props.isMobile && <>
                        {this.columns.map((column, c) => {
                            return column.map((period, i) => { 
                                let isSleepSegment = period.actions[0].json_class === 'SchedulerActionSleep'
                                const segment = !isSleepSegment && <ScheduleSegmentMobileV4
                                    key={`segment_mobile_${this.props.dayId}_${c}_${i}`}
                                    dayId={this.props.dayId}
                                    dayName={this.props.dayName}
                                    column={c}
                                    totalColumns={this.columns.length}
                                    period={period}
                                    actions={this.props.actions}
                                    isMobile={this.props.isMobile}
                                />
                                return segment
                            })
                        })}
                    </>}
                    {!this.props.isMobile && <>
                        <div  style={columnWrapStyles}>
                            {hours}
                        </div>
                        {this.columns.map((column, c) => {
                            return column.map((period, i) => { 
                                let isSleepSegment = period.actions[0].json_class === 'SchedulerActionSleep'
                                const segment = <ScheduleSegmentV4
                                    isSleep={isSleepSegment}
                                    key={`segment_${this.props.dayId}_${c}_${i}`}
                                    dayId={this.props.dayId}
                                    dayName={this.props.dayName}
                                    column={c}
                                    totalColumns={this.columns.length}
                                    period={period}
                                    actions={this.props.actions}
                                    isMobile={this.props.isMobile}
                                />
                                return segment
                            })
                        })}
                    </>}
                </Segment>
            </div>
        )
    }
}

ScheduleDayV4.propTypes = {
    actions: PropTypes.object.isRequired,
    dayId: PropTypes.number.isRequired,
    dayName: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    schedule: PropTypes.array
}
export default ScheduleDayV4
