import * as types from './actionTypes'
import flatten from '../util/flattenObject'
import {API_BASE_URL} from '../config.js'
import { addMessage } from './messengerActions'
import { log } from './logActions'
import { fetchWithToken } from '../util/accessToken'
import { notAuthorisedResponse } from './authActions'

export const CHANGE = 'change'
export const RESET = 'reset'
export const VERIFY = 'verify'

const MIN_PASSWORD_LENGTH = 8

export function passwordInputChange(password, confirmPassword) {
    // Check strength
    let warning = undefined
    let suggestions = []
    if (password.length < MIN_PASSWORD_LENGTH) {
        warning = "Password too short"
        suggestions.push(`At least ${MIN_PASSWORD_LENGTH} characters are required`)
    }
    const match = password === confirmPassword ? true : false
    return {
        type: types.PASSWORD_INPUT_CHANGE,
        match: match,
        strength: undefined,
        warning: warning,
        suggestions: suggestions
    }
}

export function comfirmPasswordInputChange(password, confirmPassword) {
    // Check passwords match
    const match = password === confirmPassword ? true : false
    return {
        type: types.COMFIRM_PASSWORD_INPUT_CHANGE,
        match: match
    }
}

export function passwordChangeSubmit(password, confirmPassword, reason, hash = null, token = null, privacyPolicyAccepted = false) {
    return (dispatch, getState) => {
        // Check passwords match
        if (password !== confirmPassword) {
            const errors = ["Passwords do not match"]
            dispatch(passwordChangeError(errors))
        }
        // Check password length
        if (password.length < MIN_PASSWORD_LENGTH) {
            const errors = [`Password must be at least ${MIN_PASSWORD_LENGTH} characters`]
            dispatch(passwordChangeError(errors))
        }
        let path = null
        switch (reason) {
            case RESET:
                // A password reset has been requested for this user
                if (!token || !hash) {
                    const errors = ["One or more of the verification parameters are missing"]
                    dispatch(passwordChangeError(errors))
                    return
                }
                path = "/forgot-password/reset"
                break
            case VERIFY:
                // The user is setting their password for the first time
                if (!token || !hash) {
                    const errors = ["One or more of the verification parameters are missing"]
                    dispatch(passwordChangeError(errors))
                    return
                }
                path = "/api/users/verify"
                break
            default:
                return
        }
        if (path) {
            return fetchWithToken(`${API_BASE_URL}${path}`,
                {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept': 'application/json'
                    },
                    body : `password=${password}&hash=${hash}&token=${token}&privacyPolicyAccepted=${privacyPolicyAccepted}`
                },
                dispatch,
                getState
            )
            .then(response => {
                if (response.status === 401) {
                    dispatch(notAuthorisedResponse())
                } else {
                    return response.json()
                }
            })
            .then((json) => {
                if (json.errors) {
                    if (Array.isArray(json.errors)) {
                        dispatch(passwordChangeError(json.errors))
                    } else {
                        // If we get an object back, transform it to an array
                        var errors = [];
                        for(var o in json.errors) {
                            errors.push(json.errors[o]);
                        }
                        dispatch(passwordChangeError(errors))
                    }
                } else {
                    dispatch(passwordChangeSuccess(json))
                }
            })
            .catch((response) => {
                const errors = ["A problem occured"]
                dispatch(passwordChangeError(errors))
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(log("error", response))
            })
        }
    }
}

export function passwordChangeSuccess() {
    return dispatch => {
        // Confirm password changed. Click confirm to be redirected to login screen
        const message = "Your password has been successfuly updated. Please click continue and log in"
        dispatch({ type: types.PASSWORD_CHANGE_SUCCESS, success: true, message: message})
    }
}

export function passwordChangeError(errors) {
    // Signal error as to why password could not be set
    return { type: types.PASSWORD_CHANGE_ERROR, errors: errors }
}

/*
* Function to solely handle the LP4 user password update
*/
export function lp4PasswordUpdateSubmit(password, newPassword, confirmNewPassword) {
    return (dispatch, getState) => {

        const url = `${API_BASE_URL}/api/change-password`
        return fetchWithToken(
            url,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    'password' : password,
                    'new_password': newPassword,
                    'new_password_copy' : confirmNewPassword,
                }),
            },
            dispatch,
            getState
        )
        .then(response => {
            if (response.status === 401) {
                dispatch(notAuthorisedResponse())
            } else {
                return response.json()
            }
        })
        .then((json) => {
            if (json.errors) {
                if (Array.isArray(json.errors)) {
                    dispatch(lp4PasswordUpdateError(json.errors))
                } else {
                    // If we get an object back, transform it to an array
                    var errors = [];
                    for(var o in json.errors) {
                        errors.push(json.errors[o]);
                    }
                    dispatch(lp4PasswordUpdateError(errors))
                }
            } else {
                dispatch(lp4PasswordUpdateSuccess(json))
            }
        })
        .catch((response) => {
            const errors = ["A problem occured"]
            dispatch(lp4PasswordUpdateError(errors))
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function lp4PasswordUpdateSuccess() {
    return dispatch => {
        // Confirm password changed. Click confirm to be redirected to login screen
        const message = "Your password has been successfuly updated."
        dispatch({ type: types.LP4_PASSWORD_UPDATE_SUCCESS, success: true, message: message})
    }
}

export function lp4PasswordUpdateError(errors) {
    // Signal error as to why password could not be set
    return { type: types.LP4_PASSWORD_UPDATE_ERROR, errors: errors }
}

export function passwordResetErrors() {
    return { type: types.PASSWORD_RESET_ERRORS }
}

export function resetRequestSubmit(email) {
    return dispatch => {
        return fetch(`${API_BASE_URL}/forgot-password`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                email: email,
            })
        })
        .then(response => {
            if (response.status === 401) {
                dispatch(notAuthorisedResponse())
            } else {
                return response.json()
            }
        })
        .then((json) => {
            if (json.errors) {
                if (Array.isArray(json.errors)) {
                    dispatch(resetRequestError(json.errors))
                } else {
                    // If we get an object back, transform it to an array
                    var errors = [];
                    const flattened = flatten(json.errors)
                    for(var o in flattened) {
                        errors.push(flattened[o]);
                    }
                    dispatch(resetRequestError(errors))
                }
            } else {
                dispatch(resetRequestSuccess(json))
            }
        })
        .catch((response) => {
            const errors = ["A problem occured trying to reset your password. Please try again"]
            dispatch(resetRequestError(errors))

            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function resetRequestSuccess() {
    return { type: types.PASSWORD_RESET_REQUEST_SUCCESS}
}

export function resetRequestError(errors) {
    return { type: types.PASSWORD_RESET_REQUEST_ERROR, errors: errors}
}

export function validateCurrentPasswordRequest(history, username, password) {
    return dispatch => {
        dispatch( { type: types.PASSWORD_VALIDATION_REQUEST,
            auth:
                {
                    username: username,
                    password: password,
                    loading: true
                }
        })
        return fetch(API_BASE_URL +  '/auth/authorize', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                'grant_type' : 'password',
                'username' : username,
                'password' : password
            })
        })
        .then(response => {
            if (!response.ok) {
                if (response.status === 403) {
                    return response.json().then(data => {
                        dispatch(validationErrors(data))
                        return Promise.reject()
                    })
                }
            } else {
                return response.json().then(data => {
                    if (data.access_token) {
                        dispatch(validationSuccess(history, data))
                    } else {
                        dispatch(validationErrors(data))
                        return Promise.reject()
                    }
                })
            }
        })
    };
}

export function validationSuccess(history, payload) {
    return dispatch => {
        dispatch( {type: types.PASSWORD_VALIDATION_SUCCESS, auth: payload} )
    }
}

export function validationErrors(errors) {
    return { type: types.PASSWORD_VALIDATION_ERRORS, errors: errors }
}