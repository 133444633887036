import * as types from './actionTypes'
import { addMessage } from './messengerActions'
import { log } from './logActions'
import { API_BASE_URL }  from '../config'
import { fetchWithToken } from '../util/accessToken'
import { notAuthorisedResponse } from './authActions'

export function fetchProjects(clientId) {
    return (dispatch, getState) => {
        dispatch(
            {
                type: types.UCPA_FETCH_PROJECTS_REQUEST,
                clients_id: clientId
            }
        )
        const url = `${API_BASE_URL}/api/clients/${clientId}/projects`
        return fetchWithToken(
            url,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        ).then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    dispatch(notAuthorisedResponse())
                    return Promise.reject()
                }
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.UCPA_FETCH_PROJECTS_RESPONSE_ERRORS,
                                clients_id: clientId,
                                errors: data.errors
                            }
                        )
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to get list of projects",
                                data.errors
                            )
                        )
                    } else {
                        dispatch(
                            {
                                type: types.UCPA_FETCH_PROJECTS_RESPONSE_ERRORS,
                                clients_id: clientId,
                                errors: {
                                    unknownErrorType: {
                                        unknownError: "There was an unexpected error"
                                    }
                                }
                            }
                        )
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to get list of projects",
                                "There was an unexpected error"
                            )
                        )
                    }
                })
                return Promise.reject()
            } else {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.UCPA_FETCH_PROJECTS_RESPONSE_ERRORS,
                                clients_id: clientId,
                                errors: data.errors
                            }
                        )
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to get list of projects",
                                data.errors
                            )
                        )
                    } else {
                        dispatch(
                            {
                                type: types.UCPA_FETCH_PROJECTS_RESPONSE_SUCCESS,
                                clients_id: clientId,
                                projects: data
                            }
                        )
                    }
                })
            }
        })
        .catch((response) => {
            dispatch(log("error", response))
            dispatch(
                addMessage(
                    "negative",
                    "Unable to get list of projects",
                    response
                )
            )
        })
    }
}

export function fetchProjectCameras(clientId, projectId) {
    return (dispatch, getState) => {
        dispatch(
            {
                type: types.UCPA_FETCH_PROJECT_CAMERAS_REQUEST,
                clients_id: clientId,
                projects_id: projectId
            }
        )
        const url = `${API_BASE_URL}/api/clients/${clientId}/projects/${projectId}/cameras`
        return fetchWithToken(
            url,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        ).then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    dispatch(notAuthorisedResponse())
                    return Promise.reject()
                }
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.UCPA_FETCH_PROJECT_CAMERAS_RESPONSE_ERRORS,
                                clients_id: clientId,
                                projects_id: projectId,
                                errors: data.errors
                            }
                        )
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to get list of cameras",
                                data.errors
                            )
                        )
                    } else {
                        dispatch(
                            {
                                type: types.UCPA_FETCH_PROJECT_CAMERAS_RESPONSE_ERRORS,
                                clients_id: clientId,
                                projects_id: projectId,
                                errors: {
                                    unknownErrorType: {
                                        unknownError: "There was an unexpected error"
                                    }
                                }
                            }
                        )
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to get list of cameras",
                                "There was an unexpected error"
                            )
                        )
                    }
                })
            } else {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.UCPA_FETCH_PROJECT_CAMERAS_RESPONSE_ERRORS,
                                clients_id: clientId,
                                projects_id: projectId,
                                errors: data.errors
                            }
                        )
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to get list of cameras",
                                data.errors
                            )
                        )
                    } else {
                        dispatch(
                            {
                                type: types.UCPA_FETCH_PROJECT_CAMERAS_RESPONSE_SUCCESS,
                                clients_id: clientId,
                                projects_id: projectId,
                                cameras: data
                            }
                        )
                    }
                })
            }
        })
        .catch((response) => {
            dispatch(log("error", response))
            dispatch(
                addMessage(
                    "negative",
                    "Unable to get list of cameras",
                    response
                )
            )
        })
    }
}

export function resetProjectCameras() {
    return {
        type: types.UCPA_RESET_PROJECT_CAMERAS
    }
}

export function fetchUserProjects(clientId, userId) {
    return (dispatch, getState) => {
        dispatch(
            {
                type: types.UCPA_FETCH_USER_PROJECTS_REQUEST,
                clients_id: clientId,
                users_id: userId
            }
        )
        const url = `${API_BASE_URL}/api/clients/${clientId}/users/${userId}/projects`
        return fetchWithToken(
            url,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        ).then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    dispatch(notAuthorisedResponse())
                    return Promise.reject()
                }
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.UCPA_FETCH_USER_PROJECTS_RESPONSE_ERRORS,
                                clients_id: clientId,
                                users_id: userId,
                                errors: data.errors
                            }
                        )
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to get user projects",
                                data.errors
                            )
                        )
                    } else {
                        dispatch(
                            {
                                type: types.UCPA_FETCH_USER_PROJECTS_RESPONSE_ERRORS,
                                clients_id: clientId,
                                users_id: userId,
                                errors: {
                                    unknownErrorType: {
                                        unknownError: "There was an unexpected error"
                                    }
                                }
                            }
                        )
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to get user projects",
                                "There was an unexpected error"
                            )
                        )
                    }
                })
            } else {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.UCPA_FETCH_USER_PROJECTS_RESPONSE_ERRORS,
                                clients_id: clientId,
                                users_id: userId,
                                errors: data.errors
                            }
                        )
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to get user projects",
                                data.errors
                            )
                        )
                    } else {
                        dispatch(
                            {
                                type: types.UCPA_FETCH_USER_PROJECTS_RESPONSE_SUCCESS,
                                clients_id: clientId,
                                users_id: userId,
                                projects: data
                            }
                        )
                    }
                })
            }
        })
        .catch((response) => {
            dispatch(log("error", response))
            dispatch(
                addMessage(
                    "negative",
                    "Unable to get user projects",
                    response
                )
            )
        })
    }
}

export function fetchUserCameras(clientId, userId) {
    return (dispatch, getState) => {
        dispatch(
            {
                type: types.UCPA_FETCH_USER_CAMERAS_REQUEST,
                clients_id: clientId,
                users_id: userId
            }
        )
        const url = `${API_BASE_URL}/api/clients/${clientId}/users/${userId}/cameras`
        return fetchWithToken(
            url,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        ).then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    dispatch(notAuthorisedResponse())
                    return Promise.reject()
                }
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.UCPA_FETCH_USER_CAMERAS_RESPONSE_ERRORS,
                                clients_id: clientId,
                                users_id: userId,
                                errors: data.errors
                            }
                        )
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to get user cameras",
                                data.errors
                            )
                        )
                    } else {
                        dispatch(
                            {
                                type: types.UCPA_FETCH_USER_CAMERAS_RESPONSE_ERRORS,
                                clients_id: clientId,
                                users_id: userId,
                                errors: {
                                    unknownErrorType: {
                                        unknownError: "There was an unexpected error"
                                    }
                                }
                            }
                        )
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to get user cameras",
                                "There was an unexpected error"
                            )
                        )
                    }
                })
            } else {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.UCPA_FETCH_USER_CAMERAS_RESPONSE_ERRORS,
                                clients_id: clientId,
                                users_id: userId,
                                errors: data.errors
                            }
                        )
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to get user cameras",
                                data.errors
                            )
                        )
                    } else {
                        dispatch(
                            {
                                type: types.UCPA_FETCH_USER_CAMERAS_RESPONSE_SUCCESS,
                                clients_id: clientId,
                                users_id: userId,
                                cameras: data
                            }
                        )
                    }
                })
            }
        })
        .catch((response) => {
            dispatch(log("error", response))
            dispatch(
                addMessage(
                    "negative",
                    "Unable to get user cameras",
                    response
                )
            )
        })
    }
}

export function associateProjectWithUser(clientId, projectId, userId, isBulk) {
    return (dispatch, getState) => {
        dispatch(
            {
                type: types.UCPA_ASSOCIATE_PROJECT_WITH_USER_REQUEST,
                clients_id: clientId,
                users_id: userId,
                projects_id: projectId
            }
        )
        const url = `${API_BASE_URL}/api/projects/associate-project-with-user`
        return fetchWithToken(
            url,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    users_id: userId,
                    projects_id: projectId
                })
            },
            dispatch,
            getState
        ).then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    dispatch(notAuthorisedResponse())
                    return Promise.reject()
                }
                return response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.UCPA_ASSOCIATE_PROJECT_WITH_USER_RESPONSE_ERRORS,
                                clients_id: clientId,
                                users_id: userId,
                                projects_id: projectId,
                                errors: data.errors
                            }
                        )
                        if (!isBulk) {
                            dispatch(
                                addMessage(
                                    "negative",
                                    "Unable to associate project with user",
                                    data.errors
                                )
                            )
                        }
                    } else {
                        dispatch(
                            {
                                type: types.UCPA_ASSOCIATE_PROJECT_WITH_USER_RESPONSE_ERRORS,
                                clients_id: clientId,
                                users_id: userId,
                                projects_id: projectId,
                                errors: {
                                    unknownErrorType: {
                                        unknownError: "There was an unexpected error"
                                    }
                                }
                            }
                        )
                        if (!isBulk) {
                            dispatch(
                                addMessage(
                                    "negative",
                                    "Unable to associate project with user",
                                    "There was an unexpected error"
                                )
                            )
                        }
                    }
                    return Promise.reject()
                })
            } else {
                return response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.UCPA_ASSOCIATE_PROJECT_WITH_USER_RESPONSE_ERRORS,
                                clients_id: clientId,
                                users_id: userId,
                                projects_id: projectId,
                                errors: data.errors
                            }
                        )
                        if (!isBulk) {
                            dispatch(
                                addMessage(
                                    "negative",
                                    "Unable to associate project with user",
                                    data.errors
                                )
                            )
                        }
                    } else {
                        dispatch(
                            {
                                type: types.UCPA_ASSOCIATE_PROJECT_WITH_USER_RESPONSE_SUCCESS,
                                clients_id: clientId,
                                users_id: userId,
                                projects_id: projectId,
                                details: data,
                                message: 'Successfully associated user with project'
                            }
                        )
                    }
                })
            }
        })
        .catch((response) => {
            dispatch(log("error", response))
            if (!isBulk) {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to associate project with user",
                        response
                    )
                )
                return Promise.reject()
            }
        })
    }
}

export function unassociateProjectWithUser(clientId, projectId, userId) {
    return (dispatch, getState) => {
        dispatch(
            {
                type: types.UCPA_UNASSOCIATE_PROJECT_WITH_USER_REQUEST,
                clients_id: clientId,
                users_id: userId,
                projects_id: projectId
            }
        )
        const url = `${API_BASE_URL}/api/projects/unassociate-project-with-user`
        return fetchWithToken(
            url,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    users_id: userId,
                    projects_id: projectId
                })
            },
            dispatch,
            getState
        ).then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    dispatch(notAuthorisedResponse())
                    return Promise.reject()
                }
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.UCPA_UNASSOCIATE_PROJECT_WITH_USER_RESPONSE_ERRORS,
                                clients_id: clientId,
                                users_id: userId,
                                projects_id: projectId,
                                errors: data.errors
                            }
                        )
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to unassociate project with user",
                                data.errors
                            )
                        )
                    } else {
                        dispatch(
                            {
                                type: types.UCPA_UNASSOCIATE_PROJECT_WITH_USER_RESPONSE_ERRORS,
                                clients_id: clientId,
                                users_id: userId,
                                projects_id: projectId,
                                errors: {
                                    unknownErrorType: {
                                        unknownError: "There was an unexpected error"
                                    }
                                }
                            }
                        )
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to unassociate project with user",
                                "There was an unexpected error"
                            )
                        )
                    }
                })
            } else {
                if (response.status === 204) {
                    // no content...but successful
                    dispatch(
                        {
                            type: types.UCPA_UNASSOCIATE_PROJECT_WITH_USER_RESPONSE_SUCCESS,
                            clients_id: clientId,
                            users_id: userId,
                            projects_id: projectId,
                            message: 'Successfully removed association between user and project'
                        }
                    )
                } else {
                    response.json().then(data => {

                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.UCPA_UNASSOCIATE_PROJECT_WITH_USER_RESPONSE_ERRORS,
                                    clients_id: clientId,
                                    users_id: userId,
                                    projects_id: projectId,
                                    errors: data.errors
                                }
                            )
                            dispatch(
                                addMessage(
                                    "negative",
                                    "Unable to unassociate project with user",
                                    data.errors
                                )
                            )
                        }
                    })
                }
            }
        })
        .catch((response) => {
            dispatch(log("error", response))
            dispatch(
                addMessage(
                    "negative",
                    "Unable to unassociate project with user",
                    response
                )
            )
        })
    }
}

export function associateCameraWithUser(clientId, projectId, cameraId, userId) {
    return (dispatch, getState) => {
        dispatch(
            {
                type: types.UCPA_ASSOCIATE_CAMERA_WITH_USER_REQUEST,
                clients_id: clientId,
                projects_id: projectId,
                cameras_id: cameraId,
                users_id: userId
            }
        )
        const url = `${API_BASE_URL}/api/cameras/associate-camera-with-user`
        return fetchWithToken(
            url,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    users_id: userId,
                    cameras_id: cameraId
                })
            },
            dispatch,
            getState
        ).then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    dispatch(notAuthorisedResponse())
                    return Promise.reject()
                }
                return response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.UCPA_ASSOCIATE_CAMERA_WITH_USER_RESPONSE_ERRORS,
                                clients_id: clientId,
                                projects_id: projectId,
                                cameras_id: cameraId,
                                users_id: userId,
                                errors: data.errors
                            }
                        )
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to associate camera with user",
                                data.errors
                            )
                        )
                    } else {
                        dispatch(
                            {
                                type: types.UCPA_ASSOCIATE_CAMERA_WITH_USER_RESPONSE_ERRORS,
                                clients_id: clientId,
                                projects_id: projectId,
                                cameras_id: cameraId,
                                users_id: userId,
                                errors: {
                                    unknownErrorType: {
                                        unknownError: "There was an unexpected error"
                                    }
                                }
                            }
                        )
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to associate camera with user",
                                "There was an unexpected error"
                            )
                        )
                    }
                    return Promise.reject()
                })

            } else {

                return response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.UCPA_ASSOCIATE_CAMERA_WITH_USER_RESPONSE_ERRORS,
                                clients_id: clientId,
                                projects_id: projectId,
                                cameras_id: cameraId,
                                users_id: userId,
                                errors: data.errors
                            }
                        )
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to associate camera with user",
                                data.errors
                            )
                        )
                    } else {
                        dispatch(
                            {
                                type: types.UCPA_ASSOCIATE_CAMERA_WITH_USER_RESPONSE_SUCCESS,
                                clients_id: clientId,
                                projects_id: projectId,
                                cameras_id: cameraId,
                                users_id: userId,
                                details: data,
                                message: 'Successfully associated user with camera'
                            }
                        )
                    }
                })
            }
        })
        .catch((response) => {
            dispatch(log("error", response))
            dispatch(
                addMessage(
                    "negative",
                    "Unable to associate camera with user",
                    response
                )
            )
            return Promise.reject()
        })
    }
}

export function unassociateCameraWithUser(clientId, projectId, cameraId, userId) {
    return (dispatch, getState) => {
        dispatch(
            {
                type: types.UCPA_UNASSOCIATE_CAMERA_WITH_USER_REQUEST,
                clients_id: clientId,
                projects_id: projectId,
                cameras_id: cameraId,
                users_id: userId,
            }
        )
        const url = `${API_BASE_URL}/api/cameras/unassociate-camera-with-user`
        return fetchWithToken(
            url,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    users_id: userId,
                    cameras_id: cameraId
                })
            },
            dispatch,
            getState
        ).then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    dispatch(notAuthorisedResponse())
                    return Promise.reject()
                }
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.UCPA_UNASSOCIATE_CAMERA_WITH_USER_RESPONSE_ERRORS,
                                clients_id: clientId,
                                projects_id: projectId,
                                cameras_id: cameraId,
                                users_id: userId,
                                errors: data.errors
                            }
                        )
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to unassociate camera with user",
                                data.errors
                            )
                        )
                    } else {
                        dispatch(
                            {
                                type: types.UCPA_UNASSOCIATE_CAMERA_WITH_USER_RESPONSE_ERRORS,
                                clients_id: clientId,
                                projects_id: projectId,
                                cameras_id: cameraId,
                                users_id: userId,
                                errors: {
                                    unknownErrorType: {
                                        unknownError: "There was an unexpected error"
                                    }
                                }
                            }
                        )
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to unassociate camera with user",
                                "There was an unexpected error"
                            )
                        )
                    }
                })
            } else {
                if (response.status === 204) {
                    dispatch(
                        {
                            type: types.UCPA_UNASSOCIATE_CAMERA_WITH_USER_RESPONSE_SUCCESS,
                            clients_id: clientId,
                            projects_id: projectId,
                            cameras_id: cameraId,
                            users_id: userId,
                            message: 'Successfully removed association between user and camera'
                        }
                    )
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.UCPA_UNASSOCIATE_CAMERA_WITH_USER_RESPONSE_ERRORS,
                                    clients_id: clientId,
                                    projects_id: projectId,
                                    cameras_id: cameraId,
                                    users_id: userId,
                                    errors: data.errors
                                }
                            )
                            dispatch(
                                addMessage(
                                    "negative",
                                    "Unable to unassociate camera with user",
                                    data.errors
                                )
                            )
                        }

                    })
                }
            }
        })
        .catch((response) => {
            dispatch(log("error", response))
            dispatch(
                addMessage(
                    "negative",
                    "Unable to unassociate camera with user",
                    response
                )
            )
        })
    }
}


export function changeUserCameraWeight(clientId, projectId, cameraId, userId, weight) {
    return (dispatch, getState) => {
        dispatch(
            {
                type: types.UCPA_CHANGE_USER_CAMERA_WEIGHT_REQUEST,
                clients_id: clientId,
                projects_id: projectId,
                cameras_id: cameraId,
                users_id: userId,
                weight: weight
            }
        )
        const url = `${API_BASE_URL}/api/cameras/change-user-camera-weight`
        return fetchWithToken(
            url,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    users_id: userId,
                    cameras_id: cameraId,
                    weight: weight
                })
            },
            dispatch,
            getState
        ).then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    dispatch(notAuthorisedResponse())
                    return Promise.reject()
                }
                return response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.UCPA_CHANGE_USER_CAMERA_WEIGHT_RESPONSE_ERRORS,
                                clients_id: clientId,
                                projects_id: projectId,
                                cameras_id: cameraId,
                                users_id: userId,
                                errors: data.errors
                            }
                        )
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to update camera order",
                                data.errors
                            )
                        )
                    } else {
                        dispatch(
                            {
                                type: types.UCPA_CHANGE_USER_CAMERA_WEIGHT_RESPONSE_ERRORS,
                                clients_id: clientId,
                                projects_id: projectId,
                                cameras_id: cameraId,
                                users_id: userId,
                                errors: {
                                    unknownErrorType: {
                                        unknownError: "There was an unexpected error"
                                    }
                                }
                            }
                        )
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to update camera order",
                                "There was an unexpected error"
                            )
                        )
                    }
                })

            } else {
                return response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.UCPA_CHANGE_USER_CAMERA_WEIGHT_RESPONSE_ERRORS,
                                clients_id: clientId,
                                projects_id: projectId,
                                cameras_id: cameraId,
                                users_id: userId,
                                errors: data.errors
                            }
                        )
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to update camera order",
                                data.errors
                            )
                        )
                    } else {
                        dispatch(
                            {
                                type: types.UCPA_CHANGE_USER_CAMERA_WEIGHT_RESPONSE_SUCCESS,
                                clients_id: clientId,
                                projects_id: projectId,
                                cameras_id: cameraId,
                                users_id: userId,
                                details: data,
                                message: 'Successfully updated the ordering weight on camera'
                            }
                        )
                    }
                })
            }
        })
        .catch((response) => {
            dispatch(log("error", response))
            dispatch(
                addMessage(
                    "negative",
                    "Unable to update camera order",
                    response
                )
            )
        })

    }
}

export function changeUserProjectWeight(clientId, projectId, userId, weight) {
    return (dispatch, getState) => {
        dispatch(
            {
                type: types.UCPA_CHANGE_USER_PROJECT_WEIGHT_REQUEST,
                clients_id: clientId,
                projects_id: projectId,
                users_id: userId,
                weight: weight
            }
        )
        const url = `${API_BASE_URL}/api/projects/change-user-project-weight`
        return fetchWithToken(
            url,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    projects_id: projectId,
                    users_id: userId,
                    weight: weight
                })
            },
            dispatch,
            getState
        ).then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    dispatch(notAuthorisedResponse())
                    return Promise.reject()
                }
                return response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.UCPA_CHANGE_USER_PROJECT_WEIGHT_RESPONSE_ERRORS,
                                clients_id: clientId,
                                projects_id: projectId,
                                users_id: userId,
                                errors: data.errors
                            }
                        )
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to update project order",
                                data.errors
                            )
                        )
                    } else {
                        dispatch(
                            {
                                type: types.UCPA_CHANGE_USER_PROJECT_WEIGHT_RESPONSE_ERRORS,
                                clients_id: clientId,
                                projects_id: projectId,
                                users_id: userId,
                                errors: {
                                    unknownErrorType: {
                                        unknownError: "There was an unexpected error"
                                    }
                                }
                            }
                        )
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to update project order",
                                "There was an unexpected error"
                            )
                        )
                    }
                })
            } else {
                return response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.UCPA_CHANGE_USER_PROJECT_WEIGHT_RESPONSE_ERRORS,
                                clients_id: clientId,
                                projects_id: projectId,
                                users_id: userId,
                                errors: data.errors
                            }
                        )
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to update project order",
                                data.errors
                            )
                        )
                    } else {
                        dispatch(
                            {
                                type: types.UCPA_CHANGE_USER_PROJECT_WEIGHT_RESPONSE_SUCCESS,
                                clients_id: clientId,
                                projects_id: projectId,
                                users_id: userId,
                                details: data,
                                message: 'Successfully updated the ordering weight on project'
                            }
                        )
                    }
                })
            }
        })
        .catch((response) => {
            dispatch(log("error", response))
            dispatch(
                addMessage(
                    "negative",
                    "Unable to update project order",
                    response
                )
            )
        })
    }
}

export function updateProjectCameraWeights(projectCameraWeights) {
    return { type: types.UCPA_UPDATE_PROJECT_CAMERA_WEIGHTS, project_camera_weights: projectCameraWeights}
}

export function addUserProjects(projects) {
    return { type: types.UCPA_ADD_USER_PROJECTS, projects: projects }
}

export function removeUserProjects(projects) {
    return { type: types.UCPA_REMOVE_USER_PROJECTS, projects: projects }
}

export function addUserCameras(cameras) {
    return { type: types.UCPA_ADD_USER_CAMERAS, cameras: cameras }
}

export function removeUserCameras(cameras) {
    return { type: types.UCPA_REMOVE_USER_CAMERAS, cameras: cameras }
}

export function resetAll() {
    return { type: types.UCPA_RESET_ALL }
}

export function resetAssociateCameraWithUser() {
    return { type: types.UCPA_RESET_ASSOCIATE_CAMERA_WITH_USER }
}

export function resetUnassociateCameraWithUser() {
    return { type: types.UCPA_RESET_UNASSOCIATE_CAMERA_WITH_USER }
}

export function resetAssociateProjectWithUser() {
    return { type: types.UCPA_RESET_ASSOCIATE_PROJECT_WITH_USER }
}

export function resetUnassociateProjectWithUser() {
    return { type: types.UCPA_RESET_UNASSOCIATE_PROJECT_WITH_USER }
}

export function resetChangeUserProjectWeight() {
    return { type: types.UCPA_RESET_CHANGE_USER_PROJECT_WEIGHT }
}

export function resetChangeUserCameraWeight() {
    return { type: types.UCPA_RESET_CHANGE_USER_CAMERA_WEIGHT }
}

export function resetAddUserCameras() {
    return { type: types.UCPA_RESET_ADD_USER_CAMERAS }
}

export function resetRemoveUserCameras() {
    return { type: types.UCPA_RESET_REMOVE_USER_CAMERAS }
}

export function resetAddUserProjects() {
    return { type: types.UCPA_RESET_ADD_USER_PROJECTS }
}

export function resetRemoveUserProjects() {
    return { type: types.UCPA_RESET_REMOVE_USER_PROJECTS }
}

export function clearSuccessAndErrorMessages() {
    return dispatch => {
        dispatch(resetAssociateCameraWithUser())
        dispatch(resetAssociateProjectWithUser())
        dispatch(resetUnassociateProjectWithUser())
        dispatch(resetUnassociateCameraWithUser())
    }
}

export function processing(processing) {
    return { type: types.UCPA_PROCESSING, processing: processing }
}
