import * as types from './actionTypes'
import { API_BASE_URL, PAGINATION_MAX_ROWS_PER_PAGE, PAGINATION_MAX_ROWS_PER_PAGE_PERMISSIONS }  from '../config'
import { fetchWithToken } from '../util/accessToken'
import { addMessage } from './messengerActions'
import { log } from './logActions'
import { notAuthorisedResponse } from './authActions'

export function fetchRoles(page = 1, filterBy = null) {
    return (dispatch, getState) => {
        dispatch({ type: types.ROLES_VIEW_ALL_REQUEST, page: page })
        return fetchWithToken(
            `${API_BASE_URL}/api/roles?page=${page}&pageSize=${PAGINATION_MAX_ROWS_PER_PAGE}&filterBy=${filterBy}`,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.ROLES_VIEW_ALL_RESPONSE_ERRORS,
                                        errors: data.errors,
                                    })
                            } else {
                                // Error
                                dispatch(
                                    {
                                        type: types.ROLES_VIEW_ALL_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        }
                                    }
                                )
                            }

                        })
                    }
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.ROLES_VIEW_ALL_RESPONSE_ERRORS,
                                    errors: data.errors,
                                })
                        } else {
                            dispatch(
                                {
                                    type: types.ROLES_VIEW_ALL_RESPONSE_SUCCESS,
                                    details: data
                                })
                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(log("error", response))
            })
    }
}

export function fetchRole(roleId) {
    return (dispatch, getState) => {
        dispatch({ type: types.ROLES_VIEW_ONE_REQUEST, roles_id: roleId })
        return fetchWithToken(
            `${API_BASE_URL}/api/roles/${roleId}`,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.ROLES_VIEW_ONE_RESPONSE_ERRORS,
                                        errors: data.errors,
                                    })
                            } else {
                                // Error
                                dispatch(
                                    {
                                        type: types.ROLES_VIEW_ONE_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        }
                                    }
                                )
                            }

                        })
                    }
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.ROLES_VIEW_ONE_RESPONSE_ERRORS,
                                    errors: data.errors,
                                })
                        } else {
                            dispatch(
                                {
                                    type: types.ROLES_VIEW_ONE_RESPONSE_SUCCESS,
                                    details: data
                                })
                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(log("error", response))
            })
    }
}

export function reset() {
    return { type: types.ROLES_RESET }
}

export function resetCreate() {
    return { type: types.ROLES_RESET_CREATE }
}

export function resetUpdate() {
    return { type: types.ROLES_RESET_UPDATE }
}

export function resetDelete() {
    return { type: types.ROLES_RESET_DELETE }
}

export function createRole(details) {
    return (dispatch, getState) => {
        dispatch({ type: types.ROLES_CREATE_REQUEST, details: details })
        return fetchWithToken(
            `${API_BASE_URL}/api/roles`,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(details)
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.ROLES_CREATE_RESPONSE_ERRORS,
                                        errors: data.errors,
                                    })
                            } else {
                                dispatch(
                                    {
                                        type: types.ROLES_CREATE_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        }
                                    }
                                )
                            }

                        })
                    }
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.ROLES_CREATE_RESPONSE_ERRORS,
                                    errors: data.errors,
                                })
                        } else {
                            dispatch(
                                addMessage(
                                    "success",
                                    "Success",
                                    "Successfully created new role."
                                )
                            )
                            dispatch(
                                {
                                    type: types.ROLES_CREATE_RESPONSE_SUCCESS,
                                    details: data
                                })
                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(log("error", response))
            })
    }
}

export function updateRole(roleId, details) {
    return (dispatch, getState) => {
        dispatch({ type: types.ROLES_UPDATE_REQUEST, roles_id: roleId, details: details })
        return fetchWithToken(
            `${API_BASE_URL}/api/roles/${roleId}`,
            {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(details)
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.ROLES_UPDATE_RESPONSE_ERRORS,
                                        errors: data.errors,
                                    })
                            } else {
                                dispatch(
                                    {
                                        type: types.ROLES_UPDATE_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        }
                                    }
                                )
                            }

                        })
                    }
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.ROLES_UPDATE_RESPONSE_ERRORS,
                                    errors: data.errors,
                                })
                        } else {
                            dispatch(
                                addMessage(
                                    "success",
                                    "Success",
                                    "Successfully update role."
                                )
                            )
                            dispatch(
                                {
                                    type: types.ROLES_UPDATE_RESPONSE_SUCCESS,
                                    details: data
                                })
                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(log("error", response))
            })
    }
}

export function deleteRole(roleId) {
    return (dispatch, getState) => {
        dispatch({ type: types.ROLES_DELETE_REQUEST, roles_id: roleId })
        return fetchWithToken(
            `${API_BASE_URL}/api/roles/${roleId}`,
            {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.ROLES_DELETE_RESPONSE_ERRORS,
                                        errors: data.errors,
                                    })
                            } else {
                                dispatch(
                                    {
                                        type: types.ROLES_DELETE_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        }
                                    }
                                )
                            }

                        })
                    }
                } else {
                    if (response.status === 204) {
                        dispatch(
                            addMessage(
                                "success",
                                "Success",
                                "Successfully removed role."
                            )
                        )
                        dispatch(
                            {
                                type: types.ROLES_DELETE_RESPONSE_SUCCESS,
                                message: 'Successfully removed role'
                            })
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.ROLES_DELETE_RESPONSE_ERRORS,
                                        errors: data.errors,
                                    })
                            }
                        })
                    }
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(log("error", response))
            })
    }
}

export function associatePermissionWithRole(permissionId, roleId) {
    return (dispatch, getState) => {
        dispatch({
            type: types.ROLES_ASSOCIATE_PERMISSION_WITH_ROLE_REQUEST,
            roles_id: roleId,
            permissions_id: permissionId
        })
        return fetchWithToken(
            `${API_BASE_URL}/api/roles/associate-permission-with-role`,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    roles_id: roleId,
                    permissions_id: permissionId
                })
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.ROLES_ASSOCIATE_PERMISSION_WITH_ROLE_RESPONSE_ERRORS,
                                        errors: data.errors,
                                    })
                            } else {
                                dispatch(
                                    {
                                        type: types.ROLES_ASSOCIATE_PERMISSION_WITH_ROLE_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        }
                                    }
                                )
                            }

                        })
                    }
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.ROLES_ASSOCIATE_PERMISSION_WITH_ROLE_RESPONSE_ERRORS,
                                    errors: data.errors,
                                })
                        } else {
                            dispatch(
                                {
                                    type: types.ROLES_ASSOCIATE_PERMISSION_WITH_ROLE_RESPONSE_SUCCESS,
                                    message: data.message
                                })
                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(log("error", response))
            })
    }
}

export function unassociatePermissionWithRole(permissionId, roleId) {
    return (dispatch, getState) => {
        dispatch({
            type: types.ROLES_UNASSOCIATE_PERMISSION_WITH_ROLE_REQUEST,
            roles_id: roleId,
            permissions_id: permissionId
        })
        return fetchWithToken(
            `${API_BASE_URL}/api/roles/unassociate-permission-with-role`,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    roles_id: roleId,
                    permissions_id: permissionId
                })
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.ROLES_UNASSOCIATE_PERMISSION_WITH_ROLE_RESPONSE_ERRORS,
                                        errors: data.errors,
                                    })
                            } else {
                                dispatch(
                                    {
                                        type: types.ROLES_UNASSOCIATE_PERMISSION_WITH_ROLE_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        }
                                    }
                                )
                            }

                        })
                    }
                } else {

                    if (response.status === 204) {
                        dispatch(
                            {
                                type: types.ROLES_UNASSOCIATE_PERMISSION_WITH_ROLE_RESPONSE_SUCCESS,
                                message: 'Successfully removed association between permission and role'
                            })
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.ROLES_UNASSOCIATE_PERMISSION_WITH_ROLE_RESPONSE_ERRORS,
                                        errors: data.errors,
                                    })
                            }
                        })
                    }
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(log("error", response))
            })
    }
}

export function fetchPermissions(page = 1, filterBy = null) {
    return (dispatch, getState) => {
        dispatch({ type: types.ROLES_PERMISSIONS_REQUEST, page: page })
        return fetchWithToken(
            `${API_BASE_URL}/api/permissions?page=${page}&pageSize=${PAGINATION_MAX_ROWS_PER_PAGE_PERMISSIONS}&filterBy=${filterBy}`,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.ROLES_PERMISSIONS_RESPONSE_ERRORS,
                                        errors: data.errors,
                                    })
                            } else {
                                // Error
                                dispatch(
                                    {
                                        type: types.ROLES_PERMISSIONS_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        }
                                    }
                                )
                            }

                        })
                    }
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.ROLES_PERMISSIONS_RESPONSE_ERRORS,
                                    errors: data.errors,
                                })
                        } else {
                            dispatch(
                                {
                                    type: types.ROLES_PERMISSIONS_RESPONSE_SUCCESS,
                                    details: data
                                })
                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(log("error", response))
            })
    }
}
