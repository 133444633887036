import * as types from './actionTypes'
import { API_BASE_URL}  from '../config'
import { addMessage } from './messengerActions'
import { log as appLog } from './logActions'
import { notAuthorisedResponse } from './authActions'
import * as uploadQueue from './uploadQueueActions'
import { fetchWithToken } from '../util/accessToken'
import EXIF from 'exif-js'

export function fetchStatus(cameraId) {
    return (dispatch, getState) => {
        dispatch({ type: types.CAMERA_CONTROL_STATUS_REQUEST, cameras_id: cameraId  })
        // Let's also call log so that it appears in the LogViewer...
        // dispatch(log('Info', 'Requesting camera status', Date.now()))
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control/status` ,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_STATUS_RESPONSE_ERRORS,
                                        errors: data.errors,
                                    })
                                dispatch(log('Error', 'There was an error while requesting camera status', Date.now()))
                            } else {
                                // Error
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_STATUS_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        }
                                    }
                                )
                                dispatch(log('Error', 'There was an unexpected error while requesting camera status', Date.now()))
                            }

                        })
                    }
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_STATUS_RESPONSE_ERRORS,
                                    errors: data.errors,
                                }
                            )
                            dispatch(log('Error', 'There was an error while requesting camera status', Date.now()))
                        } else {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_STATUS_RESPONSE_SUCCESS,
                                    details: data
                                }
                            )
                            // dispatch(log('Info', 'Successfully received camera status details ', Date.now(), data))
                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(appLog("error", response))
            })
    }
}

/**
 *
 * @param level
 * @param message
 * @param timestamp
 * @param extras
 * @param type
 * @returns {{type, level: *, message: *, timestamp: *, extras: *, type}}
 *
 * On taking a shot, it's necessary to generate a link in the ActivityLog.
 * extras contains JSON data and type is used to determine what to do.
 *
 */
export function log(level, message, timestamp, extras = null, action = null) {
    return {
        type: types.CAMERA_CONTROL_LOG,
        level: level,
        message: message,
        timestamp: timestamp,
        extras: extras,
        action: action
    }
}

export function resetLog() {
    return { type: types.CAMERA_CONTROL_LOG_RESET }
}

export function reset() {
    return { type: types.CAMERA_CONTROL_RESET }
}

export function fetchCamera(cameraId) {
    return (dispatch, getState) => {
        dispatch({ type: types.CAMERA_CONTROL_CAMERA_REQUEST, cameras_id: cameraId  })
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}?thumbs=true` ,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_CAMERA_RESPONSE_ERRORS,
                                        errors: data.errors,
                                        cameras_id: cameraId
                                    })
                            } else {
                                // Error
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_CAMERA_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        },
                                        cameras_id: cameraId
                                    }
                                )
                            }

                        })
                    }
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_CAMERA_RESPONSE_ERRORS,
                                    errors: data.errors,
                                    cameras_id: cameraId
                                }
                            )
                        } else {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_CAMERA_RESPONSE_SUCCESS,
                                    details: data,
                                    cameras_id: cameraId
                                }
                            )
                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(appLog("error", response))
            })
    }
}

export function shoot(cameraId) {
    return (dispatch, getState) => {
        dispatch({ type: types.CAMERA_CONTROL_SHOOT_REQUEST, cameras_id: cameraId  })
        // Let's also call log so that it appears in the LogViewer...
        dispatch(log('Info', 'Requesting camera shoot', Date.now()))
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control/shoot` ,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_SHOOT_RESPONSE_ERRORS,
                                        errors: data.errors,
                                        cameras_id: cameraId
                                    })
                                dispatch(log('Error', 'There was an error while requesting camera shoot', Date.now()))
                            } else {
                                // Error
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_SHOOT_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        },
                                        cameras_id: cameraId
                                    }
                                )
                                dispatch(log('Error', 'There was an unexpected error while requesting camera shoot', Date.now()))
                            }

                        })
                    }
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_SHOOT_RESPONSE_ERRORS,
                                    errors: data.errors,
                                    cameras_id: cameraId
                                }
                            )
                            dispatch(log('Error', 'There was an error while requesting camera shoot', Date.now()))
                        } else {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_SHOOT_RESPONSE_SUCCESS,
                                    details: data,
                                    cameras_id: cameraId
                                }
                            )
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_UPDATE_EXIF,
                                    details: data
                                }
                            )
                            data.cameras_id = cameraId
                            dispatch(log('Success', 'Successfully received camera shoot details ', Date.now(), data, 'Shoot'))
                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(appLog("error", response))
            })
    }
}

export function uploadShot(cameraId) {
    return (dispatch, getState) => {
        dispatch(log('Info', 'Stopping queue', Date.now()))
        return dispatch(uploadQueue.stop(cameraId))
        .then(() => {
            const state = getState()
            if (!state.uploadQueue.started) {
                dispatch(log('Info', 'Queue stopped', Date.now()))
            } else {
                throw new Error('Failed to stop queue')
            }
            dispatch(log('Info', 'Flushing queue', Date.now()))
            return dispatch(uploadQueue.clear(cameraId))
        })
        .then(() => {
            const state = getState()
            const shoot = state.cameraControl.shoot
            if (!shoot.success) {
                throw new Error('Cannot upload - camera failed to shoot')
            }
            if (!shoot.details) {
                throw new Error('Cannot upload - no shot details')
            }
            if (!shoot.details.filename) {
                throw new Error('Cannot upload - no filename available')
            }
            const filename = shoot.details.filename
            dispatch(log('Info', `Adding ${filename} to queue`, Date.now()))
            return dispatch(uploadQueue.addImages(cameraId, [filename]))
        })
        .then(() =>{
            dispatch(log('Info', 'Starting queue', Date.now()))
            return dispatch(uploadQueue.start(cameraId))
        })
        .then(() => {
            const state = getState()
            if (state.uploadQueue.started) {
                dispatch(log('Info', 'Queue started', Date.now()))
            } else {
                throw new Error('Failed to start queue')
            }
        })
        .catch((error) =>{
            dispatch(log('Error', error.toString(), Date.now()))
        })
    }
}

export function version(version) {
    return { type: types.CAMERA_CONTROL_VERSION, version: version }
}

export function extractExif(cameraId, imgElement) {
    return (dispatch) => {
        let data = {}

        // This function will fire a async request to the imageElement url,
        // this in turn then fires another preview shot which isn't ideal
        EXIF.getData(imgElement, function() {
            //console.log(EXIF.getAllTags(this))

            data.make = EXIF.getTag(this, 'Make')
            data.model = EXIF.getTag(this, 'Model')
            data.aperture = EXIF.getTag(this, 'ApertureValue').toString()
            data.iso_speed = EXIF.getTag(this, 'ISOSpeedRatings')
            data.focal_length = EXIF.getTag(this, 'FocalLength') + ' mm'
            data.shutter_speed = EXIF.getTag(this, 'ShutterSpeedValue')
            data.exposure_compensation = EXIF.getTag(this, 'ExposureCompensation')

            dispatch({
                type: types.CAMERA_CONTROL_EXIF_RESPONSE_SUCCESS,
                details: data,
                cameras_id: cameraId
            })
        })

        // setTimeout(function () {
        //     dispatch({
        //         type: types.CAMERA_CONTROL_EXIF_RESPONSE_SUCCESS,
        //         details: data,
        //         cameras_id: cameraId
        //     })
        // }, 200);

        data.cameras_id = cameraId
        dispatch(log('Success', 'Successfully received image exif details ', Date.now(), data, 'Preview'))
    }
}

export function fetchExif(cameraId) {
    return (dispatch, getState) => {
        dispatch({ type: types.CAMERA_CONTROL_EXIF_REQUEST, cameras_id: cameraId  })
        // Let's also call log so that it appears in the LogViewer...
        dispatch(log('Info', 'Requesting camera exif details', Date.now()))
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control/exif` ,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_EXIF_RESPONSE_ERRORS,
                                        errors: data.errors,
                                        cameras_id: cameraId
                                    })
                                dispatch(log('Error', 'There was an error while requesting camera exif details', Date.now()))
                            } else {
                                // Error
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_EXIF_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        },
                                        cameras_id: cameraId
                                    }
                                )
                                dispatch(log('Error', 'There was an unexpected error while requesting camera exif details', Date.now()))
                            }

                        })
                    }
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_EXIF_RESPONSE_ERRORS,
                                    errors: data.errors,
                                    cameras_id: cameraId
                                }
                            )
                            dispatch(log('Error', 'There was an error while requesting camera status', Date.now()))
                        } else {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_EXIF_RESPONSE_SUCCESS,
                                    details: data,
                                    cameras_id: cameraId
                                }
                            )
                            dispatch(log('Info', 'Successfully received camera exif details ', Date.now(), data, 'Exif'))
                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(appLog("error", response))
            })
    }
}

export function preview(cameraId) {
    return (dispatch, getState) => {
        dispatch({ type: types.CAMERA_CONTROL_PREVIEW_REQUEST, cameras_id: cameraId  })
        // Let's also call log so that it appears in the LogViewer...
        dispatch(log('Info', 'Requesting camera preview shot', Date.now()))
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control/preview` ,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_PREVIEW_RESPONSE_ERRORS,
                                        errors: data.errors,
                                        cameras_id: cameraId
                                    })
                                dispatch(log('Error', 'There was an error while requesting camera preview shot', Date.now()))
                            } else {
                                // Error
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_PREVIEW_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        },
                                        cameras_id: cameraId
                                    }
                                )
                                dispatch(log('Error', 'There was an unexpected error while requesting camera preview shot', Date.now()))
                            }

                        })
                    }
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_PREVIEW_RESPONSE_ERRORS,
                                    errors: data.errors,
                                    cameras_id: cameraId
                                }
                            )
                            dispatch(log('Error', 'There was an error while requesting camera preview shot', Date.now()))
                        } else {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_PREVIEW_RESPONSE_SUCCESS,
                                    details: data,
                                    cameras_id: cameraId
                                }
                            )
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_UPDATE_EXIF,
                                    details: data
                                }
                            )
                            data.cameras_id = cameraId
                            dispatch(log('Success', 'Successfully received camera preview details ', Date.now(), data, 'Preview'))
                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(appLog("error", response))
            })
    }
}

export function loadingPreviewImage(status) {
    return { type: types.CAMERA_CONTROL_LOADING_PREVIEW_IMAGE, status: status }
}

export function previewQuality(quality) {
    return { type: types.CAMERA_CONTROL_PREVIEW_QUALITY, quality: quality }
}


export function previewQualityVersion(quality, version) {
    return { type: types.CAMERA_CONTROL_PREVIEW_VERSION_QUALITY, version: version, quality: quality }
}

export function updateProperties(cameraId, properties) {
    return (dispatch, getState) => {
        dispatch( { type: types.CAMERA_CONTROL_UPDATE_PROPERTIES_REQUEST, cameras_id: cameraId, properties: properties })
        // dispatch(log('Info', 'Updating camera properties...', Date.now()))
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control/properties` ,
            {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(properties)
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    // Remember, we're bouncing out 401s
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_UPDATE_PROPERTIES_RESPONSE_ERRORS,
                                    errors: data.errors,
                                    cameras_id: cameraId
                                })
                            dispatch(log('Error', 'There was an error while updating camera properties', Date.now()))
                        } else {
                            // Error
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_UPDATE_PROPERTIES_RESPONSE_ERRORS,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error"
                                        }
                                    },
                                    cameras_id: cameraId
                                }
                            )
                            dispatch(log('Error', 'There was an unexpected error while updating camera properties', Date.now()))
                        }

                    })
                }
            } else {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.CAMERA_CONTROL_UPDATE_PROPERTIES_RESPONSE_ERRORS,
                                errors: data.errors,
                                cameras_id: cameraId,
                            }
                        )
                        dispatch(log('Error', 'There was an error while updating camera properties', Date.now()))
                    } else {
                        dispatch(
                            {
                                type: types.CAMERA_CONTROL_UPDATE_PROPERTIES_RESPONSE_SUCCESS,
                                details: data,
                                cameras_id: cameraId
                            }
                        )
                        dispatch(log('Info', 'Successfully updated camera properties', Date.now(), properties, 'UpdateProperty'))

                    }

                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(appLog("error", response))
        })

    }
}


export function fetchProperties(cameraId) {
    return (dispatch, getState) => {
        dispatch( { type: types.CAMERA_CONTROL_PROPERTIES_REQUEST, cameras_id: cameraId })

        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control/properties` ,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_PROPERTIES_RESPONSE_ERRORS,
                                        errors: data.errors,
                                    })
                                dispatch(log('Error', 'There was an error while fetching camera properties', Date.now()))
                            } else {
                                // Error
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_PROPERTIES_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        }
                                    }
                                )
                                dispatch(log('Error', 'There was an unexpected error while fetching camera properties', Date.now()))
                            }

                        })
                    }
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_PROPERTIES_RESPONSE_ERRORS,
                                    errors: data.errors,
                                }
                            )
                            dispatch(log('Error', 'There was an error while fetching camera properties', Date.now()))
                        } else {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_PROPERTIES_RESPONSE_SUCCESS,
                                    details: data
                                }
                            )
                            // dispatch(log('Info', 'Successfully fetched camera properties', Date.now()))

                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(appLog("error", response))
            })

    }
}


export function resetUpdateSystem() {
    return { type: types.CAMERA_CONTROL_UPDATE_SYSTEM_RESET }
}

export function resetUpdateApn() {
    return { type: types.CAMERA_CONTROL_UPDATE_APN_RESET }
}

export function updateSystem(cameraId, properties) {


    return (dispatch, getState) => {
        dispatch({
            type: types.CAMERA_CONTROL_UPDATE_SYSTEM_REQUEST,
            cameras_id: cameraId,
            properties: properties
        })

        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control/system`,
            {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(properties)
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    // Remember, we're bouncing out 401s
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_UPDATE_SYSTEM_RESPONSE_ERRORS,
                                    errors: data.errors,
                                    cameras_id: cameraId
                                })
                            dispatch(log('Error', 'There was an error while updating camera system properties', Date.now()))
                        } else {
                            // Error
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_UPDATE_SYSTEM_RESPONSE_ERRORS,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error"
                                        }
                                    },
                                    cameras_id: cameraId
                                }
                            )
                            dispatch(log('Error', 'There was an unexpected error while updating camera system properties', Date.now()))
                        }

                    })
                }
            } else {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.CAMERA_CONTROL_UPDATE_SYSTEM_RESPONSE_ERRORS,
                                errors: data.errors,
                                cameras_id: cameraId,
                            }
                        )
                        dispatch(log('Error', 'There was an error while updating camera system properties', Date.now()))
                    } else {
                        dispatch(
                            {
                                type: types.CAMERA_CONTROL_UPDATE_SYSTEM_RESPONSE_SUCCESS,
                                details: data,
                                cameras_id: cameraId,
                                properties: properties
                            }
                        )

                        dispatch(log('Info', 'Successfully updated camera system properties', Date.now(), properties, 'UpdateSystemProperty'))

                    }

                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(appLog("error", response))
        })

    }

}

export function fetchSystem(cameraId) {
    return (dispatch, getState) => {
        dispatch( { type: types.CAMERA_CONTROL_SYSTEM_REQUEST, cameras_id: cameraId })

        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control/system` ,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_SYSTEM_RESPONSE_ERRORS,
                                        errors: data.errors,
                                    })
                                dispatch(log('Error', 'There was an error while fetching camera system details', Date.now()))
                            } else {
                                // Error
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_SYSTEM_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        }
                                    }
                                )
                                dispatch(log('Error', 'There was an unexpected error while fetching camera system details', Date.now()))
                            }

                        })
                    }
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_SYSTEM_RESPONSE_ERRORS,
                                    errors: data.errors,
                                }
                            )
                            dispatch(log('Error', 'There was an error while fetching camera system details', Date.now()))
                        } else {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_SYSTEM_RESPONSE_SUCCESS,
                                    details: data
                                }
                            )
                            // dispatch(log('Info', 'Successfully fetched camera properties', Date.now()))

                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(appLog("error", response))
            })

    }
}

export function fetchLogs(cameraId) {
    return (dispatch, getState) => {
        dispatch( { type: types.CAMERA_CONTROL_LOGS_REQUEST, cameras_id: cameraId })
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control/logs` ,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    // Remember, we're bouncing out 401s
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_LOGS_RESPONSE_ERRORS,
                                    errors: data.errors,
                                    cameras_id: cameraId
                                })
                            dispatch(log('Error', 'There was an error while fetching camera logs', Date.now()))
                        } else {
                            // Error
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_LOGS_RESPONSE_ERRORS,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error"
                                        }
                                    },
                                    cameras_id: cameraId
                                }
                            )
                            dispatch(log('Error', 'There was an unexpected error while fetching camera logs', Date.now()))
                        }

                    })
                }
            } else {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.CAMERA_CONTROL_LOGS_RESPONSE_ERRORS,
                                errors: data.errors,
                                cameras_id: cameraId
                            }
                        )
                        dispatch(log('Error', 'There was an error while fetching camera logs', Date.now()))
                    } else {
                        dispatch(
                            {
                                type: types.CAMERA_CONTROL_LOGS_RESPONSE_SUCCESS,
                                details: data,
                                cameras_id: cameraId
                            }
                        )
                    }

                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(appLog("error", response))
        })
    }
}

export function startScheduler(cameraId) {
    return (dispatch, getState) => {
        dispatch({ type: types.CAMERA_CONTROL_SCHEDULER_START_REQUEST, cameras_id: cameraId  })
        // Let's also call log so that it appears in the LogViewer...
        dispatch(log('Info', 'Requesting camera scheduler start', Date.now()))
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control/scheduler/start` ,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_SCHEDULER_START_RESPONSE_ERRORS,
                                        errors: data.errors,
                                        cameras_id: cameraId
                                    })
                                dispatch(log('Error', 'There was an error while requesting camera scheduler start', Date.now()))
                            } else {
                                // Error
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_SCHEDULER_START_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        },
                                        cameras_id: cameraId
                                    }
                                )
                                dispatch(log('Error', 'There was an unexpected error while requesting camera scheduler start', Date.now()))
                            }

                        })
                    }
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_SCHEDULER_START_RESPONSE_ERRORS,
                                    errors: data.errors,
                                    cameras_id: cameraId
                                }
                            )
                            dispatch(log('Error', 'There was an error while requesting camera scheduler start', Date.now()))
                        } else {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_SCHEDULER_START_RESPONSE_SUCCESS,
                                    details: data,
                                    cameras_id: cameraId
                                }
                            )
                            dispatch(log('Success', 'Successfully started camera scheduler ', Date.now()))
                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(appLog("error", response))
            })
    }
}

export function stopScheduler(cameraId) {
    return (dispatch, getState) => {
        dispatch({ type: types.CAMERA_CONTROL_SCHEDULER_STOP_REQUEST, cameras_id: cameraId  })
        // Let's also call log so that it appears in the LogViewer...
        dispatch(log('Info', 'Requesting camera scheduler stop', Date.now()))
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control/scheduler/stop` ,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_SCHEDULER_STOP_RESPONSE_ERRORS,
                                        errors: data.errors,
                                        cameras_id: cameraId
                                    })
                                dispatch(log('Error', 'There was an error while requesting camera scheduler stop', Date.now()))
                            } else {
                                // Error
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_SCHEDULER_STOP_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        },
                                        cameras_id: cameraId
                                    }
                                )
                                dispatch(log('Error', 'There was an unexpected error while requesting camera scheduler stop', Date.now()))
                            }

                        })
                    }
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_SCHEDULER_STOP_RESPONSE_ERRORS,
                                    errors: data.errors,
                                    cameras_id: cameraId
                                }
                            )
                            dispatch(log('Error', 'There was an error while requesting camera scheduler stop', Date.now()))
                        } else {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_SCHEDULER_STOP_RESPONSE_SUCCESS,
                                    details: data,
                                    cameras_id: cameraId
                                }
                            )

                            dispatch(log('Success', 'Successfully stopped camera scheduler ', Date.now()))
                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(appLog("error", response))
            })
    }
}

export function restartScheduler(cameraId) {
    return (dispatch, getState) => {
        dispatch({ type: types.CAMERA_CONTROL_SCHEDULER_RESTART_REQUEST, cameras_id: cameraId  })
        // Let's also call log so that it appears in the LogViewer...
        dispatch(log('Info', 'Requesting camera scheduler restart', Date.now()))
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control/scheduler/restart` ,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_SCHEDULER_RESTART_RESPONSE_ERRORS,
                                        errors: data.errors,
                                        cameras_id: cameraId
                                    })
                                dispatch(log('Error', 'There was an error while requesting camera scheduler restart', Date.now()))
                            } else {
                                // Error
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_SCHEDULER_RESTART_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        },
                                        cameras_id: cameraId
                                    }
                                )
                                dispatch(log('Error', 'There was an unexpected error while requesting camera scheduler restart', Date.now()))
                            }

                        })
                    }
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_SCHEDULER_RESTART_RESPONSE_ERRORS,
                                    errors: data.errors,
                                    cameras_id: cameraId
                                }
                            )
                            dispatch(log('Error', 'There was an error while requesting camera scheduler restart', Date.now()))
                        } else {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_SCHEDULER_RESTART_RESPONSE_SUCCESS,
                                    details: data,
                                    cameras_id: cameraId
                                }
                            )
                            dispatch(log('Success', 'Successfully restarted camera scheduler ', Date.now()))
                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(appLog("error", response))
            })
    }
}

export function fetchApn(cameraId) {
    return (dispatch, getState) => {
        dispatch({ type: types.CAMERA_CONTROL_FETCH_APN_REQUEST, cameras_id: cameraId  })
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control/apn` ,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    // Remember, we're bouncing out 401s
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_FETCH_APN_RESPONSE_ERRORS,
                                    errors: data.errors,
                                    cameras_id: cameraId
                                })
                            // dispatch(log('Error', 'There was an error while requesting camera APN', Date.now()))
                        } else {
                            // Error
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_FETCH_APN_RESPONSE_ERRORS,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error"
                                        }
                                    },
                                    cameras_id: cameraId
                                }
                            )
                            // dispatch(log('Error', 'There was an unexpected error while requesting camera APN', Date.now()))
                        }

                    })
                }
            } else {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.CAMERA_CONTROL_FETCH_APN_RESPONSE_ERRORS,
                                errors: data.errors,
                                cameras_id: cameraId
                            }
                        )
                        // dispatch(log('Error', 'There was an error while requesting camera APN', Date.now()))
                    } else {
                        dispatch(
                            {
                                type: types.CAMERA_CONTROL_FETCH_APN_RESPONSE_SUCCESS,
                                details: data,
                                cameras_id: cameraId
                            }
                        )
                    }

                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(appLog("error", response))
        })
    }
}


export function updateApn(cameraId, apn) {
    return (dispatch, getState) => {
        dispatch({ type: types.CAMERA_CONTROL_UPDATE_APN_REQUEST, cameras_id: cameraId, apn: apn })
        // Let's also call log so that it appears in the LogViewer...
        dispatch(log('Info', 'Updating Camera APN...', Date.now()))
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control/apn` ,
            {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    apn: apn
                })
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_UPDATE_APN_RESPONSE_ERRORS,
                                        errors: data.errors,
                                        cameras_id: cameraId
                                    })
                                // dispatch(log('Error', 'There was an error while updating camera APN', Date.now()))
                            } else {
                                // Error
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_UPDATE_APN_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        },
                                        cameras_id: cameraId
                                    }
                                )
                                // dispatch(log('Error', 'There was an error while updating camera APN', Date.now()))
                            }

                        })
                    }
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_UPDATE_APN_RESPONSE_ERRORS,
                                    errors: data.errors,
                                    cameras_id: cameraId
                                }
                            )
                            // dispatch(log('Error', 'There was an error while updating camera APN', Date.now()))
                        } else {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_UPDATE_APN_RESPONSE_SUCCESS,
                                    details: data,
                                    cameras_id: cameraId
                                }
                            )
                            // dispatch(log('Success', 'Successfully updated camera APN', Date.now()))
                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(appLog("error", response))
            })
    }
}


export function fetchWifi(cameraId) {
    return (dispatch, getState) => {
        dispatch({ type: types.CAMERA_CONTROL_FETCH_WIFI_REQUEST, cameras_id: cameraId  })
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control/wifi` ,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_FETCH_WIFI_RESPONSE_ERRORS,
                                        errors: data.errors,
                                        cameras_id: cameraId
                                    })
                                // dispatch(log('Error', 'There was an error while requesting camera APN', Date.now()))
                            } else {
                                // Error
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_FETCH_WIFI_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        },
                                        cameras_id: cameraId
                                    }
                                )
                                // dispatch(log('Error', 'There was an unexpected error while requesting camera APN', Date.now()))
                            }

                        })
                    }
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_FETCH_WIFI_RESPONSE_ERRORS,
                                    errors: data.errors,
                                    cameras_id: cameraId
                                }
                            )
                            // dispatch(log('Error', 'There was an error while requesting camera APN', Date.now()))
                        } else {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_FETCH_WIFI_RESPONSE_SUCCESS,
                                    details: data,
                                    cameras_id: cameraId
                                }
                            )
                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(appLog("error", response))
            })
    }
}






export function startWifi(cameraId) {
    return (dispatch, getState) => {
        dispatch({ type: types.CAMERA_CONTROL_START_WIFI_REQUEST, cameras_id: cameraId  })
        // Let's also call log so that it appears in the LogViewer...
        dispatch(log('Info', 'Requesting camera wifi start', Date.now()))
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control/wifi/start` ,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_START_WIFI_RESPONSE_ERRORS,
                                        errors: data.errors,
                                        cameras_id: cameraId
                                    })
                                dispatch(log('Error', 'There was an error while requesting camera wifi start', Date.now()))
                            } else {
                                // Error...might be a message
                                let message = "There was an unexpected error while requesting camera wifi start"
                                if (data.message) {
                                    message = data.message
                                }

                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_START_WIFI_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: message
                                            }
                                        },
                                        cameras_id: cameraId
                                    }
                                )
                                dispatch(log('Error', 'There was an unexpected error while requesting camera wifi start', Date.now()))
                            }

                        })
                    }
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_START_WIFI_RESPONSE_ERRORS,
                                    errors: data.errors,
                                    cameras_id: cameraId
                                }
                            )
                            dispatch(log('Error', 'There was an error while requesting camera wifi start', Date.now()))
                        } else {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_START_WIFI_RESPONSE_SUCCESS,
                                    details: data,
                                    cameras_id: cameraId
                                }
                            )
                            dispatch(log('Success', 'Successfully started camera wifi ', Date.now()))
                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(appLog("error", response))
            })
    }
}

export function stopWifi(cameraId) {
    return (dispatch, getState) => {
        dispatch({ type: types.CAMERA_CONTROL_STOP_WIFI_REQUEST, cameras_id: cameraId  })
        // Let's also call log so that it appears in the LogViewer...
        dispatch(log('Info', 'Requesting camera wifi stop', Date.now()))
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control/wifi/stop` ,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_STOP_WIFI_RESPONSE_ERRORS,
                                        errors: data.errors,
                                        cameras_id: cameraId
                                    })
                                dispatch(log('Error', 'There was an error while requesting camera wifi stop', Date.now()))
                            } else {
                                // Error
                                let message = "There was an unexpected error while requesting camera wifi stop"
                                if (data.message) {
                                    message = data.message
                                }
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_STOP_WIFI_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: message
                                            }
                                        },
                                        cameras_id: cameraId
                                    }
                                )
                                dispatch(log('Error', 'There was an unexpected error while requesting camera wifi stop', Date.now()))
                            }

                        })
                    }
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_STOP_WIFI_RESPONSE_ERRORS,
                                    errors: data.errors,
                                    cameras_id: cameraId
                                }
                            )
                            dispatch(log('Error', 'There was an error while requesting camera wifi stop', Date.now()))
                        } else {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_STOP_WIFI_RESPONSE_SUCCESS,
                                    details: data,
                                    cameras_id: cameraId
                                }
                            )

                            dispatch(log('Success', 'Successfully stopped camera wifi ', Date.now()))
                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(appLog("error", response))
            })
    }
}

export function restartWifi(cameraId) {
    return (dispatch, getState) => {
        dispatch({ type: types.CAMERA_CONTROL_RESTART_WIFI_REQUEST, cameras_id: cameraId  })
        // Let's also call log so that it appears in the LogViewer...
        dispatch(log('Info', 'Requesting camera wifi restart', Date.now()))
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control/wifi/restart` ,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_RESTART_WIFI_RESPONSE_ERRORS,
                                        errors: data.errors,
                                        cameras_id: cameraId
                                    })
                                dispatch(log('Error', 'There was an error while requesting camera wifi restart', Date.now()))
                            } else {
                                // Error
                                let message = "There was an unexpected error while requesting camera wifi restart"
                                if (data.message) {
                                    message = data.message
                                }
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_RESTART_WIFI_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: message
                                            }
                                        },
                                        cameras_id: cameraId
                                    }
                                )
                                dispatch(log('Error', 'There was an unexpected error while requesting camera wifi restart', Date.now()))
                            }

                        })
                    }
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_RESTART_WIFI_RESPONSE_ERRORS,
                                    errors: data.errors,
                                    cameras_id: cameraId
                                }
                            )
                            dispatch(log('Error', 'There was an error while requesting camera wifi restart', Date.now()))
                        } else {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_RESTART_WIFI_RESPONSE_SUCCESS,
                                    details: data,
                                    cameras_id: cameraId
                                }
                            )
                            dispatch(log('Success', 'Successfully restarted camera wifi ', Date.now()))
                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(appLog("error", response))
            })
    }
}


export function rebootCameraBody(cameraId) {
    return (dispatch, getState) => {
        dispatch({ type: types.CAMERA_CONTROL_REBOOT_CAMERA_BODY_REQUEST, cameras_id: cameraId  })
        // Let's also call log so that it appears in the LogViewer...
        dispatch(log('Info', 'Requesting camera reboot', Date.now()))
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control/camera-body/reboot` ,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_REBOOT_CAMERA_BODY_RESPONSE_ERRORS,
                                        errors: data.errors,
                                        cameras_id: cameraId
                                    })
                                dispatch(log('Error', 'There was an error while requesting camera reboot', Date.now()))
                            } else {
                                // Error
                                let message = "There was an unexpected error while requesting camera reboot"
                                if (data.message) {
                                    message = data.message
                                }
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_REBOOT_CAMERA_BODY_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: message
                                            }
                                        },
                                        cameras_id: cameraId
                                    }
                                )
                                dispatch(log('Error', 'There was an unexpected error while requesting camera reboot', Date.now()))
                            }

                        })
                    }
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_REBOOT_CAMERA_BODY_RESPONSE_ERRORS,
                                    errors: data.errors,
                                    cameras_id: cameraId
                                }
                            )
                            dispatch(log('Error', 'There was an error while requesting camera reboot', Date.now()))
                        } else {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_REBOOT_CAMERA_BODY_RESPONSE_SUCCESS,
                                    details: data,
                                    cameras_id: cameraId
                                }
                            )
                            dispatch(log('Success', 'Successfully rebooted camera', Date.now()))
                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(appLog("error", response))
            })
    }
}

export function resetRebootCameraBody() {
    return { type: types.CAMERA_CONTROL_REBOOT_CAMERA_BODY_RESET }
}

export function runTests(cameraId) {
    return (dispatch, getState) => {
        dispatch({ type: types.CAMERA_CONTROL_RUN_TESTS_REQUEST, cameras_id: cameraId  })
        // Let's also call log so that it appears in the LogViewer...
        // dispatch(log('Info', 'Requesting camera tests', Date.now()))
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control/run-test` ,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_RUN_TESTS_RESPONSE_ERRORS,
                                        errors: data.errors,
                                        cameras_id: cameraId
                                    })
                                // dispatch(log('Error', 'There was an error while requesting camera reboot', Date.now()))
                            } else {
                                // Error
                                let message = "There was an unexpected error while requesting camera reboot"
                                if (data.message) {
                                    message = data.message
                                }
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_RUN_TESTS_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: message
                                            }
                                        },
                                        cameras_id: cameraId
                                    }
                                )
                                // dispatch(log('Error', 'There was an unexpected error while requesting camera reboot', Date.now()))
                            }

                        })
                    }
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_RUN_TESTS_RESPONSE_ERRORS,
                                    errors: data.errors,
                                    cameras_id: cameraId
                                }
                            )
                            // dispatch(log('Error', 'There was an error while requesting camera reboot', Date.now()))
                        } else {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_RUN_TESTS_RESPONSE_SUCCESS,
                                    details: data,
                                    cameras_id: cameraId
                                }
                            )

                            // Only use this if you want to simulate streaming the response
                            // dispatch(testOutputStream(data))
                            // dispatch(log('Success', 'Successfully rebooted camera', Date.now()))
                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(appLog("error", response))
            })
    }
}

export function setTestsVisibility(visibility) {
    return { type: types.CAMERA_CONTROL_TESTS_VISIBILITY, visibility: visibility }
}

export function setTestProcessing(processing) {
    return { type: types.CAMERA_CONTROL_TEST_PROCESSING, processing: processing }
}

export function resetTests() {
    return { type: types.CAMERA_CONTROL_RUN_TESTS_RESET }
}

/**
 *
 * This is to simulate streaming the server-side response
 *
 */
export function testOutputStream(data) {

    return dispatch => {

        dispatch(setTestsVisibility(true))
        dispatch(setTestProcessing(true))

        let output = data

        const intervalId = setInterval(() => {
            if (output.length === 0) {
                clearInterval(intervalId);
                dispatch(setTestProcessing(false))
            }
            const stream = output.shift()
            dispatch({type: types.CAMERA_CONTROL_TEST_OUTPUT_STREAM, stream: stream})

        }, 100)
    }
}


export function fetchDataTransfer(cameraId) {
    return (dispatch, getState) => {
        dispatch({ type: types.CAMERA_CONTROL_DATA_TRANSFER_REQUEST, cameras_id: cameraId  })
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control/data-transfer`,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_DATA_TRANSFER_RESPONSE_ERRORS,
                                        errors: data.errors,
                                    })
                            } else {
                                // Error
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_DATA_TRANSFER_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        }
                                    }
                                )
                                // dispatch(log('Error', 'There was an unexpected error while requesting camera status', Date.now()))
                            }

                        })
                    }
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_DATA_TRANSFER_RESPONSE_ERRORS,
                                    errors: data.errors,
                                }
                            )
                            dispatch(log('Error', 'There was an error while requesting camera status', Date.now()))
                        } else {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_DATA_TRANSFER_RESPONSE_SUCCESS,
                                    details: data
                                }
                            )
                            // dispatch(log('Info', 'Successfully received camera status details ', Date.now(), data))
                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(appLog("error", response))
            })
    }
}

export function resetUpdateDataTransfer() {
    return { type: types.CAMERA_CONTROL_DATA_TRANSFER_UPDATE_RESET }
}

export function updateDataTransfer(cameraId, settings) {

    return (dispatch, getState) => {
        dispatch({
            type: types.CAMERA_CONTROL_DATA_TRANSFER_UPDATE_REQUEST,
            cameras_id: cameraId,
            settings: settings
        })

        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control/data-transfer`,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(settings)
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_DATA_TRANSFER_UPDATE_RESPONSE_ERRORS,
                                        errors: data.errors,
                                        cameras_id: cameraId
                                    })
                                dispatch(log('Error', 'There was an error while updating camera SIM details', Date.now()))
                            } else {
                                // Error
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_DATA_TRANSFER_UPDATE_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        },
                                        cameras_id: cameraId
                                    }
                                )
                                dispatch(log('Error', 'There was an unexpected error while updating camera SIM details', Date.now()))
                            }

                        })
                    }
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_DATA_TRANSFER_UPDATE_RESPONSE_ERRORS,
                                    errors: data.errors,
                                    cameras_id: cameraId,
                                }
                            )
                            dispatch(log('Error', 'There was an error while updating camera SIM details', Date.now()))
                        } else {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_DATA_TRANSFER_UPDATE_RESPONSE_SUCCESS,
                                    details: data,
                                    cameras_id: cameraId,
                                    settings: settings
                                }
                            )

                            dispatch(log('Info', 'Successfully updated camera SIM details', Date.now()))

                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(appLog("error", response))
            })

    }

}

export function fetchSignalStrength(cameraId) {
    return (dispatch, getState) => {
        dispatch({ type: types.CAMERA_CONTROL_SIGNAL_STRENGTH_REQUEST, cameras_id: cameraId  })
        return fetchWithToken(`
            ${API_BASE_URL}/api/cameras/${cameraId}/camera-control/signal-strength`,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_SIGNAL_STRENGTH_RESPONSE_ERRORS,
                                        errors: data.errors,
                                    })
                            } else {
                                // Error
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_SIGNAL_STRENGTH_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        }
                                    }
                                )
                            }

                        })
                    }
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_SIGNAL_STRENGTH_RESPONSE_ERRORS,
                                    errors: data.errors,
                                }
                            )
                            dispatch(log('Error', 'There was an error while requesting signal strength', Date.now()))
                        } else {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_SIGNAL_STRENGTH_RESPONSE_SUCCESS,
                                    details: data
                                }
                            )
                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(appLog("error", response))
            })
    }
}

export function ping(cameraId) {
    return (dispatch, getState) => {
        dispatch({ type: types.CAMERA_CONTROL_PING_REQUEST, cameras_id: cameraId  })
        // Let's also call log so that it appears in the LogViewer...
        // dispatch(log('Info', 'Requesting camera status', Date.now()))
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control/ping` ,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_PING_RESPONSE_ERRORS,
                                        errors: data.errors,
                                    })
                                dispatch(log('Error', 'There was an error whilst pinging the camera', Date.now()))
                            } else {
                                // Error
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_PING_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        }
                                    }
                                )
                                dispatch(log('Error', 'There was an unexpected error whilst pinging the camera', Date.now()))
                            }

                        })
                    }
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_PING_RESPONSE_ERRORS,
                                    errors: data.errors,
                                }
                            )
                            dispatch(log('Error', 'There was an error whilst pinging the camera', Date.now()))
                        } else {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_PING_RESPONSE_SUCCESS,
                                    response: data.message
                                }
                            )
                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(appLog("error", response))
            })
    }
}

export function resetLoadingIndicators() {
    return {
        type: types.CAMERA_CONTROL_RESET_LOADING_INDICATORS
    }
}

export function fetchTest(cameraId) {
    return (dispatch, getState) => {
        dispatch( { type: types.CAMERA_CONTROL_TEST_RESULTS_REQUEST, cameras_id: cameraId })
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control/test-result`,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    // Remember, we're bouncing out 401s
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_TEST_RESULTS_ERRORS,
                                    errors: data.errors
                                })
                            dispatch(log('Error', 'There was an error while fetching the test logs', Date.now()))
                        } else {
                            // Error
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_TEST_RESULTS_ERRORS,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error"
                                        }
                                    },
                                }
                            )
                            dispatch(log('Error', 'There was an unexpected error while fetching the test logs', Date.now()))
                        }

                    })
                }
            } else {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.CAMERA_CONTROL_TEST_RESULTS_ERRORS,
                                errors: data.errors,
                            }
                        )
                        dispatch(log('Error', 'There was an error while fetching the test logs', Date.now()))
                    } else {
                        dispatch(
                            {
                                type: types.CAMERA_CONTROL_TEST_RESULTS_SUCCESS,
                                tests: data,
                            }
                        )
                    }

                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(appLog("error", response))
        })
    }
}

export function fetchTestDetails(cameraId, formattedDate) {
    return (dispatch, getState) => {
        dispatch( { type: types.CAMERA_CONTROL_TEST_RESULT_DETAILS_REQUEST, cameras_id: cameraId, date: formattedDate })
        const date = (formattedDate)? `/${formattedDate}.json` : ''
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control/test-result${date}` ,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    // Remember, we're bouncing out 401s
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_TEST_RESULT_DETAILS_ERRORS,
                                    errors: data.errors
                                })
                            dispatch(log('Error', 'There was an error while fetching the test logs', Date.now()))
                        } else {
                            // Error
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_TEST_RESULT_DETAILS_ERRORS,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error"
                                        }
                                    },
                                }
                            )
                            dispatch(log('Error', 'There was an unexpected error while fetching the test logs', Date.now()))
                        }

                    })
                }
            } else {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.CAMERA_CONTROL_TEST_RESULT_DETAILS_ERRORS,
                                errors: data.errors,
                            }
                        )
                        dispatch(log('Error', 'There was an error while fetching the test logs', Date.now()))
                    } else {
                        dispatch(
                            {
                                type: types.CAMERA_CONTROL_TEST_RESULT_DETAILS_SUCCESS,
                                testDetails: data,
                            }
                        )
                    }

                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(appLog("error", response))
        })
    }
}