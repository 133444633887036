import * as types from './actionTypes'
import { API_BASE_URL}  from '../config'
import { addMessage } from './messengerActions'
import { log } from './logActions'
import { notAuthorisedResponse } from './authActions'
import { fetchWithToken } from '../util/accessToken'


export function addImages(cameraId, images, callback = null) {
    return (dispatch, getState) => {
        dispatch({ type: types.CAMERA_CONTROL_MODIFY_UPLOAD_QUEUE_REQUEST})
        const body = { add: images }
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control/upload-queue`,
            {
                method: 'PATCH',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(body)
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_MODIFY_UPLOAD_QUEUE_ERRORS,
                                    errors: data.errors,
                                })
                            dispatch(
                                log(
                                    'Error',
                                    'There was an error while adding the images to the upload queue'
                                )
                            )
                        } else {
                            // Error
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_MODIFY_UPLOAD_QUEUE_ERRORS,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error"
                                        }
                                    }
                                }
                            )
                            dispatch(
                                log(
                                    'Error',
                                    'There was an error while adding the images to the upload queue'
                                )
                            )
                        }
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to add images to queue",
                                "There was an error while adding the images to the upload queue."
                            )
                        )
                    })
                }
            } else {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.CAMERA_CONTROL_MODIFY_UPLOAD_QUEUE_ERRORS,
                                errors: data.errors,
                            }
                        )
                        dispatch(
                            log(
                                'Error',
                                'There was an error while requesting camera status'
                            )
                        )
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to add images to queue",
                                "There was an error while adding the images to the upload queue."
                            )
                        )
                    } else {
                        dispatch(
                            {
                                type: types.CAMERA_CONTROL_MODIFY_UPLOAD_QUEUE_SUCCESS
                            }
                        )
                        dispatch(
                            {
                                type: types.CAMERA_CONTROL_DROP_MODIFY_LIST,
                                ...data
                            }
                        )
                        dispatch(load(cameraId))
                        if (callback) {
                            callback()
                        }
                    }

                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function removeImages(cameraId, images, callback = null) {
    return (dispatch, getState) => {
        dispatch({ type: types.CAMERA_CONTROL_MODIFY_UPLOAD_QUEUE_REQUEST})
        const body = { remove: images }
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control/upload-queue`,
            {
                method: 'PATCH',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(body)
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_MODIFY_UPLOAD_QUEUE_ERRORS,
                                    errors: data.errors,
                                })
                            dispatch(
                                log(
                                    'Error',
                                    'There was an error while removing the images to the upload queue.'
                                )
                            )
                        } else {
                            // Error
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_MODIFY_UPLOAD_QUEUE_ERRORS,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error"
                                        }
                                    }
                                }
                            )
                            dispatch(
                                log(
                                    'Error',
                                    'There was an error while removing the images to the upload queue.'
                                )
                            )
                        }
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to remove images from queue",
                                "There was an error while removing the images to the upload queue."
                            )
                        )
                    })
                }
            } else {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.CAMERA_CONTROL_MODIFY_UPLOAD_QUEUE_ERRORS,
                                errors: data.errors,
                            }
                        )
                        dispatch(
                            log(
                                'Error',
                                'There was an error while removing the images to the upload queue.'
                            )
                        )
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to remove images from queue",
                                "There was an error while removing the images to the upload queue.."
                            )
                        )
                    } else {
                        dispatch(
                            {
                                type: types.CAMERA_CONTROL_MODIFY_UPLOAD_QUEUE_SUCCESS
                            }
                        )
                        dispatch(
                            {
                                type: types.CAMERA_CONTROL_DROP_MODIFY_LIST,
                                ...data
                            }
                        )
                        dispatch(load(cameraId))
                        if (callback) {
                            callback()
                        }
                    }

                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function load(cameraId) {
    return (dispatch, getState) => {
        dispatch({ type: types.CAMERA_CONTROL_FETCH_UPLOAD_QUEUE_REQUEST})
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control/upload-queue`,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_FETCH_UPLOAD_QUEUE_ERRORS,
                                    errors: data.errors,
                                })
                            dispatch(
                                log(
                                    'Error',
                                    'There was an error while requesting the upload queue'
                                )
                            )
                        } else {
                            // Error
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_FETCH_UPLOAD_QUEUE_ERRORS,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error"
                                        }
                                    }
                                }
                            )
                            dispatch(
                                log(
                                    'Error',
                                    'There was an error while requesting the upload queue.'
                                )
                            )
                        }
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to load queue",
                                "There was an error while requesting the upload queue."
                            )
                        )
                    })
                }
            } else {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.CAMERA_CONTROL_FETCH_UPLOAD_QUEUE_ERRORS,
                                errors: data.errors,
                            }
                        )
                        dispatch(
                            log(
                                'Error',
                                'There was an error while requesting the upload queue.'
                            )
                        )
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to load queue",
                                "There was an error while requesting the upload queue."
                            )
                        )
                    } else {
                        dispatch(
                            {
                                type: types.CAMERA_CONTROL_FETCH_UPLOAD_QUEUE_SUCCESS,
                                ...data
                            }
                        )
                    }

                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function clear(cameraId, callback = null) {
    return (dispatch, getState) => {
        dispatch({ type: types.CAMERA_CONTROL_CLEAR_UPLOAD_QUEUE_REQUEST})
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control/upload-queue`,
            {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_CLEAR_UPLOAD_QUEUE_ERRORS,
                                    errors: data.errors,
                                })
                            dispatch(
                                log(
                                    'Error',
                                    'There was an error while requesting to clear the queue.'
                                )
                            )
                        } else {
                            // Error
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_CLEAR_UPLOAD_QUEUE_ERRORS,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error"
                                        }
                                    }
                                }
                            )
                            dispatch(
                                log(
                                    'Error',
                                    'There was an error while requesting to clear the queue.'
                                )
                            )
                        }
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to clear queue",
                                "There was an error while requesting to clear the queue."
                            )
                        )
                    })
                }
            } else {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.CAMERA_CONTROL_CLEAR_UPLOAD_QUEUE_ERRORS,
                                errors: data.errors,
                            }
                        )
                        dispatch(
                            log(
                                'Error',
                                'There was an error while requesting to clear the queue.'
                            )
                        )
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to clear queue",
                                "There was an error while requesting to clear the queue."
                            )
                        )
                    } else {
                        dispatch(
                            {
                                type: types.CAMERA_CONTROL_CLEAR_UPLOAD_QUEUE_SUCCESS
                            }
                        )
                    }
                    if (callback) {
                        callback()
                    }
                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function start(cameraId) {
    return (dispatch, getState) => {
        dispatch({ type: types.CAMERA_CONTROL_START_STOP_UPLOAD_QUEUE_REQUEST})
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control/upload-queue/start`,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_START_STOP_UPLOAD_QUEUE_ERRORS,
                                    errors: data.errors,
                                })
                            dispatch(
                                log(
                                    'Error',
                                    'There was an error while requesting to start the queue.'
                                )
                            )
                        } else {
                            // Error
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_START_STOP_UPLOAD_QUEUE_ERRORS,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error"
                                        }
                                    }
                                }
                            )
                            dispatch(
                                log(
                                    'Error',
                                    'There was an error while requesting to start the queue.'
                                )
                            )
                        }
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to start queue",
                                "There was an error while requesting to start the queue."
                            )
                        )
                    })
                }
            } else {
                if (response.status === 200) {
                    dispatch(
                        {
                            type: types.CAMERA_CONTROL_START_STOP_UPLOAD_QUEUE_SUCCESS,
                            started: true
                        }
                    )
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_START_STOP_UPLOAD_QUEUE_ERRORS,
                                    errors: data.errors,
                                }
                            )
                            dispatch(
                                log(
                                    'Error',
                                    'There was an error while requesting to start the queue.'
                                )
                            )
                            dispatch(
                                addMessage(
                                    "negative",
                                    "Unable to start queue",
                                    "There was an error while requesting to start the queue."
                                )
                            )
                        } else {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_START_STOP_UPLOAD_QUEUE_SUCCESS,
                                    started: true
                                }
                            )
                        }
                    })
                }
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function stop(cameraId) {
    return (dispatch, getState) => {
        dispatch({ type: types.CAMERA_CONTROL_START_STOP_UPLOAD_QUEUE_REQUEST})
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control/upload-queue/stop`,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_START_STOP_UPLOAD_QUEUE_ERRORS,
                                    errors: data.errors,
                                })
                            dispatch(
                                log(
                                    'Error',
                                    'There was an error while requesting to stop the queue.'
                                )
                            )
                        } else {
                            // Error
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_START_STOP_UPLOAD_QUEUE_ERRORS,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error"
                                        }
                                    }
                                }
                            )
                            dispatch(
                                log(
                                    'Error',
                                    'There was an error while requesting to stop the queue.'
                                )
                            )
                        }
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to stop queue",
                                "There was an error while requesting to stop the queue."
                            )
                        )
                    })
                }
            } else {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.CAMERA_CONTROL_START_STOP_UPLOAD_QUEUE_ERRORS,
                                errors: data.errors,
                            }
                        )
                        dispatch(
                            log(
                                'Error',
                                'There was an error while requesting to stop the queue.'
                            )
                        )
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to stop queue",
                                "There was an error while requesting to stop the queue."
                            )
                        )
                    } else {
                        dispatch(
                            {
                                type: types.CAMERA_CONTROL_START_STOP_UPLOAD_QUEUE_SUCCESS,
                                started: false
                            }
                        )
                    }

                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function reset() {
    return { type: types.CAMERA_CONTROL_UPLOAD_QUEUE_RESET }
}
