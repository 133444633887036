import initialState from './initialState'
import {
    SCHEDULE_RESET,
    SCHEDULE_LOAD_REQUEST,
    SCHEDULE_LOAD_SUCCESS,
    SCHEDULE_LOAD_ERROR,
    SCHEDULE_SAVE_REQUEST,
    SCHEDULE_SAVE_SUCCESS,
    SCHEDULE_SAVE_ERROR,
    SCHEDULE_CLEAR_DAY,
    SCHEDULE_COPY_TO_NEXT_DAY,
    SCHEDULE_ADD_PERIOD,
    SCHEDULE_MODIFY_PERIOD,
    SCHEDULE_REMOVE_PERIOD,
    SCHEDULE_INCREASE_PERIOD_PRIORITY,
    SCHEDULE_DECREASE_PERIOD_PRIORITY,
    SCHEDULE_SHOW_ADD_PERIOD_MODAL,
    SCHEDULE_HIDE_ADD_PERIOD_MODAL,
    SCHEDULE_SHOW_EDIT_PERIOD_MODAL,
    SCHEDULE_HIDE_EDIT_PERIOD_MODAL,
    SCHEDULE_APPLY_TEMPLATE,
    SCHEDULE_V4_POPULATE_DAY,
    SCHEDULE_V4_POPULATE_DAY_ERROR,
    SCHEDULE_V4_MODIFY_DAY
} from '../actions/actionTypes'

import templates from '../assets/scheduleTemplates'

export default function schedule(state = initialState.schedule, action) {

    switch (action.type) {
        case SCHEDULE_RESET:
            return initialState.schedule
        case SCHEDULE_LOAD_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    loading: true,
                    days: initialState.schedule.days,
                    potId: initialState.schedule.potId
                }
            )
        case SCHEDULE_LOAD_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    success: true,
                    days: action.data[0].schedule,
                    potId: action.data[0].pot_id,
                    potVersion: action.data[0].pot_version
                }
            )
        case SCHEDULE_LOAD_ERROR:
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    success: false,
                    errors: action.errors
                }
            )
        case SCHEDULE_SAVE_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    saving: true,
                    saveSuccess: false
                }
            )
        case SCHEDULE_SAVE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    saving: false,
                    saveSuccess: true
                }
            )
        case SCHEDULE_SAVE_ERROR:
            return Object.assign(
                {},
                state,
                {
                    saving: false,
                    saveSuccess: false,
                    errors: action.errors
                }
            )
        case SCHEDULE_CLEAR_DAY:
            const day = action.day % 7
            return Object.assign(
                {},
                state,
                {
                    days: [
                        ...state.days.slice(0, day),
                        [],
                        ...state.days.slice(day + 1)
                    ]
                }
            )
        case SCHEDULE_COPY_TO_NEXT_DAY:
            const sourceDay = action.day
            const targetDay = (action.day + 1) % 7
            return Object.assign(
                {},
                state,
                {
                    days: [
                        ...state.days.slice(0, targetDay),
                        state.days[sourceDay],
                        ...state.days.slice(targetDay + 1)
                    ]
                }
            )
        case SCHEDULE_ADD_PERIOD:
        {
            let day = state.days[state.addPeriodModalDay].slice(0)
            day.push(action.data)
            return Object.assign(
                {},
                state,
                {
                    addPeriodModalVisible: false,
                    days: [
                        ...state.days.slice(0, state.addPeriodModalDay),
                        day,
                        ...state.days.slice(state.addPeriodModalDay + 1)
                    ]
                }
            )
        }
        case SCHEDULE_MODIFY_PERIOD:
            {
                let day = state.days[state.editPeriodModalDay].slice(0)
                day.splice(state.editPeriodModalPeriod, 1, action.data)
                return Object.assign(
                    {},
                    state,
                    {
                        editPeriodModalVisible: false,
                        days: [
                            ...state.days.slice(0, state.editPeriodModalDay),
                            day,
                            ...state.days.slice(state.editPeriodModalDay + 1)
                        ]
                    }
                )
            }
        case SCHEDULE_REMOVE_PERIOD:
        {
            let day = state.days[action.day].slice(0)
            day.splice(action.period, 1)
            return Object.assign(
                {},
                state,
                {
                    editPeriodModalVisible: false,
                    days: [
                        ...state.days.slice(0, action.day),
                        day,
                        ...state.days.slice(action.day + 1)
                    ]
                }
            )
        }
        case SCHEDULE_INCREASE_PERIOD_PRIORITY:
            {
                let day = state.days[action.day].slice(0)
                const period = day[action.period]
                // Find previous overlapping period
                let newIndex
                for (var i = action.period-1; i>=0; i--) {
                    const p2 = day[i]
                    if (p2.start < period.stop && p2.stop > period.start) {
                        newIndex = i
                        break
                    }
                }
                if (newIndex === undefined) {
                    return state
                }
                // Remove from old position
                day.splice(action.period,1)
                // Insert into new position
                day.splice(newIndex, 0, period)
                return Object.assign(
                    {},
                    state,
                    {
                        days: [
                            ...state.days.slice(0, action.day),
                            day,
                            ...state.days.slice(action.day + 1)
                        ]
                    }
                )
            }
        case SCHEDULE_DECREASE_PERIOD_PRIORITY:
            {
                let day = state.days[action.day].slice(0)
                const period = day[action.period]
                // Find next overlapping period
                let newIndex
                for (var j = action.period+1; j<day.length; j++) {
                    const p2 = day[j]
                    if (p2.start < period.stop && p2.stop > period.start) {
                        newIndex = j
                        break
                    }
                }
                if (newIndex === undefined) {
                    return state
                }
                // Remove from old position
                day.splice(action.period,1)
                // Insert into new position
                day.splice(newIndex+1, 0, period)
                return Object.assign(
                    {},
                    state,
                    {
                        days: [
                            ...state.days.slice(0, action.day),
                            day,
                            ...state.days.slice(action.day + 1)
                        ]
                    }
                )
            }
        case SCHEDULE_SHOW_ADD_PERIOD_MODAL:
            return Object.assign(
                {},
                state,
                {
                    addPeriodModalVisible: true,
                    addPeriodModalDay: action.day
                }
            )
        case SCHEDULE_HIDE_ADD_PERIOD_MODAL:
            return Object.assign(
                {},
                state,
                {
                    addPeriodModalVisible: false
                }
            )
        case SCHEDULE_SHOW_EDIT_PERIOD_MODAL:
            return Object.assign(
                {},
                state,
                {
                    editPeriodModalVisible: true,
                    editPeriodModalDay: action.day,
                    editPeriodModalPeriod: action.period
                }
            )
        case SCHEDULE_HIDE_EDIT_PERIOD_MODAL:
            return Object.assign(
                {},
                state,
                {
                    editPeriodModalVisible: false
                }
            )
        case SCHEDULE_APPLY_TEMPLATE:
            return Object.assign(
                {},
                state,
                {
                    days: templates[action.key]
                }
            )

        case SCHEDULE_V4_POPULATE_DAY:
            return Object.assign(
                {},
                state,
                {
                    addPeriodModalVisible: false,
                    days: [
                            ...state.days.slice(0, state.addPeriodModalDay),
                            action.data,
                            ...state.days.slice(state.addPeriodModalDay + 1)
                        ]
                }
            )
        case SCHEDULE_V4_POPULATE_DAY_ERROR:
            return Object.assign(
                {},
                state,
                {
                    errors: action.errors,
                }
            )
        case SCHEDULE_V4_MODIFY_DAY:
            return Object.assign(
                {},
                state,
                {
                    editPeriodModalVisible: false,
                    days: [
                            ...state.days.slice(0, state.editPeriodModalDay),
                            action.data,
                            ...state.days.slice(state.editPeriodModalDay + 1)
                        ]
                }
            )
        default:
            return state
    }
}
