import React, { Component } from 'react'
import { Label } from 'semantic-ui-react'
import PropTypes from 'prop-types'

class CameraStatusFilter extends Component {
    render() {
        if (this.props.statuses.statuses.length < 1) {
            return null
        }
        return (
            <div>
                <h4>Filter by Status</h4>
                {this.props.statuses.statuses.map((status, idx) => {
                    let tagColour = 'grey'
                    if (this.props.statuses.filterBy.indexOf(status) > -1) {
                        tagColour = 'blue'
                    }
                    return (
                        <Label
                            as='a'
                            key={idx}
                            onClick={() => {this.props.toggleFilterStatus(status)}}
                            color={tagColour}
                            className={'tags'}
                        >
                            {status}
                        </Label>
                    )
                })}
            </div>
        )
    }
}

CameraStatusFilter.propTypes = {
    statuses: PropTypes.shape({
        statuses: PropTypes.array.isRequired,
        filterBy: PropTypes.array.isRequired
    }),
    toggleFilterStatus: PropTypes.func.isRequired
}

export default CameraStatusFilter
