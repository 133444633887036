import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from '../reducers/rootReducer';
import thunk from 'redux-thunk';

export default function configureStore() {

    let composeEnhancers
    if (process.env.NODE_ENV === "production" &&
        process.env.REACT_APP_STAGE !== 'uat')
    {
        composeEnhancers = compose
    } else {
        composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    }

    return createStore(
        rootReducer,
        composeEnhancers(
            applyMiddleware(thunk)
        )
    );
}
