import React, { Component } from 'react'
import { Label } from 'semantic-ui-react'
import PropTypes from 'prop-types'

class CameraTypeFilter extends Component {
    render() {
        if (this.props.types.length < 1) {
            return null
        }
        return (
            <div>
                <h4>Filter by Type</h4>
                {this.props.types.map((type, idx) => {
                    let tagColour = 'grey'
                    if (this.props.filterByTypes.indexOf(type) > -1) {
                        tagColour = 'blue'
                    } else {
                    }
                    return (
                        <Label
                            as='a'
                            key={idx}
                            onClick={() => {this.props.toggleFilterType(type)}}
                            color={tagColour}
                        >

                            {type}
                        </Label>
                    )
                })}
            </div>
        )
    }
}

CameraTypeFilter.propTypes = {
    types: PropTypes.array,
    filterByTypes: PropTypes.array,
    toggleFilterType: PropTypes.func.isRequired
}

export default CameraTypeFilter
