import initialState from './initialState';
import {
    SHOW_FOOTER,
    HIDE_FOOTER
} from '../actions/actionTypes';

export default function footer(state = initialState.footer, action) {

    switch (action.type) {
        case SHOW_FOOTER:
            return Object.assign({}, state, { isHidden: false })
        case HIDE_FOOTER:
            return Object.assign({}, state, { isHidden: true })
        default:
            return state
    }
}
