import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Message, Grid, Icon, Popup, Loader } from 'semantic-ui-react'
import { getErrorMessage } from '../util/errors'

class CameraFocusV4 extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    focus = (direction, step_size) => {
        this.props.cameraControlV4Actions.focus(this.props.cameras_id, direction, step_size)
    }

    auto_focus = () => {
        this.props.cameraControlV4Actions.auto_focus(this.props.cameras_id).then(() => {
            this.props.shootPreview()
        })
    }
    

    getForm = () => {
        const styles = {
            marginTop: 0,
        }
        return (
            <>
                {/* <Button style={styles} circular icon onClick={() => this.focus('far', 'large')} >
                    <Icon
                    name="fast backward"
                    />
                </Button>
                <Button style={styles} circular icon onClick={() => this.focus('far', 'medium')} >
                    <Icon
                    name="backward"
                    />
                </Button>
                <Button style={styles} circular icon onClick={() => this.focus('far', 'small')} >
                    <Icon
                    name="left chevron"
                    />
                </Button> */}
                <Popup
                    trigger={
                        <Button style={styles} circular icon onClick={() => this.auto_focus()} >
                            <Icon
                            name="expand"
                            />
                        </Button>
                    }
                    content="Auto Focus"
                    position='right center'
                />
                
                {/* <Button style={styles} circular icon onClick={() => this.focus('near', 'small')} >
                    <Icon
                    name="right chevron"
                    />
                </Button>
                <Button style={styles} circular icon onClick={() => this.focus('near', 'medium')} >
                    <Icon
                    name="forward"
                    />
                </Button>
                <Button style={styles} circular icon onClick={() => this.focus('near', 'large')} >
                    <Icon
                    name="fast forward"
                    />
                </Button> */}
            </>
        )

    }

    getMessage = (blurb) => {
        return (this.props.camera_control_v4.focus.error || this.props.camera_control_v4.auto_focus.error) ?
            <Message negative>
                <Message.Header>Error</Message.Header>
                <p>{blurb}</p>
            </Message> : null
    }

    getBlurb = () => {
        if (this.props.camera_control_v4.focus.error) {
            return this.getFocusError()
        }
        else if (this.props.camera_control_v4.auto_focus.error) {
            return this.getAutoFocusError()
        }
    }

    getFocusError = () => {
        if (!this.props.camera_control_v4.focus.error) {
            return null
        }
        let message = getErrorMessage(this.props.camera_control_v4.focus.errors, 'cameras_id')
        if (!message) {
            message = getErrorMessage(this.props.camera_control_v4.focus.errors, 'unknownErrorType')
        }
        return (message) ? message : "There was an unexpected error"
    }

    getAutoFocusError = () => {
        if (!this.props.camera_control_v4.auto_focus.error) {
            return null
        }
        let message = getErrorMessage(this.props.camera_control_v4.auto_focus.errors, 'cameras_id')
        if (!message) {
            message = getErrorMessage(this.props.camera_control_v4.auto_focus.errors, 'unknownErrorType')
        }
        return (message) ? message : "There was an unexpected error"
    }

    isReady = () => {
        return !this.props.camera_control_v4.focus.loading && !this.props.camera_control_v4.auto_focus.loading
    }

    render = () => {
        const message = this.getMessage(this.getBlurb())
        if (!this.isReady()) {

            return (
                <div className='focus-settings'>
                    <Grid columns='1'>
                        <Grid.Row>
                            <Grid.Column>
                                <h3>Focus</h3>
                                <Grid.Column><Loader inline='centered' active style={{marginBottom: '5px'}} /></Grid.Column>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            )

        } else {
            return (
                <div className='focus-settings' style={{marginBottom: '1.428em'}}>
                    <h3>Auto Focus &nbsp; <Popup trigger={<Icon name="question circle outline" />}>
                        <p><strong>What is this?</strong></p>
                        <p>Auto Focus uses the cameras sensors to determine the best focus for the shot.</p>

                        <p><strong>Clicking the auto focus button will focus the camera and fire a preview shot for confirmation</strong></p>
                    </Popup></h3>
                    {this.getForm()}
                    {message}
                </div>
            )
        }
    }
}

CameraFocusV4.propTypes = {
    cameraControlV4Actions: PropTypes.object.isRequired,
    cameras_id: PropTypes.string.isRequired,
    camera_control_v4: PropTypes.object.isRequired
}

export default CameraFocusV4
