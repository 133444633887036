import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Input} from 'semantic-ui-react'


class MfaToken extends Component {
    constructor(props) {
        super(props)
        this.token = null
    }

    componentDidMount() {
        this.props.resetErrors()
    }

    onTokenChange = (event) => {
        this.token = event.target.value
    }

    onSubmit = (event) => {
        event.preventDefault()
        const details = {
            token: this.token
        }
        this.props.verifyMfaToken(details, this.props.history)        
        return false
    }

    render() {
        
        let formError
        if (this.props.errors && Object.keys(this.props.errors).length > 0) {
            if ('message' in this.props.errors) {
                formError = (
                    <section className="fieldError" style={{marginTop: '1rem'}}>
                      {this.props.errors.message}
                    </section>
                )
            }
        }
        return (
            <div className='step'>
                <div className='step-inner' style={{background: "white"}}>
                    <Form size='large'>
                        <h3>Two-step Verification</h3>
                        {this.props.fromAuthentication ?
                            <>
                                <p>Thank you for validating your authenticator application.</p>
                                <p>Please enter a new authentication code to login. </p>
                            </>
                        :
                            <p>Please enter the 6-digit code from your authentication application.</p>
                        }
                        <input
                            name="token"
                            autoCapitalize="off"
                            autoComplete="off"
                            type="text"
                            aria-label="received token"
                            placeholder=""
                            onChange={this.onTokenChange}
                        />
                        {formError}
                        <Button
                            className="primary"
                            disabled={this.props.loading}
                            onClick={this.onSubmit}
                            style={{marginTop: '1rem'}}
                        >
                            {this.props.loading? 'Please Wait':'Continue'}
                        </Button>
                    </Form>
                </div>
            </div>
        )
    }
}

export default MfaToken

MfaToken.propTypes = {
    history: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    errors: PropTypes.object,
    verifyMfaToken: PropTypes.func.isRequired,
    resetErrors: PropTypes.func.isRequired
}
