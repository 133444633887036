import * as types from './actionTypes'
import { API_BASE_URL}  from '../config'
import { addMessage } from './messengerActions'
import { log } from './logActions'
import { notAuthorisedResponse } from './authActions'
import { fetchWithToken } from '../util/accessToken'

export function fetchAllEmbeds(cameraId) {
    return (dispatch, getState) => {
        dispatch({ type: types.EMBED_FETCH_ALL_REQUEST })
        let url = `${API_BASE_URL}/api/cameras/${cameraId}/embeds`
        return fetchWithToken(
            url,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.EMBED_FETCH_ALL_RESPONSE_ERRORS,
                                        errors: data.errors,
                                    })
                            } else {
                                // Error
                                dispatch(
                                    {
                                        type: types.EMBED_FETCH_ALL_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        }
                                    }
                                )
                            }

                        })
                    }
                } else {
                    if (response.status === 204) {
                        dispatch(
                            {
                                type: types.EMBED_FETCH_ALL_RESPONSE_SUCCESS,
                                embeds: []
                            }
                        )
                        return
                    }
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.EMBED_FETCH_ALL_RESPONSE_ERRORS,
                                    errors: data.errors,
                                }
                            )
                        } else {
                            dispatch(
                                {
                                    type: types.EMBED_FETCH_ALL_RESPONSE_SUCCESS,
                                    embeds: data
                                }
                            )
                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(log("error", response))
            })
    }
}

export function fetchOneEmbed(cameraId, embedId) {
    return (dispatch, getState) => {
        dispatch({ type: types.EMBED_FETCH_ONE_REQUEST, embeds_id: embedId })
        let url = `${API_BASE_URL}/api/cameras/${cameraId}/embeds/${embedId}`
        return fetchWithToken(
            url,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    }  else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.EMBED_FETCH_ONE_RESPONSE_ERRORS,
                                        errors: data.errors,
                                    })
                            } else {
                                // Error
                                dispatch(
                                    {
                                        type: types.EMBED_FETCH_ONE_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        }
                                    }
                                )
                            }

                        })
                    }
                } else {

                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.EMBED_FETCH_ONE_RESPONSE_ERRORS,
                                    errors: data.errors,
                                }
                            )
                        } else {
                            dispatch(
                                {
                                    type: types.EMBED_FETCH_ONE_RESPONSE_SUCCESS,
                                    details: data
                                }
                            )
                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(log("error", response))
            })
    }
}

export function updateEmbed(cameraId, embedId, details) {
    return (dispatch, getState) => {
        dispatch({
            type: types.EMBED_UPDATE_REQUEST,
            cameras_id: cameraId,
            embed_id: embedId,
            details: details
        })
        let url = `${API_BASE_URL}/api/cameras/${cameraId}/embeds/${embedId}`
        return fetchWithToken(
            url,
            {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(details)
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    }  else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.EMBED_UPDATE_RESPONSE_ERRORS,
                                        errors: data.errors,
                                    })
                            } else {
                                // Error
                                dispatch(
                                    {
                                        type: types.EMBED_UPDATE_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        }
                                    }
                                )
                            }

                        })
                    }
                } else {

                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.EMBED_UPDATE_RESPONSE_ERRORS,
                                    errors: data.errors,
                                }
                            )
                        } else {
                            dispatch(
                                {
                                    type: types.EMBED_UPDATE_RESPONSE_SUCCESS,
                                    details: data
                                }
                            )
                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(log("error", response))
            })
    }
}

export function deleteEmbed(cameraId, embedId) {
    return (dispatch, getState) => {
        dispatch({
            type: types.EMBED_DELETE_REQUEST,
            cameras_id: cameraId,
            embeds_id: embedId
        })
        let url = `${API_BASE_URL}/api/cameras/${cameraId}/embeds/${embedId}`
        return fetchWithToken(
            url,
            {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    }  else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.EMBED_DELETE_RESPONSE_ERRORS,
                                        errors: data.errors,
                                    })
                            } else {
                                // Error
                                dispatch(
                                    {
                                        type: types.EMBED_DELETE_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        }
                                    }
                                )
                            }

                        })
                    }
                } else {
                    if (response.status === 204) {
                        dispatch(
                            {
                                type: types.EMBED_DELETE_RESPONSE_SUCCESS,
                                embedId: embedId
                            }
                        )
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.EMBED_DELETE_RESPONSE_ERRORS,
                                        errors: data.errors,
                                    }
                                )
                            }

                        })
                    }
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(log("error", response))
            })
    }
}

export function createEmbed(cameraId, details) {
    return (dispatch, getState) => {
        dispatch({
            type: types.EMBED_CREATE_REQUEST,
            cameras_id: cameraId,
            details: details
        })
        let url = `${API_BASE_URL}/api/cameras/${cameraId}/embeds`
        return fetchWithToken(
            url,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(details)
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    }  else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.EMBED_CREATE_RESPONSE_ERRORS,
                                        errors: data.errors,
                                    })
                            } else {
                                // Error
                                dispatch(
                                    {
                                        type: types.EMBED_CREATE_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        }
                                    }
                                )
                            }

                        })
                    }
                } else {

                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.EMBED_CREATE_RESPONSE_ERRORS,
                                    errors: data.errors,
                                }
                            )
                        } else {
                            dispatch(
                                {
                                    type: types.EMBED_CREATE_RESPONSE_SUCCESS,
                                    details: data
                                }
                            )
                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(log("error", response))
            })
    }
}

export function reset() {
    return { type: types.EMBED_RESET }
}

export function createReset() {
    return { type: types.EMBED_CREATE_RESET}
}

export function updateReset() {
    return { type: types.EMBED_UPDATE_RESET}
}
