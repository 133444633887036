import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Message } from 'semantic-ui-react'
import * as messengerActions from '../actions/messengerActions'

class Messenger extends Component {

    constructor(props) {
        super(props)
        this.state = {
            items: [] // { id: <number>, status: <number> }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props !== prevProps) {
            // Moved code out of render into here to avoid "cannot update state inside render error"
            this.props.messenger.messages.forEach((row) => {
                const id = row.id
                const item = this.state.items.find((item) => item.id === id)
                if (!item) {
                    // Shouldn't need to do anything...
                    this.setState((prevState, props) => {
                        const items = prevState.items.slice()
                        items.push({id: id, className: ''})
                        return {
                            items: items
                        }
                    })
                    // Let's remove it after n seconds
                    setTimeout(() => {
                        this.props.messengerActions.removeMessage(row.id)
                    }, row.status === 'success' ? 5000 : 10000)
                    // Let's apply the fade in
                    setTimeout(() => {
                        this.setState((prevState, props) => {
                            const items = prevState.items.slice()
                            const idx = items.findIndex((i) => i.id === id)
                            if (items[idx].className === '') {
                                items[idx] = {
                                    id: id,
                                    className: 'fadeOut'
                                }
                            }
                            return {
                                items: items
                            }
                        })
                    }, 2000)
                }
            })
        }
    }

    render() {
        return (
            <div className="messenger">
            {
                this.props.messenger.messages.map((row, k) => {

                    const id = row.id
                    const item = this.state.items.find((item) => item.id === id)
                    const className = (item) ? item.className : ''

                    return (

                        <Message
                            key={k}
                             className={`message ${className}`}
                            onDismiss={() => {
                                this.props.messengerActions.removeMessage(row.id)
                            }}
                            header={row.title}
                            content={row.details}
                            negative={row.status === "negative"}
                            positive={row.status === "positive"}
                            warning={row.status === "warning"}
                            success={row.status === "success"}

                        />

                    )
                })
            }
            </div>
        )
    }
}

Messenger.propTypes = {
    messengerActions: PropTypes.object,
    messenger: PropTypes.object,

}

function mapStateToProps(state) {
    return {
        messenger: state.messenger,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        messengerActions: bindActionCreators(messengerActions, dispatch)
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Messenger))
