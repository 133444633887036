import initialState from './initialState';
import {
    PROJECT_LIST_REQUEST,
    PROJECT_LIST_RESPONSE,
    PROJECT_LIST_ERRORS,
    PROJECT_LIST_RESET,
    PROJECT_LIST_FILTER_BY
} from '../actions/actionTypes';

export default function projects(state = initialState.projects, action) {

    switch (action.type) {
        case PROJECT_LIST_REQUEST:
            return Object.assign({}, state, { loading: true })
        case PROJECT_LIST_RESPONSE:
            return Object.assign({}, state, {
                errors: {error: false},
                tableData: action.tableData,
                totalRows: action.totalRows,
                loading: false,
                currentPage: action.currentPage,
                filtered: action.filtered,
                sortColumn: action.sortColumn,
                sortAscending: action.sortAscending,
                searchTerm: action.searchTerm
            })
        case PROJECT_LIST_ERRORS:
            return Object.assign({}, state, {
                errors: {error: true, message:"Unable to load project list"},
                tableData: [],
                totalRows: 0,
                loading: false,
                filtered: action.filtered
            })
        case PROJECT_LIST_RESET:
            return Object.assign(
                {},
                state,
                {
                    ...initialState.projects
                }
            )
        case PROJECT_LIST_FILTER_BY:
            return Object.assign(
                {},
                state,
                {
                    searchTerm: action.searchTerm
                }
            )
        default:
            return state
    }
}
