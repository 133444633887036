import * as types from './actionTypes'
import { API_BASE_URL}  from '../config'
import { addMessage } from './messengerActions'
import { log } from './logActions'
import { fetchWithToken } from '../util/accessToken'

export function validateEmailResponseSuccess(response) {
    return {type: types.BULK_USERS_VALIDATE_EMAIL_RESPONSE_SUCCESS, ...response}
}

export function validateEmailResponseErrors(response) {
    return {type: types.BULK_USERS_VALIDATE_EMAIL_RESPONSE_ERRORS, ...response}
}

export function validateEmailReset() {
    return {type: types.BULK_USERS_VALIDATE_EMAIL_RESET}
}

export function validateEmailRequest(email) {
    return (dispatch, getState) => {
        dispatch({ type: types.BULK_USERS_VALIDATE_EMAIL_REQUEST })
        return fetchWithToken(
            API_BASE_URL + '/api/admin/users/bulk/validate-email-as-username',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    email: email
                })
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                return response
            } else {
                return response
            }
        })
        .then(response => response.json())
        .then((json) => {
            if (json.errors) {
                dispatch(validateEmailResponseErrors(json))
            } else {
                dispatch(validateEmailResponseSuccess(json))
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

/*
* Created new function to combine the validation and adding user to list
*/
export function validateEmailRequestAndAddToBulkUserList(email) {
    return (dispatch, getState) => {
        dispatch({ type: types.BULK_USERS_VALIDATE_EMAIL_REQUEST })
        return fetchWithToken(
            API_BASE_URL + '/api/admin/users/bulk/validate-email-as-username',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    email: email
                })
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                return response
            } else {
                return response
            }
        })
        .then(response => response.json())
        .then((json) => {
            if (json.errors) {
                dispatch(validateEmailResponseErrors(json))
            } else {
                dispatch(validateEmailResponseSuccess(json))
                dispatch(addValidatedEmailToBulkUserList(json.email))
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function addValidatedEmailToBulkUserList(email) {
    return { type: types.BULK_USERS_ADD_VALIDATED_EMAIL_TO_LIST, email: email}
}

export function reset() {
    return {type: types.BULK_USERS_RESET}
}

export function validateTemplate(template) {
    return dispatch => {
        dispatch({ type: types.BULK_USERS_VALIDATE_TEMPLATE_REQUEST })
        // Do some validation...
        dispatch({ type: types.BULK_USERS_VALIDATE_TEMPLATE_RESPONSE_SUCCESS, template: template })
    }
}

export function removeEmailFromBulkUserList(email) {
    return { type: types.BULK_USERS_REMOVE_EMAIL_FROM_LIST, email: email}
}

export function updateTemplate(template) {
    return { type: types.BULK_USERS_UPDATE_TEMPLATE, template }
}

export function startQueue() {
    return { type: types.BULK_USERS_PROCESS_QUEUE_STARTED }
}

export function finishQueue() {
    return { type: types.BULK_USERS_PROCESS_QUEUE_COMPLETED }
}

export function addUser(template, email) {
    return (dispatch, getState) => {
        const userDetails = Object.assign(
            {},
            {
                ...template,
            },
            {
                username: email,
                email: email,
                send_email: false
            }
        )
        dispatch(
            {
                type: types.BULK_USERS_PROCESS_QUEUE_UPDATED,
                email: email,
                status: "Processing",
                progress: 50
            }
        )

        return fetchWithToken(
            API_BASE_URL + '/api/users',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(userDetails)
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                // If here, we have errors
                return response.json().then(data => {
                    // Obsolete...API not returning at error any longer.
                    if (data.errors.sendVerificationEmail) {
                        // Warning
                        dispatch(
                            {
                                type: types.BULK_USERS_PROCESS_QUEUE_UPDATED,
                                email: email,
                                status: "Warning",
                                progress: 75,
                                errors: data.errors,
                                success: true,
                                error: false,
                                warning: true
                            }
                        )
                    } else {
                        // Error
                        dispatch(
                            {
                                type: types.BULK_USERS_PROCESS_QUEUE_UPDATED,
                                email: email,
                                status: "Error",
                                progress: 75,
                                errors: data.errors,
                                success: false,
                                error: true,
                                warning: false
                            }
                        )
                    }
                })
            } else {
                if (response.status === 201) {
                    return response.json().then(data => {
                        dispatch(
                            {
                                type: types.BULK_USERS_PROCESS_QUEUE_UPDATED,
                                email: email,
                                status: "Processing",
                                progress: 75, // was 100, but now associating cameras etc
                                errors: {},
                                success: true,
                                warning: false,
                                user: data
                            }
                        )
                    })
                }
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

/**
 *
 * This is in order to update the status to reflect the 2-step approach
 * @param email
 * @param status
 * @returns {{type, email: *, status: *, progress: number}}
 */
export function updateUserAssociations(email, status) {
    return {
        type: types.BULK_USERS_PROCESS_QUEUE_UPDATED,
        email: email,
        status: status,
        progress: 100
    }
}

export function addUserProjects(projects) {
    return { type: types.BULK_USERS_ADD_USER_PROJECTS, projects: projects }
}

export function addUserCameras(cameras) {
    return { type: types.BULK_USERS_ADD_USER_CAMERAS, cameras: cameras }
}

export function updateProjectCameraWeights(projectCameraWeights) {
    return {
        type: types.BULK_USERS_UPDATE_PROJECT_CAMERA_WEIGHTS,
        project_camera_weights: projectCameraWeights
    }
}

export function resetAddUserProjects() {
    return { type: types.BULK_USERS_RESET_ADD_USER_PROJECTS }
}

export function resetAddUserCameras() {
    return { type: types.BULK_USERS_RESET_ADD_USER_CAMERAS }
}

export function resetUpdateProjectCameraWeights() {
    return { type: types.BULK_USERS_RESET_UPDATE_PROJECT_CAMERA_WEIGHTS }
}
