import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import * as authActions from '../actions/authActions';
import * as videoEditsActions from '../actions/videoEditsActions';
import DocumentTitle from 'react-document-title';
import { checkAuth } from '../util/accessToken';
import { Container, Table, Loader, Dimmer, Button} from 'semantic-ui-react';
import XMLParser from 'react-xml-parser';


class VideoEdits extends Component {

    componentDidMount() {
        return checkAuth(this.props.auth, this.props.authActions, this.props.history)
        .then(() => {
            this.props.videoEditsActions.videoEditListRequest();
        })
        .catch(err => {})
    }

    handleDeleteVideoEditClick = (id) => {
        this.props.videoEditsActions.deleteVideoEditRequest(id)
        //const videoList = this.props.videoEdits.videoEdits
        this.props.videoEditsActions.removeVideoEdit(id)
    }


    handleRequeueVideoEditClick = (id) => {
        this.props.videoEditsActions.requeueVideoEditRequest(id)
    }

    orderDays(daysFilter) {
        const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
        let includedDays = []
        daysOfWeek.map(function(day) {
            if (daysFilter[day] === "1") {
                includedDays.push(day.substring(0, 3))
            }
            return true
        })
        return includedDays
    }

    render() {
        const videoEdits = this.props.videoEdits.videoEdits
        let videoEditRows;
        if (videoEdits.length > 0) {
            videoEditRows = videoEdits.map((videoEdit, idx) => {
                let link = null;
                let button = null;
                let requeueButton = null;
                const id = videoEdit.id

                if (videoEdit.status === 'Completed') {
                    link = <Button
                    compact
                    icon='play'
                    size='tiny'
                    basic
                    color='blue'
                    onClick={() => {
                        window.open(videoEdit.url, '_blank').focus();
                    }}
                    content='Play'
                    style={{float: 'right', margin: '5px'}}></Button>
                }

                if (videoEdit.status === 'Completed' || videoEdit.status === 'Failed') {
                    button = <Button
                                compact
                                icon='trash'
                                size='tiny'
                                basic
                                color='blue'
                                onClick={() => {
                                    this.handleDeleteVideoEditClick(id)
                                }}
                                content='Delete'
                                style={{float: 'right', margin: '5px'}}></Button>
                }

                if (videoEdit.status === 'Failed') {
                    requeueButton = <Button
                                compact
                                icon='redo'
                                size='tiny'
                                basic
                                color='green'
                                onClick={() => {
                                    this.handleRequeueVideoEditClick(id)
                                }}
                                content='Requeue'
                                style={{float: 'right', margin: '5px'}}></Button>
                }

                var manifest_data = new XMLParser().parseFromString(videoEdit.manifest);
                const potId = manifest_data.getElementsByTagName('potId')[0].value
                const storageLocation = videoEdit.storage_location
                const startDate = manifest_data.getElementsByTagName('start')[0].value
                const endDate = manifest_data.getElementsByTagName('end')[0].value
                const fromTime = manifest_data.getElementsByTagName('filterTimes')[0].attributes.from
                const untilTime = manifest_data.getElementsByTagName('filterTimes')[0].attributes.until
                const daysFilter = manifest_data.getElementsByTagName('filterDays')[0].attributes
                const days = this.orderDays(daysFilter)
                const duration = manifest_data.getElementsByTagName('duration')[0].value
                return (
                    <Table.Row key={idx}>
                        <Table.Cell>{videoEdit.username === " " ?
                                    videoEdit.email : videoEdit.username}</Table.Cell>
                        <Table.Cell>{videoEdit.client}</Table.Cell>
                        <Table.Cell>{videoEdit.status}</Table.Cell>
                        <Table.Cell>{videoEdit.created}</Table.Cell>
                        <Table.Cell>{videoEdit.updated}</Table.Cell>
                        <Table.Cell>{potId}</Table.Cell>
                        <Table.Cell>{storageLocation}</Table.Cell>
                        <Table.Cell>{startDate} to {endDate}</Table.Cell>
                        <Table.Cell>{fromTime} to {untilTime}</Table.Cell>
                        <Table.Cell>{days.toString()}</Table.Cell>
                        <Table.Cell>{duration} seconds</Table.Cell>
                        <Table.Cell>
                            <ul style={{padding: 0, margin: 0}}>{link}{button}{requeueButton}</ul>
                        </Table.Cell>
                    </Table.Row>
                )
            })
        } else {
            videoEditRows = (
                <Table.Row>
                    <Table.Cell colSpan='6'>No Results</Table.Cell>
                </Table.Row>
            )
        }


        return (
            <div>
                <DocumentTitle title='Video Edits' />
                {/* <h2>Video Edits</h2> */}
                <Container className="content-container" fluid={true} style={{padding: '0 1.2em'}}>
                    <Dimmer.Dimmable>
                        <Dimmer active={this.props.videoEdits.loading} inverted>
                            <Loader>Loading</Loader>
                        </Dimmer>
                        <Table celled padded data-testid="table--video-edits">
                            <Table.Header style={{
                                    position: 'sticky',
                                    top: 0,
                                    zIndex: 1,
                                }}>
                                <Table.Row>
                                    <Table.HeaderCell singleLine>
                                    User
                                    </Table.HeaderCell>
                                    <Table.HeaderCell singleLine>
                                    Client
                                    </Table.HeaderCell>
                                    <Table.HeaderCell singleLine>
                                    Status
                                    </Table.HeaderCell>
                                    <Table.HeaderCell singleLine>
                                    Requested
                                    </Table.HeaderCell>
                                    <Table.HeaderCell singleLine>
                                    Updated
                                    </Table.HeaderCell>
                                    <Table.HeaderCell singleLine>
                                    Pot Id
                                    </Table.HeaderCell>
                                    <Table.HeaderCell singleLine>
                                    Storage Location
                                    </Table.HeaderCell>
                                    <Table.HeaderCell singleLine>
                                    Date Range
                                    </Table.HeaderCell>
                                    <Table.HeaderCell singleLine>
                                    Times Included
                                    </Table.HeaderCell>
                                    <Table.HeaderCell singleLine>
                                    Days Included
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                    Duration
                                    </Table.HeaderCell>
                                    <Table.HeaderCell singleLine>
                                    Actions
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {videoEditRows}
                            </Table.Body>
                        </Table>
                    </Dimmer.Dimmable>
                </Container>
            </div>
        )
    }
}

VideoEdits.propTypes = {
    authActions: PropTypes.object
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        videoEdits: state.videoEdits
    }
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(authActions, dispatch),
        videoEditsActions: bindActionCreators(videoEditsActions, dispatch)
    }
}
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(VideoEdits))
