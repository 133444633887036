import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Message, Grid, Dropdown } from 'semantic-ui-react'

import { getErrorMessage } from './../util/errors'

class ImageRotation extends Component {

    constructor(props) {
        super(props)
        this.state = { rotation: null}
    }

    getRotationOptions = () => {
        return this.props.camera_control.system.details.rotation.options.map((item) => {
            return {  text: `${item}°`, value: item }
        })
    }

    onChangeRotation = (evt, {value}) => {
        this.setState({rotation: value})
    }

    isProcessing = () => {
        return this.props.camera_control.update_system.processing
            && 'rotation' in this.props.camera_control.update_system.properties
    }

    isButtonDisabled = () => {
        if (this.isProcessing()) {
            return true
        }
        if (this.state.rotation === null) {
            return true
        }
        if (this.state.rotation && this.state.rotation === this.getValue()) {
            return true
        }
        return false
    }

    onClick = () => {
        const properties = {
            rotation: this.state.rotation
        }
        this.props.cameraControlActions.updateSystem(this.props.cameras_id, properties)
            .then(() => {
                this.props.cameraControlActions.fetchSystem(this.props.cameras_id)
            })
    }

    getValue = () => {
        return this.props.camera_control.system.details.rotation.value
    }

    isReady = () => {
        return this.props.camera_control.system.details
            && 'rotation' in this.props.camera_control.system.details
    }

    handleDismiss = () => {
        this.setState({ visible: false })

        setTimeout(() => {
            this.setState({ visible: true })
        }, 2000)
    }

    getError = () => {
        if (!this.props.camera_control.update_system.error) {
            return null
        }
        let message = getErrorMessage(this.props.camera_control.update_system.errors, 'cameras_id')
        if (!message) {
            message = getErrorMessage(this.props.camera_control.update_system.errors, 'unknownErrorType')
        }
        return (message) ? message : "There was an unexpected error"
    }

    isMessageRelatedToImageRotation = () => {
        return (this.props.camera_control.update_system.error || this.props.camera_control.update_system.success)
            &&  'rotation' in this.props.camera_control.update_system.properties
    }

    getMessage = (blurb) => {
        return (this.isMessageRelatedToImageRotation()) ?
            <Message positive={this.props.camera_control.update_system.success} negative={this.props.camera_control.update_system.error}

                     onDismiss={this.onDismissMessage}

            >
                <Message.Header>{(this.props.camera_control.update_system.error) ? 'Error' : 'Success'}</Message.Header>
                <p>{blurb}</p>
            </Message> : null
    }

    getBlurb = () => {
        if (this.props.camera_control.update_system.error) {
            return this.getError()
        }
        if (this.props.camera_control.update_system.success) {
            return 'Successfully updated Image Rotation settings'
        }
        return null
    }

    onDismissMessage = () => {
        this.props.cameraControlActions.resetUpdateSystem()
    }

    render = () => {

        if (!this.isReady()) {

            return (
                <div className='image-rotation'>
                    {/*<Dimmer active inverted><Loader content='' /></Dimmer>*/}
                    <Grid columns='1'>
                        <Grid.Row>
                            <Grid.Column>
                                <Grid.Column><div className="animated-blank-line" /></Grid.Column>
                                <Grid.Column><div className="animated-blank-line" /></Grid.Column>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            )

        } else {

            const rotationDropdown = (this.props.camera_control.system.details  && this.props.camera_control.system.details.rotation) ?
                <div>
                    <Dropdown
                        fluid
                        loading={this.props.camera_control.update_system.processing
                        && 'rotation' in this.props.camera_control.update_system.properties}
                        floating
                        selection
                        labeled
                        placeholder='Image Rotation'
                        defaultValue={this.props.camera_control.system.details.rotation.value}
                        onChange={ this.onChangeRotation}
                        options={this.getRotationOptions()}
                    />
                </div> : null

            return (
                <div className='image-rotation'>

                    {this.getMessage(this.getBlurb())}

                    <Grid columns='1'>
                        <Grid.Row>
                            <Grid.Column>

                                {rotationDropdown}

                                <Button
                                    fluid
                                    loading={this.isProcessing()}
                                    disabled={this.isButtonDisabled()}
                                    onClick={this.onClick}>Update Image Rotation</Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            )

        }
    }
}

ImageRotation.propTypes = {
    cameraControlActions: PropTypes.object.isRequired,
    cameras_id: PropTypes.string.isRequired,
    camera_control: PropTypes.object.isRequired
}

export default ImageRotation
