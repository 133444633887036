import * as types from './actionTypes'

export function addMessage(status, title, details) {
    return {type: types.MESSENGER_ADD_MESSAGE, status, title, details}
}

export function removeMessage(id) {
    return {type: types.MESSENGER_REMOVE_MESSAGE, id}
}

export function removeAllMessages() {
    return {type: types.MESSENGER_REMOVE_ALL_MESSAGES}
}
