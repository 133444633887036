import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Button, Form, Grid, Message, Segment } from 'semantic-ui-react'

import * as headerActions from '../../actions/headerActions'
import * as footerActions from '../../actions/footerActions'
import * as passwordActions from '../../actions/passwordActions'
import {RESET} from '../../actions/passwordActions'

import * as logActions from '../../actions/logActions'
import * as messengerActions from '../../actions/messengerActions'

import PasswordEntry from '../PasswordEntry'

/** User container class **/
class ResetPassword extends Component
{
    render() {
        return (
            <div>
                <Switch>
                    <Route exact path='/resetPassword/hash/:hash/token/:token' render={props =>
                        <PasswordEntry handleSubmit={this.handleSubmit} reason={RESET} {...this.props} {...props} />
                    } />
                    <Route exact path='/resetPassword/hash/:hash' render={props =>
                        <PasswordEntry handleSubmit={this.handleSubmit} reason={RESET} {...this.props} {...props} />
                    } />
                    <Route path="/resetPassword" render={props =>
                        <ResetPasswordPreflight {...this.props} {...props} />
                    }/>
                </Switch>
            </div>
        )
    }
}

class ResetPasswordPreflight extends Component
{
    componentDidMount() {
        this.props.passwordActions.passwordResetErrors()
    }

    onUsernameChange = (event) => {
        this.username = event.target.value
    }

    onClick = () => {
        this.props.passwordActions.resetRequestSubmit(this.username)
    }

    render() {
        let resetForm = (
            <Form size='large'>
                <h2>Reset Password</h2>
                <Message
                    error={true}
                    visible={this.props.password.errors.length > 0 }
                    header={false}
                    list={this.props.password.errors}
                />
                <Segment>
                    <p>Please enter the email address associated with your account.</p>
                    <Form.Input
                        fluid
                        icon='user'
                        iconPosition='left'
                        placeholder='E-mail address'
                        error={false}
                        onChange={this.onUsernameChange}
                    />
                    <Button loading={this.props.password.loading} onClick={this.onClick} color='teal' fluid size='large'>Reset</Button>
                </Segment>
            </Form>
        )

        let resetConfirmation = (
            <div>
            Instructions on how to reset your password have been sent to your email address.
            </div>
        )

        return (
            <div>
                <style>{`
      body > div,
      body > div > div,
      body > div > div > div.login-form {
        height: 100%;
      }
    `}</style>
                <Grid
                    textAlign='center'
                    style={{ height: '100%' }}
                    verticalAlign='middle'
                >
                    <Grid.Column style={{ maxWidth: 450 }}>
                    {this.props.password.success ?
                        resetConfirmation :
                        resetForm
                    }
                    </Grid.Column>
                </Grid>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        password: state.password
    }
}

function mapDispatchToProps(dispatch) {
    return {
        passwordActions: bindActionCreators(passwordActions, dispatch),
        headerActions: bindActionCreators(headerActions, dispatch),
        footerActions: bindActionCreators(footerActions, dispatch),
        logActions: bindActionCreators(logActions, dispatch),
        messengerActions: bindActionCreators(messengerActions, dispatch)
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPassword))
