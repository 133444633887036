import initialState from './initialState';
import {
    SHOW_HEADER,
    HIDE_HEADER,
    SHOW_LED_CHART,
    HIDE_LED_CHART
} from '../actions/actionTypes';

export default function header(state = initialState.header, action) {

    switch (action.type) {
        case SHOW_HEADER:
            return Object.assign({}, state, { isHidden: false })
        case SHOW_LED_CHART:
            return Object.assign({}, state, { chartOpen: true })
        case HIDE_HEADER:
            return Object.assign({}, state, { isHidden: true })
        case HIDE_LED_CHART:
            return Object.assign({}, state, { chartOpen: false })
        default:
            return state
    }
}
