import React, { Component } from 'react'
import {
    Button,
    Label,
    Modal,
    Message,
    Form,
} from 'semantic-ui-react'
import { getErrorMessage } from './../../util/errors'
import PropTypes from 'prop-types'

class UpdateBIMModal extends Component {

    constructor(props) {
        super(props)
        try {
            const parameters = JSON.parse(this.props.cameras.camera.details.vc_parameters)
            if (parameters === null) {
                throw new Error('No BIM data')
            }
            this.state = parameters
            this.defaultData = parameters
            this.final3dImageFile = null
            this.props.bimFinal3dImageActions.reset()
        } catch(err) {
            this.state = this.getInitialState()
            this.defaultData = this.getInitialState()
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.bimFinal3dImage.upload.success & !prevProps.bimFinal3dImage.upload.success) {
            this.setState({final3dImage: {filename: true}})
        }
        if (this.props.bimFinal3dImage.remove.success & !prevProps.bimFinal3dImage.remove.success) {
            this.setState({final3dImage: {filename: null}})
        }
    }

    getInitialState = () => {
        return {
            position: {
                x: 0,
                y: 0,
                z: 0
            },
            rotation: {
                x: 0,
                y: 0,
                z: 0
            },
            fieldOfView: 0,
            width: 0,
            height: 0,
            link: {
                ip: null,
                port: null,
                username: null,
                password: null,
                taskFilter: null,
                threeDFilter: null,
                lookAhead: null,
                lookBack: null
            },
            bgcolor: '#000000',
            associatedPot: null
        }
    }

    onPosXChange = (event) => {
        let position = this.state.position
        position.x = event.target.value
        this.setState({position: position})
    }

    onPosYChange = (event) => {
        let position = this.state.position
        position.y = event.target.value
        this.setState({position: position})
    }

    onPosZChange = (event) => {
        let position = this.state.position
        position.z = event.target.value
        this.setState({position: position})
    }

    onRotXChange = (event) => {
        let rotation = this.state.rotation
        rotation.x = event.target.value
        this.setState({rotation: rotation})
    }

    onRotYChange = (event) => {
        let rotation = this.state.rotation
        rotation.y = event.target.value
        this.setState({rotation: rotation})
    }

    onRotZChange = (event) => {
        let rotation = this.state.rotation
        rotation.z = event.target.value
        this.setState({rotation: rotation})
    }

    onFoVChange = (event) => {
        this.setState({fieldOfView: event.target.value})
    }

    onWidthChange = (event) => {
        this.setState({width: event.target.value})
    }

    onHeightChange = (event) => {
        this.setState({height: event.target.value})
    }

    onLinkIPChange = (event, {value}) => {
        let link = this.state.link
        link.ip = value
        this.setState({link: link})
    }

    onLinkPortChange = (event, {value}) => {
        let link = this.state.link
        link.port = value
        this.setState({link: link})
    }

    onLinkUsernameChange = (event, {value}) => {
        let link = this.state.link
        link.username = value
        this.setState({link: link})
    }

    onLinkPasswordChange = (event, {value}) => {
        let link = this.state.link
        link.password = value
        this.setState({link: link})
    }

    onLink3dFilterChange = (event, {value}) => {
        let link = this.state.link
        link.threeDFilter = value
        this.setState({link: link})
    }

    onLinkLookAheadChange = (event, {value}) => {
        let link = this.state.link
        link.lookAhead = value
        this.setState({link: link})
    }

    onLinkLookBackChange = (event, {value}) => {
        let link = this.state.link
        link.lookBack = value
        this.setState({link: link})
    }

    onBackgroundColourChange = (event) => {
        this.setState({bgcolor: event.target.value})
    }

    onAssociatedPotChange = (event, {value}) => {
        this.setState({associatedPot: value})
    }

    getData = (bimFinal3dImage) => {
        const data = Object.assign(
            {},
            this.state
        )
        if (bimFinal3dImage.filename) {
            data['final3dImage'] = {
                ...bimFinal3dImage
            }
        }
        return JSON.stringify(data)
    }

    onUploadFileChange = (event) => {
        if (this.final3dImageFile && this.final3dImageFile.files[0]) {
            const cameraId = this.props.cameras.camera.cameras_id
            const formData = new FormData()
            formData.append('image', this.final3dImageFile.files[0])
            this.props.bimFinal3dImageActions.uploadImage(cameraId, formData)
            this.final3dImageFile.value = null
        }
        event.preventDefault()
        return false
    }

    onRemoveImage = (event) => {
        const cameraId = this.props.cameras.camera.cameras_id
        this.props.bimFinal3dImageActions.removeImage(cameraId)
    }

    render() {

        const errors = this.props.cameras.update.errors
        const uploadImageErrors = this.props.bimFinal3dImage.upload.errors
        const removeImageErrors = this.props.bimFinal3dImage.remove.errors
        let final3dImageExists = false
        if (this.state.final3dImage && this.state.final3dImage.filename) {
            final3dImageExists = this.state.final3dImage.filename
        }

        return (
            <Modal size={"tiny"} open={this.props.visible} onClose={this.props.onClose} closeIcon>
                <Modal.Header>BIM Settings</Modal.Header>
                <Modal.Content>
                    <Form>
                        <h3>Associated Pot</h3>
                        <Message
                            error={true}
                            visible={errors.associatedPot !== undefined}
                            header={errors.associatedPot}
                            content={getErrorMessage(errors, 'associatedPot')}
                        />
                        <Form.Input
                            fluid
                            label="Pot"
                            defaultValue={this.defaultData.associatedPot}
                            onChange={this.onAssociatedPotChange}
                        />
                        <h3>Final 3D Image</h3>
                        <Message
                            error={true}
                            visible={this.props.bimFinal3dImage.upload.error}
                            header="Error uploading image"
                            content={getErrorMessage(uploadImageErrors, 'image')}
                        />
                        <Message
                            error={true}
                            visible={this.props.bimFinal3dImage.remove.error}
                            header="Error removing image"
                            content={getErrorMessage(removeImageErrors, 'image')}
                        />
                        <input
                            type='file'
                            id='upload-image'
                            style={{display: 'none'}}
                            onChange={this.onUploadFileChange}
                            ref={(input) => {this.final3dImageFile = input}}
                        />
                        <Button
                            as='label'
                            htmlFor='upload-image'
                            color='blue'
                            className='button'
                            loading={this.props.bimFinal3dImage.upload.processing}
                        >
                            {final3dImageExists ? "Replace existing image" : "Upload new image"}
                        </Button>
                        {final3dImageExists ? (
                            <Button
                                onClick={this.onRemoveImage}
                                loading={this.props.bimFinal3dImage.remove.processing}
                            >Remove existing image</Button>
                        ) : null}
                        <h3>Virtual Camera</h3>
                        <Message
                            error={true}
                            visible={errors.posX !== undefined}
                            header={errors.posX}
                            content={getErrorMessage(errors, 'posX')}
                        />
                        <Message
                            error={true}
                            visible={errors.posY !== undefined}
                            header={errors.posY}
                            content={getErrorMessage(errors, 'posY')}
                        />
                        <Message
                            error={true}
                            visible={errors.posZ !== undefined}
                            header={errors.posZ}
                            content={getErrorMessage(errors, 'posZ')}
                        />
                        <h3>Position</h3>
                        <Form.Group widths="equal">
                            <Form.Input fluid label="x">
                                <input
                                    defaultValue={this.defaultData.position.x}
                                    onChange={this.onPosXChange}
                                />
                                <Label basic>px</Label>
                            </Form.Input>
                            <Form.Input fluid label="y">
                                <input
                                    defaultValue={this.defaultData.position.y}
                                    onChange={this.onPosYChange}
                                />
                                <Label basic>px</Label>
                            </Form.Input>
                            <Form.Input fluid label="z">
                                <input
                                    defaultValue={this.defaultData.position.z}
                                    onChange={this.onPosZChange}
                                />
                                <Label basic>px</Label>
                            </Form.Input>
                        </Form.Group>
                        <Message
                            error={true}
                            visible={errors.rotX !== undefined}
                            header={errors.rotX}
                            content={getErrorMessage(errors, 'rotX')}
                        />
                        <Message
                            error={true}
                            visible={errors.rotY !== undefined}
                            header={errors.rotY}
                            content={getErrorMessage(errors, 'rotY')}
                        />
                        <Message
                            error={true}
                            visible={errors.rotZ !== undefined}
                            header={errors.rotZ}
                            content={getErrorMessage(errors, 'rotZ')}
                        />
                        <h3>Rotation</h3>
                        <Form.Group widths="equal">
                            <Form.Input fluid label="x">
                                <input
                                    defaultValue={this.defaultData.rotation.x}
                                    onChange={this.onRotXChange}
                                />
                                <Label basic>&deg;</Label>
                            </Form.Input>
                            <Form.Input fluid label="y">
                                <input
                                    defaultValue={this.defaultData.rotation.y}
                                    onChange={this.onRotYChange}
                                />
                                <Label basic>&deg;</Label>
                            </Form.Input>
                            <Form.Input fluid label="z">
                                <input
                                    defaultValue={this.defaultData.rotation.z}
                                    onChange={this.onRotZChange}
                                />
                                <Label basic>&deg;</Label>
                            </Form.Input>
                        </Form.Group>
                        <Message
                            error={true}
                            visible={errors.FoV !== undefined}
                            header={errors.FoV}
                            content={getErrorMessage(errors, 'FoV')}
                        />
                        <Form.Input fluid label="Field of View">
                            <input
                                defaultValue={this.defaultData.fieldOfView}
                                onChange={this.onFoVChange}
                            />
                            <Label basic>&deg;</Label>
                        </Form.Input>
                        <h3>Dimensions</h3>
                        <Message
                            error={true}
                            visible={errors.width !== undefined}
                            header={errors.width}
                            content={getErrorMessage(errors, 'width')}
                        />
                        <Message
                            error={true}
                            visible={errors.height !== undefined}
                            header={errors.height}
                            content={getErrorMessage(errors, 'height')}
                        />
                        <Form.Group widths="equal">
                            <Form.Input fluid label="Width">
                                <input
                                    defaultValue={this.defaultData.width}
                                    onChange={this.onWidthChange}
                                />
                                <Label basic>px</Label>
                            </Form.Input>
                            <Form.Input fluid label="Height">
                                <input
                                    defaultValue={this.defaultData.height}
                                    onChange={this.onHeightChange}
                                />
                                <Label basic>px</Label>
                            </Form.Input>
                        </Form.Group>
                        <h3>Link Server</h3>
                        <Message
                            error={true}
                            visible={errors.linkIP !== undefined}
                            header={errors.linkIP}
                            content={getErrorMessage(errors, 'linkIP')}
                        />
                        <Message
                            error={true}
                            visible={errors.linkPort !== undefined}
                            header={errors.linkPort}
                            content={getErrorMessage(errors, 'linkPort')}
                        />
                        <Form.Group widths="equal">
                            <Form.Input
                                fluid
                                label="IP Address"
                                defaultValue={this.defaultData.link.ip}
                                onChange={this.onLinkIPChange}
                            />
                            <Form.Input
                                fluid
                                label="Port"
                                defaultValue={this.defaultData.link.port}
                                onChange={this.onLinkPortChange}
                            />
                        </Form.Group>
                        <Message
                            error={true}
                            visible={errors.linkUsername !== undefined}
                            header={errors.linkUsername}
                            content={getErrorMessage(errors, 'linkUsername')}
                        />
                        <Message
                            error={true}
                            visible={errors.linkPassword !== undefined}
                            header={errors.linkPassword}
                            content={getErrorMessage(errors, 'linkPassword')}
                        />
                        <Form.Group widths="equal">
                            <Form.Input
                                fluid
                                label="Username"
                                defaultValue={this.defaultData.link.username}
                                onChange={this.onLinkUsernameChange}
                            />
                            <Form.Input
                                fluid
                                label="Password"
                                defaultValue={this.defaultData.link.password}
                                onChange={this.onLinkPasswordChange}
                            />
                        </Form.Group>
                        <Message
                            error={true}
                            visible={errors.linkThreeDFilter !== undefined}
                            header={errors.linkThreeDFilter}
                            content={getErrorMessage(errors, 'linkThreeDFilter')}
                        />
                        <Form.Input
                            fluid
                            label="3D Filter"
                            defaultValue={this.defaultData.link.threeDFilter}
                            onChange={this.onLink3dFilterChange}
                        />
                        <Message
                            error={true}
                            visible={errors.linkLookBack !== undefined}
                            header={errors.linkLookBack}
                            content={getErrorMessage(errors, 'linkLookBack')}
                        />
                        <Message
                            error={true}
                            visible={errors.linkLookAhead !== undefined}
                            header={errors.linkLookAhead}
                            content={getErrorMessage(errors, 'linkLookAhead')}
                        />
                        <Form.Group widths="equal">
                            <Form.Input
                                fluid
                                label="Look back"
                                placeholder="days"
                                defaultValue={this.defaultData.link.lookBack}
                                onChange={this.onLinkLookBackChange}
                            />
                            <Form.Input
                                fluid
                                label="Look ahead"
                                placeholder="days"
                                defaultValue={this.defaultData.link.lookAhead}
                                onChange={this.onLinkLookAheadChange}
                            />
                        </Form.Group>
                        <h3>Background Colour</h3>
                        <Message
                            error={true}
                            visible={errors.backgroundColour !== undefined}
                            header={errors.backgroundColour}
                            content={getErrorMessage(errors, 'backgroundColour')}
                        />
                        <input
                            type="color"
                            defaultValue={this.defaultData.bgcolor}
                            onChange={this.onBackgroundColourChange}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        loading={this.props.cameras.update.loading}
                        onClick={this.props.onSubmit}
                        positive
                    >Submit</Button>
                    <Button
                        onClick={this.props.onClose}
                        negative
                    >Cancel</Button>
                </Modal.Actions>
            </Modal>
        )
    }
}

UpdateBIMModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    bimFinal3dImage: PropTypes.object,
    bimFinal3dImageActions: PropTypes.object.isRequired
}

export default UpdateBIMModal
