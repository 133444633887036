import * as types from './actionTypes';
import { API_BASE_URL } from '../config';
import { notAuthorisedResponse } from './authActions';
import { fetchWithToken } from '../util/accessToken';
import { addMessage } from './messengerActions'
import { log } from './logActions'

export function videoEditListRequest() {
    return (dispatch, getState) => {
        dispatch({
            type: types.VIDEO_EDIT_LIST_REQUEST
        })
        const url = `${API_BASE_URL}/api/videoedits`
        return fetchWithToken(
            url, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (response.status === 401) {
                dispatch(notAuthorisedResponse())
            } else {
                return response.json().then(data => {
                    if ('error' in data) {
                        throw new Error(data['error'])
                    }
                    dispatch({
                        type: types.VIDEO_EDIT_LIST_RESPONSE,
                        videoEdits: data
                    })
                })
                .catch((err) => {
                    dispatch({
                            type: types.VIDEO_EDIT_LIST_ERROR,
                            error: err.message
                        })
                })
            }
        })
        .catch((err) => {
            dispatch({
                    type: types.VIDEO_EDIT_LIST_ERROR,
                    error: err.message
                })
            })
        }
    }

export function deleteVideoEditRequest(id) {
    return (dispatch, getState) => {
        dispatch({
            type: types.DELETE_VIDEO_EDIT_REQUEST
        })
        const url = `${API_BASE_URL}/api/videoedits/${id}`
        return fetchWithToken(
            url, {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (response.status === 401) {
                dispatch(notAuthorisedResponse())
            } else if (!response.ok) {
                response.json().then(data => {
                    dispatch(log("error", data))
                    dispatch({
                        type: types.DELETE_VIDEO_EDIT_ERROR,
                        error: data.errors,
                        message: data.message
                        })
                })
            } else {
                if(response.status === 204) {
                    dispatch(
                        addMessage(
                            "success",
                            "Success",
                            "Successfully deleted video."
                        )
                    )
                    dispatch({
                        type: types.DELETE_VIDEO_EDIT_SUCCESS,
                        message: 'Your request to delete this video has been successful'

                    })
                }
            }
        })
        .catch((err) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch({
                    type: types.DELETE_VIDEO_EDIT_ERROR,
                    error: err.errors,
                    message: err.message
                })
            dispatch(log("error", err))
            })
        }
    }

export function removeVideoEdit(id) {
    return {
        type: types.REMOVE_VIDEO_EDIT,
        videoEditId: id
    }
}

export function requeueVideoEditRequest(id) {
    return (dispatch, getState) => {
        dispatch({
            type: types.REQUEUE_VIDEO_EDIT_REQUEST
        })
        const url = `${API_BASE_URL}/api/videoedits/${id}`
        return fetchWithToken(
            url, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (response.status === 401) {
                dispatch(notAuthorisedResponse())
            } else if (!response.ok) {
                response.json().then(data => {
                    dispatch(log("error", data))
                    dispatch({
                        type: types.REQUEUE_VIDEO_EDIT_ERROR,
                        error: data.errors,
                        message: data.message
                        })
                })
            } else {
                if(response.status === 200) {
                    dispatch(
                        addMessage(
                            "success",
                            "Success",
                            "Successfully requeued video."
                        )
                    )
                    dispatch({
                        type: types.REQUEUE_VIDEO_EDIT_SUCCESS,
                        message: 'Your request to requeue this video has been successful'

                    })
                }
            }
        })
        .catch((err) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch({
                    type: types.REQUEUE_VIDEO_EDIT_ERROR,
                    error: err.errors,
                    message: err.message
                })
            dispatch(log("error", err))
            })
        }
    }