import React, { Component } from 'react';
import PropTypes from 'prop-types'
import {
    BrowserRouter as Router,
    Route,
    NavLink,
    Redirect
  } from "react-router-dom";
import { Grid,
    Tab,
    Segment,
    Form,
    Button,
    Dropdown,
    Container,
    Modal,
    Loader,
    Icon } from 'semantic-ui-react'
import { API_BASE_URL }  from '../../../config'
import uniqid from 'uniqid'
import DocumentTitle from 'react-document-title'
import SetupModeControl from "../../SetupModeControl";
import ScheduleV4 from '../../schedule/ScheduleV4'
import CameraSettingsV4 from '../../CameraSettingsV4'
import CameraStatusV4 from '../../CameraStatusV4'
import TimeZone from "../../TimeZone"
import Logging from "../../Logging";
import Logs from '../../LogsV4';
import ImageRotation from "../../ImageRotation";
import LogViewer from "../../LogViewer"
import Photo from '../../Photo';
import placeholder from '../../../assets/placeholder.png'
import { isPermitted } from '../../../util/acl'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
// import LedColourChartModal from '../../modals/LedColourChart'

class CameraControlV4 extends Component {

    constructor(props) {
        super(props)
        this.interval = null
        this.version = null
        this.toggleRef = React.createRef();
        this.state = {
            liveviewImage: null,
            liveviewSrc: undefined,
            liveview: false,
            previewSrc: undefined,
            imageLoading: false,
            errorText: undefined,
            modalOpen: false,
            warningEventActive: false,
            shownWarning: false,
            overlayValues: [
                {
                    text: 'None',
                    value: 'none'
                },
                {
                    text:  '2x2 Grid',
                    value: 'g2x2'
                },
                {
                    text: '3x3 Grid',
                    value: 'g3x3'
                },
                {
                    text: '4x4 Grid',
                    value: 'g4x4'
                },
                {
                    text: '16:9 Middle',
                    value: 'c16_9mid'
                },
                {
                    text: '16:9 Top',
                    value: 'c16_9top'
                },
                {
                    text: '16:9 Bottom',
                    value: 'c16_9bot'
                },
            ],
        }
    }

    componentDidMount() {
        this.props.cameraControlV4Actions.reset()

        this.version = uniqid()
        this.props.cameraControlActions.version(this.version);

        if (this.props.camera_control_v4.fetch_setup_mode.details &&
            this.props.camera_control_v4.fetch_setup_mode.details.setup_mode_state === 'On') {
            this.createWarning()
        }

        return this.props.cameraControlActions.fetchCamera(this.props.cameras_id)
        .then(() => {
            this.props.cameraControlV4Actions.fetchCameraInfo(this.props.cameras_id)
        })
        .then(() => {
            // Check result and break chain if no camera found
            if (this.props.camera_control.camera.errors
                && this.props.camera_control.camera.errors.cameras_id
                && this.props.camera_control.camera.errors.cameras_id.cameraDoesNotExist
            ) {
                throw new Error("Camera not found")
            }
            return this.props.cameraControlV4Actions.fetchSetupMode(this.props.cameras_id)
        })
        .then(() => {
            if (this.props.camera_control.status.error) {
                throw new Error("Status error")
            }
            return this.props.cameraControlActions.fetchSystem(this.props.cameras_id)
        })
        // .then(() => {
        //     return this.props.cameraControlActions.fetchProperties(this.props.cameras_id)
        // })
        // .then(() => {
        //     return this.props.cameraControlActions.fetchStatus(this.props.cameras_id)
        // })
        // .then(() => {
        //     return this.props.cameraControlV4Actions.fetchLogs(this.props.cameras_id)
        // })
        
    }

    // unloadEvent = (e) => {
    //     e.preventDefault();
    //     e.returnValue = '';
    // }

    warningEventListener = (item, event) => {
        item.addEventListener(event, (e) => {
           e.preventDefault()
           if (e.stopPropagation) {
                e.stopPropagation();
            } else {
                e.cancelBubble = true;
            }
            if (this.props.camera_control_v4.fetch_setup_mode.details &&
                this.props.camera_control_v4.fetch_setup_mode.details.setup_mode_state === 'On') {
                if (!this.state.shownWarning) {
                    this.setState({modalOpen: 'warning'})
                }
            }
        })
        
    }

    createWarning = () => {
        //document.querySelectorAll('.menuWrap a').forEach((item) => {this.warningEventListener(item, 'mouseenter'), false})
        document.querySelectorAll('.header a').forEach((item) => {
            if (!item.classList.contains('js--led-toggle')) {
                this.warningEventListener(item, 'click');
            }
        }, false)


        // addEventListener("beforeunload", (e) => {
        //     this.unloadEvent(e)
        // });

        this.setState({warningEventActive: true})
    }

    componentDidUpdate(prevProps) {
        if (this.props.camera_control_v4.fetch_setup_mode.details && this.props.camera_control_v4.fetch_setup_mode.details !== prevProps.camera_control_v4.fetch_setup_mode.details) {
            if (this.props.camera_control_v4.fetch_setup_mode.details.setup_mode_state === 'OnRequested' && !this.interval) {
                this.pollCameraStatusEndpoint('On')
            } else if (this.props.camera_control_v4.fetch_setup_mode.details.setup_mode_state === 'OffRequested' && !this.interval) {
                this.pollCameraStatusEndpoint('Off')
                // removeEventListener('beforeunload', (e) => {
                //     this.unloadEvent(e)
                // });

            } else if (this.props.camera_control_v4.fetch_setup_mode.details.setup_mode_state === 'On' && !this.state.warningEventActive) {
                // If the camera is on but the warning event hasn't been created then call it.
                this.createWarning()
            }
        }
    }

    isInSetupMode = () => {
        const setupModeStatus = (this.props.camera_control_v4.fetch_setup_mode.details
            && this.props.camera_control_v4.fetch_setup_mode.details.setup_mode_state === 'On') ? true : false

        return setupModeStatus
    }

    getTimeZone = () => {
        return (
            <TimeZone
                cameraControlActions={this.props.cameraControlActions}
                cameras_id={this.props.cameras_id}
                camera_control={this.props.camera_control}
            />
        )
    }

    getImageRotation = () => {
        return (
            <ImageRotation
                cameraControlActions={this.props.cameraControlActions}
                cameras_id={this.props.cameras_id}
                camera_control={this.props.camera_control}
            />
        )
    }

    pollCameraStatusEndpoint = (option) => {
        let done = false
        if (!this.interval) {
            this.interval = setInterval(async () => {
                if (done) {
                    return;
                }

                this.props.cameraControlV4Actions.fetchSetupMode(this.props.cameras_id, true)
                if (this.props.camera_control_v4.fetch_setup_mode.details.setup_mode_state === option) {
                    clearInterval(this.interval);
                    this.interval = null;
                    done = true;
                }
            }, 30000)
        }
    }

    getSetupModeControl = () => {
        const setupModeStatus = (this.props.camera_control_v4.fetch_setup_mode.details
            && this.props.camera_control_v4.fetch_setup_mode.details.setup_mode_state) ? this.props.camera_control_v4.fetch_setup_mode.details.setup_mode_state : 'Unknown'

        const notUserConfigured = (this.props.cameras.camera.hasOwnProperty("details") && this.props.cameras.camera.details.user_configured === false)

        return (
            <>
                {/* {this.getPotIdOrLoader()} */}
                {(setupModeStatus === 'OnRequested' && notUserConfigured)?
                    <div>
                        <Button positive style={{marginTop: '15px'}}>Please plug in your camera</Button>
                    </div>
                :
                    <SetupModeControl
                        size='big'
                        loading={this.props.camera_control_v4.fetch_setup_mode.loading}
                        processing={this.props.camera_control_v4.setup_mode.processing}
                        action={this.props.camera_control_v4.setup_mode.action}
                        status={setupModeStatus}
                        success={this.props.camera_control_v4.setup_mode.success}
                        error={this.props.camera_control_v4.setup_mode.error}
                        errors={this.props.camera_control_v4.setup_mode.errors}
                        notUserConfigured={notUserConfigured}
                        showOffModal={this.showSetupModeOffModal}
                        onStartClick={() => {
                            this.props.cameraControlV4Actions.requestOnSetupMode(this.props.cameras_id).then(() => {
                                if (!this.interval) {
                                    this.pollCameraStatusEndpoint('On')
                                }
                            })
                        }}
                        onStopClick={() => {
                            this.props.cameraControlV4Actions.requestOffSetupMode(this.props.cameras_id).then(() => {
                                // Update the camera user_configured value on first save
                                if (notUserConfigured) {
                                    this.props.camerasActions.update(this.props.cameras.camera.details.id, {
                                        pot_id: this.props.cameras.camera.details.pot_id,
                                        type: this.props.cameras.camera.details.type,
                                        status: this.props.cameras.camera.details.status,
                                        user_configured: true
                                    },
                                    true)
                                }
                    
                                if (!this.interval) {
                                    this.pollCameraStatusEndpoint('Off')
                                }
                            })
                        }}
                        ref={this.toggleRef}
                    />
                }
            </>
        )
    }

    showSetupModeOffModal = () => {
        this.setState({modalOpen: 'off'})
    }

    getTopLabels = () => {
        const manufacturer = (this.props.camera_control.properties.details) ?
            this.props.camera_control.properties.details.manufacturer : null

        const cameraModel = (this.props.camera_control.properties.details) ?
            this.props.camera_control.properties.details.camera_model : null

        const topLabels = (this.props.camera_control.properties.details) ?
            <div className='top-label'>
                <span>Manufacturer: <span className='inside-span'>{manufacturer}</span>
                &nbsp;Model: <span className='inside-span'>{cameraModel}</span></span>
            </div>: null

        return topLabels
    }

    getBottomLabels = () => {

        if (this.props.camera_control.loading_preview_image) {
            return (
                <div>
                    <div className='animated-blank-line'></div>
                </div>
            )
        }

        const isoSpeed = (
            this.props.camera_control.exif.details
            && this.props.camera_control.exif.details.iso_speed
        ) ?
            this.props.camera_control.exif.details.iso_speed : null

        const shutterSpeed = (this.props.camera_control.exif.details) ?
            this.props.camera_control.exif.details.shutter_speed : null

        const focalLength = (this.props.camera_control.exif.details) ?
            this.props.camera_control.exif.details.focal_length : null

        const exposureCompensation = (
            this.props.camera_control.exif.details
            && this.props.camera_control.exif.details.exposure_compensation
        ) ?
            this.props.camera_control.exif.details.exposure_compensation : null

        const aperture = (
            this.props.camera_control.exif.details
            && this.props.camera_control.exif.details.aperture
        ) ?
            this.props.camera_control.exif.details.aperture : null

        if (this.props.camera_control.exif.details) {
            return (
                <div className='exif-details'>
                    <span>Shutter Speed: <span className='inside-span'>{shutterSpeed}</span></span>
                    <span>Aperture: <span className='inside-span'>ƒ{aperture}</span></span>
                    <span>ISO Speed: <span className='inside-span'>{isoSpeed}</span></span>
                    {exposureCompensation && <span>Exposure Comp: <span className='inside-span'>{exposureCompensation}</span></span>}
                    <span>Focal Length: <span className='inside-span'>{focalLength}</span></span>
                </div>
            )
        }
        return null;
    }

    getPotIdOrLoader = () => {
        const potId = (this.props.camera_control.camera.details) ?
            this.props.camera_control.camera.details.pot_id : null

        const lastCheckIn = (this.props.camera_control.camera.details) ?
            this.props.camera_control.camera.details.last_check_in : null
        if (!potId) {
            return (
                <div className='animated-blank-line'></div>
            )
        }
        //return (<div><h1>{potId}</h1><p>PCID: {this.props.camera_control.camera.details.pcid}</p><p>Last Check In: {lastCheckIn}</p><br/></div>)
        return (<div className="camera-control__title"><h1>{potId}</h1><p>Last Check In: {lastCheckIn}</p></div>)
    }

    reloadPreviewImage = (optionalQuality = null) => {
        // Let's re-render the latest preview image in a controlled manner
        const version = uniqid()
        this.props.cameraControlActions.loadingPreviewImage(true)
        const src = this.getPreviewUrl(optionalQuality, version)
        let previewImage = new Image()
        previewImage.src = src
        previewImage.onload = () => {
            // We only propagate the changes after pre-caching the image...
            // Fix disappearing image between tab changes
            this.setState({previewSrc: src})
            this.props.cameraControlActions.extractExif(this.props.cameras_id, previewImage)
            this.props.cameraControlActions.loadingPreviewImage(false)
        }
    }

    onPreview = () => {
        this.reloadPreviewImage()
    }

    onShoot = () => {
        this.props.cameraControlV4Actions.shoot(this.props.cameras_id).then(() => {
            //this.reloadPreviewImage()
        })
    }

    fullResModal = () => {
        return (
            <Modal
                onClose={() => this.setState({modalOpen: false})}
                onOpen={() => this.setState({modalOpen: 'preview'})}
                open={this.state.modalOpen === 'preview'}
                size='small'
                >
                <Modal.Header>Full Resolution Preview</Modal.Header>
                <Modal.Content>
                    <p>
                        The full resolution preview is a large file and depending on signal strength, fetching the image could take a few minutes.
                    </p>
                    <p><strong>Are you sure you would like to continue?</strong></p>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content="Close"
                        onClick={() => this.setState({modalOpen: false})}
                        primary
                    />
                    <Button
                        as='a'
                        href={`${API_BASE_URL}/api/cameras/${this.props.cameras_id}/camera-control/preview.jpg?access_token=${localStorage.getItem('accessToken')}&size=full&version=${this.version}`}
                        target='_blank'
                        rel='noopener noreferrer'
                        content="Fetch Preview"
                        onClick={() => {
                            this.setState({modalOpen: false})

                        }}
                        positive
                    />
                </Modal.Actions>
            </Modal>
        )
    }

    setupModeWarningAnimation = () => {
        let toggle = document.querySelector('.setup-mode-control')

        if (!toggle.classList.contains('animate')) {
            toggle.classList.add('animate')

            setTimeout(() => {
                toggle.classList.remove('animate')
              }, "2000")
        }
    }

    warningModal = () => {
        return (
            <Modal
                onClose={() => this.setState({modalOpen: false})}
                onOpen={() => this.setState({modalOpen: 'warning'})}
                open={this.state.modalOpen === 'warning'}
                size='small'
                >
                <Modal.Header>Warning! Camera setup mode is currently enabled</Modal.Header>
                <Modal.Content>
                    <p>If you have finished setting up your camera and wish to navigate to another page please disable setup mode.</p>
                    <p><strong>If the camera is left in setup mode it will not shoot when scheduled to.</strong></p>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content="Close"
                        onClick={() => {
                            this.setState({modalOpen: false})
                            this.setupModeWarningAnimation()
                        }}
                        negative
                        labelPosition='left'
                        icon='warning'
                    />
                </Modal.Actions>
            </Modal>
        )
    }

    setupOffModal = () => {
        return (
            <Modal
                onClose={() => this.setState({modalOpen: false})}
                onOpen={() => this.setState({modalOpen: 'off'})}
                open={this.state.modalOpen === 'off'}
                size='small'
                >
                <Modal.Header>Turn off Setup Mode?</Modal.Header>
                <Modal.Content>
                    <p>By clicking this button you are about to turn off Setup Mode.</p>
                    <p>This will put the camera into shooting mode.</p>
                    <p><strong>Please ensure the camera shot composition meets your requirements before continuing.</strong></p>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content="Close"
                        onClick={() => {
                            this.setState({modalOpen: false})
                        }}
                        negative
                        labelPosition='left'
                        icon='warning'
                    />
                    <Button
                        content="Proceed"
                        primary
                        onClick={() => {
                            this.setState({modalOpen: false})
                            this.toggleRef.current.onToggle();
                        }}
                    />
                </Modal.Actions>
            </Modal>
        )
    }

    getErrorMessage = () => {
        return (
            <div style={{
                width: "1056px",
                height: "704px",
                backgroundColor: "#7f7f7f",
                color: "#fff",
                textAlign: "center",
                textTransform: "uppercase",
                paddingTop: "200px",
                fontSize: "4em"
            }}>
                <p>{this.state.errorText}</p>
            </div>
        )
    }

    getLiveviewUrl = () => {
        return `${API_BASE_URL}/api/cameras/${this.props.cameras_id}`
            + `/camera-control-v4/live-view?access_token`
            + `=${localStorage.getItem('accessToken')}`
    }

    getLogging = () => {
        return (
            <Logging
                  cameraControlActions={this.props.cameraControlActions}
                  cameras_id={this.props.cameras_id}
                  camera_control={this.props.camera_control}
            />
        )
    }

    getLogs = () => {
        return (
            <Logs
                cameraControlActions={this.props.cameraControlActions}
                auth={this.props.auth}
                cameras_id={this.props.cameras_id}
                camera_control={this.props.camera_control}
                camera_control_v4={this.props.camera_control_v4}
            />
        )
    }

    getPreviewUrl = (optionalQuality = null, optionalVersion = null) => {
        const quality = (optionalQuality) ?
            optionalQuality
            : this.props.camera_control.preview_image_quality
        const version = (optionalVersion) ?
            optionalVersion
            : this.props.camera_control.version
        return `${API_BASE_URL}/api/cameras/${this.props.cameras_id}`
            + `/camera-control/preview.jpg?access_token`
            + `=${localStorage.getItem('accessToken')}&quality=${quality}&version=${version}`
    }

    getPreviewImageOrLoader = () => {
        const src = this.state.previewSrc ? this.state.previewSrc : placeholder

        const style = {
            width: '100%',
        }

        return (
            <Segment
                basic
                style={{
                    width: '100%',
                    height: 'auto',
                    padding: 0
                }}
            >
            <div>
                <TransformWrapper>
                        <TransformComponent wrapperStyle={style} contentStyle={style}>
                            <div className='camera-control-v4-preview-image'>
                                <img id="preview-image" src={src} alt="Camera Preview"/>
                                <canvas ref={(canvas) => {this.canvas = canvas}} />
                            </div>
                        </TransformComponent>
                </TransformWrapper>
            </div>
            </Segment>
        )
    }

    getPreviewOrLoader = () => {
        if (this.props.camera_control.system.loading) {
            return (
                <Loader inline="centered" active />
            )
        } else {
            return (
                <Form className='preview-controls'>
                    <Form.Group>
                        <Form.Field>
                            <label>Preview Quality</label>
                            <Dropdown
                                floating
                                selection
                                labeled
                                placeholder='Preview Quality'
                                defaultValue={this.props.camera_control.lp4_preview_image_quality}
                                onChange={(evt, val) => {
                                    const quality = val.value
                                    if (quality === this.props.camera_control.lp4_preview_image_quality) {
                                        return
                                    }
                                    this.reloadPreviewImage(quality)
                                    const qualityItem = this.props.camera_control.preview_image_quality_range.find((item) => {
                                        return item.value === quality
                                    })
                                    this.props.cameraControlActions.log('Info', `Preview quality set to ${qualityItem.text}`, Date.now())
                                }}
                                options={this.props.camera_control.preview_image_quality_range}
                                style={{minWidth: '90px'}}
                            />
                        </Form.Field>
                        <Form.Field className='field--shot'>
                            <label>Preview Image</label>
                            <Button circular icon='camera' color='green'
                                    loading={this.props.camera_control.loading_preview_image}
                                    onClick={this.onPreview}/>
                        </Form.Field>
                        <Form.Field className='field--shot'>
                            <label>Full Res Image</label>
                            <Button circular icon='camera' color='blue'
                                //loading={this.props.camera_control.loading_preview_image}
                                onClick={() => this.setState({modalOpen: 'preview'})}/>
                        </Form.Field>

                        {/* <Form.Field>
                            <label>Shoot</label>
                            <Button circular icon='camera' color='orange'
                                    loading={this.props.camera_control.shoot.processing}
                                    onClick={this.onShoot}/>
                        </Form.Field> */}
                        {/* <Form.Field className='mobile-hidden'>
                            <label>Overlay</label>
                            <Dropdown

                                selection
                                labeled
                                placeholder='Overlay'
                                defaultValue='none'
                                onChange={this.changeOverlay}
                                options={this.state.overlayValues}
                            />
                        </Form.Field> */}
                    </Form.Group>
                </Form>

            )
        }
    }

    changeOverlay = (event, {value}) => {
        const width = this.canvas.width
        const height = this.canvas.height
        const context = this.canvas.getContext('2d')
        context.clearRect(0, 0, width, height);
        switch(value) {
            case 'g2x2':
                this.drawLine(width / 2, 0, width / 2, height)
                this.drawLine(0, height / 2, width, height / 2)
                break
            case 'g3x3':
                this.drawLine(width / 3, 0, width / 3, height)
                this.drawLine(2 * width / 3, 0, 2 * width / 3, height)
                this.drawLine(0, height / 3, width, height / 3)
                this.drawLine(0, 2 * height / 3, width, 2 * height / 3)
                break
            case 'g4x4':
                this.drawLine(width / 4, 0, width / 4, height)
                this.drawLine(2 * width / 4, 0, 2 * width / 4, height)
                this.drawLine(3 * width / 4, 0, 3 * width / 4, height)
                this.drawLine(0, height / 4, width, height / 4)
                this.drawLine(0, 2 * height / 4, width, 2 * height / 4)
                this.drawLine(0, 3 * height / 4, width, 3 * height / 4)
                break
            case 'c16_9mid':
                const barHeightM = (height - width * (9 / 16)) / 2
                this.drawRectangle(0, 0, width, barHeightM)
                this.drawRectangle(0, height - barHeightM, width, barHeightM)
                break
            case 'c16_9top':
                const barHeightT = (height - width * (9 / 16))
                this.drawRectangle(0, height - barHeightT, width, barHeightT)
                break
            case 'c16_9bot':
                const barHeightB = (height - width * (9 / 16))
                this.drawRectangle(0, 0, width, barHeightB)
                break
            default:

        }
    }

    drawLine = (x1, y1, x2, y2) => {
        if (!this.canvas) {
            return null
        }
        const context = this.canvas.getContext('2d')
        context.beginPath();
        context.moveTo(x1,y1);
        context.lineTo(x2,y2);
        context.stroke();
    }

    drawRectangle = (x1, y1, w, h) => {
        if (!this.canvas) {
            return null
        }
        const context = this.canvas.getContext('2d')
        context.fillStyle = "rgba(0, 0, 0, 0.6)";
        context.fillRect(x1, y1, w, h);
    }

    scheduleColumnProps = () => {
        if (this.props.camera_control_v4.camera_info && this.props.camera_control_v4.camera_info.monitoring_data) {
            return `mobile={16} tablet={16} computer={16} largeScreen={10} widescreen={8}`
        } else {
            return `mobile={16}`
        }
    }

    getContainerPanes = () => {
        let panes = []

        const schedulePane =  {
            menuItem: {
                as: NavLink,
                id: "tab1",
                to: `/cameras/${this.props.cameras_id}/camera-control-v4`,
                content:'Schedule',
                exact: true,
                key: "info"
            }, pane: (
                <Route
                    path={`/cameras/${this.props.cameras_id}/camera-control-v4`}
                    exact
                    render={() => (
                        <Tab.Pane attached={false}>
                            <Grid>
                                <Grid.Row className='no-padding-bottom'>
                                    {this.props.camera_control_v4.camera_info && this.props.camera_control_v4.camera_info.monitoring_data && <>
                                        <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={6} widescreen={8}>
                                            <Grid className='grid-row--top' style={{'marginBottom': 0}}>
                                                <Grid.Row>
                                                    <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={16} widescreen={16}>
                                                        {(this.props.camera_control.camera.details && this.props.camera_control.camera.details.thumbnail_url)?
                                                            <>
                                                                <h2>Latest Image</h2>
                                                                <Photo
                                                                    src={this.props.camera_control.camera.details.thumbnail_url}
                                                                />
                                                            </>
                                                        : null }
                                                    </Grid.Column>
                                                    <Grid.Column className='mobile-hidden' tablet={8} computer={8} largeScreen={16} widescreen={16}>
                                                        {<CameraStatusV4
                                                            {...this.props.camera_control_v4.camera_info}
                                                            cameraName={this.props.cameras.camera.details.custom_name}
                                                        />}
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Grid.Column>
                                    </>}

                                    <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={10} widescreen={8}>
                                        {(isPermitted(this.props.auth.user, 'get-scheduler-schedule')) ?
                                            <ScheduleV4
                                                auth={this.props.auth}
                                                schedule={this.props.schedule}
                                                match={this.props.match}
                                                authActions={this.props.authActions}
                                                scheduleActions={this.props.scheduleActions}
                                            />
                                        : null}
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Tab.Pane>
                    )}
                />
            )
        }

        const cameraControlPane = {
            menuItem: (!this.isInSetupMode())? {
                content:'Camera Setup',
                disabled: true
            } : {
                as: NavLink,
                id: "tab2",
                to: `/cameras/${this.props.cameras_id}/camera-control-v4/controls`,
                content:'Camera Setup',
                exact: true,
                key: "cameraControl",
            }, pane: (
                <Route
                    path={`/cameras/${this.props.cameras_id}/camera-control-v4/controls`}
                    exact
                    render={() => {
                        const log = this.props.camera_control.log
                        if (this.isInSetupMode()) {
                            return (
                                <Tab.Pane attached={false}>
                                    <Grid stackable>
                                        <Grid.Row className='no-padding-bottom'>
                                            <Grid.Column mobile={16} tablet={16} computer={8} largeScreen={10} widescreen={11}>
    
                                                {this.state.errorText ?
                                                    this.getErrorMessage()
                                                : this.getPreviewImageOrLoader()}
                                            </Grid.Column>
                                            <Grid.Column mobile={16} tablet={16} computer={8} largeScreen={6} widescreen={5}>
                                                <Segment
                                                    basic
                                                    style={{display: 'flex', justifyContent: 'space-between', padding: 0}}
                                                >
                                                    {this.getPreviewOrLoader()}
                                                </Segment>
                                                <CameraSettingsV4
                                                    cameraControlActions={this.props.cameraControlActions}
                                                    cameraControlV4Actions={this.props.cameraControlV4Actions}
                                                    cameras_id={this.props.cameras_id}
                                                    camera_control={this.props.camera_control}
                                                    camera_control_v4={this.props.camera_control_v4}
                                                    shootPreview={this.onPreview}
                                                />
                                                <LogViewer
                                                    loading={this.props.camera_control.camera.loading}
                                                    accessToken={localStorage.getItem('accessToken')}
                                                    name={'Activity Log'}
                                                    fromWhen={false}
                                                    log={log}
                                                    uploadShot={this.props.cameraControlV4Actions.uploadShot}
                                                    fullResClick={() => this.setState({modalOpen: 'preview'})}
                                                    isLP4={true}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    {this.fullResModal()}
                                </Tab.Pane>
                            )
                        } else {
                            return (
                                <Redirect to={`/cameras/${this.props.cameras_id}/camera-control-v4`} />
                            )
                        }
                    }}
                />
            )
        }

        if (isPermitted(this.props.auth.user, 'get-cameracontrol-settings')) {
            panes.push(schedulePane)
        }

        if (isPermitted(this.props.auth.user, 'get-cameracontrol-settings')) {
            panes.push(cameraControlPane)
        }

        return panes
    }

    

    render = () => {
        let title
        const potId = (this.props.camera_control.camera.details) ?
            this.props.camera_control.camera.details.pot_id : null
        if (potId) {
            title = `${potId} Camera Control V4`
        } else {
            title = 'Camera Control V4'
        }
        return (
            <>
                <div className="sub-header sub-header--camera-control">
                    <Container fluid={true}>
                        <div>
                            <h1 className='sub-header__heading'>
                                {this.props.camera_control.camera.details && this.props.camera_control.camera.details.custom_name}
                                &nbsp;-&nbsp;
                                {(this.props.camera_control.camera.details && this.props.camera_control.camera.details.projects_name) ?
                                this.props.camera_control.camera.details.projects_name :
                                'Not assigned to a project'}
                            </h1>
                            <p>Last Check In: {this.props.camera_control.camera.details && this.props.camera_control.camera.details.last_check_in
                                ? this.props.camera_control.camera.details.last_check_in : 'Never checked in'}</p>
                            <Button color='black' tiny onClick={this.props.headerActions.showLedChart} basic className='led-toggle'>View Camera LED Chart</Button>
                        </div>
                        {this.getSetupModeControl()}
                    </Container>
                </div>

                <div className={`camera-control${(this.isInSetupMode())? ' camera-control--active' : null}`}>
                    <DocumentTitle title={title} />
                    <Tab
                        menu={{ secondary: true, className: 'camera-control__tabs' }}
                        panes={this.getContainerPanes()}
                        //defaultActiveIndex={0}
                        activeIndex={-1} 
                        renderActiveOnly={false} 
                    />
                </div>
                {/* <LedColourChartModal open={this.state.modalOpen === 'chart'} onClose={() => this.setState({modalOpen: false})} /> */}
                {this.warningModal()}
                {this.setupOffModal()}
            </>

        )
    }
}

CameraControlV4.propTypes = {
    cameras_id: PropTypes.any.isRequired,
    cameraControlActions: PropTypes.shape({
        fetchStatus: PropTypes.func.isRequired,
        fetchProperties: PropTypes.func.isRequired,
        fetchCamera: PropTypes.func.isRequired,
        fetchExif: PropTypes.func.isRequired,
        fetchLogs: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired,
        preview: PropTypes.func.isRequired,
        shoot: PropTypes.func.isRequired,
        updateProperties: PropTypes.func.isRequired,
        loadingPreviewImage: PropTypes.func.isRequired,
        previewQualityVersion: PropTypes.func.isRequired,
        fetchSystem: PropTypes.func.isRequired,
        updateSystem: PropTypes.func.isRequired
    })
}

export default CameraControlV4
