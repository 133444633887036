import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input, Icon } from 'semantic-ui-react'


class PasswordInput extends Component {

    constructor(props) {
        super(props)

        this.state = {
            type: "password"
        }
    }

    render = () => {

        const i = <Icon name={(this.state.type === "password") ? "unhide" : "hide"} link data-testid="icon--password" onClick={() => {

            this.setState((prevState) => {
                const passwordType = (prevState.type === "password") ? "text" : "password"
                return {
                    type: passwordType
                }
            })

        }} />

        return (
            <div className='password-input'>
                <Input
                    fluid
                    icon={i}
                    type={this.state.type}
                    loading={this.props.processing}
                    defaultValue={this.props.value}
                    placeholder={this.props.placeholder}
                    onChange={ (evt, val) => {
                        const value = val.value
                        this.props.onChange(value)
                    }}
                />
            </div>
        )
    }

}

PasswordInput.propTypes = {
    processing: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string
}

export default PasswordInput