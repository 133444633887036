/** Class representing a table column. */
class TableColumn  {
    /**
    * Create a column.
    * @param {string} key - Object key for colummn data
    * @param {string} title - Human-readable column heading.
    * @param {boolean} sortable - If the column may be sorted by the user.
    */
    constructor(key, title, sortable = true) {
        this.key = key
        this.title = title
        this.sortable = sortable
    }
}

export default TableColumn
