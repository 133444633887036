import React, { Component } from 'react'
import {
    Button,
    Modal,
    Message
} from 'semantic-ui-react'
import { getErrorMessage } from './../../util/errors'
import PropTypes from 'prop-types'

class DeleteRoleModal extends Component {

    getHeader = (error, success) => {
        if (error) {
            return "Error"
        } else if (success) {
            return "Success!"
        } else {
            return ""
        }
    }

    getContent = (error, success, loading, errors) => {
        if (error) {
            let message = getErrorMessage(this.props.errors, 'roles_id')
            if (!message) {
                message = getErrorMessage(this.props.errors, 'unknownErrorType')
            }
            return (message) ? message : "There was an unexpected error"
        } else if (success) {
            return <p>Successfully deleted this role</p>
        } else if (loading) {
            return <p>Processing...</p>
        } else {
            return <p>Are you sure you want to delete this tag?</p>
        }
    }

    getButtons = (error, success) => {
        if (error) {
            return null
        } else if (success) {
            return null
        } else {
            return <div>
                <Button onClick={this.props.onClose} negative>Cancel</Button>
                <Button
                    onClick={() => { this.props.onClick(this.props.roleId) }}
                    loading={this.props.processing}
                    positive
                    icon='checkmark'
                    labelPosition='right'
                    content='Delete' />
            </div>
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.success === true && prevProps.success === false) {
            this.onClose()
        } else {
            if (this.props !== prevProps) {
                this.setState( (prevState, props) => {
                    return { ...props }
                })
            }
        }
    }

    onClose = () => {
        this.setState({}, () => {
            this.props.onClose()
        })
    }

    render() {
        return (
            <Modal size={"small"} open={this.props.open} onClose={this.props.onClose} closeIcon>
                <Modal.Header>Remove Role</Modal.Header>
                <Modal.Content>
                    <Message
                        error={this.props.error}
                        success={this.props.success}
                        visible={true}
                        header={this.getHeader(this.props.error, this.props.success)}
                        content={this.getContent(this.props.error, this.props.success, this.props.loading, this.props.errors)}
                    />
                    <div className='modalButtons'>
                        {this.getButtons(this.props.error, this.props.success)}
                    </div>
                </Modal.Content>
            </Modal>
        )
    }

}

DeleteRoleModal.propTypes = {
    open: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired,
    processing: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    roleId: PropTypes.number
}

export default DeleteRoleModal
