import * as types from './actionTypes'
import { API_BASE_URL}  from '../config'
import { addMessage } from './messengerActions'
import { log } from './logActions'
import { fetchWithToken } from '../util/accessToken'
import { notAuthorisedResponse } from './authActions'

export function addUserResponse(history, response) {

    return dispatch => {
        if (!response.errors) {
            dispatch(
                addMessage(
                    "success",
                    "Success",
                    "Successfully created new user."
                )
            )
            dispatch(addUserResponseSuccess(response))
            // dispatch(resetErrors())
            // history.push('/users')
        } else {
            dispatch(addUserResponseErrors(response))
        }
    }
}

export function addUserResponseSuccess(response) {
    return {type: types.ADD_USER_RESPONSE_SUCCESS, addUser: response}
}

export function addUserResponseErrors(response) {
    return {type: types.ADD_USER_RESPONSE_ERRORS, addUser: response}
}

export function addUserRequest(history, userDetails) {

    return (dispatch, getState) => {

        dispatch({ type: types.ADD_USER_REQUEST })

        return fetchWithToken(
            API_BASE_URL + '/api/users',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(userDetails)
            },
            dispatch,
            getState
        )
        .then(response => {
            if (response.status === 401) {
                dispatch(notAuthorisedResponse())
            } else {
                return response.json()
            }
        })
        .then((json) => {
            dispatch(addUserResponse(history, json))
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function adminUserRolesResponse(response) {
    if (!Array.isArray(response)) {
        return {type: types.ADMIN_USER_ROLES_RESPONSE, addUser: []}
    } else {
        return {type: types.ADMIN_USER_ROLES_RESPONSE, addUser: response}
    }
}

export function adminUserRolesRequest() {

    return (dispatch, getState) => {

        dispatch({ type: types.ADMIN_USER_ROLES_REQUEST })

        return fetchWithToken(
            API_BASE_URL + '/api/admin/user/roles',
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (response.status === 401) {
                dispatch(notAuthorisedResponse())
            } else {
                return response.json()
            }
        })
        .then((json) => {
            dispatch(adminUserRolesResponse(json))
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })

    }
}


export function adminUserStatusesResponse(response) {
    if (!Array.isArray(response)) {
        return {type: types.ADMIN_USER_STATUSES_RESPONSE, addUser: []}
    } else {
        return {type: types.ADMIN_USER_STATUSES_RESPONSE, addUser: response}
    }
}

export function adminUserStatusesRequest() {

    return (dispatch, getState) => {

        dispatch({ type: types.ADMIN_USER_STATUSES_REQUEST })

        return fetchWithToken(
            API_BASE_URL + '/api/admin/user/statuses',
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (response.status === 401) {
                dispatch(notAuthorisedResponse())
            } else {
                return response.json()
            }
        })
        .then((json) => {
            dispatch(adminUserStatusesResponse(json))
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })

    }
}

export function adminUserClientsResponse(response) {
    if (response.errors) {
        return {type: types.ADMIN_USER_CLIENTS_RESPONSE, addUser: [] }
    } else {
        return {type: types.ADMIN_USER_CLIENTS_RESPONSE, addUser: response.clients}
    }
}

export function adminUserClientsRequest() {

    return (dispatch, getState) => {

        dispatch({ type: types.ADMIN_USER_CLIENTS_REQUEST })

        return fetchWithToken(
            API_BASE_URL + '/api/clients',
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (response.status === 401) {
                dispatch(notAuthorisedResponse())
            } else {
                return response.json()
            }
        })
        .then((json) => {
            dispatch(adminUserClientsResponse(json))
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })

    }
}

export function resetErrors() {
    return { type: types.ADD_USER_RESET_ERRORS }
}

export function updateLocalUserState(user) {
    return { type: types.ADD_USER_UPDATE_LOCAL_USER_STATE, user: user }
}

export function ucpaCompleted() {
    return { type: types.ADD_USER_UCPA_COMPLETED }
}

export function clearErrors() {
    return { type: types.ADD_USER_CLEAR_ERRORS }
}
