import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {Popup, Grid } from 'semantic-ui-react'

class SignalStrength extends Component {

    getStrength = () => {
        const strength = this.props.details ? this.props.details.strength : null

        const barOneClass = (strength && strength > 0) ? 'active' : null
        const barTwoClass = (strength && strength >= 20) ? 'active' : null
        const barThreeClass = (strength && strength >= 40) ? 'active' : null
        const barFourClass = (strength && strength >= 60) ? 'active' : null
        const barFiveClass = (strength && strength >= 80) ? 'active' : null

        return (
            <div className='bars'>
                <div className={`signal-strength-bar signal-strength-bar-1 ${barOneClass}`}></div>
                <div className={`signal-strength-bar signal-strength-bar-2 ${barTwoClass}`}></div>
                <div className={`signal-strength-bar signal-strength-bar-3 ${barThreeClass}`}></div>
                <div className={`signal-strength-bar signal-strength-bar-4 ${barFourClass}`}></div>
                <div className={`signal-strength-bar signal-strength-bar-5 ${barFiveClass}`}></div>
            </div>
        )
    }

    getContent = () => {

        const strength = this.getStrength()
        const operator = this.props.details ? this.props.details.operator : null
        const measurement = this.props.details ? this.props.details.measurement : null

        const strengthPercentage = (this.props.details) ? this.props.details.strength : 0

        const details = this.props.compact ? null :
            <div>
                <span className='operator'>{operator}</span>
                <span className='measurement'>{measurement}</span>
            </div>


        return (
            <div>
                <Popup
                    trigger={strength}
                    content={`Strength: ${strengthPercentage}%`}
                    size='mini'
                />
                {details}
            </div>
        )
    }

    isReady = () => {
        return !this.props.loading
    }

    render = () => {

        if (!this.isReady() && !this.props.compact) {
            return (
                <div className='signal-strength'>
                    {/*<Dimmer active inverted><Loader content='' /></Dimmer>*/}
                    <Grid columns='1'>
                        <Grid.Row>
                            <Grid.Column><div className="animated-blank-line" /></Grid.Column>
                            <Grid.Column><div className="animated-blank-line" /></Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            )

        } else {
            return (
                <div className='signal-strength'>
                    {this.getContent()}
                </div>
            )
        }

    }
}

SignalStrength.propTypes = {
    loading: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    errors: PropTypes.object,
    details: PropTypes.object,
    compact: PropTypes.bool
}

export default SignalStrength
