import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as authActions from '../actions/authActions'
import * as logActions from '../actions/logActions'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

const Security = (props) => {

    useEffect(() => {
        if (props.auth.forceOut) {
            props.history.push('/auth')
        }
    }, [props.auth])

    return (<div />)
}

Security.propTypes = {
    authActions: PropTypes.object,
    auth: PropTypes.object,
    logActions: PropTypes.object
}

function mapStateToProps(state) {
    return {
        auth: state.auth
    }
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(authActions, dispatch),
        logActions: bindActionCreators(logActions, dispatch),
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Security))
