import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'semantic-ui-react';

/**
 * Class representing a photo.
 *
 * Loads in an image and if it fails to load, a fallback placeholder image is
 * loaded instead.
 */
class Photo extends Component {
    constructor(props) {
        super(props);
        this.placeholder = require('../assets/placeholder.png');
        this.state = { src: props.src ? props.src : this.placeholder };
        this.imageStyle = {
            width: '100%',
            height: 'auto',
        };
        this.wraperStyle = {
            position: 'relative',
        };
        this.wrapperClassNames = '';
        this.imageClassNames = '';
    }

    onError = () => {
        this.setState({ src: this.placeholder });
    };

    render() {
        if (
            this.props.potVersion === 4 &&
            this.props.portraitTagIndex !== null &&
            this.props.portraitTagIndex > -1
        ) {
            this.wrapperClassNames = 'image--portrait';
        }

        return (
            <div className={this.wrapperClassNames} style={this.wrapperStyle}>
                <Image
                    style={this.imageStyle}
                    label={this.props.label}
                    onError={this.onError}
                    src={this.state.src}
                    onDoubleClick={() => {
                        this.props.authAsCamera(this.props.cameraId);
                    }}
                />
                {this.props.infoPane && this.props.infoPane}
            </div>
        );
    }
}

Photo.propTypes = {
    src: PropTypes.string,
    cameraId: PropTypes.number,
    authAsCamera: PropTypes.func,
};

export default Photo;
