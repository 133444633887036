import * as types from './actionTypes'

export function showHeader() {
    return { type: types.SHOW_HEADER }
}

export function showLedChart() {
    return { type: types.SHOW_LED_CHART }
}

export function hideHeader() {
    return { type: types.HIDE_HEADER }
}

export function hideLedChart() {
    return { type: types.HIDE_LED_CHART }
}