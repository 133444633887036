import React, { Component } from 'react'
import { Button, Modal, Message } from 'semantic-ui-react'
import { getErrorMessage } from './../../util/errors'
import PropTypes from 'prop-types'

class DeleteTagModal extends Component {

    getHeader = (error, success) => {
        if (error) {
            return "Error"
        } else if (success) {
            return "Success!"
        } else {
            return ""
        }
    }

    getContent = (error, success, loading, errors) => {
        if (error) {
            if (errors.clients_id) {
                return <p>{getErrorMessage(errors, "clients_id")}</p>
            } else if (errors.tags_id) {
                return <p>{getErrorMessage(errors, "tags_id")}</p>
            }
        } else if (success) {
            return <p>Successfully deleted this tag</p>
        } else if (loading) {
            return <p>Processing...</p>
        } else {
            return <p>Are you sure you want to delete this tag?</p>
        }
    }

    getButtons = (error, success) => {
        if (error) {
            return <div><Button onClick={this.props.onClose} negative primary>Cancel</Button></div>
        } else if (success) {
            return <div><Button onClick={this.props.onClose} positive primary>Close</Button></div>
        } else {
            return <div>
                <Button onClick={this.props.onClose} negative>Cancel</Button>
                <Button
                    onClick={() => { this.props.onClick(this.props.tagId) }}
                    loading={this.props.loading}
                    positive
                    icon='checkmark'
                    labelPosition='right'
                    content='Delete' />
            </div>
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.success === true && prevProps.success === false) {
            this.onClose()
        } else {
            if (this.props !== prevProps) {
                this.setState( (prevState, props) => {
                    return { ...props }
                })
            }
        }
    }

    onClose = () => {
        this.setState({}, () => {
            this.props.onClose()
        })
    }

    render() {
        return (
            <Modal size={"small"} open={this.props.open} onClose={this.props.onClose} closeIcon>
                <Modal.Header>Delete a tag</Modal.Header>
                <Modal.Content>
                    <Message
                        error={this.props.error}
                        success={this.props.success}
                        visible={true}
                        header={this.getHeader(this.props.error, this.props.success)}
                        content={this.getContent(this.props.error, this.props.success, this.props.loading, this.props.errors)}
                    />
                    <div className='modalButtons'>
                        {this.getButtons(this.props.error, this.props.success)}
                    </div>
                </Modal.Content>
            </Modal>
        )
    }
}

DeleteTagModal.propTypes = {
    open: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    tagId: PropTypes.number
}

export default DeleteTagModal
