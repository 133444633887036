import initialState from './initialState';
import {
    CAMERAS_FETCH_AVAILABLE_REQUEST,
    CAMERAS_FETCH_AVAILABLE_RESPONSE_ERRORS,
    CAMERAS_FETCH_AVAILABLE_RESPONSE_SUCCESS,
    CAMERAS_ASSOCIATE_CAMERA_WITH_PROJECT_REQUEST,
    CAMERAS_ASSOCIATE_CAMERA_WITH_PROJECT_RESPONSE_ERRORS,
    CAMERAS_ASSOCIATE_CAMERA_WITH_PROJECT_RESPONSE_SUCCESS,
    CAMERAS_UNASSOCIATE_CAMERA_WITH_PROJECT_REQUEST,
    CAMERAS_UNASSOCIATE_CAMERA_WITH_PROJECT_RESPONSE_ERRORS,
    CAMERAS_UNASSOCIATE_CAMERA_WITH_PROJECT_RESPONSE_SUCCESS,
    CAMERAS_FETCH_ALL_REQUEST,
    CAMERAS_FETCH_ALL_RESPONSE_SUCCESS,
    CAMERAS_FETCH_ALL_RESPONSE_ERRORS,
    CAMERAS_FETCH_ALL_RESET,
    CAMERAS_FETCH_ALL_RESPONSE_END,
    CAMERAS_ALL_TAGS_REQUEST,
    CAMERAS_ALL_TAGS_RESPONSE,
    CAMERAS_ALL_STATUSES_REQUEST,
    CAMERAS_ALL_STATUSES_RESPONSE,
    CAMERAS_TOGGLE_FILTER_TAG,
    CAMERAS_TOGGLE_FILTER_STATUS,
    CAMERAS_TOGGLE_FILTER_TYPE,
    CAMERAS_TOGGLE_FILTER_VERSION,
    CAMERAS_SET_FILTER_TAGS,
    CAMERAS_SET_FILTER_STATUSES,
    CAMERAS_SET_FILTER_VERSIONS,
    CAMERAS_SET_FILTER_TYPES,
    CAMERAS_SET_SORT_BY,
    CAMERAS_SORT_BY_CHANGE,
    CAMERAS_CREATE_REQUEST,
    CAMERAS_CREATE_RESPONSE_ERRORS,
    CAMERAS_CREATE_RESPONSE_SUCCESS,
    CAMERAS_UPDATE_REQUEST,
    CAMERAS_UPDATE_RESPONSE_ERRORS,
    CAMERAS_UPDATE_RESPONSE_SUCCESS,
    CAMERAS_CREATE_RESET,
    CAMERAS_CAMERA_REQUEST,
    CAMERAS_CAMERA_RESPONSE_ERRORS,
    CAMERAS_CAMERA_RESPONSE_SUCCESS,
    CAMERAS_UPDATE_RESET,
    CAMERAS_GRID_VIEW_FETCH_REQUEST,
    CAMERAS_GRID_VIEW_FETCH_RESPONSE_ERRORS,
    CAMERAS_GRID_VIEW_FETCH_RESPONSE_SUCCESS,
    CAMERAS_GRID_VIEW_FETCH_RESPONSE_END,
    CAMERAS_GRID_VIEW_RESET,
    CAMERAS_LIST_VIEW_FETCH_REQUEST,
    CAMERAS_LIST_VIEW_FETCH_RESPONSE_ERRORS,
    CAMERAS_LIST_VIEW_FETCH_RESPONSE_SUCCESS,
    CAMERAS_LIST_VIEW_FETCH_RESPONSE_END,
    CAMERAS_LIST_VIEW_RESET,
    CAMERAS_FILTER_BY,
    CAMERAS_FETCH_CAMERA_INFO_REQUEST,
    CAMERAS_FETCH_CAMERA_INFO_RESPONSE_ERRORS,
    CAMERAS_FETCH_CAMERA_INFO_RESPONSE_SUCCESS,
    CAMERAS_CAMERA_INFO_RESET,
    CAMERA_ADD_PROCESSING_PROCESS,
    CAMERA_REMOVE_PROCESSING_PROCESS,
    CAMERAS_FETCH_CAMERA_TAGS_REQUEST,
    CAMERAS_FETCH_CAMERA_TAGS_RESPONSE_ERRORS,
    CAMERAS_FETCH_CAMERA_TAGS_RESPONSE_SUCCESS,
    CAMERAS_FETCH_CAMERA_TAGS_RESET,
    TAGS_ASSOCIATE_CAMERA_WITH_TAG_RESPONSE_SUCCESS,
    TAGS_UNASSOCIATE_CAMERA_WITH_TAG_RESPONSE_SUCCESS,
    CAMERAS_RESET,
    IMPORT_CSV_REQUEST,
    IMPORT_CSV_RESPONSE_ERRORS,
    IMPORT_CSV_RESPONSE_SUCCESS
} from '../actions/actionTypes';

export default function cameras(state = initialState.cameras, action) {

    switch (action.type) {
        case CAMERAS_FETCH_ALL_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    list: {
                        loading: true,
                        errors: {},
                        success: false,
                        error: false,
                        cameras: state.list.cameras,
                        hasMore: false,
                        currentPage: state.list.currentPage
                    }
                }
            )
        case CAMERAS_FETCH_ALL_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    list: {
                        loading: false,
                        errors: {},
                        success: true,
                        error: false,
                        cameras: [...state.list.cameras, ...action.cameras],
                        total: action.totalItems,
                        hasMore: state.list.cameras.length + action.cameras.length < action.totalItems,
                        currentPage: state.list.currentPage + 1
                    }
                }
            )
        case CAMERAS_FETCH_ALL_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    list: {
                        loading: false,
                        errors: action.errors,
                        success: false,
                        error: true,
                        cameras: state.list.cameras,
                        total: 0,
                        hasMore: false,
                        currentPage: state.list.currentPage
                    }
                }
            )
        case CAMERAS_FETCH_ALL_RESET:
            return Object.assign(
                {},
                state,
                {
                    list: {
                        loading: false,
                        errors: {},
                        success: false,
                        error: false,
                        cameras: [],
                        total: 0,
                        hasMore: true,
                        currentPage: 1
                    }
                }
            )
        case CAMERAS_FETCH_ALL_RESPONSE_END:
            return Object.assign(
                {},
                state,
                {
                    list: {
                        loading: false,
                        errors: {},
                        success: true,
                        error: false,
                        cameras: [...state.list.cameras, ...action.cameras],
                        total: action.totalItems,
                        hasMore: false,
                        currentPage: state.list.currentPage
                    }
                }
            )
        case CAMERAS_FETCH_AVAILABLE_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    available: {
                        loading: true,
                        errors: {},
                        success: false,
                        error: false,
                        cameras: []
                    }
                },
            )
        case CAMERAS_FETCH_AVAILABLE_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    available: {
                        loading: false,
                        errors: action.errors,
                        success: false,
                        error: true,
                        cameras: []
                    }
                }
            )
        case CAMERAS_FETCH_AVAILABLE_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    available: {
                        loading: false,
                        errors: {},
                        success: true,
                        error: false,
                        cameras: action.cameras
                    }
                }
            )
        case CAMERAS_ASSOCIATE_CAMERA_WITH_PROJECT_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    associate: {
                        loading: true,
                        errors: {},
                        success: false,
                        error: false,
                        projects_id: action.projects_id,
                        cameras_id: action.cameras_id,
                        details: {}
                    },
                }
            )
        case CAMERAS_ASSOCIATE_CAMERA_WITH_PROJECT_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    associate: {
                        loading: false,
                        errors: action.errors,
                        success: false,
                        error: true,
                        projects_id: action.projects_id,
                        cameras_id: action.cameras_id,
                        details: {}
                    },
                }
            )
        case CAMERAS_ASSOCIATE_CAMERA_WITH_PROJECT_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    associate: {
                        loading: false,
                        errors: {},
                        success: true,
                        error: false,
                        projects_id: action.projects_id,
                        cameras_id: action.cameras_id,
                        details: action.details
                    },
                }
            )
        case CAMERAS_UNASSOCIATE_CAMERA_WITH_PROJECT_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    unassociate: {
                        loading: true,
                        errors: {},
                        success: false,
                        error: false,
                        cameras_id: action.cameras_id,
                        details: {}
                    }
                }
            )
        case CAMERAS_UNASSOCIATE_CAMERA_WITH_PROJECT_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    unassociate: {
                        loading: false,
                        errors: action.errors,
                        success: false,
                        error: true,
                        cameras_id: action.cameras_id,
                        details: {}
                    }
                }
            )
        case CAMERAS_UNASSOCIATE_CAMERA_WITH_PROJECT_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    unassociate: {
                        loading: false,
                        errors: {},
                        success: true,
                        error: false,
                        cameras_id: action.cameras_id,
                        details: action.details
                    }
                }
            )

        case CAMERAS_ALL_TAGS_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    tags: {
                        loading: true,
                        success: false,
                        tags: [],
                        filterBy: state.tags.filterBy
                    }
                }
            )
        case CAMERAS_ALL_TAGS_RESPONSE:
            return Object.assign(
                {},
                state,
                {
                    tags: {
                        loading: false,
                        success: true,
                        tags: action.tags,
                        filterBy: state.tags.filterBy
                    }
                }
            )
        case CAMERAS_ALL_STATUSES_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    statuses: {
                        ready: false,
                        loading: true,
                        success: false,
                        // statuses: [],
                        // filterBy: []
                        statuses: state.statuses.statuses,
                        filterBy: state.statuses.filterBy

                    }
                }
            )
        case CAMERAS_ALL_STATUSES_RESPONSE:
            return Object.assign(
                {},
                state,
                {
                    statuses: {
                        ready: true,
                        loading: false,
                        success: true,
                        statuses: action.statuses,
                        default: action.gridDefault,
                        filterBy: state.statuses.filterBy
                    }
                }
            )
        case CAMERAS_TOGGLE_FILTER_TAG:
            {
                let filterBy = state.tags.filterBy
                const index = filterBy.indexOf(action.id)
                if (index > -1) {
                    filterBy.splice(index, 1)
                } else {
                    filterBy.push(action.id)
                }
                return Object.assign(
                    {},
                    state,
                    {
                        tags: {
                                ...state.tags,
                                filterBy: filterBy
                        }
                    }
                )
            }
        case CAMERAS_TOGGLE_FILTER_STATUS:
            {
                let filterBy = state.statuses.filterBy
                const index = filterBy.indexOf(action.status)
                if (index > -1) {
                    filterBy.splice(index, 1)
                } else {
                    filterBy.push(action.status)
                }
                return Object.assign(
                    {},
                    state,
                    {
                        statuses: {
                                ...state.statuses,
                                filterBy: filterBy
                        }
                    }
                )
            }
        case CAMERAS_TOGGLE_FILTER_TYPE:
            {
                let filterBy = state.filterByTypes
                const index = filterBy.indexOf(action.name)
                if (index > -1) {
                    filterBy.splice(index, 1)
                } else {
                    filterBy.push(action.name)
                }
                return Object.assign(
                    {},
                    state,
                    {
                        filterByTypes: filterBy
                    }
                )
            }
        case CAMERAS_TOGGLE_FILTER_VERSION:
            {
                let filterBy = state.versions.filterBy
                const index = filterBy.indexOf(action.version)
                if (index > -1) {
                    filterBy.splice(index, 1)
                } else {
                    filterBy.push(action.version)
                }
                return Object.assign(
                    {},
                    state,
                    {
                        versions: {
                                ...state.versions,
                                filterBy: filterBy
                        }
                    }
                )
            }
        case CAMERAS_SET_FILTER_TAGS:
            {
                let filterBy
                if (Array.isArray(action.filter)) {
                    filterBy = action.filter
                } else {
                    filterBy = [action.filter]
                }
                // Convert strings to ints
                filterBy=filterBy.map(Number);
                return Object.assign(
                    {},
                    state,
                    {
                        tags: {
                                ...state.tags,
                                filterBy: filterBy
                        }
                    }
                )
            }
        case CAMERAS_SET_FILTER_STATUSES:
            {
                let filterBy
                if (Array.isArray(action.filter)) {
                    filterBy = action.filter
                } else {
                    filterBy = [action.filter]
                }
                return Object.assign(
                    {},
                    state,
                    {
                        statuses: {
                                ...state.statuses,
                                filterBy: filterBy
                        }
                    }
                )
            }
        case CAMERAS_SET_FILTER_VERSIONS:
            {
                let filterBy
                if (Array.isArray(action.filter)) {
                    filterBy = action.filter
                } else {
                    filterBy = [action.filter]
                }
                return Object.assign(
                    {},
                    state,
                    {
                        versions: {
                                ...state.versions,
                                filterBy: filterBy
                        }
                    }
                )
            }
        case CAMERAS_SET_FILTER_TYPES:
            {
                let filterBy
                if (Array.isArray(action.filter)) {
                    filterBy = action.filter
                } else {
                    filterBy = [action.filter]
                }
                return Object.assign(
                    {},
                    state,
                    {
                        filterByTypes: filterBy
                    }
                )
            }
        case CAMERAS_SET_SORT_BY:
            {
                let ascending = true
                if (action.ascending === 'false') {
                    ascending = false
                }
                return Object.assign(
                    {},
                    state,
                    {
                        sort: {
                            field: action.field,
                            ascending: ascending
                        }
                    }
                )
            }
        case CAMERAS_SORT_BY_CHANGE:
            {
                let ascending = state.sort.ascending
                if (action.field === state.sort.field) {
                    ascending = !ascending
                } else {
                    ascending = true
                }
                return Object.assign(
                    {},
                    state,
                    {
                        sort: {
                            field: action.field,
                            ascending: ascending
                        }
                    }
                )
            }

        case CAMERAS_CREATE_RESET:
            return Object.assign(
                {},
                state,
                {
                    create: initialState.cameras.create
                }
            )

        case CAMERAS_CREATE_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    create: {
                        loading: true,
                        errors: {},
                        details: {},
                        success: false,
                        error: false
                    },
                }
            )

        case CAMERAS_CREATE_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    create: {
                        loading: false,
                        errors: action.errors,
                        details: {},
                        success: false,
                        error: true
                    },
                }
            )

        case CAMERAS_CREATE_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    create: {
                        loading: false,
                        errors: {},
                        details: action.details,
                        success: true,
                        error: false
                    },
                }
            )

        case CAMERAS_UPDATE_RESET:
            return Object.assign(
                {},
                state,
                {
                    update: initialState.cameras.update
                }
            )

        case CAMERAS_UPDATE_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    update: {
                        loading: true,
                        cameras_id: action.cameras_id,
                        errors: {},
                        details: {},
                        success: false,
                        error: false
                    },
                }
            )

        case CAMERAS_UPDATE_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    update: {
                        loading: false,
                        cameras_id: state.update.cameras_id,
                        errors: action.errors,
                        details: {},
                        success: false,
                        error: true
                    },
                }
            )

        case CAMERAS_UPDATE_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    update: {
                        loading: false,
                        cameras_id: state.update.cameras_id,
                        errors: {},
                        details: action.details,
                        success: true,
                        error: false
                    },
                }
            )

        case CAMERAS_CAMERA_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    camera: {
                        loading: true,
                        cameras_id: action.cameras_id,
                        errors: {},
                        details: {},
                        success: false,
                        error: false
                    },
                }
            )

        case CAMERAS_CAMERA_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    camera: {
                        loading: false,
                        cameras_id: state.camera.cameras_id,
                        errors: action.errors,
                        details: {},
                        success: false,
                        error: true
                    },
                }
            )

        case CAMERAS_CAMERA_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    camera: {
                        loading: false,
                        cameras_id: state.camera.cameras_id,
                        errors: {},
                        details: action.details,
                        success: true,
                        error: false
                    },
                }
            )

        case CAMERAS_LIST_VIEW_FETCH_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    list_view: {
                        loading: true,
                        errors: {},
                        success: false,
                        error: false,
                        cameras: state.list_view.cameras,
                        currentPage: action.currentPage,
                        total: state.list_view.total,
                    }
                }
            )

        case CAMERAS_LIST_VIEW_FETCH_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    list_view: {
                        loading: false,
                        errors: {},
                        success: true,
                        error: false,
                        cameras: [...action.cameras],
                        total: action.totalItems,
                        currentPage: action.currentPage
                    }
                }
            )

        case CAMERAS_LIST_VIEW_FETCH_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    list_view: {
                        loading: false,
                        errors: action.errors,
                        success: false,
                        error: true,
                        cameras: [],
                        total: 0,
                        currentPage: state.list_view.currentPage
                    }
                }
            )

        case CAMERAS_LIST_VIEW_FETCH_RESPONSE_END:
            return Object.assign(
                {},
                state,
                {
                        list_view: {
                            loading: false,
                            errors: {},
                            success: true,
                            error: false,
                            cameras: [...state.list_view.cameras, ...action.cameras],
                            total: action.totalItems,
                            currentPage: state.list_view.currentPage
                        }
                }
            )

        case CAMERAS_LIST_VIEW_RESET:
            return Object.assign(
                {},
                state,
                {
                    list_view: initialState.cameras.list_view
                }
            )

        case CAMERAS_GRID_VIEW_FETCH_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    grid_view: {
                        loading: true,
                        errors: {},
                        success: false,
                        error: false,
                        cameras: state.grid_view.cameras,
                        currentPage: state.grid_view.currentPage,
                        loadingMore: true,
                        hasMore: false,
                        total: state.grid_view.total
                    }
                }
            )

        case CAMERAS_GRID_VIEW_FETCH_RESPONSE_SUCCESS:
            // Okay, we only want to include unique cameras, not duplicates....
            let cameras = state.grid_view.cameras.slice()
            let output = JSON.parse(JSON.stringify(cameras));

            action.cameras.forEach((c, idx) => {
                const cameraIdx = output.findIndex((cam) => {
                    if (!cam) {
                        return false
                    }
                    return cam.id === c.id
                })

                // If the camera exists in state then update it in the array incase of any changes
                // There was an issue with Camera names not being updated after being set
                if (cameraIdx !== -1) {
                    output.splice(cameraIdx, 1, c)
                } else {
                    output.push(Object.assign({}, action.cameras[idx]))
                }
            })

            const hasMore = state.grid_view.cameras.length + action.cameras.length < action.totalItems;

            return Object.assign(
                {},
                state,
                {
                    grid_view: {
                        loading: false,
                        errors: {},
                        success: true,
                        error: false,
                        cameras: output,
                        total: action.totalItems,
                        hasMore: hasMore,
                        currentPage: (hasMore)? state.grid_view.currentPage + 1 : state.grid_view.currentPage,
                        loadingMore: false
                    }
                }
            )

        case CAMERAS_GRID_VIEW_FETCH_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    grid_view: {
                        loading: false,
                        errors: action.errors,
                        success: false,
                        error: true,
                        cameras: state.grid_view.cameras,
                        total: 0,
                        hasMore: false,
                        currentPage: state.grid_view.currentPage,
                        loadingMore: false
                    }
                }
            )

        case CAMERAS_GRID_VIEW_FETCH_RESPONSE_END:
            return Object.assign(
                {},
                state,
                {
                    grid_view: {
                        loading: false,
                        errors: {},
                        success: true,
                        error: false,
                        cameras: [...state.grid_view.cameras, ...action.cameras],
                        total: action.totalItems,
                        hasMore: false,
                        currentPage: state.grid_view.currentPage,
                        loadingMore: false
                    }
                }
            )

        case CAMERAS_GRID_VIEW_RESET:
            return Object.assign(
                {},
                state,
                {
                    grid_view: initialState.cameras.grid_view
                }
            )

        case CAMERAS_FILTER_BY:
            return Object.assign(
                {},
                state,
                {
                    filterBy: action.filterBy
                }
            )


        case CAMERAS_FETCH_CAMERA_INFO_REQUEST:

            {
                const cameraId = action.cameras_id;
                let cameras = state.camera_info.cameras.slice()
                const cameraIndex = cameras.findIndex((elem, idx) => {
                    if (!elem) {
                        return false
                    }
                    return elem.cameras_id === cameraId
                })
                if (cameraIndex === -1) {
                    cameras.push({
                        cameras_id: cameraId,
                        loading: true,
                        errors: {},
                        success: false,
                        error: false,
                        details: {}
                    })
                } else {
                    cameras[cameraIndex] = {
                        cameras_id: cameraId,
                        loading: true,
                        errors: {},
                        success: false,
                        error: false,
                        details: {}
                    }
                }
                return Object.assign(
                    {},
                    state,
                    {
                        camera_info: {
                            cameras: cameras
                        }
                    }
                )
            }

        case CAMERAS_FETCH_CAMERA_INFO_RESPONSE_ERRORS:

            {
                const cameraId = action.cameras_id;
                let cameras = state.camera_info.cameras.slice()
                const cameraIndex = cameras.findIndex((elem, idx) => {
                    if (!elem) {
                        return false
                    }
                    return elem.cameras_id === cameraId
                })
                if (cameraIndex > -1) {
                    cameras[cameraIndex] = {
                        cameras_id: cameraId,
                        loading: false,
                        errors: action.errors,
                        success: false,
                        error: true,
                        details: {}
                    }
                }
                return Object.assign(
                    {},
                    state,
                    {
                        camera_info: {
                            cameras: cameras
                        }
                    }
                )

            }

        case CAMERAS_FETCH_CAMERA_INFO_RESPONSE_SUCCESS:

            {
                const cameraId = action.cameras_id;
                let cameras = state.camera_info.cameras.slice()
                const cameraIndex = cameras.findIndex((elem, idx) => {
                    if (!elem) {
                        return false
                    }
                    return elem.cameras_id === cameraId
                })
                if (cameraIndex > -1) {
                    cameras[cameraIndex] = {
                        cameras_id: cameraId,
                        loading: false,
                        errors: {},
                        success: true,
                        error: false,
                        details: action.details
                    }
                }
                return Object.assign(
                    {},
                    state,
                    {
                        camera_info: {
                            cameras: cameras
                        }
                    }
                )
            }

        case CAMERAS_CAMERA_INFO_RESET:

            return Object.assign(
                {},
                state,
                {

                    camera_info: {
                        cameras: []
                    }
                }
            )

        case CAMERA_ADD_PROCESSING_PROCESS:

            let enabledProcesses = {}
            if (state.camera.details.processing) {
                enabledProcesses = state.camera.details.processing
            }
            
            enabledProcesses[action.parameters.type] = {...action.parameters}

            return Object.assign(
                {},
                state,
                {
                    camera: {
                        ...state.camera,
                        details: {
                            ...state.camera.details,
                            processing: {
                                ...enabledProcesses,
                            }
                        }
                    }
                }
            )
        case CAMERA_REMOVE_PROCESSING_PROCESS:
            let activeProcesses = {}

            activeProcesses = state.camera.details.processing
            delete activeProcesses[action.parameters.type]

            return Object.assign(
                {},
                state,
                {
                    camera: {
                        ...state.camera,
                        details: {
                            ...state.camera.details,
                            processing: {
                                ...activeProcesses,
                            }
                        }
                    }
                }
            )

        case CAMERAS_FETCH_CAMERA_TAGS_RESET:
            return Object.assign(
                {},
                state,
                {
                    camera_tags: initialState.cameras.camera_tags
                }
            )

        case CAMERAS_FETCH_CAMERA_TAGS_REQUEST:
            {
                const clientId = action.clients_id
                const cameraId = action.cameras_id

                let cameras = state.camera_tags.cameras.slice()
                const cameraIndex = cameras.findIndex((elem, idx) => {
                    if (!elem) {
                        return false
                    }
                    return elem.cameras_id === cameraId
                })
                if (cameraIndex === -1) {
                    cameras.push({
                        clients_id: clientId,
                        cameras_id: cameraId,
                        loading: true,
                        errors: {},
                        success: false,
                        error: false,
                        tags: []
                    })
                } else {
                    cameras[cameraIndex] = {
                        clients_id: clientId,
                        cameras_id: cameraId,
                        loading: true,
                        errors: {},
                        success: false,
                        error: false,
                        tags: cameras[cameraIndex].tags.slice()
                    }
                }
                return Object.assign(
                    {},
                    state,
                    {
                        camera_tags: {
                            cameras: cameras
                        }
                    }
                )
            }

        case CAMERAS_FETCH_CAMERA_TAGS_RESPONSE_ERRORS:
            {
                const clientId = action.clients_id
                const cameraId = action.cameras_id

                let cameras = state.camera_tags.cameras.slice()
                const cameraIndex = cameras.findIndex((elem, idx) => {
                    if (!elem) {
                        return false
                    }
                    return elem.cameras_id === cameraId
                })
                if (cameraIndex > -1) {
                    cameras[cameraIndex] = {
                        clients_id: clientId,
                        cameras_id: cameraId,
                        loading: false,
                        errors: action.errors,
                        success: false,
                        error: true,
                        tags: cameras[cameraIndex].tags.slice()
                    }
                }
                return Object.assign(
                    {},
                    state,
                    {
                        camera_tags: {
                            cameras: cameras
                        }
                    }
                )
            }

        case CAMERAS_FETCH_CAMERA_TAGS_RESPONSE_SUCCESS:
            {
                const clientId = action.clients_id
                const cameraId = action.cameras_id

                let cameras = state.camera_tags.cameras.slice()
                const cameraIndex = cameras.findIndex((elem, idx) => {
                    if (!elem) {
                        return false
                    }
                    return elem.cameras_id === cameraId
                })
                if (cameraIndex > -1) {
                    cameras[cameraIndex] = {
                        clients_id: clientId,
                        cameras_id: cameraId,
                        loading: false,
                        errors: {},
                        success: true,
                        error: false,
                        tags: action.tags.slice()
                    }
                }
                return Object.assign(
                    {},
                    state,
                    {
                        camera_tags: {
                            cameras: cameras
                        }
                    }
                )
        }

        case TAGS_ASSOCIATE_CAMERA_WITH_TAG_RESPONSE_SUCCESS:
        {
            const cameraId = action.cameras_id
            const tagId = action.tags_id
            const tag = action.tag
            // Handle updating the ListView
            let listViewCameras = state.list_view.cameras.slice()
            const listCameraIndex = listViewCameras.findIndex((elem, idx) => {
                if (!elem) {
                    return false
                }
                return elem.id === cameraId
            })
            if (listCameraIndex > -1) {
                let tags = listViewCameras[listCameraIndex].tags || []
                // Let's check the tag doesn't already exist...
                if (tags.findIndex((i) => {
                    if (!i) {
                        return false
                    }
                    return i.id === tagId
                }) === -1) {
                    tags.push(tag)
                    listViewCameras[listCameraIndex] =
                        Object.assign({}, listViewCameras[listCameraIndex], {tags: tags})
                }
            }
            // Handle updating the GridView
            let gridViewCameras = state.grid_view.cameras.slice()
            const gridCameraIndex = gridViewCameras.findIndex((elem, idx) => {
                if (!elem) {
                    return false
                }
                return elem.id === cameraId
            })
            if (gridCameraIndex > -1) {
                let tags = gridViewCameras[gridCameraIndex].tags || []
                // Let's check the tag doesn't already exist...
                if (tags.findIndex((i) => {
                        if (!i) {
                            return false
                        }
                        return i.id === tagId
                    }) === -1) {
                    tags.push(tag)
                    gridViewCameras[gridCameraIndex] =
                        Object.assign({}, gridViewCameras[gridCameraIndex], {tags: tags})

                }
            }
            return Object.assign(
                {},
                state,
                {
                    list_view: {
                        loading: state.list_view.loading,
                        errors: state.list_view.errors,
                        success: state.list_view.success,
                        error: state.list_view.error,
                        cameras: listViewCameras,
                        currentPage: state.list_view.currentPage,
                        total: state.list_view.total,
                    }
                },
                {
                    grid_view: {
                        loading: state.grid_view.loading,
                        errors: state.grid_view.errors,
                        success: state.grid_view.success,
                        error: state.grid_view.error,
                        cameras: gridViewCameras,
                        total: state.grid_view.total,
                        hasMore: state.grid_view.hasMore,
                        currentPage: state.grid_view.currentPage,
                        loadingMore: state.grid_view.loadingMore
                    }
                }
            )
        }

        case TAGS_UNASSOCIATE_CAMERA_WITH_TAG_RESPONSE_SUCCESS:

        {
            const cameraId = action.cameras_id
            const tagId = action.tags_id
            // Handle updating the ListView
            let listViewCameras = state.list_view.cameras.slice()
            const listCameraIndex = listViewCameras.findIndex((elem, idx) => {
                if (!elem) {
                    return false
                }
                return elem.id === cameraId
            })
            if (listCameraIndex > -1) {
                let tags = listViewCameras[listCameraIndex].tags || []
                if (tags.length > 0) {
                    const tagIdx = tags.findIndex((t) => {
                        if (!t) {
                            return false
                        }
                        return t.id === tagId
                    })
                    tags.splice(tagIdx, 1)

                    listViewCameras[listCameraIndex] =
                        Object.assign({}, listViewCameras[listCameraIndex], { tags: tags })
                }
            }
            // Handle updating the GridView
            let gridViewCameras = state.grid_view.cameras.slice()
            const gridCameraIndex = gridViewCameras.findIndex((elem, idx) => {
                if (!elem) {
                    return false
                }
                return elem.id === cameraId
            })
            if (gridCameraIndex > -1) {
                let tags = gridViewCameras[gridCameraIndex].tags || []
                if (tags.length > 0) {
                    // Should always be the case
                    const tagIdx = tags.findIndex((t) => {
                        if (!t) {
                            return false
                        }
                        return t.id === tagId
                    })
                    tags.splice(tagIdx, 1)
                    gridViewCameras[gridCameraIndex] =
                        Object.assign({}, gridViewCameras[gridCameraIndex], { tags: tags })
                }
            }
            return Object.assign(
                {},
                state,
                {
                    list_view: {
                        loading: state.list_view.loading,
                        errors: state.list_view.errors,
                        success: state.list_view.success,
                        error: state.list_view.error,
                        cameras: listViewCameras,
                        currentPage: state.list_view.currentPage,
                        total: state.list_view.total,
                    }
                },
                {
                    grid_view: {
                        loading: state.grid_view.loading,
                        errors: state.grid_view.errors,
                        success: state.grid_view.success,
                        error: state.grid_view.error,
                        cameras: gridViewCameras,
                        total: state.grid_view.total,
                        hasMore: state.grid_view.hasMore,
                        currentPage: state.grid_view.currentPage,
                        loadingMore: state.grid_view.loadingMore
                    }
                }
            )
        }

        case CAMERAS_RESET:
            return Object.assign(
                {},
                state,
                {
                    ...initialState.cameras
                }
            )

        case IMPORT_CSV_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    importCoordinates: {
                        loading: true,
                        errors: {},
                        details: {},
                        success: false,
                        error: false
                    },
                }
            )

        case IMPORT_CSV_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    importCoordinates: {
                        loading: false,
                        errors: action.errors,
                        details: {},
                        success: false,
                        error: true
                    },
                }
            )

        case IMPORT_CSV_RESPONSE_SUCCESS:
        return Object.assign(
            {},
            state,
            {
                importCoordinates: {
                    loading: false,
                    errors: {},
                    details: action.details,
                    success: true,
                    error: false
                }
            }
        )

        default:
            return state
    }
}
