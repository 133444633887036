import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Home from './Home'
import Auth from './Auth'
import AuthVia from './AuthVia'
import Logout from './Logout'
// Split the following out from the main bundle
import UsersContainer from '../containers/UsersContainer';
import Client from './Client'
import Admin from './pages/Admin';
import ViewClient from './pages/ViewClient';
import VideoEdits from './VideoEdits';
import ProjectsContainer from '../containers/ProjectsContainer';
import CameraControlContainer from '../containers/CameraControlContainer';
import CameraControlV4Container from '../containers/CameraControlV4Container';
import ScheduleContainer from '../containers/ScheduleContainer';
import FileListContainer from '../containers/FileListContainer';
import CameraUploadContainer from '../containers/CameraUploadContainer';
import CamerasContainer from '../containers/CamerasContainer';
import PotAssignContainer from '../containers/PotAssignContainer';
import RolesContainer from '../containers/RolesContainer';
import TagsContainer from '../containers/TagsContainer';
import Verify from './pages/Verify';
import ResetPassword from './pages/ResetPassword';
import ManageAccount from './pages/ManageAccount';
import SubscriptionsContainer from '../containers/SubscriptionsContainer'
import Contact from './pages/Contact'

const Main = (props) => {
    return (
        <main id="main">
            <Switch>
                <Route exact path='/' component={Home}/>
                <Route path='/admin' component={Admin}/>
                {/* The Route below is requred for the project access users list */}
                <Route path='/clients/:clientId/users/:userId/:action/project' render={()=> <UsersContainer isUserListLinkedFromProject={true}/>} />
                <Route path='/clients/:clientId/users/:userId/:action' component={UsersContainer}/>
                <Route path='/users/:action?' component={UsersContainer}/>
                <Route path='/clients/:clientId/projects/:projectId?/:action?' component={ProjectsContainer} />
                <Route path='/projects/:projectId/:action' component={ProjectsContainer} />
                <Route path='/projects/:action?' component={ProjectsContainer} />
                <Route path='/clients/:clientId/:action' component={Client}/>s
                <Route path='/clients/:id' component={ViewClient}/>
                <Route path='/clients' component={Client}/>
                <Route path='/videoEdits' component={VideoEdits}/>
                <Route path='/auth/:step?' component={Auth}/>
                <Route path='/authvia' component={AuthVia}/>
                <Route path='/logout' component={Logout}/>
                <Route path='/verify' component={Verify}/>
                <Route path='/resetPassword' component={ResetPassword}/>
                <Route path='/subscriptions' component={SubscriptionsContainer}/>
                <Route path='/cameras/:cameraId/camera-control' component={CameraControlContainer}/>
                <Route path='/cameras/:cameraId/camera-control-v4/:settings?' component={CameraControlV4Container}/>
                <Route path='/cameras/:cameraId/schedule' component={ScheduleContainer}/>
                <Route path='/cameras/:cameraId/file-list' component={FileListContainer} />
                <Route path='/cameras/:cameraId/upload' component={CameraUploadContainer} />
                <Route path='/cameras/pot-assign' component={PotAssignContainer} />
                <Route path='/cameras/deployed/true/show/id' render={() => <CamerasContainer {...props} vanity={true} hideClients={true} />} />
                <Route path='/cameras/deployed/true' render={() => <CamerasContainer {...props} vanity={true} />} />
                <Route path='/cameras/unstable/true' render={() => <CamerasContainer {...props} antivanity={true} />} />
                <Route path='/cameras' component={CamerasContainer}/>
                <Route path='/roles' component={RolesContainer}/>
                <Route path='/tags' component={TagsContainer}/>
                <Route path='/account' component={ManageAccount}/>
                {/* <Route path='/contact' component={Contact}/> */}
            </Switch>
        </main>
    )
}

// function asyncComponent(importComponent) {

//     class AsyncComponent extends Component {
//         constructor(props) {
//             super(props)
//             this.state = {
//                 component: null
//             }
//         }

//         async componentDidMount() {
//             const { default: component } = await importComponent()
//             this.setState({component: component})
//         }

//         render() {
//             const C = this.state.component
//             return C ? <C {...this.props} /> : null
//         }
//     }
//     return AsyncComponent
// }


export default Main
