import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import React, { Component } from 'react'
import * as authActions from '../actions/authActions'
import * as messengerActions from '../actions/messengerActions'
import * as tagsActions from '../actions/tagsActions'
import TagListView from './../components/TagListView'
import DocumentTitle from 'react-document-title'
import { isPermitted } from "./../util/acl"
import { Container, Button, Input } from 'semantic-ui-react'
import CreateTagModal from "../components/modals/CreateTag";
import UpdateTagModal from "../components/modals/UpdateTag";
import DeleteTagModal from "../components/modals/DeleteTag";
import { checkAuth } from '../util/accessToken'
import debounce from 'lodash/debounce'

class TagsContainer extends Component {

    constructor(props) {
        super(props)
        this.state = { type: null }
    }

    componentDidMount () {
        window.scrollTo(0, 0)

        return checkAuth(this.props.auth, this.props.authActions, this.props.history)
        .then(() => {
            this.props.messengerActions.removeAllMessages()
            this.props.tagsActions.reset()
            this.props.tagsActions.fetchAllTags(this.props.auth.user.clients_id, 1, 'ALL')
        })
        .catch(err => {})
    }

    onFilterByChange = debounce((filterBy) => {
        this.props.tagsActions.filterBy(filterBy)
        this.props.tagsActions.fetchAllTags(this.props.auth.user.clients_id, 1, 'ALL')
    }, 500)

    render() {
        const createTagButton = (isPermitted(this.props.auth.user, 'post-tags-tags')) ? <Button className='create-tag-button' onClick={() => {
            this.props.tagsActions.resetCreate()
            this.setState((prevState, props) => {
                return {
                    ...prevState,
                    type: "CreateTag"
                }
            })

        }} primary>Create tag</Button> : null

        return (
            <div className='tags-container'>
                <DocumentTitle title='Tags' />
                <header className="sub-header sub-header--users">
                    <Container fluid={true} style={{padding: '0 1.2em'}}>
                        <Input
                            className='filter-by-tag'
                            placeholder="Search..."
                            onChange={(evt, {value}) => {
                                this.onFilterByChange(value)
                            }}
                        />
                        {createTagButton}
                    </Container>
                </header>
                {/* <h2>Tags</h2> */}
                <TagListView
                    tagsActions={this.props.tagsActions}
                    tags={this.props.tags}
                    auth={this.props.auth}
                    onOpenUpdateTagModal={(tagId) => {
                        // Let's fetch the details...
                        this.props.tagsActions.resetUpdate()
                        this.props.tagsActions.fetchOneTag(this.props.auth.user.clients_id, tagId)
                        this.setState({type: "UpdateTag"})

                    }}

                    onOpenDeleteTagModal={(tagId) => {
                        // Let's fetch the details...
                        this.props.tagsActions.resetDelete()
                        // this.props.tagsActions.fetchOneTag(this.props.auth.user.clients_id, tagId)
                        this.setState({type: "DeleteTag", tags_id: tagId})

                    }}
                />

                <CreateTagModal
                    open={this.state.type === 'CreateTag'}
                    error={this.props.tags.create.error}
                    success={this.props.tags.create.success}
                    loading={this.props.tags.create.processing}
                    errors={this.props.tags.create.errors}
                    onClick={(details) => {
                        this.props.tagsActions.createTag(this.props.auth.user.clients_id, details).then(() => {
                            this.props.tagsActions.fetchAllTags(this.props.auth.user.clients_id, 1, 'ALL')
                        })
                    }}
                    onClose={() => {
                        this.setState({type: null}, () => {
                            // Let's re-render the list...
                            this.props.tagsActions.fetchAllTags(this.props.auth.user.clients_id, 1, 'ALL')
                        })
                    }}
                />

                <UpdateTagModal
                    open={this.state.type === 'UpdateTag'}
                    error={this.props.tags.update.error}
                    success={this.props.tags.update.success}
                    loading={this.props.tags.update.processing}
                    errors={this.props.tags.update.errors}
                    tag={this.props.tags.fetch_one.details}

                    onClick={(tagId, details) => {
                        this.props.tagsActions.updateTag(this.props.auth.user.clients_id, tagId, details).then(() => {
                            this.props.tagsActions.fetchAllTags(this.props.auth.user.clients_id, 1, 'ALL')
                        })
                    }}
                    onClose={() => {
                        this.setState({type: null}, () => {
                            // Let's re-render the list...
                            this.props.tagsActions.fetchAllTags(this.props.auth.user.clients_id, 1, 'ALL')
                        })
                    }}
                />

                <DeleteTagModal
                    open={this.state.type === 'DeleteTag'}
                    error={this.props.tags.remove.error}
                    success={this.props.tags.remove.success}
                    loading={this.props.tags.remove.processing}
                    errors={this.props.tags.remove.errors}
                    tagId={(this.state.type === 'DeleteTag') ? this.state.tags_id: null}
                    onClose={() => {
                        this.setState({type: null}, () => {
                            this.props.tagsActions.fetchAllTags(this.props.auth.user.clients_id, 1, 'ALL')
                        })
                    }}
                    onClick={(tagId) => {
                        this.props.tagsActions.deleteTag(this.props.auth.user.clients_id, tagId).then(() => {
                            this.props.tagsActions.fetchAllTags(this.props.auth.user.clients_id, 1, 'ALL')
                        })
                    }}
                />

            </div>
        )
    }
}

TagsContainer.propTypes = {
    authActions: PropTypes.object,
    auth: PropTypes.object,
    messengerActions: PropTypes.object,
    tagsActions: PropTypes.object
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        tags: state.tags
    }
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(authActions, dispatch),
        messengerActions: bindActionCreators(messengerActions, dispatch),
        tagsActions: bindActionCreators(tagsActions, dispatch)
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(TagsContainer))
