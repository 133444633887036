import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as headerActions from '../actions/headerActions'
import PropTypes from 'prop-types'

import { NavLink, withRouter, Link } from 'react-router-dom'
import {Container, Menu, Image, Icon, Button, Header as SemanticHeader} from "semantic-ui-react"
import { isPermitted } from "../util/acl"
import SettingsOverlay from './SettingsOverlay'
import LedColourChartModal from './modals/LedColourChart'

const Header = (props) => {
    const [userName, setUserName] = useState({
        firstName: 'My Account',
        lastName: null
    })
    const [displayOverlay, setDisplayOverlay] = useState(false)

    useEffect(() => {
        if (props.auth && props.auth.user && props.auth.user) {
            const usersFirstName = props.auth.user.first_name
            const usersLastName = props.auth.user.last_name
            if (usersFirstName || (usersFirstName && usersLastName)) {
                setUserName({
                    firstName: usersFirstName,
                    lastName: usersLastName
                });
            }
        }
    }, [props.auth])

    const onClickUser = () => {
        setDisplayOverlay(prevState => !prevState.displayOverlay)
    }

    const getSettingsOverlay = () => {
        if (displayOverlay) {
            return ( <SettingsOverlay onDismiss={() => setDisplayOverlay(false)} userId={props.auth.user.roles_id} />)
        }
        return null
    }

    const mobileMenuToggle = () => {
        const menu = document.querySelector('.menuWrap')
        if (menu) {
            menu.classList.toggle('active')
        }
    }

    const onLedChartClick = (e) => {
        e.preventDefault();
        // Fire redux function to trigger global state
    }

    const usersMenuItem = (isPermitted(props.auth.user, 'get-users-users')) ?
        <Menu.Item><NavLink to='/users' onClick={(mobileMenuToggle)}>Users</NavLink></Menu.Item> : null
    const projectsMenuItem = (isPermitted(props.auth.user, 'get-projects-projects')) ?
        <Menu.Item><NavLink to='/projects' onClick={(mobileMenuToggle)}>Projects</NavLink></Menu.Item> : null
    const clientsMenuItem = (isPermitted(props.auth.user, 'get-clients-clients')) ?
        <Menu.Item><NavLink to='/clients' onClick={(mobileMenuToggle)}>Clients</NavLink></Menu.Item>: null
    const camerasMenuItem = (isPermitted(props.auth.user, 'get-cameras-cameras')) ?
        <Menu.Item><NavLink to='/cameras' onClick={(mobileMenuToggle)}>Cameras</NavLink></Menu.Item> : null
    const tagsMenuItem = (isPermitted(props.auth.user, 'get-tags-tags')) ?
        <Menu.Item><NavLink to='/tags' onClick={(mobileMenuToggle)}>Tags</NavLink></Menu.Item> : null
    const videoEditsMenuItem = (isPermitted(props.auth.user, 'get-videoedits-index')) ?
        <Menu.Item><NavLink to='/videoEdits' onClick={(mobileMenuToggle)}>Video Edits</NavLink></Menu.Item> : null
    const subscriptionsMenuItem = (props.auth.user && props.auth.user.roles_id === 5) ?
        <Menu.Item><NavLink to='/subscriptions' onClick={(mobileMenuToggle)}>Subscriptions</NavLink></Menu.Item> : null
    const faqMenuItem =  (props.auth.user && props.auth.user.roles_id === 5) ? <Menu.Item><a href="https://lobsterpictures.tv/faqs"          target="_blank" rel="noopener nofollow">FAQs</a></Menu.Item> : null
    const ledMenuItem = <Menu.Item position='right'><a className='led-toggle js--led-toggle' onClick={props.headerActions.showLedChart}>LED Chart</a></Menu.Item>
    const mobileAccountMenuItem = <Menu.Item className='mobile-visible mobile-account-link'><NavLink to='/account' onClick={(mobileMenuToggle)}>Edit Account</NavLink></Menu.Item>
    const mobileContactUsMenuItem = <Menu.Item className='mobile-visible contact-us-link'><a href="https://lobsterpictures.tv/contact"          target="_blank" rel="noopener nofollow" onClick={(mobileMenuToggle)}>Contact Us</a></Menu.Item>
    const mobileLogoutMenuItem = <Menu.Item className='mobile-visible'><NavLink to='/logout' onClick={(mobileMenuToggle)}>Logout</NavLink></Menu.Item>

    if (props.auth.user) {
        return (
            <>
                <SemanticHeader>
                    <Menu borderless size='huge' className='menu--main'>
                        <Container fluid>
                            <Menu.Item header>
                                <Link to="/">
                                    <Image size='mini' src={require('../logo.png')} />
                                </Link>
                                <Menu.Item position='right'>
                                    <Button color='black' onClick={props.headerActions.showLedChart} basic className='led-toggle led-toggle--mobile'>LED Chart</Button>
                                    <div className="menu__mobile-toggle" onClick={() => mobileMenuToggle()}>
                                        <Icon name="bars"></Icon>
                                    </div>
                                </Menu.Item>
                            </Menu.Item>
                            <div className="menuWrap">
                                <Menu.Item>
                                    <NavLink to='/' exact={true} onClick={(mobileMenuToggle)}>Home</NavLink>
                                </Menu.Item>
                                {projectsMenuItem}
                                {camerasMenuItem}
                                {usersMenuItem}
                                {clientsMenuItem}
                                {tagsMenuItem}
                                {videoEditsMenuItem}
                                {subscriptionsMenuItem}
                                {faqMenuItem}
                                {ledMenuItem}
                                {mobileAccountMenuItem}
                                {mobileContactUsMenuItem}
                                {mobileLogoutMenuItem}
                                
                                <Menu.Item
                                    onClick={onClickUser}
                                    link={true}
                                    className='mobile-hidden'
                                >
                                    <span className="tablet-hidden"><Icon name="user" /></span>
                                    {userName.firstName} <span className="tablet-hidden">&nbsp;{userName.lastName}</span>
                                    <span style={{marginLeft: '10px'}}><Icon name="chevron down"/></span>
                                </Menu.Item>
                            </div>
                            {getSettingsOverlay()}
                        </Container>
                    </Menu>
                </SemanticHeader>
                <LedColourChartModal open={props.header.chartOpen} onClose={props.headerActions.hideLedChart} />
            </>
        )
    } else {
        return (
            <SemanticHeader>
                <Menu borderless size='huge' className='menu--main'>
                    <Container fluid={true}>
                        <Menu.Item header>
                            {/* <Link to="/"> */}
                                <Image size='mini' src={require('../logo.png')} style={{ marginRight: '1.5em' }} />
                            {/* </Link> */}
                        </Menu.Item>
                    </Container>
                </Menu>
            </SemanticHeader>
        )
    }
}

Header.propTypes = {
    headerActions: PropTypes.object,
    header: PropTypes.object,
    auth: PropTypes.object
}

function mapStateToProps(state) {
    return {
        header: state.header,
        auth: state.auth,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        headerActions: bindActionCreators(headerActions, dispatch)
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Header))
