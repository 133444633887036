import React, { Component } from 'react'
import { Label } from 'semantic-ui-react'
import PropTypes from 'prop-types'

class CameraTagsFilter extends Component {
    constructor(props) {
        super(props)
        this.sortedTags = [];
    }

    setSortedTags = (tagsToSort) => {
        this.sortedTags = [...tagsToSort.sort((a, b) => {
            const tagA = a.name.toUpperCase(); // ignore case
            const tagB = b.name.toUpperCase();
            return (tagA < tagB) ? -1 : (tagA > tagB) ? 1 : 0;
        })];
    }

    componentDidMount() {
        let tags = this.props.tags.tags
        if (tags) {
            this.setSortedTags(tags)
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.tags.tags !== prevProps.tags.tags) {
            this.setSortedTags(this.props.tags.tags)
        }
    }

    render() {
        if (this.props.tags.tags.length < 1) {
            return null
        }

        return (
           <div>
                <h4>Filter by Tags</h4>
                {this.sortedTags.map((tag) => {
                    // const selectedClass = (this.props.tags.filterBy.indexOf(tag.id) > -1) ? '' : 'basic'
                    const tagColour = tag.color
                    return (
                        <Label
                            as='a'
                            key={tag.id}
                            basic={this.props.tags.filterBy.indexOf(tag.id) === -1}
                            color={tagColour}
                            onClick={() => {this.props.toggleFilterTag(tag.id)}}

                        >
                            {tag.name}
                        </Label>
                    )
                })}
           </div>
        )
    }
}

CameraTagsFilter.propTypes = {
    tags: PropTypes.shape({
        tags: PropTypes.array.isRequired,
        filterBy: PropTypes.array.isRequired
    }),
    toggleFilterTag: PropTypes.func.isRequired
}

export default CameraTagsFilter
