import React, { Component } from 'react'
import PropTypes from 'prop-types'

import DocumentTitle from 'react-document-title'
import { Form, Dropdown, Icon } from 'semantic-ui-react'

import {LOBSTER_POT_4} from '../../config'
import ScheduleDay from './ScheduleDay'
import AddScheduleModal from '../modals/AddSchedule'
import EditScheduleModal from '../modals/EditSchedule'

class Schedule extends Component {

    componentDidMount() {
        this.props.scheduleActions.load(this.props.match.params.cameraId)
    }

    generateScheduleTemplates() {
        if (this.props.schedule.potVersion === LOBSTER_POT_4) {
            return [
                {key: 'standard77so', text: 'Standard: 7am-7pm 15 minute shoot', value: 'standard77so'}
            ]
        }

        return [
            {key: 'standard77', text: 'Standard: 7am-7pm 15 minute shoot, queue and upload', value: 'standard77'},
            {key: 'standard24', text: 'Standard: 24 hours, 15 minute shoot, queue and upload', value: 'standard24'},
            {key: 'indoor1', text: 'Indoor: 5 minute shoot, 10 minute shoot, queue and upload', value: 'indoor1'}
        ]
    }

    generateSchedulerKey() {
        if (this.props.schedule.potVersion === LOBSTER_POT_4) {
            return <div>
                <div className="schedulerKeyItem">
                    <Icon.Group style={{lineHeight: 1.5}}>
                        <Icon
                            name='photo'
                            inverted
                            color='black'
                            circular
                        />
                    </Icon.Group>
                    Shoot
                </div>
                <div className="schedulerKeyItem">
                    <Icon.Group style={{lineHeight: 1.5}}>
                        <Icon
                            name='upload'
                            inverted
                            color='black'
                            circular
                        />
                    </Icon.Group>
                    Upload
                </div>
                <div className="schedulerKeyItem">
                    <Icon.Group style={{lineHeight: 1.5}}>
                        <Icon
                            name='bed'
                            inverted
                            color='black'
                            circular
                        />
                    </Icon.Group>
                    Sleep
                </div>
                {/* <div className="schedulerKeyItem">
                    <i className="inverted circular black icon">5x1</i>
                    Rapid Shoot (5 x 1min)
                </div>
                <div className="schedulerKeyItem">
                    <i className="inverted circular black icon">6x2</i>
                    Rapid Shoot (6 x 2min)
                </div> */}
                <div className="schedulerKeyItem">
                    <Icon.Group style={{lineHeight: 1.5}}>
                        <Icon
                            name='sync'
                            inverted
                            color='black'
                            circular
                        />
                    </Icon.Group>
                    Fan
                </div>
                <div className="schedulerKeyItem">
                    <Icon.Group style={{lineHeight: 1.5}}>
                        <Icon
                            name='fire'
                            inverted
                            color='black'
                            circular
                        />
                    </Icon.Group>
                    Heater
                </div>
            </div>
        }
        else {
            return <div>
                    <div className="schedulerKeyItem">
                        <Icon.Group style={{lineHeight: 1.5}}>
                            <Icon
                                name='power'
                                inverted
                                color='red'
                                circular
                            />
                        </Icon.Group>
                        Halt
                    </div>
                    <div className="schedulerKeyItem">
                        <Icon.Group style={{lineHeight: 1.5}}>
                            <Icon
                                name='photo'
                                inverted
                                color='black'
                                circular
                            />
                        </Icon.Group>
                        Shoot
                    </div>
                    <div className="schedulerKeyItem">
                        <Icon.Group style={{lineHeight: 1.5}}>
                            <Icon circular inverted color='black' name='list ul' />
                            <Icon corner inverted name='photo'
                                style={{
                                    fontSize: '0.9em',
                                    right: '0.2em'
                                }}
                            />
                        </Icon.Group>
                        Shoot and Queue
                    </div>
                    <div className="schedulerKeyItem">
                        <Icon.Group style={{lineHeight: 1.5}}>
                            <Icon
                                name='upload'
                                inverted
                                color='black'
                                circular
                            />
                        </Icon.Group>
                        Upload
                    </div>
                </div>
        }
    }

    render() {

        let addModal
        if (this.props.schedule.addPeriodModalVisible) {
            addModal = (
                <AddScheduleModal
                    onClose={this.props.scheduleActions.dismissAddPeriodModal}
                    onAdd={this.props.scheduleActions.addPeriod}
                    potVersion={this.props.schedule.potVersion}
                />
            )
        }

        let editModal
        if (this.props.schedule.editPeriodModalVisible) {
            const day = this.props.schedule.editPeriodModalDay
            const period = this.props.schedule.editPeriodModalPeriod
            const schedule = this.props.schedule.days[day][period]
            editModal = (
                <EditScheduleModal
                    {...schedule}
                    onClose={this.props.scheduleActions.dismissEditPeriodModal}
                    onUpdate={this.props.scheduleActions.modifyPeriod}
                    onRemove={this.props.scheduleActions.removePeriod}
                    potVersion={this.props.schedule.potVersion}
                />
            )
        }

        const scheduleTemplates = this.generateScheduleTemplates()


        const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday',
            'Thursday', 'Friday', 'Saturday', 'Sunday']

        let title
        if (this.props.schedule.potId) {
            title = `${this.props.schedule.potId} Schedule`
        } else {
            title = 'Schedule'
        }

        const schedulerKey = this.generateSchedulerKey()

        return (
            <div data-test-id="component--schedule">
                <DocumentTitle title={title} />
                <h2>Schedule {this.props.schedule.potId ? `for ${this.props.schedule.potId}` : null}</h2>
                <Form>
                    <Form.Group inline>
                        <Form.Button
                            content='Revert'
                            onClick={
                                () => {
                                    this.props.scheduleActions.load(
                                        this.props.match.params.cameraId
                                    )
                                }
                            }
                        />
                        <Form.Button
                            content='Save'
                            color='orange'
                            onClick={
                                () => {
                                    this.props.scheduleActions.save(
                                        this.props.match.params.cameraId
                                    )
                                }
                            }
                            loading={this.props.schedule.saving}
                        />
                        <Dropdown
                            placeholder='Apply template'
                            selection options={scheduleTemplates}
                            onChange={
                                (event, {value}) => {
                                    this.props.scheduleActions.applyTemplate(value)
                                }
                            }
                        />
                        <div className="schedulerKey">
                            <b>Legend</b>
                            {schedulerKey}
                        </div>
                    </Form.Group>
                </Form>
                {
                    Array(7).fill().map((a, i) => {
                        const day = (i + 1) % 7
                        return (
                            <ScheduleDay
                                key={dayNames[day]}
                                dayName={dayNames[day]}
                                dayId={day}
                                loading={this.props.schedule.loading}
                                actions={this.props.scheduleActions}
                                schedule={this.props.schedule.days[day]}
                            />
                        )
                    })
                }
                <div style={{clear: 'both'}} />
                    {addModal}
                    {editModal}
            </div>
        )
    }
}

Schedule.propTypes = {
    schedule: PropTypes.object.isRequired,
    scheduleActions: PropTypes.object.isRequired
}

export default Schedule
