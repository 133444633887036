import initialState from './initialState';
import {
    LOGO_UPLOAD_LOGO_REQUEST,
    LOGO_UPLOAD_LOGO_RESPONSE_ERRORS,
    LOGO_UPLOAD_LOGO_RESPONSE_SUCCESS,
    LOGO_RESET,
    LOGO_REMOVE_LOGO_REQUEST,
    LOGO_REMOVE_LOGO_RESPONSE_ERRORS,
    LOGO_REMOVE_LOGO_RESPONSE_SUCCESS,
    LOGO_REMOVE_LOGO_RESET
} from '../actions/actionTypes';

export default function logo(state = initialState.logo, action) {

    switch (action.type) {
        case LOGO_UPLOAD_LOGO_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    upload: {
                        logo: null,
                        clientId: action.clients_id,
                        processing: true,
                        success: false,
                        error: false,
                        errors: {},
                        details: {},
                    }
                },
            )
        case LOGO_UPLOAD_LOGO_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    upload: {
                        logo: null,
                        clientId: state.upload.clientId,
                        processing: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: {}
                    }
                }
            )

        case LOGO_UPLOAD_LOGO_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    upload: {
                        logo: action.details.url,
                        clientId: state.upload.clientId,
                        processing: false,
                        success: true,
                        error: false,
                        errors: {},
                        details: action.details
                    }
                },
            )

        case LOGO_RESET:
            return Object.assign(
                {},
                {
                    ...initialState.logo
                }
            )

        case LOGO_REMOVE_LOGO_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    delete: {
                        clientId: action.clients_id,
                        processing: true,
                        success: false,
                        error: false,
                        errors: {}
                    }
                }
            )

        case LOGO_REMOVE_LOGO_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    delete: {
                        clientId: state.delete.clientId,
                        processing: false,
                        success: false,
                        error: true,
                        errors: action.errors
                    }
                }
            )

        case LOGO_REMOVE_LOGO_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    delete: {
                        clientId: state.delete.clientId,
                        processing: false,
                        success: true,
                        error: false,
                        errors: {}
                    }
                }
            )

        case LOGO_REMOVE_LOGO_RESET:
            return Object.assign(
                {},
                state,
                {
                    delete: {
                        ...initialState.logo.delete
                    }
                }

            )

        default:
            return state
    }
}
