import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, Menu, Icon, Input, Checkbox } from 'semantic-ui-react'
import { PAGINATION_MAX_ROWS_PER_PAGE_PERMISSIONS }  from './../config'
import debounce from 'lodash/debounce'

class PermissionListView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            filterBy: ''
        }
    }

    componentDidMount() {
        this.props.rolesActions.fetchPermissions(1, this.state.filterBy)
    }

    onPermissionFilterByChange = debounce((filterTerm) => {
        this.setState({filterBy: filterTerm})
        this.props.rolesActions.fetchPermissions(1, filterTerm)
    }, 500)

    prevPage = (page) => {
        this.props.rolesActions.fetchPermissions(page, this.state.filterBy)
    }

    nextPage = (page) => {
        this.props.rolesActions.fetchPermissions(page, this.state.filterBy)
    }

    jumpToPage = (page) => {
        this.props.rolesActions.fetchPermissions(page, this.state.filterBy)
    }

    getPermissions = () => {
        return (this.props.roles.permissions.details
            && this.props.roles.permissions.details.permissions) ? this.props.roles.permissions.details.permissions : []

    }

    getNumberOfPages = (numberPerPage) => {
        if (this.props.roles.permissions.details && this.props.roles.permissions.details.totalItems) {
            if (this.props.roles.permissions.details.totalItems > 0) {
                return Math.ceil(this.props.roles.permissions.details.totalItems / numberPerPage)
            } else {
                return 1;
            }
        }
        return 1;
    }

    getPrevPageNumberOrNull = () => {
        return (this.props.roles.permissions.page > 1) ?
            this.props.roles.permissions.page - 1: null
    }

    getNextPageNumberOrNull = (numberOfPages) => {
        return (this.props.roles.permissions.page < numberOfPages) ?
            this.props.roles.permissions.page + 1: null

    }

    getCurrPageNumber = () => {
        return this.props.roles.permissions.page
    }

    render = () => {

        const permissions = this.getPermissions()
        const numberPerPage = PAGINATION_MAX_ROWS_PER_PAGE_PERMISSIONS
        const numberOfPages = this.getNumberOfPages(numberPerPage)
        const currPage = this.getCurrPageNumber()
        const prevPage = this.getPrevPageNumberOrNull()
        const nextPage = this.getNextPageNumberOrNull(numberOfPages)

        return (
            <div className='permission-list-view'>

                <p>&nbsp;</p>

                <Input  id="permissionFilterByField" onChange={(event, {value}) => {
                    this.onPermissionFilterByChange(value)
                }}  />

                <Table size='small' striped={true} fixed={true} selectable compact textAlign='left'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Description</Table.HeaderCell>
                            <Table.HeaderCell>Category</Table.HeaderCell>
                            <Table.HeaderCell>Scope</Table.HeaderCell>
                            <Table.HeaderCell />
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>

                        {
                            permissions.map((item, idx) => {

                                return (
                                    <Table.Row key={idx}>
                                        <Table.Cell>{item.name}</Table.Cell>
                                        <Table.Cell>{item.description}</Table.Cell>
                                        <Table.Cell>{item.category}</Table.Cell>
                                        <Table.Cell>{item.scope}</Table.Cell>
                                        <Table.Cell textAlign='center'><Checkbox

                                            onChange={(event, {checked}) => {
                                                this.props.onPermissionChange(item.id, checked)
                                            }}

                                            defaultChecked={this.props.checkedPermissions.findIndex((elem) => {
                                            return elem === item.id
                                        }) > -1} /></Table.Cell>
                                    </Table.Row>
                                )

                            })

                        }

                    </Table.Body>


                    {(numberOfPages > 1) ?
                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan='5'>
                                    <Menu floated='right' pagination>
                                        {
                                            (prevPage) ? <Menu.Item as='a' icon onClick={() => {
                                                this.prevPage(prevPage)
                                            }}>
                                                <Icon name='left chevron'/>
                                            </Menu.Item> : null
                                        }
                                        {

                                            Array(numberOfPages).fill(null).map((u, i) => {
                                                return (<Menu.Item key={i} as='a' active={currPage === (i + 1)}
                                                                   onClick={() => {
                                                                       const page = i + 1
                                                                       this.jumpToPage(page)
                                                                   }}>{i + 1}</Menu.Item>)
                                            })
                                        }
                                        {
                                            (nextPage) ?

                                                <Menu.Item as='a' icon onClick={() => {
                                                    this.nextPage(nextPage)
                                                }}>
                                                    <Icon name='right chevron'/>
                                                </Menu.Item> : null
                                        }
                                    </Menu>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer> : null
                    }


                </Table>

            </div>
        )
    }
}

PermissionListView.propTypes = {
    rolesActions: PropTypes.object.isRequired,
    roles: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    checkedPermissions: PropTypes.array.isRequired,
    onPermissionChange: PropTypes.func.isRequired
}

export default PermissionListView
