import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
    Icon,
    Menu,
    Message,
    Image,
    Button,
    Grid,
    Container,
    Label
} from 'semantic-ui-react'
import moment from 'moment';
import pagination from '../util/pagination'
import { isPermitted } from "../util/acl"
import { VIEWER_URL }  from '../config'

const ProjectGrid = (props) => {

    let isCompletedProjects = false;
    let rowsPerPage = props.rowsPerPage || 12
    let currentPage = props.currentPage || 1
    let placeholder = require('../assets/placeholder.png')

    /**
     * Jump to a particular page in the results set
     *
     * @param {string} name - The page number label ("1", "2", etc)
     */
    const handlePaginationPageClick = (e, { name }) => {
        const page = parseInt(name, 10)
        props.projectsActions.projectListRequest(
            rowsPerPage,
            page,
            props.projects.sortColumn,
            props.projects.sortAscending,
            props.projects.searchTerm,
            props.clientId
        )
    }

    /** Jump to the previous page in the results set */
    const handlePaginationPrevClick = (e) => {
        if (props.projects.currentPage > 1 ) {
            const prevPage = props.projects.currentPage - 1
            props.projectsActions.projectListRequest(
                rowsPerPage,
                prevPage,
                props.projects.sortColumn,
                props.projects.sortAscending,
                props.projects.searchTerm,
                props.clientId
            )
        }
    }

    /** Jump to the next page in the results set */
    const handlePaginationNextClick = (e) => {
        const totalPages = Math.ceil(props.projects.totalRows/rowsPerPage)
        if (props.projects.currentPage < totalPages) {
            const nextPage = props.projects.currentPage + 1
            props.projectsActions.projectListRequest(
                rowsPerPage,
                nextPage,
                props.projects.sortColumn,
                props.projects.sortAscending,
                props.projects.searchTerm,
                props.clientId
            )
        }
    }

    const toggleOverlay = (e) => {
        e.preventDefault()
        const parent = e.target.parentNode.parentNode.parentNode;
        const overlay = parent.querySelector('.grid-item__image-overlay')
        const allOverlays = document.querySelectorAll('.grid-item__image-overlay')
        for (const item of allOverlays) {
            item.classList.remove('active');
        }
        overlay.classList.add('active')
    }

    /**
     * Render a single table row of results
     *
     * @param {Object} row - An object containing one row of results
     * @param {number} index - The current row number being rendered
     */
    const renderBodyRow = (status) => {
        let projects = (props.projects) ? props.projects : null

        if (projects) {
            for (const key in projects) {
                if (projects.hasOwnProperty(key)) {
                    if (key === 'tableData') {
                        const element = projects[key];
                        let output = element.map((dataRow, index) => {
                            let permitted = isPermitted(props.auth.user, 'put-projects-projects');
                            let statusFilter = null;

                            const editProjectLink = (permitted) ?
                                <Button
                                    as={Link}
                                    to={`/clients/${dataRow.clients_id}/projects/${dataRow.id}/edit`}
                                    icon='cogs'
                                    basic
                                    color='blue'
                                    compact
                                    size='small'
                                    content='Edit'
                                /> : null

                            const viewAccessLink = (
                                isPermitted(props.auth.user, 'get-project-getlistprojectaccess')
                            ) ?
                                <Button
                                    as={Link}
                                    to={`/clients/${dataRow.clients_id}/projects/${dataRow.id}/access`}
                                    icon='unordered list'
                                    basic
                                    color='blue'
                                    size='small'
                                    compact
                                    content='View Users'
                                />
                            : null

                            let imageUrl = (dataRow.logo) ? dataRow.logo : placeholder

                            if (status === 'active') {
                                statusFilter = ['Active', 'New']
                            } else  if (status === 'completed') {
                                statusFilter = ['Completed', 'Archived']
                            }

                            if (statusFilter.includes(dataRow.status)) {
                                if (status === 'completed') {
                                    isCompletedProjects = true
                                }

                                return (
                                    <Grid.Column key={`project-${dataRow.id}-${index}`}>
                                        <div className="card--projects-grid">
                                            <div className="project__header">
                                                <div className="project__header--left">
                                                    <p className="project__title">{dataRow.name}</p>
                                                    <p className='project__location'>{dataRow.location}</p>
                                                </div>
                                                <div className="project__header--right">
                                                <Label>{dataRow.status}</Label>
                                                </div>
                                            </div>

                                            <hr />
                                            <div onClick={(e) => toggleOverlay(e)}>
                                                <div className="grid-item__image-wrap">
                                                    <Image
                                                    className={'test'}
                                                        src={imageUrl}
                                                    />
                                                    <div className="grid-item__image-overlay">
                                                    <a href={`${VIEWER_URL}/loginvia?token=${localStorage.getItem('accessToken')}&expiry=${localStorage.getItem('accessTokenExpiry')}&refresh=${localStorage.getItem('refreshToken')}`}
                                                    onClick={(e) => {
                                                        if (e.stopPropagation) {
                                                            e.stopPropagation();
                                                        } else {
                                                            e.cancelBubble = true;
                                                        }
                                                    }}
                                                    style={{cursor: 'pointer'}}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    >Launch <br />Viewer</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            {dataRow.start && dataRow.finish && <>
                                                <div className="project__dates">
                                                    <p className="project__dates__title"><Icon
                                                        name="calendar"
                                                    /> <strong>Project Dates</strong></p>
                                                    <div className="project__date-wrap">
                                                        <p style={{marginRight: '10px'}}>{moment(dataRow.start).isValid() ? moment(dataRow.start).format("MMM YYYY") : null}</p>
                                                        -
                                                        <p style={{marginLeft: '10px'}}>{moment(dataRow.finish).isValid() ? moment(dataRow.finish).format("MMM YYYY") : null}</p>
                                                    </div>
                                                </div>
                                                <hr />
                                            </>}

                                            <div className="project__links">
                                                {editProjectLink}
                                                {viewAccessLink}
                                            </div>
                                        </div>
                                    </Grid.Column>
                                )
                            }
                            return true
                        })
                        return output
                    }
                }
            }
        } else {
            return <h2>No Projects found</h2>
        }
    }

    /** Render the table footer row */
    const renderFooter = () => {
        if (props.projects.totalRows === 0) {
            return (
                <>
                    {props.projects.errors.error !== true ?
                        "No results found" : (
                            <Message
                                error={true}
                                visible={props.projects.errors.error}
                                header="Error"
                                content={props.projects.errors.message}
                            />
                        )

                    }
                </>
            )
        }
        const totalPages = Math.ceil(props.projects.totalRows/rowsPerPage)
        const first = ((props.projects.currentPage - 1) * rowsPerPage) + 1
        let last = props.projects.currentPage * rowsPerPage
        if (last > props.projects.totalRows) last = props.projects.totalRows
        let pages = pagination(props.projects.currentPage, totalPages)
        let menuPagination = (
            <Menu pagination>
                <Menu.Item name="prev" onClick={handlePaginationPrevClick} icon="angle left" />
                {pages.map((page, key) => {
                    return (
                        <Menu.Item name={page.toString()} key={key} active={props.projects.currentPage === page} onClick={handlePaginationPageClick} />
                    )
                })}
                <Menu.Item name="next" onClick={handlePaginationNextClick} icon="angle right" />
            </Menu>
        )
        return (
            <>
                <div>Showing {first} - {last} of {props.projects.totalRows} {props.projects.totalRows === 1 ? "result" : "results"}</div>
                <div style={{textAlign: 'center'}}>
                    {totalPages > 1 ? menuPagination : null}
                </div>
            </>
        )
    }

    const projectTitleHeight = () => {
        const projects = document.querySelectorAll('.card--projects-grid')
        let projectTitleHeight = 0;
        projects.forEach(project => {
            let titleHeight = project.querySelector('.project__header').clientHeight;
            if (titleHeight > projectTitleHeight) {
                projectTitleHeight = titleHeight
            }
        });
        if (projectTitleHeight > 0) {
            document.querySelectorAll('.card--projects-grid .project__header').forEach(item => {
                item.style.height = `${projectTitleHeight}px`
            })
        }
    }


    const activeProjectGrid = renderBodyRow('active');
    const completedProjectGrid = renderBodyRow('completed');
    projectTitleHeight();

    return (
        <Container className="content-container" fluid={true} style={{padding: '0 1.2em'}}>
            <Grid className="styled-grid styled-grid--projects">
                {activeProjectGrid}
            </Grid>
            {isCompletedProjects && <>
                <h2>Completed Projects</h2><br />
                <Grid className="styled-grid styled-grid--projects">
                    {completedProjectGrid}
                </Grid>
            </>}
                {/* {renderFooter()} */}
        </Container>
    )
}

ProjectGrid.propTypes = {
    clientId: PropTypes.number,
    projects: PropTypes.shape({
        currentPage: PropTypes.number,
        errors: PropTypes.object,
        filtered: PropTypes.bool,
        loading: PropTypes.bool,
        sortAscending: PropTypes.bool,
        sortColumn: PropTypes.string,
        tableData: PropTypes.array.isRequired,
        totalRows: PropTypes.number
    }).isRequired,
    projectsActions: PropTypes.object,
    addProjectActions: PropTypes.object,
    updateProjectActions: PropTypes.object
}

export default ProjectGrid
