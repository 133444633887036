import initialState from './initialState';
import {
    UPDATE_PROJECT_REQUEST,
    UPDATE_PROJECT_RESPONSE_ERRORS,
    UPDATE_PROJECT_RESPONSE_SUCCESS,
    UPDATE_PROJECT_RESET_ERRORS,
    PROJECT_DETAILS_REQUEST,
    PROJECT_DETAILS_RESPONSE_ERRORS,
    PROJECT_DETAILS_RESPONSE_SUCCESS,
    DELETE_PROJECT_REQUEST,
    DELETE_PROJECT_RESPONSE_ERRORS,
    DELETE_PROJECT_RESPONSE_SUCCESS,
    DELETE_PROJECT_RESET_ERRORS,
    UPDATE_PROJECT_PROJECT_CAMERAS_AVAILABLE_CAMERAS,
    UPDATE_PROJECT_PROJECT_CAMERAS_PROJECT_CAMERAS,
    UPDATE_PROJECT_PROJECT_CAMERAS_REMOVE_CAMERAS,
    UPDATE_PROJECT_PROJECT_CAMERAS_ADD_CAMERAS,
    UPDATE_PROJECT_PROJECT_CAMERAS_CAMERA_WEIGHTS
} from '../actions/actionTypes';

export default function updateProject(state = initialState.updateProject, action) {

    switch (action.type) {
        case PROJECT_DETAILS_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    loading: true,
                    processing: false,
                    errors: {},
                    error: false,
                    success: false
                },
                {
                    details: {
                        ...action.details,
                        remove_cameras: state.details.remove_cameras,
                        add_cameras: state.details.add_cameras,
                        default_weights: state.details.default_weights,
                    }
                }
            )
        case PROJECT_DETAILS_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    processing: false,
                    errors: {},
                    error: false,
                    success: false
                },
                {
                    details: {
                        ...action.details,
                        remove_cameras: state.details.remove_cameras,
                        add_cameras: state.details.add_cameras,
                        default_weights: state.details.default_weights,
                    }
                }
            )
        case PROJECT_DETAILS_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    processing: false,
                    errors: action.errors,
                    error: true,
                    success: false
                }
            )
        case UPDATE_PROJECT_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    processing: true,
                    errors: {},
                    error: false,
                    success: false
                },
                {
                    details: {
                        ...action.details
                    }
                }
            )
        case UPDATE_PROJECT_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    processing: false,
                    errors: action.errors,
                    error: true,
                    success: false
                }
            )
        case UPDATE_PROJECT_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    processing: false,
                    errors: {},
                    error: false,
                    success: true,
                },
                {
                    details: {
                        ...action.details,
                        add_cameras: [],
                        remove_cameras: [],
                        default_weights: []
                    }
                }
            )
        case UPDATE_PROJECT_RESET_ERRORS:
            return Object.assign(
                {},
                state,
                initialState.updateProject
            )
        case DELETE_PROJECT_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    delete:
                        {
                            loading: true,
                            errors: {},
                            projects_id: action.projects_id,
                            success: false,
                            error: false
                        }
                }
            )
        case DELETE_PROJECT_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    delete:
                        {
                            loading: false,
                            errors: action.errors,
                            projects_id: action.projects_id,
                            success: false,
                            error: true
                        }
                }
            )
        case DELETE_PROJECT_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    delete:
                        {
                            loading: false,
                            errors: {},
                            projects_id : action.projects_id,
                            success: true,
                            error: false
                        }
                }
            )
        case DELETE_PROJECT_RESET_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    delete:
                        {
                            loading: false,
                            errors: {},
                            projects_id: false,
                            success: false,
                            error: false
                        }
                }
            )

        case UPDATE_PROJECT_PROJECT_CAMERAS_AVAILABLE_CAMERAS:
            return Object.assign(
                {},
                state,
                {
                    project_cameras: {
                        ...state.project_cameras,
                        available_cameras: action.cameras
                    }
                }
            )
        case UPDATE_PROJECT_PROJECT_CAMERAS_PROJECT_CAMERAS:
            return Object.assign(
                {},
                state,
                {
                    project_cameras: {
                        ...state.project_cameras,
                        project_cameras: action.cameras
                    }
                }
            )
        case UPDATE_PROJECT_PROJECT_CAMERAS_REMOVE_CAMERAS:
            const removeCameras = action.cameras.map((camera) => {
                return camera.id
            })
            return Object.assign(
                {},
                state,
                {
                    project_cameras: {
                        ...state.project_cameras,
                        remove_cameras: action.cameras
                    },
                    details: {
                        ...state.details,
                        remove_cameras: removeCameras
                    }
                }
            )
        case UPDATE_PROJECT_PROJECT_CAMERAS_ADD_CAMERAS:
            const addCameras = action.cameras.map((camera) => {
                return camera.id
            })
            return Object.assign(
                {},
                state,
                {
                    project_cameras: {
                        ...state.project_cameras,
                        add_cameras: action.cameras
                    },
                    details: {
                        ...state.details,
                        add_cameras: addCameras

                    }
                }
            )
        case UPDATE_PROJECT_PROJECT_CAMERAS_CAMERA_WEIGHTS:
            const cameraWeights = action.cameras.map((camera) => {
                return {
                    cameras_id: camera.id,
                    default_weight: camera.default_weight
                }
            })

            return Object.assign(
                {},
                state,
                {
                    project_cameras: {
                        ...state.project_cameras,
                        camera_weights: action.cameras
                    },
                    details: {
                        ...state.details,
                        default_weights: cameraWeights
                    }
                }
            )

        default:
            return state
    }
}
