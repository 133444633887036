import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './components/App'
import { Provider } from 'react-redux'
import "semantic-ui-css/semantic.css"
import './admin.css'
import configureStore from './store/configureStore'
import './errorHandler'

const container = document.getElementById('root');
const root = createRoot(container);
const store = configureStore();

root.render(<Provider store={store}><App /></Provider>);
