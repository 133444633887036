import React, { Component } from 'react'
import {
    Button,
    Modal,
    Message,
    Form,
    Select
} from 'semantic-ui-react'
import { getErrorMessage } from './../../util/errors'
import PropTypes from 'prop-types'

class UpdateTagModal extends Component {

    constructor(props) {
        super(props)
        this.state = this.getInitialState()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.success === true && prevProps.success === false) {
            this.onClose()
        } else {
            if (this.props !== prevProps) {
                this.setState( (prevState, props) => {
                    return {
                        name: this.props.tag.name,
                        description: this.props.tag.description,
                        status: this.props.tag.status,
                        color: this.props.tag.color
                    }
                })
            }
        }
    }

    getHeader = (error, success) => {
        if (error) {
            return <h3 id='successStatus'>Error</h3>
        } else if (success) {
            return <h3 id='successStatus'>Success!</h3>
        } else {
            return ""
        }
    }

    getContent = (error, success, loading, errors) => {
        if (error) {
            return null;
        } else if (success) {
            return <p>Successfully updated tag</p>
        } else if (loading) {
            return <p>Processing...</p>
        } else {
            return null;
        }
    }

    getInitialState = () => {
        return {
            name: null,
            description: null,
            status: null,
            color: null
        }
    }

    onClose = () => {
        this.setState(this.getInitialState(), () => {
            this.props.onClose()
        })
    }

    onClick = () => {
        this.props.onClick(this.props.tag.id, this.state)
    }

    onNameChange = (event, {value}) => {
        this.setState({name: value})
    }

    onDescriptionChange = (event, {value}) => {
        this.setState({description: value})
    }

    onStatusChange = (event, {value}) => {
        this.setState({status: value})
    }

    onColorChange = (event, {value}) => {
        this.setState({color: value})
    }

    getPreloader = () => {
        if (!this.props.tag.id) {
            return (
                <div>
                    <div className='animated-blank-line'></div>
                    <div className='animated-blank-block-75'></div>
                    <div className='animated-blank-line'></div>
                </div>
            )
        }
        return null
    }

    getForm = () => {
        if (this.props.tag.id && !this.props.success) {
            return (
                <Form size='large'>
                    <Form.Field>
                        <label>Name</label>
                        <Form.Input
                            id='name'
                            fluid
                            placeholder='Name'
                            onChange={this.onNameChange}
                            error={false}
                            defaultValue={this.props.tag.name}
                        />
                    </Form.Field>
                    <Message
                        error={true}
                        visible={this.props.errors.name !== undefined}
                        header={''}
                        content={getErrorMessage(this.props.errors, 'name')}
                    />
                    <Form.Field>
                        <label>Description</label>
                        <Form.TextArea
                            id='description'

                            placeholder='Description'
                            onChange={this.onDescriptionChange}
                            error={false}
                            defaultValue={this.props.tag.description}
                        />
                    </Form.Field>
                    <Message
                        error={true}
                        visible={this.props.errors.description !== undefined}
                        header={this.props.errors.description}
                        content={getErrorMessage(this.props.errors, 'description')}
                    />

                    <Form.Field
                        label={'Status'}
                        id='tagStatus'
                        control={Select}
                        options={[{text: 'Active', value: 'Active'},{text: 'Inactive', value: 'Inactive'}]}
                        placeholder='Status'
                        onChange={this.onStatusChange}
                        error={this.props.errors.status}
                        defaultValue={this.props.tag.status}
                        // value={this.state.status}
                    />
                    <Message
                        error={true}
                        visible={this.props.errors.status}
                        header={this.props.errors.status}
                        content={getErrorMessage(this.props.errors, 'status')}
                    />


                    <Form.Field
                        label={'Colour'}
                        id='tagColor'
                        control={Select}
                        options={[
                            {text: 'Red', value: 'red'},
                            {text: 'Orange', value: 'orange'},
                            {text: 'Yellow', value: 'yellow'},
                            {text: 'Olive', value: 'olive'},
                            {text: 'Green', value: 'green'},
                            {text: 'Teal', value: 'teal'},
                            {text: 'Blue', value: 'blue'},
                            {text: 'Violet', value: 'violet'},
                            {text: 'Purple', value: 'purple'},
                            {text: 'Pink', value: 'pink'},
                            {text: 'Brown', value: 'brown'},
                            {text: 'Grey', value: 'grey'},
                            {text: 'Black', value: 'black'}
                        ]}
                        placeholder='Colour'
                        onChange={this.onColorChange}
                        error={this.props.errors.color}
                        defaultValue={this.props.tag.color}
                        // value={this.state.color}
                    />
                    <Message
                        error={true}
                        visible={this.props.errors.color}
                        header={this.props.errors.color}
                        content={getErrorMessage(this.props.errors, 'color')}
                    />


                </Form>
            )
        }
        return null
    }

    getMessage = () => {
        const message = (this.props.success || this.props.error) ?
            <Message
                error={this.props.error}
                success={this.props.success}
                visible={false}
                header={this.getHeader(this.props.error, this.props.success)}
                content={this.getContent(this.props.error, this.props.success, this.props.loading, this.props.errors)}
            /> : null

        return message
    }

    getCreateButton = () => {
        return (!this.props.success) ?
            <Button
                loading={this.props.loading}
                id='updateTagButton'
                onClick={this.onClick}
                positive>Update</Button> : null
    }

    getCloseOrCancelButton = () => {
        return (this.props.success) ?
            <Button onClick={this.onClose} primary>Close</Button>
            : <Button onClick={this.onClose} negative>Cancel</Button>
    }

    render = () => {
        return (
            <Modal size={"small"} open={this.props.open} onClose={this.onClose} closeIcon>
                <Modal.Header>Update tag</Modal.Header>
                <Modal.Content>
                    {this.getMessage()}
                    {this.getPreloader()}
                    {this.getForm()}
                    <div className='modalButtons'>
                        {this.getCreateButton()}
                    </div>
                </Modal.Content>
            </Modal>
        )
    }

}

UpdateTagModal.propTypes = {
    open: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    tag: PropTypes.object.isRequired,
    tags_id: PropTypes.number,
    onClick: PropTypes.func.isRequired
}

export default UpdateTagModal
