import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid } from 'semantic-ui-react'
import { KBtoGB } from "./../util/converter"
import { PieChart, Pie, Cell, Legend } from 'recharts';

class Drop extends Component {

    isReady = () => {
        return this.props.camera_control.system.details
            && 'drop_size' in this.props.camera_control.system.details
            && 'drop_free' in this.props.camera_control.system.details
    }

    render = () => {

        if (!this.isReady()) {

            return (
                <div className='drop'>
                    {/*<Dimmer active inverted><Loader content='' /></Dimmer>*/}
                    <Grid columns='1'>
                        <Grid.Row>
                            <Grid.Column>
                                <Grid.Column><div className="animated-blank-circle" /></Grid.Column>
                                <Grid.Column><div className="animated-blank-line" /></Grid.Column>
                                <Grid.Column><div className="animated-blank-line" /></Grid.Column>
                                <Grid.Column><div className="animated-blank-line" /></Grid.Column>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            )

        } else {

            const dropSize = this.props.camera_control.system.details.drop_size
            const dropFree = this.props.camera_control.system.details.drop_free
            const dropUsed = (dropSize && dropFree) ? dropSize - dropFree : null
            const data = [
                {
                    name: `Used (${KBtoGB(dropUsed)} GB)`,
                    value: parseInt(dropUsed, 10)
                },{
                    name: `Free (${KBtoGB(dropFree)} GB)`,
                    value: parseInt(dropFree, 10)
                }
            ]
            const COLORS = ['#DB2828','#21BA45']
            const dropPieChart = (dropSize && dropFree) ? <PieChart width={200} height={220}><Pie
                data={data}
                cx={100}
                cy={80}
                outerRadius={70}
                fill="#8884d8"
                paddingAngle={0}
                propKey='name'
                dataKey='value'

            >
                {
                    data.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]}/>)
                }
            </Pie>
                <Legend layout='vertical' verticalAlign="bottom" align="center"/>
            </PieChart>: null

            const dropTotal = (dropSize && dropFree) ? <span>Total: <span className='inside-span'>{KBtoGB(dropSize)} GB</span></span> : null

            return (
                <div className='drop-pie-chart-container'>
                    {dropPieChart}
                    {dropTotal}
                </div>
            )

        }
    }
}

Drop.propTypes = {
    cameraControlActions: PropTypes.object.isRequired,
    cameras_id: PropTypes.string.isRequired,
    camera_control: PropTypes.object.isRequired
}

export default Drop
