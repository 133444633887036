import initialState from './initialState';
import {
    BULK_USERS_VALIDATE_EMAIL_REQUEST,
    BULK_USERS_VALIDATE_EMAIL_RESPONSE_ERRORS,
    BULK_USERS_VALIDATE_EMAIL_RESPONSE_SUCCESS,
    BULK_USERS_VALIDATE_EMAIL_RESET,
    BULK_USERS_ADD_VALIDATED_EMAIL_TO_LIST,
    BULK_USERS_RESET,
    BULK_USERS_PROCESS_QUEUE_STARTED,
    BULK_USERS_PROCESS_QUEUE_UPDATED,
    BULK_USERS_PROCESS_QUEUE_COMPLETED,
    BULK_USERS_REMOVE_EMAIL_FROM_LIST,
    BULK_USERS_UPDATE_TEMPLATE,

    BULK_USERS_ADD_USER_CAMERAS,
    BULK_USERS_ADD_USER_PROJECTS,
    BULK_USERS_UPDATE_PROJECT_CAMERA_WEIGHTS,
    BULK_USERS_RESET_ADD_USER_CAMERAS,
    BULK_USERS_RESET_ADD_USER_PROJECTS,
    BULK_USERS_RESET_UPDATE_PROJECT_CAMERA_WEIGHTS

} from '../actions/actionTypes';

export default function bulkUsers(state = initialState.bulkUsers, action) {

    switch (action.type) {
        case BULK_USERS_UPDATE_TEMPLATE:
            return Object.assign(
                {},
                state,
                {
                    template: action.template
                }
            )
        case BULK_USERS_REMOVE_EMAIL_FROM_LIST:
            return Object.assign(
                {},
                state,
                {
                    emails: state.emails.filter((row) => {
                        if (row.email === action.email) {
                            return false
                        } else {
                            return true
                        }
                    })
                }
            )
        case BULK_USERS_PROCESS_QUEUE_UPDATED:

            // Persisting users created as we now need the IDs for associating cameras and projects
            const users = state.users.slice()
            if (action.user) {
                const index = users.findIndex((elem) => {
                    return action.user.email === elem.username
                })

                if (index === -1) {
                    users.push(action.user)
                }
            }

            return Object.assign(
                {},
                state,
                {
                    emails: state.emails.map((row) => {
                        if (row.email === action.email) {
                            return {
                                email: row.email,
                                status: action.status,
                                progress: action.progress,
                                errors: action.errors,
                                success: action.success,
                                warning: action.warning,
                                error: action.error
                            }
                        } else {
                            return row
                        }
                    }),
                    users: users
                }
            )
        case BULK_USERS_PROCESS_QUEUE_STARTED:
            return Object.assign(
                {},
                state,
                {
                    processing: true
                }
            )
        case BULK_USERS_PROCESS_QUEUE_COMPLETED:
            return Object.assign(
                {},
                state,
                {
                    processing: false,
                    completed: true,
                    ucpa_completed: true,
                }
            )
        case BULK_USERS_RESET:
            return Object.assign(
                {},
                initialState.bulkUsers
            )
        case BULK_USERS_VALIDATE_EMAIL_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    validateEmailAsUsername: {
                        ...state.validateEmailAsUsername,
                        loading: true
                    },
                    errorEmails: initialState.bulkUsers.errorEmails
                }
            )
        case BULK_USERS_VALIDATE_EMAIL_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    validateEmailAsUsername: {
                        ...state.validateEmailAsUsername,
                        loading: false,
                        success: true,
                        error: false,
                        email: [
                            ...state.validateEmailAsUsername.email,
                            action.email
                        ],
                        errors: {}
                    }
                }
            )
        case BULK_USERS_VALIDATE_EMAIL_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    validateEmailAsUsername: {
                        ...state.validateEmailAsUsername,
                        loading: false,
                        success: false,
                        error: true,
                        email: action.email,
                        errors: action.errors
                    },

                    errorEmails: [
                        ...state.errorEmails,
                        {
                            email: action.email,
                            error: action.errors
                        }
                    ]
                }
            )
        case BULK_USERS_VALIDATE_EMAIL_RESET:
            return Object.assign(
                {},
                state,
                {
                    validateEmailAsUsername: {
                        ...initialState.bulkUsers.validateEmailAsUsername
                    }
                }
            )
        case BULK_USERS_ADD_VALIDATED_EMAIL_TO_LIST:
            return Object.assign(
                {},
                state,
                {
                    emails: [
                        ...state.emails,
                        {
                            email: action.email,
                            status: 'Pending',
                            progress: 0
                        }
                    ]
                },
                {
                    validateEmailAsUsername: {
                        ...initialState.bulkUsers.validateEmailAsUsername
                    }
                }
            )

        case BULK_USERS_ADD_USER_CAMERAS:
            return Object.assign(
                {},
                state,
                {
                    add_user_cameras: action.cameras
                }
            )

        case BULK_USERS_ADD_USER_PROJECTS:
            return Object.assign(
                {},
                state,
                {
                    add_user_projects: action.projects
                }
            )

        case BULK_USERS_UPDATE_PROJECT_CAMERA_WEIGHTS:
            return Object.assign(
                {},
                state,
                {
                    project_camera_weights: action.project_camera_weights
                }
            )

        case BULK_USERS_RESET_ADD_USER_CAMERAS:
            return Object.assign(
                {},
                state,
                {
                    add_user_cameras: initialState.bulkUsers.add_user_cameras
                }
            )

        case BULK_USERS_RESET_ADD_USER_PROJECTS:
            return Object.assign(
                {},
                state,
                {
                    add_user_projects: initialState.bulkUsers.add_user_projects
                }
            )

        case BULK_USERS_RESET_UPDATE_PROJECT_CAMERA_WEIGHTS:
            return Object.assign(
                {},
                state,
                {
                    project_camera_weights: initialState.bulkUsers.project_camera_weights
                }
            )

        default:
            return state
    }
}
