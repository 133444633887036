import React, { Component } from 'react'
import { Table, Icon } from 'semantic-ui-react'
import moment from 'moment';
import { API_BASE_URL}  from './../config'
import uniqid from 'uniqid'

export default class LogViewer extends Component {
    /**
     *
     * This method will help generate any links
     * @param item
     * @returns {*}
     */
    generateContent = (item, idx) => {

        const negative = (item.level === 'Error' || item.level === 'Warning')
        const positive = (!negative && item.level === 'Success')
        switch (item.action) {

            case 'UpdateSystemProperty':
                const loggingLevel = (item.extras.logging_level) ? <span>Logging Level set to <span className='inside-span'>"{item.extras.logging_level.toUpperCase()}"</span></span> : null
                const rotation = ('rotation' in item.extras) ? <span>Rotation set to <span className='inside-span'>"{item.extras.rotation}°"</span></span> : null
                const timeZone = (item.extras.time_zone) ? <span>Time Zone set to <span className='inside-span'>"{item.extras.time_zone}"</span></span> : null
                return (
                    <Table.Row key={idx} positive={positive} negative={negative}>
                        <Table.Cell width={13}>
                            <span>Setting <span className='inside-span'>OK</span></span>
                            {loggingLevel}
                            {rotation}
                            {timeZone}
                        </Table.Cell>
                        <Table.Cell width={3}>{this.getWhen(item.timestamp)}</Table.Cell>
                    </Table.Row>
                )
            case 'UpdateProperty':
                const imageFormat = (item.extras.image_format) ? <span>Image Format set to <span className='inside-span'>"{item.extras.image_format}"</span></span> : null
                const aperture = (item.extras.aperture) ? <span>Aperture set to <span className='inside-span'>"{item.extras.aperture}"</span></span> : null
                const iso = (item.extras.iso) ? <span>ISO Speed set to <span className='inside-span'>"{item.extras.iso}"</span></span> : null
                const meteringMode = (item.extras.metering_mode) ? <span>Metering Mode set to <span className='inside-span'>"{item.extras.metering_mode}"</span></span> : null
                const whiteBalance = (item.extras.white_balance) ? <span>White Balance set to <span className='inside-span'>"{item.extras.white_balance}"</span></span> : null
                const exposureCompensation = (item.extras.exposure_compensation) ? <span>Exposure Compensation set to <span className='inside-span'>"{item.extras.exposure_compensation}"</span></span> : null
                return (
                    <Table.Row key={idx} positive={positive} negative={negative}>
                        <Table.Cell width={13}>
                            <span>Setting <span className='inside-span'>OK</span></span>
                            {aperture}
                            {exposureCompensation}
                            {iso}
                            {imageFormat}
                            {meteringMode}
                            {whiteBalance}
                        </Table.Cell>
                        <Table.Cell width={3}>{this.getWhen(item.timestamp)}</Table.Cell>
                    </Table.Row>
                )
            case 'Shoot':
                return (
                    <Table.Row key={idx} positive={positive} negative={negative}>
                        <Table.Cell width={13}>
                            <span>Shoot   <span className='inside-span'>OK</span></span><br />
                            <span>Shot Taken: <span className='inside-span'>{item.extras.last_preview_shot}</span></span><br />
                            <span>Filename:  <span className='inside-span'>{item.extras.filename}</span></span>
                            <span>Focal Length: <span className='inside-span'>{item.extras.focal_length}</span></span>
                            <span>Aperture: <span className='inside-span'>{item.extras.aperture}</span></span>
                            <span>Exposure Comp: <span className='inside-span'>{item.extras.exposure_compensation}</span></span>
                            <span>Shutter Speed: <span className='inside-span'>{item.extras.shutter_speed}</span></span>
                            <span>ISO Speed: <span className='inside-span'>{item.extras.iso_speed}</span></span>
                            <a target='_blank' rel='noopener noreferrer' href={`${API_BASE_URL}/api/cameras/${item.extras.cameras_id}/camera-control/image/${item.extras.filename}?access_token=${this.props.accessToken}`}><Icon name='image' size='large' /> View</a>
                            <label
                                htmlFor={`upload_${item.extras.filename}`}
                                style={{
                                    float: 'right',
                                    color: 'orange',
                                    marginRight: '1em',
                                    cursor: 'pointer'
                                }}
                                onClick={() => {this.props.uploadShot(item.extras.cameras_id)}}
                            >
                                <Icon
                                    link
                                    id={`upload_${item.extras.filename}`}
                                    name='upload'
                                    color='orange'
                                />
                                Upload
                            </label>
                        </Table.Cell>
                        <Table.Cell width={3}>{this.getWhen(item.timestamp)}</Table.Cell>
                    </Table.Row>
                )
            case 'ShootV4':
                    const uniqId = uniqid()
                    return (
                        <Table.Row key={idx} positive={positive} negative={negative}>
                            <Table.Cell width={13}>
                                <span>Shoot   <span className='inside-span'>OK</span></span><br />
                                <label
                                    htmlFor={`upload_${uniqId}`}
                                    style={{
                                        float: 'right',
                                        color: 'orange',
                                        marginRight: '1em',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {this.props.uploadShot(item.extras.cameras_id)}}
                                >
                                    <Icon
                                        link
                                        id={`upload_${uniqId}`}
                                        name='upload'
                                        color='orange'
                                    />
                                    Upload
                                </label>
                            </Table.Cell>
                            <Table.Cell width={3}>{this.getWhen(item.timestamp)}</Table.Cell>
                        </Table.Row>
                    )
            case 'UploadV4':
                const viewSrc = `${API_BASE_URL}/api/cameras/${item.extras.cameras_id}/images/${item.extras.filename}`
                    + `?access_token=${this.props.accessToken}&type=image_full`
                return (
                    <Table.Row key={idx} positive={positive} negative={negative}>
                        <Table.Cell width={13}>
                            <span>Upload   <span className='inside-span'>{item.extras.result ? 'OK' : 'Failed'}</span></span><br />
                            <span>Filename:  <span className='inside-span'>{item.extras.filename}</span></span>
                            {item.extras.result ? <a target='_blank' rel='noopener noreferrer' href={viewSrc}><Icon name='image' size='large' /> View</a> : null}
                        </Table.Cell>
                        <Table.Cell width={3}>{this.getWhen(item.timestamp)}</Table.Cell>
                    </Table.Row>
                )
            case 'Preview':
                const src = `${API_BASE_URL}/api/cameras/${item.extras.cameras_id}/camera-control/preview.jpg?size=full`
                    + `&access_token=${this.props.accessToken}&v=${uniqid()}`
                return (
                    <Table.Row key={idx} positive={positive} negative={negative}>
                        <Table.Cell width={13}>
                            <span>Preview   <span className='inside-span'>OK</span></span><br />
                            {(item.extras.last_preview_shot)?
                                <span>Shot Taken: <span className='inside-span'>{item.extras.last_preview_shot}</span><br /></span>
                            : null}
                            {(item.extras.filename)?
                                <span>Filename:  <span className='inside-span'>{item.extras.filename}</span></span>
                            : null}
                            <span>Focal Length: <span className='inside-span'>{item.extras.focal_length}</span></span>
                            <span>Aperture: <span className='inside-span'>{item.extras.aperture}</span></span>
                            {(item.extras.exposure_compensation)?
                                <span>Exposure Comp: <span className='inside-span'>{item.extras.exposure_compensation}</span></span>
                            : null}
                            <span>Shutter Speed: <span className='inside-span'>{item.extras.shutter_speed}</span></span>
                            <span>ISO Speed: <span className='inside-span'>{item.extras.iso_speed}</span></span>
                            {(this.props.isLP4)?
                                <a onClick={(e) => {e.preventDefault(); this.props.fullResClick()}} rel='noopener noreferrer' href='#'>
                                    <Icon name='image' size='large' />View Full Size Image
                                </a>
                            : <a target='_blank' rel='noopener noreferrer' href={src}>
                                <Icon name='image' size='large' />View Full Size Image
                            </a>}
                        </Table.Cell>
                        <Table.Cell width={3}>{this.getWhen(item.timestamp)}</Table.Cell>
                    </Table.Row>
                )
            case 'Exif':
                return (
                    <Table.Row key={idx} positive={false} negative={negative}>
                        <Table.Cell width={13}>
                            <span>EXIF Preview   <span className='inside-span'>OK</span></span><br />
                            <span>Shot Taken: <span className='inside-span'>{item.extras.last_preview_shot}</span></span><br />
                            <span>Focal Length: <span className='inside-span'>{item.extras.focal_length}</span></span>
                            <span>Aperture: <span className='inside-span'>{item.extras.aperture}</span></span>
                            <span>Exposure Comp: <span className='inside-span'>{item.extras.exposure_compensation}</span></span>
                            <span>Shutter Speed: <span className='inside-span'>{item.extras.shutter_speed}</span></span>
                            <span>ISO Speed: <span className='inside-span'>{item.extras.iso_speed}</span></span>
                        </Table.Cell>
                        <Table.Cell width={3}>{this.getWhen(item.timestamp)}</Table.Cell>
                    </Table.Row>
                )
            default:
                return (
                    <Table.Row key={idx} positive={false} negative={negative}>
                        <Table.Cell width={13}>{item.message}</Table.Cell>
                        <Table.Cell width={3}>{this.getWhen(item.timestamp)}</Table.Cell>
                    </Table.Row>
                )
        }
    }

    getWhen = (timestamp) => {
        if (this.props.fromWhen) {
            return moment(parseInt(timestamp, 10)).fromNow()
        } else {
            return moment(parseInt(timestamp, 10)).format('HH:mm:ss D/MM/YYYY')
        }
    }

    isReady = () => {
        return !this.props.loading
    }

    render = () => {

        const data = this.props.log
        const sortedData = data.sort((a,b) => {
                if (a.timestamp > b.timestamp) {
                    return -1
                }
                if (a.timestamp < b.timestamp) {
                    return 1
                }
                return 0
            })

        // Only render table if there is any data...
        let activity = null
        if (sortedData.length > 0) {
            activity = <Table striped={true} fixed={true}>
                <Table.Header className='hidden'>
                    <Table.Row>
                        <Table.HeaderCell width={13}>Message</Table.HeaderCell>
                        <Table.HeaderCell width={3}>When</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>

                    { sortedData.map((item, idx) => {

                        return this.generateContent(item, idx)

                    })}

                </Table.Body>
            </Table>
        }

        if (!this.isReady()) {

            return (
                <div className='log-viewer' data-testid="log-viewer">
                    <div className='animated-blank-line'></div>
                    <div className='animated-blank-block-100'></div>
                </div>
            )

        } else {

            return (
                <div className='log-viewer' data-testid="log-viewer">
                    <h3>{this.props.name}</h3>
                    <Table striped={true} fixed={true} className="mobile-hidden">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={13}>Message</Table.HeaderCell>
                                <Table.HeaderCell width={3}>When</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                        </Table.Body>
                    </Table>
                    <div className='log-viewer-scrollable-container'>
                        <div>
                            {activity}
                        </div>
                    </div>
                </div>

            )
        }
    }
}
