import convert from 'convert-units'

import { converterBase10 as converter } from 'byte-converter'

export function mphToKph(mph, rounding = 2) {
    return +convert(mph).from('m/h').to('km/h').toFixed(rounding)
}

export function kphToMph(kph, rounding = 2) {
    return +convert(kph).from('km/h').to('m/h').toFixed(rounding)
}

export function celsiusToFahrenheit(c, rounding = 2) {
    return +convert(c).from('C').to('F').toFixed(rounding)
}

export function fahrenheitToCelsius(f, rounding = 2) {
    return +convert(f).from('F').to('C').toFixed(rounding)
}

export function KBtoGB(kb, rounding = 2) {
    return +convert(kb).from('KB').to('GB').toFixed(rounding)
}

export function BtoGB(b, rounding = 2) {
    return +converter(b, 'B', 'GB').toFixed(rounding)
}

