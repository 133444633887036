import moment from 'moment'

import * as types from '../actions/actionTypes'
import { API_BASE_URL } from '../config'

import { log } from '../actions/logActions'

/**
 * Wrapper for the fetch function that verifies the user's access token,
 * refreshes it if necessary and adds the Authorization header to the request.
 *
 * @param string url
 * @param object init
 * @param dispatch function
 * @param getState function
 *
 * @return Promise
 */
export function fetchWithToken(url, init, dispatch, getState) {
    const state = getState()
    let validAccessToken = false
    let accessToken = null
    const now = moment()
    if ('access_token' in state.auth && 'access_token_expiry' in state.auth) {
        // Check expiry of access token in state
        const expiry = moment(state.auth.access_token_expiry)
        if (expiry.subtract(5, 'minutes') > now) {
            validAccessToken = true
            accessToken = state.auth.access_token
        }
    } else if (
        localStorage.getItem('accessToken') !== null
        && localStorage.getItem('accessTokenExpiry') !== null
    ) {
        const expiry = moment(localStorage.getItem('accessTokenExpiry'))
        if (expiry.subtract(5, 'minutes') > now) {
            validAccessToken = true
            accessToken = localStorage.getItem('accessToken')
        }
    }
    if (validAccessToken) {
        const newInit = appendAuthorizationHeader(init, accessToken)
        return fetch(url, newInit)
    }
    // Check if access token refresh is in progress
    if (window.refreshingAccessToken) {
        return waitForAccessTokenRefresh(getState)
        .then(accessToken => {
            return fetch(url, appendAuthorizationHeader(init, accessToken))
        })
        .catch(() => {
            // Failed to update the access_token. Use the old one
            return fetch(url, appendAuthorizationHeader(init, state.auth.access_token))
        })
    } else {
        dispatch({type: types.REFRESH_ACCESS_TOKEN_REQUEST})
        window.refreshingAccessToken = true
        const refreshToken = localStorage['refreshToken']
        return fetch(API_BASE_URL +  '/auth/authorize', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                'grant_type' : 'refresh_token',
                'refresh_token' : refreshToken
            })
        })
        .then(response => {
            if (!response.ok) {
                // If here, we have errors
                dispatch({ type: types.REFRESH_ACCESS_TOKEN_ERROR})
                window.refreshingAccessToken = false
                return fetch(url, appendAuthorizationHeader(init, state.auth.access_token))
            } else {
                return response.json().then(data => {
                    if (data.errors) {
                        dispatch({type: types.REFRESH_ACCESS_TOKEN_ERROR})
                        window.refreshingAccessToken = false
                        return fetch(url, appendAuthorizationHeader(init, state.auth.access_token))
                    } else {
                        dispatch({type: types.REFRESH_ACCESS_TOKEN_RESPONSE, auth: data})
                        const newInit = appendAuthorizationHeader(init, data.access_token)
                        window.refreshingAccessToken = false
                        return fetch(url, newInit)
                    }
                })
            }
        })
        .catch((response) => {
            dispatch(log("error refreshing access token", response))
            dispatch({ type: types.REFRESH_ACCESS_TOKEN_ERROR})
            window.refreshingAccessToken = false
            return fetch(url, appendAuthorizationHeader(init, state.access_token))
        })
    }
}

/**
 * Appends the Authorization header to the fetch init object
 *
 * @param object init
 * @param string accessToken
 *
 * @return object
 */
export function appendAuthorizationHeader(init, accessToken) {
    const newInit = Object.assign(
        {},
        init,
        {
            headers: {
                ...init.headers,
                Authorization: 'Bearer ' + accessToken
            }
        }
    )
    return newInit
}

/**
 * If another request to refresh the access token has been made, this promise
 * waits up to 10 seconds for it to complete before resolving.
 *
 * @param function getState
 *
 * @return Promise
 */
export function waitForAccessTokenRefresh(getState) {
	return new Promise((resolve, reject) => {
      	var i = 0
        const checkToken = setInterval(() => {
            if (!window.refreshingAccessToken) {
                clearInterval(checkToken)
                const state = getState()
        		resolve(state.auth.access_token)
            }
            if (i>50) {
                clearInterval(checkToken)
                reject()
            }
            i++
        }, 200)
    })
}


/**
 * Determine auth state of user: access token, refresh token or neither and
 * perform the appropriate action
 *
 * @param Object auth
 * @param Object authActions
 * @param Array history
 *
 * @return null
 */
export function checkAuth(auth, authActions, history) {
    return new Promise((resolve, reject) => {
        if (auth.access_token || localStorage.getItem('accessToken') !== null) {
            return authActions.loggedInUserDetailsRequest()
            .then(() => resolve())
        } else {
            const refreshToken = localStorage['refreshToken']
            if (refreshToken) {
                return authActions.refreshAccessTokenRequest(
                    refreshToken,
                    history
                )
                .then(() => {
                    return authActions.loggedInUserDetailsRequest()
                })
                .then(() => {
                    resolve()
                })
            } else {
                history.push('/auth')
                reject()
            }
        }
    })
}
