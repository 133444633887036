import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as authActions from '../actions/authActions'
import * as headerActions from '../actions/headerActions'
import * as footerActions from '../actions/footerActions'
import * as captchaActions from '../actions/captchaActions'
import * as logActions from '../actions/logActions'
import * as messengerActions from '../actions/messengerActions'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Button, Form, Grid, Message, Segment, Container } from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'


import UserCredentials from './login/UserCredentials'
import MfaSetup from './login/MfaSetup'
import MfaToken from './login/MfaToken'


class Auth extends Component {
    componentDidMount() {
        if (this.props.auth.forceOut === true) {
            this.props.authActions.forcedOut(this.props.auth.forceOutMessage)
        } else {
            // Let's reset any previous auth errors - auth.password and auth.username
            this.props.messengerActions.removeAllMessages()
            this.props.authActions.loginResetErrors()
        }

        // this.props.headerActions.hideHeader()
        // this.props.footerActions.hideFooter()
    }

    render() {

        const { step } = this.props.match.params
        let xOffset = 100;
        if (step === 'enter-token') {
            xOffset = 200;
        } else if (step === 'setup-token') {
            xOffset = 0;
        }

        return (
            <Container className='login-form'>
                {/* Heads up! The styles below are necessary for the correct render of this example.
                You can do same with CSS, the main idea is that all the elements up to the `Grid`
                below must have a height of 100%.*/}
                {/* <style>{`
                    body > div,
                    body > div > div,
                    body > div > div > div.login-form {
                        height: 100%;
                    }
                `}</style> */}
                
                    <div className='container'>
                        <div
                            className='stepper'
                            style={{marginLeft: `${0-xOffset}%`}}
                        >
                            <MfaSetup
                                auth={this.props.auth}
                                history={this.props.history}
                                errors={this.props.auth.errors}
                                createTotp={this.props.authActions.createTotpFactor}
                                verifyTotp={this.props.authActions.verifyTotpFactor}
                                totpStatus={this.props.auth.totp}
                                totpUrl={this.props.auth.totpUrl}
                                requestMfaToken={this.props.authActions.requestMfaToken}
                                resetErrors={this.props.authActions.loginResetErrors}
                                resetTotpFactor={this.props.authActions.resetTotpFactor}
                            />
                            <UserCredentials
                                auth={this.props.auth}
                                captcha={this.props.captcha}
                                captchaRecaptchaRequest={this.props.captchaActions.captchaRecaptchaRequest}
                                history={this.props.history}
                                loginRequest={this.props.authActions.loginRequest}
                            />
                            <MfaToken
                                history={this.props.history}
                                loading={this.props.auth.loading}
                                errors={this.props.auth.errors}
                                verifyMfaToken={this.props.authActions.verifyMfaToken}
                                resetErrors={this.props.authActions.loginResetErrors}
                                fromAuthentication={this.props.auth.totp==='verified'}
                            />
                        </div>
                    </div>
            </Container>
        )
    }
}

Auth.propTypes = {
    authActions: PropTypes.object,
    auth: PropTypes.object,
    headerActions: PropTypes.object,
    header: PropTypes.object,
    footerActions: PropTypes.object,
    footer: PropTypes.object,
    captchaActions: PropTypes.object,
    captcha: PropTypes.object,
    logActions: PropTypes.object,
    messengerActions: PropTypes.object
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        header: state.header,
        footer: state.footer,
        captcha: state.captcha
    }
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(authActions, dispatch),
        headerActions: bindActionCreators(headerActions, dispatch),
        footerActions: bindActionCreators(footerActions, dispatch),
        captchaActions: bindActionCreators(captchaActions, dispatch),
        logActions: bindActionCreators(logActions, dispatch),
        messengerActions: bindActionCreators(messengerActions, dispatch),
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Auth))
