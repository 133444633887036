import * as types from './actionTypes'
import { addMessage } from './messengerActions'
import { log } from './logActions'
import { API_BASE_URL }  from '../config'
import { fetchWithToken } from '../util/accessToken'
import { notAuthorisedResponse } from './authActions'

export function projectDetailsResponseSuccess(payload) {
    return { type: types.PROJECT_DETAILS_RESPONSE_SUCCESS, details:  payload }
}

export function projectDetailsResponseErrors(payload) {
    return { type: types.PROJECT_DETAILS_RESPONSE_ERRORS, ...payload }
}

export function projectDetailsRequest(clientId, projectId) {

    return (dispatch, getState) => {
        dispatch({ type: types.PROJECT_DETAILS_REQUEST, clients_id: clientId, projects_id: projectId })

        const url = `${API_BASE_URL}/api/clients/${clientId}/projects/${projectId}`
        return fetchWithToken(
            url, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (response.status === 400) {
                response.json().then((data) => {
                    if (data.errors) {
                        dispatch(projectDetailsResponseErrors(data))
                    }
                })
            } else if (response.status === 401) {
                dispatch(notAuthorisedResponse())
            } else {
                return response.json()
            }
        })
        .then((data) => {
            if (data.errors) {
                dispatch(projectDetailsResponseErrors(data))
            } else {
                dispatch(projectDetailsResponseSuccess(data))
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

/**
 *
 * Updates an existing project for a client
 *
 * @param clientId
 * @param projectId
 * @param projectDetails
 * @returns {function(*)}
 */
export function updateProjectRequest(clientId, projectId, projectDetails) {

    return (dispatch, getState) => {
        dispatch(
            {
                type: types.UPDATE_PROJECT_REQUEST,
                details: projectDetails,
                clients_id: clientId,
                projects_id: projectId
            }
        )
        // Depending on the context....build up the URL appropriately
        const url = `${API_BASE_URL}/api/clients/${clientId}/projects/${projectId}`
        return fetchWithToken(
            url,
            {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(projectDetails)
            },
            dispatch,
            getState
        )
        .then(response => {
            if (response.status === 400) {
                response.json().then((data) => {
                    if (data.errors) {
                        dispatch(updateProjectResponseErrors(data))
                    }
                })
            } else if (response.status === 401) {
                dispatch(notAuthorisedResponse())
            } else {
                return response.json()
            }
        })
        .then((data) => {
            if (data.errors) {
                dispatch(updateProjectResponseErrors(data))
            } else {
                dispatch(
                    addMessage(
                        "success",
                        "Success",
                        "Successfully updated project."
                    )
                )
                dispatch(updateProjectResponseSuccess(data))
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }

}

export function updateProjectResponseSuccess(response) {
    return { type: types.UPDATE_PROJECT_RESPONSE_SUCCESS, ...response }
}

export function updateProjectResponseErrors(response) {
    return { type: types.UPDATE_PROJECT_RESPONSE_ERRORS, ...response }
}

export function resetErrors() {
    return { type: types.UPDATE_PROJECT_RESET_ERRORS }
}

/**
 *
 * Deletes a project for a given client
 *
 * @param clientId
 * @param projectId
 * @returns {function(*=)}
 */
export function deleteProjectRequest(clientId, projectId) {
    return (dispatch, getState) => {
        dispatch({type: types.DELETE_PROJECT_REQUEST, projects_id: projectId})

        const url = `${API_BASE_URL}/api/clients/${clientId}/projects/${projectId}`
        return fetchWithToken(
            url,
            {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (response.status === 400) {
                response.json().then((data) => {
                    if (data.errors) {
                        dispatch(deleteProjectResponseErrors(clientId, projectId, data))
                    }
                })
            } else if (response.status === 204) {
                dispatch(
                    addMessage(
                        "success",
                        "Success",
                        "Successfully deleted project."
                    )
                )
                dispatch(deleteProjectResponseSuccess(clientId, projectId))
            } else {
                // Must be JSON/errors
                response.json().then((data) => {
                    dispatch(deleteProjectResponseErrors(clientId, projectId, data))
                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function deleteProjectResponseSuccess(clientId, projectId) {
    return {
        type: types.DELETE_PROJECT_RESPONSE_SUCCESS,
        clients_id: clientId,
        projects_id: projectId
    }
}

export function deleteProjectResponseErrors(clientId, projectId, response) {
    return {
        type: types.DELETE_PROJECT_RESPONSE_ERRORS,
        clients_id: clientId,
        projects_id: projectId,
        errors: response.errors
    }
}

export function deleteProjectResetErrors() {
    return { type: types.DELETE_PROJECT_RESET_ERRORS }
}

export function updateProjectDetailsCameraDefaultWeightsChange(defaultWeights) {
    return { type: types.UPDATE_PROJECT_DETAILS_CAMERA_DEFAULT_WEIGHTS_CHANGE, defaultWeights }
}

export function projectCamerasAvailableCameras(availableCameras) {
    return { type: types.UPDATE_PROJECT_PROJECT_CAMERAS_AVAILABLE_CAMERAS, cameras: availableCameras}
}

export function projectCamerasProjectCameras(projectCameras) {
    return { type: types.UPDATE_PROJECT_PROJECT_CAMERAS_PROJECT_CAMERAS, cameras: projectCameras}
}

export function projectCamerasRemoveCameras(removeCameras) {
    return { type: types.UPDATE_PROJECT_PROJECT_CAMERAS_REMOVE_CAMERAS, cameras: removeCameras}
}

export function projectCamerasAddCameras(addCameras) {
    return { type: types.UPDATE_PROJECT_PROJECT_CAMERAS_ADD_CAMERAS, cameras: addCameras}
}

export function projectCamerasCameraWeights(cameraWeights) {
    return { type: types.UPDATE_PROJECT_PROJECT_CAMERAS_CAMERA_WEIGHTS, cameras: cameraWeights}
}
