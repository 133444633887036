import * as types from './actionTypes'
import { API_BASE_URL, VIEWER_URL }  from '../config'
import { addMessage } from './messengerActions'
import { log } from './logActions'
import { notAuthorisedResponse } from './authActions'
import { fetchWithToken } from '../util/accessToken'

export function fetchAllCamerasReset() {
    return dispatch => {
        dispatch(
            {type: types.CAMERAS_FETCH_ALL_RESET}
        )
    }
}

export function fetchAvailableCameras() {
    return (dispatch, getState) => {
        dispatch({ type: types.CAMERAS_FETCH_AVAILABLE_REQUEST  })
        return fetchWithToken(
            API_BASE_URL + '/api/admin/cameras/available',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    // Remember, we're bouncing out 401s
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERAS_FETCH_AVAILABLE_RESPONSE_ERRORS,
                                    errors: data.errors,
                                })
                        } else {
                            // Error
                            dispatch(
                                {
                                    type: types.CAMERAS_FETCH_AVAILABLE_RESPONSE_ERRORS,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error"
                                        }
                                    }
                                }
                            )
                        }

                    })
                }
            } else {
                if (response.status === 204) {
                    dispatch({
                        type: types.CAMERAS_FETCH_AVAILABLE_RESPONSE_SUCCESS,
                        cameras: []
                    })
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERAS_FETCH_AVAILABLE_RESPONSE_ERRORS,
                                    errors: data.errors,
                                })
                        } else {
                            // Error
                            dispatch(
                                {
                                    type: types.CAMERAS_FETCH_AVAILABLE_RESPONSE_SUCCESS,
                                    cameras: data
                                })
                        }

                    })
                }
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function associateCameraWithProjectRequest(cameraId, projectId) {
    return (dispatch, getState) => {
        dispatch({
            type: types.CAMERAS_ASSOCIATE_CAMERA_WITH_PROJECT_REQUEST,
            cameras_id: cameraId,
            projects_id: projectId
        })
        return fetchWithToken(
            API_BASE_URL + '/api/cameras/associate-camera-with-project',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    cameras_id: cameraId,
                    projects_id: projectId
                })
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.CAMERAS_ASSOCIATE_CAMERA_WITH_PROJECT_RESPONSE_ERRORS,
                                        cameras_id: cameraId,
                                        projects_id: projectId,
                                        errors: data.errors,
                                    })
                            } else {
                                // Error
                                dispatch(
                                    {
                                        type: types.CAMERAS_ASSOCIATE_CAMERA_WITH_PROJECT_RESPONSE_ERRORS,
                                        cameras_id: cameraId,
                                        projects_id: projectId,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        }
                                    }
                                )
                            }

                        })
                    }
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERAS_ASSOCIATE_CAMERA_WITH_PROJECT_RESPONSE_ERRORS,
                                    errors: data.errors,
                                    cameras_id: cameraId,
                                    projects_id: projectId
                                })
                        } else {
                            // Error
                            dispatch(
                                {
                                    type: types.CAMERAS_ASSOCIATE_CAMERA_WITH_PROJECT_RESPONSE_SUCCESS,
                                    details: data,
                                    cameras_id: cameraId,
                                    projects_id: projectId
                                })
                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(log("error", response))
            })
    }
}

export function unassociateCameraWithProjectRequest(cameraId) {
    return (dispatch, getState) => {
        dispatch({ type: types.CAMERAS_UNASSOCIATE_CAMERA_WITH_PROJECT_REQUEST, cameras_id: cameraId })
        return fetchWithToken(
            API_BASE_URL + '/api/cameras/associate-camera-with-project',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    cameras_id: cameraId
                })
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.CAMERAS_UNASSOCIATE_CAMERA_WITH_PROJECT_RESPONSE_ERRORS,
                                        errors: data.errors,
                                        cameras_id: cameraId
                                    })
                            } else {
                                // Error
                                dispatch(
                                    {
                                        type: types.CAMERAS_UNASSOCIATE_CAMERA_WITH_PROJECT_RESPONSE_ERRORS,
                                        cameras_id: cameraId,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        }
                                    }
                                )
                            }

                        })
                    }
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERAS_UNASSOCIATE_CAMERA_WITH_PROJECT_RESPONSE_ERRORS,
                                    errors: data.errors,
                                    cameras_id: cameraId
                                })
                        } else {
                            // Error
                            dispatch(
                                {
                                    type: types.CAMERAS_UNASSOCIATE_CAMERA_WITH_PROJECT_RESPONSE_SUCCESS,
                                    details: data,
                                    cameras_id: cameraId
                                })
                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(log("error", response))
            })

    }
}

export function allCameraTagsRequest() {

    return (dispatch, getState) => {

        dispatch({ type: types.CAMERAS_ALL_TAGS_REQUEST })

        return fetchWithToken(
            API_BASE_URL + '/api/cameras/tags',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (response.status === 204) {
                return {}
            }
            else if (response.status === 401) {
                dispatch(notAuthorisedResponse())
            } else {
                return response.json()
            }
        })
        .then((json) => {
            dispatch(allCameraTagsResponse(json))
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to load tags",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })

    }
}

export function allCameraTagsResponse(response) {
    if ('tags' in response) {
        return {type: types.CAMERAS_ALL_TAGS_RESPONSE, tags: response.tags}
    } else {
        return {type: types.CAMERAS_ALL_TAGS_RESPONSE, tags: []}
    }
}

export function allCameraStatusesRequest() {

    return (dispatch, getState) => {

        dispatch({ type: types.CAMERAS_ALL_STATUSES_REQUEST })

        return fetchWithToken(
            API_BASE_URL + '/api/cameras/statuses',
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (response.status === 401) {
                dispatch(notAuthorisedResponse())
            } else {
                return response.json()
            }
        })
        .then((json) => {
            dispatch(allCameraStatusesResponse(json))
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to load statuses",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })

    }
}

export function allCameraStatusesResponse(response) {
    if ('statuses' in response) {
        return {type: types.CAMERAS_ALL_STATUSES_RESPONSE, statuses: response.statuses, gridDefault: response.gridDefault}
    } else {
        return {type: types.CAMERAS_ALL_STATUSES_RESPONSE, statuses: [], gridDefault: null}
    }
}

export function toggleCameraFilterTag(id, context = "grid") {
    return dispatch => {
        dispatch({type: types.CAMERAS_TOGGLE_FILTER_TAG, id: id})
        if (context === "grid") {
            dispatch(fetchGridViewCameras(1))
        } else if (context === "list") {
            dispatch(fetchListViewCameras(1))
        }
    }
}

export function toggleCameraFilterStatus(status, context = "grid") {
    return dispatch => {
        dispatch({type: types.CAMERAS_TOGGLE_FILTER_STATUS, status: status})
        if (context === "grid") {
            dispatch(fetchGridViewCameras(1))
        } else if (context === "list") {
            dispatch(fetchListViewCameras(1))
        }
    }
}

export function toggleCameraFilterType(type, context = "grid") {
    return dispatch => {
        dispatch({type: types.CAMERAS_TOGGLE_FILTER_TYPE, name: type})
        if (context === "grid") {
            dispatch(fetchGridViewCameras(1))
        } else if (context === "list") {
            dispatch(fetchListViewCameras(1))
        }
    }
}

export function toggleCameraFilterVersion(version, context = "grid") {
    return dispatch => {
        dispatch({type: types.CAMERAS_TOGGLE_FILTER_VERSION, version: version})
        if (context === "grid") {
            dispatch(fetchGridViewCameras(1))
        } else if (context === "list") {
            dispatch(fetchListViewCameras(1))
        }
    }
}

export function setCameraFilterTags(filter, context = "grid") {
    return dispatch => {
        dispatch({type: types.CAMERAS_SET_FILTER_TAGS, filter: filter})
    }
}

export function setCameraFilterStatuses(filter, context = "grid") {
    return dispatch => {
        dispatch({type: types.CAMERAS_SET_FILTER_STATUSES, filter: filter})
    }
}

export function setCameraFilterVersions(filter, context = "grid") {
    return dispatch => {
        dispatch({type: types.CAMERAS_SET_FILTER_VERSIONS, filter: filter})
    }
}

export function setCameraFilterTypes(filter, context = "grid") {
    return dispatch => {
        dispatch({type: types.CAMERAS_SET_FILTER_TYPES, filter: filter})
    }
}

export function changeCamerasSortBy(field, context = "grid") {
    return dispatch => {
        dispatch({type: types.CAMERAS_SORT_BY_CHANGE, field: field})
        if (context === "grid") {
            dispatch(fetchGridViewCameras(1))
        } else if (context === "list") {
            dispatch(fetchListViewCameras(1))
        }
    }
}

export function setCamerasSortBy(field, ascending = "true", context = "grid") {
    return dispatch => {
        dispatch({
            type: types.CAMERAS_SET_SORT_BY,
            field: field,
            ascending: ascending
        })
    }
}


export function create(details) {
    return (dispatch, getState) => {
        dispatch({type: types.CAMERAS_CREATE_REQUEST})
        const url = `${API_BASE_URL}/api/cameras`
        return fetchWithToken(
            url,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(details)
            },
            dispatch,
            getState
        )
        .then(response => {
            if (response.status === 400) {
                response.json().then((data) => {
                    if (data.errors) {
                        dispatch({type: types.CAMERAS_CREATE_RESPONSE_ERRORS, errors: data.errors })
                    } else {
                        dispatch(
                            {
                                type: types.CAMERAS_CREATE_RESPONSE_ERRORS,
                                errors: {
                                    unknownErrorType: {
                                        unknownError: "There was an unexpected error"
                                    }
                                }
                            }
                        )
                    }
                })
            } else if (response.status === 401) {
                dispatch(notAuthorisedResponse())
            } else {
                return response.json()
            }
        })
        .then((data) => {
            if (data.errors) {
                dispatch({type: types.CAMERAS_CREATE_RESPONSE_ERRORS, errors: data.errors })
            } else {
                dispatch(
                    addMessage(
                        "success",
                        "Success",
                        "Successfully created camera."
                    )
                )
                dispatch({type: types.CAMERAS_CREATE_RESPONSE_SUCCESS, details: data })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function update(cameraId, details, hideMessage) {
    return (dispatch, getState) => {
        dispatch({type: types.CAMERAS_UPDATE_REQUEST, cameras_id: cameraId})
        const url = `${API_BASE_URL}/api/cameras/${cameraId}`
        return fetchWithToken(
            url,
            {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(details)
            },
            dispatch,
            getState
        )
        .then(response => {
            if (response.status === 400) {
                response.json().then((data) => {
                    if (data.errors) {
                        dispatch({type: types.CAMERAS_UPDATE_RESPONSE_ERRORS, errors: data.errors })
                    } else {
                        dispatch(
                            {
                                type: types.CAMERAS_UPDATE_RESPONSE_ERRORS,
                                errors: {
                                    unknownErrorType: {
                                        unknownError: "There was an unexpected error"
                                    }
                                }
                            }
                        )
                    }
                })
            } else if (response.status === 401) {
                dispatch(notAuthorisedResponse())
            } else {
                return response.json()
            }
        })
        .then((data) => {
            if (data.errors) {
                dispatch({type: types.CAMERAS_UPDATE_RESPONSE_ERRORS, errors: data.errors })
            } else {
                if (!hideMessage) {
                    dispatch(
                        addMessage(
                            "success",
                            "Success",
                            "Successfully updated camera settings."
                        )
                    )
                }
                
                dispatch({type: types.CAMERAS_UPDATE_RESPONSE_SUCCESS, details: data })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function resetCreate() {
    return { type: types.CAMERAS_CREATE_RESET }
}

export function resetUpdate() {
    return { type: types.CAMERAS_UPDATE_RESET }
}

export function fetchCamera(cameraId) {
    return (dispatch, getState) => {
        dispatch({ type: types.CAMERAS_CAMERA_REQUEST, cameras_id: cameraId  })
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}` ,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        ).then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    // Remember, we're bouncing out 401s
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERAS_CAMERA_RESPONSE_ERRORS,
                                    errors: data.errors,
                                    cameras_id: cameraId
                                })
                        } else {
                            // Error
                            dispatch(
                                {
                                    type: types.CAMERAS_CAMERA_RESPONSE_ERRORS,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error"
                                        }
                                    },
                                    cameras_id: cameraId
                                }
                            )
                        }

                    })
                }
            } else {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.CAMERAS_CAMERA_RESPONSE_ERRORS,
                                errors: data.errors,
                                cameras_id: cameraId
                            }
                        )
                    } else {
                        dispatch(
                            {
                                type: types.CAMERAS_CAMERA_RESPONSE_SUCCESS,
                                details: data,
                                cameras_id: cameraId
                            }
                        )
                        // Attempt to parse virtual camera parameters
                        try {
                            const params = JSON.parse(data['vc_parameters'])
                            if (params.final3dImage) {
                                dispatch(
                                    {
                                        type: types.BIM_FINAL_3D_SET_FILENAME,
                                        ...params.final3dImage
                                    }
                                )
                            }
                        } catch(err) {
                            //console.log(err)
                        }
                    }

                })
            }
        }).catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function fetchGridViewCameras(currentPage) {
    return (dispatch, getState) => {
        dispatch({ type: types.CAMERAS_GRID_VIEW_FETCH_REQUEST  })

        const state = getState()
        const filterTags = state.cameras.tags.filterBy
        const filterStatuses = state.cameras.statuses.filterBy
        const filterVersions = state.cameras.versions.filterBy
        const filterTypes = state.cameras.filterByTypes
        const sortField = state.cameras.sort.field
        const ascending = state.cameras.sort.ascending
        const filterBy = state.cameras.filterBy
        let url = `${API_BASE_URL}/api/cameras?currentPage=${currentPage}`
        url += `&orderBy=${sortField}&ascending=${ascending}`

        if (filterTags.length === 0) {
            url += '&filterTag[]='
        } else {
            filterTags.forEach(tag => {
                url += `&filterTag[]=${tag}`
            })
        }

        if (filterStatuses.length === 0) {
            url += '&filterStatus[]='
        } else {
            filterStatuses.forEach(status => {
                url += `&filterStatus[]=${status}`
            })
        }

        if (filterVersions.length === 0) {
            url += '&filterVersion[]='
        } else {
            filterVersions.forEach(status => {
                url += `&filterVersion[]=${status}`
            })
        }

        if (filterTypes.length === 0) {
            url += '&filterType[]='
        } else {
            filterTypes.forEach(type => {
                url += `&filterType[]=${type}`
            })
        }

        url += `&filterBy=${filterBy}&thumbs=true`

        return fetchWithToken(
            url,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    // Remember, we're bouncing out 401s
                    dispatch(notAuthorisedResponse())
                } else if (response.status === 204) {

                    dispatch({
                            type: types.CAMERAS_GRID_VIEW_FETCH_RESPONSE_END,
                            totalItems: 0,
                            cameras: []
                        }
                    )

                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERAS_GRID_VIEW_FETCH_RESPONSE_ERRORS,
                                    errors: data.errors,
                                })
                        } else {
                            // Error
                            dispatch(
                                {
                                    type: types.CAMERAS_GRID_VIEW_FETCH_RESPONSE_ERRORS,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error"
                                        }
                                    }
                                }
                            )
                        }

                    })
                }
            } else {
                if (response.status === 204) {
                    const totalItems = state.cameras.grid_view.currentPage === 1 ?
                        0 :
                        state.cameras.grid_view.total
                    dispatch({
                            type: types.CAMERAS_GRID_VIEW_FETCH_RESPONSE_END,
                            totalItems: totalItems,
                            cameras: []
                        }
                    )
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch({
                                type: types.CAMERAS_GRID_VIEW_FETCH_RESPONSE_ERRORS,
                                errors: data.errors,
                            })
                        } else {
                            dispatch({
                                type: types.CAMERAS_GRID_VIEW_FETCH_RESPONSE_SUCCESS,
                                ...data
                            })
                        }

                    })
                }
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function resetGridView() {
    return dispatch => {
        return new Promise(function(resolve, reject) {
            dispatch({ type: types.CAMERAS_GRID_VIEW_RESET })
            resolve()
        })
    }
}

export function fetchListViewCameras(currentPage, pageSize = 20) {
    return (dispatch, getState) => {
        dispatch({ type: types.CAMERAS_LIST_VIEW_FETCH_REQUEST, currentPage: currentPage  })
        const state = getState()
        const filterTags = state.cameras.tags.filterBy
        const filterStatuses = state.cameras.statuses.filterBy
        const filterVersions = state.cameras.versions.filterBy
        const sortField = state.cameras.sort.field
        const ascending = state.cameras.sort.ascending
        const filterBy = state.cameras.filterBy
        let url = `${API_BASE_URL}/api/cameras?currentPage=${currentPage}`
        url += `&orderBy=${sortField}&ascending=${ascending}`

        if (filterTags.length === 0) {
            url += '&filterTag[]='
        } else {
            filterTags.forEach(tag => {
                url += `&filterTag[]=${tag}`
            })
        }

        if (filterStatuses.length === 0) {
            url += '&filterStatus[]='
        } else {
            filterStatuses.forEach(status => {
                url += `&filterStatus[]=${status}`
            })
        }

        if (filterVersions.length === 0) {
            url += '&filterVersion[]='
        } else {
            filterVersions.forEach(status => {
                url += `&filterVersion[]=${status}`
            })
        }

        // filterTags.forEach(tag => {url += `&filterTag[]=${tag}`})
        // filterStatuses.forEach(status => {url += `&filterStatus[]=${status}`})

        url += `&pageSize=${pageSize}`
        url += `&filterBy=${filterBy}`

        return fetchWithToken(
            url,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    // Remember, we're bouncing out 401s
                    dispatch(notAuthorisedResponse())
                } else if (response.status === 204) {

                        dispatch({
                                type: types.CAMERAS_LIST_VIEW_FETCH_RESPONSE_END,
                                cameras: []
                            }
                        )

                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERAS_LIST_VIEW_FETCH_RESPONSE_ERRORS,
                                    errors: data.errors,
                                })
                        } else {
                            // Error
                            dispatch(
                                {
                                    type: types.CAMERAS_LIST_VIEW_FETCH_RESPONSE_ERRORS,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error"
                                        }
                                    }
                                }
                            )
                        }

                    })
                }
            } else {

                if (response.status === 204) {
                    dispatch({
                            type: types.CAMERAS_LIST_VIEW_FETCH_RESPONSE_END,
                            cameras: []
                        }
                    )
                } else {

                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERAS_LIST_VIEW_FETCH_RESPONSE_ERRORS,
                                    errors: data.errors,
                                }
                            )
                        } else {
                            dispatch(
                                {
                                    type: types.CAMERAS_LIST_VIEW_FETCH_RESPONSE_SUCCESS,
                                    ...data,
                                    currentPage: currentPage
                                }
                            )
                        }

                    })

                }

            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function resetListView() {
    return { type: types.CAMERAS_LIST_VIEW_RESET }
}


export function filterBy(searchTerm) {
    return { type: types.CAMERAS_FILTER_BY, filterBy: searchTerm }
}

export function resetCameraInfo() {
    return { type: types.CAMERAS_CAMERA_INFO_RESET }
}

export function fetchCameraInfo(cameraId) {
    return (dispatch, getState) => {
        dispatch({type: types.CAMERAS_FETCH_CAMERA_INFO_REQUEST, cameras_id: cameraId})
        let url = `${API_BASE_URL}/api/cameras/${cameraId}/get-info`
        return fetchWithToken(
            url,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.CAMERAS_FETCH_CAMERA_INFO_RESPONSE_ERRORS,
                                        errors: data.errors,
                                        cameras_id: cameraId
                                    })
                            } else {
                                // Error
                                dispatch(
                                    {
                                        type: types.CAMERAS_FETCH_CAMERA_INFO_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        },
                                        cameras_id: cameraId
                                    }
                                )
                            }

                        })
                    }
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERAS_FETCH_CAMERA_INFO_RESPONSE_ERRORS,
                                    errors: data.errors,
                                    cameras_id: cameraId
                                }
                            )
                        } else {
                            dispatch(
                                {
                                    type: types.CAMERAS_FETCH_CAMERA_INFO_RESPONSE_SUCCESS,
                                    details: data,
                                    cameras_id: cameraId
                                }
                            )
                        }
                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(log("error", response))
            })
    }
}

export function resetCameraTags() {
    return { type: types.CAMERAS_FETCH_CAMERA_TAGS_RESET }
}

export function fetchCameraTags(clientId, cameraId) {
    return (dispatch, getState) => {
        dispatch(
            {
                type: types.CAMERAS_FETCH_CAMERA_TAGS_REQUEST,
                cameras_id: cameraId,
                clients_id: clientId
            })
        let url = `${API_BASE_URL}/api/clients/${clientId}/cameras/${cameraId}/tags`
        return fetchWithToken(
            url,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.CAMERAS_FETCH_CAMERA_TAGS_RESPONSE_ERRORS,
                                        errors: data.errors,
                                        cameras_id: cameraId,
                                        clients_id: clientId
                                    })
                            } else {
                                // Error
                                dispatch(
                                    {
                                        type: types.CAMERAS_FETCH_CAMERA_TAGS_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        },
                                        cameras_id: cameraId,
                                        clients_id: clientId
                                    }
                                )
                            }

                        })
                    }
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERAS_FETCH_CAMERA_TAGS_RESPONSE_ERRORS,
                                    errors: data.errors,
                                    cameras_id: cameraId,
                                    clients_id: clientId
                                }
                            )
                        } else {
                            dispatch(
                                {
                                    type: types.CAMERAS_FETCH_CAMERA_TAGS_RESPONSE_SUCCESS,
                                    tags: data,
                                    cameras_id: cameraId,
                                    clients_id: clientId
                                }
                            )
                        }
                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(log("error", response))
            })
    }
}

export function reset() {
    return { type: types.CAMERAS_RESET }
}

export function authAsCamera(cameraId) {
    return (dispatch, getState) => {
        // Spawn new window with viewer URL waiting for credentials
        const viewer = window.open(`${VIEWER_URL}/auth-as`, '_blank')

        // Make API call to set projects and cameras is Redis
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/auth-as-camera`,
            {
                method: 'GET',
                mode: 'cors'
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                viewer.close()
                // might be forbidden!
                if (response.status === 401) {
                    // Remember, we're bouncing out 401s
                    dispatch(notAuthorisedResponse())
                } else {
                    dispatch(
                        addMessage(
                            "negative",
                            "Error",
                            "Unable to open the viewer as the requested camera"
                        )
                    )
                    dispatch(log("error", response))
                }
            } else {
                response.json()
                .then(data => {
                    // Send credentials via postMessage()
                    setTimeout(() => {
                        const state = getState()
                        const auth = Object.assign(
                            {},
                            state.auth,
                            data
                        )
                        viewer.postMessage(auth, VIEWER_URL)
                    }, 500)
                    // Second send, in case things are taking a while to load
                    setTimeout(() => {
                        const state = getState()
                        const auth = Object.assign(
                            {},
                            state.auth,
                            data
                        )
                        viewer.postMessage(auth, VIEWER_URL)
                    }, 10000)
                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function importCoordinatesCSV(file) {
    return (dispatch, getState) => {
        dispatch({type: types.IMPORT_CSV_REQUEST})
        const url = `${API_BASE_URL}/api/cameras/import-data`
        return fetchWithToken(
            url,
            {
                method: 'POST',
                mode: 'cors',
                body: file
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                // might be forbidden!
                if (response.status === 401) {
                    // Remember, we're bouncing out 401s
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch({
                                type: types.IMPORT_CSV_RESPONSE_ERRORS,
                                errors: data.errors })
                        } else {
                            dispatch({
                                type: types.IMPORT_CSV_RESPONSE_ERRORS,
                                errors:  { logo: { unknownError: 'Unknown error' }}
                            })
                        }
                    })
                }
            } else {
                response.json().then((data) => {
                    // Presumably, errors of some kind...
                    if (data.errors) {
                        dispatch({
                            type: types.IMPORT_CSV_RESPONSE_ERRORS,
                            errors: data.errors
                        })
                    } else {
                        dispatch(
                            addMessage(
                                "success",
                                "Success",
                                "Successfully imported updated pot coordinates."
                            )
                        )
                        dispatch({
                            type: types.IMPORT_CSV_RESPONSE_SUCCESS,
                            details: data
                        })
                    }
                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
}
}
