import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as authActions from '../actions/authActions'
import * as cameraUploadActions from '../actions/cameraUploadActions'
import CameraUpload from '../components/pages/CameraUpload'
import { isPermitted } from './../util/acl'
import { checkAuth } from '../util/accessToken'

class CameraUploadContainer extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)

        return checkAuth(this.props.auth, this.props.authActions, this.props.history)
        .then(() => {
            this.props.cameraUploadActions.getDetails(this.props.match.params.cameraId)
        })
        .catch(err => {})
    }

    render() {
        const upload = isPermitted(this.props.auth.user, 'get-cameras-upload');

        return (
            <>
                {upload && <CameraUpload
                    potId={this.props.cameraUpload.potId}
                    password={this.props.cameraUpload.password}
                    pcid={this.props.cameraUpload.pcid}
                    potVersion={this.props.cameraUpload.potVersion}
                    uploadHost={this.props.cameraUpload.uploadHost}
                />}
                {!upload &&  <p>You do not have permission to view this page</p>}
            </>
        )
    }

}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        cameraUpload: state.cameraUpload
    }
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(authActions, dispatch),
        cameraUploadActions: bindActionCreators(cameraUploadActions, dispatch)
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CameraUploadContainer)
