import React, { Component } from 'react'
import {
    Message,
    Form,
    Checkbox,
    Tab,
    Grid,
} from 'semantic-ui-react'
import { getErrorMessage } from '../util/errors'
import UserProjectsAndCamerasSelector from "./UserProjectsAndCamerasSelector";

class UpdateBulkUsers extends Component {

    constructor() {
        super()
        this.state = {
            pane: 'Settings',
            user: {
                settings: {
                    permitted_to_save: true,
                },
                video_editor: true,
                id: null,
                bulk_edit: true,

            }
        }
    }

    componentDidMount() {
        this.props.fetchUserFields(this.state.user)
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.user !== prevState.user) {
            this.props.fetchUserFields(this.state.user)
        }
    }

    onContextChange = (context) => {
        this.props.onContextChange(context)
    }

    onCommentChange = (event) => {
        const val = event.target.value
        this.setState((prevState, props) => {
            return {
                ...prevState,
                user: {
                    ...prevState.user,
                    comment: val
                }
            }
        }, () => {
            this.props.onUpdateLocalUserState(this.state)
        })
    }

    onDelayChange = (event) => {
        const val = event.target.value
        this.setState((prevState, props) => {
            return {
                ...prevState,
                user: {
                    ...prevState.user,
                    delay: parseInt(val)
                }
            } 
        }, () => {
            this.props.onUpdateLocalUserState(this.state)
        })
    }

    onLimitHistoryChange = (event) => {
        const val = event.target.value
        this.setState((prevState, props) => {
            return {
                ...prevState,
                user: {
                    ...prevState.user,
                    limit_history: parseInt(val)
                }
            }
        }, () => {
            this.props.onUpdateLocalUserState(this.state)
        })
    }

    onProcessedChange = (event, {checked}) => {
        this.setState((prevState, props) => {
            return {
                ...prevState,
                user: {
                    ...prevState.user,
                    processed: checked
                }
            }
        }, () => {
            this.props.onUpdateLocalUserState(this.state)
        })
    }

    onAllowSavingPermissionChange = (event, {checked}) => {
        let video_editor = this.state.user.video_editor
        if (!checked) {
          video_editor = false
        }
        this.setState((prevState, props) => {
            return {
                ...prevState,
                user: {
                    ...prevState.user,
                    settings: {
                        permitted_to_save: checked
                    },
                    video_editor: video_editor
                }
            }
        }, () => {
            this.props.onUpdateLocalUserState(this.state)
        })
    }

    onAllowVideoEditingPermissionChange = (event, {checked}) => {
        this.setState((prevState, props) => {
            return {
                ...prevState,
                user: {
                    ...prevState.user,
                    video_editor: checked
                }
            }
        }, () => {
            this.props.onUpdateLocalUserState(this.state)
        })
    }

    isReady = () => {
        return true
        //return this.props.user.username
    }

    getHeader = (error, success) => {
        if (error) {
            return <p>Error</p>
        } else if (success) {
            return <p>Successfully updated user settings</p>
        } else {
            return ""
        }
    }

    getContent = (error, success, loading, errors) => {
        // We might have a server error, or just regular errors
        if (error) {
            return this.renderForm()
        } else if (success) {
            return <div>
                {this.renderForm()}
            </div>
        } else if (loading) {
            return <div>
                {this.renderForm()}
            </div>
        } else {
            // Must be initial loading...
            return this.renderForm()
        }
    }

    renderForm = () => {

        if (!this.isReady()) {
            return (<div>
                <div>
                    <div className='animated-blank-line'></div>
                    <div className='animated-blank-line'></div>
                    <div className='animated-blank-line'></div>
                    <div className='animated-blank-line'></div>
                    <div className='animated-blank-line'></div>
                    <div className='animated-blank-line'></div>
                    <div className='animated-blank-line'></div>
                    <div className='animated-blank-block-75'></div>
                    <div className='animated-blank-line'></div>
                    <div className='animated-blank-block-75'></div>
                    <div className='animated-blank-line'></div>
                    <div className='animated-blank-block-75'></div>
                    <div className='animated-blank-line'></div>
                    <div className='animated-blank-line'></div>
                    <div className='animated-blank-line'></div>
                </div>
            </div>)
        } else {
            return (
                <div>
                    <Form size='large'>
                        <h3>User Account Details</h3>
                        <Message
                            error={true}
                            visible={this.props.errors.status}
                            header={this.props.errors.status}
                            content={getErrorMessage(this.props.errors, 'status')}
                        />
                         <Grid columns='2'>
                            <Grid.Row>
                                <Grid.Column>
                                    <Form.TextArea
                                            label = 'Comments'
                                        placeholder='Comments here'
                                    
                                        onChange={this.onCommentChange}
                                        error={(this.props.errors.comment) ? true : false}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <h3>Overrides</h3>
                                    <p>Time delay (in hours)</p>
                                    <Form.Group>
                                        <Form.Input
                                            type="number"
                                            placeholder='Time delay'
                                            onChange={this.onDelayChange}
                                            error={(this.props.errors.delay) ? true : false}
                                            defaultValue={this.props.user.delay}
                                            min="0"
                                        />
                                    </Form.Group>
                                    <Message
                                        error={true}
                                        visible={(this.props.errors.delay) ? true : false}
                                        header={'Error'}
                                        content={getErrorMessage(this.props.errors, 'delay')}
                                    />

                                    <p>Limit access to historical images (in days)</p>
                                    <Form.Group>
                                        <Form.Input
                                            type="number"
                                            placeholder='Limit access (in days)'
                                            onChange={this.onLimitHistoryChange}
                                            error={(this.props.errors.limit_history) ? true : false}
                                            defaultValue={this.props.user.limit_history}
                                            min="0"
                                        />
                                    </Form.Group>
                                    <Message
                                        error={true}
                                        visible={(this.props.errors.limit_history) ? true : false}
                                        header={'Error'}
                                        content={getErrorMessage(this.props.errors, 'limit_history')}
                                    />

                                    <h3>Show Blurred Images?</h3>
                                    <p>Show the blurred version of images to this user if set against camera</p>
                                    <Checkbox
                                        label={this.props.user.processed ? 'Enabled' : 'Disabled'}
                                        defaultChecked={this.props.user.processed}
                                        onChange={this.onProcessedChange}
                                        toggle />

                                    <h3>Permissions</h3>

                                    <p className="permissions__title">Allow Saving</p>
                                    <Checkbox
                                        label={this.state.user.settings && this.state.user.settings.permitted_to_save ? 'Enabled' : 'Disabled'}
                                        checked={this.state.user.settings && this.state.user.settings.permitted_to_save}
                                        onChange={this.onAllowSavingPermissionChange}
                                        toggle />

                                    <p className="permissions__title">Video Editing</p>
                                    <Checkbox
                                        label={this.state.user.video_editor ? 'Enabled' : 'Disabled'}
                                        checked={this.state.user.video_editor}
                                        onChange={this.onAllowVideoEditingPermissionChange}
                                        toggle />

                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </div>
            )
        }
    }

    hasAnythingChanged = () => {
        return (
            this.props.add_user_projects.length > 0 ||
            this.props.remove_user_projects.length > 0
        )
    }

    getPanes = () => {
        return [
            { menuItem: { key: 'settings', content: 'User settings' }, render: () => {

                // this.onContextChange('Settings')

                let message = null
                if (!this.props.isBulkEdit && (this.props.error || this.props.success)) {
                    message = <Message
                        error={this.props.error}
                        success={this.props.success}
                        visible={true}
                        header={''}
                        content={this.getHeader(this.props.error, this.props.success)}
                    />
                }

                return (<Tab.Pane>

                    {message}
                    {this.getContent(this.props.error, this.props.success, this.props.loading, this.props.errors)}
                    <br />

                </Tab.Pane>)}, onItemClick: () => {}
            },
            { menuItem: { key: 'camera', content: 'Restrict to certain projects and cameras'  }, render: () => {

                // this.onContextChange('UserProjects')

                let localModificationMessage = null
                if (this.hasAnythingChanged()) {
                    localModificationMessage = <Message
                        error={false}
                        success={false}
                        visible={true}
                        header={''}
                        content={'Click Confirm to save your changes.'}
                    />
                }

                let unassociationProjectMessage = null
                if ( !this.props.isBulkEdit && (this.props.unassociate_project_with_user.error || this.props.unassociate_project_with_user.success)) {
                    unassociationProjectMessage = <Message
                        onDismiss={() => {
                            this.props.resetUnassociateProjectWithUser()
                        }}
                        error={this.props.unassociate_project_with_user.error}
                        success={this.props.unassociate_project_with_user.success}
                        visible={true}
                        header={''}
                        content={(this.props.unassociate_project_with_user.error) ? "Error unassociating project with user" : (this.props.unassociate_project_with_user.success) ? this.props.unassociate_project_with_user.message : ''}
                    />
                }

                let associationProjectMessage = null
                if (!this.props.isBulkEdit && (this.props.associate_project_with_user.error || this.props.associate_project_with_user.success)) {
                    associationProjectMessage = <Message
                        onDismiss={() => {
                            this.props.resetAssociateProjectWithUser()
                        }}
                        error={this.props.associate_project_with_user.error}
                        success={this.props.associate_project_with_user.success}
                        visible={true}
                        header={''}
                        content={(this.props.associate_project_with_user.error) ? "Error associating project with user" : (this.props.associate_project_with_user.success) ? this.props.associate_project_with_user.message : ''}
                    />
                }

                if (!this.isReady()) {

                    return (
                        <Tab.Pane>
                            <div>
                                <div className='animated-blank-line'></div>
                                <div className='animated-blank-block-75'></div>
                                <div className='animated-blank-line'></div>
                            </div>
                        </Tab.Pane>)
                } else {

                    return (<Tab.Pane>

                        {localModificationMessage}
                        {unassociationProjectMessage}
                        {associationProjectMessage}

                        <UserProjectsAndCamerasSelector
                            auth={this.props.auth}
                            loadingProjectCameras={this.props.cameras.loading}
                            loadingProjectCamerasProjectId={this.props.cameras.projects_id}
                            cameras={this.props.cameras.cameras}
                            userCameras={this.props.user_cameras.cameras}
                            isBulkEdit={this.props.isBulkEdit}
                            user_project_cameras={
                                (() => {

                                    let items = []

                                    if (!this.props.isBulkEdit) {
                                        items = this.props.user_projects.projects.slice()
                                    }

                                    items = items.concat(this.props.add_user_projects)
                                    items = items.filter((elem) => {
                                        return this.props.remove_user_projects.findIndex((e) => {
                                            return e.id === elem.id
                                        }) === -1
                                    })

                                    let cams = this.props.user_cameras.cameras.slice()

                                    cams = cams.concat(this.props.add_user_cameras)
                                    cams = cams.filter((elem) => {
                                        return this.props.remove_user_cameras.findIndex((e) => {
                                            return e.id === elem.id
                                        }) === -1
                                    })

                                    items = items.concat(cams)
                                    items = items.map((elem, idx) => {
                                        return Object.assign(
                                            {},
                                            elem,
                                            {
                                                key: elem.id,
                                                text: elem.pot_id || elem.name
                                            }
                                        )
                                    })
                                    // Okay, this is sorting essentially by anything available...
                                    items = items.sort((a, b) => {

                                        if ('weight' in a && 'weight' in b) {
                                            if (a.weight < b.weight) {
                                                return -1
                                            }
                                            if (a.weight > b.weight) {
                                                return 1
                                            }
                                            return 0
                                        } else if ('weight' in a && 'default_weight' in b) {
                                            if (a.weight < b.default_weight) {
                                                return -1
                                            }
                                            if (a.weight > b.default_weight) {
                                                return 1
                                            }
                                            return 0
                                        } else if ('default_weight' in a && 'weight' in b) {
                                            if (a.default_weight < b.weight) {
                                                return -1
                                            }
                                            if (a.default_weight > b.weight) {
                                                return 1
                                            }
                                            return 0
                                        } else {
                                            if ('default_weight' in a < 'default_weight' in b) {
                                                return -1
                                            }
                                            if (a.default_weight > b.default_weight) {
                                                return 1
                                            }
                                            return 0
                                        }

                                    })

                                    if (this.props.project_camera_weights && this.props.project_camera_weights.length > 0) {
                                        // If here, this sorting should ultimately take priority,
                                        // as it reflects the very latest local changes
                                        items = items.sort((a, b) => {

                                            const aWeighting = this.props.project_camera_weights.find((i) => {
                                                if ('pot_id' in a || 'pot_id' in i) {
                                                    if ('pot_id' in i && 'pot_id' in a) {
                                                        return a.id === i.id
                                                    } else {
                                                        return false
                                                    }
                                                } else {
                                                    return a.id === i.id
                                                }
                                            })

                                            const bWeighting = this.props.project_camera_weights.find((i) => {
                                                if ('pot_id' in b || 'pot_id' in i) {
                                                    if ('pot_id' in i && 'pot_id' in b) {
                                                        return b.id === i.id
                                                    } else {
                                                        return false
                                                    }
                                                } else {
                                                    return b.id === i.id
                                                }
                                            })

                                            if (aWeighting && bWeighting) {
                                                // Should always be the case
                                                if (aWeighting.weight < bWeighting.weight) {
                                                    return -1
                                                }
                                                if (aWeighting.weight > bWeighting.weight) {
                                                    return 1
                                                }
                                                return 0


                                            } else {
                                                return -1
                                            }

                                        })

                                    }
                                    return items

                                })()
                            }

                            onLoadProjectCameras={(project) => {
                                this.props.onLoadProjectCameras(project)
                            }}

                            onResetProjectCameras = {this.props.onResetProjectCameras}

                            onAddUserCameras={(cameras) => {

                                // Okay, so we only want to add cameras not already previously linked to user
                                const camerasFiltered = cameras.filter((elem) => {
                                    return this.props.user_cameras.cameras.findIndex((e) => {
                                        return elem.id === e.id
                                    }) === -1
                                })

                                // We also only want to add a camera not already added...
                                const camerasFilteredUnique = camerasFiltered.filter((camera) => {
                                    return (this.props.add_user_cameras.findIndex((elem) => {
                                        return elem.id === camera.id
                                    }) === -1)
                                })

                                // Let's add the latest array to the existing one
                                const addUserCameras = [...this.props.add_user_cameras, ...camerasFilteredUnique]
                                this.props.onAddUserCameras(addUserCameras)

                                // Let's also tidy up the remove cameras array, if necessary
                                let removeUserCameras = this.props.remove_user_cameras.slice()
                                let changed = false

                                cameras.forEach((camera) => {
                                    const key =
                                        removeUserCameras.findIndex((elem) => {
                                            return elem.id === camera.id
                                        })

                                    if (key !== -1) {
                                        changed = true
                                        // We can tidy up a little...
                                        removeUserCameras.splice(key, 1)
                                    }
                                })

                                if (changed) {
                                    this.props.onRemoveCameras(removeUserCameras)
                                }
                            }}

                            onAddProject={(project) => {
                                if (this.props.add_user_projects.findIndex((elem) => {
                                        return elem.id === project.id
                                    }) === -1) {
                                    
                                    this.props.onAddUserProjects([...this.props.add_user_projects, project])
                                }
                            }}

                            onRemoveProject={(project) => {
                                if (this.props.remove_user_projects.findIndex((elem) => {
                                        return elem.id === project.id
                                    }) === -1) {

                                    // So, we only need to add items that are actually already linked to user
                                    if (this.props.user_projects.projects.findIndex((elem) => {
                                            return elem.id === project.id
                                        }) !== -1) {

                                        this.props.onRemoveProjects([...this.props.remove_user_projects, project])

                                    }
                                }

                                // Let's also update the add_user_projects too, if necessary
                                const key = this.props.add_user_projects.findIndex((elem) => {
                                    return elem.id === project.id
                                })
                                if (key !== -1) {
                                    let projs = this.props.add_user_projects.slice()
                                    projs.splice(key, 1)
                                    this.props.onAddUserProjects(projs)
                                }
                            }}

                            onRemoveCamera={(camera) => {
                                if (this.props.remove_user_cameras.findIndex((elem) => {
                                        return elem.id === camera.id
                                    }) === -1) {

                                    // We only need to add here if the already linked to user..
                                    if (this.props.user_cameras.cameras.findIndex((elem) => {
                                            return elem.id === camera.id
                                        }) !== -1) {

                                        this.props.onRemoveCameras([...this.props.remove_user_cameras, camera])
                                    }
                                }

                                // Let's also update add_user_cameras too, if necessary
                                const key = (this.props.add_user_cameras.findIndex((elem) => {
                                    return elem.id === camera.id
                                }))
                                if (key !== -1) {
                                    let cams = this.props.add_user_cameras.slice()
                                    cams.splice(key, 1)
                                    this.props.onAddUserCameras(cams)
                                }

                            }}

                            onUpdateProjectCameraWeights={this.props.onUpdateProjectCameraWeights}

                            projects={
                                (() => {
                                    let p = this.props.projects.projects.map((elem, idx) => {
                                        return Object.assign(
                                            {},
                                            elem,
                                            {
                                                key: idx,
                                                text: elem.name
                                            }
                                        )
                                    })

                                    p = p.filter((elem) => {

                                        const notAddedToUserProjects = this.props.add_user_projects.findIndex((e) => {
                                            return elem.id === e.id
                                        }) === -1

                                        const notMarkedForRemoval = this.props.remove_user_projects.findIndex((e) => {
                                            return elem.id === e.id
                                        }) === -1

                                        // const notExistingUserProject = this.props.user_projects.projects.findIndex((e) => {
                                        //     return elem.id === e.id
                                        // }) === -1

                                        if (notAddedToUserProjects /*&& notExistingUserProject*/) {
                                            return true
                                        } else if (/*!notExistingUserProject &&*/ !notMarkedForRemoval) {
                                            return true
                                        } else {
                                            return false
                                        }

                                    })

                                    return p

                                })()
                            }
                        />

                    </Tab.Pane>)

                }}},
            
        ]
    }

    render = () => {
        return (
            <div className='update-user'>
                <Tab panes={this.getPanes()} onTabChange={(event, value) => {
                    const ctxt = (value.panes[value.activeIndex].menuItem.key === 'camera') ? "UserProjects" : "Settings"
                    this.onContextChange(ctxt)
                }}/>
            </div>
        )
    }
}

export default UpdateBulkUsers
