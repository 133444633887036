import React, { Component } from 'react'
import {
    Button,
    Modal,
    Message,
    Form,
    Select
    } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { getErrorMessage } from './../../util/errors'
import flatten from './../../util/flattenObject'
import LogoUploader from "../LogoUploader";

class UpdateClientModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            name: "",
            status: props.client.status,
            role: "Service Client",
            uploadable: false,
            show_suspended_modal: false,
            mfaRequirement: 'Unavailable'
        }
    }

    getInitialConfig() {
        return {
            name: "",
            status: "Active",
            role: "Service Client",
            uploadable: false,
            mfaRequirement: 'Unavailable'
        }
    }

    onOpen = (event, data) => {
        this.props.editClientActions.resetErrors()
        this.props.logoActions.reset()
        this.props.editClientActions.clientDetailsRequest(this.props.clientId)
    }

    onClose = () => {
        this.setState(this.getInitialConfig(), () => {
            this.props.onClose()
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.success === true && prevProps.success === false) {
            this.onClose()
        } else {
            if (this.props !== prevProps) {
                this.setState((prevState, props) => {
                    return {
                        name: props.client.name,
                        role: props.client.role,
                        status: props.client.status,
                        uploadable: false,
                        mfa_requirement: props.client.mfaRequirement
                    }
                })
            }
        }
    }

    onNameChange = (event, {value}) => {
        this.setState({name: value})
    }

    onRoleChange = (event, {value}) => {
        this.setState({role: value})
    }

    onStatusChange = (event, {value}) => {
        if (value === 'Suspended') {
            this.setState({
                status: value,
                show_suspended_modal: true,
                status_old: this.state.status
            })
        } else {
            this.setState({status: value})
        }
    }

    onMfaChange = (event, {value}) => {
        this.setState({mfaRequirement: value})
    }

    getHeader = (error, success) => {
        if (error) {
            return <p>Error</p>
        } else if (success) {
            return <p>Success!</p>
        } else {
            return ""
        }
    }

    getContent = (error, success, loading, errors) => {
        // We might have a server error, or just regular errors
        if (error) {
            return this.renderForm()
        } else if (success) {
            return null
        } else if (loading) {
            return this.renderForm()
        } else {
            // Must be initial loading...
            return this.renderForm()
        }
    }

    onUploadable = (status) => {
        this.setState({uploadable: status})
    }

    onSuspendedModalConfirmButtonClick = () => {
        this.setState({show_suspended_modal: false})
    }

    onSuspendedModalCloseButtonClick = () => {
        this.setState({
            show_suspended_modal: false,
            status: this.state.status_old
        })
    }

    getSuspendedModalConfirmButton = () => {
        return <Button id='suspendedModalConfirmButton' onClick={this.onSuspendedModalConfirmButtonClick} positive>Confirm</Button>
    }

    getSuspendedModalCloseOrCancelButton = () => {
        return <Button onClick={this.onSuspendedModalCloseButtonClick} negative>Cancel</Button>
    }

    getSuspendedModal = () => {
        return (
            <Modal size={"tiny"} open={this.state.show_suspended_modal} onClose={this.onCloseSuspendedModal} closeIcon>
                <Modal.Header>Suspend Client</Modal.Header>
                <Modal.Content>
                    <h4>Are you sure you want to suspend this client?</h4>
                    <p>Whilst suspended, no users belonging to this client may access the viewer.</p>
                </Modal.Content>
                <Modal.Actions>
                    {this.getSuspendedModalCloseOrCancelButton()}
                    {this.getSuspendedModalConfirmButton()}
                </Modal.Actions>
                <br/>
            </Modal>
        )
    }

    renderForm = () => {
        let isLobsterUser = (this.props.user && (this.props.user.roles_id === 4 || this.props.user.roles_id === 5)) ? false : true;
        if (!this.props.client.name) {
            return <p>Loading...</p>
        }
        return (
            <Form size='large'>
                <Form.Input
                        fluid
                        label='Name'
                        placeholder='Client Name'
                        onChange={this.onNameChange}
                        defaultValue={this.props.client.name}
                        error={(this.props.errors.name) ? true : false}
                    />
                    <Message
                        error={true}
                        visible={this.props.errors.name !== undefined}
                        header={this.props.errors.name}
                        content={getErrorMessage(this.props.errors, 'name')}
                    />
                    {isLobsterUser && <>
                        <Form.Field
                            label='Status'
                            control={Select}
                            options={this.props.statuses}
                            placeholder='Status'
                            onChange={this.onStatusChange}
                            value={this.state.status}
                            error={(this.props.errors.status) ? true: false}
                        />
                        <Message
                            error={true}
                            visible={this.props.errors.status !== undefined}
                            header={this.props.errors.status}
                            content={getErrorMessage(this.props.errors, 'status')}
                        />
                        <Form.Field
                            label='Relationship'
                            control={Select}
                            options={this.props.roles}
                            placeholder='Role'
                            onChange={this.onRoleChange}
                            defaultValue={this.props.client.role}
                            error={(this.props.errors.role) ? true: false}
                        />
                        <Message
                            error={true}
                            visible={this.props.errors.role !== undefined}
                            header={this.props.errors.role}
                            content={getErrorMessage(this.props.errors, 'role')}
                        />
                        <Form.Field
                            label='MFA Status'
                            control={Select}
                            options={this.props.mfaOptions}
                            onChange={this.onMfaChange}
                            defaultValue={this.props.client.mfaRequirement}
                        />
                    </>}
                    <div className='logo-uploader-container'>
                        <LogoUploader
                            client={this.props.client}
                            editClientActions={this.props.editClientActions}
                            logoActions={this.props.logoActions}
                            logo={this.props.logo}
                            onUploadable={this.onUploadable}
                            user={this.props.user}
                        />

                    </div>
                    {this.getSuspendedModal()}
            </Form>
        )
    }

    getButtons = (error, success) => {
        if (success) {
            return null
        } else {
            return (
                <div>
                    {this.props.removeClientLink}
                    <Button disabled={!this.isReady() || this.state.uploadable} onClick={() => { this.props.onClick(this.props.client.id, flatten(this.state))}} loading={this.props.loading} positive icon='checkmark' labelPosition='right' content='Update' />
                </div>
            )
        }
    }

    isReady = () => {
        return this.props.clientId || this.props.client.name
    }

    getPreloader = () => {
        if (!this.isReady()) {
            return (
                <>
                    <div className='logo-uploader-container'>
                        <LogoUploader
                            client={this.props.client}
                            editClientActions={this.props.editClientActions}
                            logoActions={this.props.logoActions}
                            logo={this.props.logo}
                        />
                    </div>
                    <div className="animated-blank-line" />
                    <div className="animated-blank-line" />
                    <div className="animated-blank-line" />
                    <div className="animated-blank-line" />
                </>
            )
        }
        return false
    }

    render = () => {
        return (
            <Modal
                size={"small"}
                open={this.props.open}
                onOpen={this.onOpen}
                onMount={this.onOpen}
                onClose={this.props.onClose}
                closeIcon
            >
                <Modal.Header>Update client</Modal.Header>
                <Modal.Content>
                    {this.getPreloader()}

                    {this.getContent(this.props.error, this.props.success, this.props.loading, this.props.errors)}

                    <div className='modalButtons'>
                        {this.getButtons(this.props.error, this.props.success)}
                    </div>
                </Modal.Content>
            </Modal>
        )
    }

}

UpdateClientModal.propTypes = {
    editClientActions: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    logoActions: PropTypes.object.isRequired,
    logo: PropTypes.object.isRequired
}


export default UpdateClientModal
