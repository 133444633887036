import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Message, Grid, Input } from 'semantic-ui-react'
import PasswordInput from "./forms/PasswordInput";

import { getErrorMessage } from './../util/errors'

class UploadSettings extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    isProcessing = () => {
        return this.props.camera_control.update_system.processing
        && (
            'upload_username' in this.props.camera_control.update_system.properties ||
            'upload_password' in this.props.camera_control.update_system.properties ||
            'upload_host' in this.props.camera_control.update_system.properties
            )
    }

    isButtonDisabled = () => {
        if (this.isProcessing()) {
            return true
        }
        return !(this.state.upload_username || this.state.upload_password || this.state.upload_host) ? true : false
    }

    onClick = () => {

        const properties = this.state

        this.props.cameraControlActions.updateSystem(this.props.cameras_id, properties).then(() => {
            this.props.cameraControlActions.fetchSystem(this.props.cameras_id)
        })

    }

    isReady = () => {
        return this.props.camera_control.system.details
            && this.props.camera_control.system.details.upload_username
            && this.props.camera_control.system.details.upload_password
            && this.props.camera_control.system.details.upload_host

    }

    handleDismiss = () => {
        this.setState({ visible: false })

        setTimeout(() => {
            this.setState({ visible: true })
        }, 2000)
    }

    getError = () => {
        if (!this.props.camera_control.update_system.error) {
            return null
        }
        let message = getErrorMessage(this.props.camera_control.update_system.errors, 'cameras_id')
        if (!message) {
            message = getErrorMessage(this.props.camera_control.update_system.errors, 'unknownErrorType')
        }
        return (message) ? message : "There was an unexpected error"
    }

    isMessageRelatedToUploadSettings = () => {
        return (this.props.camera_control.update_system.error || this.props.camera_control.update_system.success)
            &&  (
                'upload_dir' in this.props.camera_control.update_system.properties ||
                'upload_username' in this.props.camera_control.update_system.properties ||
                'upload_password' in this.props.camera_control.update_system.properties ||
                'upload_host' in this.props.camera_control.update_system.properties
            )
    }

    getMessage = (blurb) => {
        return (this.isMessageRelatedToUploadSettings()) ?
            <Message positive={this.props.camera_control.update_system.success} negative={this.props.camera_control.update_system.error}

                     onDismiss={this.onDismissMessage}

            >
                <Message.Header>{(this.props.camera_control.update_system.error) ? 'Error' : 'Success'}</Message.Header>
                <p>{blurb}</p>
            </Message> : null
    }

    getBlurb = () => {
        if (this.props.camera_control.update_system.error) {
            return this.getError()
        }
        if (this.props.camera_control.update_system.success) {
            return 'Successfully updated Upload settings'
        }
        return null
    }

    onDismissMessage = () => {
        this.props.cameraControlActions.resetUpdateSystem()
    }

    onChangeUploadDir = (evt, {value}) => {
        this.setState({upload_dir: value})
    }

    onChangeUsername = (evt, {value}) => {
        this.setState({upload_username: value})
    }

    onChangePassword = (password) => {
        this.setState({upload_password: password})
    }

    onChangeServer = (evt, {value}) => {
        this.setState({upload_host: value })
    }

    getForm = () => {

        const uploadDirInput = (this.props.camera_control.system.details && this.props.camera_control.system.details.upload_dir) ?

            <div>
                <label>Upload Path</label>
                <Input
                    fluid
                    defaultValue={this.props.camera_control.system.details.upload_dir}
                    placeholder='Upload Path'
                    onChange={this.onChangeUploadDir}
                />
            </div>  : null

        const uploadUsernameInput = (this.props.camera_control.system.details && this.props.camera_control.system.details.upload_username) ?

            <div>
                <label>Upload Username</label>
                <Input
                    fluid
                    defaultValue={this.props.camera_control.system.details.upload_username}
                    placeholder='Upload Username'
                    onChange={this.onChangeUsername}
                />
            </div>   : null

        const uploadPasswordInput = (this.props.camera_control.system.details && this.props.camera_control.system.details.upload_password) ?
           <div>
                <label>Upload Password</label>
                <PasswordInput
                    processing={false}
                    fluid
                    value={this.props.camera_control.system.details.upload_password}
                    onChange={this.onChangePassword} />
           </div> : null

        const uploadHostInput = (this.props.camera_control.system.details && this.props.camera_control.system.details.upload_host) ?
           <div>
                <label>Upload Server</label>
                <Input
                    fluid
                    defaultValue={this.props.camera_control.system.details.upload_host}
                    placeholder='Upload Server'
                    onChange={this.onChangeServer}
                />
           </div> : null

        return (<div>
                {uploadHostInput}
                {uploadUsernameInput}
                {uploadPasswordInput}
                {uploadDirInput}
            </div>
            )

    }

    render = () => {

        if (!this.isReady()) {

            return (
                <div className='upload-settings'>
                    {/*<Dimmer active inverted><Loader content='' /></Dimmer>*/}
                    <Grid columns='1'>
                        <Grid.Row>
                            <Grid.Column><div className="animated-blank-block-200" /></Grid.Column>
                            <Grid.Column><div className="animated-blank-line" /></Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            )

        } else {
            return (
                <div className='upload-settings'>
                    {this.getMessage(this.getBlurb())}
                    <Grid columns='1'>
                        <Grid.Row>
                            <Grid.Column>
                                {this.getForm()}
                                <Button
                                    fluid
                                    loading={this.isProcessing()}
                                    disabled={this.isButtonDisabled()}
                                    onClick={this.onClick}>Update Upload Settings</Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            )
        }
    }
}

UploadSettings.propTypes = {
    cameraControlActions: PropTypes.object.isRequired,
    cameras_id: PropTypes.string.isRequired,
    camera_control: PropTypes.object.isRequired
}

export default UploadSettings
