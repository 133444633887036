import initialState from './initialState';
import {
    USER_LIST_REQUEST,
    USER_LIST_RESPONSE,
    USER_LIST_ERRORS,
    USER_LIST_RESET,
    USER_LIST_BULK_EDIT,
    USER_LIST_BULK_EDIT_REMOVE,
    USER_LIST_BULK_EDIT_RESET,
    USER_LIST_BULK_EDIT_SELECT,
    USERS_WITH_PROJECT_ACCESS_REQUEST,
    USERS_WITH_PROJECT_ACCESS_SUCCESS,
    USERS_WITH_PROJECT_ACCESS_ERROR,
    USER_LIST_FILTER_BY,
    USER_LIST_SORT_BY
} from '../actions/actionTypes';

export default function users(state = initialState.users, action) {

    switch (action.type) {
        case USER_LIST_REQUEST:
            return Object.assign({}, state, { loading: true })
        case USER_LIST_RESPONSE:
            return Object.assign({}, state, {
                errors: {error: false},
                tableData: action.tableData,
                totalRows: action.totalRows,
                loading: false,
                currentPage: action.currentPage,
                filtered: action.filtered,
                sortColumn: action.sortColumn,
                sortAscending: action.sortAscending,
                searchTerm: action.searchTerm,
                searchField: action.searchField
            })
        case USER_LIST_ERRORS:
            return Object.assign({}, state, {
                errors: {error: true, message:"Unable to load user list"},
                tableData: [],
                totalRows: 0,
                loading: false,
                filtered: action.filtered
            })
        case USER_LIST_RESET:
            return Object.assign({}, state, {
                errors: {error: false},
                loading: false,
                filtered: false,
                searchTerm: null,
                tableData: []
            })
        case USER_LIST_BULK_EDIT:
            return Object.assign({}, state, {
                usersToEdit: [...state.usersToEdit, action.usersToEdit]
            })
        case USER_LIST_BULK_EDIT_REMOVE:
            return Object.assign({}, state, {
                usersToEdit: state.usersToEdit.filter(row =>
                    row !== action.userToRemove
                )
            })
        case USER_LIST_BULK_EDIT_RESET:
            return Object.assign({}, state, {
                usersToEdit: []
            })
        case USER_LIST_BULK_EDIT_SELECT:
            return Object.assign({}, state, {
                selectedUserClientId: action.selectedUserClientId
            })
        case USERS_WITH_PROJECT_ACCESS_REQUEST:
            return Object.assign({}, state, {
                errors: {error: false},
                loading: true,
                filtered: false,
                searchTerm: null,
            })
        case USERS_WITH_PROJECT_ACCESS_SUCCESS:
            return Object.assign({}, state, {
                errors: {error: false},
                loading: false,
                tableData: action.users,
                exportData: action.exportData,
                totalRows: action.totalRows,
                currentPage: action.currentPage,
            })
        case USERS_WITH_PROJECT_ACCESS_ERROR:
            return Object.assign({}, state, {
                errors: {
                    error: true,
                    message: action.error
                },
                loading: false,
                tableData: [],
                totalRows: 0
            })
        case USER_LIST_FILTER_BY:
            return Object.assign(
                {},
                state,
                {
                    searchTerm: action.searchTerm,
                    searchField: action.searchField
                }
            )
        case USER_LIST_SORT_BY:
            return Object.assign(
                {},
                state,
                {
                    sortColumn: action.sortColumn,
                    sortAscending: action.ascending
                }
            )
        default:
            return state
    }
}
