import initialState from './initialState'
import {
    POT_ASSIGN_FETCH_PCIDS_REQUEST,
    POT_ASSIGN_FETCH_PCIDS_ERRORS,
    POT_ASSIGN_FETCH_PCIDS_SUCCESS,
    POT_ASSIGN_IDENTIFY_REQUEST,
    POT_ASSIGN_IDENTIFY_ERROR,
    POT_ASSIGN_IDENTIFY_SUCCESS,
    POT_ASSIGN_NEXT_POT_REQUEST,
    POT_ASSIGN_NEXT_POT_ERROR,
    POT_ASSIGN_NEXT_POT_SUCCESS,
    POT_ASSIGN_ASSIGN_REQUEST,
    POT_ASSIGN_ASSIGN_ERROR,
    POT_ASSIGN_ASSIGN_SUCCESS,
    POT_ASSIGN_RESET
} from '../actions/actionTypes'

export default function potAssign(state = initialState.potAssign, action) {
    switch (action.type) {
        case POT_ASSIGN_FETCH_PCIDS_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    loading: true,
                    success: false,
                    error: false,
                    errors: {},
                    pcids: []
                }
            )
        case POT_ASSIGN_FETCH_PCIDS_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    success: false,
                    error: true,
                    errors: action.errors,
                    pcids: []
                }
            )
        case POT_ASSIGN_FETCH_PCIDS_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    success: true,
                    error: false,
                    errors: {},
                    pcids: action.pcids
                }
            )
        case POT_ASSIGN_IDENTIFY_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    identifying: action.pcid
                }
            )
        case POT_ASSIGN_IDENTIFY_ERROR:
            return Object.assign(
                {},
                state,
                {
                    identifying: false
                }
            )
        case POT_ASSIGN_IDENTIFY_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    identifying: false
                }
            )
        case POT_ASSIGN_NEXT_POT_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    gettingNextPot: true
                }
            )
        case POT_ASSIGN_NEXT_POT_ERROR:
            return Object.assign(
                {},
                state,
                {
                    gettingNextPot: false
                }
            )
        case POT_ASSIGN_NEXT_POT_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    gettingNextPot: false
                }
            )
        case POT_ASSIGN_ASSIGN_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    assigning: true
                }
            )
        case POT_ASSIGN_ASSIGN_ERROR:
            return Object.assign(
                {},
                state,
                {
                    assigning: false
                }
            )
        case POT_ASSIGN_ASSIGN_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    assigning: false
                }
            )
        case POT_ASSIGN_RESET:
            return initialState.potAssign
        default:
            return state
    }
}
