import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Button,
    Modal
    } from 'semantic-ui-react'
import CreateUser from "../CreateUser";
import { withRouter } from 'react-router-dom'

class CreateUserModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            activeIndex: 0,
        }
        this.fromProject = false;
        this.fromProjectId = null;
    }

    onOpen = (event, data) => {
        this.props.addUserActions.resetErrors()
        this.props.ucpaActions.resetAll()
        if (this.props.clientId) {
            this.props.ucpaActions.fetchProjects(this.props.clientId)
        }
    }

    componentDidMount() {
        if (this.props.location.state && this.props.location.state.fromProject){
            this.fromProject = this.props.location.state.fromProject
        }
        if (this.props.location.state && this.props.location.state.fromProjectId) {
            this.fromProjectId = this.props.location.state.fromProjectId
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.clientId && this.props.clientId !== prevProps.clientId) {
            this.props.ucpaActions.fetchProjects(this.props.clientId)
        }
    }

    onClose = () => {
        this.props.ucpaActions.resetAll()
        this.props.onClose()
    }

    isPartialSuccess = () => {
        return (this.props.addUser.errors.sendVerificationEmail);
    }

    getButtons = (error, success) => {
        if (success) {
            return <div><Button onClick={this.props.onClose} positive primary>Close</Button></div>
        } else if (this.isPartialSuccess()) {
            return <div><Button onClick={this.props.onClose} positive primary>Close</Button></div>
        } else if (this.fromProject && this.fromProjectId && this.state.activeIndex === 0) {
           return <div><Button positive primary onClick={() => {this.setState({activeIndex: 1})}}>Next</Button></div>
        } else {
            return <div>
                {/*<Button onClick={this.props.onClose}>Cancel</Button>*/}
                <Button onClick={() => {
                    const args = this.props.addUser.user
                    this.handleCreateUserConfirmedClick(args)
                }} loading={this.props.addUser.loading} positive icon='checkmark' labelPosition='right' content='Create' />
            </div>
        }
    }

    // Child component will pass back active tab on tab click
    onActiveTabChange = (activeIndex) => {
        this.setState({activeIndex: activeIndex})
    }

    render = () => {

        return (
            <Modal
                size={"large"}
                open={this.props.open}
                onOpen={this.onOpen}
                onMount={this.onOpen}
                onClose={this.props.onClose}
                closeIcon
            >
                <Modal.Header>Create a user</Modal.Header>
                <Modal.Content>
                    <CreateUser
                        auth={this.props.auth}
                        error={this.props.addUser.error}
                        errors={this.props.addUser.errors}
                        clients={this.props.clients}
                        clientId={this.props.clientId}
                        roles={this.props.roles}
                        statuses={this.props.statuses}
                        success={this.props.addUser.success}
                        loading={this.props.addUser.loading} // processing, surely?
                        details={this.props.addUser.details}
                        ucpa_completed={this.props.addUser.ucpa_completed}
                        user={this.props.addUser.user}
                        onUpdateLocalUserState={(user) => {
                            this.props.addUserActions.updateLocalUserState(user)
                        }}
                        // Additional fields for associating projects and cameras
                        cameras={this.props.ucpa.cameras}
                        projects={this.props.ucpa.projects}
                        user_cameras={this.props.ucpa.user_cameras}
                        user_projects={this.props.ucpa.user_projects}
                        add_user_cameras={this.props.ucpa.add_user_cameras}
                        remove_user_cameras={this.props.ucpa.remove_user_cameras}
                        add_user_projects={this.props.ucpa.add_user_projects}
                        remove_user_projects={this.props.ucpa.remove_user_projects}
                        project_camera_weights={this.props.ucpa.project_camera_weights}
                        unassociate_project_with_user={this.props.ucpa.unassociate_project_with_user}
                        unassociate_camera_with_user={this.props.ucpa.unassociate_camera_with_user}
                        associate_project_with_user={this.props.ucpa.associate_project_with_user}
                        associate_camera_with_user={this.props.ucpa.associate_camera_with_user}
                        onLoadProjectCameras={(clientId, projectId) => {
                            this.props.ucpaActions.fetchProjectCameras(clientId, projectId)
                        }}
                        onResetProjectCameras={this.props.ucpaActions.resetProjectCameras}
                        onAddProject={() => {}}
                        onRemoveProject={() => {}}
                        onRemoveCamera={() => {}}
                        // Added to support new functionality
                        onClientChange={(clientId) => {
                            // Let's request the projects for the client...
                            this.props.ucpaActions.fetchProjects(clientId)

                        }}
                        ucpa_processing={this.props.ucpa.processing}
                        ucpa_loading={this.props.ucpa.projects.loading}
                        resetUnassociateProjectWithUser={() => {
                            this.props.ucpaActions.resetUnassociateProjectWithUser()
                        }}
                        resetUnassociateCameraWithUser={() => {
                            this.props.ucpaActions.resetUnassociateCameraWithUser()
                        }}
                        resetAssociateProjectWithUser={() => {
                            this.props.ucpaActions.resetAssociateProjectWithUser()
                        }}
                        resetAssociateCameraWithUser={() => {
                            this.props.ucpaActions.resetAssociateCameraWithUser()
                        }}
                        onAddUserProjects={(projects) => {
                            this.props.ucpaActions.addUserProjects(projects)
                        }}
                        onRemoveProjects={(projects) => {
                            this.props.ucpaActions.removeUserProjects(projects)
                        }}
                        onAddUserCameras={(cameras) => {
                            this.props.ucpaActions.addUserCameras(cameras)
                        }}
                        onRemoveCameras={(cameras) => {
                            this.props.ucpaActions.removeUserCameras(cameras)
                        }}
                        onUpdateProjectCameraWeights={(projectCameraWeights) => {
                            this.props.ucpaActions.updateProjectCameraWeights(projectCameraWeights)
                        }}
                        adminUserRolesRequest={() => {
                            this.props.addUserActions.adminUserRolesRequest()
                        }}
                        adminUserStatusesRequest={() => {
                            this.props.addUserActions.adminUserStatusesRequest()
                        }}
                        adminUserClientsRequest={() => {
                            this.props.addUserActions.adminUserClientsRequest()
                        }}
                        fromProject={this.fromProject}
                        fromProjectId={this.fromProjectId}
                        activeIndex={this.state.activeIndex}
                        activeTabChange={this.onActiveTabChange}
                    />
                    <div className='modalButtons'>
                        {this.getButtons(this.props.addUser.error, this.props.addUser.success)}
                    </div>
                </Modal.Content>
            </Modal>
        )
    }

    handleCreateUserConfirmedClick = (args) => {
        this.props.addUserActions.clearErrors()
        this.props.addUserActions.addUserRequest(null, args).then(() => {
            if (this.props.addUser.success
                || (this.props.addUser.error && this.props.addUser.error.sendVerificationEmail)) {
                // Let's handle the associations now...
                this.props.ucpaActions.clearSuccessAndErrorMessages()

                // Could trigger a global loading state here....to avoid continuous re-rendering inside modal
                this.props.ucpaActions.processing(true)

                // Okay, so the "state" is held in Redux....we just need to apply it

                // 1. Add new relationships between user and project and user and camera
                // 2. Update the weights on each relationship between user and camera and user and project

                const userId = this.props.addUser.details.id
                const clientId = this.props.addUser.details.clients_id

                // If a Project Admin has a project restriction and creates a new users
                // the project restriction is inherited by the new user
                // this is processed as part of the addUserRequest call
                // So at this stage the user may have inhreited multiple projects restrictions
                // We need to remove the restrictions that aren't relevent
                // IF the restrictions have been overridden in the create user window
                // I know....sorry. (restrictions)

                // Total projects the auth'd user has access to
                const projects = this.props.ucpa.projects.projects
                // Projects that have been selected to add to user
                const projectsToAddToUser = this.props.ucpa.add_user_projects
                // Projects that the auth'd user has access to, minus the project to add to user
                const projectIDs = new Set(projectsToAddToUser.map(({ id }) => id));
                const projectsToRemoveFromUser = projects.filter(({ id }) => !projectIDs.has(id));

                let removeUserProjects = []
                let addUserProjects = []
                let addUserCameras = []

                // If the auth'd user is Project Admin that has restrictions then remove projects
                if (this.props.auth.user && parseInt(this.props.auth.user.roles_id) === 4 && projectsToRemoveFromUser.length !== projects.length && (projects.length < this.props.projectsTotalRows)) {
                    removeUserProjects = projectsToRemoveFromUser.map((project) => {
                        const projectId = project.id
                        return this.props.ucpaActions.unassociateProjectWithUser(clientId, projectId, userId)
                    })
                } else {
                    addUserProjects = this.props.ucpa.add_user_projects.map((project) => {
                        const projectId = project.id
                        return this.props.ucpaActions.associateProjectWithUser(clientId, projectId, userId)
                    })
                }

                if (this.props.ucpa.add_user_cameras) {
                    addUserCameras = this.props.ucpa.add_user_cameras.map((camera) => {
                        const cameraId = camera.id
                        const projectId = camera.projects_id
                        return this.props.ucpaActions.associateCameraWithUser(clientId, projectId, cameraId, userId)
                    })
                }

                Promise.all([...removeUserProjects, ...addUserProjects, ...addUserCameras]).then(() => {
                    const projectCameraWeights = this.props.ucpa.project_camera_weights.map((item) => {
                        if ('pot_id' in item) {
                            const cameraId = item.id
                            const projectId = item.projects_id
                            const weight = item.weight
                            return this.props.ucpaActions.changeUserCameraWeight(clientId, projectId, cameraId, userId, weight)
                        } else {
                            const projectId = item.id
                            const weight = item.weight
                            return this.props.ucpaActions.changeUserProjectWeight(clientId, projectId, userId, weight)
                        }
                    })
                    Promise.all([...projectCameraWeights]).then(() => {
                        this.props.addUserActions.ucpaCompleted()
                        this.props.onClose()
                    })
                })
            }
        })
    }

}

CreateUserModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    addUser: PropTypes.object.isRequired,
    addUserActions: PropTypes.object.isRequired,
    ucpa: PropTypes.object.isRequired,
    ucpaActions:  PropTypes.object.isRequired,
    clients:  PropTypes.array.isRequired,
    roles:  PropTypes.array.isRequired,
    statuses:  PropTypes.array.isRequired
}

//export default CreateUserModal

export default withRouter(CreateUserModal)
