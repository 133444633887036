import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Segment, Icon, Popup, Button } from 'semantic-ui-react'
import ScheduleSegment from './ScheduleSegment'

class ScheduleDay extends Component {
    constructor(props) {
        super(props)
        this.columns = []
    }

    generateColumns = () => {
        this.columns = []

        // If thre's no schedule for this day, do nothing
        if (!this.props.schedule) {
            return
        }
        this.props.schedule.forEach((period, i) => {
            period.periodIndex = i
            if (this.columns.length ===0) {
                // Nothing in any column yet, so add this to first column
                const column = [period]
                this.columns.push(column)
            } else {
                // Find a suitable home, respecting priorities
                let targetColumn = this.columns.length
                for (var c = this.columns.length-1; c >= 0; c--) {
                    const column = this.columns[c]
                    let clash = false
                    for (var p = 0; p < column.length; p++) {
                        const periodCompare = column[p]
                        if (periodCompare.start < period.stop && periodCompare.stop > period.start) {
                            clash = true
                            break
                        }
                    }
                    if (!clash) {
                        targetColumn = c
                    } else {
                        break
                    }
                }
                if (targetColumn === this.columns.length) {
                    // No suitable existing column found, create a new one
                    const column = [period]
                    this.columns.push(column)
                } else {
                    // Add to existing column
                    let column = this.columns[targetColumn]
                    if (column.start > period.stop) {
                        column.start = period.start
                        column.unshift(period)
                    } else {
                        column.stop = period.stop
                        column.push(period)
                    }
                }
            }
        })
    }

    render() {

        this.generateColumns()

        const tickStyle = {
            height: '1.5em',
            position: 'relative',
            borderBottom: '1px dotted #aaa',
            width: '97%',
            left: '1.5em'
        }

        const tickLabelStyle = {
            fontSize: '0.7em',
            position: 'absolute',
            top: '1.3em',
            left: '-3em'
        }

        const hours = Array(25).fill().map((a,h) => {
            let hour
            if (h < 10) {
                hour = "0"+h
            } else {
                hour = h
            }
            return (
                <div
                    key={`${this.props.dayName}_hour_${hour}`}
                    className='hour-marker'
                    style={tickStyle}>
                  <p style={tickLabelStyle}>{hour}:00</p>
                </div>
            )
        })

        const dayWidth = `${this.columns.length * 8.1 + 2.7}em`

        return (
            <div
                data-testid="component--schedule-day"
                style={{
                    float: "left",
                    marginRight: 0,
                    width: dayWidth,
                    minWidth: '18.9em'
                }}
            >
                <Segment attached='top' inverted>
                  {this.props.dayName}
                  <div style={{float:'right', marginTop: '-0.4em'}}>
                      <Popup
                          trigger={
                              <Button
                                circular
                                color='red'
                                icon='remove'
                                size='small'
                                onClick={() => {this.props.actions.clearDay(this.props.dayId)}}
                            />
                          }
                          content="Clear the current day"
                          basic
                      />
                      {
                          this.props.dayName !== 'Sunday' ?
                              <Popup
                                  trigger={
                                      <Button
                                        circular
                                        color='blue'
                                        icon='arrow right'
                                        size='small'
                                        onClick={() => {this.props.actions.copyToNextDay(this.props.dayId)}}
                                        data-testid="schedule-day--copy"
                                    />
                                  }
                                  content="Copy schedule to the next day"
                                  basic
                              />
                              : null
                      }
                  </div>
                </Segment>
                <Segment attached size='mini'>
                    <Button
                        icon
                        labelPosition='right'
                        size='tiny'
                        onClick={() => this.props.actions.openAddPeriodModal(this.props.dayId)}
                    >
                        Add Period
                        <Icon name='add circle' />
                    </Button>
                </Segment>
                <Segment
                    attached
                    className={this.props.loading ? 'loading' : null}
                >
                    {hours}
                    {this.columns.map((column, c) => {
                        return column.map((period, i) => {
                            return (
                                <ScheduleSegment
                                    key={`segment_${this.props.dayId}_${c}_${i}`}
                                    dayId={this.props.dayId}
                                    dayName={this.props.dayName}
                                    column={c}
                                    totalColumns={this.columns.length}
                                    period={period}
                                    actions={this.props.actions}
                                />
                            )
                        })
                    })}
                </Segment>
            </div>
        )
    }
}

ScheduleDay.propTypes = {
    actions: PropTypes.object.isRequired,
    dayId: PropTypes.number.isRequired,
    dayName: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    schedule: PropTypes.array
}
export default ScheduleDay
