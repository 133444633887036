const templates = {
    'standard77': [
        [
            {
                "json_class":"Period",
                "start":25200,
                "stop":68459,
                "interval":900,
                "actions":[
                    {
                        "json_class":"SchedulerActionShootQueue"
                    },
                    {
                        "json_class":"SchedulerActionUpload"
                    }
                ]
            }
        ],
        [
            {
                "json_class":"Period",
                "start":25200,
                "stop":68459,
                "interval":900,
                "actions":[
                    {
                        "json_class":"SchedulerActionShootQueue"
                    },
                    {
                        "json_class":"SchedulerActionUpload"
                    }
                ]
            }
        ],
        [
            {
                "json_class":"Period",
                "start":25200,
                "stop":68459,
                "interval":900,
                "actions":[
                    {
                        "json_class":"SchedulerActionShootQueue"
                    },
                    {
                        "json_class":"SchedulerActionUpload"
                    }
                ]
            }
        ],
        [
            {
                "json_class":"Period",
                "start":25200,
                "stop":68459,
                "interval":900,
                "actions":[
                    {
                        "json_class":"SchedulerActionShootQueue"
                    },
                    {
                        "json_class":"SchedulerActionUpload"
                    }
                ]
            }
        ],
        [
            {
                "json_class":"Period",
                "start":25200,
                "stop":68459,
                "interval":900,
                "actions":[
                    {
                        "json_class":"SchedulerActionShootQueue"
                    },
                    {
                        "json_class":"SchedulerActionUpload"
                    }
                ]
            }
        ],
        [
            {
                "json_class":"Period",
                "start":25200,
                "stop":68459,
                "interval":900,
                "actions":[
                    {
                        "json_class":"SchedulerActionShootQueue"
                    },
                    {
                        "json_class":"SchedulerActionUpload"
                    }
                ]
            }
        ],
        [
            {
                "json_class":"Period",
                "start":25200,
                "stop":68459,
                "interval":900,
                "actions":[
                    {
                        "json_class":"SchedulerActionShootQueue"
                    },
                    {
                        "json_class":"SchedulerActionUpload"
                    }
                ]
            }
        ]
    ],
    'standard24': [
        [
            {
                "json_class":"Period",
                "start":0,
                "stop":86399,
                "interval":900,
                "actions":[
                    {
                        "json_class":"SchedulerActionShootQueue"
                    },
                    {
                        "json_class":"SchedulerActionUpload"
                    }
                ]
            }
        ],
        [
            {
                "json_class":"Period",
                "start":0,
                "stop":86399,
                "interval":900,
                "actions":[
                    {
                        "json_class":"SchedulerActionShootQueue"
                    },
                    {
                        "json_class":"SchedulerActionUpload"
                    }
                ]
            }
        ],
        [
            {
                "json_class":"Period",
                "start":0,
                "stop":86399,
                "interval":900,
                "actions":[
                    {
                        "json_class":"SchedulerActionShootQueue"
                    },
                    {
                        "json_class":"SchedulerActionUpload"
                    }
                ]
            }
        ],
        [
            {
                "json_class":"Period",
                "start":0,
                "stop":86399,
                "interval":900,
                "actions":[
                    {
                        "json_class":"SchedulerActionShootQueue"
                    },
                    {
                        "json_class":"SchedulerActionUpload"
                    }
                ]
            }
        ],
        [
            {
                "json_class":"Period",
                "start":0,
                "stop":86399,
                "interval":900,
                "actions":[
                    {
                        "json_class":"SchedulerActionShootQueue"
                    },
                    {
                        "json_class":"SchedulerActionUpload"
                    }
                ]
            }
        ],
        [
            {
                "json_class":"Period",
                "start":0,
                "stop":86399,
                "interval":900,
                "actions":[
                    {
                        "json_class":"SchedulerActionShootQueue"
                    },
                    {
                        "json_class":"SchedulerActionUpload"
                    }
                ]
            }
        ],
        [
            {
                "json_class":"Period",
                "start":0,
                "stop":86399,
                "interval":900,
                "actions":[
                    {
                        "json_class":"SchedulerActionShootQueue"
                    },
                    {
                        "json_class":"SchedulerActionUpload"
                    }
                ]
            }
        ]
    ],
    'indoor1': [
        [],
        [
            {
                "json_class":"Period",
                "start":25200,
                "stop":68459,
                "interval":600,
                "actions":[
                    {
                        "json_class":"SchedulerActionShootQueue"
                    },
                    {
                        "json_class":"SchedulerActionUpload"
                    }
                ]
            },
            {
                "json_class":"Period",
                "start":25200,
                "stop":68459,
                "interval":300,
                "actions":[
                    {
                        "json_class":"SchedulerActionShoot"
                    }
                ]
            }
        ],
        [
            {
                "json_class":"Period",
                "start":25200,
                "stop":68459,
                "interval":600,
                "actions":[
                    {
                        "json_class":"SchedulerActionShootQueue"
                    },
                    {
                        "json_class":"SchedulerActionUpload"
                    }
                ]
            },
            {
                "json_class":"Period",
                "start":25200,
                "stop":68459,
                "interval":300,
                "actions":[
                    {
                        "json_class":"SchedulerActionShoot"
                    }
                ]
            }
        ],
        [
            {
                "json_class":"Period",
                "start":25200,
                "stop":68459,
                "interval":600,
                "actions":[
                    {
                        "json_class":"SchedulerActionShootQueue"
                    },
                    {
                        "json_class":"SchedulerActionUpload"
                    }
                ]
            },
            {
                "json_class":"Period",
                "start":25200,
                "stop":68459,
                "interval":300,
                "actions":[
                    {
                        "json_class":"SchedulerActionShoot"
                    }
                ]
            }
        ],
        [
            {
                "json_class":"Period",
                "start":25200,
                "stop":68459,
                "interval":600,
                "actions":[
                    {
                        "json_class":"SchedulerActionShootQueue"
                    },
                    {
                        "json_class":"SchedulerActionUpload"
                    }
                ]
            },
            {
                "json_class":"Period",
                "start":25200,
                "stop":68459,
                "interval":300,
                "actions":[
                    {
                        "json_class":"SchedulerActionShoot"
                    }
                ]
            }
        ],
        [
            {
                "json_class":"Period",
                "start":25200,
                "stop":68459,
                "interval":600,
                "actions":[
                    {
                        "json_class":"SchedulerActionShootQueue"
                    },
                    {
                        "json_class":"SchedulerActionUpload"
                    }
                ]
            },
            {
                "json_class":"Period",
                "start":25200,
                "stop":68459,
                "interval":300,
                "actions":[
                    {
                        "json_class":"SchedulerActionShoot"
                    }
                ]
            }
        ],
        []
    ],
    'standard77so' : [
        [
            {
                "stop": 25199,
                "start": 0,
                "actions": [
                    {
                        "json_class": "SchedulerActionSleep"
                    }
                ],
                "interval": 300,
                "json_class": "Period",
                "periodIndex": 0,
                "id":'0-0',
            },
            {
                "stop": 68459,
                "start": 25200,
                "actions": [
                    {
                        "json_class": "SchedulerActionShoot"
                    },
                    {
                        "json_class": "SchedulerActionUpload"
                    }
                ],
                "interval": 900,
                "json_class": "Period",
                "periodIndex": 1,
                "id":'0-1',
            },
            {
                "stop": 86399,
                "start": 68520,
                "actions": [
                    {
                        "json_class": "SchedulerActionSleep"
                    }
                ],
                "interval": 300,
                "json_class": "Period",
                "periodIndex": 2,
                "id":'0-2',
            }
        ],
        [
            {
                "stop": 25199,
                "start": 0,
                "actions": [
                    {
                        "json_class": "SchedulerActionSleep"
                    }
                ],
                "interval": 300,
                "json_class": "Period",
                "periodIndex": 0,
                "id":'1-0',
            },
            {
                "stop": 68459,
                "start": 25200,
                "actions": [
                    {
                        "json_class": "SchedulerActionShoot"
                    },
                    {
                        "json_class": "SchedulerActionUpload"
                    }
                ],
                "interval": 900,
                "json_class": "Period",
                "periodIndex": 1,
                "id":'1-1',
            },
            {
                "stop": 86399,
                "start": 68520,
                "actions": [
                    {
                        "json_class": "SchedulerActionSleep"
                    }
                ],
                "interval": 300,
                "json_class": "Period",
                "periodIndex": 2,
                "id":'1-2',
            }
        ],
        [
            {
                "stop": 25199,
                "start": 0,
                "actions": [
                    {
                        "json_class": "SchedulerActionSleep"
                    }
                ],
                "interval": 300,
                "json_class": "Period",
                "periodIndex": 0,
                "id":'2-0',
            },
            {
                "stop": 68459,
                "start": 25200,
                "actions": [
                    {
                        "json_class": "SchedulerActionShoot"
                    },
                    {
                        "json_class": "SchedulerActionUpload"
                    }
                ],
                "interval": 900,
                "json_class": "Period",
                "periodIndex": 1,
                "id":'2-1',
            },
            {
                "stop": 86399,
                "start": 68520,
                "actions": [
                    {
                        "json_class": "SchedulerActionSleep"
                    }
                ],
                "interval": 300,
                "json_class": "Period",
                "periodIndex": 2,
                "id":'2-2',
            }
        ],
        [
            {
                "stop": 25199,
                "start": 0,
                "actions": [
                    {
                        "json_class": "SchedulerActionSleep"
                    }
                ],
                "interval": 300,
                "json_class": "Period",
                "periodIndex": 0,
                "id":'3-0',
            },
            {
                "stop": 68459,
                "start": 25200,
                "actions": [
                    {
                        "json_class": "SchedulerActionShoot"
                    },
                    {
                        "json_class": "SchedulerActionUpload"
                    }
                ],
                "interval": 900,
                "json_class": "Period",
                "periodIndex": 1,
                "id":'3-1',
            },
            {
                "stop": 86399,
                "start": 68520,
                "actions": [
                    {
                        "json_class": "SchedulerActionSleep"
                    }
                ],
                "interval": 300,
                "json_class": "Period",
                "periodIndex": 2,
                "id":'3-2',
            }
        ],
        [
            {
                "stop": 25199,
                "start": 0,
                "actions": [
                    {
                        "json_class": "SchedulerActionSleep"
                    }
                ],
                "interval": 300,
                "json_class": "Period",
                "periodIndex": 0,
                "id":'4-0',
            },
            {
                "stop": 68459,
                "start": 25200,
                "actions": [
                    {
                        "json_class": "SchedulerActionShoot"
                    },
                    {
                        "json_class": "SchedulerActionUpload"
                    }
                ],
                "interval": 900,
                "json_class": "Period",
                "periodIndex": 1,
                "id":'4-1',
            },
            {
                "stop": 86399,
                "start": 68520,
                "actions": [
                    {
                        "json_class": "SchedulerActionSleep"
                    }
                ],
                "interval": 300,
                "json_class": "Period",
                "periodIndex": 2,
                "id":'4-2',
            }
        ],
        [
            {
                "stop": 25199,
                "start": 0,
                "actions": [
                    {
                        "json_class": "SchedulerActionSleep"
                    }
                ],
                "interval": 300,
                "json_class": "Period",
                "periodIndex": 0,
                "id":'5-0',
            },
            {
                "stop": 68459,
                "start": 25200,
                "actions": [
                    {
                        "json_class": "SchedulerActionShoot"
                    },
                    {
                        "json_class": "SchedulerActionUpload"
                    }
                ],
                "interval": 900,
                "json_class": "Period",
                "periodIndex": 1,
                "id":'5-1',
            },
            {
                "stop": 86399,
                "start": 68520,
                "actions": [
                    {
                        "json_class": "SchedulerActionSleep"
                    }
                ],
                "interval": 300,
                "json_class": "Period",
                "periodIndex": 2,
                "id":'5-2',
            }
        ],
        [
            {
                "stop": 25199,
                "start": 0,
                "actions": [
                    {
                        "json_class": "SchedulerActionSleep"
                    }
                ],
                "interval": 300,
                "json_class": "Period",
                "periodIndex": 0,
                "id":'6-0',
            },
            {
                "stop": 68459,
                "start": 25200,
                "actions": [
                    {
                        "json_class": "SchedulerActionShoot"
                    },
                    {
                        "json_class": "SchedulerActionUpload"
                    }
                ],
                "interval": 900,
                "json_class": "Period",
                "periodIndex": 1,
                "id":'6-1',
            },
            {
                "stop": 86399,
                "start": 68520,
                "actions": [
                    {
                        "json_class": "SchedulerActionSleep"
                    }
                ],
                "interval": 300,
                "json_class": "Period",
                "periodIndex": 2,
                "id":'6-2',
            }
        ]
    ]
}

export default templates
