import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { PieChart, Pie, Cell, Legend } from 'recharts';
import { BtoGB } from "./../util/converter"

class DataTransferView extends Component {

    render = () => {


        if (!this.props.data_transfer || this.props.data_transfer.loading) {
            return (
                <div className='data-transfer-view'>
                    <div className='data-transfer-container'>
                        <h4>Data Transfer</h4>
                        <p>Loading...</p>

                        <div className='data-transfer-pie-chart-container'>

                        </div>

                    </div>
                </div>
            )
        }


        const dataTransferReceiveTotalInBytes = (this.props.data_transfer && 'receive_total_in_bytes' in this.props.data_transfer) ? this.props.data_transfer.receive_total_in_bytes : null
        const dataTransferTransmitTotalInBytes = (this.props.data_transfer && 'transmit_total_in_bytes' in this.props.data_transfer) ? this.props.data_transfer.transmit_total_in_bytes : null
        const dataTransferTotalUsed = (this.props.data_transfer && 'total_used_in_bytes' in this.props.data_transfer) ? this.props.data_transfer.total_used_in_bytes : null
        const dataTransferAllowance = (this.props.data_transfer && 'data_allowance_in_bytes' in this.props.data_transfer) ? this.props.data_transfer.data_allowance_in_bytes : null
        const dataTransferFree = dataTransferAllowance - dataTransferTotalUsed
        const dataTransferData = [
            {name: `Downloaded (${BtoGB(dataTransferReceiveTotalInBytes)} GB)`, value: parseInt(dataTransferReceiveTotalInBytes, 10)},
            {name: `Uploaded (${BtoGB(dataTransferTransmitTotalInBytes)} GB)`, value: parseInt(dataTransferTransmitTotalInBytes, 10)},
            {name: `Free (${BtoGB(dataTransferFree)} GB)`, value: parseInt(dataTransferFree, 10)}]

        const dataTransferHeader = (dataTransferAllowance) ? (
            <div>
                <span>Monthly data allowance is <span className='inside-span'>{BtoGB(dataTransferAllowance)} GB</span></span>
                <p><span className='inside-span'>{this.props.data_transfer.days_remaining_in_current_allowance} days</span> remaining before the allowance starts again</p>
            </div>) : null

        const dataTransferDetails = (dataTransferAllowance) ? (
            <ul>
                <li>So far <span className='inside-span'>{Math.round(this.props.data_transfer.percentage_allowance_used)}%</span> of the allowance has been used</li>
                <li><span className='inside-span'>{Math.round(this.props.data_transfer.percentage_allowance_used_based_on_projected_usage)}%</span> is the projected usage by the end of the current period</li>
                <li>Based on projected usage, <span className='inside-span'>{this.props.data_transfer.projected_days_remaining_in_current_allowance} days remaining </span> in the current period</li>
            </ul>) : null

        const DATA_TRANSFER_COLORS = ['#863060','#A96B3E','#21BA45']
        const dataTransferPieChart = (dataTransferReceiveTotalInBytes && dataTransferTransmitTotalInBytes) ? <PieChart width={200} height={220}><Pie
            data={dataTransferData}
            cx={90}
            cy={80}
            outerRadius={70}
            fill="#21BA45"
            paddingAngle={0}
            propKey='name'
            dataKey='value'

        >
            {
                dataTransferData.map((entry, index) => <Cell key={index} fill={DATA_TRANSFER_COLORS[index]}/>)
            }
        </Pie>
            <Legend layout='vertical' verticalAlign="bottom" align="center"/>
        </PieChart>: null

        return (
            <div className='data-transfer-view'>
                <div className='data-transfer-container'>
                    <h4>Data Transfer</h4>
                    {dataTransferHeader}
                    <div className='data-transfer-pie-chart-container'>
                        {dataTransferPieChart}
                    </div>
                    {dataTransferDetails}
                </div>
            </div>
        )
    }
}

DataTransferView.propTypes = {
    data_transfer: PropTypes.object
}

export default DataTransferView
