import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
    Grid,
    Menu,
    Message,
    Checkbox,
    Container,
    Image
} from 'semantic-ui-react'
import pagination from '../util/pagination'
import { isPermitted } from "../util/acl"
import { Link } from 'react-router-dom'
import ConditionalWrapper from '../util/ConditionalWrapper'
import '../styles/UserList.css'



const UserGrid = (props) => {
    const rowsPerPage = props.rowsPerPage || 12
    const currentPage = props.currentPage || 1

    // Set 12 grid items
    if ( props.fetchGridOrListTotals) {
        props.fetchGridOrListTotals(12);
    }

    useEffect(() => {
        console.log(props.isUserListLinkedFromProject, (props.isUserListLinkedFromProject))
        if (props.isUserListLinkedFromProject) {
            props.projectsActions.usersWithProjectAccess(
                props.projectId,
                rowsPerPage,
                currentPage
            )
        } else {
            props.usersActions.userListRequest(
                rowsPerPage,
                currentPage,
                props.users.sortColumn,
                props.users.sortAscending,
                null,
                props.clientId
            )
            props.bulkUsersActions.reset()
        }

        return () => {
            props.usersActions.userListReset()
        }
    }, []);
    
    /**
    * Jump to a particular page in the results set
    *
    * @param {string} name - The page number label ("1", "2", etc)
    */
    const handlePaginationPageClick = (e, { name }) => {
        const page = parseInt(name, 10)

        if (props.isUserListLinkedFromProject) {
            props.projectsActions.usersWithProjectAccess(
                props.projectId,
                rowsPerPage,
                page
            )
        } else {
            props.usersActions.userListRequest(
                rowsPerPage,
                page,
                props.users.sortColumn,
                props.users.sortAscending,
                props.users.searchTerm,
                props.clientId
            )
        }
    }

    /** Jump to the previous page in the results set */
    const handlePaginationPrevClick = (e) => {
        if (props.users.currentPage > 1 ) {
            const prevPage = props.users.currentPage - 1

            if (props.isUserListLinkedFromProject) {
                props.projectsActions.usersWithProjectAccess(
                    props.projectId,
                    rowsPerPage,
                    prevPage
                )
            } else {
                props.usersActions.userListRequest(
                    rowsPerPage,
                    prevPage,
                    props.users.sortColumn,
                    props.users.sortAscending,
                    props.users.searchTerm,
                    props.clientId
                )
            }
        }
    }

    /** Jump to the next page in the results set */
    const handlePaginationNextClick = (e) => {
        const totalPages = Math.ceil(props.users.totalRows/rowsPerPage)
        if (props.users.currentPage < totalPages) {
            const nextPage = props.users.currentPage + 1

            if (props.isUserListLinkedFromProject) {
                props.projectsActions.usersWithProjectAccess(
                    props.projectId,
                    rowsPerPage,
                    nextPage
                )
            } else {
                props.usersActions.userListRequest(
                    rowsPerPage,
                    nextPage,
                    props.users.sortColumn,
                    props.users.sortAscending,
                    props.users.searchTerm,
                    props.clientId
                )
            }
        }
    }

    /**
    * Render a single table row of results
    *
    * @param {Object} row - An object containing one row of results
    * @param {number} index - The current row number being rendered
    */
    const renderBodyRow = () => {
        let users = (props.users) ? props.users : null
        let lobsterLogo =  <Image src={require('../logo.png')} />

        if (users) {
            for (const key in users) {
                if (users.hasOwnProperty(key)) {
                    if (key === 'tableData') {
                        const element = users[key];
                        let output = element.map((dataRow, index) => {
                            const updated = moment(dataRow.updated).format('HH:mm, Do MMM YYYY')
                            return (
                                <Grid.Column key={`${dataRow.id}-${index}`}>
                                    <div className="card--users-grid">
                                        <div className="user-grid__image-wrap">
                                            {(dataRow.clientLogo)? <Image src={dataRow.clientLogo} /> : lobsterLogo}
                                            {dataRow.status && <p className={`user-grid__user-status user-status--${dataRow.status.toLowerCase()}`}>{dataRow.status}</p>}
                                            {!props.clientId && <Checkbox
                                                name={`${dataRow.id}`}
                                                onChange={
                                                    (e, data) => {props.checkboxSelect(e, data, dataRow.id, dataRow.clients_id)}
                                                }
                                                checked={props.usersToEdit && props.usersToEdit.indexOf(dataRow.id) > -1}
                                            />}
                                        </div>
                                        <div className="user-grid__content">
                                            <ConditionalWrapper
                                                condition={(isPermitted(props.auth.user, 'put-users-users'))}
                                                wrapper={children => <Link to={`/clients/${dataRow.clients_id}/users/${dataRow.id}/update`}>{children}</Link>}>
                                                {dataRow.name && <p className="user-grid__user-name">{dataRow.name}</p>}
                                                {dataRow.username && <p className="user-grid__user-username">{dataRow.username}</p>}
                                                {!dataRow.client_image && props.clientId === undefined && <p className="user-grid__user-client">{dataRow.clientName}</p>}
                                                {updated && <p className="user-grid__user-update">Updated - {updated}</p>}
                                            </ConditionalWrapper>
                                        </div>
                                    </div>
                                </Grid.Column>
                             )
                        })

                        return output
                    }
                }
            }
        }

        // const resendButton = ((row.status === USER_STATUS_NEW) && row.email) ?
        //     <li style={{marginTop: '10px'}}>
        //         <Button
        //             icon='mail'
        //             basic
        //             color='brown'
        //             compact
        //             size='tiny'
        //             as={Link}
        //             to={`/clients/${row.clients_id}/users/${row.id}/resend-verification`}
        //             content='Resend Verification Email'
        //         />
        //     </li> : null

        // // Don't show client name if we're limited results to one client
        // let clientName = this.props.clientId === undefined ?
        //     (<TableCell>{row.clientName}</TableCell>) : null

        // let authAsUserLink
        // // Don't auth as user for Lobster staff
        // if (row.clients_id > 1 && row.api !== true) {
        //     authAsUserLink =(isPermitted(this.props.auth.user, 'get-authas-user')) ?
        //         <li>
        //             <Button
        //                 icon='id badge'
        //                 basic
        //                 color='blue'
        //                 compact
        //                 size='tiny'
        //                 onClick={() => {
        //                     this.props.usersActions.authAsUser(row.clients_id, row.id)
        //                 }}
        //                 content='Auth As User'
        //             />
        //         </li>: null
        // }

        // const updated = moment(row.updated).format('Do MMM YYYY, h:mm A')
        // const lastLogin = (row.lastLogin) ? moment(row.lastLogin).format('Do MMM YYYY, h:mm A') : null
        //  const created = (row.created) ? moment(row.created).format('Do MMM YYYY, h:mm A') : null
    }

    /** Render the table footer row */
    const renderFooter = () => {
        if (props.users.totalRows === 0) {
            return (
                <>
                    {props.users.errors.error !== true ?
                        "No results found" : (
                            <Message
                            error={true}
                            visible={props.users.errors.error}
                            header="Error"
                            content={props.users.errors.message}
                            />
                        )

                    }
                </>
            )
        }
        const totalPages = Math.ceil(props.users.totalRows/rowsPerPage)
        const first = ((props.users.currentPage - 1) * rowsPerPage) + 1
        let last = props.users.currentPage * rowsPerPage
        if (last > props.users.totalRows) last = props.users.totalRows
        let pages = pagination(props.users.currentPage, totalPages)
        let menuPagination = (
            <Menu pagination>
                <Menu.Item name="prev" onClick={handlePaginationPrevClick} icon="angle left" />
                    {pages.map((page, key) => {
                    return (
                        <Menu.Item name={page.toString()} key={key} active={props.users.currentPage === page} onClick={handlePaginationPageClick} />
                    )
                })}
                <Menu.Item name="next" onClick={handlePaginationNextClick} icon="angle right" />
            </Menu>
        )

        return (
            <>
                <div className='user-grid-pagination'>
                    <p>Showing {first} - {last} of {props.users.totalRows} {props.users.totalRows === 1 ? "result" : "results"}</p>
                    {totalPages > 1 ? menuPagination : null}
                </div>
            </>
        )
    }

    return (
        <Container className="content-container" fluid={true} style={{padding: '0 1.2em'}}>
            <Grid className="styled-grid styled-grid--users">
                {renderBodyRow()}
            </Grid>
            {renderFooter()}
        </Container>
    )
}

UserGrid.propTypes = {
    clientId: PropTypes.number,
    bulkUsers: PropTypes.object,
    ucpa: PropTypes.object,
    users: PropTypes.shape({
        currentPage: PropTypes.number,
        errors: PropTypes.object,
        filtered: PropTypes.bool,
        loading: PropTypes.bool,
        sortAscending: PropTypes.bool,
        sortColumn: PropTypes.string,
        tableData: PropTypes.array.isRequired,
        totalRows: PropTypes.number
    }).isRequired,
    usersActions: PropTypes.object,
    editUserActions: PropTypes.object,
    addUserActions: PropTypes.object,
    bulkUsersActions: PropTypes.object,
    logActions: PropTypes.object,
    messengerActions: PropTypes.object,
    ucpaActions: PropTypes.object
}

export default UserGrid
