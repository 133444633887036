import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Message, Grid } from 'semantic-ui-react'
import TimeZoneDropdown from './forms/TimeZone';
import { getErrorMessage } from './../util/errors'

class TimeZone extends Component {

    constructor(props) {
        super(props)
        this.state = { time_zone: null }
    }

    onChange = (timeZone) => {
        const properties = {
            time_zone: timeZone
        }
        this.setState(properties)
    }

    getTimeZoneOptions = () => {
        return this.props.camera_control.system.details.time_zone.options.map((item) => {
            return {  text: item, value: item }
        })
    }

    isProcessing = () => {
        return this.props.camera_control.update_system.processing
            && 'time_zone' in this.props.camera_control.update_system.properties
    }

    isButtonDisabled = () => {
        if (this.isProcessing()) {
            return true
        }
        if (!this.state.time_zone) {
            return true
        }
        if (this.state.time_zone && this.state.time_zone === this.getValue()) {
            return true
        }
        return false
    }

    onClick = () => {
        const properties = {
            time_zone: this.state.time_zone
        }
        this.props.cameraControlActions.updateSystem(this.props.cameras_id, properties)
            .then(() => {
                this.props.cameraControlActions.fetchSystem(this.props.cameras_id)
            })
    }

    getValue = () => {
        return this.props.camera_control.system.details.time_zone.value
    }

    isReady = () => {
        return this.props.camera_control.system.details && this.props.camera_control.system.details.time_zone
    }

    handleDismiss = () => {
        this.setState({ visible: false })

        setTimeout(() => {
            this.setState({ visible: true })
        }, 2000)
    }

    getError = () => {
        if (!this.props.camera_control.update_system.error) {
            return null
        }
        let message = getErrorMessage(this.props.camera_control.update_system.errors, 'cameras_id')
        if (!message) {
            message = getErrorMessage(this.props.camera_control.update_system.errors, 'unknownErrorType')
        }
        return (message) ? message : "There was an unexpected error"
    }

    isMessageRelatedToTimeZone = () => {
        return (this.props.camera_control.update_system.error || this.props.camera_control.update_system.success)
            &&  'time_zone' in this.props.camera_control.update_system.properties
    }

    getMessage = (blurb) => {
        return (this.isMessageRelatedToTimeZone()) ?
            <Message positive={this.props.camera_control.update_system.success} negative={this.props.camera_control.update_system.error}

                     onDismiss={this.onDismissMessage}

            >
                <Message.Header>{(this.props.camera_control.update_system.error) ? 'Error' : 'Success'}</Message.Header>
                <p>{blurb}</p>
            </Message> : null
    }

    getBlurb = () => {
        if (this.props.camera_control.update_system.error) {
            return this.getError()
        }
        if (this.props.camera_control.update_system.success) {
            return 'Successfully updated Time Zone settings'
        }
        return null
    }

    onDismissMessage = () => {
        this.props.cameraControlActions.resetUpdateSystem()
    }

    render = () => {

        if (!this.isReady()) {

            return (
            <div className='time-zone-widget'>
                {/*<Dimmer active inverted><Loader content='' /></Dimmer>*/}
                <Grid columns='1'>
                    <Grid.Row>
                        <Grid.Column>
                            <div className="animated-blank-circle" />
                            <div className="animated-blank-line" />
                            <div className="animated-blank-line" />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
            )

        } else {

            return (
                <div className='time-zone-widget'>

                    {this.getMessage(this.getBlurb())}

                    <Grid columns='1'>
                        <Grid.Row>
                            <Grid.Column>
                                <TimeZoneDropdown
                                    value={this.getValue()}
                                    processing={false}
                                    options={this.getTimeZoneOptions()}
                                    onChange={this.onChange}
                                />

                                <Button
                                    fluid
                                    loading={this.isProcessing()}
                                    disabled={this.isButtonDisabled()}
                                    onClick={this.onClick}>Update Time Zone</Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            )

        }
    }
}

TimeZone.propTypes = {
    cameraControlActions: PropTypes.object.isRequired,
    cameras_id: PropTypes.string.isRequired,
    camera_control: PropTypes.object.isRequired
}

export default TimeZone
