import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as authActions from '../actions/authActions'
import * as scheduleActions from '../actions/scheduleActions'
import Schedule from '../components/schedule/Schedule'
import { isPermitted } from './../util/acl'
import { checkAuth } from '../util/accessToken'

class ScheduleContainer extends Component {

    componentDidMount () {
        window.scrollTo(0, 0)

        this.props.scheduleActions.reset()
        checkAuth(this.props.auth, this.props.authActions, this.props.history)
    }

    render() {
        const schedule = (isPermitted(this.props.auth.user, 'get-scheduler-schedule')) ?
            <Schedule {...this.props} /> :
            <p>You do not have permission to view this page</p>

        return (
            <div>
                {schedule}
            </div>)
    }

}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        schedule: state.schedule
    }
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(authActions, dispatch),
        scheduleActions: bindActionCreators(scheduleActions, dispatch)
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ScheduleContainer)
