import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import React, { Component } from 'react';
import * as authActions from '../actions/authActions'
import * as logActions from '../actions/logActions'
import * as messengerActions from '../actions/messengerActions'
import * as headerActions from '../actions/headerActions'
import * as cameraControlV4Actions from '../actions/cameraControlV4Actions'
import * as cameraControlActions from '../actions/cameraControlActions'
import * as camerasActions from '../actions/camerasActions'
import * as dropActions from '../actions/dropActions'
import * as uploadQueueActions from '../actions/uploadQueueActions'
import * as scheduleActions from '../actions/scheduleActions'
import CameraControlV4 from "../components/pages/cameraControl/CameraControlV4"
import CameraControlV4Admin from "../components/pages/cameraControl/CameraControlV4Admin"
import { isPermitted } from "../util/acl"
import { checkAuth } from '../util/accessToken'

class CameraControlV4Container extends Component {

    componentDidMount () {
        window.scrollTo(0, 0)

        checkAuth(this.props.auth, this.props.authActions, this.props.history)
        this.props.camerasActions.fetchCamera(this.props.match.params.cameraId)
    }

    render() {
        const cameraId = this.props.match.params.cameraId
        const cameraControl = (
            isPermitted(this.props.auth.user, 'get-cameracontrol-status')
        ) ?
            (this.props.auth.user && this.props.auth.user.roles_id === 5) ? 
                <CameraControlV4
                    cameras_id={cameraId}
                    showChart={this.props.headerActions.showLedChart}
                    {...this.props}
                />
            :
                <CameraControlV4Admin
                    cameras_id={cameraId}
                    {...this.props}
                />
        : null
        return (
            <div>
                {cameraControl}
            </div>)
    }

}

CameraControlV4Container.propTypes = {
    authActions: PropTypes.object,
    auth: PropTypes.object,
    logActions: PropTypes.object,
    messengerActions: PropTypes.object,
    camera_control_v4: PropTypes.object,
    cameraControlV4Actions: PropTypes.object,
    camera_control: PropTypes.object,
    cameraControlActions: PropTypes.object
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        projects: state.projects,
        addProject: state.addProject,
        updateProject: state.updateProject,
        camera_control_v4: state.cameraControlV4,
        camera_control: state.cameraControl,
        cameras: state.cameras,
        drop: state.drop,
        uploadQueue: state.uploadQueue,
        schedule: state.schedule
    }
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(authActions, dispatch),
        logActions: bindActionCreators(logActions, dispatch),
        headerActions: bindActionCreators(headerActions, dispatch),
        messengerActions: bindActionCreators(messengerActions, dispatch),
        cameraControlV4Actions: bindActionCreators(cameraControlV4Actions, dispatch),
        cameraControlActions: bindActionCreators(cameraControlActions, dispatch),
        camerasActions: bindActionCreators(camerasActions, dispatch),
        dropActions: bindActionCreators(dropActions, dispatch),
        uploadQueueActions: bindActionCreators(uploadQueueActions, dispatch),
        scheduleActions: bindActionCreators(scheduleActions, dispatch)
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CameraControlV4Container))
