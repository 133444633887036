import React, { Component } from 'react'
import {
    Button,
    Modal,
    Form,
} from 'semantic-ui-react'

class ImportCoordinatesModal extends Component {

    constructor(props) {
        super(props)
        this.inputFile = null
    }

    uploadFile = event => {
        this.inputFile = event.target.files[0]
        // console.log(event.target.files[0])
    }

    render() {
        const closeOrCancelButton = this.props.success ?
            <Button onClick={() => {this.props.onClose()}} primary>Close</Button>
            : <Button onClick={() => {this.props.onClose()}} negative>Cancel</Button>

        const importButton = !this.props.success ?
            <Button loading={this.props.loading}
                    id="import-coordinates-button"
                    onClick={() => {
                        if (this.inputFile !== null){
                            let formData = new FormData()
                            formData.append('file', this.inputFile)
                            this.props.importCoordinates(formData)
                            this.props.onClose()
                        }
                    }}
                    positive>
                    Import pot coordinates
            </Button> : null

        const importForm = !this.props.success ?
            <Form size='large'>
                <label>Upload CSV file with pot coordinates</label>
                <Form.Input
                    id="file-input"
                    control="input"
                    type="file"
                    onChange={this.uploadFile}
                    >
                </Form.Input>


            </Form> : null

        // TODO:
        // Add Message element with attributes for error and succes based on equivilent states.
        // visible set to false (?). header displays error or sucess depending on state, content
        // displays message depening on error (get from response form api call)

        return (
            <Modal
                size={"small"}
                open={this.props.open}
                onClose={() => {this.props.onClose()}}
                closeIcon
                >
                <Modal.Header>Import pot coordinates</Modal.Header>
                <Modal.Content>
                    {importForm}
                    <div className='modalButtons'>
                        {importButton}
                        {closeOrCancelButton}
                    </div>
                </Modal.Content>

            </Modal>
        )
    }
}

export default ImportCoordinatesModal
