import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Message, Tab } from 'semantic-ui-react'
import UserProjectsAndCamerasSelector from "./UserProjectsAndCamerasSelector";
import UpdateUserForm from './forms/UpdateUser/UpdateUserForm'
/*
* This component is the Update User modal for Lobster Pictures Staff
*/

const UpdateUser = (props) => {

    useEffect(() => {
        props.adminUserRolesRequest()
        props.adminUserStatusesRequest()
        props.adminUserClientsRequest()
    }, [])

    const getHeader = (error, success) => {
        if (error) {
            return <p>Error</p>
        } else if (success) {
            return <p>Successfully updated user settings</p>
        } else {
            return ""
        }
    }

    const onContextChange = (context) => {
        props.onContextChange(context)
    }

    const isReady = () => {
        return props.user.username
    }

    const hasAnythingChanged = () => {
        return (
            props.add_user_cameras.length > 0 ||
            props.remove_user_cameras.length > 0 ||
            props.add_user_projects.length > 0 ||
            props.remove_user_projects.length > 0 ||
            props.project_camera_weights.length > 0
        )
    }

    const getPanes = () => {
        let panes = [
            { menuItem: { key: 'settings', content: 'User settings' }, render: () => {

                // this.onContextChange('Settings')

                let message = null
                if (props.error || props.success) {
                    message = <Message
                        error={props.error}
                        success={props.success}
                        visible={true}
                        header={''}
                        content={getHeader(props.error, props.success)}
                    />
                }

                return (<Tab.Pane>

                    {message}
                    {<UpdateUserForm
                        auth={props.auth}
                        user={props.user}
                        errors={props.errors}
                        clients={props.clients}
                        statuses={props.statuses}
                        roles={props.roles}
                        onUpdateLocalUserState={props.onUpdateLocalUserState}
                        resetMfa={props.resetMfa}
                        clientId={props.clientId}
                        userId={props.userId}
                    />}
                    <br />

                </Tab.Pane>)}, onItemClick: () => {}
            },
        ]

        const showProjectCameraRestrictions = (props.auth.user && parseInt(props.auth.user.roles_id) !== 4) || (props.auth.user && (parseInt(props.auth.user.roles_id) === 4 && props.user.roles_id && parseInt(props.user.roles_id) !== 4))

        let projectCameraTabText = (props.auth.user && parseInt(props.auth.user.roles_id) !== 5)? 'Restrict to certain projects and cameras' : 'Projects and Cameras access';

        if (showProjectCameraRestrictions) {
            panes.push({ menuItem: { key: 'camera', content: projectCameraTabText}, render: () => {
                // this.onContextChange('UserProjects')

                let localModificationMessage = null
                if (hasAnythingChanged()) {
                    localModificationMessage = <Message
                        error={false}
                        success={false}
                        visible={true}
                        header={''}
                        content={'Click Confirm to save your changes.'}
                    />
                }

                let unassociationProjectMessage = null
                if (props.unassociate_project_with_user.error || props.unassociate_project_with_user.success) {
                    unassociationProjectMessage = <Message
                        onDismiss={() => {
                            props.resetUnassociateProjectWithUser()
                        }}
                        error={props.unassociate_project_with_user.error}
                        success={props.unassociate_project_with_user.success}
                        visible={true}
                        header={''}
                        content={(props.unassociate_project_with_user.error) ? "Error unassociating project with user" : (props.unassociate_project_with_user.success) ? props.unassociate_project_with_user.message : ''}
                    />
                }

                let unassociationCameraMessage = null
                if ( props.unassociate_camera_with_user.error || props.unassociate_camera_with_user.success) {
                    unassociationCameraMessage = <Message
                        onDismiss={() => {
                            props.resetUnassociateCameraWithUser()
                        }}
                        error={props.unassociate_camera_with_user.error}
                        success={props.unassociate_camera_with_user.success}
                        visible={true}
                        header={''}
                        content={(props.unassociate_camera_with_user.error) ? "Error unassociating camera with user" : (props.unassociate_camera_with_user.success) ? props.unassociate_camera_with_user.message : ''}
                    />
                }

                let associationProjectMessage = null
                if ( props.associate_project_with_user.error || props.associate_project_with_user.success) {
                    associationProjectMessage = <Message
                        onDismiss={() => {
                            props.resetAssociateProjectWithUser()
                        }}
                        error={props.associate_project_with_user.error}
                        success={props.associate_project_with_user.success}
                        visible={true}
                        header={''}
                        content={(props.associate_project_with_user.error) ? "Error associating project with user" : (props.associate_project_with_user.success) ? props.associate_project_with_user.message : ''}
                    />
                }

                let associationCameraMessage = null
                if ( props.associate_camera_with_user.error || props.associate_camera_with_user.success) {
                    associationCameraMessage = <Message
                        onDismiss={() => {
                            props.resetAssociateCameraWithUser()
                        }}
                        error={props.associate_camera_with_user.error}
                        success={props.associate_camera_with_user.success}
                        visible={true}
                        header={''}
                        content={(props.associate_camera_with_user.error) ? "Error associating camera with user" : (props.associate_camera_with_user.success) ? props.associate_camera_with_user.message : ''}
                    />
                }


                if (!isReady()) {
                    return (
                        <Tab.Pane>
                            <div>
                                <div className='animated-blank-line'></div>
                                <div className='animated-blank-block-75'></div>
                                <div className='animated-blank-line'></div>
                            </div>
                        </Tab.Pane>)
                } else {
                    return (<Tab.Pane>
                        {localModificationMessage}
                        {unassociationProjectMessage}
                        {unassociationCameraMessage}
                        {associationProjectMessage}
                        {associationCameraMessage}

                        <UserProjectsAndCamerasSelector
                            auth={props.auth}
                            loadingProjectCameras={props.cameras.loading}
                            loadingProjectCamerasProjectId={props.cameras.projects_id}
                            cameras={props.cameras.cameras}
                            user_cameras={props.user_cameras.cameras}

                            user_project_cameras={
                                (() => {
                                    let items = props.user_projects.projects.slice()

                                    items = items.concat(props.add_user_projects)
                                    items = items.filter((elem) => {
                                        return props.remove_user_projects.findIndex((e) => {
                                            return e.id === elem.id
                                        }) === -1
                                    })

                                    let cams = props.user_cameras.cameras.slice()

                                    cams = cams.concat(props.add_user_cameras)
                                    cams = cams.filter((elem) => {
                                        return props.remove_user_cameras.findIndex((e) => {
                                            return e.id === elem.id
                                        }) === -1
                                    })

                                    items = items.concat(cams)
                                    items = items.map((elem, idx) => {
                                        return Object.assign(
                                            {},
                                            elem,
                                            {
                                                key: elem.id,
                                                text: elem.pot_id || elem.name
                                            }
                                        )
                                    })
                                    // Okay, this is sorting essentially by anything available...
                                    items = items.sort((a, b) => {

                                        if ('weight' in a && 'weight' in b) {
                                            if (a.weight < b.weight) {
                                                return -1
                                            }
                                            if (a.weight > b.weight) {
                                                return 1
                                            }
                                            return 0
                                        } else if ('weight' in a && 'default_weight' in b) {
                                            if (a.weight < b.default_weight) {
                                                return -1
                                            }
                                            if (a.weight > b.default_weight) {
                                                return 1
                                            }
                                            return 0
                                        } else if ('default_weight' in a && 'weight' in b) {
                                            if (a.default_weight < b.weight) {
                                                return -1
                                            }
                                            if (a.default_weight > b.weight) {
                                                return 1
                                            }
                                            return 0
                                        } else {
                                            if ('default_weight' in a < 'default_weight' in b) {
                                                return -1
                                            }
                                            if (a.default_weight > b.default_weight) {
                                                return 1
                                            }
                                            return 0
                                        }

                                    })

                                    if (props.project_camera_weights && props.project_camera_weights.length > 0) {
                                        // If here, this sorting should ultimately take priority,
                                        // as it reflects the very latest local changes
                                        items = items.sort((a, b) => {

                                            const aWeighting = props.project_camera_weights.find((i) => {
                                                if ('pot_id' in a || 'pot_id' in i) {
                                                    if ('pot_id' in i && 'pot_id' in a) {
                                                        return a.id === i.id
                                                    } else {
                                                        return false
                                                    }
                                                } else {
                                                    return a.id === i.id
                                                }
                                            })

                                            const bWeighting = props.project_camera_weights.find((i) => {
                                                if ('pot_id' in b || 'pot_id' in i) {
                                                    if ('pot_id' in i && 'pot_id' in b) {
                                                        return b.id === i.id
                                                    } else {
                                                        return false
                                                    }
                                                } else {
                                                    return b.id === i.id
                                                }
                                            })

                                            if (aWeighting && bWeighting) {
                                                // Should always be the case
                                                if (aWeighting.weight < bWeighting.weight) {
                                                    return -1
                                                }
                                                if (aWeighting.weight > bWeighting.weight) {
                                                    return 1
                                                }
                                                return 0


                                            } else {
                                                return -1
                                            }

                                        })

                                    }
                                    return items

                                })()
                            }

                            onLoadProjectCameras={(project) => {
                                props.onLoadProjectCameras(project)
                            }}

                            onResetProjectCameras = {props.onResetProjectCameras}

                            onAddUserCameras={(cameras) => {

                                // Okay, so we only want to add cameras not already previously linked to user
                                const camerasFiltered = cameras.filter((elem) => {
                                    return props.user_cameras.cameras.findIndex((e) => {
                                        return elem.id === e.id
                                    }) === -1
                                })

                                // We also only want to add a camera not already added...
                                const camerasFilteredUnique = camerasFiltered.filter((camera) => {
                                    return (props.add_user_cameras.findIndex((elem) => {
                                        return elem.id === camera.id
                                    }) === -1)
                                })

                                // Let's add the latest array to the existing one
                                const addUserCameras = [...props.add_user_cameras, ...camerasFilteredUnique]
                                props.onAddUserCameras(addUserCameras)

                                // Let's also tidy up the remove cameras array, if necessary
                                let removeUserCameras = props.remove_user_cameras.slice()
                                let changed = false

                                cameras.forEach((camera) => {
                                    const key =
                                        removeUserCameras.findIndex((elem) => {
                                            return elem.id === camera.id
                                        })

                                    if (key !== -1) {
                                        changed = true
                                        // We can tidy up a little...
                                        removeUserCameras.splice(key, 1)
                                    }
                                })

                                if (changed) {
                                    props.onRemoveCameras(removeUserCameras)
                                }
                            }}

                            onAddProject={(project) => {
                                if (props.add_user_projects.findIndex((elem) => {
                                        return elem.id === project.id
                                    }) === -1) {

                                    // If project is already is linked to user, but now marked for removal
                                    // we don't really want to add it here
                                    if (props.user_projects.projects.findIndex((elem) => {
                                            return elem.id === project.id
                                        }) === -1) {
                                        // Avoids
                                        props.onAddUserProjects([...props.add_user_projects, project])
                                    }
                                }

                                // If we're adding a project, let's clean up the remove projects just in case
                                // Also necessary for any project previously marked for removal
                                const key = props.remove_user_projects.findIndex((elem) => {
                                    return elem.id === project.id
                                })
                                if (key !== -1) {
                                    let projs = props.remove_user_projects.slice()
                                    projs.splice(key, 1)
                                    props.onRemoveProjects(projs)
                                }
                            }}

                            onRemoveProject={(project) => {
                                if (props.remove_user_projects.findIndex((elem) => {
                                        return elem.id === project.id
                                    }) === -1) {

                                    // So, we only need to add items that are actually already linked to user
                                    if (props.user_projects.projects.findIndex((elem) => {
                                            return elem.id === project.id
                                        }) !== -1) {

                                        props.onRemoveProjects([...props.remove_user_projects, project])

                                    }
                                }

                                // Let's also update the add_user_projects too, if necessary
                                const key = props.add_user_projects.findIndex((elem) => {
                                    return elem.id === project.id
                                })
                                if (key !== -1) {
                                    let projs = props.add_user_projects.slice()
                                    projs.splice(key, 1)
                                    props.onAddUserProjects(projs)
                                }
                            }}

                            onRemoveCamera={(camera) => {
                                if (props.remove_user_cameras.findIndex((elem) => {
                                        return elem.id === camera.id
                                    }) === -1) {

                                    // We only need to add here if the already linked to user..
                                    if (props.user_cameras.cameras.findIndex((elem) => {
                                            return elem.id === camera.id
                                        }) !== -1) {

                                        props.onRemoveCameras([...props.remove_user_cameras, camera])
                                    }
                                }

                                // Let's also update add_user_cameras too, if necessary
                                const key = (props.add_user_cameras.findIndex((elem) => {
                                    return elem.id === camera.id
                                }))
                                if (key !== -1) {
                                    let cams = props.add_user_cameras.slice()
                                    cams.splice(key, 1)
                                    props.onAddUserCameras(cams)
                                }

                            }}

                            onUpdateProjectCameraWeights={props.onUpdateProjectCameraWeights}
                            projects={
                                (() => {
                                    let p = props.projects.projects.map((elem, idx) => {
                                        return Object.assign(
                                            {},
                                            elem,
                                            {
                                                key: idx,
                                                text: elem.name
                                            }
                                        )
                                    })

                                    p = p.filter((elem) => {

                                        const notAddedToUserProjects = props.add_user_projects.findIndex((e) => {
                                            return elem.id === e.id
                                        }) === -1

                                        const notMarkedForRemoval = props.remove_user_projects.findIndex((e) => {
                                            return elem.id === e.id
                                        }) === -1

                                        const notExistingUserProject = props.user_projects.projects.findIndex((e) => {
                                            return elem.id === e.id
                                        }) === -1

                                        if (notAddedToUserProjects && notExistingUserProject) {
                                            return true
                                        } else if (!notExistingUserProject && !notMarkedForRemoval) {
                                            return true
                                        } else {
                                            return false
                                        }

                                    })

                                    return p

                                })()
                            }
                        />
                    </Tab.Pane>)
                }
            }})
        }

        return panes;
    }

    return (
        <div className='update-user'>
            <Tab panes={getPanes()} onTabChange={(event, value) => {
                const ctxt = (value.panes[value.activeIndex].menuItem.key === 'camera') ? "UserProjects" : "Settings"
                onContextChange(ctxt)
            }}/>
        </div>
    )
}

UpdateUser.propTypes = {
    user: PropTypes.object.isRequired,
    errors:PropTypes.object.isRequired,
    success:PropTypes.bool.isRequired,
    error:PropTypes.bool.isRequired,
    loading:PropTypes.bool.isRequired,
    clients: PropTypes.array.isRequired,
    roles: PropTypes.array.isRequired,
    statuses: PropTypes.array.isRequired,
    clientId: PropTypes.any,
    userId: PropTypes.any
}

export default UpdateUser
