import React, { useRef } from "react";
import Slider from "react-slick";
import { Icon } from "semantic-ui-react";
import '../../styles/Slick.css'
import '../../styles/HorizontalSlide.css'

const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, fontSize: '28px' }}
        onClick={onClick}
      >
         <Icon name="chevron right"></Icon>
      </div>
    );
  }
  
const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, fontSize: '28px' }}
        onClick={onClick}
      >
           <Icon name="chevron left"></Icon>
      </div>
    );
  }


const HorizontalSlider = (props) => {
    const thisSlider = useRef(null);
    const activeItem = props.items.find(element => String(element.value) === String(props.value))

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        centerMode: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        initialSlide: activeItem.id,
        swipeToSlide: true,
        responsive: [
            // {
            //     breakpoint: 992,
            //     settings: {
            //         slidesToShow: 3,
            //     }
            // },
            // {
            //     breakpoint: 992,
            //     settings: {
            //         slidesToShow: 1,
            //     }
            // },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 3
                }
            }
        ]
    }

    const onChange = (index) => {
        let slide = null
        slide = props.items[index]
        props.onChange(slide.value)
    }
    
    return (
        <Slider
            ref={thisSlider}
            {...settings}
            items={props.items}
            afterChange={(index) => onChange(index)}
            className='camera-control__slider'
        >
            {props.items.map(({id, value}) => (
                <div className="slider__value" key={`${props.title}--${id}`}>
                    <p>{value}</p>
                 </div>
            ))}
        </Slider>
    );
}

export default HorizontalSlider