export default {
    auth: {
        forceOut: false,
        forceOutMessage: false,
        loading: false,
        errors: {},
        attempts: 0,
    },
    security: {
        lockdownStatus: {
            status: null,
            loading: false,
            errors: {},
            message: null,
            success: false,
            error: false
        },
        applyLockdown: {
            loading: false,
            errors: {},
            message: null,
            success: false,
            error: false
        },
        removeLockdown: {
            loading: false,
            errors: {},
            message: null,
            success: false,
            error: false
        }
    },
    users: {
        tableData: [],
        totalRows: 0,
        loading: false,
        currentPage: 1,
        filtered: false,
        sortColumn: null,
        sortAscending: true,
        searchTerm: null,
        exportData: null,
        usersToEdit: [],
        errors: {
            error: false
        }
    },
    header: { isHidden: true },
    footer: { isHidden: true },
    password: {
        strength: 0,
        match: false,
        warning: null,
        suggestions: [],
        errors: []
    },
    addUser: {
        user: {
            settings: {
                weather: {
                    enabled: true,
                    temperature_unit: 'Celsius',
                    wind_speed_unit: 'MPH',
                },
                permitted_to_save: true,
                permitted_to_zoom: true,
                permitted_to_view_calendar: true,
                show_navigation_permission: 'Yes',
                show_thumbnails_permission: 'Yes',
                permitted_to_view_timelapse: true,
                permitted_to_auto_refresh: false,
                hide_virtual_cameras: false,
                show_bim_model_type: 'Contract',
            },
            job_title: "",
            first_name: "",
            last_name: "",
            username: "",
            email: "",
            roles_id: 3,
            status: "New",
            clients_id: "",
            lockdown: "OFF",
            processed: false,
            limit_history: 0,
            video_editor: true,
            delay: 0
        }, // Moving state to Redux in order to support tabs
        loading: false,
        success: false,
        error: false,
        errors: {},
        roles: [],
        statuses: [],
        clients: [],
        details: {},
        ucpa_completed: false // used by modal...after camera project association step completed
    },
    editUser: {
        context: 'Settings',
        user: {
            settings: {
                weather: {
                    enabled: true,
                    temperature_unit: 'Celsius',
                    wind_speed_unit: 'MPH'
                },
                permitted_to_save: true,
                permitted_to_zoom: true,
                permitted_to_view_calendar: true,
                show_navigation_permission: 'Yes',
                show_thumbnails_permission: 'Yes',
                permitted_to_view_timelapse: true,
                permitted_to_auto_refresh: false,
                hide_virtual_cameras: false,
                show_bim_model_type: 'Contract',
            }
        },
        bulkEditUsers: [],
        loading: false,
        errors: {},
        roles: [],
        statuses: [],
        clients: [],
        success: false,
        error: false,
        video_editor: false,
        mfa_enabled: false,
        mfa_verified: false,
        suspend: {
            loading: false,
            errors: {},
            message: null,
            id: false
        },
        delete: {
            loading: false,
            errors: {},
            message: null,
            users_id: false
        },
        resendEmailVerification: {
            loading: false,
            errors: {},
            message: null,
            id: false
        }
    },
    addClient: {
        loading: false,
        errors: {},
        roles: [],
        statuses: []
    },
    captcha: {
        isHuman: false,
        loading: false,
        errors: {},
        success: false,
        error: false
    },
    editClient: {
        loading: false,
        errors: {},
        error: false,
        success: false,
        roles: [],
        statuses: [],
        deleted: []
    },
    viewClient: {
        loading: false,
        errors: {}
    },
    clients: {
        tableData: [],
        totalRows: 0,
        loading: false,
        currentPage: 1,
        filtered: false,
        sortColumn: null,
        sortAscending: true,
        searchTerm: null,
        errors: {
            error: false
        }
    },
    bulkUsers: {
        validateEmailAsUsername: {
            email: "",
            errors: {},
            error: false,
            success: false,
            loading: false
        },
        emails: [],
        errorEmails: [],
        template: {
            username: "",
            settings: {
                weather: {
                    enabled: true,
                    temperature_unit: 'Celsius',
                    wind_speed_unit: 'MPH'
                },
                permitted_to_save: true,
                permitted_to_zoom: true,
                permitted_to_view_calendar: true,
                show_navigation_permission: 'Yes',
                show_thumbnails_permission: 'Yes',
                permitted_to_view_timelapse: true,
                permitted_to_auto_refresh: false,
                hide_virtual_cameras: false,
                show_bim_model_type: 'Contract'
            },
            job_title: "",
            first_name: "",
            last_name: "",
            email: "",
            roles_id: 3,
            status: "New",
            clients_id: "",
            lockdown: "OFF",
            comment: "",
            processed: true,
            limit_history: 0,
            delay: 0,
            video_editor: true,
            api: false
        },
        errors: {},
        success: false,
        error: false,
        processing: false,
        loading: false,
        completed: false,
        templateCompleted: false,
        // Added to support UCPA
        add_user_cameras: [],
        add_user_projects: [],
        project_camera_weights: [],
        users: [],
        ucpa_completed: false
    },
    messenger: {
        messages: []
    },
    log: {
        logs: []
    },
    projects: {
        tableData: [],
        totalRows: 0,
        loading: false,
        currentPage: 1,
        filtered: false,
        sortColumn: null,
        sortAscending: true,
        searchTerm: null,
        errors: {
            error: false
        }
    },
    addProject: {
        loading: false,
        error: false,
        success: false,
        errors: {},
        statuses: ['New', 'Active', 'Cancelled', 'Completed', 'Archived'],
        details: {
            name: '',
            description: '',
            latitude: '',
            longitude: '',
            location: '',
            start: null,
            finish: null,
            comment: '',
            status: 'New',
            add_cameras: [],
            camera_weights: []
        },
        clients: {
            clients: [],
            loading: false,
            errors: {}
        },
        // Used for local changes...implementing Harry's feedback.  Pushing state to Redux
        project_cameras: {
            available_cameras: [], // add_cameras + available_cameras = true available_cameras
            project_cameras: [], // remove_cameras + project_cameras = true project_cameras
            add_cameras: [], // available_cameras to be added to project
            remove_cameras: [], // project_cameras to be removed from project
            camera_weights: [] // ordered, weighted list includes existing and new cameras
        }
    },
    updateProject: {
        loading: false,
        processing: false,
        error: false,
        success: false,
        errors: {},
        statuses: ['New', 'Active', 'Cancelled', 'Completed', 'Archived'],
        details: {
            name: '',
            description: '',
            latitude: null,
            longitude: null,
            location: '',
            start: null,
            finish: null,
            comment: '',
            status: 'Active',
            cameras: [],
            add_cameras: [],
            remove_cameras: [],
            default_weights: []
        },
        delete: {
            loading: false,
            errors: {},
            projects_id: false,
            success: false,
            error: false
        },
        clients: {
            clients: [],
            loading: false,
            errors: {}
        },
        // Used for local changes...implementing Harry's feedback.  Pushing state to Redux
        project_cameras: {
            available_cameras: [], // add_cameras + available_cameras = true available_cameras
            project_cameras: [], // remove_cameras + project_cameras = true project_cameras
            add_cameras: [], // available_cameras to be added to project
            remove_cameras: [], // project_cameras to be removed from project
            camera_weights: [] // ordered, weighted list includes existing and new cameras
        }
    },
    cameras: {
        create: {
            loading: false,
            errors: {},
            // cameras_id: false,
            details: {},
            success: false,
            error: false
        },
        importCoordinates: {
            loading: false,
            errors: {},
            details: {},
            success: false,
            error: false
        },
        camera: {
            loading: false,
            errors: {},
            cameras_id: null,
            details: {
                processing: []
            },
            success: false,
            error: false
        },
        update: {
            loading: false,
            errors: {},
            cameras_id: false,
            details: {},
            success: false,
            error: false
        },
        delete: {
            loading: false,
            errors: {},
            cameras_id: false,
            success: false,
            error: false
        },
        available: {
            loading: false,
            errors: {},
            success: false,
            error: false,
            cameras: []
        },
        associate: {
            loading: false,
            errors: {},
            success: false,
            error: false,
            projects_id: null,
            cameras_id: null,
            details: {}
        },
        unassociate: {
            loading: false,
            errors: {},
            success: false,
            error: false,
            cameras_id: null,
            details: {}
        },
        list: {
            loading: false,
            errors: {},
            success: false,
            error: false,
            cameras: [],
            total: 0,
            hasMore: true,
            loadingMore: false,
            currentPage: 1
        },
        list_view: {
            loading: false,
            errors: {},
            success: false,
            error: false,
            cameras: [],
            total: 0,
            currentPage: 1
        },
        grid_view: {
            loading: false,
            errors: {},
            success: false,
            error: false,
            cameras: [],
            total: 0,
            hasMore: true,
            loadingMore: false,
            currentPage: 1
        },
        statuses: {
            ready: false,
            loading: false,
            errors: {},
            success: false,
            error: false,
            statuses: [],
            default: null,
            filterBy: ['Deployed']
        },
        types: ['Linux','Windows','Virtual','Video'],
        filterByTypes: ['Linux','Windows','Video'],
        tags: {
            loading: false,
            errors: {},
            success: false,
            error: false,
            tags: [],
            filterBy: []
        },
        sort: {
            field: 'potId',
            ascending: true
        },
        filterBy: '',
        camera_info: {
            cameras: []
        },
        camera_tags: {
            cameras: []
        },
        versions: {
            versions: ['1', '2', '3', '4'],
            filterBy: []
        }
    },
    ucpa: {
        clients_id: null,
        processing: false, // global state, used to hide modal output
        // Working collections to maintain local changes before applying
        add_user_cameras: [],
        remove_user_cameras: [],
        add_user_projects: [],
        remove_user_projects: [],
        project_camera_weights: [],
        // API requests/responses
        // All cameras belonging to a given project. Fetched when arrow clicked.
        cameras: {
            projects_id: null,
            cameras: [],
            loading: false,
            success: false,
            error: false,
            errors: {}
        },
        // All projects belonging to given client
        projects: {
            projects: [],
            loading: false,
            success: false,
            error: false,
            errors: {}
        },
        // Existing cameras associated with user
        user_cameras: {
            cameras: [],
            loading: false,
            success: false,
            error: false,
            errors: {}
        },
        // Existing projects associated with user
        user_projects: {
            projects: [],
            loading: false,
            success: false,
            error: false,
            errors: {}
        },
        change_project_weight: {
            projects_id: null,
            users_id: null,
            processing: false,
            success: false,
            error: false,
            errors: {},
            details: {},
            message: null
        },
        change_camera_weight: {
            cameras_id: null,
            users_id: null,
            processing: false,
            success: false,
            error: false,
            errors: {},
            details: {},
            message: null
        },
        associate_project_with_user: {
            projects_id: null,
            users_id: null,
            processing: false,
            success: false,
            error: false,
            errors: {},
            details: {},
            message: null
        },
        unassociate_project_with_user: {
            projects_id: null,
            users_id: null,
            processing: false,
            success: false,
            error: false,
            errors: {},
            message: null
        },
        associate_camera_with_user: {
            projects_id: null,
            users_id: null,
            processing: false,
            success: false,
            error: false,
            errors: {},
            details: {}
        },
        unassociate_camera_with_user: {
            projects_id: null,
            users_id: null,
            processing: false,
            success: false,
            error: false,
            errors: {}
        }
    },
    camera_control : {
        cameras_id: null,
        version: null,
        loading_preview_image: false,
        preview_image_quality: 3,
        lp4_preview_image_quality: 1,
        image_formats: [
            { text: "Large Fine JPEG", value: "Large Fine JPEG" },
            { text: "Large Normal JPEG", value:"Large Normal JPEG" },
            { text: "Medium Fine JPEG", value: "Medium Fine JPEG" },
            { text: "Medium Normal JPEG", value: "Medium Normal JPEG" },
            { text: "Small Fine JPEG", value: "Small Fine JPEG" },
            { text: "Small Normal JPEG", value: "Small Normal JPEG" },
            { text: "Smaller JPEG", value: "Smaller JPEG" },
            { text: "Tiny JPEG", value: "Tiny JPEG"}
        ],
        preview_image_quality_range: [
            {text: "Very Low", value: 1},
            {text: "Low", value: 2},
            {text: "Medium", value: 3},
            {text: "High", value: 4}
        ],
        status: {
            loading: false,
            success: false,
            error: false,
            errors: null,
            details: null
        },
        camera: {
            loading: false,
            success: false,
            error: false,
            errors: null,
            details: null
        },
        shoot: {
            processing: false,
            success: false,
            error: false,
            errors: null,
            details: null
        },
        exif: {
            loading: false,
            success: false,
            error: false,
            errors: null,
            details: null
        },
        preview: {
            processing: false,
            success: false,
            error: false,
            errors: null,
            details: null
        },
        update_properties: {
            properties: null,
            processing: false,
            success: false,
            error: false,
            errors: null,
            details: null
        },
        properties: {
            processing: false,
            success: false,
            error: false,
            errors: null,
            details: null
        },
        system: {
            loading: false,
            success: false,
            error: false,
            errors: null,
            details: {}
        },
        update_system: {
            properties: null,
            processing: false,
            success: false,
            error: false,
            errors: null,
            details: null
        },
        scheduler: {
            processing: false,
            action: null,
            success: false,
            error: false,
            errors: null,
            details: null
        },
        fetch_apn: {
            details: null,
            loading: false,
            success: false,
            error: false,
            errors: null
        },
        update_apn: {
            apn: null,
            details: null,
            loading: false,
            success: false,
            error: false,
            errors: null
        },
        fetch_wifi: {
            details: null,
            loading: false,
            success: false,
            error: false,
            errors: null
        },
        wifi: {
            processing: false,
            action: null,
            success: false,
            error: false,
            errors: null,
            details: null
        },
        reboot_camera_body: {
            processing: false,
            success: false,
            error: false,
            errors: {},
            details: {}
        },
        tests: {
            visible: false,
            processing: false,
            success: false,
            error: false,
            errors: {},
            details: {},
            stream: []
        },
        test_results: {
            processing: false,
            errors: {},
            resuts: {}
        },
        log: [],
        update_data_transfer: {
            settings: {
                allowance_start_day: null,
                data_allowance_in_bytes: null
            },
            processing: false,
            success: false,
            error: false,
            errors: null,
            details: null
        },
        connection_information: {
            loading: false,
            success: false,
            error: false,
            errors: null,
            details: null
        },
        ping: {
            pinging: false,
            success: false,
            error: false,
            pingTime: null
        }
    },
    schedule: {
        loading: false,
        success: false,
        saving: false,
        saveSuccess: false,
        addPeriodModalVisible: false,
        addPeriodModalDay: null,
        editPeriodModalVisible: false,
        editPeriodModalDay: null,
        editPeriodModalPeriod: null,
        potId: null,
        days: [[],[],[],[],[],[],[]]
    },
    roles: {
        create: {
            processing: false,
            success: false,
            error: false,
            errors: {},
            details: {}
        },
        update: {
            roles_id: null,
            processing: false,
            success: false,
            error: false,
            errors: {},
            details: {}
        },
        remove: {
            roles_id: null,
            processing: false,
            success: false,
            error: false,
            errors: {}
        },
        view_one: {
            roles_id: null,
            loading: false,
            success: false,
            error: false,
            errors: null,
            details: {}
        },
        view_all: {
            page: 1,
            loading: false,
            success: false,
            error: false,
            errors: null,
            details: null
        },
        associate_permission_with_role: {
            processing: false,
            success: false,
            error: false,
            errors: null,
            details: null
        },
        unassociate_permission_with_role: {
            processing: false,
            success: false,
            error: false,
            errors: null,
            details: null
        },
    },
    tags : {
        create: {
            clientId: null,
            processing: false,
            success: false,
            error: false,
            errors: {},
            details: {}
        },
        fetch_one: {
            tagId: null,
            loading: false,
            success: false,
            error: false,
            errors: {},
            details: {},
        },
        list: {
            loading: false,
            success: false,
            error: false,
            errors: {},
            tags: {
                tags: []
            },
            currentPage: 1,
            sortColumn: 'id',
            sortAscending: true,
            filterBy: null,
            pageSize: 20
        },
        update: {
            clientId: null,
            tagId: null,
            processing: false,
            success: false,
            error: false,
            errors: {},
            details: {},
        },
        remove: {
            clientId: null,
            tagId: null,
            processing: false,
            success: false,
            error: false,
            errors: {}
        },
        associate_camera_with_tag: {
            clientId: null,
            tagId: null,
            cameraId: null,
            processing: false,
            success: false,
            error: false,
            errors: {}
        },
        unassociate_camera_with_tag: {
            clientId: null,
            tagId: null,
            cameraId: null,
            processing: false,
            success: false,
            error: false,
            errors: {}
        }
    },
    logo: {
        upload: {
            logo: null,
            clientId: null,
            projectId: null,
            processing: false,
            success: false,
            error: false,
            errors: {},
            details: {},
        },
        delete: {
            clientId: null,
            projectId: null,
            processing: false,
            success: false,
            error: false,
            errors: {}
        }

    },
    processing: {
        blur: {
            set: {
                processing: false,
                success: false,
                error: false,
                errors: {}
            },
            remove: {
                processing: false,
                success: false,
                error: false,
                errors: {}
            }
        }
    },
    bimFinal3dImage: {
        filename: null,
        width: null,
        height: null,
        upload: {
            processing: false,
            success: false,
            error: false,
            errors: {}
        },
        remove: {
            processing: false,
            success: false,
            error: false,
            errors: {}
        }
    },
    embed : {
        create: {
            cameraId: null,
            processing: false,
            success: false,
            error: false,
            errors: {},
            details: {}
        },
        fetch_one: {
            cameraId: null,
            loading: false,
            success: false,
            error: false,
            errors: {},
            details: {},
        },
        list: {
            cameraId: null,
            loading: false,
            success: false,
            error: false,
            errors: {},
            embeds: {},
        },
        update: {
            cameraId: null,
            embedId: null,
            processing: false,
            success: false,
            error: false,
            errors: {},
            details: {},
        },
        delete: {
            cameraId: null,
            embedId: null,
            processing: false,
            success: false,
            error: false,
            errors: {}
        }
    },
    fileList: {
        loading: false,
        success: false,
        error: false,
        errors: {},
        currentPage: 1,
        pageSize: 600,
        totalRows: 0,
        tableData: [],
        start: null,
        end: null,
        ascending: false,
        potId: null,
        minDate: null,
        maxDate: null
    },
    cameraUpload: {
      potId: null,
      password: null,
      uploadHost: null,
      pcid: null,
      potVersion: null,
      loading: false,
      success: false,
      error: false,
      errors: {},
    },
    uploadQueue: {
        loading: false,
        modifying: false,
        clearing: false,
        started: false,
        startingStopping: false,
        success: false,
        error: false,
        errors: {},
        queue: [],
        files: 0,
        size: 0
    },
    drop: {
        loading: false,
        success: false,
        error: false,
        errors: {},
        drop: [],
        files: 0
    },
    potAssign: {
        loading: false,
        assigning: false,
        identifying: false,
        gettingNextPot: false,
        success: false,
        error: false,
        errors: {},
        pcids: []
    },
    videoEdits: {
        loading: false,
        videoEdits: [],
        error: false,
        errors: {},
        delete: {
            loading: false,
            errors: {},
            message: null
        }
    },
    camera_control_v4 : {
        cameras_id: null,
        version: null,
        fetch_setup_mode: {
            details: null,
            loading: false,
            success: false,
            error: false,
            errors: null
        },
        setup_mode: {
            processing: false,
            action: null,
            success: false,
            error: false,
            errors: null,
            details: null
        },
        focus: {
            processing: false,
            success: false,
            error: false,
            errors: null,
            details: null
        },
        auto_focus: {
            processing: false,
            success: false,
            error: false,
            errors: null,
            details: null
        },
        system: {
            details: {}
        },
    },
    subscriptions: {
        data: [],
        loading: false,
        total: 0,
        addingSubscription: false,
        addingSubscriptionSuccess: false,
        removingSubscription: false,
        removeSubscriptionSuccess: false,
        portalLink: null,
        newCameraLoading: false,
        newCameraSuccess: false,
    }
}
