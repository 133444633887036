import React, { Component } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as headerActions from '../../actions/headerActions'
import * as footerActions from '../../actions/footerActions'
import * as passwordActions from '../../actions/passwordActions'
import {VERIFY} from '../../actions/passwordActions'
import * as logActions from '../../actions/logActions'
import * as messengerActions from '../../actions/messengerActions'
import PasswordEntry from '../PasswordEntry'

/** User container class **/
class Verify extends Component
{
    render() {
        return (
            <div>
                <Switch>
                    <Route exact path='/verify/hash/:hash/token/:token' render={props =>
                        <PasswordEntry handleSubmit={this.handleSubmit} reason={VERIFY} {...this.props} {...props} />
                    } />
                    <Route exact path='/verify/hash/:hash' render={props =>
                        <PasswordEntry handleSubmit={this.handleSubmit} reason={VERIFY} {...this.props} {...props} />
                    } />
                    <Redirect from="/verify" to="/" />
                </Switch>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        password: state.password
    }
}

function mapDispatchToProps(dispatch) {
    return {
        passwordActions: bindActionCreators(passwordActions, dispatch),
        headerActions: bindActionCreators(headerActions, dispatch),
        footerActions: bindActionCreators(footerActions, dispatch),
        logActions: bindActionCreators(logActions, dispatch),
        messengerActions: bindActionCreators(messengerActions, dispatch)
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Verify))
