import initialState from './initialState';
import {
    UCPA_FETCH_PROJECTS_REQUEST,
    UCPA_FETCH_PROJECTS_RESPONSE_ERRORS,
    UCPA_FETCH_PROJECTS_RESPONSE_SUCCESS,
    UCPA_FETCH_PROJECT_CAMERAS_REQUEST,
    UCPA_FETCH_PROJECT_CAMERAS_RESPONSE_ERRORS,
    UCPA_FETCH_PROJECT_CAMERAS_RESPONSE_SUCCESS,
    UCPA_RESET_PROJECT_CAMERAS,
    UCPA_FETCH_USER_CAMERAS_REQUEST,
    UCPA_FETCH_USER_CAMERAS_RESPONSE_ERRORS,
    UCPA_FETCH_USER_CAMERAS_RESPONSE_SUCCESS,
    UCPA_FETCH_USER_PROJECTS_REQUEST,
    UCPA_FETCH_USER_PROJECTS_RESPONSE_ERRORS,
    UCPA_FETCH_USER_PROJECTS_RESPONSE_SUCCESS,
    UCPA_ASSOCIATE_PROJECT_WITH_USER_REQUEST,
    UCPA_ASSOCIATE_PROJECT_WITH_USER_RESPONSE_ERRORS,
    UCPA_ASSOCIATE_PROJECT_WITH_USER_RESPONSE_SUCCESS,
    UCPA_UNASSOCIATE_PROJECT_WITH_USER_REQUEST,
    UCPA_UNASSOCIATE_PROJECT_WITH_USER_RESPONSE_ERRORS,
    UCPA_UNASSOCIATE_PROJECT_WITH_USER_RESPONSE_SUCCESS,
    UCPA_ASSOCIATE_CAMERA_WITH_USER_REQUEST,
    UCPA_ASSOCIATE_CAMERA_WITH_USER_RESPONSE_ERRORS,
    UCPA_ASSOCIATE_CAMERA_WITH_USER_RESPONSE_SUCCESS,
    UCPA_UNASSOCIATE_CAMERA_WITH_USER_REQUEST,
    UCPA_UNASSOCIATE_CAMERA_WITH_USER_RESPONSE_ERRORS,
    UCPA_UNASSOCIATE_CAMERA_WITH_USER_RESPONSE_SUCCESS,
    UCPA_CHANGE_USER_CAMERA_WEIGHT_REQUEST,
    UCPA_CHANGE_USER_CAMERA_WEIGHT_RESPONSE_ERRORS,
    UCPA_CHANGE_USER_CAMERA_WEIGHT_RESPONSE_SUCCESS,
    UCPA_CHANGE_USER_PROJECT_WEIGHT_REQUEST,
    UCPA_CHANGE_USER_PROJECT_WEIGHT_RESPONSE_ERRORS,
    UCPA_CHANGE_USER_PROJECT_WEIGHT_RESPONSE_SUCCESS,
    UCPA_ADD_USER_CAMERAS,
    UCPA_REMOVE_USER_CAMERAS,
    UCPA_ADD_USER_PROJECTS,
    UCPA_REMOVE_USER_PROJECTS,
    UCPA_UPDATE_PROJECT_CAMERA_WEIGHTS,
    UCPA_RESET_ALL,
    UCPA_RESET_CHANGE_USER_CAMERA_WEIGHT,
    UCPA_RESET_CHANGE_USER_PROJECT_WEIGHT,
    UCPA_RESET_UNASSOCIATE_PROJECT_WITH_USER,
    UCPA_RESET_ASSOCIATE_PROJECT_WITH_USER,
    UCPA_RESET_UNASSOCIATE_CAMERA_WITH_USER,
    UCPA_RESET_ASSOCIATE_CAMERA_WITH_USER,
    UCPA_RESET_ADD_USER_CAMERAS,
    UCPA_RESET_REMOVE_USER_CAMERAS,
    UCPA_RESET_ADD_USER_PROJECTS,
    UCPA_RESET_REMOVE_USER_PROJECTS,
    UCPA_PROCESSING
} from '../actions/actionTypes';

export default function ucpa(state = initialState.ucpa, action) {

    switch (action.type) {

        case UCPA_FETCH_PROJECTS_REQUEST:
            return Object.assign(
                {},
                state,
                {
                  clients_id: action.clients_id
                },
                {
                    projects: {
                        loading: true,
                        success: false,
                        error: false,
                        errors: {},
                        projects: []
                    }
                }
            )

        case UCPA_FETCH_PROJECTS_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    projects: {
                        loading: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        projects: []
                    }
                }
            )

        case UCPA_FETCH_PROJECTS_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    projects: {
                        loading: false,
                        success: true,
                        error: false,
                        errors: {},
                        projects: action.projects.projects
                    }
                }
            )

        case UCPA_FETCH_PROJECT_CAMERAS_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    cameras: {
                        projects_id: action.projects_id,
                        loading: true,
                        success: false,
                        error: false,
                        errors: {},
                        cameras: []
                    }
                }
            )

        case UCPA_FETCH_PROJECT_CAMERAS_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    cameras: {
                        projects_id: action.projects_id,
                        loading: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        cameras: []
                    }
                }
            )

        case UCPA_FETCH_PROJECT_CAMERAS_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    cameras: {
                        projects_id: action.projects_id,
                        loading: false,
                        success: true,
                        error: false,
                        errors: {},
                        cameras: action.cameras.cameras
                    }
                }
            )

        case UCPA_RESET_PROJECT_CAMERAS:
            return Object.assign(
                {},
                state,
                {
                    cameras: {
                        projects_id: undefined,
                        loading: false,
                        success: false,
                        error: false,
                        errors: {},
                        cameras: []
                    }
                }
            )

        case UCPA_FETCH_USER_CAMERAS_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    user_cameras: {
                        cameras: [],
                        loading: true,
                        success: false,
                        error: false,
                        errors: {}
                    }
                }
            )

        case UCPA_FETCH_USER_CAMERAS_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    user_cameras: {
                        cameras: [],
                        loading: false,
                        success: false,
                        error: true,
                        errors: action.errors
                    }
                }
            )

        case UCPA_FETCH_USER_CAMERAS_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    user_cameras: {
                        cameras: action.cameras,
                        loading: false,
                        success: true,
                        error: false,
                        errors: {}
                    }
                }
            )

        case UCPA_FETCH_USER_PROJECTS_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    user_projects: {
                        projects: [],
                        loading: true,
                        success: false,
                        error: false,
                        errors: {}
                    }
                }
            )

        case UCPA_FETCH_USER_PROJECTS_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    user_projects: {
                        projects: [],
                        loading: false,
                        success: false,
                        error: true,
                        errors: action.errors
                    }
                }
            )

        case UCPA_FETCH_USER_PROJECTS_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    user_projects: {
                        projects: action.projects,
                        loading: false,
                        success: true,
                        error: false,
                        errors: {}
                    }
                }
            )

        case UCPA_ASSOCIATE_PROJECT_WITH_USER_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    associate_project_with_user: {
                        projects_id: action.projects_id,
                        users_id: action.users_id,
                        processing: true,
                        success: false,
                        error: false,
                        errors: {},
                        details: {},
                        message: null
                    }
                }
            )

        case UCPA_ASSOCIATE_PROJECT_WITH_USER_RESPONSE_ERRORS:
            {
                // Having to tidy up working folder...
                let projects = state.add_user_projects.slice()
                const key = projects.findIndex((project) => {
                    return project.id === action.projects_id
                })

                if (key !== -1) {
                    projects.splice(key, 1)
                }
                return Object.assign(
                    {},
                    state,
                    {
                        associate_project_with_user: {
                            projects_id: action.projects_id,
                            users_id: action.users_id,
                            processing: false,
                            success: false,
                            error: true,
                            errors: action.errors,
                            details: {},
                            message: null
                        }
                    }
                )

            }

        case UCPA_ASSOCIATE_PROJECT_WITH_USER_RESPONSE_SUCCESS:
            {
                // Having to tidy up working folder...
                let projects = state.add_user_projects.slice()
                const key = projects.findIndex((project) => {
                    return project.id === action.projects_id
                })
                if (key !== -1) {
                    projects.splice(key, 1)
                }
                return Object.assign(
                    {},
                    state,
                    {
                        associate_project_with_user: {
                            projects_id: action.projects_id,
                            users_id: action.users_id,
                            processing: false,
                            success: true,
                            error: false,
                            errors: {},
                            details: action.details,
                            message: action.message
                        },
                        add_user_projects: projects
                    }
                )

            }

        case UCPA_UNASSOCIATE_PROJECT_WITH_USER_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    unassociate_project_with_user: {
                        projects_id: action.projects_id,
                        users_id: action.users_id,
                        processing: true,
                        success: false,
                        error: false,
                        errors: {},
                        message: null
                    },
                }
            )

        case UCPA_UNASSOCIATE_PROJECT_WITH_USER_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    unassociate_project_with_user: {
                        projects_id: action.projects_id,
                        users_id: action.users_id,
                        processing: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        message: null
                    },
                }
            )

        case UCPA_UNASSOCIATE_PROJECT_WITH_USER_RESPONSE_SUCCESS:
            {
                // We need to remove project from working folder
                let projects = state.remove_user_projects.slice()
                const key = projects.findIndex((project) => {
                    return project.id === action.projects_id
                })
                if (key !== -1) {
                    projects.splice(key, 1)
                }
                return Object.assign(
                    {},
                    state,
                    {
                        unassociate_project_with_user: {
                            projects_id: action.projects_id,
                            users_id: action.users_id,
                            processing: false,
                            success: true,
                            error: false,
                            errors: {},
                            message: action.message
                        },
                        remove_user_projects: projects
                    }
                )
            }

        case UCPA_ASSOCIATE_CAMERA_WITH_USER_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    associate_camera_with_user: {
                        cameras_id: action.cameras_id,
                        users_id: action.users_id,
                        processing: true,
                        success: false,
                        error: false,
                        errors: {},
                        details: {},
                        message: null
                    }
                }
            )

        case UCPA_ASSOCIATE_CAMERA_WITH_USER_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    associate_camera_with_user: {
                        cameras_id: action.cameras_id,
                        users_id: action.users_id,
                        processing: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: {},
                        message: null
                    }
                }
            )

        case UCPA_ASSOCIATE_CAMERA_WITH_USER_RESPONSE_SUCCESS:
            {
                // Having to tidy up working folder...
                let cameras = state.add_user_cameras.slice()
                const key = cameras.findIndex((camera) => {
                    return camera.id === action.cameras_id
                })
                if (key !== -1) {
                    cameras.splice(key, 1)
                }
                return Object.assign(
                    {},
                    state,
                    {
                        associate_camera_with_user: {
                            cameras_id: action.cameras_id,
                            users_id: action.users_id,
                            processing: false,
                            success: true,
                            error: false,
                            errors: {},
                            details: action.details,
                            message: action.message
                        },
                        add_user_cameras: cameras
                    }
                )

            }

        case UCPA_UNASSOCIATE_CAMERA_WITH_USER_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    unassociate_camera_with_user: {
                        cameras_id: action.cameras_id,
                        users_id: action.users_id,
                        processing: true,
                        success: false,
                        error: false,
                        errors: {},
                        message: null
                    }
                }
            )

        case UCPA_UNASSOCIATE_CAMERA_WITH_USER_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    unassociate_camera_with_user: {
                        cameras_id: action.cameras_id,
                        users_id: action.users_id,
                        processing: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        message: null
                    }
                }
            )

        case UCPA_UNASSOCIATE_CAMERA_WITH_USER_RESPONSE_SUCCESS:
            {
                // Having to tidy up working folder...
                let cameras = state.remove_user_cameras.slice()
                const key = cameras.findIndex((camera) => {
                    return camera.id === action.cameras_id
                })
                if (key !== -1) {
                    cameras.splice(key, 1)
                }
                return Object.assign(
                    {},
                    state,
                    {
                        unassociate_camera_with_user: {
                            cameras_id: action.cameras_id,
                            users_id: action.users_id,
                            processing: false,
                            success: true,
                            error: false,
                            errors: {},
                            message: action.message
                        },
                        remove_user_cameras: cameras
                    }
                )

            }

        case UCPA_CHANGE_USER_CAMERA_WEIGHT_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    change_camera_weight: {
                        cameras_id: action.cameras_id,
                        users_id: action.users_id,
                        processing: true,
                        success: false,
                        error: false,
                        errors: {},
                        details: {},
                        message: null
                    }
                }
            )

        case UCPA_CHANGE_USER_CAMERA_WEIGHT_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    change_camera_weight: {
                        cameras_id: action.cameras_id,
                        users_id: action.users_id,
                        processing: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: {},
                        message: null
                    }
                }
            )

        case UCPA_CHANGE_USER_CAMERA_WEIGHT_RESPONSE_SUCCESS:
            {
                // Having to tidy up working folder...
                let items = state.project_camera_weights.slice()
                const key = items.findIndex((item) => {
                    return item.id === action.cameras_id && 'pot_id' in item === true
                })
                if (key !== -1) {
                    items.splice(key, 1)
                }
                return Object.assign(
                    {},
                    state,
                    {
                        change_camera_weight: {
                            cameras_id: action.cameras_id,
                            users_id: action.users_id,
                            processing: false,
                            success: true,
                            error: false,
                            errors: {},
                            details: action.details,
                            message: action.message
                        },
                        project_camera_weights: items
                    }
                )
            }

        case UCPA_CHANGE_USER_PROJECT_WEIGHT_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    change_project_weight: {
                        projects_id: action.projects_id,
                        users_id: action.users_id,
                        processing: true,
                        success: false,
                        error: false,
                        errors: {},
                        details: {},
                        message: null
                    }
                }
            )

        case UCPA_CHANGE_USER_PROJECT_WEIGHT_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    change_project_weight: {
                        projects_id: action.projects_id,
                        users_id: action.users_id,
                        processing: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: {},
                        message: null
                    }
                }
            )

        case UCPA_CHANGE_USER_PROJECT_WEIGHT_RESPONSE_SUCCESS:
            {
                // Having to tidy up working folder...
                let items = state.project_camera_weights.slice()
                const key = items.findIndex((item) => {
                    return item.id === action.projects_id && 'pot_id' in item === false
                })

                if (key !== -1) {
                    items.splice(key, 1)
                }

                return Object.assign(
                    {},
                    state,
                    {
                        change_project_weight: {
                            projects_id: action.projects_id,
                            users_id: action.users_id,
                            processing: false,
                            success: true,
                            error: false,
                            errors: {},
                            details: action.details,
                            message: action.message
                        },
                        project_camera_weights: items
                    }
                )

            }

        case UCPA_ADD_USER_CAMERAS:
            return Object.assign(
                {},
                state,
                {
                    add_user_cameras: action.cameras
                }
            )

        case UCPA_REMOVE_USER_CAMERAS:
            return Object.assign(
                {},
                state,
                {
                    remove_user_cameras: action.cameras
                }
            )

        case UCPA_ADD_USER_PROJECTS:
            return Object.assign(
                {},
                state,
                {
                    add_user_projects: action.projects
                }
            )

        case UCPA_REMOVE_USER_PROJECTS:
            return Object.assign(
                {},
                state,
                {
                    remove_user_projects: action.projects
                }
            )

        case UCPA_UPDATE_PROJECT_CAMERA_WEIGHTS:
            return Object.assign(
                {},
                state,
                {
                    project_camera_weights: action.project_camera_weights
                }
            )

        case UCPA_RESET_ALL:
            return Object.assign(
                {},
                initialState.ucpa
            )

        case UCPA_RESET_CHANGE_USER_CAMERA_WEIGHT:
            return Object.assign(
                {},
                state,
                {
                    change_camera_weight: {
                        ...initialState.ucpa.change_camera_weight
                    }
                }
            )

        case UCPA_RESET_CHANGE_USER_PROJECT_WEIGHT:
            return Object.assign(
                {},
                state,
                {
                    change_project_weight: {
                        ...initialState.ucpa.change_project_weight
                    }
                }
            )

        case UCPA_RESET_UNASSOCIATE_PROJECT_WITH_USER:
            return Object.assign(
                {},
                state,
                {
                    unassociate_project_with_user: {
                        ...initialState.ucpa.unassociate_project_with_user
                    }
                }
            )

        case UCPA_RESET_ASSOCIATE_PROJECT_WITH_USER:
            return Object.assign(
                {},
                state,
                {
                    associate_project_with_user: {
                        ...initialState.ucpa.associate_project_with_user
                    }
                }
            )

        case UCPA_RESET_UNASSOCIATE_CAMERA_WITH_USER:
            return Object.assign(
                {},
                state,
                {
                    unassociate_camera_with_user: {
                        ...initialState.ucpa.unassociate_camera_with_user
                    }
                }
            )

        case UCPA_RESET_ASSOCIATE_CAMERA_WITH_USER:
            return Object.assign(
                {},
                state,
                {
                    associate_camera_with_user: {
                        ...initialState.ucpa.associate_camera_with_user
                    }
                }
            )

        case UCPA_RESET_ADD_USER_CAMERAS:
            return Object.assign(
                {},
                state,
                {
                    add_user_cameras: initialState.ucpa.add_user_cameras
                }
            )

        case UCPA_RESET_REMOVE_USER_CAMERAS:
            return Object.assign(
                {},
                state,
                {
                    remove_user_cameras: initialState.ucpa.remove_user_cameras
                }
            )

        case UCPA_RESET_ADD_USER_PROJECTS:
            return Object.assign(
                {},
                state,
                {
                    add_user_projects: initialState.ucpa.add_user_projects
                }
            )

        case UCPA_RESET_REMOVE_USER_PROJECTS:
            return Object.assign(
                {},
                state,
                {
                    remove_user_projects: initialState.ucpa.remove_user_projects
                }
            )

        case UCPA_PROCESSING:
            return Object.assign(
                {},
                state,
                {
                    processing: action.processing
                }
            )

        default:
            return state
    }
}
