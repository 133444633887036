import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
    Button,
    Modal,
    Message,
    Form,
    Select,
    Grid,
    Icon
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { getErrorMessage } from './../../util/errors'
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import ProjectCameras from './../forms/ProjectCameras'
import LogoUploader from "../LogoUploader";

const UpdateProjectModal = (props) => {
    const [configValues, setConfigValues] = useState({
        name: "",
        description: "",
        location: "",
        latitude: null,
        longitude: null,
        start: null,
        finish: null,
        status: "New",
        watermark: null,
        startDate: null,
        finishDate: null,
        add_cameras: [],
        remove_cameras: [],
        cameras: [],
        default_weights: [],
        uploadable: false,
        user_configured: 1,
    })

    //const prevProps = useRef(props.details)

    // @TODO Create a utiity import to save duplicating these functions
    // between Create and Update

    const onOpen = (event, data) => {
        props.updateProjectActions.resetErrors()
        props.camerasActions.fetchAvailableCameras()
        props.updateProjectActions.projectDetailsRequest(
            props.clientId, props.projectId
        )
    }

    const onConfirm = (event, data) => {
        props.updateProjectActions.updateProjectRequest(
            props.clientId, props.projectId, configValues
        )
    }

    useEffect(() => {
        if (props.success === true) {
            onClose()
            props.updateProjectActions.resetErrors()
        } else {
            const start = (configValues.start) ? configValues.start : props.details.start
            const finish = (configValues.finish) ? configValues.finish : props.details.finish
            setConfigValues(prevState => {
                return {
                    name: props.details.name,
                    description: props.details.description,
                    location: props.details.location,
                    latitude: props.details.latitude,
                    longitude: props.details.longitude,
                    start: start,
                    finish: finish,
                    status: configValues.status ? configValues.status : props.details.status,
                    watermark: props.details.watermark,
                    startDate: (start) ? new moment(start).toDate() : null,
                    finishDate: (finish) ? new moment(finish).toDate() : null,
                    add_cameras: props.details.add_cameras,
                    remove_cameras: props.details.remove_cameras,
                    cameras: props.details.cameras,
                    default_weights: props.details.default_weights,
                    user_configured: configValues.user_configured,
                }
            })
        }
    }, [props.success, props.details, configValues.start, configValues.finish, configValues.status])

    const onStartDateChange = (date) => {
        if (date) {
            const formattedDate = moment(date, "YYMMDDHHmmss")
            setConfigValues(prevState => {
                return {...prevState, startDate: date, start: formattedDate.format("YYYY-MM-DD")}
            })
        } else {
            setConfigValues(prevState => {
                return {...prevState, startDate: "", start: ""}
            })
        }
    }

    const onFinishDateChange = (date) => {
        if (date) {
            const formattedDate = moment(date, "YYMMDDHHmmss")
            setConfigValues(prevState => {
                return {...prevState, finishDate: date, finish: formattedDate.format("YYYY-MM-DD")}
            })
        } else {
            setConfigValues(prevState => {
                return {...prevState, finishDate: "", finish: ""}
            })
        }
    }

    const onNameChange = (event, {value}) => {
        setConfigValues(prevState => {
            return {...prevState, name: value}
        })
    }

    const onDescriptionChange = (event, {value}) => {
         setConfigValues(prevState => {
            return {...prevState, description: value}
         })
    }

    const onLocationChange = (event, {value}) => {
        setConfigValues(prevState => {
            return {...prevState, location: value}
        })
    }

    const onLatitudeChange = (event, {value}) => {
        setConfigValues(prevState => {
            return {...prevState, latitude: value}
        })
    }

    const onLongitudeChange = (event, {value}) => {
        setConfigValues(prevState => {
            return {...prevState, longitude: value}
        })
    }

    const onStatusChange = (event, {value}) => {
        setConfigValues(prevState => {
            return {...prevState, status: value}
        })
    }

    const onWatermarkChange = (event, {checked}) => {
        setConfigValues(prevState => {
            return {...prevState, watermark: checked}
        })
    }

    const onClose = () => {
        props.onClose()
        //setConfigValues(initialState)
        // this.setState(this.getInitialConfig(), () => {
        //     this.props.onClose()
        // })
    }


    const getContent = (error, success, loading, errors) => {

        if (!isReady()) {
            return null
        }

        // We might have a server error, or just regular errors
        if (error) {
            const unexpectedErrorMessage = getErrorMessage(props.errors, 'unexpected')
            const addCamerasErrorMessage = getErrorMessage(props.errors, 'add_cameras')
            return (

                <div>
                    <p className='error'>{unexpectedErrorMessage}</p>
                    <p className='error'>{addCamerasErrorMessage}</p>
                    {renderForm()}
                </div>

            )
        } else if (success) {
            return <p>Successfully created project</p>
        } else if (loading) {
            return renderForm()
        } else {
            // Must be initial loading...
            return renderForm()
        }
    }

    const onUploadable = (status) => {
        setConfigValues(prevState => {
            return {...prevState, uploadable: status}
        })
    }

    const renderForm = () => {
        // if (!this.isReady()) {
        //     return null
        // }

        let isLobsterUser = false
        if (props.auth && props.auth.user && props.auth.user.roles_id !== 4) {
            isLobsterUser = true;
        }

        // Let's try something with the cameras....
        let deployedCameras = props.deployedCameras
        let weightedDeployedCameras = []
        deployedCameras.forEach((c) => {
            // Let's search for it in configValues.default_weights
            const matching = configValues.default_weights.find((c1) => {
                return c1.cameras_id === c.key
            })
            if (matching) {
                weightedDeployedCameras.push(Object.assign({}, c, { default_weight: matching.default_weight}))
            } else {
                weightedDeployedCameras.push(Object.assign({}, c))
            }
        })

        return (
            <Form size='large'>
                <Grid columns='2' stackable>
                    <Grid.Row>
                        <Grid.Column>
                        {isLobsterUser && <>
                            <Form.Input
                                fluid
                                placeholder='Project Name'
                                label='Project Name'
                                onChange={onNameChange}
                                defaultValue={props.details.name}
                                error={props.errors.name ? true : false}
                            />
                            <Message
                                error={true}
                                visible={props.errors.name !== undefined}
                                header={''}
                                content={getErrorMessage(props.errors, 'name')}
                            />
                        </>}
                            <Form.TextArea
                                placeholder='Description'
                                label='Description'
                                onChange={onDescriptionChange}
                                error={props.errors.description ? true : false }
                                defaultValue={props.details.description}
                            />
                            <Message
                                error={true}
                                visible={props.errors.description !== undefined}
                                header={''}
                                content={getErrorMessage(props.errors, 'description')}
                            />
                            {isLobsterUser && <>
                                <Form.Field
                                    control={Select}
                                    options={props.statuses}
                                    placeholder='Status'
                                    label='Status'
                                    onChange={onStatusChange}
                                    error={props.errors.status ? true : false}
                                    defaultValue={props.details.status}

                                />
                                <Message
                                    error={true}
                                    visible={props.errors.status}
                                    header={''}
                                    content={getErrorMessage(props.errors, 'status')}
                                />
                            </>}
                            {isLobsterUser && props.auth.user.roles_id !== 5 && <>
                                <Form.Checkbox
                                    label={configValues.watermark ? 'Watermark Enabled (Click to disable)' : 'Watermark Disabled (Click to enable)'}
                                    checked={configValues.watermark || false}
                                    onChange={onWatermarkChange}
                                    toggle
                                />
                                <Message
                                    error={true}
                                    visible={props.errors.watermark}
                                    header={''}
                                    content={getErrorMessage(props.errors, 'watermark')}
                                />
                            </>}
                            <div className='logo-uploader-container'>
                                <LogoUploader
                                    project={props.project}
                                    updateProjectActions={props.updateProjectActions}
                                    logoActions={props.logoActions}
                                    logo={props.logo}
                                    onUploadable={onUploadable}
                                    user={props.user}
                                    projectId={props.projectId}
                                    clientId={props.clientId}
                                    dimensionText={'450px x 300px'}
                                />
                            </div>
                        </Grid.Column>
                        <Grid.Column>
                            <Form.Input
                                fluid
                                placeholder='Location'
                                label='Location'
                                onChange={onLocationChange}
                                error={props.errors.location ? true : false}
                                defaultValue={props.details.location}
                            />
                            <Message
                                error={true}
                                visible={props.errors.location !== undefined}
                                header={''}
                                content={getErrorMessage(props.errors, 'location')}
                            />
                            <Grid columns='2'>
                                <Grid.Row className='no-padding-bottom'>
                                    <Grid.Column>
                                        <Form.Input
                                            fluid
                                            type="number"
                                            placeholder='Latitude'
                                            label='Latitude'
                                            onChange={onLatitudeChange}
                                            error={props.errors.latitude ? true : false}
                                            defaultValue={props.details.latitude}
                                        />
                                        <Message
                                            error={true}
                                            visible={props.errors.latitude !== undefined}
                                            header={''}
                                            content={getErrorMessage(props.errors, 'latitude')}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Form.Input
                                            fluid
                                            type="number"
                                            placeholder='Longitude'
                                            label='Longitude'
                                            onChange={onLongitudeChange}
                                            error={props.errors.longitude ? true : false}
                                            defaultValue={props.details.longitude}
                                        />
                                        <Message
                                            error={true}
                                            visible={props.errors.longitude !== undefined}
                                            header={''}
                                            content={getErrorMessage(props.errors, 'longitude')}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row className={(props.errors.latitude || props.errors.longitude) ? '' : 'no-padding-top'}>
                                    <Grid.Column>
                                        <div className="ui field">
                                            <label>Start Date</label>
                                        </div>
                                        <div className='projectStartDateContainer'>
                                            <DatePicker
                                                placeholderText='Start'
                                                dateFormat="do MMM yy"
                                                selected={configValues.startDate}
                                                onChange={onStartDateChange}
                                            />
                                            <Icon className='datePickerIcon' name='calendar'/>
                                        </div>
                                        <Message
                                            className='projectDateErrors'
                                            error={true}
                                            visible={props.errors.start !== undefined}
                                            header={''}
                                            content={getErrorMessage(props.errors, 'start')}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <div className="ui field">
                                            <label>Finish Date</label>
                                        </div>
                                        <div className='projectFinishDateContainer'>
                                            <DatePicker
                                                placeholderText='Finish'
                                                dateFormat="do MMM yy"
                                                selected={configValues.finishDate}
                                                onChange={onFinishDateChange}
                                            />
                                            <Icon className='datePickerIcon' name='calendar'/>
                                        </div>
                                        <Message
                                            className='projectDateErrors'
                                            error={true}
                                            visible={props.errors.finish !== undefined}
                                            header={''}
                                            content={getErrorMessage(props.errors, 'finish')}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                {isLobsterUser && <ProjectCameras
                    // Existing data source
                    availableCameras={props.availableCameras}
                    deployedCameras={weightedDeployedCameras}
                    add_cameras={props.projectCameras.add_cameras}
                    remove_cameras={props.projectCameras.remove_cameras}
                    camera_weights={props.projectCameras.camera_weights}
                    // Pushing local state into Redux here
                    onAddCameras={(cameras) => {
                        props.onAddCameras(cameras)
                    }}
                    onRemoveCameras={ (cameras) => {
                        props.onRemoveCameras(cameras)}
                    }
                    onCameraWeights={ (cameras) => {
                        props.onCameraWeights(cameras)}
                    }
                    projectCameras={props.projectCameras}
                />}
            </Form>)
    }

    const getButtons = (error, success) => {
        if (success) {
            return <div><Button id="closeProjectModalButton" onClick={onClose} positive primary>Close</Button></div>
        } else {
            const removeProjectLink =
                props.permittedToDelete ?
                <Button
                    style={{
                        float: 'left'
                    }}
                    as={Link}
                    icon='trash'
                    color='red'
                    to={`/clients/${props.clientId}/projects/${props.projectId}/delete`}
                    content='Remove'
                /> : null

            return (
                 <div>
                    {removeProjectLink}
                    <Button
                        id="updateProjectButton"
                        onClick={onConfirm}
                        loading={props.processing}
                        positive
                        icon='checkmark'
                        labelPosition='right'
                        content='Update'
                    />
                </div>
            )
        }
    }

    // const getMessage = () => {
    //     if (!isReady()) {
    //         return null
    //     }
    //     return  (
    //         <div>
    //             {getContent(props.error, props.success, props.loading, props.errors)}
    //         </div>
    //     )
    // }

    const getPreloader = () => {
        if (!isReady()) {
            return (
                <div>
                    <div className='animated-blank-line'></div>
                    <div className='animated-blank-line'></div>
                    <div className='animated-blank-line'></div>
                    <Grid columns='2'>
                        <Grid.Row>
                            <Grid.Column>
                                <div className='animated-blank-block-200'></div>
                            </Grid.Column>
                            <Grid.Column>
                                <div className='animated-blank-block-200'></div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <div className='animated-blank-line'></div>
                    <Grid columns='2'>
                        <Grid.Row className='no-padding-bottom'>
                            <Grid.Column>
                                <div className='animated-blank-line'></div>
                            </Grid.Column>
                            <Grid.Column>
                                <div className='animated-blank-line'></div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <div className='animated-blank-line'></div>
                            </Grid.Column>
                            <Grid.Column>
                                <div className='animated-blank-line'></div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <div className='animated-blank-line'></div>
                </div>
            )
        }
        return null
    }

    const isReady = () => {
        if (props.success) {
            return true
        }
        return (props.details.name) ? true : false
    }

    return (
        <Modal
            size={"large"}
            open={props.open}
            onOpen={onOpen}
            onMount={onOpen}
            onClose={onClose}
            closeIcon
        >
            <Modal.Header>Edit Project</Modal.Header>
            <Modal.Content>
                {getPreloader()}

                <div>
                    {getContent(props.error, props.success, props.loading, props.errors)}
                </div>

                <div className='modalButtons'>
                    {getButtons(props.error, props.success)}
                </div>

            </Modal.Content>
        </Modal>
    )

}

UpdateProjectModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    processing: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    statuses: PropTypes.array.isRequired,
    project: PropTypes.object.isRequired,
    success: PropTypes.bool.isRequired,
    details: PropTypes.object.isRequired,
    availableCameras: PropTypes.array.isRequired,
    deployedCameras: PropTypes.array.isRequired,
    projectCameras: PropTypes.object.isRequired,
    onAddCameras: PropTypes.func.isRequired,
    onRemoveCameras: PropTypes.func.isRequired,
    onCameraWeights: PropTypes.func.isRequired
}

export default UpdateProjectModal
