export const LOGIN_REQUEST                                                  = 'LOGIN_REQUEST'
export const LOGIN_RESPONSE                                                 = 'LOGIN_RESPONSE'
export const LOGIN_ERRORS                                                   = 'LOGIN_ERRORS'
export const LOGIN_SUCCESS                                                  = 'LOGIN_SUCCESS'
export const LOGIN_RESET_ERRORS                                             = 'LOGIN_RESET_ERRORS'
export const REFRESH_ACCESS_TOKEN_REQUEST                                   = 'REFRESH_ACCESS_TOKEN_REQUEST'
export const REFRESH_ACCESS_TOKEN_RESPONSE                                  = 'REFRESH_ACCESS_TOKEN_RESPONSE'
export const REFRESH_ACCESS_TOKEN_ERROR                                     = 'REFRESH_ACCESS_TOKEN_ERROR'
export const LOGOUT_REQUEST                                                 = 'LOGOUT_REQUEST'
export const LOGOUT_RESPONSE                                                = 'LOGOUT_RESPONSE'
export const SHOW_HEADER                                                    = 'SHOW_HEADER'
export const HIDE_HEADER                                                    = 'HIDE_HEADER'
export const SHOW_LED_CHART                                                 = 'SHOW_LED_CHART'
export const HIDE_LED_CHART                                                 = 'HIDE_LED_CHART'
export const SHOW_FOOTER                                                    = 'SHOW_FOOTER'
export const HIDE_FOOTER                                                    = 'HIDE_FOOTER'
export const LOGGED_IN_USER_DETAILS_REQUEST                                 = 'LOGGED_IN_USER_DETAILS_REQUEST'
export const LOGGED_IN_USED_DETAILS_RESPONSE                                = 'LOGGED_IN_USED_DETAILS_RESPONSE'
export const PASSWORD_INPUT_CHANGE                                          = 'PASSWORD_INPUT_CHANGE'
export const COMFIRM_PASSWORD_INPUT_CHANGE                                  = 'COMFIRM_PASSWORD_INPUT_CHANGE'
export const PASSWORD_CHANGE_SUBMIT                                         = 'PASSWORD_CHANGE_SUBMIT'
export const PASSWORD_CHANGE_SUCCESS                                        = 'PASSWORD_CHANGE_SUCCESS'
export const PASSWORD_CHANGE_ERROR                                          = 'PASSWORD_CHANGE_ERROR'
export const PASSWORD_RESET_ERRORS                                          = 'PASSWORD_RESET_ERRORS'
export const LP4_PASSWORD_UPDATE_SUCCESS                                    = 'LP4_PASSWORD_UPDATE_SUCCESS'
export const LP4_PASSWORD_UPDATE_ERROR                                      = 'LP4_PASSWORD_UPDATE_ERROR'
export const PASSWORD_RESET_REQUEST_SUBMIT                                  = 'PASSWORD_RESET_REQUEST_SUBMIT'
export const PASSWORD_RESET_REQUEST_SUCCESS                                 = 'PASSWORD_RESET_REQUEST_SUCCESS'
export const PASSWORD_RESET_REQUEST_ERROR                                   = 'PASSWORD_RESET_REQUEST_ERROR'
export const PASSWORD_VALIDATION_REQUEST                                    = 'PASSWORD_VALIDATION_REQUEST'
export const PASSWORD_VALIDATION_SUCCESS                                    = 'PASSWORD_VALIDATION_SUCCESS'
export const PASSWORD_VALIDATION_ERRORS                                     = 'PASSWORD_VALIDATION_ERRORS'
export const USER_LIST_REQUEST                                              = 'USER_LIST_REQUEST'
export const USER_LIST_RESPONSE                                             = 'USER_LIST_RESPONSE'
export const USER_LIST_ERRORS                                               = 'USER_LIST_ERRORS'
export const USER_LIST_BULK_EDIT                                            = 'USER_LIST_BULK_EDIT'
export const USER_LIST_BULK_EDIT_REMOVE                                     = 'USER_LIST_BULK_EDIT_REMOVE'
export const USER_LIST_BULK_EDIT_RESET                                      = 'USER_LIST_BULK_EDIT_RESET'
export const USER_LIST_FILTER_BY                                            = 'USER_LIST_FILTER_BY'
export const USER_LIST_SORT_BY                                              = 'USER_LIST_SORT_BY'
export const USER_LIST_BULK_EDIT_SELECT                                     = 'USER_LIST_BULK_EDIT_SELECT'
export const ADD_USER_REQUEST                                               = 'ADD_USER_REQUEST'
export const ADD_USER_RESPONSE                                              = 'ADD_USER_RESPONSE'
export const ADD_USER_RESPONSE_SUCCESS                                      = 'ADD_USER_RESPONSE_SUCCESS'
export const ADD_USER_RESPONSE_ERRORS                                       = 'ADD_USER_RESPONSE_ERRORS'
export const ADMIN_USER_ROLES_REQUEST                                       = 'ADMIN_USER_ROLES_REQUEST'
export const ADMIN_USER_ROLES_RESPONSE                                      = 'ADMIN_USER_ROLES_RESPONSE'
export const ADMIN_USER_STATUSES_REQUEST                                    = 'ADMIN_USER_STATUSES_REQUEST'
export const ADMIN_USER_STATUSES_RESPONSE                                   = 'ADMIN_USER_STATUSES_RESPONSE'
export const ADMIN_USER_CLIENTS_REQUEST                                     = 'ADMIN_USER_CLIENTS_REQUEST'
export const ADMIN_USER_CLIENTS_RESPONSE                                    = 'ADMIN_USER_CLIENTS_RESPONSE'
export const ADD_USER_RESET_ERRORS                                          = 'ADD_USER_RESET_ERRORS'
export const NOT_AUTHORISED_RESPONSE                                        = 'NOT_AUTHORISED_RESPONSE'
export const EDIT_USER_REQUEST                                              = 'EDIT_USER_REQUEST'
export const EDIT_USER_DETAILS_REQUEST                                      = 'EDIT_USER_DETAILS_REQUEST'
export const EDIT_USER_DETAILS_RESPONSE                                     = 'EDIT_USER_DETAILS_RESPONSE'
export const EDIT_USER_BULK_DETAILS_RESPONSE                                = 'EDIT_USER_BULK_DETAILS_RESPONSE'
export const EDIT_USER_RESPONSE                                             = 'EDIT_USER_RESPONSE'
export const EDIT_USER_RESPONSE_ERRORS                                      = 'EDIT_USER_RESPONSE_ERRORS'
export const EDIT_USER_RESPONSE_SUCCESS                                     = 'EDIT_USER_RESPONSE_SUCCESS'
export const EDIT_USER_RESET_ERRORS                                         = 'EDIT_USER_RESET_ERRORS'
export const EDIT_USER_ROLES_REQUEST                                        = 'EDIT_USER_ROLES_REQUEST'
export const EDIT_USER_ROLES_RESPONSE                                       = 'EDIT_USER_ROLES_RESPONSE'
export const EDIT_USER_STATUSES_REQUEST                                     = 'EDIT_USER_STATUSES_REQUEST'
export const EDIT_USER_STATUSES_RESPONSE                                    = 'EDIT_USER_STATUSES_RESPONSE'
export const EDIT_USER_CLIENTS_REQUEST                                      = 'EDIT_USER_CLIENTS_REQUEST'
export const EDIT_USER_CLIENTS_RESPONSE                                     = 'EDIT_USER_CLIENTS_RESPONSE'
export const DELETE_USER_REQUEST                                            = 'DELETE_USER_REQUEST'
export const DELETE_USER_RESPONSE_ERRORS                                    = 'DELETE_USER_RESPONSE_ERRORS'
export const DELETE_USER_RESPONSE_SUCCESS                                   = 'DELETE_USER_RESPONSE_SUCCESS'
export const DELETE_USER_RESET_ERRORS                                       = 'DELETE_USER_RESET_ERRORS'
export const SUSPEND_USER_REQUEST                                           = 'SUSPEND_USER_REQUEST'
export const SUSPEND_USER_RESPONSE_ERRORS                                   = 'SUSPEND_USER_RESPONSE_ERRORS'
export const SUSPEND_USER_RESPONSE_SUCCESS                                  = 'SUSPEND_USER_RESPONSE_SUCCESS'
export const SUSPEND_USER_RESET_ERRORS                                      = 'SUSPEND_USER_RESET_ERRORS'
export const USER_VERIFICATION_EMAIL_REQUEST                                = 'USER_VERIFICATION_EMAIL_REQUEST'
export const USER_VERIFICATION_EMAIL_RESPONSE_ERRORS                        = 'USER_VERIFICATION_EMAIL_RESPONSE_ERRORS'
export const USER_VERIFICATION_EMAIL_RESPONSE_SUCCESS                       = 'USER_VERIFICATION_EMAIL_RESPONSE_SUCCESS'
export const USER_VERIFICATION_EMAIL_RESET_ERRORS                           = 'USER_VERIFICATION_EMAIL_RESET_ERRORS'
export const ADD_CLIENT_REQUEST                                             = 'ADD_CLIENT_REQUEST'
export const ADD_CLIENT_RESPONSE                                            = 'ADD_CLIENT_RESPONSE'
export const ADD_CLIENT_RESPONSE_SUCCESS                                    = 'ADD_CLIENT_RESPONSE_SUCCESS'
export const ADD_CLIENT_RESPONSE_ERRORS                                     = 'ADD_CLIENT_RESPONSE_ERRORS'
export const ADMIN_CLIENT_ROLES_REQUEST                                     = 'ADMIN_CLIENT_ROLES_REQUEST'
export const ADMIN_CLIENT_ROLES_RESPONSE                                    = 'ADMIN_CLIENT_ROLES_RESPONSE'
export const ADMIN_CLIENT_STATUSES_REQUEST                                  = 'ADMIN_CLIENT_STATUSES_REQUEST'
export const ADMIN_CLIENT_STATUSES_RESPONSE                                 = 'ADMIN_CLIENT_STATUSES_RESPONSE'
export const ADD_CLIENT_RESET_ERRORS                                        = 'ADD_CLIENT_RESET_ERRORS'
export const ADD_CLIENT_RESET_SUCCESS                                       = 'ADD_CLIENT_RESET_SUCCESS'
export const SECURITY_APPLY_LOCKDOWN_RESET_ERRORS                           = 'SECURITY_APPLY_LOCKDOWN_RESET_ERRORS'
export const SECURITY_APPLY_LOCKDOWN_REQUEST                                = 'SECURITY_APPLY_LOCKDOWN_REQUEST'
export const SECURITY_APPLY_LOCKDOWN_RESPONSE_SUCCESS                       = 'SECURITY_APPLY_LOCKDOWN_RESPONSE_SUCCESS'
export const SECURITY_APPLY_LOCKDOWN_RESPONSE_ERRORS                        = 'SECURITY_APPLY_LOCKDOWN_RESPONSE_ERRORS'
export const SECURITY_REMOVE_LOCKDOWN_RESET_ERRORS                          = 'SECURITY_REMOVE_LOCKDOWN_RESET_ERRORS'
export const SECURITY_REMOVE_LOCKDOWN_REQUEST                               = 'SECURITY_REMOVE_LOCKDOWN_REQUEST'
export const SECURITY_REMOVE_LOCKDOWN_RESPONSE_SUCCESS                      = 'SECURITY_REMOVE_LOCKDOWN_RESPONSE_SUCCESS'
export const SECURITY_REMOVE_LOCKDOWN_RESPONSE_ERRORS                       = 'SECURITY_REMOVE_LOCKDOWN_RESPONSE_ERRORS'
export const CAPTCHA_RECAPTCHA_REQUEST                                      = 'CAPTCHA_RECAPTCHA_REQUEST'
export const CAPTCHA_RECAPTCHA_RESPONSE_ERRORS                              = 'CAPTCHA_RECAPTCHA_RESPONSE_ERRORS'
export const CAPTCHA_RECAPTCHA_RESPONSE_SUCCESS                             = 'CAPTCHA_RECAPTCHA_RESPONSE_SUCCESS'
export const CAPTCHA_RECAPTCHA_RESET_ERRORS                                 = 'CAPTCHA_RECAPTCHA_RESET_ERRORS'
export const CLIENTS_REQUEST                                                = 'CLIENTS_REQUEST'
export const CLIENTS_RESPONSE_ERRORS                                        = 'CLIENTS_RESPONSE_ERRORS'
export const CLIENTS_RESPONSE_SUCCESS                                       = 'CLIENTS_RESPONSE_SUCCESS'
export const CLIENTS_MFA_REQUIREMENTS_REQUEST                               = 'CLIENTS_MFA_REQUIREMENTS_REQUEST'
export const CLIENTS_MFA_REQUIREMENTS_SUCCESS                               = 'CLIENTS_MFA_REQUIREMENTS_SUCCESS'
export const CLIENTS_MFA_REQUIREMENTS_ERROR                                 = 'CLIENTS_MFA_REQUIREMENTS_ERROR'
export const CLIENTS_FILTER_BY                                              = 'CLIENTS_FILTER_BY'
export const EDIT_CLIENT_REQUEST                                            = 'EDIT_CLIENT_REQUEST'
export const EDIT_CLIENT_RESPONSE_SUCCESS                                   = 'EDIT_CLIENT_RESPONSE_SUCCESS'
export const EDIT_CLIENT_RESPONSE_ERRORS                                    = 'EDIT_CLIENT_RESPONSE_ERRORS'
export const EDIT_CLIENT_RESET_ERRORS                                       = 'EDIT_CLIENT_RESET_ERRORS'
export const EDIT_CLIENT_RESET_SUCCESS                                      = 'EDIT_CLIENT_RESET_SUCCESS'
export const EDIT_CLIENT_DETAILS_REQUEST                                    = 'EDIT_CLIENT_DETAILS_REQUEST'
export const EDIT_CLIENT_DETAILS_RESPONSE                                   = 'EDIT_CLIENT_DETAILS_RESPONSE'
export const EDIT_CLIENT_DETAILS_REQUEST_ERRORS                             = 'EDIT_CLIENT_DETAILS_REQUEST_ERRORS'
export const VIEW_CLIENT_DETAILS_REQUEST                                    = 'VIEW_CLIENT_DETAILS_REQUEST'
export const VIEW_CLIENT_DETAILS_RESPONSE                                   = 'VIEW_CLIENT_DETAILS_RESPONSE'
export const VIEW_CLIENT_DETAILS_REQUEST_ERRORS                             = 'VIEW_CLIENT_DETAILS_REQUEST_ERRORS'
export const BULK_USERS_VALIDATE_EMAIL_REQUEST                              = 'BULK_USERS_VALIDATE_EMAIL_REQUEST'
export const BULK_USERS_VALIDATE_EMAIL_RESPONSE_SUCCESS                     = 'BULK_USERS_VALIDATE_EMAIL_RESPONSE_SUCCESS'
export const BULK_USERS_VALIDATE_EMAIL_RESPONSE_ERRORS                      = 'BULK_USERS_VALIDATE_EMAIL_RESPONSE_ERRORS'
export const BULK_USERS_VALIDATE_EMAIL_RESET                                = 'BULK_USERS_VALIDATE_EMAIL_RESET'
export const BULK_USERS_ADD_VALIDATED_EMAIL_TO_LIST                         = 'BULK_USERS_ADD_VALIDATED_EMAIL_TO_LIST'
export const BULK_USERS_REMOVE_EMAIL_FROM_LIST                              = 'BULK_USERS_REMOVE_EMAIL_FROM_LIST'
export const BULK_USERS_RESET                                               = 'BULK_USERS_RESET'
export const BULK_USERS_PROCESS_QUEUE_STARTED                               = 'BULK_USERS_PROCESS_QUEUE_STARTED'
export const BULK_USERS_PROCESS_QUEUE_UPDATED                               = 'BULK_USERS_PROCESS_QUEUE_UPDATED'
export const BULK_USERS_PROCESS_QUEUE_COMPLETED                             = 'BULK_USERS_PROCESS_QUEUE_COMPLETED'
export const BULK_USERS_VALIDATE_TEMPLATE_REQUEST                           = 'BULK_USERS_VALIDATE_TEMPLATE_REQUEST'
export const BULK_USERS_VALIDATE_TEMPLATE_RESPONSE_SUCCESS                  = 'BULK_USERS_VALIDATE_TEMPLATE_RESPONSE_SUCCESS'
export const BULK_USERS_VALIDATE_TEMPLATE_RESPONSE_ERRORS                   = 'BULK_USERS_VALIDATE_TEMPLATE_RESPONSE_ERRORS'
export const BULK_USERS_UPDATE_TEMPLATE                                     = 'BULK_USERS_UPDATE_TEMPLATE'
export const CLIENTS_RESET                                                  = 'CLIENTS_RESET'
export const USER_LIST_RESET                                                = 'USER_LIST_RESET'
export const CHECK_USER_LOCKDOWN_STATUS_REQUEST                             = 'CHECK_USER_LOCKDOWN_STATUS_REQUEST'
export const CHECK_USER_LOCKDOWN_STATUS_RESPONSE                            = 'CHECK_USER_LOCKDOWN_STATUS_RESPONSE'
export const MESSENGER_ADD_MESSAGE                                          = 'MESSENGER_ADD_MESSAGE'
export const MESSENGER_REMOVE_MESSAGE                                       = 'MESSENGER_REMOVE_MESSAGE'
export const MESSENGER_REMOVE_ALL_MESSAGES                                  = 'MESSENGER_REMOVE_ALL_MESSAGES'
export const LOG_REQUEST                                                    = 'LOG_REQUEST'
export const FORCED_OUT                                                     = 'FORCED_OUT'
export const PROJECT_LIST_REQUEST                                           = 'PROJECT_LIST_REQUEST'
export const PROJECT_LIST_RESPONSE                                          = 'PROJECT_LIST_RESPONSE'
export const PROJECT_LIST_ERRORS                                            = 'PROJECT_LIST_ERRORS'
export const PROJECT_LIST_RESET                                             = 'PROJECT_LIST_RESET'
export const PROJECT_LIST_FILTER_BY                                         = 'PROJECT_LIST_FILTER_BY'
export const RESET_LOGIN_COUNTER                                            = 'RESET_LOGIN_COUNTER'
export const DELETE_CLIENT_REQUEST                                          = 'DELETE_CLIENT_REQUEST'
export const DELETE_CLIENT_RESPONSE_SUCCESS                                 = 'DELETE_CLIENT_RESPONSE_SUCCESS'
export const DELETE_CLIENT_RESPONSE_ERRORS                                  = 'DELETE_CLIENT_RESPONSE_ERRORS'
export const CREATE_PROJECT_REQUEST                                         = 'CREATE_PROJECT_REQUEST'
export const CREATE_PROJECT_RESPONSE_SUCCESS                                = 'CREATE_PROJECT_RESPONSE_SUCCESS'
export const CREATE_PROJECT_RESPONSE_ERRORS                                 = 'CREATE_PROJECT_RESPONSE_ERRORS'
export const CREATE_PROJECT_RESET_ERRORS                                    = 'CREATE_PROJECT_RESET_ERRORS'
export const CREATE_PROJECT_CLIENTS_REQUEST                                 = 'CREATE_PROJECT_CLIENTS_REQUEST'
export const CREATE_PROJECT_CLIENTS_RESPONSE_SUCCESS                        = 'CREATE_PROJECT_CLIENTS_RESPONSE_SUCCESS'
export const CREATE_PROJECT_CLIENTS_RESPONSE_ERRORS                         = 'CREATE_PROJECT_CLIENTS_RESPONSE_ERRORS'
export const PROJECT_DETAILS_REQUEST                                        = 'PROJECT_DETAILS_REQUEST'
export const PROJECT_DETAILS_RESPONSE_SUCCESS                               = 'PROJECT_DETAILS_RESPONSE_SUCCESS'
export const PROJECT_DETAILS_RESPONSE_ERRORS                                = 'PROJECT_DETAILS_RESPONSE_ERRORS'
export const UPDATE_PROJECT_REQUEST                                         = 'UPDATE_PROJECT_REQUEST'
export const UPDATE_PROJECT_RESPONSE_SUCCESS                                = 'UPDATE_PROJECT_RESPONSE_SUCCESS'
export const UPDATE_PROJECT_RESPONSE_ERRORS                                 = 'UPDATE_PROJECT_RESPONSE_ERRORS'
export const UPDATE_PROJECT_RESET_ERRORS                                    = 'UPDATE_PROJECT_RESET_ERRORS'
export const DELETE_PROJECT_REQUEST                                         = 'DELETE_PROJECT_REQUEST'
export const DELETE_PROJECT_RESPONSE_SUCCESS                                = 'DELETE_PROJECT_RESPONSE_SUCCESS'
export const DELETE_PROJECT_RESPONSE_ERRORS                                 = 'DELETE_PROJECT_RESPONSE_ERRORS'
export const DELETE_PROJECT_RESET_ERRORS                                    = 'DELETE_PROJECT_RESET_ERRORS'
export const CAMERAS_FETCH_AVAILABLE_REQUEST                                = 'CAMERAS_FETCH_AVAILABLE_REQUEST'
export const CAMERAS_FETCH_AVAILABLE_RESPONSE_SUCCESS                       = 'CAMERAS_FETCH_AVAILABLE_RESPONSE_SUCCESS'
export const CAMERAS_FETCH_AVAILABLE_RESPONSE_ERRORS                        = 'CAMERAS_FETCH_AVAILABLE_RESPONSE_ERRORS'
export const CAMERAS_ASSOCIATE_CAMERA_WITH_PROJECT_REQUEST                  = 'CAMERAS_ASSOCIATE_CAMERA_WITH_PROJECT_REQUEST'
export const CAMERAS_ASSOCIATE_CAMERA_WITH_PROJECT_RESPONSE_SUCCESS         = 'CAMERAS_ASSOCIATE_CAMERA_WITH_PROJECT_RESPONSE_SUCCESS'
export const CAMERAS_ASSOCIATE_CAMERA_WITH_PROJECT_RESPONSE_ERRORS          = 'CAMERAS_ASSOCIATE_CAMERA_WITH_PROJECT_RESPONSE_ERRORS'
export const CAMERAS_UNASSOCIATE_CAMERA_WITH_PROJECT_REQUEST                = 'CAMERAS_UNASSOCIATE_CAMERA_WITH_PROJECT_REQUEST'
export const CAMERAS_UNASSOCIATE_CAMERA_WITH_PROJECT_RESPONSE_SUCCESS       = 'CAMERAS_UNASSOCIATE_CAMERA_WITH_PROJECT_RESPONSE_SUCCESS'
export const CAMERAS_UNASSOCIATE_CAMERA_WITH_PROJECT_RESPONSE_ERRORS        = 'CAMERAS_UNASSOCIATE_CAMERA_WITH_PROJECT_RESPONSE_ERRORS'
export const UPDATE_PROJECT_DETAILS_CAMERA_DEFAULT_WEIGHTS_CHANGE           = 'UPDATE_PROJECT_DETAILS_CAMERA_DEFAULT_WEIGHTS_CHANGE'
export const UCPA_FETCH_PROJECTS_REQUEST                                    = 'UCPA_FETCH_PROJECTS_REQUEST'
export const UCPA_FETCH_PROJECTS_RESPONSE_SUCCESS                           = 'UCPA_FETCH_PROJECTS_RESPONSE_SUCCESS'
export const UCPA_FETCH_PROJECTS_RESPONSE_ERRORS                            = 'UCPA_FETCH_PROJECTS_RESPONSE_ERRORS'
export const UCPA_FETCH_PROJECT_CAMERAS_REQUEST                             = 'UCPA_FETCH_PROJECT_CAMERAS_REQUEST'
export const UCPA_FETCH_PROJECT_CAMERAS_RESPONSE_SUCCESS                    = 'UCPA_FETCH_PROJECT_CAMERAS_RESPONSE_SUCCESS'
export const UCPA_FETCH_PROJECT_CAMERAS_RESPONSE_ERRORS                     = 'UCPA_FETCH_PROJECT_CAMERAS_RESPONSE_ERRORS'
export const UCPA_RESET_PROJECT_CAMERAS                                     = 'UCPA_RESET_PROJECT_CAMERAS'
export const UCPA_FETCH_USER_PROJECTS_REQUEST                               = 'UCPA_FETCH_USER_PROJECTS_REQUEST'
export const UCPA_FETCH_USER_PROJECTS_RESPONSE_SUCCESS                      = 'UCPA_FETCH_USER_PROJECTS_RESPONSE_SUCCESS'
export const UCPA_FETCH_USER_PROJECTS_RESPONSE_ERRORS                       = 'UCPA_FETCH_USER_PROJECTS_RESPONSE_ERRORS'
export const UCPA_FETCH_USER_CAMERAS_REQUEST                                = 'UCPA_FETCH_USER_CAMERAS_REQUEST'
export const UCPA_FETCH_USER_CAMERAS_RESPONSE_SUCCESS                       = 'UCPA_FETCH_USER_CAMERAS_RESPONSE_SUCCESS'
export const UCPA_FETCH_USER_CAMERAS_RESPONSE_ERRORS                        = 'UCPA_FETCH_USER_CAMERAS_RESPONSE_ERRORS'
export const UCPA_ASSOCIATE_CAMERA_WITH_USER_REQUEST                        = 'UCPA_ASSOCIATE_CAMERA_WITH_USER_REQUEST'
export const UCPA_ASSOCIATE_CAMERA_WITH_USER_RESPONSE_SUCCESS               = 'UCPA_ASSOCIATE_CAMERA_WITH_USER_RESPONSE_SUCCESS'
export const UCPA_ASSOCIATE_CAMERA_WITH_USER_RESPONSE_ERRORS                = 'UCPA_ASSOCIATE_CAMERA_WITH_USER_RESPONSE_ERRORS'
export const UCPA_ASSOCIATE_PROJECT_WITH_USER_REQUEST                       = 'UCPA_ASSOCIATE_PROJECT_WITH_USER_REQUEST'
export const UCPA_ASSOCIATE_PROJECT_WITH_USER_RESPONSE_SUCCESS              = 'UCPA_ASSOCIATE_PROJECT_WITH_USER_RESPONSE_SUCCESS'
export const UCPA_ASSOCIATE_PROJECT_WITH_USER_RESPONSE_ERRORS               = 'UCPA_ASSOCIATE_PROJECT_WITH_USER_RESPONSE_ERRORS'
export const UCPA_UNASSOCIATE_CAMERA_WITH_USER_REQUEST                      = 'UCPA_UNASSOCIATE_CAMERA_WITH_USER_REQUEST'
export const UCPA_UNASSOCIATE_CAMERA_WITH_USER_RESPONSE_SUCCESS             = 'UCPA_UNASSOCIATE_CAMERA_WITH_USER_RESPONSE_SUCCESS'
export const UCPA_UNASSOCIATE_CAMERA_WITH_USER_RESPONSE_ERRORS              = 'UCPA_UNASSOCIATE_CAMERA_WITH_USER_RESPONSE_ERRORS'
export const UCPA_UNASSOCIATE_PROJECT_WITH_USER_REQUEST                     = 'UCPA_UNASSOCIATE_PROJECT_WITH_USER_REQUEST'
export const UCPA_UNASSOCIATE_PROJECT_WITH_USER_RESPONSE_SUCCESS            = 'UCPA_UNASSOCIATE_PROJECT_WITH_USER_RESPONSE_SUCCESS'
export const UCPA_UNASSOCIATE_PROJECT_WITH_USER_RESPONSE_ERRORS             = 'UCPA_UNASSOCIATE_PROJECT_WITH_USER_RESPONSE_ERRORS'
export const UCPA_CHANGE_USER_CAMERA_WEIGHT_REQUEST                         = 'UCPA_CHANGE_USER_CAMERA_WEIGHT_REQUEST'
export const UCPA_CHANGE_USER_CAMERA_WEIGHT_RESPONSE_SUCCESS                = 'UCPA_CHANGE_USER_CAMERA_WEIGHT_RESPONSE_SUCCESS'
export const UCPA_CHANGE_USER_CAMERA_WEIGHT_RESPONSE_ERRORS                 = 'UCPA_CHANGE_USER_CAMERA_WEIGHT_RESPONSE_ERRORS'
export const UCPA_CHANGE_USER_PROJECT_WEIGHT_REQUEST                        = 'UCPA_CHANGE_USER_PROJECT_WEIGHT_REQUEST'
export const UCPA_CHANGE_USER_PROJECT_WEIGHT_RESPONSE_SUCCESS               = 'UCPA_CHANGE_USER_PROJECT_WEIGHT_RESPONSE_SUCCESS'
export const UCPA_CHANGE_USER_PROJECT_WEIGHT_RESPONSE_ERRORS                = 'UCPA_CHANGE_USER_PROJECT_WEIGHT_RESPONSE_ERRORS'
export const UCPA_ADD_USER_CAMERAS                                          = 'UCPA_ADD_USER_CAMERAS'
export const UCPA_REMOVE_USER_CAMERAS                                       = 'UCPA_REMOVE_USER_CAMERAS'
export const UCPA_ADD_USER_PROJECTS                                         = 'UCPA_ADD_USER_PROJECTS'
export const UCPA_REMOVE_USER_PROJECTS                                      = 'UCPA_REMOVE_USER_PROJECTS'
export const UCPA_RESET_ALL                                                 = 'UCPA_RESET_ALL'
export const UCPA_RESET_CHANGE_USER_CAMERA_WEIGHT                           = 'UCPA_RESET_CHANGE_USER_CAMERA_WEIGHT'
export const UCPA_RESET_CHANGE_USER_PROJECT_WEIGHT                          = 'UCPA_RESET_CHANGE_USER_PROJECT_WEIGHT'
export const UCPA_RESET_UNASSOCIATE_PROJECT_WITH_USER                       = 'UCPA_RESET_UNASSOCIATE_PROJECT_WITH_USER'
export const UCPA_RESET_ASSOCIATE_PROJECT_WITH_USER                         = 'UCPA_RESET_ASSOCIATE_PROJECT_WITH_USER'
export const UCPA_RESET_UNASSOCIATE_CAMERA_WITH_USER                        = 'UCPA_RESET_UNASSOCIATE_CAMERA_WITH_USER'
export const UCPA_RESET_ASSOCIATE_CAMERA_WITH_USER                          = 'UCPA_RESET_ASSOCIATE_CAMERA_WITH_USER'
export const UCPA_RESET_ADD_USER_CAMERAS                                    = 'UCPA_RESET_ADD_USER_CAMERAS'
export const UCPA_RESET_REMOVE_USER_CAMERAS                                 = 'UCPA_RESET_REMOVE_USER_CAMERAS'
export const UCPA_RESET_ADD_USER_PROJECTS                                   = 'UCPA_RESET_ADD_USER_PROJECTS'
export const UCPA_RESET_REMOVE_USER_PROJECTS                                = 'UCPA_RESET_REMOVE_USER_PROJECTS'
export const UCPA_UPDATE_PROJECT_CAMERA_WEIGHTS                             = 'UCPA_UPDATE_PROJECT_CAMERA_WEIGHTS'
export const UCPA_PROCESSING                                                = 'UCPA_PROCESSING'
export const CAMERAS_FETCH_ALL_REQUEST                                      = 'CAMERAS_FETCH_ALL_REQUEST'
export const CAMERAS_FETCH_ALL_RESPONSE_SUCCESS                             = 'CAMERAS_FETCH_ALL_RESPONSE_SUCCESS'
export const CAMERAS_FETCH_ALL_RESPONSE_ERRORS                              = 'CAMERAS_FETCH_ALL_RESPONSE_ERRORS'
export const CAMERAS_FETCH_ALL_RESET                                        = 'CAMERAS_FETCH_ALL_RESET'
export const CAMERAS_FETCH_ALL_RESPONSE_END                                 = 'CAMERAS_FETCH_ALL_RESPONSE_END'
export const UPDATE_PROJECT_PROJECT_CAMERAS_AVAILABLE_CAMERAS               = 'UPDATE_PROJECT_PROJECT_CAMERAS_AVAILABLE_CAMERAS'
export const UPDATE_PROJECT_PROJECT_CAMERAS_PROJECT_CAMERAS                 = 'UPDATE_PROJECT_PROJECT_CAMERAS_PROJECT_CAMERAS'
export const UPDATE_PROJECT_PROJECT_CAMERAS_REMOVE_CAMERAS                  = 'UPDATE_PROJECT_PROJECT_CAMERAS_REMOVE_CAMERAS'
export const UPDATE_PROJECT_PROJECT_CAMERAS_ADD_CAMERAS                     = 'UPDATE_PROJECT_PROJECT_CAMERAS_ADD_CAMERAS'
export const UPDATE_PROJECT_PROJECT_CAMERAS_CAMERA_WEIGHTS                  = 'UPDATE_PROJECT_PROJECT_CAMERAS_CAMERA_WEIGHTS'
export const CREATE_PROJECT_PROJECT_CAMERAS_AVAILABLE_CAMERAS               = 'CREATE_PROJECT_PROJECT_CAMERAS_AVAILABLE_CAMERAS'
export const CREATE_PROJECT_PROJECT_CAMERAS_PROJECT_CAMERAS                 = 'CREATE_PROJECT_PROJECT_CAMERAS_PROJECT_CAMERAS'
export const CREATE_PROJECT_PROJECT_CAMERAS_REMOVE_CAMERAS                  = 'CREATE_PROJECT_PROJECT_CAMERAS_REMOVE_CAMERAS'
export const CREATE_PROJECT_PROJECT_CAMERAS_ADD_CAMERAS                     = 'CREATE_PROJECT_PROJECT_CAMERAS_ADD_CAMERAS'
export const CREATE_PROJECT_PROJECT_CAMERAS_CAMERA_WEIGHTS                  = 'CREATE_PROJECT_PROJECT_CAMERAS_CAMERA_WEIGHTS'
export const CAMERA_CONTROL_LOG                                             = 'CAMERA_CONTROL_LOG'
export const CAMERA_CONTROL_LOG_RESET                                       = 'CAMERA_CONTROL_LOG_RESET'
export const CAMERA_CONTROL_STATUS_REQUEST                                  = 'CAMERA_CONTROL_STATUS_REQUEST'
export const CAMERA_CONTROL_STATUS_RESPONSE_ERRORS                          = 'CAMERA_CONTROL_STATUS_RESPONSE_ERRORS'
export const CAMERA_CONTROL_STATUS_RESPONSE_SUCCESS                         = 'CAMERA_CONTROL_STATUS_RESPONSE_SUCCESS'
export const CAMERA_CONTROL_SETTINGS_REQUEST                                = 'CAMERA_CONTROL_SETTINGS_REQUEST'
export const CAMERA_CONTROL_SETTINGS_RESPONSE_ERRORS                        = 'CAMERA_CONTROL_SETTINGS_RESPONSE_ERRORS'
export const CAMERA_CONTROL_SETTINGS_RESPONSE_SUCCESS                       = 'CAMERA_CONTROL_SETTINGS_RESPONSE_SUCCESS'
export const CAMERA_CONTROL_CAMERA_REQUEST                                  = 'CAMERA_CONTROL_CAMERA_REQUEST'
export const CAMERA_CONTROL_CAMERA_RESPONSE_ERRORS                          = 'CAMERA_CONTROL_CAMERA_RESPONSE_ERRORS'
export const CAMERA_CONTROL_CAMERA_RESPONSE_SUCCESS                         = 'CAMERA_CONTROL_CAMERA_RESPONSE_SUCCESS'
export const CAMERA_CONTROL_RESET                                           = 'CAMERA_CONTROL_RESET'
export const CAMERA_CONTROL_SHOOT_REQUEST                                   = 'CAMERA_CONTROL_SHOOT_REQUEST'
export const CAMERA_CONTROL_SHOOT_RESPONSE_ERRORS                           = 'CAMERA_CONTROL_SHOOT_RESPONSE_ERRORS'
export const CAMERA_CONTROL_SHOOT_RESPONSE_SUCCESS                          = 'CAMERA_CONTROL_SHOOT_RESPONSE_SUCCESS'
export const CAMERA_CONTROL_VERSION                                         = 'CAMERA_CONTROL_VERSION'
export const CAMERA_CONTROL_EXIF_REQUEST                                    = 'CAMERA_CONTROL_EXIF_REQUEST'
export const CAMERA_CONTROL_EXIF_RESPONSE_ERRORS                            = 'CAMERA_CONTROL_EXIF_RESPONSE_ERRORS'
export const CAMERA_CONTROL_EXIF_RESPONSE_SUCCESS                           = 'CAMERA_CONTROL_EXIF_RESPONSE_SUCCESS'
export const CAMERA_CONTROL_PREVIEW_REQUEST                                 = 'CAMERA_CONTROL_PREVIEW_REQUEST'
export const CAMERA_CONTROL_PREVIEW_RESPONSE_ERRORS                         = 'CAMERA_CONTROL_PREVIEW_RESPONSE_ERRORS'
export const CAMERA_CONTROL_PREVIEW_RESPONSE_SUCCESS                        = 'CAMERA_CONTROL_PREVIEW_RESPONSE_SUCCESS'
export const CAMERA_CONTROL_LOADING_PREVIEW_IMAGE                           = 'CAMERA_CONTROL_LOADING_PREVIEW_IMAGE'
export const CAMERA_CONTROL_PREVIEW_QUALITY                                 = 'CAMERA_CONTROL_PREVIEW_QUALITY'
export const CAMERA_CONTROL_PREVIEW_VERSION_QUALITY                         = 'CAMERA_CONTROL_PREVIEW_VERSION_QUALITY'
export const CAMERA_CONTROL_UPDATE_PROPERTIES_REQUEST                       = 'CAMERA_CONTROL_UPDATE_PROPERTIES_REQUEST'
export const CAMERA_CONTROL_UPDATE_PROPERTIES_RESPONSE_ERRORS               = 'CAMERA_CONTROL_UPDATE_PROPERTIES_RESPONSE_ERRORS'
export const CAMERA_CONTROL_UPDATE_PROPERTIES_RESPONSE_SUCCESS              = 'CAMERA_CONTROL_UPDATE_PROPERTIES_RESPONSE_SUCCESS'
export const CAMERA_CONTROL_PROPERTIES_REQUEST                              = 'CAMERA_CONTROL_PROPERTIES_REQUEST'
export const CAMERA_CONTROL_PROPERTIES_RESPONSE_ERRORS                      = 'CAMERA_CONTROL_PROPERTIES_RESPONSE_ERRORS'
export const CAMERA_CONTROL_PROPERTIES_RESPONSE_SUCCESS                     = 'CAMERA_CONTROL_PROPERTIES_RESPONSE_SUCCESS'
export const CAMERA_CONTROL_SYSTEM_REQUEST                                  = 'CAMERA_CONTROL_SYSTEM_REQUEST'
export const CAMERA_CONTROL_SYSTEM_RESPONSE_ERRORS                          = 'CAMERA_CONTROL_SYSTEM_RESPONSE_ERRORS'
export const CAMERA_CONTROL_SYSTEM_RESPONSE_SUCCESS                         = 'CAMERA_CONTROL_SYSTEM_RESPONSE_SUCCESS'
export const CAMERA_CONTROL_UPDATE_SYSTEM_REQUEST                           = 'CAMERA_CONTROL_UPDATE_SYSTEM_REQUEST'
export const CAMERA_CONTROL_UPDATE_SYSTEM_RESPONSE_ERRORS                   = 'CAMERA_CONTROL_UPDATE_SYSTEM_RESPONSE_ERRORS'
export const CAMERA_CONTROL_UPDATE_SYSTEM_RESPONSE_SUCCESS                  = 'CAMERA_CONTROL_UPDATE_SYSTEM_RESPONSE_SUCCESS'
export const CAMERA_CONTROL_LOGS_REQUEST                                    = 'CAMERA_CONTROL_LOGS_REQUEST'
export const CAMERA_CONTROL_LOGS_RESPONSE_ERRORS                            = 'CAMERA_CONTROL_LOGS_RESPONSE_ERRORS'
export const CAMERA_CONTROL_LOGS_RESPONSE_SUCCESS                           = 'CAMERA_CONTROL_LOGS_RESPONSE_SUCCESS'
export const CAMERA_CONTROL_SCHEDULER_START_REQUEST                         = 'CAMERA_CONTROL_SCHEDULER_START_REQUEST'
export const CAMERA_CONTROL_SCHEDULER_START_RESPONSE_ERRORS                 = 'CAMERA_CONTROL_SCHEDULER_START_RESPONSE_ERRORS'
export const CAMERA_CONTROL_SCHEDULER_START_RESPONSE_SUCCESS                = 'CAMERA_CONTROL_SCHEDULER_START_RESPONSE_SUCCESS'
export const CAMERA_CONTROL_SCHEDULER_STOP_REQUEST                          = 'CAMERA_CONTROL_SCHEDULER_STOP_REQUEST'
export const CAMERA_CONTROL_SCHEDULER_STOP_RESPONSE_ERRORS                  = 'CAMERA_CONTROL_SCHEDULER_STOP_RESPONSE_ERRORS'
export const CAMERA_CONTROL_SCHEDULER_STOP_RESPONSE_SUCCESS                 = 'CAMERA_CONTROL_SCHEDULER_STOP_RESPONSE_SUCCESS'
export const CAMERA_CONTROL_SCHEDULER_RESTART_REQUEST                       = 'CAMERA_CONTROL_SCHEDULER_RESTART_REQUEST'
export const CAMERA_CONTROL_SCHEDULER_RESTART_RESPONSE_ERRORS               = 'CAMERA_CONTROL_SCHEDULER_RESTART_RESPONSE_ERRORS'
export const CAMERA_CONTROL_SCHEDULER_RESTART_RESPONSE_SUCCESS              = 'CAMERA_CONTROL_SCHEDULER_RESTART_RESPONSE_SUCCESS'
export const CAMERA_CONTROL_FETCH_APN_REQUEST                               = 'CAMERA_CONTROL_FETCH_APN_REQUEST'
export const CAMERA_CONTROL_FETCH_APN_RESPONSE_ERRORS                       = 'CAMERA_CONTROL_FETCH_APN_RESPONSE_ERRORS'
export const CAMERA_CONTROL_FETCH_APN_RESPONSE_SUCCESS                      = 'CAMERA_CONTROL_FETCH_APN_RESPONSE_SUCCESS'
export const CAMERA_CONTROL_UPDATE_APN_REQUEST                              = 'CAMERA_CONTROL_UPDATE_APN_REQUEST'
export const CAMERA_CONTROL_UPDATE_APN_RESPONSE_ERRORS                      = 'CAMERA_CONTROL_UPDATE_APN_RESPONSE_ERRORS'
export const CAMERA_CONTROL_UPDATE_APN_RESPONSE_SUCCESS                     = 'CAMERA_CONTROL_UPDATE_APN_RESPONSE_SUCCESS'
export const CAMERA_CONTROL_FETCH_WIFI_REQUEST                              = 'CAMERA_CONTROL_FETCH_WIFI_REQUEST'
export const CAMERA_CONTROL_FETCH_WIFI_RESPONSE_ERRORS                      = 'CAMERA_CONTROL_FETCH_WIFI_RESPONSE_ERRORS'
export const CAMERA_CONTROL_FETCH_WIFI_RESPONSE_SUCCESS                     = 'CAMERA_CONTROL_FETCH_WIFI_RESPONSE_SUCCESS'
export const CAMERA_CONTROL_START_WIFI_REQUEST                              = 'CAMERA_CONTROL_START_WIFI_REQUEST'
export const CAMERA_CONTROL_START_WIFI_RESPONSE_ERRORS                      = 'CAMERA_CONTROL_START_WIFI_RESPONSE_ERRORS'
export const CAMERA_CONTROL_START_WIFI_RESPONSE_SUCCESS                     = 'CAMERA_CONTROL_START_WIFI_RESPONSE_SUCCESS'
export const CAMERA_CONTROL_STOP_WIFI_REQUEST                               = 'CAMERA_CONTROL_STOP_WIFI_REQUEST'
export const CAMERA_CONTROL_STOP_WIFI_RESPONSE_ERRORS                       = 'CAMERA_CONTROL_STOP_WIFI_RESPONSE_ERRORS'
export const CAMERA_CONTROL_STOP_WIFI_RESPONSE_SUCCESS                      = 'CAMERA_CONTROL_STOP_WIFI_RESPONSE_SUCCESS'
export const CAMERA_CONTROL_RESTART_WIFI_REQUEST                            = 'CAMERA_CONTROL_RESTART_WIFI_REQUEST'
export const CAMERA_CONTROL_RESTART_WIFI_RESPONSE_ERRORS                    = 'CAMERA_CONTROL_RESTART_WIFI_RESPONSE_ERRORS'
export const CAMERA_CONTROL_RESTART_WIFI_RESPONSE_SUCCESS                   = 'CAMERA_CONTROL_RESTART_WIFI_RESPONSE_SUCCESS'
export const CAMERA_CONTROL_FETCH_DROP_REQUEST                              = 'CAMERA_CONTROL_FETCH_DROP_REQUEST'
export const CAMERA_CONTROL_FETCH_DROP_ERRORS                               = 'CAMERA_CONTROL_FETCH_DROP_ERRORS'
export const CAMERA_CONTROL_FETCH_DROP_SUCCESS                              = 'CAMERA_CONTROL_FETCH_DROP_SUCCESS'
export const CAMERA_CONTROL_DROP_RESET                                      = 'CAMERA_CONTROL_DROP_RESET'
export const CAMERA_CONTROL_DROP_MODIFY_LIST                                = 'CAMERA_CONTROL_DROP_MODIFY_LIST'
export const CAMERA_CONTROL_FETCH_UPLOAD_QUEUE_REQUEST                      = 'CAMERA_CONTROL_FETCH_UPLOAD_QUEUE_REQUEST'
export const CAMERA_CONTROL_FETCH_UPLOAD_QUEUE_ERRORS                       = 'CAMERA_CONTROL_FETCH_UPLOAD_QUEUE_ERRORS'
export const CAMERA_CONTROL_FETCH_UPLOAD_QUEUE_SUCCESS                      = 'CAMERA_CONTROL_FETCH_UPLOAD_QUEUE_SUCCESS'
export const CAMERA_CONTROL_UPLOAD_QUEUE_RESET                              = 'CAMERA_CONTROL_UPLOAD_QUEUE_RESET'
export const CAMERA_CONTROL_CLEAR_UPLOAD_QUEUE_REQUEST                      = 'CAMERA_CONTROL_CLEAR_UPLOAD_QUEUE_REQUEST'
export const CAMERA_CONTROL_CLEAR_UPLOAD_QUEUE_ERRORS                       = 'CAMERA_CONTROL_CLEAR_UPLOAD_QUEUE_ERRORS'
export const CAMERA_CONTROL_CLEAR_UPLOAD_QUEUE_SUCCESS                      = 'CAMERA_CONTROL_CLEAR_UPLOAD_QUEUE_SUCCESS'
export const CAMERA_CONTROL_MODIFY_UPLOAD_QUEUE_REQUEST                     = 'CAMERA_CONTROL_MODIFY_UPLOAD_QUEUE_REQUEST'
export const CAMERA_CONTROL_MODIFY_UPLOAD_QUEUE_ERRORS                      = 'CAMERA_CONTROL_MODIFY_UPLOAD_QUEUE_ERRORS'
export const CAMERA_CONTROL_MODIFY_UPLOAD_QUEUE_SUCCESS                     = 'CAMERA_CONTROL_MODIFY_UPLOAD_QUEUE_SUCCESS'
export const CAMERA_CONTROL_START_STOP_UPLOAD_QUEUE_REQUEST                 = 'CAMERA_CONTROL_START_STOP_UPLOAD_QUEUE_REQUEST'
export const CAMERA_CONTROL_START_STOP_UPLOAD_QUEUE_ERRORS                  = 'CAMERA_CONTROL_START_STOP_UPLOAD_QUEUE_ERRORS'
export const CAMERA_CONTROL_START_STOP_UPLOAD_QUEUE_SUCCESS                 = 'CAMERA_CONTROL_START_STOP_UPLOAD_QUEUE_SUCCESS'
export const CAMERA_CONTROL_RESET_LOADING_INDICATORS                        = 'CAMERA_CONTROL_RESET_LOADING_INDICATORS'
export const CAMERA_CONTROL_UPDATE_EXIF                                     = 'CAMERA_CONTROL_UPDATE_EXIF'
export const CAMERA_CONTROL_PING_REQUEST                                    = 'CAMERA_CONTROL_PING_REQUEST'
export const CAMERA_CONTROL_PING_RESPONSE_ERRORS                            = 'CAMERA_CONTROL_PING_RESPONSE_ERRORS'
export const CAMERA_CONTROL_PING_RESPONSE_SUCCESS                           = 'CAMERA_CONTROL_PING_RESPONSE_SUCCESS'
export const CAMERAS_ALL_TAGS_REQUEST                                       = 'CAMERAS_ALL_TAGS_REQUEST'
export const CAMERAS_ALL_TAGS_RESPONSE                                      = 'CAMERAS_ALL_TAGS_RESPONSE'
export const CAMERAS_ALL_STATUSES_REQUEST                                   = 'CAMERAS_ALL_STATUSES_REQUEST'
export const CAMERAS_ALL_STATUSES_RESPONSE                                  = 'CAMERAS_ALL_STATUSES_RESPONSE'
export const CAMERAS_TOGGLE_FILTER_TAG                                      = 'CAMERAS_TOGGLE_FILTER_TAG'
export const CAMERAS_TOGGLE_FILTER_STATUS                                   = 'CAMERAS_TOGGLE_FILTER_STATUS'
export const CAMERAS_TOGGLE_FILTER_TYPE                                     = 'CAMERAS_TOGGLE_FILTER_TYPE'
export const CAMERAS_TOGGLE_FILTER_VERSION                                  = 'CAMERAS_TOGGLE_FILTER_VERSION'
export const CAMERAS_SET_FILTER_TAGS                                        = 'CAMERAS_SET_FILTER_TAGS'
export const CAMERAS_SET_FILTER_STATUSES                                    = 'CAMERAS_SET_FILTER_STATUSES'
export const CAMERAS_SET_FILTER_VERSIONS                                    = 'CAMERAS_SET_FILTER_VERSIONS'
export const CAMERAS_SET_FILTER_TYPES                                       = 'CAMERAS_SET_FILTER_TYPES'
export const CAMERAS_SORT_BY_CHANGE                                         = 'CAMERAS_SORT_BY_CHANGE'
export const CAMERAS_SET_SORT_BY                                            = 'CAMERAS_SET_SORT_BY'
export const CAMERAS_CREATE_RESET                                           = 'CAMERAS_CREATE_RESET'
export const CAMERAS_CREATE_REQUEST                                         = 'CAMERAS_CREATE_REQUEST'
export const CAMERAS_CREATE_RESPONSE_ERRORS                                 = 'CAMERAS_CREATE_RESPONSE_ERRORS'
export const CAMERAS_CREATE_RESPONSE_SUCCESS                                = 'CAMERAS_CREATE_RESPONSE_SUCCESS'
export const CAMERAS_UPDATE_RESET                                           = 'CAMERAS_UPDATE_RESET'
export const CAMERAS_UPDATE_REQUEST                                         = 'CAMERAS_UPDATE_REQUEST'
export const CAMERAS_UPDATE_RESPONSE_ERRORS                                 = 'CAMERAS_UPDATE_RESPONSE_ERRORS'
export const CAMERAS_UPDATE_RESPONSE_SUCCESS                                = 'CAMERAS_UPDATE_RESPONSE_SUCCESS'
export const CAMERAS_CAMERA_REQUEST                                         = 'CAMERAS_CAMERA_REQUEST'
export const CAMERAS_CAMERA_RESPONSE_ERRORS                                 = 'CAMERAS_CAMERA_RESPONSE_ERRORS'
export const CAMERAS_CAMERA_RESPONSE_SUCCESS                                = 'CAMERAS_CAMERA_RESPONSE_SUCCESS'
export const IMPORT_CSV_REQUEST                                             = 'IMPORT_CSV_REQUEST'
export const IMPORT_CSV_RESPONSE_ERRORS                                      = 'IMPORT_CSV_RESPONSE_ERROR'
export const IMPORT_CSV_RESPONSE_SUCCESS                                    = 'IMPORT_CSV_RESPONSE_SUCCESS'
export const ADD_USER_UPDATE_LOCAL_USER_STATE                               = 'ADD_USER_UPDATE_LOCAL_USER_STATE'
export const ADD_USER_UCPA_COMPLETED                                        = 'ADD_USER_UCPA_COMPLETED'
export const ADD_USER_CLEAR_ERRORS                                          = 'ADD_USER_CLEAR_ERRORS'
export const BULK_USERS_ADD_USER_CAMERAS                                    = 'BULK_USERS_ADD_USER_CAMERAS'
export const BULK_USERS_ADD_USER_PROJECTS                                   = 'BULK_USERS_ADD_USER_PROJECTS'
export const BULK_USERS_UPDATE_PROJECT_CAMERA_WEIGHTS                       = 'BULK_USERS_UPDATE_PROJECT_CAMERA_WEIGHTS'
export const BULK_USERS_RESET_ADD_USER_CAMERAS                              = 'BULK_USERS_RESET_ADD_USER_CAMERAS'
export const BULK_USERS_RESET_ADD_USER_PROJECTS                             = 'BULK_USERS_RESET_ADD_USER_PROJECTS'
export const BULK_USERS_RESET_UPDATE_PROJECT_CAMERA_WEIGHTS                 = 'BULK_USERS_RESET_UPDATE_PROJECT_CAMERA_WEIGHTS'
export const EDIT_USER_UPDATE_LOCAL_USER_STATE                              = 'EDIT_USER_UPDATE_LOCAL_USER_STATE'
export const EDIT_USER_UPDATE_LOCAL_BULK_USER_STATE                         = 'EDIT_USER_UPDATE_LOCAL_BULK_USER_STATE'
export const EDIT_USER_SET_CONTEXT                                          = 'EDIT_USER_SET_CONTEXT'
export const SCHEDULE_RESET                                                 = 'SCHEDULE_RESET'
export const SCHEDULE_LOAD_REQUEST                                          = 'SCHEDULE_LOAD_REQUEST'
export const SCHEDULE_LOAD_SUCCESS                                          = 'SCHEDULE_LOAD_SUCCESS'
export const SCHEDULE_LOAD_ERROR                                            = 'SCHEDULE_LOAD_ERROR'
export const SCHEDULE_SAVE_REQUEST                                          = 'SCHEDULE_SAVE_REQUEST'
export const SCHEDULE_SAVE_SUCCESS                                          = 'SCHEDULE_SAVE_SUCCESS'
export const SCHEDULE_SAVE_ERROR                                            = 'SCHEDULE_SAVE_ERROR'
export const SCHEDULE_CLEAR_DAY                                             = 'SCHEDULE_CLEAR_DAY'
export const SCHEDULE_COPY_TO_NEXT_DAY                                      = 'SCHEDULE_COPY_TO_NEXT_DAY'
export const SCHEDULE_ADD_PERIOD                                            = 'SCHEDULE_ADD_PERIOD'
export const SCHEDULE_MODIFY_PERIOD                                         = 'SCHEDULE_MODIFY_PERIOD'
export const SCHEDULE_REMOVE_PERIOD                                         = 'SCHEDULE_REMOVE_PERIOD'
export const SCHEDULE_INCREASE_PERIOD_PRIORITY                              = 'SCHEDULE_INCREASE_PERIOD_PRIORITY'
export const SCHEDULE_DECREASE_PERIOD_PRIORITY                              = 'SCHEDULE_DECREASE_PERIOD_PRIORITY'
export const SCHEDULE_SHOW_ADD_PERIOD_MODAL                                 = 'SCHEDULE_SHOW_ADD_PERIOD_MODAL'
export const SCHEDULE_HIDE_ADD_PERIOD_MODAL                                 = 'SCHEDULE_HIDE_ADD_PERIOD_MODAL'
export const SCHEDULE_SHOW_EDIT_PERIOD_MODAL                                = 'SCHEDULE_SHOW_EDIT_PERIOD_MODAL'
export const SCHEDULE_HIDE_EDIT_PERIOD_MODAL                                = 'SCHEDULE_HIDE_EDIT_PERIOD_MODAL'
export const SCHEDULE_APPLY_TEMPLATE                                        = 'SCHEDULE_APPLY_TEMPLATE'
export const SCHEDULE_V4_SHOW_ADD_PERIOD_MODAL                              = 'SCHEDULE_V4_SHOW_ADD_PERIOD_MODAL'
export const SCHEDULE_V4_POPULATE_DAY                                       = 'SCHEDULE_V4_POPULATE_DAY'
export const SCHEDULE_V4_POPULATE_DAY_ERROR                                 = 'SCHEDULE_V4_POPULATE_DAY_ERROR'
export const SCHEDULE_V4_MODIFY_DAY                                         = 'SCHEDULE_V4_MODIFY_DAY'
export const CAMERA_CONTROL_REBOOT_CAMERA_BODY_REQUEST                      = 'CAMERA_CONTROL_REBOOT_CAMERA_BODY_REQUEST'
export const CAMERA_CONTROL_REBOOT_CAMERA_BODY_RESPONSE_ERRORS              = 'CAMERA_CONTROL_REBOOT_CAMERA_BODY_RESPONSE_ERRORS'
export const CAMERA_CONTROL_REBOOT_CAMERA_BODY_RESPONSE_SUCCESS             = 'CAMERA_CONTROL_REBOOT_CAMERA_BODY_RESPONSE_SUCCESS'
export const CAMERA_CONTROL_REBOOT_CAMERA_BODY_RESET                        = 'CAMERA_CONTROL_REBOOT_CAMERA_BODY_RESET'
export const CAMERA_CONTROL_RUN_TESTS_REQUEST                               = 'CAMERA_CONTROL_RUN_TESTS_REQUEST'
export const CAMERA_CONTROL_RUN_TESTS_RESPONSE_ERRORS                       = 'CAMERA_CONTROL_RUN_TESTS_RESPONSE_ERRORS'
export const CAMERA_CONTROL_RUN_TESTS_RESPONSE_SUCCESS                      = 'CAMERA_CONTROL_RUN_TESTS_RESPONSE_SUCCESS'
export const CAMERA_CONTROL_TESTS_VISIBILITY                                = 'CAMERA_CONTROL_TESTS_VISIBILITY'
export const CAMERA_CONTROL_TEST_OUTPUT_STREAM                              = 'CAMERA_CONTROL_TEST_OUTPUT_STREAM'
export const CAMERA_CONTROL_TEST_PROCESSING                                 = 'CAMERA_CONTROL_TEST_PROCESSING'
export const CAMERA_CONTROL_RUN_TESTS_RESET                                 = 'CAMERA_CONTROL_RUN_TESTS_RESET'
export const CAMERAS_LIST_VIEW_FETCH_REQUEST                                = 'CAMERAS_LIST_VIEW_FETCH_REQUEST'
export const CAMERAS_LIST_VIEW_FETCH_RESPONSE_SUCCESS                       = 'CAMERAS_LIST_VIEW_FETCH_RESPONSE_SUCCESS'
export const CAMERAS_LIST_VIEW_FETCH_RESPONSE_ERRORS                        = 'CAMERAS_LIST_VIEW_FETCH_RESPONSE_ERRORS'
export const CAMERAS_LIST_VIEW_FETCH_RESPONSE_END                           = 'CAMERAS_LIST_VIEW_FETCH_RESPONSE_END'
export const CAMERAS_LIST_VIEW_RESET                                        = 'CAMERAS_LIST_VIEW_RESET'
export const CAMERAS_GRID_VIEW_FETCH_REQUEST                                = 'CAMERAS_GRID_VIEW_FETCH_REQUEST'
export const CAMERAS_GRID_VIEW_FETCH_RESPONSE_SUCCESS                       = 'CAMERAS_GRID_VIEW_FETCH_RESPONSE_SUCCESS'
export const CAMERAS_GRID_VIEW_FETCH_RESPONSE_ERRORS                        = 'CAMERAS_GRID_VIEW_FETCH_RESPONSE_ERRORS'
export const CAMERAS_GRID_VIEW_FETCH_RESPONSE_END                           = 'CAMERAS_GRID_VIEW_FETCH_RESPONSE_END'
export const CAMERAS_GRID_VIEW_RESET                                        = 'CAMERAS_GRID_VIEW_RESET'
export const CAMERA_CONTROL_DATA_TRANSFER_REQUEST                           = 'CAMERA_CONTROL_DATA_TRANSFER_REQUEST'
export const CAMERA_CONTROL_DATA_TRANSFER_RESPONSE_ERRORS                   = 'CAMERA_CONTROL_DATA_TRANSFER_RESPONSE_ERRORS'
export const CAMERA_CONTROL_DATA_TRANSFER_RESPONSE_SUCCESS                  = 'CAMERA_CONTROL_DATA_TRANSFER_RESPONSE_SUCCESS'
export const CAMERA_CONTROL_DATA_TRANSFER_UPDATE_REQUEST                    = 'CAMERA_CONTROL_DATA_TRANSFER_UPDATE_REQUEST'
export const CAMERA_CONTROL_DATA_TRANSFER_UPDATE_RESPONSE_ERRORS            = 'CAMERA_CONTROL_DATA_TRANSFER_UPDATE_RESPONSE_ERRORS'
export const CAMERA_CONTROL_DATA_TRANSFER_UPDATE_RESPONSE_SUCCESS           = 'CAMERA_CONTROL_DATA_TRANSFER_UPDATE_RESPONSE_SUCCESS'
export const CAMERA_CONTROL_DATA_TRANSFER_UPDATE_RESET                      = 'CAMERA_CONTROL_DATA_TRANSFER_UPDATE_RESET'
export const CAMERA_CONTROL_SIGNAL_STRENGTH_REQUEST                         = 'CAMERA_CONTROL_SIGNAL_STRENGTH_REQUEST'
export const CAMERA_CONTROL_SIGNAL_STRENGTH_RESPONSE_ERRORS                 = 'CAMERA_CONTROL_SIGNAL_STRENGTH_RESPONSE_ERRORS'
export const CAMERA_CONTROL_SIGNAL_STRENGTH_RESPONSE_SUCCESS                = 'CAMERA_CONTROL_SIGNAL_STRENGTH_RESPONSE_SUCCESS'
export const ROLES_CREATE_REQUEST                                           = 'ROLES_CREATE_REQUEST'
export const ROLES_CREATE_RESPONSE_ERRORS                                   = 'ROLES_CREATE_RESPONSE_ERRORS'
export const ROLES_CREATE_RESPONSE_SUCCESS                                  = 'ROLES_CREATE_RESPONSE_SUCCESS'
export const ROLES_VIEW_ALL_REQUEST                                         = 'ROLES_VIEW_ALL_REQUEST'
export const ROLES_VIEW_ALL_RESPONSE_ERRORS                                 = 'ROLES_VIEW_ALL_RESPONSE_ERRORS'
export const ROLES_VIEW_ALL_RESPONSE_SUCCESS                                = 'ROLES_VIEW_ALL_RESPONSE_SUCCESS'
export const ROLES_VIEW_ONE_REQUEST                                         = 'ROLES_VIEW_ONE_REQUEST'
export const ROLES_VIEW_ONE_RESPONSE_ERRORS                                 = 'ROLES_VIEW_ONE_RESPONSE_ERRORS'
export const ROLES_VIEW_ONE_RESPONSE_SUCCESS                                = 'ROLES_VIEW_ONE_RESPONSE_SUCCESS'
export const ROLES_UPDATE_REQUEST                                           = 'ROLES_UPDATE_REQUEST'
export const ROLES_UPDATE_RESPONSE_ERRORS                                   = 'ROLES_UPDATE_RESPONSE_ERRORS'
export const ROLES_UPDATE_RESPONSE_SUCCESS                                  = 'ROLES_UPDATE_RESPONSE_SUCCESS'
export const ROLES_DELETE_REQUEST                                           = 'ROLES_DELETE_REQUEST'
export const ROLES_DELETE_RESPONSE_ERRORS                                   = 'ROLES_DELETE_RESPONSE_ERRORS'
export const ROLES_DELETE_RESPONSE_SUCCESS                                  = 'ROLES_DELETE_RESPONSE_SUCCESS'
export const ROLES_ASSOCIATE_PERMISSION_WITH_ROLE_REQUEST                   = 'ROLES_ASSOCIATE_PERMISSION_WITH_ROLE_REQUEST'
export const ROLES_ASSOCIATE_PERMISSION_WITH_ROLE_RESPONSE_ERRORS           = 'ROLES_ASSOCIATE_PERMISSION_WITH_ROLE_RESPONSE_ERRORS'
export const ROLES_ASSOCIATE_PERMISSION_WITH_ROLE_RESPONSE_SUCCESS          = 'ROLES_ASSOCIATE_PERMISSION_WITH_ROLE_RESPONSE_SUCCESS'
export const ROLES_UNASSOCIATE_PERMISSION_WITH_ROLE_REQUEST                 = 'ROLES_UNASSOCIATE_PERMISSION_WITH_ROLE_REQUEST'
export const ROLES_UNASSOCIATE_PERMISSION_WITH_ROLE_RESPONSE_ERRORS         = 'ROLES_UNASSOCIATE_PERMISSION_WITH_ROLE_RESPONSE_ERRORS'
export const ROLES_UNASSOCIATE_PERMISSION_WITH_ROLE_RESPONSE_SUCCESS        = 'ROLES_UNASSOCIATE_PERMISSION_WITH_ROLE_RESPONSE_SUCCESS'
export const ROLES_RESET_CREATE                                             = 'ROLES_RESET_CREATE'
export const ROLES_RESET                                                    = 'ROLES_RESET'
export const ROLES_RESET_UPDATE                                             = 'ROLES_RESET_UPDATE'
export const ROLES_RESET_DELETE                                             = 'ROLES_RESET_DELETE'
export const ROLES_PERMISSIONS_REQUEST                                      = 'ROLES_PERMISSIONS_REQUEST'
export const ROLES_PERMISSIONS_RESPONSE_ERRORS                              = 'ROLES_PERMISSIONS_RESPONSE_ERRORS'
export const ROLES_PERMISSIONS_RESPONSE_SUCCESS                             = 'ROLES_PERMISSIONS_RESPONSE_SUCCESS'
export const CAMERA_CONTROL_UPDATE_SYSTEM_RESET                             = 'CAMERA_CONTROL_UPDATE_SYSTEM_RESET'
export const CAMERA_CONTROL_UPDATE_APN_RESET                                = 'CAMERA_CONTROL_UPDATE_APN_RESET'
export const CAMERAS_FILTER_BY                                              = 'CAMERAS_FILTER_BY'
export const TAGS_CREATE_REQUEST                                            = 'TAGS_CREATE_REQUEST'
export const TAGS_CREATE_RESPONSE_ERRORS                                    = 'TAGS_CREATE_RESPONSE_ERRORS'
export const TAGS_CREATE_RESPONSE_SUCCESS                                   = 'TAGS_CREATE_RESPONSE_SUCCESS'
export const TAGS_FETCH_ALL_REQUEST                                         = 'TAGS_FETCH_ALL_REQUEST'
export const TAGS_FETCH_ALL_RESPONSE_ERRORS                                 = 'TAGS_FETCH_ALL_RESPONSE_ERRORS'
export const TAGS_FETCH_ALL_RESPONSE_SUCCESS                                = 'TAGS_FETCH_ALL_RESPONSE_SUCCESS'
export const TAGS_FETCH_ONE_REQUEST                                         = 'TAGS_FETCH_ONE_REQUEST'
export const TAGS_FETCH_ONE_RESPONSE_ERRORS                                 = 'TAGS_FETCH_ONE_RESPONSE_ERRORS'
export const TAGS_FETCH_ONE_RESPONSE_SUCCESS                                = 'TAGS_FETCH_ONE_RESPONSE_SUCCESS'
export const TAGS_UPDATE_REQUEST                                            = 'TAGS_UPDATE_REQUEST'
export const TAGS_UPDATE_RESPONSE_ERRORS                                    = 'TAGS_UPDATE_RESPONSE_ERRORS'
export const TAGS_UPDATE_RESPONSE_SUCCESS                                   = 'TAGS_UPDATE_RESPONSE_SUCCESS'
export const TAGS_DELETE_REQUEST                                            = 'TAGS_DELETE_REQUEST'
export const TAGS_DELETE_RESPONSE_ERRORS                                    = 'TAGS_DELETE_RESPONSE_ERRORS'
export const TAGS_DELETE_RESPONSE_SUCCESS                                   = 'TAGS_DELETE_RESPONSE_SUCCESS'
export const TAGS_RESET                                                     = 'TAGS_RESET'
export const TAGS_FILTER_BY                                                 = 'TAGS_FILTER_BY'
export const TAGS_RESET_CREATE                                              = 'TAGS_RESET_CREATE'
export const TAGS_RESET_UPDATE                                              = 'TAGS_RESET_UPDATE'
export const TAGS_RESET_DELETE                                              = 'TAGS_RESET_DELETE'
export const LOGO_RESET                                                     = 'LOGO_RESET'
export const LOGO_UPLOAD_LOGO_REQUEST                                       = 'LOGO_UPLOAD_LOGO_REQUEST'
export const LOGO_UPLOAD_LOGO_RESPONSE_ERRORS                               = 'LOGO_UPLOAD_LOGO_RESPONSE_ERRORS'
export const LOGO_UPLOAD_LOGO_RESPONSE_SUCCESS                              = 'LOGO_UPLOAD_LOGO_RESPONSE_SUCCESS'
export const LOGO_REMOVE_LOGO_REQUEST                                       = 'LOGO_REMOVE_LOGO_REQUEST'
export const LOGO_REMOVE_LOGO_RESPONSE_ERRORS                               = 'LOGO_REMOVE_LOGO_RESPONSE_ERRORS'
export const LOGO_REMOVE_LOGO_RESPONSE_SUCCESS                              = 'LOGO_REMOVE_LOGO_RESPONSE_SUCCESS'
export const LOGO_REMOVE_LOGO_RESET                                         = 'LOGO_REMOVE_LOGO_RESET'
export const CAMERAS_CAMERA_INFO_RESET                                      = 'CAMERAS_CAMERA_INFO_RESET'
export const CAMERAS_FETCH_CAMERA_INFO_REQUEST                              = 'CAMERAS_FETCH_CAMERA_INFO_REQUEST'
export const CAMERAS_FETCH_CAMERA_INFO_RESPONSE_ERRORS                      = 'CAMERAS_FETCH_CAMERA_INFO_RESPONSE_ERRORS'
export const CAMERAS_FETCH_CAMERA_INFO_RESPONSE_SUCCESS                     = 'CAMERAS_FETCH_CAMERA_INFO_RESPONSE_SUCCESS'
export const PROCESSING_RESET                                               = 'PROCESSING_RESET'
export const PROCESSING_GET_TYPES_REQUEST                                   = 'PROCESSING_GET_TYPES_REQUEST'
export const PROCESSING_GET_TYPES_ERRORS                                    = 'PROCESSING_GET_TYPES_ERRORS'
export const PROCESSING_GET_TYPES_SUCCESS                                   = 'PROCESSING_GET_TYPES_SUCCESS'
export const PROCESSING_SET_PROCESS_REQUEST                                 = 'PROCESSING_SET_PROCESS_REQUEST'
export const PROCESSING_SET_PROCESS_SUCCESS                                 = 'PROCESSING_SET_PROCESS_RESPONSE'
export const PROCESSING_SET_PROCESS_ERRORS                                  = 'PROCESSING_SET_PROCESS_ERRORS'
export const PROCESSING_REMOVE_PROCESS_REQUEST                              = 'PROCESSING_REMOVE_PROCESS_REQUEST'
export const PROCESSING_REMOVE_PROCESS_SUCCESS                              = 'PROCESSING_REMOVE_PROCESS_RESPONSE'
export const PROCESSING_REMOVE_PROCESS_ERRORS                               = 'PROCESSING_REMOVE_PROCESS_ERRORS'
export const CAMERA_ADD_PROCESSING_PROCESS                                  = 'CAMERA_ADD_PROCESSING_PROCESS'
export const CAMERA_REMOVE_PROCESSING_PROCESS                               = 'CAMERA_REMOVE_PROCESSING_PROCESS'
export const TAGS_ASSOCIATE_CAMERA_WITH_TAG_RESET                           = 'TAGS_ASSOCIATE_CAMERA_WITH_TAG_RESET'
export const TAGS_ASSOCIATE_CAMERA_WITH_TAG_REQUEST                         = 'TAGS_ASSOCIATE_CAMERA_WITH_TAG_REQUEST'
export const TAGS_ASSOCIATE_CAMERA_WITH_TAG_RESPONSE_ERRORS                 = 'TAGS_ASSOCIATE_CAMERA_WITH_TAG_RESPONSE_ERRORS'
export const TAGS_ASSOCIATE_CAMERA_WITH_TAG_RESPONSE_SUCCESS                = 'TAGS_ASSOCIATE_CAMERA_WITH_TAG_RESPONSE_SUCCESS'
export const TAGS_UNASSOCIATE_CAMERA_WITH_TAG_RESET                         = 'TAGS_UNASSOCIATE_CAMERA_WITH_TAG_RESET'
export const TAGS_UNASSOCIATE_CAMERA_WITH_TAG_REQUEST                       = 'TAGS_UNASSOCIATE_CAMERA_WITH_TAG_REQUEST'
export const TAGS_UNASSOCIATE_CAMERA_WITH_TAG_RESPONSE_ERRORS               = 'TAGS_UNASSOCIATE_CAMERA_WITH_TAG_RESPONSE_ERRORS'
export const TAGS_UNASSOCIATE_CAMERA_WITH_TAG_RESPONSE_SUCCESS              = 'TAGS_UNASSOCIATE_CAMERA_WITH_TAG_RESPONSE_SUCCESS'
export const CAMERAS_FETCH_CAMERA_TAGS_RESET                                = 'CAMERAS_FETCH_CAMERA_TAGS_RESET'
export const CAMERAS_FETCH_CAMERA_TAGS_REQUEST                              = 'CAMERAS_FETCH_CAMERA_TAGS_REQUEST'
export const CAMERAS_FETCH_CAMERA_TAGS_RESPONSE_ERRORS                      = 'CAMERAS_FETCH_CAMERA_TAGS_RESPONSE_ERRORS'
export const CAMERAS_FETCH_CAMERA_TAGS_RESPONSE_SUCCESS                     = 'CAMERAS_FETCH_CAMERA_TAGS_RESPONSE_SUCCESS'
export const EMBED_RESET                                                    = 'EMBED_RESET'
export const EMBED_FETCH_ONE_REQUEST                                        = 'EMBED_FETCH_ONE_REQUEST'
export const EMBED_FETCH_ONE_RESPONSE_ERRORS                                = 'EMBED_FETCH_ONE_RESPONSE_ERRORS'
export const EMBED_FETCH_ONE_RESPONSE_SUCCESS                               = 'EMBED_FETCH_ONE_RESPONSE_SUCCESS'
export const EMBED_FETCH_ALL_REQUEST                                        = 'EMBED_FETCH_ALL_REQUEST'
export const EMBED_FETCH_ALL_RESPONSE_ERRORS                                = 'EMBED_FETCH_ALL_RESPONSE_ERRORS'
export const EMBED_FETCH_ALL_RESPONSE_SUCCESS                               = 'EMBED_FETCH_ALL_RESPONSE_SUCCESS'
export const EMBED_CREATE_REQUEST                                           = 'EMBED_CREATE_REQUEST'
export const EMBED_CREATE_RESPONSE_ERRORS                                   = 'EMBED_CREATE_RESPONSE_ERRORS'
export const EMBED_CREATE_RESPONSE_SUCCESS                                  = 'EMBED_CREATE_RESPONSE_SUCCESS'
export const EMBED_CREATE_RESET                                             = 'EMBED_CREATE_RESET'
export const EMBED_UPDATE_REQUEST                                           = 'EMBED_UPDATE_REQUEST'
export const EMBED_UPDATE_RESPONSE_ERRORS                                   = 'EMBED_UPDATE_RESPONSE_ERRORS'
export const EMBED_UPDATE_RESPONSE_SUCCESS                                  = 'EMBED_UPDATE_RESPONSE_SUCCESS'
export const EMBED_UPDATE_RESET                                             = 'EMBED_UPDATE_RESET'
export const EMBED_DELETE_REQUEST                                           = 'EMBED_DELETE_REQUEST'
export const EMBED_DELETE_RESPONSE_ERRORS                                   = 'EMBED_DELETE_RESPONSE_ERRORS'
export const EMBED_DELETE_RESPONSE_SUCCESS                                  = 'EMBED_DELETE_RESPONSE_SUCCESS'
export const CAMERAS_RESET                                                  = 'CAMERAS_RESET'
export const FILE_LIST_REQUEST                                              = 'FILE_LIST_REQUEST'
export const FILE_LIST_RESPONSE_SUCCESS                                     = 'FILE_LIST_RESPONSE_SUCCESS'
export const FILE_LIST_RESPONSE_ERRORS                                      = 'FILE_LIST_RESPONSE_ERRORS'
export const FILE_LIST_SET_PAGE                                             = 'FILE_LIST_SET_PAGE'
export const FILE_LIST_SET_START_DATE                                       = 'FILE_LIST_SET_START_DATE'
export const FILE_LIST_SET_END_DATE                                         = 'FILE_LIST_SET_END_DATE'
export const FILE_LIST_RESET                                                = 'FILE_LIST_RESET'
export const FILE_LIST_DELETE_REQUEST                                       = 'FILE_LIST_DELETE_REQUEST'
export const FILE_LIST_DELETE_SUCCESS                                       = 'FILE_LIST_DELETE_SUCCESS'
export const FILE_LIST_DELETE_ERRORS                                        = 'FILE_LIST_DELETE_ERRORS'
export const CAMERA_UPLOAD_REQUEST                                          = 'CAMERA_UPLOAD_REQUEST'
export const CAMERA_UPLOAD_RESPONSE_SUCCESS                                 = 'CAMERA_UPLOAD_RESPONSE_SUCCESS'
export const CAMERA_UPLOAD_RESPONSE_ERRORS                                  = 'CAMERA_UPLOAD_RESPONSE_ERRORS'
export const POT_ASSIGN_FETCH_PCIDS_REQUEST                                 = 'POT_ASSIGN_FETCH_PCIDS_REQUEST'
export const POT_ASSIGN_FETCH_PCIDS_ERRORS                                  = 'POT_ASSIGN_FETCH_PCIDS_ERRORS'
export const POT_ASSIGN_FETCH_PCIDS_SUCCESS                                 = 'POT_ASSIGN_FETCH_PCIDS_SUCCESS'
export const POT_ASSIGN_IDENTIFY_REQUEST                                    = 'POT_ASSIGN_IDENTIFY_REQUEST'
export const POT_ASSIGN_IDENTIFY_ERROR                                      = 'POT_ASSIGN_IDENTIFY_ERROR'
export const POT_ASSIGN_IDENTIFY_SUCCESS                                    = 'POT_ASSIGN_IDENTIFY_SUCCESS'
export const POT_ASSIGN_NEXT_POT_REQUEST                                    = 'POT_ASSIGN_NEXT_POT_REQUEST'
export const POT_ASSIGN_NEXT_POT_ERROR                                      = 'POT_ASSIGN_NEXT_POT_ERROR'
export const POT_ASSIGN_NEXT_POT_SUCCESS                                    = 'POT_ASSIGN_NEXT_POT_SUCCESS'
export const POT_ASSIGN_ASSIGN_REQUEST                                      = 'POT_ASSIGN_ASSIGN_REQUEST'
export const POT_ASSIGN_ASSIGN_ERROR                                        = 'POT_ASSIGN_ASSIGN_ERROR'
export const POT_ASSIGN_ASSIGN_SUCCESS                                      = 'POT_ASSIGN_ASSIGN_SUCCESS'
export const POT_ASSIGN_RESET                                               = 'POT_ASSIGN_RESET'
export const BIM_FINAL_3D_IMAGE_UPLOAD_REQUEST                              = 'BIM_FINAL_3D_IMAGE_UPLOAD_REQUEST'
export const BIM_FINAL_3D_IMAGE_UPLOAD_ERRORS                               = 'BIM_FINAL_3D_IMAGE_UPLOAD_ERRORS'
export const BIM_FINAL_3D_IMAGE_UPLOAD_SUCCESS                              = 'BIM_FINAL_3D_IMAGE_UPLOAD_SUCCESS'
export const BIM_FINAL_3D_IMAGE_REMOVE_REQUEST                              = 'BIM_FINAL_3D_IMAGE_REMOVE_REQUEST'
export const BIM_FINAL_3D_IMAGE_REMOVE_ERRORS                               = 'BIM_FINAL_3D_IMAGE_REMOVE_ERRORS'
export const BIM_FINAL_3D_IMAGE_REMOVE_SUCCESS                              = 'BIM_FINAL_3D_IMAGE_REMOVE_SUCCESS'
export const BIM_FINAL_3D_IMAGE_RESET                                       = 'BIM_FINAL_3D_IMAGE_RESET'
export const BIM_FINAL_3D_SET_FILENAME                                      = 'BIM_FINAL_3D_SET_FILENAME'
export const VIDEO_EDIT_LIST_REQUEST                                        = 'VIDEO_EDIT_LIST_REQUEST'
export const VIDEO_EDIT_LIST_RESPONSE                                       = 'VIDEO_EDIT_LIST_RESPONSE'
export const VIDEO_EDIT_LIST_ERROR                                          = 'VIDEO_EDIT_LIST_ERROR'
export const DELETE_VIDEO_EDIT_REQUEST                                      = 'DELETE_VIDEO_EDIT_REQUEST'
export const DELETE_VIDEO_EDIT_SUCCESS                                      = 'DELETE_VIDEO_EDIT_SUCCESS'
export const DELETE_VIDEO_EDIT_ERROR                                        = 'DELETE_VIDEO_EDIT_ERROR'
export const REQUEUE_VIDEO_EDIT_REQUEST                                     = 'REQUEUE_VIDEO_EDIT_REQUEST'
export const REQUEUE_VIDEO_EDIT_SUCCESS                                     = 'REQUEUE_VIDEO_EDIT_SUCCESS'
export const REQUEUE_VIDEO_EDIT_ERROR                                       = 'REQUEUE_VIDEO_EDIT_ERROR'
export const REMOVE_VIDEO_EDIT                                              = 'REMOVE_VIDEO_EDIT'
export const USERS_WITH_PROJECT_ACCESS_REQUEST                              = 'USERS_WITH_PROJECT_ACCESS_REQUEST'
export const USERS_WITH_PROJECT_ACCESS_SUCCESS                              = 'USERS_WITH_PROJECT_ACCESS_SUCCESS'
export const USERS_WITH_PROJECT_ACCESS_ERROR                                = 'USERS_WITH_PROJECT_ACCESS_ERROR'
export const CAMERA_CONTROL_V4_FETCH_SETUP_MODE_REQUEST                     = 'CAMERA_CONTROL_V4_FETCH_SETUP_MODE_REQUEST'
export const CAMERA_CONTROL_V4_FETCH_SETUP_MODE_RESPONSE_ERRORS             = 'CAMERA_CONTROL_V4_FETCH_SETUP_MODE_RESPONSE_ERRORS'
export const CAMERA_CONTROL_V4_FETCH_SETUP_MODE_RESPONSE_SUCCESS            = 'CAMERA_CONTROL_V4_FETCH_SETUP_MODE_RESPONSE_SUCCESS'
export const CAMERA_CONTROL_V4_START_SETUP_MODE_REQUEST                     = 'CAMERA_CONTROL_V4_START_SETUP_MODE_REQUEST'
export const CAMERA_CONTROL_V4_START_SETUP_MODE_RESPONSE_ERRORS             = 'CAMERA_CONTROL_V4_START_SETUP_MODE_RESPONSE_ERRORS'
export const CAMERA_CONTROL_V4_START_SETUP_MODE_RESPONSE_SUCCESS            = 'CAMERA_CONTROL_V4_START_SETUP_MODE_RESPONSE_SUCCESS'
export const CAMERA_CONTROL_V4_STOP_SETUP_MODE_REQUEST                      = 'CAMERA_CONTROL_V4_STOP_SETUP_MODE_REQUEST'
export const CAMERA_CONTROL_V4_STOP_SETUP_MODE_RESPONSE_ERRORS              = 'CAMERA_CONTROL_V4_STOP_SETUP_MODE_RESPONSE_ERRORS'
export const CAMERA_CONTROL_V4_STOP_SETUP_MODE_RESPONSE_SUCCESS             = 'CAMERA_CONTROL_V4_STOP_SETUP_MODE_RESPONSE_SUCCESS'
export const CAMERA_CONTROL_V4_RESET                                        = 'CAMERA_CONTROL_V4_RESET'
export const CAMERA_CONTROL_V4_FOCUS_REQUEST                                = 'CAMERA_CONTROL_V4_FOCUS_REQUEST'
export const CAMERA_CONTROL_V4_FOCUS_RESPONSE_ERRORS                        = 'CAMERA_CONTROL_V4_FOCUS_RESPONSE_ERRORS'
export const CAMERA_CONTROL_V4_FOCUS_RESPONSE_SUCCESS                       = 'CAMERA_CONTROL_V4_FOCUS_RESPONSE_SUCCESS'
export const CAMERA_CONTROL_V4_AUTO_FOCUS_REQUEST                           = 'CAMERA_CONTROL_V4_AUTO_FOCUS_REQUEST'
export const CAMERA_CONTROL_V4_AUTO_FOCUS_RESPONSE_ERRORS                   = 'CAMERA_CONTROL_V4_AUTO_FOCUS_RESPONSE_ERRORS'
export const CAMERA_CONTROL_V4_AUTO_FOCUS_RESPONSE_SUCCESS                  = 'CAMERA_CONTROL_V4_AUTO_FOCUS_RESPONSE_SUCCESS'
export const CAMERA_CONTROL_V4_LOGS_REQUEST                                 = 'CAMERA_CONTROL_V4_LOGS_REQUEST'
export const CAMERA_CONTROL_V4_LOGS_RESPONSE_ERRORS                         = 'CAMERA_CONTROL_V4_LOGS_RESPONSE_ERRORS'
export const CAMERA_CONTROL_V4_LOGS_RESPONSE_SUCCESS                        = 'CAMERA_CONTROL_V4_LOGS_RESPONSE_SUCCESS'
export const CAMERA_CONTROL_V4_BATTERY_SOURCE_REQUEST                       = 'CAMERA_CONTROL_V4_BATTERY_SOURCE_REQUEST'
export const CAMERA_CONTROL_V4_BATTERY_SOURCE_RESPONSE_ERRORS               = 'CAMERA_CONTROL_V4_BATTERY_SOURCE_RESPONSE_ERRORS'
export const CAMERA_CONTROL_V4_BATTERY_SOURCE_RESPONSE_SUCCESS              = 'CAMERA_CONTROL_V4_BATTERY_SOURCE_RESPONSE_SUCCESS'
export const CAMERA_CONTROL_V4_FETCH_CAMERA_INFO_REQUEST                    = 'CAMERA_CONTROL_V4_FETCH_CAMERA_INFO_REQUEST'
export const CAMERA_CONTROL_V4_FETCH_CAMERA_INFO_RESPONSE_ERRORS            = 'CAMERA_CONTROL_V4_FETCH_CAMERA_INFO_RESPONSE_ERRORS'
export const CAMERA_CONTROL_V4_FETCH_CAMERA_INFO_RESPONSE_SUCCESS           = 'CAMERA_CONTROL_V4_FETCH_CAMERA_INFO_RESPONSE_SUCCESS'
export const CAMERA_CONTROL_TEST_RESULTS_REQUEST                            = 'CAMERA_CONTROL_TEST_RESULTS_REQUEST'
export const CAMERA_CONTROL_TEST_RESULTS_ERRORS                             = 'CAMERA_CONTROL_TEST_RESULTS_ERRORS'
export const CAMERA_CONTROL_TEST_RESULTS_SUCCESS                            = 'CAMERA_CONTROL_TEST_RESULTS_SUCCESS'
export const CAMERA_CONTROL_TEST_RESULT_DETAILS_REQUEST                     = 'CAMERA_CONTROL_TEST_RESULT_DETAILS_REQUEST'
export const CAMERA_CONTROL_TEST_RESULT_DETAILS_ERRORS                      = 'CAMERA_CONTROL_TEST_RESULT_DETAILS_ERRORS'
export const CAMERA_CONTROL_TEST_RESULT_DETAILS_SUCCESS                     = 'CAMERA_CONTROL_TEST_RESULT_DETAILS_SUCCESS'
export const SUBSCRIPTIONS_REQUEST                                          = 'SUBSCRIPTIONS_REQUEST'
export const SUBSCRIPTIONS_RESPONSE_ERRORS                                  = 'SUBSCRIPTIONS_RESPONSE_ERRORS'
export const SUBSCRIPTIONS_RESPONSE_SUCCESS                                 = 'SUBSCRIPTIONS_RESPONSE_SUCCESS'
export const SUBSCRIPTIONS_ADD_REQUEST                                      = 'SUBSCRIPTIONS_ADD_REQUEST'
export const SUBSCRIPTIONS_ADD_RESPONSE_ERRORS                              = 'SUBSCRIPTIONS_ADD_RESPONSE_ERRORS'
export const SUBSCRIPTIONS_ADD_RESPONSE_SUCCESS                             = 'SUBSCRIPTIONS_ADD_RESPONSE_SUCCESS'
export const SUBSCRIPTIONS_REMOVE_REQUEST                                   = 'SUBSCRIPTIONS_REMOVE_REQUEST'
export const SUBSCRIPTIONS_REMOVE_RESPONSE_ERRORS                           = 'SUBSCRIPTIONS_REMOVE_RESPONSE_ERRORS'
export const SUBSCRIPTIONS_REMOVE_RESPONSE_SUCCESS                          = 'SUBSCRIPTIONS_REMOVE_RESPONSE_SUCCESS'
export const SUBSCRIPTIONS_RESET                                            = 'SUBSCRIPTIONS_RESET'
export const SUBSCRIPTIONS_CAMERA_SUBSCRIBE_REQUEST                         = 'SUBSCRIPTIONS_CAMERA_SUBSCRIBE_REQUEST'
export const SUBSCRIPTIONS_CAMERA_SUBSCRIBE_ERRORS                          = 'SUBSCRIPTIONS_CAMERA_SUBSCRIBE_ERRORS'
export const SUBSCRIPTIONS_CAMERA_SUBSCRIBE_SUCCESS                         = 'SUBSCRIPTIONS_CAMERA_SUBSCRIBE_SUCCESS'
export const SUBSCRIPTIONS_CAMERA_UNSUBSCRIBE_REQUEST                       = 'SUBSCRIPTIONS_CAMERA_UNSUBSCRIBE_REQUEST'
export const SUBSCRIPTIONS_CAMERA_UNSUBSCRIBE_ERRORS                        = 'SUBSCRIPTIONS_CAMERA_UNSUBSCRIBE_ERRORS'
export const SUBSCRIPTIONS_CAMERA_UNSUBSCRIBE_SUCCESS                       = 'SUBSCRIPTIONS_CAMERA_UNSUBSCRIBE_SUCCESS'
export const SUBSCRIPTIONS_PORTAL_LINK_REQUEST                              = 'SUBSCRIPTIONS_PORTAL_LINK_REQUEST'
export const SUBSCRIPTIONS_PORTAL_LINK_SUCCESS                              = 'SUBSCRIPTIONS_PORTAL_LINK_SUCCESS'
export const SUBSCRIPTIONS_PORTAL_LINK_ERRORS                               = 'SUBSCRIPTIONS_PORTAL_LINK_ERRORS'
export const SUBSCRIPTIONS_PAYWALL_CONFIG_SUCCESS                           = 'SUBSCRIPTIONS_PAYWALL_CONFIG_SUCCESS'
export const SUBSCRIPTIONS_PAYWALL_CONFIG_ERRORS                            = 'SUBSCRIPTIONS_PAYWALL_CONFIG_ERRORS'
export const SUBSCRIPTIONS_NEW_CAMERA_REQUEST                               = 'SUBSCRIPTIONS_NEW_CAMERA_REQUEST'
export const SUBSCRIPTIONS_NEW_CAMERA_SUCCESS                               = 'SUBSCRIPTIONS_NEW_CAMERA_SUCCESS'
export const SUBSCRIPTIONS_NEW_CAMERA_ERRORS                                = 'SUBSCRIPTIONS_NEW_CAMERA_ERRORS'
export const RESET_MFA_REQUEST                                              = 'RESET_MFA_REQUEST'
export const RESET_MFA_ERRORS                                               = 'RESET_MFA_ERRORS'
export const RESET_MFA_SUCCESS                                              = 'RESET_MFA_SUCCESS'
export const SEND_MFA_TOKEN_REQUEST                                         = 'SEND_MFA_TOKEN_REQUEST'
export const SEND_MFA_TOKEN_RESPONSE                                        = 'SEND_MFA_TOKEN_RESPONSE'
export const SEND_MFA_TOKEN_ERROR                                           = 'SEND_MFA_TOKEN_ERROR'
export const VERIFY_MFA_TOKEN_REQUEST                                       = 'VERIFY_MFA_TOKEN_REQUEST'
export const VERIFY_MFA_TOKEN_RESPONSE                                      = 'VERIFY_MFA_TOKEN_RESPONSE'
export const VERIFY_MFA_TOKEN_ERROR                                         = 'VERIFY_MFA_TOKEN_ERROR'
export const TOTP_CREATE_FACTOR_REQUEST                                     = 'TOTP_CREATE_FACTOR_REQUEST'
export const TOTP_CREATE_FACTOR_RESPONSE_ERRORS                             = 'TOTP_CREATE_FACTOR_RESPONSE_ERRORS'
export const TOTP_CREATE_FACTOR_RESPONSE_SUCCESS                            = 'TOTP_CREATE_FACTOR_RESPONSE_SUCCESS'
export const TOTP_VERIFY_FACTOR_REQUEST                                     = 'TOTP_VERIFY_FACTOR_REQUEST'
export const TOTP_VERIFY_FACTOR_RESPONSE_ERRORS                             = 'TOTP_VERIFY_FACTOR_RESPONSE_ERRORS'
export const TOTP_VERIFY_FACTOR_RESPONSE_SUCCESS                            = 'TOTP_VERIFY_FACTOR_RESPONSE_SUCCESS'
export const TOTP_FACTOR_RESET                                              = 'TOTP_FACTOR_RESET'
export const USER_MFA_ENTER                                                 = 'USER_MFA_ENTER'
export const USER_MFA_SETUP                                                 = 'USER_MFA_SETUP'