import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import React, { Component } from 'react';
import * as authActions from '../actions/authActions'
import * as logActions from '../actions/logActions'
import * as messengerActions from '../actions/messengerActions'
import * as cameraControlActions from '../actions/cameraControlActions'
import * as dropActions from '../actions/dropActions'
import * as uploadQueueActions from '../actions/uploadQueueActions'
import * as scheduleActions from '../actions/scheduleActions'
import CameraControl from "../components/pages/cameraControl/CameraControl"
import { isPermitted } from "./../util/acl"
import { checkAuth } from '../util/accessToken'
import { Container } from 'semantic-ui-react'

class CameraControlContainer extends Component {

    constructor(props) {
        super(props)
        this.props.cameraControlActions.resetLog()
    }

    componentDidMount () {
        window.scrollTo(0, 0)

        checkAuth(this.props.auth, this.props.authActions, this.props.history)
    }

    render() {
        let includeLP3Features = false

        if ('camera' in this.props.camera_control
            && this.props.camera_control.camera
        ) {
            if ('details' in this.props.camera_control.camera
                && this.props.camera_control.camera.details
            ) {
                if (this.props.camera_control.camera.details.pot_version === 2 || this.props.camera_control.camera.details.pot_version === 3) {
                    includeLP3Features = true
                }
            }
        }
        const cameraId = this.props.match.params.cameraId
        const cameraControl = (
            isPermitted(this.props.auth.user, 'get-cameracontrol-status')
        ) ?
            <CameraControl
                cameras_id={cameraId}
                includeLP3Features={includeLP3Features}
                {...this.props}
            />
            : null
        return (
            <div>
                <div className="sub-header sub-header--camera-control">
                    <Container fluid={true} style={{padding: '0 1.2em'}}>
                        <div>
                            <h1 className='sub-header__heading'>
                                {this.props.camera_control.camera.details && this.props.camera_control.camera.details.custom_name}
                                &nbsp;-&nbsp;
                                {this.props.camera_control.camera.details && this.props.camera_control.camera.details.projects_name}
                            </h1>
                            <p>Last Check In: {this.props.camera_control.camera.details && this.props.camera_control.camera.details.last_check_in}</p>
                        </div>
                    </Container>
                </div>
                {cameraControl}
            </div>)
    }

}

CameraControlContainer.propTypes = {
    authActions: PropTypes.object,
    auth: PropTypes.object,
    logActions: PropTypes.object,
    messengerActions: PropTypes.object,
    camera_control: PropTypes.object,
    cameraControlActions: PropTypes.object
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        projects: state.projects,
        addProject: state.addProject,
        updateProject: state.updateProject,
        camera_control: state.cameraControl,
        drop: state.drop,
        uploadQueue: state.uploadQueue,
        schedule: state.schedule
    }
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(authActions, dispatch),
        logActions: bindActionCreators(logActions, dispatch),
        messengerActions: bindActionCreators(messengerActions, dispatch),
        cameraControlActions: bindActionCreators(cameraControlActions, dispatch),
        dropActions: bindActionCreators(dropActions, dispatch),
        uploadQueueActions: bindActionCreators(uploadQueueActions, dispatch),
        scheduleActions: bindActionCreators(scheduleActions, dispatch)
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CameraControlContainer))
