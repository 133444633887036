import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Popup } from 'semantic-ui-react'

const EditPeriod = (props) => {
    return (
        <Popup
            trigger={
                <Icon
                    name='configure'
                    size={props.small ? null :'large'}
                    style={{
                        color: '#ededed'
                    }}
                    link
                    onClick={(event) => {
                        event.stopPropagation()
                        props.onClick(
                            props.dayId, props.period)
                    }}
                    data-testid="icon--edit-period"
                />
            }
            content="Edit period"
            basic
        />
    )
}

export default EditPeriod

EditPeriod.propTypes = {
    small: PropTypes.bool,
    dayId: PropTypes.number.isRequired,
    period: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired
}
