import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Segment } from 'semantic-ui-react'
import moment from 'moment'

class CameraStatus extends Component {
    formatUptime = (uptime) => {
        const duration = moment.duration(uptime, 'seconds');
        return duration.days() + ' days, '
                + duration.hours() + ' hours, '
                + duration.minutes() + ' minutes and '
                +duration.seconds() + ' seconds'
    }

    render() {
        let details

        if (this.props.loading) {
            details = (
                <div className="animated-blank-line" />
            )
        } else if (this.props.details) {
            details =  (
                <div>
                    <p>Uptime: {this.formatUptime(this.props.details.uptime)}</p>
                    <p>Scheduler: {this.props.details.schedulerd_running ? 'Running' : 'Stopped'}</p>
                    <p>Camera Control: {this.props.details.camera_control_running ? 'Running' : 'Stopped'}</p>
                    <small>
                        <p>Frontend Version: {this.props.details.frontend_version}</p>
                        <p>Backend Version: {this.props.details.backend_version}</p>
                        <p>CPU Architecture: {this.props.details.architecture}</p>
                        <p>PCID: {this.props.details.pcid}</p>
                    </small>
                </div>
            )
        } else {
            details = (
                <div>Unknown</div>
            )
        }
        return (
            <Segment>
                <h4>Status</h4>
                {details}
            </Segment>
        )
    }

}

CameraStatus.propTypes = {
    loading: PropTypes.bool.isRequired,
    details: PropTypes.object
}

export default CameraStatus
