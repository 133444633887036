import * as types from './actionTypes'
import { API_BASE_URL }  from '../config'
import { addMessage, removeAllMessages } from './messengerActions'
import { log } from './logActions'
import { notAuthorisedResponse } from './authActions'
import { fetchWithToken } from '../util/accessToken'

export function reset() {
    return {
        type: types.SCHEDULE_RESET
    }
}

export function load(cameraId) {
    return (dispatch, getState) => {
        dispatch(removeAllMessages())
        dispatch({
            type: types.SCHEDULE_LOAD_REQUEST,
        })
        // Do the fetch here
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/scheduler/schedule`,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    // Remember, we're bouncing out 401s
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                addMessage(
                                    "negative",
                                    "Unable to load schedule",
                                    data.errors
                                )
                            )
                            dispatch(
                                {
                                    type: types.SCHEDULE_LOAD_ERROR,
                                    errors: data.errors,
                                })
                        } else {
                            // Error
                            dispatch(
                                addMessage(
                                    "negative",
                                    "Unable to load schedule",
                                    "Please try again later"
                                )
                            )
                            dispatch(
                                {
                                    type: types.SCHEDULE_LOAD_ERROR,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error"
                                        }
                                    }
                                }
                            )
                        }

                    })
                }
            } else {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to load schedule",
                                data.errors
                            )
                        )
                        dispatch(
                            {
                                type: types.SCHEDULE_LOAD_ERROR,
                                errors: data.errors,
                            }
                        )
                    } else {
                        dispatch(
                            {
                                type: types.SCHEDULE_LOAD_SUCCESS,
                                data: data
                            }
                        )
                    }
                })

            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function save(cameraId) {
    return (dispatch, getState) => {
        dispatch(removeAllMessages())
        dispatch({
            type: types.SCHEDULE_SAVE_REQUEST,
        })
        const state = getState()
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/scheduler/schedule`,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify([
                    {
                        json_class: 'Schedule',
                        schedule: state.schedule.days
                    }
                ])
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    // Remember, we're bouncing out 401s
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.message) {
                            dispatch(
                                addMessage(
                                    "negative",
                                    "Unable to save schedule",
                                    data.message
                                )
                            )
                            dispatch(
                                {
                                    type: types.SCHEDULE_SAVE_ERROR,
                                    errors: data.message
                                })
                        } else {
                            // Error
                            dispatch(
                                addMessage(
                                    "negative",
                                    "Unable to save schedule",
                                    "Please try again later"
                                )
                            )
                            dispatch(
                                {
                                    type: types.SCHEDULE_SAVE_ERROR,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error"
                                        }
                                    }
                                }
                            )
                        }

                    })
                }
            } else {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to save schedule",
                                data.errors
                            )
                        )
                        dispatch(
                            {
                                type: types.SCHEDULE_SAVE_ERROR,
                                errors: data.errors
                            })
                    } else {
                        dispatch(
                            addMessage(
                                "positive",
                                "Success",
                                "The schedule successfully saved"
                            )
                        )
                        dispatch(
                            {
                                type: types.SCHEDULE_SAVE_SUCCESS,
                            })
                    }

                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function clearDay(day) {
    return {
        type: types.SCHEDULE_CLEAR_DAY,
        day: day
    }
}

export function copyToNextDay(day) {
    return {
        type: types.SCHEDULE_COPY_TO_NEXT_DAY,
        day: day
    }
}

export function addPeriod(data) {
    return {
        type: types.SCHEDULE_ADD_PERIOD,
        data: data
    }
}

export function modifyPeriod(data) {
    return {
        type: types.SCHEDULE_MODIFY_PERIOD,
        data: data
    }
}

export function removePeriod(day = null, period = null) {
    return (dispatch, getState) => {
        const state = getState()
        if (day === null) {
            day = state.schedule.editPeriodModalDay
        }
        if (period === null) {
            period = state.schedule.editPeriodModalPeriod
        }
        dispatch(
            {
                type: types.SCHEDULE_REMOVE_PERIOD,
                day: day,
                period: period
            }
        )
    }
}

export function increasePeriodPriority(day, period) {
    return {
        type: types.SCHEDULE_INCREASE_PERIOD_PRIORITY,
        day: day,
        period: period
    }
}

export function decreasePeriodPriority(day, period) {
    return {
        type: types.SCHEDULE_DECREASE_PERIOD_PRIORITY,
        day: day,
        period: period
    }
}

export function openAddPeriodModal(day) {
    return {
        type: types.SCHEDULE_SHOW_ADD_PERIOD_MODAL,
        day: day
    }
}

export function openEditPeriodModal(day, period) {
    return {
        type: types.SCHEDULE_SHOW_EDIT_PERIOD_MODAL,
        day: day,
        period: period
    }
}

export function dismissAddPeriodModal() {
    return {
        type: types.SCHEDULE_HIDE_ADD_PERIOD_MODAL
    }
}


export function dismissEditPeriodModal() {
    return {
        type: types.SCHEDULE_HIDE_EDIT_PERIOD_MODAL
    }
}

export function applyTemplate(key) {
    return {
        type: types.SCHEDULE_APPLY_TEMPLATE,
        key: key
    }
}

export function populateScheduleDay(data) {
    return {
        type: types.SCHEDULE_V4_POPULATE_DAY,
        data: data
    }
}

export function populateScheduleDayError(error) {
    return (dispatch, getState) => {
        dispatch(
            addMessage(
                "negative",
                error.message,
                error.errors
            )
        )
        dispatch ({
            type: types.SCHEDULE_V4_POPULATE_DAY_ERROR,
            error: error
        })
    }
}

export function modifyScheduleDay(data) {
    return {
        type: types.SCHEDULE_V4_MODIFY_DAY,
        data: data
    }
}