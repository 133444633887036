import React, { Component } from 'react'
import { Table, Icon, Menu} from 'semantic-ui-react'
import 'react-datepicker/dist/react-datepicker.css'

export default class LogTable extends Component {

    constructor(props) {
        super(props)
        let numberPerPage = 20
        let numberOfPages = Math.ceil(props.logs.length / numberPerPage);
        let nextPage, prevPage = null
        let currPage = 1
        if (numberOfPages > 1) {
            nextPage = currPage + 1
        }
        this.state = {
            currPage: currPage,
            numberPerPage: numberPerPage,
            numberOfPages: numberOfPages,
            nextPage: nextPage,
            prevPage: prevPage,
            start: null,
            end: null
        }

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props !== prevProps) {
            this.setState((prevState, props) => {
                let numberPerPage = this.state.numberPerPage
                let numberOfPages = Math.ceil(props.logs.length / numberPerPage);

                let nextPage, prevPage = null
                let currPage = 1
                if (numberOfPages > 1) {
                    nextPage = currPage + 1
                    prevPage = null
                }

                return {
                    currPage: currPage,
                    numberPerPage: numberPerPage,
                    numberOfPages: numberOfPages,
                    nextPage: nextPage,
                    prevPage: prevPage,
                    start: null,
                    end: null
                }

            })
        }
    }

    onChangeStart = (start) => {

        if (start) {
            this.setState({ start: start })
        } else {
            this.setState((prevState, props) => {

                // We might have more or fewer results...
                let numberOfPages = Math.ceil(this.props.logs.length / this.state.numberPerPage);

                let nextPage, prevPage = null
                let currPage = 1
                if (numberOfPages > 1) {
                    nextPage = currPage + 1
                    prevPage = null
                }

                return {
                    currPage: currPage,
                    numberPerPage: this.state.numberPerPage,
                    numberOfPages: numberOfPages,
                    nextPage: nextPage,
                    prevPage: prevPage,
                    start: null,
                    end: this.state.end
                }
            })
        }
    }

    onChangeEnd = (end) => {
        if (end) {
            this.setState({ end: end })
        } else {
            this.setState((prevState, props) => {
                // We might have more or fewer results...
                let numberOfPages = Math.ceil(this.props.logs.length / this.state.numberPerPage);

                let nextPage, prevPage = null
                let currPage = 1
                if (numberOfPages > 1) {
                    nextPage = currPage + 1
                    prevPage = null
                }

                return {
                    currPage: currPage,
                    numberPerPage: this.state.numberPerPage,
                    numberOfPages: numberOfPages,
                    nextPage: nextPage,
                    prevPage: prevPage,
                    start: this.state.start,
                    end: null
                }
            })

        }
    }

    nextPage = () => {
        this.setState((prevState, props) => {
            let numberPerPage = prevState.numberPerPage
            let numberOfPages = Math.ceil(props.logs.length / numberPerPage);
            let nextPage, prevPage = null
            let currPage = prevState.currPage + 1

            if (numberOfPages > 1 && currPage < numberOfPages) {
                nextPage = currPage + 1
                if (currPage > 1) {
                    prevPage = currPage - 1

                } else {
                    prevPage = null
                }
            } else if (numberOfPages > 1 && currPage === numberOfPages) {
                nextPage = null
                prevPage = currPage - 1
            }

            // Sanity check and fix
            if (currPage > numberOfPages) {
                currPage = numberOfPages
                nextPage = null
                prevPage = (numberOfPages > 1) ? currPage - 1 : null
            }

            return {
                currPage: currPage,
                numberPerPage: numberPerPage,
                numberOfPages: numberOfPages,
                nextPage: nextPage,
                prevPage: prevPage,
                start: prevState.start,
                end: prevState.end
            }
        })


    }

    prevPage = () => {
        this.setState((prevState, props) => {
            let numberPerPage = prevState.numberPerPage
            let numberOfPages = Math.ceil(props.logs.length / numberPerPage);
            let nextPage, prevPage = null
            let currPage = prevState.currPage - 1

            if (numberOfPages > 1 && currPage < numberOfPages) {
                nextPage = currPage + 1
                if (currPage > 1) {
                    prevPage = currPage - 1

                } else {
                    prevPage = null
                }
            } else if (numberOfPages > 1 && currPage === numberOfPages) {
                nextPage = null
                prevPage = currPage - 1
            }

            // Sanity check and fix
            if (currPage < 1) {
                currPage = 1
                nextPage = (numberOfPages > 1) ? currPage + 1 : null
                prevPage =  null
            }

            return {
                currPage: currPage,
                numberPerPage: numberPerPage,
                numberOfPages: numberOfPages,
                nextPage: nextPage,
                prevPage: prevPage,
                start: prevState.start,
                end: prevState.end
            }
        })


    }

    jumpToPage = (page) => {

        this.setState((prevState, props) => {

            let numberPerPage = prevState.numberPerPage
            let numberOfPages = Math.ceil(props.logs.length / numberPerPage);
            let nextPage, prevPage = null
            let currPage = page

            if (numberOfPages > 1 && currPage < numberOfPages) {
                nextPage = currPage + 1
                if (currPage > 1) {
                    prevPage = currPage - 1
                } else {
                    prevPage = null
                }
            } else if (numberOfPages > 1 && currPage === numberOfPages) {
                nextPage = null
                prevPage = currPage - 1
            }

            return {
                currPage: currPage,
                numberPerPage: numberPerPage,
                numberOfPages: numberOfPages,
                nextPage: nextPage,
                prevPage: prevPage,
                start: prevState.start,
                end: prevState.end
            }
        })

    }

    render = () => {

        const { currPage, numberPerPage, numberOfPages, nextPage, prevPage} = this.state

        //const begin = ((currPage - 1) * numberPerPage);
        //const end = begin + numberPerPage;

        return (
            <div className="log-table">
            <Table striped compact size='small'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>File</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        this.props.logs.map( (item, index) => {
                            return (
                                <Table.Row key={index}>
                                    <Table.Cell>
                                        <a
                                            className='external-link'
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            href={`${this.props.url}/${item}?access_token=${this.props.accessToken}`}
                                        >
                                            {item}
                                        </a>
                                    </Table.Cell>
                                </Table.Row>
                            )
                        })
                    }
                </Table.Body>
                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell>
                            <Menu floated='right' pagination>
                                {
                                    (prevPage) ? <Menu.Item as='a' icon onClick={() => {
                                        this.prevPage()
                                    }}>
                                        <Icon name='left chevron'/>
                                    </Menu.Item> : null
                                }
                                {
                                    Array(numberOfPages).fill(null).map( (u, i) => {
                                        return  (<Menu.Item key={i} as='a' active={currPage === (i + 1)} onClick={() => {
                                            const page = i + 1
                                            this.jumpToPage(page)
                                        }}>{i + 1}</Menu.Item>)
                                    })
                                }
                                {
                                    (nextPage) ?

                                    <Menu.Item as='a' icon onClick={() => {
                                        this.nextPage()
                                    }}>
                                        <Icon name='right chevron'/>
                                    </Menu.Item> : null
                                }
                            </Menu>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>

            </Table>
            </div>
        )
    }

}
