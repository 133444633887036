import * as types from './actionTypes'
import { API_BASE_URL } from '../config'
import { addMessage } from './messengerActions'
import { log } from './logActions'
import { notAuthorisedResponse } from './authActions'
import { fetchWithToken } from '../util/accessToken'

export function getProcessingTypes() {
    return (dispatch, getState) => {
        dispatch({ type: types.PROCESSING_GET_TYPES_REQUEST })
        return fetchWithToken(
            `${API_BASE_URL}/api/processing/types`,
            {
                method: 'GET',
                mode: 'cors',
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                // might be forbidden!
                if (response.status === 401) {
                    // Remember, we're bouncing out 401s
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch({ type: types.PROCESSING_GET_TYPES_ERRORS, errors: data.errors })
                        } else {
                            dispatch({
                                type: types.PROCESSING_GET_TYPES_ERRORS,
                                errors:  { logo: { unknownError: 'Unknown error' }}
                            })
                        }
                    })
                }
            } else {
                response.json().then((data) => {
                    // Presumably, errors of some kind...
                    if (data.errors) {
                        dispatch({ type: types.PROCESSING_GET_TYPES_ERRORS, errors: data.errors })
                    } else {
                        dispatch({
                            type: types.PROCESSING_GET_TYPES_SUCCESS,
                            types: data
                        })
                    }
                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function setProcess(cameraId, formData, processHandle) {
    return (dispatch, getState) => {
        dispatch({ type: types.PROCESSING_SET_PROCESS_REQUEST })
        let url
        if (processHandle) {
            url = `${API_BASE_URL}/api/cameras/${cameraId}/processing/set-${processHandle.replaceAll('_', '-')}`
        } else {
            url = `${API_BASE_URL}/api/cameras/${cameraId}/processing/set-blur`
        }

        return fetchWithToken(
            url,
            {
                method: 'POST',
                mode: 'cors',
                body: formData
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                // might be forbidden!
                if (response.status === 401) {
                    // Remember, we're bouncing out 401s
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch({ type: types.PROCESSING_SET_PROCESS_ERRORS, errors: data.errors })
                        } else {
                            dispatch({
                                type: types.PROCESSING_SET_PROCESS_ERRORS,
                                errors:  { logo: { unknownError: 'Unknown error' }}
                            })
                        }
                    })
                }
            } else {
                response.json().then((data) => {
                    // Presumably, errors of some kind...
                    if (data.errors) {
                        dispatch({ type: types.PROCESSING_SET_PROCESS_ERRORS, errors: data.errors })
                    } else {
                        dispatch({
                            type: types.PROCESSING_SET_PROCESS_SUCCESS
                        })
                        dispatch({
                            type: types.CAMERA_ADD_PROCESSING_PROCESS,
                            parameters: data
                        })
                    }
                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })

    }
}

export function removeProcess(cameraId, processHandle) {
    return (dispatch, getState) => {
        dispatch({ type: types.PROCESSING_REMOVE_PROCESS_REQUEST })
        let url
        if (processHandle) {
            url = `${API_BASE_URL}/api/cameras/${cameraId}/processing/remove-${processHandle.replaceAll('_', '-')}`
        } else {
            url = `${API_BASE_URL}/api/cameras/${cameraId}/processing/remove-blur`
        }

        return fetchWithToken(
            url,
            {
                method: 'POST',
                mode: 'cors'
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                // might be forbidden!
                if (response.status === 401) {
                    // Remember, we're bouncing out 401s
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch({ type: types.PROCESSING_REMOVE_PROCESS_ERRORS, errors: data.errors })
                        } else {
                            dispatch({
                                type: types.PROCESSING_REMOVE_PROCESS_ERRORS,
                                errors:  { blur: { unknownError: 'Unknown error' }}
                            })
                        }
                    })
                }
            } else {
                    response.json().then((data) => {
                        // Presumably, errors of some kind...
                        if (data.errors) {
                            dispatch({ type: types.PROCESSING_REMOVE_PROCESS_ERRORS, errors: data.errors })
                        } else {
                            dispatch({
                                type: types.PROCESSING_REMOVE_PROCESS_SUCCESS
                            })
                            dispatch({
                                type: types.CAMERA_REMOVE_PROCESSING_PROCESS,
                                parameters: data
                            })
                        }
                    })
                //}
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })

    }
}

export function reset() {
    return {
        type: types.PROCESSING_RESET
    }
}
