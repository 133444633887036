import initialState from './initialState';
import {
    ADD_USER_REQUEST,
    ADD_USER_RESPONSE,
    ADMIN_USER_ROLES_REQUEST,
    ADMIN_USER_ROLES_RESPONSE,
    ADMIN_USER_STATUSES_REQUEST,
    ADMIN_USER_STATUSES_RESPONSE,
    ADMIN_USER_CLIENTS_REQUEST,
    ADMIN_USER_CLIENTS_RESPONSE,
    ADD_USER_RESET_ERRORS,
    ADD_USER_RESPONSE_ERRORS,
    ADD_USER_RESPONSE_SUCCESS,
    ADD_USER_UPDATE_LOCAL_USER_STATE,
    ADD_USER_UCPA_COMPLETED,
    ADD_USER_CLEAR_ERRORS
} from '../actions/actionTypes';

export default function addUser(state = initialState.addUser, action) {

    switch (action.type) {
        case ADD_USER_RESPONSE:
            return Object.assign({}, state, action.addUser, { loading: false })
        case ADMIN_USER_ROLES_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    roles: []
                }
            )
        case ADMIN_USER_ROLES_RESPONSE:
            return Object.assign(
                {},
                state,
                {
                    roles: action.addUser
                }
            )
        case ADMIN_USER_STATUSES_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    statuses: []
                }
            )
            // return state
        case ADMIN_USER_STATUSES_RESPONSE:
            return Object.assign(
                {},
                state,
                {
                    statuses: action.addUser
                }
            )
        case ADMIN_USER_CLIENTS_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    clients: []
                }
            )
        case ADMIN_USER_CLIENTS_RESPONSE:
            return Object.assign(
                {},
                state,
                {
                    clients: action.addUser
                }
            )
        case ADD_USER_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    errors : {},
                    loading: true,
                    success: false,
                    error: false,
                    details: {}
                }
            )
        case ADD_USER_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    success: true,
                    error: false,
                    errors: {},
                    details: action.addUser
                }
            )
        case ADD_USER_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    errors : action.addUser.errors,
                    loading: false,
                    success: false,
                    error: true,
                    details: {}
                }
            )
        case ADD_USER_RESET_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    errors: {},
                    loading: false,
                    success: false,
                    error: false,
                    details: {},
                    user: initialState.addUser.user,
                    ucpa_completed: initialState.addUser.ucpa_completed
                }
            )
        case ADD_USER_UPDATE_LOCAL_USER_STATE:
            return Object.assign(
                {},
                state,
                {
                    user: action.user
                }
            )

        case ADD_USER_UCPA_COMPLETED:
            return Object.assign(
                {},
                state,
                {
                    ucpa_completed: true
                }
            )
        case ADD_USER_CLEAR_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    error: false,
                    errors: {}
                }
            )
        default:
            return state

    }
}
