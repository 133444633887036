import initialState from './initialState';
import {
    CAMERA_CONTROL_V4_RESET,
    CAMERA_CONTROL_V4_FETCH_SETUP_MODE_REQUEST,
    CAMERA_CONTROL_V4_FETCH_SETUP_MODE_RESPONSE_ERRORS,
    CAMERA_CONTROL_V4_FETCH_SETUP_MODE_RESPONSE_SUCCESS,
    CAMERA_CONTROL_V4_START_SETUP_MODE_REQUEST,
    CAMERA_CONTROL_V4_START_SETUP_MODE_RESPONSE_ERRORS,
    CAMERA_CONTROL_V4_START_SETUP_MODE_RESPONSE_SUCCESS,
    CAMERA_CONTROL_V4_STOP_SETUP_MODE_REQUEST,
    CAMERA_CONTROL_V4_STOP_SETUP_MODE_RESPONSE_ERRORS,
    CAMERA_CONTROL_V4_STOP_SETUP_MODE_RESPONSE_SUCCESS,
    CAMERA_CONTROL_V4_FOCUS_REQUEST,
    CAMERA_CONTROL_V4_FOCUS_RESPONSE_ERRORS,
    CAMERA_CONTROL_V4_FOCUS_RESPONSE_SUCCESS,
    CAMERA_CONTROL_V4_AUTO_FOCUS_REQUEST,
    CAMERA_CONTROL_V4_AUTO_FOCUS_RESPONSE_ERRORS,
    CAMERA_CONTROL_V4_AUTO_FOCUS_RESPONSE_SUCCESS,
    CAMERA_CONTROL_V4_LOGS_REQUEST,
    CAMERA_CONTROL_V4_LOGS_RESPONSE_ERRORS,
    CAMERA_CONTROL_V4_LOGS_RESPONSE_SUCCESS,
    CAMERA_CONTROL_V4_BATTERY_SOURCE_REQUEST,
    CAMERA_CONTROL_V4_BATTERY_SOURCE_RESPONSE_ERRORS,
    CAMERA_CONTROL_V4_BATTERY_SOURCE_RESPONSE_SUCCESS,
    CAMERA_CONTROL_V4_FETCH_CAMERA_INFO_REQUEST,
    CAMERA_CONTROL_V4_FETCH_CAMERA_INFO_RESPONSE_ERRORS,
    CAMERA_CONTROL_V4_FETCH_CAMERA_INFO_RESPONSE_SUCCESS,
} from '../actions/actionTypes';

export default function cameraControlV4(state = initialState.camera_control_v4, action) {

    switch (action.type) {
        case CAMERA_CONTROL_V4_RESET:

            return Object.assign(
                {},
                state,
                {
                    ...initialState.camera_control_v4
                }
            )

        case CAMERA_CONTROL_V4_FETCH_SETUP_MODE_REQUEST:

            return Object.assign(
                {},
                state,
                {
                    fetch_setup_mode: {
                        details: null,
                        loading: true,
                        success: false,
                        error: false,
                        errors: null
                    }
                }
            )

        case CAMERA_CONTROL_V4_FETCH_SETUP_MODE_RESPONSE_ERRORS:

            return Object.assign(
                {},
                state,
                {
                    fetch_setup_mode: {
                        details: null,
                        loading: false,
                        success: false,
                        error: true,
                        errors: action.errors
                    }
                }
            )

        case CAMERA_CONTROL_V4_FETCH_SETUP_MODE_RESPONSE_SUCCESS:

            return Object.assign(
                {},
                state,
                {
                    fetch_setup_mode: {
                        details: action.details,
                        loading: false,
                        success: true,
                        error: false,
                        errors: null
                    }
                }
            )


        case CAMERA_CONTROL_V4_START_SETUP_MODE_REQUEST:

            return Object.assign(
                {},
                state,
                {
                    setup_mode: {
                        processing: true,
                        action: 'Start',
                        success: false,
                        error: false,
                        errors: null,
                        details: null
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_V4_START_SETUP_MODE_RESPONSE_ERRORS:

            return Object.assign(
                {},
                state,
                {
                    setup_mode: {
                        processing: false,
                        action: 'Start',
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: null
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_V4_START_SETUP_MODE_RESPONSE_SUCCESS:

            return Object.assign(
                {},
                state,
                {
                    setup_mode: {
                        processing: false,
                        action: 'Start',
                        success: true,
                        error: false,
                        errors: null,
                        details: action.details
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_V4_STOP_SETUP_MODE_REQUEST:

            return Object.assign(
                {},
                state,
                {
                    setup_mode: {
                        processing: true,
                        action: 'Stop',
                        success: false,
                        error: false,
                        errors: null,
                        details: null
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_V4_STOP_SETUP_MODE_RESPONSE_ERRORS:

            return Object.assign(
                {},
                state,
                {
                    setup_mode: {
                        processing: false,
                        action: 'Stop',
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: null
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_V4_STOP_SETUP_MODE_RESPONSE_SUCCESS:

            return Object.assign(
                {},
                state,
                {
                    setup_mode: {
                        processing: false,
                        action: 'Stop',
                        success: true,
                        error: false,
                        errors: null,
                        details: action.details
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_V4_FOCUS_REQUEST:

            return Object.assign(
                {},
                state,
                {
                    focus: {
                        details: null,
                        loading: true,
                        success: false,
                        error: false,
                        errors: null
                    }
                }
            )

        case CAMERA_CONTROL_V4_FOCUS_RESPONSE_ERRORS:

            return Object.assign(
                {},
                state,
                {
                    focus: {
                        details: null,
                        loading: false,
                        success: false,
                        error: true,
                        errors: action.errors
                    }
                }
            )

        case CAMERA_CONTROL_V4_FOCUS_RESPONSE_SUCCESS:

            return Object.assign(
                {},
                state,
                {
                    focus: {
                        details: action.details,
                        loading: false,
                        success: true,
                        error: false,
                        errors: null
                    }
                }
            )

        case CAMERA_CONTROL_V4_AUTO_FOCUS_REQUEST:

            return Object.assign(
                {},
                state,
                {
                    focus: {
                        details: null,
                        loading: true,
                        success: false,
                        error: false,
                        errors: null
                    }
                }
            )

        case CAMERA_CONTROL_V4_AUTO_FOCUS_RESPONSE_ERRORS:

            return Object.assign(
                {},
                state,
                {
                    focus: {
                        details: null,
                        loading: false,
                        success: false,
                        error: true,
                        errors: action.errors
                    }
                }
            )

        case CAMERA_CONTROL_V4_AUTO_FOCUS_RESPONSE_SUCCESS:

            return Object.assign(
                {},
                state,
                {
                    focus: {
                        details: action.details,
                        loading: false,
                        success: true,
                        error: false,
                        errors: null
                    }
                }
            )

        case CAMERA_CONTROL_V4_LOGS_REQUEST:

            return Object.assign(
                {},
                state,
                {
                    system: {
                        details: {
                            logs: {
                                loading: true,
                                success: false,
                                error: false,
                                errors: null,
                                details: null
                            },
                            cameras_id: action.cameras_id
                        }
                    }
                }
            )
    
        case CAMERA_CONTROL_V4_LOGS_RESPONSE_ERRORS:

            return Object.assign(
                {},
                state,
                {
                    system: {
                        details: {
                            logs: {
                                loading: false,
                                success: false,
                                error: true,
                                errors: action.errors,
                                details: null
                            },
                            cameras_id: action.cameras_id
                        }
                    }
                    
                }
            )

        case CAMERA_CONTROL_V4_LOGS_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    system: {
                        details: {
                            logs: {
                                loading: false,
                                success: true,
                                error: false,
                                errors: null,
                                details: action.details
                            },
                            cameras_id: action.cameras_id
                        }
                    },
                }
            )

        case CAMERA_CONTROL_V4_BATTERY_SOURCE_REQUEST:

            return Object.assign(
                {},
                state,
                {
                    battery_source: {
                        details: null,
                        loading: true,
                        success: false,
                        error: false,
                        errors: null
                    }
                }
            )

        case CAMERA_CONTROL_V4_BATTERY_SOURCE_RESPONSE_ERRORS:

            return Object.assign(
                {},
                state,
                {
                    battery_source: {
                        details: null,
                        loading: false,
                        success: false,
                        error: true,
                        errors: action.errors
                    }
                }
            )

        case CAMERA_CONTROL_V4_BATTERY_SOURCE_RESPONSE_SUCCESS:

            return Object.assign(
                {},
                state,
                {
                    battery_source: {
                        details: action.details,
                        loading: false,
                        success: true,
                        error: false,
                        errors: null
                    }
                }
            )

        case CAMERA_CONTROL_V4_FETCH_CAMERA_INFO_REQUEST:
            {
                return Object.assign(
                    {},
                    state,
                    {
                        camera_info: action.details
                    }
                )
            }

        case CAMERA_CONTROL_V4_FETCH_CAMERA_INFO_RESPONSE_ERRORS:
            {
                return Object.assign(
                    {},
                    state,
                    {
                        camera_info: action.details
                    }
                )
            }

        case CAMERA_CONTROL_V4_FETCH_CAMERA_INFO_RESPONSE_SUCCESS:
            {
                return Object.assign(
                    {},
                    state,
                    {
                        camera_info: action.details,
                    }
                )
            }
        default:
            return state
    }

}
