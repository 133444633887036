
import React, {useState, useEffect, useRef, useLayoutEffect} from 'react'
import { Grid, Form, Dropdown } from 'semantic-ui-react'

const Subscription = (props) => {
    const [inputValue, setInputValue] = useState(null)
    const [updateData, setUpdateData] = useState({})
    const [options, setOptions] = useState([])

    // When component loads save current value in case it changes
    useEffect(() => {
        if (props.selectedCamera && props.selectedCamera.id) {
            setInputValue(props.selectedCamera.id)
            setUpdateData((prevState) => {
                return {
                ...prevState,
                initialValue: props.selectedCamera.id,
            }})
            props.loaded(props.selectedCamera.id)
        }
    }, [props.selectedCamera])

    // when input changes set initial value to prevSubCamera and current value to subCamera
    const onSelectedCameraChange = (event, data) => {
        setInputValue(data.value)
        setUpdateData((prevState) => {
            return {
            ...prevState,
            subscribedCamera: data.value,
        }})
    }

    // Store the current input value before it's changed
    const onSelectedCameraClick = (event, data,) => {
        setUpdateData((prevState) => {
            return {
            ...prevState,
            previousInputValue: data.value,
        }})
    }

    // If both old and new are updated pass these values back to parent container
    useEffect(() => {
        if (updateData.initialValue && updateData.subscribedCamera) {
            props.selectChanged(updateData.initialValue, null)
            props.selectChanged(null, updateData.subscribedCamera, updateData.previousInputValue)
            // If deleting value
        } else if (updateData.subscribedCamera === "") {
            props.selectChanged(updateData.previousInputValue, null)
            generateOptions()
        } else {
            props.selectChanged(null, updateData.subscribedCamera, updateData.previousInputValue)
        }
    }, [updateData])

    const generateOptions = () => {
        let data =  props.options.map(function(elem, index) {
            let optionText = (elem.custom_name)? `${elem.projects_name} - ${elem.custom_name}` : `${elem.projects_name} - ${elem.pot_id}`
            let disabled = false

            if (props.disabledOptions && props.disabledOptions.indexOf(elem.id) !== -1 || props.activeOptions && props.activeOptions.indexOf(elem.id) !== -1) {
                disabled = true
            }
            
            return {
                value: elem.id,
                text: optionText,
                key: elem.id,
                disabled: disabled
            }
        })

        setOptions(data)
    }

    
    // Options for the dropdown input
    useEffect(() => {
        generateOptions()
    }, [props.options, props.activeOptions, props.disabledOptions])

    return (
        <Grid.Column>
            <div className="card--subscriptions-list">
                <h3>Subscription {props.index+1}</h3>
                <div>
                    <p className='dropdown-label'>Associated Camera: </p>
                    <Dropdown
                        options={options}
                        placeholder='Select Camera'
                        onChange={onSelectedCameraChange}
                        onClick={onSelectedCameraClick}
                        value={(inputValue)? inputValue : null}
                        clearable
                    />
                </div>
            </div>
        </Grid.Column>
    )
}

export default Subscription