import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Container, Table, Menu, Icon, Popup } from 'semantic-ui-react'
import { isPermitted } from "./../util/acl"

class TagListView extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        // this.props.tagsActions.fetchAllTags(this.props.auth.user.clients_id, 1)
    }

    prevPage = (page) => {
        this.props.tagsActions.fetchAllTags(this.props.auth.user.clients_id, page, 'ALL')
    }

    nextPage = (page) => {
        this.props.tagsActions.fetchAllTags(this.props.auth.user.clients_id, page, 'ALL')
    }

    jumpToPage = (page) => {
        this.props.tagsActions.fetchAllTags(this.props.auth.user.clients_id, page, 'ALL'    )
    }

    getTags = () => {
        return (this.props.tags.list.tags
            && this.props.tags.list.tags.tags) ? this.props.tags.list.tags.tags : []
    }

    getNumberOfPages = (numberPerPage) => {
        if (this.props.tags.list.tags && this.props.tags.list.tags.totalItems) {
            if (this.props.tags.list.tags.totalItems > 0) {
                return Math.ceil(this.props.tags.list.tags.totalItems / numberPerPage)
            } else {
                return 1;
            }
        }
        return 1;
    }

    getPrevPageNumberOrNull = () => {
        return (this.props.tags.list.currentPage > 1) ?
            this.props.tags.list.currentPage - 1: null
    }

    getNextPageNumberOrNull = (numberOfPages) => {
        return (this.props.tags.list.currentPage < numberOfPages) ?
            this.props.tags.list.currentPage + 1: null
    }

    getCurrPageNumber = () => {
        return this.props.tags.list.currentPage
    }

    isPreloading = () => {
        return this.props.tags.list.loading /*&& this.props.tags.list.tags.tags === undefined*/
    }

    getUpdateTagLink = (item) => {
        let updateTagLink = null
        if (isPermitted(this.props.auth.user, 'put-tags-tags')) {
            updateTagLink = (

                <Popup
                    offset={10}
                    trigger={<Icon className='tag-modal-link action-link right' link={true} name='cogs' onClick={() => {
                        // this.props.onOpenModal(item.id)
                        this.props.onOpenUpdateTagModal(item.id)
                    }}/>}
                    content='Update'
                />

            )
        }
        return updateTagLink
    }

    getDeleteTagLink = (item) => {
        let deleteTagLink = null
        if (isPermitted(this.props.auth.user, 'delete-tags-tags')) {
            deleteTagLink = (

                <Popup
                    offset={10}
                    trigger={<Icon className='tag-modal-link action-link right' link={true} name='trash' onClick={() => {
                        // this.props.onOpenModal(item.id)
                        this.props.onOpenDeleteTagModal(item.id)
                    }}/>}
                    content='Remove'
                />

            )
        }
        return deleteTagLink
    }

    render = () => {

        const tags = this.getTags()
        const numberPerPage = this.props.tags.list.pageSize
        const numberOfPages = this.getNumberOfPages(numberPerPage)
        const currPage = this.getCurrPageNumber()
        const prevPage = this.getPrevPageNumberOrNull()
        const nextPage = this.getNextPageNumberOrNull(numberOfPages)

        if (this.isPreloading()) {
            return (
                <div className='tag-list-view'>
                    <div>
                        <div className='animated-blank-line'></div>
                        <div className='animated-blank-block-400'></div>
                        <div className='animated-blank-line'></div>
                    </div>
                </div>
            )
        }

        return (
            <div className='tag-list-view'>
                <Container className="content-container" fluid={true} style={{padding: '0 1.2em'}}>
                    <Table striped={true} fixed={true} selectable compact textAlign='left'>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell>Status</Table.HeaderCell>
                                <Table.HeaderCell colSpan='3'>Description</Table.HeaderCell>
                                <Table.HeaderCell textAlign='right'>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {tags.map((item, idx) => {
                                return (
                                    <Table.Row className="tag-table--row" key={idx}>
                                        <Table.Cell>{item.name}</Table.Cell>
                                        <Table.Cell>{item.status}</Table.Cell>
                                        <Table.Cell colSpan='3'>{item.description}</Table.Cell>

                                        <Table.Cell textAlign='right'>
                                            {this.getUpdateTagLink(item)}
                                            {this.getDeleteTagLink(item)}
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            })}
                        </Table.Body>

                        {(numberOfPages > 1) &&
                            <Table.Footer>
                                <Table.Row>
                                    <Table.HeaderCell colSpan='6'>
                                        <Menu floated='right' pagination>
                                            {(prevPage) ?
                                                <Menu.Item as='a' icon onClick={() => {
                                                    this.prevPage(prevPage)
                                                }}>
                                                    <Icon name='left chevron'/>
                                                </Menu.Item> : null
                                            }
                                            {Array(numberOfPages).fill(null).map((u, i) => {
                                                return (<Menu.Item key={i} as='a' active={currPage === (i + 1)}
                                                    onClick={() => {
                                                        const page = i + 1
                                                        this.jumpToPage(page)
                                                    }}>{i + 1}</Menu.Item>)
                                            })}
                                            {(nextPage) ?
                                                <Menu.Item as='a' icon onClick={() => {
                                                    this.nextPage(nextPage)
                                                }}>
                                                    <Icon name='right chevron'/>
                                                </Menu.Item> : null
                                            }
                                        </Menu>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        }
                    </Table>
                </Container>
            </div>
        )
    }
}

TagListView.propTypes = {
    tagsActions: PropTypes.object.isRequired,
    tags: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    onOpenUpdateTagModal: PropTypes.func.isRequired,
    onOpenDeleteTagModal: PropTypes.func.isRequired
}

export default TagListView
