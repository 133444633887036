import React, { Component } from 'react'
import { Button, Modal, Message } from 'semantic-ui-react'
import { getErrorMessage } from './../../util/errors'

class DeleteClientModal extends Component {

    getHeader = (error, success) => {
        if (error) {
            return "Error"
        } else if (success) {
            return "Success!"
        } else {
            return ""
        }
    }

    getContent = (error, success, loading, errors) => {
        if (error) {
            if (errors.clients_id) {
                return <p>{getErrorMessage(errors, "clients_id")}</p>
            } else if (errors.users_id) {
                return <p>{getErrorMessage(errors, "users_id")}</p>
            } else if (errors.authenticated_user) {
                return <p>{getErrorMessage(errors, "authenticated_user")}</p>
            }
        } else if (success) {
            return <p>Successfully deleted this client</p>
        } else if (loading) {
            return <p>Processing...</p>
        } else {
            return <p>Are you sure you want to delete this client?</p>
        }
    }

    getButtons = (error, success) => {
        if (error) {
            return null
        } else if (success) {
            return null
        } else {
            return <div>
                <Button onClick={this.props.onClose} negative>Cancel</Button>
                <Button onClick={this.props.onClick} loading={this.props.loading} positive icon='checkmark' labelPosition='right' content='Delete' />
            </div>
        }
    }

    render() {
        return (
            <Modal size={"small"} open={this.props.open} onClose={this.props.onClose} closeIcon>
                <Modal.Header>Delete a client</Modal.Header>
                <Modal.Content>
                    <Message
                        error={this.props.error}
                        success={this.props.success}
                        visible={true}
                        header={this.getHeader(this.props.error, this.props.success)}
                        content={this.getContent(this.props.error, this.props.success, this.props.loading, this.props.errors)}
                    />
                    <div className='modalButtons'>
                        {this.getButtons(this.props.error, this.props.success)}
                    </div>
                </Modal.Content>
            </Modal>
        )
    }
}

export default DeleteClientModal
