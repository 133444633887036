import * as types from './actionTypes'
import { API_BASE_URL}  from '../config'
import { addMessage } from './messengerActions'
import { log } from './logActions'
import { fetchWithToken } from '../util/accessToken'
import { notAuthorisedResponse } from './authActions'

export function addClientResponse(response) {
    return dispatch => {
        if (!response.errors) {
            dispatch(addMessage(
                "success",
                "Success",
                "Successfully created a new client."
            ))
            dispatch(addClientResponseSuccess(response))
            dispatch(resetErrors())
        } else {
            dispatch(addClientResponseErrors(response))
        }
    }
}

export function addClientResponseSuccess(response) {
    return {type: types.ADD_CLIENT_RESPONSE_SUCCESS, addClient: response}
}

export function addClientResponseErrors(response) {
    return {type: types.ADD_CLIENT_RESPONSE_ERRORS, addClient: response}
}

export function addClientRequest(history, clientDetails) {

    return (dispatch, getState) => {

        dispatch({ type: types.ADD_CLIENT_REQUEST })

        return fetchWithToken(
            API_BASE_URL + '/api/clients',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(clientDetails)
            },
            dispatch,
            getState
        )
        .then(response => {
            if (response.status === 401) {
                dispatch(notAuthorisedResponse())
            } else {
                return response.json()
            }
        })
        .then((json) => {
            dispatch(addClientResponse(json))
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function adminClientRolesResponse(response) {
    if (!Array.isArray(response)) {
        return {type: types.ADMIN_CLIENT_ROLES_RESPONSE, addClient: []}
    } else {
        return {type: types.ADMIN_CLIENT_ROLES_RESPONSE, addClient: response}
    }
}

export function adminClientRolesRequest() {

    return (dispatch, getState) => {

        dispatch({ type: types.ADMIN_CLIENT_ROLES_REQUEST })

        return fetchWithToken(
            API_BASE_URL + '/api/admin/client/roles',
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (response.status === 401) {
                dispatch(notAuthorisedResponse())
            } else {
                return response.json()
            }
        })
        .then((json) => {
            dispatch(adminClientRolesResponse(json))
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })

    }
}


export function adminClientStatusesResponse(response) {
    if (!Array.isArray(response)) {
        return {type: types.ADMIN_CLIENT_STATUSES_RESPONSE, addClient: []}
    } else {
        return {type: types.ADMIN_CLIENT_STATUSES_RESPONSE, addClient: response}
    }
}

export function adminClientStatusesRequest() {

    return (dispatch, getState) => {

        dispatch({ type: types.ADMIN_CLIENT_STATUSES_REQUEST })

        return fetchWithToken(
            API_BASE_URL + '/api/admin/client/statuses',
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (response.status === 401) {
                dispatch(notAuthorisedResponse())
            } else {
                return response.json()
            }
        })
        .then((json) => {
            dispatch(adminClientStatusesResponse(json))
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })

    }
}

export function resetErrors() {
    return { type: types.ADD_CLIENT_RESET_ERRORS }
}

export function resetSuccess() {
    return { type: types.ADD_CLIENT_RESET_SUCCESS }
}
