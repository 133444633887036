import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid, Divider } from 'semantic-ui-react'
import { API_BASE_URL }  from './../config'
import LogTable from "./LogTableV4"

class Logs extends Component {

    isReady = () => {
        return this.props.camera_control_v4.system
            && this.props.camera_control_v4.system.details
            && this.props.camera_control_v4.system.details.logs
    }

    

    render = () => {
        if (!this.isReady()) {
            return (
                <div className='logs'>
                    <Grid columns='1'>
                        <Grid.Row>
                            <Grid.Column>
                                <Grid.Column>The camera is currently offline</Grid.Column>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            )
        } else {
            const logs = this.props.camera_control_v4.system.details.logs.details || []
            const accessToken = localStorage['accessToken']
            return (
                <div className='logs'>
                    <Grid columns='1'>
                        <Grid.Row>
                            <Grid.Column>
                                <p>Here are the most recent log files for this camera.</p>
                                {/* <ul>
                                    <li>
                                        <a href={`${API_BASE_URL}/api/cameras/${this.props.cameras_id}/camera-control/logs/${}?access_token`
                                        + `=${accessToken}`} target='_blank' >Logs</a>
                                    </li>
                                </ul> */}
                                <Divider />
                                <LogTable
                                    logs={logs} url={`${API_BASE_URL}/api/cameras/${this.props.cameras_id}/camera-control/logs`}
                                    accessToken={accessToken}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            )
        }
    }
}

Logs.propTypes = {
    cameraControlActions: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    cameras_id: PropTypes.string.isRequired,
    camera_control: PropTypes.object.isRequired
}

export default Logs
