import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Segment } from 'semantic-ui-react'

class CameraStorage extends Component {

    toGB = (bytes) => {
        return parseFloat(bytes / 1024 / 1024 / 1024).toFixed(2)
    }

    render() {
        let details

        if (this.props.loading) {
            details = (
                <div className="animated-blank-line" />
            )
        } else if (this.props.details
                   && this.props.details.output
                   && this.props.details.output.camera
                   && this.props.details.output.camera.storage
        ) {
            if (this.props.details.output.camera.storage.length === 0) {
                details = (
                    <div>
                        <span style={{color: 'red'}}>NO CARD</span>
                    </div>
                )
            } else {
                details = this.props.details.output.camera.storage.map(((card, index) => {
                    return (
                        <div key={`detail-${index}`}>
                            <h5>{card.type}</h5>
                            Size: {this.toGB(card.size)}GB,
                            Used: {this.toGB(card.size - card.free)}GB,
                            Free: {this.toGB(card.free)}GB
                        </div>
                    )
                }))
            }

        } else {
            details = (
                <div>Unknown</div>
            )
        }
        return (
            <Segment>
                <h4>Camera Storage</h4>
                {details}
            </Segment>
        )
    }

}

CameraStorage.propTypes = {
    loading: PropTypes.bool.isRequired,
    details: PropTypes.object
}

export default CameraStorage
