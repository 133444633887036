import * as types from './actionTypes'
import { API_BASE_URL }  from '../config'
import { addMessage } from './messengerActions'
import { log } from './logActions'
import { fetchWithToken } from '../util/accessToken'

export function applyLockdownRequest() {

    return (dispatch, getState) => {

        dispatch({ type: types.SECURITY_APPLY_LOCKDOWN_REQUEST })

        return fetchWithToken(
            API_BASE_URL + '/api/security/lockdown/on',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {

            if (!response.ok) {
                // If here, we have errors
                response.json().then(data => {
                    // Hmmm...bit of a fudge here until we implement a better fix
                    data.errors = {
                            unexpectedError : {
                            errorMessage: data.message
                        }
                    }
                    dispatch(applyLockdownResponseErrors(data))
                })
            } else {

                // Might be No Content...especially on success
                response.json().then(data => {

                    if (data.errors) {
                        dispatch(applyLockdownResponseErrors(data))
                    } else {
                        dispatch(applyLockdownResponseSuccess(data))
                    }
                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })

    }
}

export function resetApplyLockdownErrors() {
    return  { type: types.SECURITY_APPLY_LOCKDOWN_RESET_ERRORS }
}

export function applyLockdownResponseErrors(payload) {
    return  {
        type: types.SECURITY_APPLY_LOCKDOWN_RESPONSE_ERRORS,
        ...payload
    }
}

export function applyLockdownResponseSuccess(payload) {
    return  {
        type: types.SECURITY_APPLY_LOCKDOWN_RESPONSE_SUCCESS,
        ...payload
    }
}

export function removeLockdownRequest() {

    return (dispatch, getState) => {

        dispatch({ type: types.SECURITY_REMOVE_LOCKDOWN_REQUEST })

        return fetchWithToken(
            API_BASE_URL + '/api/security/lockdown/off',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {

            if (!response.ok) {
                // If here, we have errors
                response.json().then(data => {
                    // Hmmm...bit of a fudge here until we implement a better fix
                    data.errors = {
                        unexpectedError : {
                            errorMessage: data.message
                        }
                    }
                    dispatch(removeLockdownResponseErrors(data))
                })
            } else {
                // Might be No Content...especially on success
                response.json().then(data => {

                    if (data.errors) {
                        dispatch(removeLockdownResponseErrors(data))
                    } else {
                        dispatch(removeLockdownResponseSuccess(data))
                    }

                })
            }

        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function resetRemoveLockdownErrors() {
    return  { type: types.SECURITY_REMOVE_LOCKDOWN_RESET_ERRORS }
}

export function removeLockdownResponseErrors(payload) {
    return  {
        type: types.SECURITY_REMOVE_LOCKDOWN_RESPONSE_ERRORS,
        ...payload
    }
}

export function removeLockdownResponseSuccess(payload) {
    return  {
        type: types.SECURITY_REMOVE_LOCKDOWN_RESPONSE_SUCCESS,
        ...payload
    }
}
