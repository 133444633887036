import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Message,
    Form,
    Select,
    Checkbox,
    Tab,
    Popup,
    Icon,
    Grid,
} from 'semantic-ui-react'
import { getErrorMessage } from './../util/errors'
import TemperatureUnit from "./forms/checkboxes/TemperatureUnit";
import WindSpeedUnit from "./forms/checkboxes/WindSpeedUnit";
import ShowBimModelType from './forms/checkboxes/ShowBimModelType';
import UserProjectsAndCamerasSelector from "./UserProjectsAndCamerasSelector";

class CreateUser extends Component {

    constructor() {
        super()
        this.state = {
            settings: {
                weather: {
                    enabled: true,
                    temperature_unit: 'Celsius',
                    wind_speed_unit: 'MPH',
                },
                kiosk_mode_enabled: false,
                permitted_to_save: true,
                permitted_to_zoom: true,
                permitted_to_view_calendar: true,
                show_navigation_permission: 'Yes',
                show_thumbnails_permission: 'Yes',
                permitted_to_view_timelapse: true,
                permitted_to_auto_refresh: false,
                hide_virtual_cameras: false,
                show_bim_model_type: 'Contract'
            },
            job_title: "",
            first_name: "",
            last_name: "",
            username: "",
            email: "",
            roles_id: 3,
            status: "New",
            clients_id: "",
            lockdown: "OFF",
            processed: true,
            limit_history: 0,
            delay: 0,
            api: false,
            comment: "",
            loginless: false,
            video_editor: true
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props !== prevProps) {
            this.setState( (prevState, props) => {
                return {
                    first_name: props.user.first_name,
                    last_name: props.user.last_name,
                    email:  props.user.email,
                    roles_id: props.user.roles_id,
                    clients_id: props.clientId,
                    status: props.user.status,
                    settings: props.user.settings,
                    username: props.user.username,
                    job_title: props.user.job_title,
                    lockdown: props.user.lockdown,
                    processed: props.user.processed,
                    limit_history: props.user.limit_history,
                    delay: props.user.delay,
                    api: props.user.api,
                    comment: props.user.comment,
                    video_editor: props.user.video_editor
                }
            })
        }
    }

    componentDidMount() {
        this.props.adminUserRolesRequest()
        // this.props.adminUserStatusesRequest()
            this.props.adminUserClientsRequest()
    }

    static getDerivedStateFromProps(props, state) {
        if (state.clients_id !== props.clientId && props.auth && props.auth.user && (props.auth.user.roles_id === 4 || props.auth.user.roles_id === 5 )) {
            return { clients_id: props.clientId }
        }

        return null
     }

    render = () => {
        let message = null

        if (this.props.loading) {
            message = this.getProgressingMessageForStepOne()
        } else if (this.props.success && this.props.ucpa_completed) {
            message = this.getSuccessfullyCompletedMessage()
        } else if (this.props.success && !this.props.ucpa_completed) {
            message =  this.getProgressingMessageForStepTwo()
        } else if (this.props.error) {
            message =  this.getPartiallySuccessfulMessage()
        } else {
            message = this.getInstructionMessage()
        }

        const tabs = this.getTabs()
        return (
            <div className='create-user'>
                {message}
                {tabs}
            </div>
        )
    }

    getInstructionMessage = () => {
        return (
            <Message
                error={false}
                success={false}
                visible={true}
                header={'Instructions'}
                content={<p>Please complete the <em> User Settings</em>  tab and optionally the <em>Projects and cameras associated with user </em>tab.</p>}
            />
        )
    }

    getProgressingMessageForStepOne = () => {
        return (
            <Message
                error={false}
                success={false}
                visible={true}
                header={'Processing...'}
                content={'Creating a new user account'}
            />
        )
    }

    getProgressingMessageForStepTwo = () => {
        return (
            <Message
                error={false}
                success={false}
                visible={true}
                header={'Processing...'}
                content={'Creating any project or camera associations for the new user account'}
            />
        )
    }

    getSuccessfullyCompletedMessage = () => {
        return (
            <Message
                error={false}
                success={true}
                visible={true}
                header={'Finished processing'}
                content={'Created a new user account and also created any project or camera associations'}
            />
        )
    }

    getPartiallySuccessfulMessage = () => {
        return (
            <Message
                error={true}
                success={false}
                visible={true}
                header={'Finished processing'}
                content={'It looks like there was one or more errors while processing your request'}
            />
        )
    }

    getTabs = () => {

        if (!this.props.success) {
            return (<Tab panes={this.getPanes()} activeIndex={this.props.activeIndex} onTabChange={this.onTabChange}/>)
        }

        if (this.props.success && !this.props.ucpa_completed) {
            return (<Tab panes={this.getPanes()} activeIndex={this.props.activeIndex} onTabChange={this.onTabChange}/>)
        }

        return null
    }

    onTabChange = (e, {activeIndex}) => {
        this.props.activeTabChange(activeIndex)
    }

    onJobTitleChange = (event) => {
        const val = event.target.value
        this.setState((prevState, props) => {
            return {
                ...prevState,
                job_title: val
            }
        }, () => {
            this.props.onUpdateLocalUserState(this.state)
        })
    }

    onUsernameChange = (event) => {
        const val = event.target.value
        this.setState((prevState, props) => {
            return {
                ...prevState,
                username: val
            }
        }, () => {
            this.props.onUpdateLocalUserState(this.state)
        })
    }

    onEmailChange = (event, isLobsterUser) => {
        const val = event.target.value.trim()
        this.setState((prevState, props) => {
            if (!isLobsterUser) {
                return {
                    ...prevState,
                    email: val,
                    username: val,
                }
            } else {
                return {
                    ...prevState,
                    email: val
                }
            }
        }, () => {
            this.props.onUpdateLocalUserState(this.state)
        })
    }

    onFirstnameChange = (event) => {
        const val = event.target.value
        this.setState((prevState, props) => {
            return {
                ...prevState,
                first_name: val
            }
        }, () => {
            this.props.onUpdateLocalUserState(this.state)
        })
    }

    onLastnameChange = (event) => {
        const val = event.target.value
        this.setState((prevState, props) => {
            return {
                ...prevState,
                last_name: val
            }
        }, () => {
            this.props.onUpdateLocalUserState(this.state)
        })
    }

    onRoleChange = (event, value) => {
        const val = value.value
        this.setState((prevState, props) => {
            return {
                ...prevState,
                roles_id: val
            }
        }, () => {
            this.props.onUpdateLocalUserState(this.state)
        })
    }

    onClientChange = (event, value) => {
        const val = value.value
        this.setState((prevState, props) => {
            return {
                ...prevState,
                clients_id: val
            }
        }, () => {
            this.props.onUpdateLocalUserState(this.state)
            // Let's trigger an event so that the projects and cameras can be fetched now...
            this.props.onClientChange(val)
        })


    }

    onStatusChange = (event, value) => {
        const val = value.value
        this.setState((prevState, props) => {
            return {
                ...prevState,
                status: val
            }
        }, () => {
            this.props.onUpdateLocalUserState(this.state)
        })
    }

    onWeatherSettingsChange = (event, {checked}) => {
        this.setState((prevState, props) => {
            return {
                ...prevState,
                settings: {
                    ...prevState.settings,
                    weather: {
                        ...prevState.settings.weather,
                        enabled: checked
                    }
                }
            }
        }, () => {
            this.props.onUpdateLocalUserState(this.state)
        })
    }

    onTemperatureUnitChange = (event, value) => {
        this.setState((prevState, props) => {
            return {
                ...prevState,
                settings: {
                    ...prevState.settings,
                    weather: {
                        ...prevState.settings.weather,
                        temperature_unit: value
                    }
                }
            }
        }, () => {
            this.props.onUpdateLocalUserState(this.state)
        })
    }

    onWindSpeedUnitChange = (event, value) => {
        this.setState((prevState, props) => {
            return {
                ...prevState,
                settings: {
                    ...prevState.settings,
                    weather: {
                        ...prevState.settings.weather,
                        wind_speed_unit: value
                    }
                }
            }
        }, () => {
            this.props.onUpdateLocalUserState(this.state)
        })
    }

    onAutoRefreshPermissionChange = (event, {checked}) => {
        this.setState((prevState, props) => {
            return {
                ...prevState,
                settings: {
                    ...prevState.settings,
                    permitted_to_auto_refresh: checked
                }
            }
        }, () => {
            this.props.onUpdateLocalUserState(this.state)
        })
    }

    onHideVirtualCamerasChange = (event, {checked}) => {
        this.setState((prevState, props) => {
            return {
                ...prevState,
                settings: {
                    ...prevState.settings,
                    hide_virtual_cameras: checked
                }
            }
        }, () => {
            this.props.onUpdateLocalUserState(this.state)
        })
    }

    onBimContractDeliveryChange = (event, value) => {
        this.setState((prevState, props) => {
            return {
                ...prevState,
                settings: {
                    ...prevState.settings,
                    show_bim_model_type: value
                }
            }
        }, () => {
            this.props.onUpdateLocalUserState(this.state)
        })
    }

    onKioskModeEnabledChange = (event, {checked}) => {
        this.setState((prevState, props) => {
            return {
                ...prevState,
                settings: {
                    ...prevState.settings,
                    kiosk_mode_enabled: checked,
                    permitted_to_save: false
                }
            }
        }, () => {
            this.props.onUpdateLocalUserState(this.state)
        })
    }

    onProcessedChange = (event, {checked}) => {
        this.setState((prevState, props) => {
            return {
                ...prevState,
                processed: checked
            }
        }, () => {
            this.props.onUpdateLocalUserState(this.state)
        })
    }

    onAllowSavingPermissionChange = (event, {checked}) => {
        let video_editor = this.state.video_editor
        if (!checked) {
          video_editor = false
        }
        this.setState((prevState, props) => {
            return {
                ...prevState,
                settings: {
                    ...prevState.settings,
                    permitted_to_save: checked
                },
                video_editor: video_editor
            }
        }, () => {
            this.props.onUpdateLocalUserState(this.state)
        })
    }

    onAllowZoomingPermissionChange = (event, {checked}) => {
        this.setState((prevState, props) => {
            return {
                ...prevState,
                settings: {
                    ...prevState.settings,
                    permitted_to_zoom: checked
                }
            }
        }, () => {
            this.props.onUpdateLocalUserState(this.state)
        })
    }

    onShowCalendarPermissionChange = (event, {checked}) => {
        this.setState((prevState, props) => {
            return {
                ...prevState,
                settings: {
                    ...prevState.settings,
                    permitted_to_view_calendar: checked
                }
            }
        }, () => {
            this.props.onUpdateLocalUserState(this.state)
        })
    }

    onShowNavigationPermissionChange = (event, value) => {
        const val = (value.checked) ? "Yes" : "No"
        this.setState((prevState, props) => {
            return {
                ...prevState,
                settings: {
                    ...prevState.settings,
                    show_navigation_permission: val
                }
            }
        }, () => {
            this.props.onUpdateLocalUserState(this.state)
        })
    }

    onShowThumbnailsPermissionChange = (event, value) => {
        const val = (value.checked) ? "Yes" : "No"
        this.setState((prevState, props) => {
            return {
                ...prevState,
                settings: {
                    ...prevState.settings,
                    show_thumbnails_permission: val
                }
            }
        }, () => {
            this.props.onUpdateLocalUserState(this.state)
        })
    }

    onViewTimeLapsePermissionChange = (event, {checked}) => {
        this.setState((prevState, props) => {
            return {
                ...prevState,
                settings: {
                    ...prevState.settings,
                    permitted_to_view_timelapse: checked
                }
            }
        }, () => {
            this.props.onUpdateLocalUserState(this.state)
        })
    }

    onAllowVideoEditingPermissionChange = (event, {checked}) => {
        this.setState((prevState, props) => {
            return {
                ...prevState,
                video_editor: checked
            }
        }, () => {
            this.props.onUpdateLocalUserState(this.state)
        })
    }

    onLockdownChange = (event, value) => {
        const val = (value.checked) ? "ON" : "OFF"
        this.setState((prevState, props) => {
            return {
                ...prevState,
                lockdown: val
            }
        }, () => {
            this.props.onUpdateLocalUserState(this.state)
        })
    }

    onDelayChange = (event) => {
        const val = event.target.value
        this.setState((prevState, props) => {
            return {
                ...prevState,
                delay: val
            }
        }, () => {
            this.props.onUpdateLocalUserState(this.state)
        })
    }

    onLimitHistoryChange = (event) => {
        const val = event.target.value
        this.setState((prevState, props) => {
            return {
                ...prevState,
                limit_history: val
            }
        }, () => {
            this.props.onUpdateLocalUserState(this.state)
        })
    }

    onLoginlessChange = (event, {checked}) => {
        let { video_editor } = this.state;
        if (checked) {
            video_editor = false;
        }
        this.setState(
            {
                loginless: checked,
                video_editor: video_editor
            },
            () => {
                this.props.onUpdateLocalUserState(this.state)
            }
        )
    }

    onCustomLandingPageChange = (event) => {
        const val = event.target.value
        this.setState((prevState, props) => {
            return {
                ...prevState,
                custom_landing_page: val
            }
        }, () => {
            this.props.onUpdateLocalUserState(this.state)
        })
    }

    onApiChange = (event, {checked}) => {
        this.setState((prevState, props) => {
            return {
                ...prevState,
                api: checked
            }
        }, () => {
            this.props.onUpdateLocalUserState(this.state)
        })
    }

    onCommentChange = (event) => {
        const val = event.target.value
        this.setState((prevState, props) => {
            return {
                ...prevState,
                comment: val
            }
        }, () => {
            this.props.onUpdateLocalUserState(this.state)
        })
    }

    getHeader = (error, success) => {
        if (error) {
            return <p>Error</p>
        } else if (success) {
            return <p>Success!</p>
        } else {
            return ""
        }
    }

    getContent = (error, success, loading, errors) => {
        // We might have a server error, or just regular errors
        if (error) {
            // sendVerificationEmail
            if (errors.sendVerificationEmail) {
                // No point showing the form...
                return <p>{getErrorMessage(errors, "sendVerificationEmail")}</p>
            } else {
                return this.renderForm()
            }
        } else if (success) {
            return <p>Successfully created this user</p>
        } else {
            // Must be initial loading...
            return this.renderForm()
        }
    }

    isPartialSuccess = () => {
        return (this.props.errors.sendVerificationEmail);
    }

    renderForm = () => {
        let isLobsterUser = false
        if (this.props.auth && this.props.auth.user && (this.props.auth.user.roles_id !== 4 && this.props.auth.user.roles_id !== 5)) {
            isLobsterUser = true;
        }

        let defaultUsernameValue = () => {
            if (isLobsterUser) {
                return this.props.user.username;
            } else if (this.state.username) {
                return this.state.username
            } else {
                return this.props.user.email
            }
        }

        const privateUserInputs = (
            <div>
                <Form.Input
                    fluid
                    placeholder='E-mail address'
                    label='E-mail address *'
                    onChange={(event) => this.onEmailChange(event, isLobsterUser)}
                    error={(this.props.errors.email) ? true : false}
                    defaultValue={this.props.user.email}
                />
                <Message
                    error={true}
                    visible={(this.props.errors.email) ? true : false}
                    header={'Error'}
                    content={getErrorMessage(this.props.errors, 'email')}
                />

                <Form.Input
                    fluid
                    label = 'Username *'
                    placeholder='E-mail address / Username'
                    onChange={this.onUsernameChange}
                    error={(this.props.errors.username) ? true : false}
                    defaultValue={defaultUsernameValue()}
                    disabled={!isLobsterUser}
                />
                <Message
                    error={true}
                    visible={(this.props.errors.username) ? true : false}
                    header={'Error'}
                    content={getErrorMessage(this.props.errors, 'username')}
                />

                <Form.Input
                    fluid
                    placeholder='Job Title'
                    label='Job Title'
                    error={(this.props.errors.job_title) ? true : false}
                    onChange={this.onJobTitleChange}
                    defaultValue={this.props.user.job_title}
                />
                <Message
                    error={true}
                    visible={(this.props.errors.job_title)}
                    header={'Error'}
                    content={getErrorMessage(this.props.errors, 'job_title')}
                />
                <Grid columns='2' stackable>
                    <Grid.Row>
                        <Grid.Column>
                            <Form.Input
                                fluid
                                placeholder='First Name'
                                label='First Name'
                                error={(this.props.errors.first_name) ? true : false}
                                onChange={this.onFirstnameChange}
                                defaultValue={this.props.user.first_name}
                            />
                            <Message
                                error={true}
                                visible={(this.props.errors.first_name) ? true : false}
                                header={'Error'}
                                content={getErrorMessage(this.props.errors, 'first_name')}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Form.Input
                                fluid
                                placeholder='Last Name'
                                label='Last Name'
                                onChange={this.onLastnameChange}
                                error={(this.props.errors.last_name) ? true : false}
                                defaultValue={this.props.user.last_name}

                            />
                            <Message
                                error={true}
                                visible={(this.props.errors.last_name) ? true : false}
                                header={'Error'}
                                content={getErrorMessage(this.props.errors, 'last_name')}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        )

        const publicUserInputs = (
            <div>
                <Form.Input
                    fluid
                    label={{ children: 'Public URL'}}
                    onChange={this.onUsernameChange}
                    error={(this.props.errors.username) ? true : false}
                    defaultValue={this.props.user.username}
                >
                    <div style={{paddingTop: '0.5em'}}>
                        lobstervision.tv/
                    </div>
                    <input />
                </Form.Input>
                <Message
                    error={true}
                    visible={(this.props.errors.username) ? true : false}
                    header={'Error'}
                    content={getErrorMessage(this.props.errors, 'username')}
                />

                {(isLobsterUser) ?
                    <>
                        <Form.Field fluid>
                            <label>
                                Custom landing page URL
                                <Popup trigger={<Icon name="question circle outline" />}>
                                    Redirects users to a specific camera, date or time
                                    on login.
                                </Popup>
                            </label>
                            <input
                                onChange={this.onCustomLandingPageChange}
                                defaultValue={this.props.user.custom_landing_page}
                                error={(this.props.errors.custom_landing_page) ? true : false}
                            />
                        </Form.Field>
                        <Message
                            error={true}
                            visible={(this.props.errors.custom_landing_page)}
                            header={'Error'}
                            content={getErrorMessage(this.props.errors, 'custom_landing_page')}
                        />
                    </>
                : null}
            </div>
        )

        const defaultClient = (isLobsterUser)? null : this.state.clients_id

        return (
            <Form size='large'>
                <Grid columns='2' stackable>
                    <Grid.Row>
                        <Grid.Column>
                            <h3>User Account Details</h3>
                            <Checkbox
                                className="field"
                                label={{ children: 'Publicly accessible' }}
                                onChange={this.onLoginlessChange}
                                defaultValue={this.props.user.loginless}
                            />
                            &nbsp;
                            <Popup trigger={<Icon name='question circle outline' />}>
                                A special kind of user where no password is required to
                                access the viewer. Instead users just go a custom URL
                                input below and get access password free.
                            </Popup>

                            {this.state.loginless ? publicUserInputs : privateUserInputs}
                           
                            <Form.Select
                                options={this.props.clients}
                                placeholder='Client'
                                label='Client *'
                                onChange={this.onClientChange}
                                error={(this.props.errors.clients_id) ? true : false}
                                defaultValue={this.state.clients_id}
                                search
                            />
                            <Message
                                error={true}
                                visible={(this.props.errors.clients_id) ? true : false}
                                header={'Error'}
                                content={getErrorMessage(this.props.errors, 'clients_id')}
                            />

                            {isLobsterUser ?
                                <>
                                    <Form.Field
                                        control={Select}
                                        options={this.props.roles}
                                        placeholder='Role'
                                        label='Role'
                                        onChange={this.onRoleChange}
                                        error={(this.props.errors.roles_id) ? true : false}
                                        defaultValue={this.state.roles_id}

                                    />
                                    <Message
                                        error={true}
                                        visible={(this.props.errors.roles_id) ? true : false}
                                        header={'Error'}
                                        content={getErrorMessage(this.props.errors, 'roles_id')}
                                    />
                                </> : null
                            }

                            <Form.TextArea
                                //fluid
                                label = 'Comments'
                                placeholder='Comments here'
                                onChange={this.onCommentChange}
                                error={(this.props.errors.comment) ? true : false}
                                defaultValue={this.props.user.comment}
                            />

                            {isLobsterUser ?
                                <>
                                    <h3>Lockdown</h3>
                                    <p>If the account is in lockdown mode, the user will not be able to log in.</p>
                                    <Checkbox
                                        label={this.state.lockdown === 'ON' ? 'Enabled' : 'Disabled'}
                                        defaultChecked={this.state.lockdown === 'ON'}
                                        onChange={this.onLockdownChange}
                                        data-testid='create-user--lockdown'
                                        toggle />
                                </> : null
                            }
                        </Grid.Column>
                        <Grid.Column>

                            <h3>Overrides</h3>
                            <p>
                                Time delay (in hours)
                                <Popup trigger={<Icon name="question circle outline" style={{marginLeft: '5px'}} />}>
                                    <p>Apply a delay to users image feed e.g. a 6 hour delay will restrict access to a 9am image until 3pm</p>
                                </Popup>
                            </p>
                            <Form.Input
                                // fluid
                                type="number"
                                placeholder='Time delay'
                                onChange={this.onDelayChange}
                                error={(this.props.errors.delay) ? true : false}
                                defaultValue={this.state.delay}
                                min="0"
                            />
                            <Message
                                error={true}
                                visible={(this.props.errors.delay) ? true : false}
                                header={'Error'}
                                content={getErrorMessage(this.props.errors, 'delay')}
                            />

                            <p>
                                Limit access to historical images (in days)
                                <Popup trigger={<Icon name="question circle outline" style={{marginLeft: '5px'}} />}>
                                    <p>Restricts user from viewing images older than the specified limit</p>
                                </Popup>
                            </p>
                            <Form.Input
                                type="number"
                                placeholder='Limit access (in days)'
                                onChange={this.onLimitHistoryChange}
                                error={(this.props.errors.limit_history) ? true : false}
                                defaultValue={this.state.limit_history}
                                min="0"
                            />
                            <Message
                                error={true}
                                visible={(this.props.errors.limit_history) ? true : false}
                                header={'Error'}
                                content={getErrorMessage(this.props.errors, 'limit_history')}
                            />
                            {isLobsterUser ?
                                <>
                                    <h3>Kiosk Mode</h3>
                                    <p>Disables logout and the downloading of images and videos</p>
                                    <Checkbox
                                        label={this.props.user.settings.kiosk_mode_enabled ? 'Enabled' :'Disabled'}
                                        defaultChecked={this.props.user.settings.kiosk_mode_enabled}
                                        onChange={this.onKioskModeEnabledChange}
                                        toggle />

                                    <h3>Processing</h3>
                                    <p>Show the processed version of images to this user</p>
                                    <Checkbox
                                        label={this.state.processed ? 'Enabled' : 'Disabled'}
                                        defaultChecked={this.state.processed}
                                        onChange={this.onProcessedChange}
                                        toggle />

                                    <h3>Weather</h3>
                                    <Checkbox
                                        label={this.state.settings.weather.enabled ? 'Enabled (Click to disable weather settings)' : 'Disabled (Click to enable weather settings)'}
                                        defaultChecked={this.state.settings.weather.enabled}
                                        onChange={this.onWeatherSettingsChange}
                                        toggle />
                                        <p></p>
                                    <Grid columns='2' stackable>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <TemperatureUnit
                                                    defaultChecked={this.state.settings.weather.temperature_unit}
                                                    disabled={!this.state.settings.weather.enabled}
                                                    onChange={this.onTemperatureUnitChange}
                                                />
                                            </Grid.Column>
                                            <Grid.Column>
                                                <WindSpeedUnit
                                                    defaultChecked={this.state.settings.weather.wind_speed_unit}
                                                    disabled={!this.state.settings.weather.enabled}
                                                    onChange={this.onWindSpeedUnitChange}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </> : null
                            }

                            <h3>Permissions</h3>
                            <Grid columns='2' stackable>
                                <Grid.Row>
                                    <Grid.Column>
                                        {isLobsterUser ?
                                            <>
                                                <p className="permissions__title">Show Navigation</p>
                                                <Checkbox
                                                    label={this.state.settings.show_navigation_permission === 'Yes' ? 'Enabled' : 'Disabled'}
                                                    defaultChecked={this.state.settings.show_navigation_permission === 'Yes'}
                                                    onChange={this.onShowNavigationPermissionChange}
                                                    toggle />
                                                <p className="permissions__title">Show Calendar</p>
                                                <Checkbox
                                                    label={this.state.settings.permitted_to_view_calendar === true ? 'Enabled' : 'Disabled'}
                                                    defaultChecked={this.state.settings.permitted_to_view_calendar === true}
                                                    onChange={this.onShowCalendarPermissionChange}
                                                    toggle />
                                            </> : null
                                        }
                                        <p className="permissions__title">
                                            Image Download
                                            <Popup trigger={<Icon name="question circle outline" style={{marginLeft: '5px'}} />}>
                                                <p>Allow user to download images via a Download button</p>
                                            </Popup>
                                        </p>
                                        <Checkbox
                                            label={this.state.settings.permitted_to_save ? 'Enabled' : 'Disabled'}
                                            checked={this.state.settings.permitted_to_save}
                                            onChange={this.onAllowSavingPermissionChange}
                                            toggle />
                                        {isLobsterUser ?
                                            <>
                                                <p className="permissions__title">Allow Zooming</p>
                                                <Checkbox
                                                    label={this.state.settings.permitted_to_zoom ? 'Enabled' : 'Disabled'}
                                                    defaultChecked={this.state.settings.permitted_to_zoom}
                                                    onChange={this.onAllowZoomingPermissionChange}
                                                    toggle />

                                                <p className="permissions__title">Show Thumbnails</p>
                                                <Checkbox
                                                    label={this.state.settings.show_thumbnails_permission === 'Yes' ? 'Enabled' : 'Disabled'}
                                                    defaultChecked={this.state.settings.show_thumbnails_permission === 'Yes'}
                                                    onChange={this.onShowThumbnailsPermissionChange}
                                                    toggle />
                                            </> : null
                                        }
                                    </Grid.Column>
                                    <Grid.Column>
                                        {isLobsterUser ?
                                            <>
                                                <p className="permissions__title">Enable Auto Refresh</p>
                                                <Checkbox
                                                    label={this.state.settings.permitted_to_auto_refresh ? 'Enabled' : 'Disabled'}
                                                    defaultChecked={this.state.settings.permitted_to_auto_refresh}
                                                    onChange={this.onAutoRefreshPermissionChange}
                                                    toggle />

                                                <p className="permissions__title">Timelapse Video</p>
                                                <Checkbox
                                                    label={this.state.settings.permitted_to_view_timelapse ? 'Enabled' : 'Disabled'}
                                                    defaultChecked={this.state.settings.permitted_to_view_timelapse}
                                                    onChange={this.onViewTimeLapsePermissionChange}
                                                    toggle />

                                                <p className="permissions__title">Hide Virtual Cameras</p>
                                                <Checkbox
                                                    label={this.state.settings.hide_virtual_cameras ? 'Enabled' : 'Disabled'}
                                                    defaultChecked={this.state.settings.hide_virtual_cameras}
                                                    onChange={this.onHideVirtualCamerasChange}
                                                    toggle />
                                            </> : null
                                        }

                                        <p className="permissions__title">
                                            Make A Film
                                            <Popup trigger={<Icon name="question circle outline" style={{marginLeft: '5px'}} />}>
                                                <p>Allow user to create and download timelaspe film</p>
                                            </Popup>
                                        </p>

                                        <Checkbox
                                            label={this.props.user.video_editor ? 'Enabled' : 'Disabled'}
                                            checked={this.state.video_editor}
                                            onChange={this.onAllowVideoEditingPermissionChange}
                                            toggle />

                                        
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            {isLobsterUser ?
                                <>
                                    <ShowBimModelType
                                        defaultChecked={this.state.settings.show_bim_model_type}
                                        onChange={this.onBimContractDeliveryChange}
                                    />

                                    <h3>Public API</h3>
                                    <p>Allow API access</p>
                                    <Checkbox
                                        label={this.state.api ? 'Enabled' : 'Disabled'}
                                        defaultChecked={this.state.api}
                                        onChange={this.onApiChange}
                                        toggle />
                                </> : null
                            }
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>)
    }

    getPanes = () => {
        return [
            { menuItem: { key: 'settings', icon: 'setting', content: 'User settings'}, render: () => <Tab.Pane>

                {this.getContent(
                    this.props.error,
                    this.props.success,
                    this.props.loading,
                    this.props.errors
                )}

            </Tab.Pane> },
            { menuItem: { key: 'camera', icon: 'camera', content: 'Projects and cameras associated with user'}, render: () => {

                return (<Tab.Pane>

                    <UserProjectsAndCamerasSelector
                        auth={this.props.auth}
                        loading={this.props.ucpa_loading}
                        loadingProjectCameras={this.props.cameras.loading}
                        loadingProjectCamerasProjectId={this.props.cameras.projects_id}
                        cameras={this.props.cameras.cameras}
                        user_cameras={this.props.user_cameras.cameras}
                        user_project_cameras={
                            (() => {
                                let items = this.props.user_projects.projects.slice()

                                let currentProject = null;
                                // If adding a user via Project Access automatically add the project to the left column
                                if (this.props.fromProject && this.props.fromProjectId) {
                                    currentProject = this.props.projects.projects.filter((elem, idx) => {
                                        if (this.props.fromProjectId === elem.id) {
                                            return elem
                                        }

                                        return false
                                    })

                                    items = items.concat(currentProject)
                                }

                                items = items.concat(this.props.add_user_projects)
                                items = items.filter((elem) => {
                                    return this.props.remove_user_projects.findIndex((e) => {
                                        return e.id === elem.id
                                    }) === -1
                                })

                                let cams = this.props.user_cameras.cameras.slice()

                                cams = cams.concat(this.props.add_user_cameras)
                                cams = cams.filter((elem) => {
                                    return this.props.remove_user_cameras.findIndex((e) => {
                                        return e.id === elem.id
                                    }) === -1
                                })

                                items = items.concat(cams)
                                items = items.map((elem, idx) => {
                                    return Object.assign(
                                        {},
                                        elem,
                                        {
                                            key: elem.id,
                                            text: elem.pot_id || elem.name
                                        }
                                    )
                                })

                                // Okay, this is sorting essentially by anything available...
                                items = items.sort((a, b) => {

                                    if ('weight' in a && 'weight' in b) {
                                        if (a.weight < b.weight) {
                                            return -1
                                        }
                                        if (a.weight > b.weight) {
                                            return 1
                                        }
                                        return 0
                                    } else if ('weight' in a && 'default_weight' in b) {
                                        if (a.weight < b.default_weight) {
                                            return -1
                                        }
                                        if (a.weight > b.default_weight) {
                                            return 1
                                        }
                                        return 0
                                    } else if ('default_weight' in a && 'weight' in b) {
                                        if (a.default_weight < b.weight) {
                                            return -1
                                        }
                                        if (a.default_weight > b.weight) {
                                            return 1
                                        }
                                        return 0
                                    } else {
                                        if ('default_weight' in a < 'default_weight' in b) {
                                            return -1
                                        }
                                        if (a.default_weight > b.default_weight) {
                                            return 1
                                        }
                                        return 0
                                    }

                                })

                                if (this.props.project_camera_weights && this.props.project_camera_weights.length > 0) {
                                    // If here, this sorting should ultimately take priority,
                                    // as it reflects the very latest local changes
                                    items = items.sort((a, b) => {

                                        const aWeighting = this.props.project_camera_weights.find((i) => {
                                            if ('pot_id' in a || 'pot_id' in i) {
                                                if ('pot_id' in i && 'pot_id' in a) {
                                                    return a.id === i.id
                                                } else {
                                                    return false
                                                }
                                            } else {
                                                return a.id === i.id
                                            }
                                        })

                                        const bWeighting = this.props.project_camera_weights.find((i) => {
                                            if ('pot_id' in b || 'pot_id' in i) {
                                                if ('pot_id' in i && 'pot_id' in b) {
                                                    return b.id === i.id
                                                } else {
                                                    return false
                                                }
                                            } else {
                                                return b.id === i.id
                                            }
                                        })

                                        if (aWeighting && bWeighting) {
                                            // Should always be the case
                                            if (aWeighting.weight < bWeighting.weight) {
                                                return -1
                                            }
                                            if (aWeighting.weight > bWeighting.weight) {
                                                return 1
                                            }
                                            return 0

                                        } else {
                                            return -1
                                        }

                                    })

                                }
                                return items

                            })()
                        }

                        onLoadProjectCameras={(project) => {
                            const clientId = this.state.clients_id
                            this.props.onLoadProjectCameras(clientId, project)
                        }}
                        onResetProjectCameras = {this.props.onResetProjectCameras}
                        onAddUserCameras={(cameras) => {

                            // Okay, so we only want to add cameras not already previously linked to user
                            const camerasFiltered = cameras.filter((elem) => {
                                return this.props.user_cameras.cameras.findIndex((e) => {
                                    return elem.id === e.id
                                }) === -1
                            })

                            // We also only want to add a camera not already added...
                            const camerasFilteredUnique = camerasFiltered.filter((camera) => {
                                return (this.props.add_user_cameras.findIndex((elem) => {
                                    return elem.id === camera.id
                                }) === -1)
                            })

                            // Let's add the latest array to the existing one
                            const addUserCameras = [...this.props.add_user_cameras, ...camerasFilteredUnique]
                            this.props.onAddUserCameras(addUserCameras)

                            // Let's also tidy up the remove cameras array, if necessary
                            let removeUserCameras = this.props.remove_user_cameras.slice()
                            let changed = false

                            cameras.forEach((camera) => {
                                const key =
                                    removeUserCameras.findIndex((elem) => {
                                        return elem.id === camera.id
                                    })

                                if (key !== -1) {
                                    changed = true
                                    // We can tidy up a little...
                                    removeUserCameras.splice(key, 1)
                                }
                            })

                            if (changed) {
                                this.props.onRemoveCameras(removeUserCameras)
                            }
                        }}

                        onAddProject={(project) => {
                            if (this.props.add_user_projects.findIndex((elem) => {
                                    return elem.id === project.id
                                }) === -1) {

                                // If project is already is linked to user, but now marked for removal
                                // we don't really want to add it here
                                if (this.props.user_projects.projects.findIndex((elem) => {
                                        return elem.id === project.id
                                    }) === -1) {
                                    // Avoids
                                    this.props.onAddUserProjects([...this.props.add_user_projects, project])
                                }
                            }

                            // If we're adding a project, let's clean up the remove projects just in case
                            // Also necessary for any project previously marked for removal
                            const key = this.props.remove_user_projects.findIndex((elem) => {
                                return elem.id === project.id
                            })
                            if (key !== -1) {
                                let projs = this.props.remove_user_projects.slice()
                                projs.splice(key, 1)
                                this.props.onRemoveProjects(projs)
                            }
                        }}

                        onRemoveProject={(project) => {
                            if (this.props.remove_user_projects.findIndex((elem) => {
                                    return elem.id === project.id
                                }) === -1) {

                                // So, we only need to add items that are actually already linked to user
                                if (this.props.user_projects.projects.findIndex((elem) => {
                                        return elem.id === project.id
                                    }) !== -1) {

                                    this.props.onRemoveProjects([...this.props.remove_user_projects, project])

                                }
                            }

                            // Let's also update the add_user_projects too, if necessary
                            const key = this.props.add_user_projects.findIndex((elem) => {
                                return elem.id === project.id
                            })
                            if (key !== -1) {
                                let projs = this.props.add_user_projects.slice()
                                projs.splice(key, 1)
                                this.props.onAddUserProjects(projs)
                            }
                        }}

                        onRemoveCamera={(camera) => {
                            if (this.props.remove_user_cameras.findIndex((elem) => {
                                    return elem.id === camera.id
                                }) === -1) {

                                // We only need to add here if the already linked to user..
                                if (this.props.user_cameras.cameras.findIndex((elem) => {
                                        return elem.id === camera.id
                                    }) !== -1) {

                                    this.props.onRemoveCameras([...this.props.remove_user_cameras, camera])
                                }
                            }

                            // Let's also update add_user_cameras too, if necessary
                            const key = (this.props.add_user_cameras.findIndex((elem) => {
                                return elem.id === camera.id
                            }))
                            if (key !== -1) {
                                let cams = this.props.add_user_cameras.slice()
                                cams.splice(key, 1)
                                this.props.onAddUserCameras(cams)
                            }

                        }}

                        onUpdateProjectCameraWeights={this.props.onUpdateProjectCameraWeights}
                        projects={
                            (() => {
                                let p = this.props.projects.projects.map((elem, idx) => {
                                    return Object.assign(
                                        {},
                                        elem,
                                        {
                                            key: idx,
                                            text: elem.name
                                        }
                                    )
                                })

                                p = p.filter((elem) => {
                                    const notAddedToUserProjects = this.props.add_user_projects.findIndex((e) => {
                                        return elem.id === e.id
                                    }) === -1

                                    const notMarkedForRemoval = this.props.remove_user_projects.findIndex((e) => {
                                        return elem.id === e.id
                                    }) === -1

                                    const notExistingUserProject = this.props.user_projects.projects.findIndex((e) => {
                                        return elem.id === e.id
                                    }) === -1

                                    // If adding a user via Project Access then don't show this project in list to add
                                    let notCurrentProject =  (this.props.fromProject && this.props.fromProjectId)?
                                        elem.id !== this.props.fromProjectId : true
    

                                    if (notAddedToUserProjects && notExistingUserProject && notCurrentProject) {
                                        return true
                                    } else if (!notExistingUserProject && !notMarkedForRemoval) {
                                        return true
                                    } else {
                                        return false
                                    }

                                })

                                return p

                            })()
                        }
                    />
                    <br/>
                </Tab.Pane>) }}
        ]

    }

}

CreateUser.propTypes = {
    loading: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    roles: PropTypes.array.isRequired,
    statuses: PropTypes.array.isRequired,
    clients: PropTypes.array.isRequired,
    details: PropTypes.object.isRequired,
    ucpa_completed: PropTypes.bool.isRequired,
    user: PropTypes.shape({
        username: PropTypes.string.isRequired,
        job_title: PropTypes.string.isRequired,
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        roles_id: PropTypes.any.isRequired,
        status: PropTypes.string.isRequired,
        clients_id: PropTypes.any.isRequired,
        lockdown: PropTypes.string.isRequired,
        processed: PropTypes.bool,
        limit_history: PropTypes.number.isRequired,
        delay: PropTypes.number.isRequired,
        settings: PropTypes.shape({
            permitted_to_save: PropTypes.bool.isRequired,
            permitted_to_zoom: PropTypes.bool.isRequired,
            permitted_to_view_calendar: PropTypes.bool.isRequired,
            show_navigation_permission: PropTypes.string.isRequired,
            show_thumbnails_permission: PropTypes.string.isRequired,
            permitted_to_view_timelapse: PropTypes.bool.isRequired,
            permitted_to_auto_refresh: PropTypes.bool.isRequired,
            weather: PropTypes.shape({
                enabled: PropTypes.bool.isRequired,
                temperature_unit: PropTypes.string.isRequired,
                wind_speed_unit: PropTypes.string.isRequired,
            }),
        }),
    }),
    projects: PropTypes.shape({
        loading: PropTypes.bool.isRequired,
        projects: PropTypes.array.isRequired
    }),
    cameras: PropTypes.shape({
        loading: PropTypes.bool.isRequired,
        cameras: PropTypes.array.isRequired
    }),
    user_cameras: PropTypes.shape({
        cameras: PropTypes.array.isRequired,
        loading: PropTypes.bool.isRequired,
        success: PropTypes.bool.isRequired,
        error: PropTypes.bool.isRequired,
        errors: PropTypes.object.isRequired
    }),
    user_projects: PropTypes.shape({
        projects: PropTypes.array.isRequired,
        loading: PropTypes.bool.isRequired,
        success: PropTypes.bool.isRequired,
        error: PropTypes.bool.isRequired,
        errors: PropTypes.object.isRequired
    })
}

export default CreateUser
