
import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as authActions from '../actions/authActions'
import * as subscriptionActions from '../actions/subscriptionsActions'
import * as camerasActions from '../actions/camerasActions'
import { checkAuth } from '../util/accessToken'
import { Container, Grid, Button, Loader } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import Subscription from '../components/Subscription'
import AddSubscriptionModal from '../components/modals/AddSubscription'
import RemoveSubscriptionModal from '../components/modals/RemoveSubscription'
import BuyCameraModal from '../components/modals/BuyCamera'


const SubscriptionsContainer = (props) => {
    const [modalOpen, setModalOpen] = useState(false)
    const [subscribedCameras, setSubscribedCameras] = useState([])
    const [showButtons, setShowButtons] = useState(false)
    const [showSaveLoader, setShowSaveLoader] = useState(false)
    const [subscriptionsToBeRemoved, setSubscriptionsToBeRemoved] = useState([])
    const [subscriptionsToBeAdded, setSubscriptionsToBeAdded] = useState([])
    const [activeSubscriptions, setActiveSubscriptions] = useState([])

    useEffect(() => {
        // On initial load check user authentication then fetch required data
        checkAuth(props.auth, props.authActions, props.history)
        props.subscriptionActions.fetchSubscriptions()
        props.camerasActions.fetchGridViewCameras(1)
        props.subscriptionActions.fetchCustomerPortalLink()
    }, [])

    useEffect(() => {
        let data = []
        for (let camera of props.cameras.grid_view.cameras) {
            if (camera.subscription_status && camera.subscription_status !== 'unsubscribed' ) {
                data.push(camera)
            }
        }   

        setSubscribedCameras(data)
    }, [props.cameras.grid_view])

    const onAddSubscriptionClick = () => {
        setModalOpen('add')
    }

    const onRemoveSubscriptionClick = () => {
        setModalOpen('remove')
    }

    const onAddCameraClick = () => {
        props.subscriptionActions.fetchPaywallConfig()
        setModalOpen('buy')
    }

    const selectLoaded = (id) => {
        // Once the select loads we need to keep track of the active values
        setActiveSubscriptions((prevState) => {
            return [
                ...prevState,
                id,
            ]
        })
    }

    const selectChanged = (removed, added, previous) => {
        if (removed && subscriptionsToBeRemoved.indexOf(removed) === -1) {
            setShowButtons(true)
            setSubscriptionsToBeRemoved((prevState) => {
                return [
                    ...prevState,
                    removed
                ]
            })
        }

        if (removed && activeSubscriptions.indexOf(removed) !== -1) {
            setShowButtons(true)
            setActiveSubscriptions((prevState) => {
                let prevIndex = prevState.indexOf(removed)
                    if (prevIndex > -1) {
                        prevState.splice(prevIndex, 1) 
                    }
                return [
                    ...prevState
                ]
            })
        }

        if (removed && subscriptionsToBeAdded.indexOf(removed) !== -1) {
            setShowButtons(true)
            setSubscriptionsToBeAdded((prevState) => {
                let prevIndex = prevState.indexOf(removed)
                if (prevIndex > -1) {
                    prevState.splice(prevIndex, 1) 
                }

                return [
                    ...prevState
                ]
            })
        }
        
        if (added && subscriptionsToBeAdded.indexOf(added) === -1) {
            setShowButtons(true)
            setSubscriptionsToBeAdded((prevState) => {
                let prevIndex = prevState.indexOf(previous)
                if (prevIndex > -1) {
                    prevState.splice(prevIndex, 1) 
                }

                return [
                    ...prevState,
                    added
                ]
            })
        }
    }

    const subscriptionBlocks = () => {
        if (!props.subscriptions.data.length) {
            return  <div className="animated-blank-block-200"></div>
        } else {
            let content = []
            for (let index = 0; index < parseInt(props.subscriptions.total); index++) {
                content.push(
                    <Grid.Column mobile={16} tablet={16} computer={8} key={`sub-${index}`}>
                        <Subscription
                            index={index}
                            options={props.cameras.grid_view.cameras}
                            selectedCamera={subscribedCameras[index]}
                            selectChanged={selectChanged}
                            disabledOptions={subscriptionsToBeAdded}
                            activeOptions={activeSubscriptions}
                            loaded={selectLoaded}
                        />
                    </Grid.Column>
                )
            }

            return (
                <Grid className="styled-grid styled-grid--subscriptions">{content}</Grid>
            )
        }
    }

    const subscriptionPrice = (props.subscriptions && props.subscriptions.data && props.subscriptions.data[0]) ?
        `£${(parseInt(props.subscriptions.data[0].amount)/100).toFixed(2)}` : null

    const subscriptionsInfoContent = () => {
        if (!props.subscriptions.data.length && !props.subscriptions.loading) {
            return (
                <Grid.Column>
                    <div className="card--subscriptions-header">
                        <Loader active inline='centered' />
                    </div>
                </Grid.Column>
            )
        } else {
            let index = 0;
            for (const subscription of props.subscriptions.data) {
                const subscriptionType = (subscription.interval === 'month') ? 'Monthly' : subscription.interval
                const startDate = new Date(subscription.start_date).toLocaleDateString('en-gb');
                const nextPaymentDate = new Date(subscription.current_period_end).toLocaleDateString('en-gb');
                const link = (props.subscriptions.portalLink)?
                    <p><a href={props.subscriptions.portalLink} className='ui primary button' target='_blank'>More Info</a></p>
                : <Loader active inline='centered' />

                return (<Grid.Column key={`header-${index}`}>
                    <div className='card--subscriptions-header'>
                        <p><span>Subscription Type:</span> {subscriptionType}</p>
                        <p><span>Start Date:</span> {startDate}</p>
                        <p><span>Next Payment:</span> {nextPaymentDate}</p>
                        <p><span>Price:</span> {subscriptionPrice}</p>
                        <p><span>Total:</span> {subscription.quantity}</p>
                        {link}
                        <div>
                            {props.cameras.grid_view.cameras && (subscription.quantity < props.cameras.grid_view.cameras.length) && <Button positive onClick={onAddSubscriptionClick}>Add<br /> Subscripton</Button>}
                            {<Button negative onClick={onRemoveSubscriptionClick}>Remove<br /> Subscription</Button>}
                        </div>
                    </div>
                </Grid.Column>)
            }

            index++
        }
    }

    const reset = () => {
        props.subscriptionActions.reset()
        props.camerasActions.resetGridView()
        setShowButtons(false)
        setSubscriptionsToBeRemoved([])
        setSubscriptionsToBeAdded([])
        props.subscriptionActions.fetchSubscriptions()
        props.subscriptionActions.fetchCustomerPortalLink()
        props.camerasActions.fetchGridViewCameras(1)
        
    }

    const closeModal = () => {
        setModalOpen(false)
        reset()
    }

    const onSubscriptionsSave = () => {
        setShowSaveLoader(true)
        const removeSubscriptions = subscriptionsToBeRemoved.map(subscription => {
            return new Promise((resolve, reject) => {
                props.subscriptionActions.unsubscribeCamera(subscription)
                resolve()
            })
        });

        const addSubscriptions = subscriptionsToBeAdded.map(subscription => {
            return new Promise((resolve, reject) => {
                props.subscriptionActions.subscribeCamera(subscription)
                resolve()
            })
        });

        function fetchCameras() {
            return new Promise((resolve, reject) => {
                props.camerasActions.fetchGridViewCameras(1)
                resolve()
            })
        };

        function fetchSubscriptions() {
            return new Promise((resolve, reject) => {
                props.subscriptionActions.fetchSubscriptions()
                resolve()
            })
        };

        // The functionality below relies on a 1s timeout
        // This is due to fetching cameras after the promise resolves shows old data
        // Potentilly doesn't allow the DB the time to update the cameras table
        Promise.all([...removeSubscriptions]).then(() => {
            Promise.all([...addSubscriptions]).then(() => {
                return new Promise((resolve, reject) => {
                    return props.camerasActions.resetGridView().then(() => {
                        return new Promise((resolve, reject) => {
                            props.subscriptionActions.reset()
                            resolve()
                        }).then(() => {
                            return new Promise(function(resolve) { 
                                setTimeout(() => {
                                    resolve()
                                  }, "1000")
                            }).then(() => {
                                return fetchCameras().then(() => {
                                    props.subscriptionActions.fetchCustomerPortalLink()
                                    return fetchSubscriptions()
                                })
                            })
                        })
                    })
                })
                // A page refresh would have the same effect as above
                // return new Promise(function(resolve) { 
                    // setTimeout(() => {
                    //     resolve()
                    //   }, "1000").then(() => {
                //     window.location.reload(false);
                // })
            })
        })

        setShowSaveLoader(false)
        setShowButtons(false)
    }

    return (
        <Container className="content-container" fluid={true} style={{padding: '0 1.2em'}}>
            <Grid className="">
                {subscriptionsInfoContent()}
            </Grid>

            <div className='subscriptions__title-wrap'>
                <h3 className="subscriptions__title">Subscriptions Details</h3>
                <div className='subscriptions__buttons' style={{textAlign: 'right'}}>
                    {showButtons && <>
                        <Button primary content='Refresh' icon='refresh' labelPosition='left' onClick={reset}/>
                        <Button positive content='Save' style={{marginRight: '2rem'}} icon='save' labelPosition='left' onClick={onSubscriptionsSave}/>
                    </>}
                    <Button className='subscriptions__title-link ui primary button' onClick={onAddCameraClick}>Buy a new camera</Button>
                </div>
                
            </div>
            {subscriptionBlocks()}

            {props.cameras.grid_view.cameras && props.subscriptions.data[0] && (props.subscriptions.data[0].quantity < 2) &&
                <div style={{textAlign: 'center', fontSize: '1.3rem'}}>
                    <a href="https://lobsterpictures.tv/contact" target="_blank" rel="noreferrer" style={{color: '#B5B5B5'}}>Contact us to cancel your Subscription</a>
                </div>
            }
            
            {showSaveLoader && <Loader active inline />}
            {props.subscriptions && <AddSubscriptionModal
                onClose={() => setModalOpen(false)}
                onSuccessClose={closeModal}
                onSubmit={props.subscriptionActions.addSubscription}
                open={modalOpen === 'add'}
                loading={props.subscriptions.loading}
                success={props.subscriptions.addingSubscriptionSuccess}
                error={props.subscriptions.error}
                cost={subscriptionPrice}
            />}
             {props.subscriptions && <RemoveSubscriptionModal
                onClose={() => setModalOpen(false)}
                onSuccessClose={closeModal}
                onSubmit={props.subscriptionActions.removeSubscription}
                open={modalOpen === 'remove'}
                loading={props.subscriptions.loading}
                success={props.subscriptions.removeSubscriptionSuccess}
            />}
            {props.subscriptions && <BuyCameraModal
                onClose={() => {setModalOpen(false)}}
                onSuccessClose={closeModal}
                onSubmit={props.subscriptionActions.buyCamera}
                open={modalOpen === 'buy'}
                prices={props.subscriptions.prices}
                loading={props.subscriptions.newCameraLoading}
                success={props.subscriptions.newCameraSuccess}
            />}
        </Container>
    )
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        subscriptions: state.subscriptions,
        cameras: state.cameras
    }
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(authActions, dispatch),
        subscriptionActions: bindActionCreators(subscriptionActions, dispatch),
        camerasActions: bindActionCreators(camerasActions, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubscriptionsContainer)
