import React, { Component } from 'react'
import { Table, Button, Icon, Menu, Form, Divider} from 'semantic-ui-react'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

export default class LogTable extends Component {

    constructor(props) {
        super(props)

        let filters = ['Scheduler']
        let numberPerPage = 20
        let numberOfPages = Math.ceil(this.filterLogs(props.logs, filters).length / numberPerPage);
        let nextPage, prevPage = null
        let currPage = 1
        if (numberOfPages > 1) {
            nextPage = currPage + 1
            prevPage = null
        }
        this.state = {
            currPage: currPage,
            numberPerPage: numberPerPage,
            numberOfPages: numberOfPages,
            nextPage: nextPage,
            prevPage: prevPage,
            filters: filters,
            start: null,
            end: null
        }

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props !== prevProps) {
            this.setState((prevState, props) => {

                let numberPerPage = this.state.numberPerPage
                let numberOfPages = Math.ceil(this.filterLogs(props.logs, this.state.filters).length / numberPerPage);

                let nextPage, prevPage = null
                let currPage = 1
                if (numberOfPages > 1) {
                    nextPage = currPage + 1
                    prevPage = null
                }

                return {
                    currPage: currPage,
                    numberPerPage: numberPerPage,
                    numberOfPages: numberOfPages,
                    nextPage: nextPage,
                    prevPage: prevPage,
                    filters: prevState.filters,
                    start: null,
                    end: null
                }

            })
        }
    }

    onChangeStart = (start) => {

        if (start) {

            const dateRange = this.getDateRange({ start: start })
            if (!dateRange) {
                // Any changing state required?
                this.setState({ start: start })
            } else {

                this.setState((prevState, props) => {

                    // We might have more or fewer results...
                    let numberOfPages = Math.ceil(this.filterLogs(this.props.logs, this.state.filters, dateRange).length / this.state.numberPerPage);

                    let nextPage, prevPage = null
                    let currPage = 1
                    if (numberOfPages > 1) {
                        nextPage = currPage + 1
                        prevPage = null
                    }

                    return {
                        currPage: currPage,
                        numberPerPage: this.state.numberPerPage,
                        numberOfPages: numberOfPages,
                        nextPage: nextPage,
                        prevPage: prevPage,
                        filters: prevState.filters,
                        start: start,
                        end: this.state.end
                    }
                })
            }

        } else {
            this.setState((prevState, props) => {

                // We might have more or fewer results...
                let numberOfPages = Math.ceil(this.filterLogs(this.props.logs, this.state.filters).length / this.state.numberPerPage);

                let nextPage, prevPage = null
                let currPage = 1
                if (numberOfPages > 1) {
                    nextPage = currPage + 1
                    prevPage = null
                }

                return {
                    currPage: currPage,
                    numberPerPage: this.state.numberPerPage,
                    numberOfPages: numberOfPages,
                    nextPage: nextPage,
                    prevPage: prevPage,
                    filters: prevState.filters,
                    start: null,
                    end: this.state.end
                }
            })
        }
    }

    onChangeEnd = (end) => {

        if (end) {

            const dateRange = this.getDateRange({ end: end })
            if (!dateRange) {
                // Any changing state required?
                this.setState({ end: end })
            } else {

                this.setState((prevState, props) => {

                    // We might have more or fewer results...
                    let numberOfPages = Math.ceil(this.filterLogs(this.props.logs, this.state.filters, dateRange).length / this.state.numberPerPage);

                    let nextPage, prevPage = null
                    let currPage = 1
                    if (numberOfPages > 1) {
                        nextPage = currPage + 1
                        prevPage = null
                    }

                    return {
                        currPage: currPage,
                        numberPerPage: this.state.numberPerPage,
                        numberOfPages: numberOfPages,
                        nextPage: nextPage,
                        prevPage: prevPage,
                        filters: prevState.filters,
                        start: this.state.start,
                        end: end
                    }

                })

            }

        } else {

            this.setState((prevState, props) => {

                // We might have more or fewer results...
                let numberOfPages = Math.ceil(this.filterLogs(this.props.logs, this.state.filters).length / this.state.numberPerPage);

                let nextPage, prevPage = null
                let currPage = 1
                if (numberOfPages > 1) {
                    nextPage = currPage + 1
                    prevPage = null
                }

                return {
                    currPage: currPage,
                    numberPerPage: this.state.numberPerPage,
                    numberOfPages: numberOfPages,
                    nextPage: nextPage,
                    prevPage: prevPage,
                    filters: prevState.filters,
                    start: this.state.start,
                    end: null
                }

            })

        }
    }

    nextPage = () => {

        this.setState((prevState, props) => {

            let numberPerPage = prevState.numberPerPage
            let numberOfPages = Math.ceil(this.filterLogs(props.logs, prevState.filters, this.getDateRange()).length / numberPerPage);
            let nextPage, prevPage = null
            let currPage = prevState.currPage + 1

            if (numberOfPages > 1 && currPage < numberOfPages) {
                nextPage = currPage + 1
                if (currPage > 1) {
                    prevPage = currPage - 1

                } else {
                    prevPage = null
                }
            } else if (numberOfPages > 1 && currPage === numberOfPages) {
                nextPage = null
                prevPage = currPage - 1
            }

            // Sanity check and fix
            if (currPage > numberOfPages) {
                currPage = numberOfPages
                nextPage = null
                prevPage = (numberOfPages > 1) ? currPage - 1 : null
            }

            return {
                currPage: currPage,
                numberPerPage: numberPerPage,
                numberOfPages: numberOfPages,
                nextPage: nextPage,
                prevPage: prevPage,
                filters: prevState.filters,
                start: prevState.start,
                end: prevState.end
            }
        })


    }

    prevPage = () => {

        this.setState((prevState, props) => {

            let numberPerPage = prevState.numberPerPage
            let numberOfPages = Math.ceil(this.filterLogs(props.logs, prevState.filters, this.getDateRange()).length / numberPerPage);
            let nextPage, prevPage = null
            let currPage = prevState.currPage - 1

            if (numberOfPages > 1 && currPage < numberOfPages) {
                nextPage = currPage + 1
                if (currPage > 1) {
                    prevPage = currPage - 1

                } else {
                    prevPage = null
                }
            } else if (numberOfPages > 1 && currPage === numberOfPages) {
                nextPage = null
                prevPage = currPage - 1
            }

            // Sanity check and fix
            if (currPage < 1) {
                currPage = 1
                nextPage = (numberOfPages > 1) ? currPage + 1 : null
                prevPage =  null
            }

            return {
                currPage: currPage,
                numberPerPage: numberPerPage,
                numberOfPages: numberOfPages,
                nextPage: nextPage,
                prevPage: prevPage,
                filters: prevState.filters,
                start: prevState.start,
                end: prevState.end
            }
        })


    }

    jumpToPage = (page) => {

        this.setState((prevState, props) => {

            let numberPerPage = prevState.numberPerPage
            let numberOfPages = Math.ceil(this.filterLogs(props.logs, prevState.filters, this.getDateRange()).length / numberPerPage);
            let nextPage, prevPage = null
            let currPage = page

            if (numberOfPages > 1 && currPage < numberOfPages) {
                nextPage = currPage + 1
                if (currPage > 1) {
                    prevPage = currPage - 1
                } else {
                    prevPage = null
                }
            } else if (numberOfPages > 1 && currPage === numberOfPages) {
                nextPage = null
                prevPage = currPage - 1
            }

            return {
                currPage: currPage,
                numberPerPage: numberPerPage,
                numberOfPages: numberOfPages,
                nextPage: nextPage,
                prevPage: prevPage,
                filters: prevState.filters,
                start: prevState.start,
                end: prevState.end
            }
        })

    }

    getDateRange = (dateRange) => {
        let start, end = null
        if (dateRange && dateRange.start) {
            start = moment(dateRange.start)
        } else if (this.state.start) {
            start = moment(this.state.start)
        }

        if (dateRange && dateRange.end) {
            end = moment(dateRange.end)
        } else if (this.state.end) {
            end = moment(this.state.end)
        }

        if (start && end) {
            return { start: start, end: end }
        }
        return null
    }

    filterLogs = (logs, filters, dateRange = null) => {

        let filtered = logs.filter((item) => {
            // scheduler.log-20170925 | scheduler.log | camera_control-20170925 | power.log...
            // Let's try to match against at least one of our filters...
            if (filters.length === 0) {
                return false
            }

            let matching = false;

            // We might be looking for blended results...
            filters.forEach((filter) => {
                if (filter === 'Power') {

                    // Just filtering out the latest...
                    if (item === 'power.log') {
                        return false
                    }

                    if (item.includes('power')) {
                        matching = true
                    }
                } else if (filter === 'Camera Control') {

                    // Just filtering out the latest...
                    if (item === 'camera_control.log') {
                        return false
                    }

                    if (item.includes('camera_control')) {
                        matching = true
                    }
                } else if (filter === 'Scheduler') {

                    // Just filtering out the latest...
                    if (item === 'scheduler.log') {
                        return false
                    }

                    if (item.includes('scheduler')) {
                        matching = true
                    }
                }
            })

            return matching
        })

        if (dateRange) {

            filtered = filtered.filter((item) => {
                // Let's get the date from the filename first...
                const itemDate = moment(item.substr(item.indexOf("-") + 1), "YYYYMMDD")
                return (itemDate.isAfter(dateRange.start) && itemDate.isBefore(dateRange.end)) ||
                    (itemDate.isAfter(dateRange.end) && itemDate.isBefore(dateRange.start))
            })
        }

        return filtered
    }


    render = () => {

        const { currPage, numberPerPage, numberOfPages, nextPage, prevPage} = this.state

        const begin = ((currPage - 1) * numberPerPage);
        const end = begin + numberPerPage;

        const filteredLogs = this.filterLogs(this.props.logs, this.state.filters, this.getDateRange())
        const logs = filteredLogs.sort((a, b) => {

            const aDate = moment(a.substr(a.indexOf("-") + 1), "YYYYMMDD")
            const bDate = moment(b.substr(b.indexOf("-") + 1), "YYYYMMDD")

            if (aDate.isBefore(bDate)) {
                return 1
            } else if (aDate.isAfter(bDate)) {
                return -1
            } else {
                return 0
            }

        }).slice(begin, end).map((item) => {
            const timestamp = moment(item.substr(item.indexOf("-") + 1), "YYYYMMDD")
            const type = this.state.filters[0]
            return {
                log: item,
                type: type,
                date: timestamp
            }
        })

        return (
            <div className="log-table">
                <div className='log-table-filters'>
                    <h4>Filter by log type</h4>

                    <Button icon='calendar' content='Scheduler' toggle onClick={() => {

                        let filters = this.state.filters.slice()
                        let currState = (this.state.filters.findIndex((item) => { return item === 'Scheduler'}) !== -1)

                        currState = !currState

                        if (currState) {
                            // Added to restrict to one selection at a time
                            filters = []
                            filters.push("Scheduler")
                        } else {
                            // Commented out to restrict to one selection at a time
                            // const index = this.state.filters.findIndex((item) => { return item === 'Scheduler'})
                            // filters.splice(index, 1)
                        }

                        this.setState({
                            filters: filters
                        }, () => { this.jumpToPage(1) })

                    }} active={ (this.state.filters.findIndex((item) => { return item === 'Scheduler'}) !== -1) } size='tiny' />

                    <Button icon='camera' content='Camera Control' toggle onClick={() => {

                        let filters = this.state.filters.slice()
                        let currState = (this.state.filters.findIndex((item) => { return item === 'Camera Control'}) !== -1)

                        currState = !currState

                        if (currState) {
                            // Added to restrict to one selection at a time
                            filters = []
                            filters.push("Camera Control")
                        } else {
                            // Commented out to restrict to one selection at a time
                            // const index = this.state.filters.findIndex((item) => { return item === 'Camera Control'})
                            // filters.splice(index, 1)
                        }

                        this.setState({
                            filters: filters
                        }, () => { this.jumpToPage(1) })

                    }}
                            active={(this.state.filters.findIndex((item) => { return item === 'Camera Control'}) !== -1)} size='tiny' />

                    <Button  icon='plug' content='Power' toggle onClick={() => {

                        let filters = this.state.filters.slice()
                        let currState = (this.state.filters.findIndex((item) => { return item === 'Power'}) !== -1)

                        currState = !currState

                        if (currState) {
                            // Added to restrict to one selection at a time
                            filters = []
                            filters.push("Power")
                        } else {
                            // Commented out to restrict to one selection at a time
                            // const index = this.state.filters.findIndex((item) => { return item === 'Power'})
                            // filters.splice(index, 1)
                        }

                        this.setState({
                            filters: filters
                        }, () => { this.jumpToPage(1) })

                    }} active={(this.state.filters.findIndex((item) => { return item === 'Power'}) !== -1)} size='tiny' />

                    <Divider />

                    <Form size='small'>
                        <Form.Group>
                            <Form.Field>
                                <label for="start-date">Start Date</label>
                                <DatePicker
                                    placeholderText=''
                                    dateFormat="do MMM yy"
                                    selected={this.state.start}
                                    onChange={(start) => {
                                        this.onChangeStart(start)
                                    }}
                                    id="start-date"
                                />
                            </Form.Field>
                            <Form.Field>
                                <label for="end-date">End Date</label>
                                <DatePicker
                                    placeholderText=''
                                    dateFormat="do MMM yy"
                                    selected={this.state.end}
                                    onChange={(end) => {
                                        this.onChangeEnd(end)
                                    }}
                                    id="end-date"
                                />
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </div>
            <Table striped compact size='small'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>File</Table.HeaderCell>
                        <Table.HeaderCell>Date</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>

                    {
                        logs.map( (item, index) => {

                            return (
                                <Table.Row key={index}>
                                    <Table.Cell><a className='external-link' target='_blank' rel='noopener noreferrer' href={`${this.props.url}/${item.log}?access_token=${this.props.accessToken}`}>{item.log}</a></Table.Cell>
                                    <Table.Cell><a className='external-link' target='_blank' rel='noopener noreferrer' href={`${this.props.url}/${item.log}?access_token=${this.props.accessToken}`}>{item.date.format("DD/MM/YYYY")}</a></Table.Cell>
                                </Table.Row>
                            )

                        })

                    }

                </Table.Body>
                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan='3'>
                            <Menu floated='right' pagination>
                                {
                                    (prevPage) ? <Menu.Item as='a' icon onClick={() => {
                                        this.prevPage()
                                    }}>
                                        <Icon name='left chevron'/>
                                    </Menu.Item> : null
                                }
                                {
                                    Array(numberOfPages).fill(null).map( (u, i) => {
                                        return  (<Menu.Item key={i} as='a' active={currPage === (i + 1)} onClick={() => {
                                            const page = i + 1
                                            this.jumpToPage(page)
                                        }}>{i + 1}</Menu.Item>)
                                    })
                                }
                                {
                                    (nextPage) ?

                                    <Menu.Item as='a' icon onClick={() => {
                                        this.nextPage()
                                    }}>
                                        <Icon name='right chevron'/>
                                    </Menu.Item> : null
                                }
                            </Menu>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>

            </Table>
            </div>
        )
    }

}
