import React, { Component } from 'react'
import PropTypes from 'prop-types'

class SettingsOverlay extends Component {

    constructor(props) {
        super(props);

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /**
     * Set the wrapper ref
     */
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            //alert('You clicked outside of me!');
            this.props.onDismiss()
        }
    }

    render = () => {
        return (
            <div ref={this.setWrapperRef} className='settings-overlay'>
                <a href="/account">Edit your details</a>
                <hr />
                <a href="https://lobsterpictures.tv/contact" target="_blank" rel="noopener nofollow">Contact us</a>
                <hr />
                <a className='logout-link' href="/logout">Logout</a>
            </div>
        )
    }
}

SettingsOverlay.propTypes = {
    onDismiss: PropTypes.func.isRequired
}

export default SettingsOverlay
