import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Message, Loader, Popup, Icon } from 'semantic-ui-react'
import { getErrorMessage } from '../util/errors'
import { Toggle } from "react-3switch";
import "react-3switch/styles.css";

class SetupModeControl extends Component {
    constructor(props) {
        super(props)
        this.toggleOptions = {
            "0": "off",
            "1": "pending",
            "2": "on",
            "off": 0,
            "pending": 1,
            "on": 2
          }

        this.state = {
            statusRequested: false,
            toggleState: this.toggleOptions.off,
            offModalShown: false,
        }
    }

    toggleStatus = (status) => {
        switch (status) {
            case 'OffRequested':
            case 'OnRequested':
                this.setState({toggleState: 1})
                this.setState({statusRequested: true})
                break
            case 'Off':
                this.setState({toggleState: 0})
                this.setState({statusRequested: false})
                break
            case 'On':
                this.setState({toggleState: 2})
                this.setState({statusRequested: false})
                break
            default:
                return
        }
    }

    componentDidMount() {
        this.toggleStatus(this.props.status)
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.status !== prevProps.status) {
            this.toggleStatus(this.props.status)
        }
    }

    getError = () => {
        if (!this.props.error) {
            return null
        }
        let message = getErrorMessage(this.props.errors, 'cameras_id')

        if (!message) {
            message = getErrorMessage(this.props.errors, 'unknownErrorType')
        }

        return (message) ? message : "There was an unexpected error"
    }

    getMessage = (blurb) => {
        return (this.props.error) ?
            <Message negative>
                <Message.Header>Error</Message.Header>
                <p>{blurb}</p>
            </Message> : null
    }

    getBlurb = () => {
        return (this.props.error) ? this.getError() :  null
    }

    isReady = () => {
        return !this.props.loading
    }

    getStatus = (status, type) => {
        let label = null
        switch (status) {
            case 'OffRequested':
            case 'On':
                label = 'Setup complete. Enabling shooting mode'
                break
            case 'Off':
            case 'OnRequested':
                label = 'Pausing shooting. Enabling setup mode'
                break
            default:
                return
        }

        return label
    }

    onToggle = (value) => {
        // Camera Off - User Requesting On
        if (this.state.toggleState === 0) {
            this.setState({toggleState: 1})
            // Fire on request
            this.props.onStartClick()
            this.setState({statusRequested: true})
            // once returned set toggle status to On
        }

         // Camera On - User Requesting Off
         if (this.state.toggleState === 2) {
            if (this.props.notUserConfigured && !this.state.offModalShown) {
                this.props.showOffModal()
                this.setState({offModalShown: true})
            } else {
                this.setState({toggleState: 1})
                this.props.onStopClick()
                this.setState({statusRequested: true})
            }
         }
    }

    popUpHelper = (trigger) => {
        return (
            <Popup trigger={trigger}>
                <p><strong>What is this?</strong></p>
                {(this.state.toggleState !== 2)? <>
                    <p>In order to lengthen battery life your camera enters a sleep state between shots. While the camera is in this sleep state it is not possible to access any of the setup mode settings.</p>
                    <p>Toggling this switch sends a request to the camera to stay online after the next shot, allowing you to make changes.</p>
                    <p><strong>Please note: The camera will check in as often as it is set to shoot in the schedule.</strong></p>
                </> : <>
                    <p>While the camera setup mode is turned <strong>On</strong> you are able to align and configure your shot using the <strong>Camera Setup</strong> tab</p>
                    <p>Once you are happy with your settings please turn setup mode to <strong>Off</strong> so the camera can shoot as per the schedule</p>
                </>
                } 
               
            </Popup>
        )
    }



    render = () => {
        if (!this.isReady()) {
            return (
                <div className='setup-mode-control'>
                    <div className='sub-header__camera-status-toggle'>
                        <h3 className='camera-status-toggle__title'>Camera control</h3>
                        {<Loader active inline size='small'></Loader>}
                    </div>
                </div>
            )
        } else {
            const status = this.getStatus(this.props.status, 'label')
            return (
                <div className='setup-mode-control'>
                    <div className='sub-header__camera-status-toggle'>
                        {this.popUpHelper(<div>
                            <h3 className='camera-status-toggle__title'>Setup mode: </h3>
                        </div>)}
                        <Toggle value={this.state.toggleState} onChange={this.onToggle} />
                        {this.popUpHelper(<div>
                            <Icon name="question circle outline" style={{
                                margin: '0 0 0 10px', fontSize: '1.78571429rem', paddingTop: '2px'
                            }}/>
                        </div>)}
                    </div>
                    {this.state.statusRequested && <div style={{display: 'flex', justifyContent: 'flex-end', margin: '15px 0 0'}}>
                        <p className='camera-status-toggle__loading-text'>{status}</p>
                        <Loader active inline size='small'></Loader>
                    </div>}
                </div>
            )

        }
    }
}

SetupModeControl.propTypes = {
    processing: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    status: PropTypes.string,
    size: PropTypes.string.isRequired,
    error: PropTypes.bool,
    success: PropTypes.bool,
    errors: PropTypes.object,
    onStartClick: PropTypes.func.isRequired,
    onStopClick: PropTypes.func.isRequired
}

export default SetupModeControl
