import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'semantic-ui-react'
import UpdateUser from './../UpdateUser'

/*
* This component is the Update User modal for Lobster Pictures Staff
*/

class UpdateUserModal extends Component {

    constructor(props) {
        super(props)
        this.rowsPerPage = props.rowsPerPage || 12
        this.currentPage = props.currentPage || 1
        this.state = {pane: 'Settings'}
    }

    onOpen = (event, data) => {
        let isLobsterUser = false
        if (this.props.auth && this.props.auth.user && this.props.auth.user.roles_id !== 4 && this.props.auth.user.roles_id !== 5) {
            isLobsterUser = true;
        }

        const {clientId, userId} = this.props
        this.props.editUserActions.resetErrors()
        this.props.editUserActions.userDetailsRequest(clientId, userId)
        // Let's also reset the ucpa
        this.props.ucpaActions.resetAll()
        this.props.ucpaActions.fetchProjects(clientId)
        this.props.ucpaActions.fetchUserProjects(clientId, userId)
        if (isLobsterUser) {
            this.props.ucpaActions.fetchUserCameras(clientId, userId)
        }
    }

    showUpdateUserModal = () => {
        const data = {
            onContextChange: (context) => {
                this.props.editUserActions.setContext(context)
            },
            loading: this.props.editUser.loading,
            error: this.props.editUser.error,
            errors: this.props.editUser.errors,
            onClick:  (args, context) => {
                this.handleUpdateUserConfirmedClick(this.props.clientId, this.props.userId, args, context)
            },
            clientId: this.props.clientId,
            userId: this.props.userId,
            clients: this.props.clients,
            roles: this.props.roles,
            statuses: this.props.statuses,
            auth: this.props.auth,
            user: this.props.editUser.user,
            success: this.props.editUser.success,
            projects: this.props.ucpa.projects,
            cameras: this.props.ucpa.cameras,
            onLoadProjectCameras: (projectId) => {
                this.props.ucpaActions.fetchProjectCameras(this.props.clientId, projectId)
            },
            onResetProjectCameras: this.props.ucpaActions.resetProjectCameras,
            user_cameras: this.props.ucpa.user_cameras,
            user_projects: this.props.ucpa.user_projects,
            add_user_cameras: this.props.ucpa.add_user_cameras,
            remove_user_cameras: this.props.ucpa.remove_user_cameras,
            add_user_projects: this.props.ucpa.add_user_projects,
            remove_user_projects: this.props.ucpa.remove_user_projects,
            project_camera_weights: this.props.ucpa.project_camera_weights,
            unassociate_project_with_user: this.props.ucpa.unassociate_project_with_user,
            unassociate_camera_with_user: this.props.ucpa.unassociate_camera_with_user,
            associate_project_with_user: this.props.ucpa.associate_project_with_user,
            associate_camera_with_user: this.props.ucpa.associate_camera_with_user,
            ucpaActions: this.props.ucpaActions,
            resetUnassociateProjectWithUser: () => {
                this.props.ucpaActions.resetUnassociateProjectWithUser()
            },
            resetUnassociateCameraWithUser: () => {
                this.props.ucpaActions.resetUnassociateCameraWithUser()
            },
            resetAssociateProjectWithUser: () => {
                this.props.ucpaActions.resetAssociateProjectWithUser()
            },
            resetAssociateCameraWithUser: () => {
                this.props.ucpaActions.resetAssociateCameraWithUser()
            },
            ucpa_processing: this.props.ucpa.processing,
            onAddUserProjects: (projects) => {
                this.props.ucpaActions.addUserProjects(projects)
            },
            onRemoveProjects: (projects) => {
                this.props.ucpaActions.removeUserProjects(projects)
            },
            onAddUserCameras: (cameras) => {
                this.props.ucpaActions.addUserCameras(cameras)
            },
            onRemoveCameras: (cameras) => {
                this.props.ucpaActions.removeUserCameras(cameras)
            },
            onUpdateProjectCameraWeights: (projectCameraWeights) => {
                this.props.ucpaActions.updateProjectCameraWeights(projectCameraWeights)
            },
            onUpdateLocalUserState: (details) => {
                this.props.editUserActions.updateLocalUserState(details)
            },
            adminUserRolesRequest: () => {
                this.props.addUserActions.adminUserRolesRequest()
            },
            adminUserStatusesRequest: () => {
                this.props.addUserActions.adminUserStatusesRequest()
            },
            adminUserClientsRequest: () => {
                this.props.addUserActions.adminUserClientsRequest()
            }
        }

        return <UpdateUser
            {...data}
            resetMfa={this.props.resetMfa}
        />
    }

    getButtons = (error, success) => {

        if (success) {
            return <div>
                <Button
                    onClick={() => {
                        const details = this.props.editUser.user
                        const pane = this.props.editUser.context
                        this.handleUpdateUserConfirmedClick(this.props.clientId, this.props.userId, details, pane)}
                    }
                    loading={this.props.editUser.loading}
                    positive
                    icon='checkmark'
                    labelPosition='right'
                    content='Confirm' />
            </div>

        } else {
            return <div>
                <Button
                    onClick={(context) => {
                        const details = this.props.editUser.user
                        const pane = this.props.editUser.context
                        this.handleUpdateUserConfirmedClick(this.props.clientId, this.props.userId, details, pane)}
                    }
                    loading={this.props.editUser.loading}
                    positive
                    icon='checkmark'
                    labelPosition='right'
                    content='Confirm' />
            </div>
        }
    }

    onContextChange = (context) => {
        this.props.editUser.setContext(context)
    }

    handleUpdateUserConfirmedClick = (clientId, userId, args, context) => {
        if (context === 'UserProjects') {
            this.props.ucpaActions.clearSuccessAndErrorMessages()

            // Could trigger a global loading state here....to avoid continuous re-rendering inside modal
            this.props.ucpaActions.processing(true)

            // Okay, so the "state" is held in Redux....we just need to apply it
            // 1. Remove any obsolete relationships between user and project and user and camera
            // 2. Add new relationships between user and project and user and camera
            // 3. Update the weights on each relationship between user and camera and user and project
            // These requests might need to be applied in a series...
            let addUserCameras = null

            let unassociateProjects = async () => {
                for (let item of this.props.ucpa.remove_user_projects) {
                    const projectId = item.id
                    try {
                        await this.props.ucpaActions.unassociateProjectWithUser(clientId, projectId, userId);
                    } catch (error) {
                        console.log('error', error.message);
                    }
                }
            };

            unassociateProjects().then(() => {
                if (this.props.ucpa.remove_user_cameras) {
                    this.props.ucpa.remove_user_cameras.map((camera) => {
                        const cameraId = camera.id
                        const projectId = camera.projects_id
                        return this.props.ucpaActions.unassociateCameraWithUser(clientId, projectId, cameraId, userId)
                    })
                }
            }).then(() =>{
                const addUserProjects = this.props.ucpa.add_user_projects.map((project) => {
                    const projectId = project.id
                    return this.props.ucpaActions.associateProjectWithUser(clientId, projectId, userId)
                })

                if (this.props.ucpa.add_user_cameras) {
                    addUserCameras = this.props.ucpa.add_user_cameras.map((camera) => {
                        const cameraId = camera.id
                        const projectId = camera.projects_id
                        return this.props.ucpaActions.associateCameraWithUser(clientId, projectId, cameraId, userId)
                    })
                }

                Promise.all([...addUserProjects, ...addUserCameras]).then(() => {
                    const projectCameraWeights = this.props.ucpa.project_camera_weights.map((item) => {
                        if ('pot_id' in item) {
                            const cameraId = item.id
                            const projectId = item.projects_id
                            const weight = item.weight
                            return this.props.ucpaActions.changeUserCameraWeight(clientId, projectId, cameraId, userId, weight)
                        } else {
                            const projectId = item.id
                            const weight = item.weight
                            return this.props.ucpaActions.changeUserProjectWeight(clientId, projectId, userId, weight)
                        }
                    })

                    Promise.all(projectCameraWeights).then(() => {
                            // Could unset the loading state here...
                            this.props.ucpaActions.processing(false)
                    })
                })
            })
        }

        let searchField = (this.props.users.searchField)? this.props.users.searchField : null
        let searchClientId = (this.props.isUserListLinkedFromProject)? this.props.clientId : undefined

        this.props.editUserActions.updateUserRequest(null, clientId, userId, args).then(() => {
            this.props.usersActions.userListRequest(
                this.rowsPerPage,
                this.currentPage,
                this.props.users.sortColumn,
                this.props.users.sortAscending,
                this.props.users.searchTerm,
                searchClientId,
                searchField
            )
            this.props.onClose()
        }).catch((err) => { console.log('error', err)})
    }

    render = () => {
        return (
            <Modal
                size={"large"}
                open={this.props.open}
                onOpen={this.onOpen}
                onMount={this.onOpen}
                onClose={this.props.onClose}
                closeIcon
            >
                <Modal.Header>Update user</Modal.Header>
                <Modal.Content>
                    {this.showUpdateUserModal()}
                    <div className='modalButtons'>
                        {this.getButtons(this.props.editUser.error, this.props.editUser.success)}
                    </div>
                </Modal.Content>
            </Modal>
        )
    }

}

UpdateUserModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    editUser: PropTypes.object.isRequired,
    editUserActions: PropTypes.object.isRequired,
    ucpa: PropTypes.object.isRequired,
    ucpaActions: PropTypes.object.isRequired,
    clients: PropTypes.array.isRequired,
    roles: PropTypes.array.isRequired,
    statuses: PropTypes.array.isRequired,
    clientId: PropTypes.any,
    userId: PropTypes.any
}

export default UpdateUserModal
