import * as types from './actionTypes'
import { API_BASE_URL}  from '../config'
import { addMessage } from './messengerActions'
import { log as appLog} from './logActions'
import { notAuthorisedResponse } from './authActions'
import { fetchWithToken } from '../util/accessToken'

export function fetchSetupMode(cameraId, poll) {
    return (dispatch, getState) => {
        if (!poll) {
            dispatch({ type: types.CAMERA_CONTROL_V4_FETCH_SETUP_MODE_REQUEST, cameras_id: cameraId  })
        }
        dispatch(log('Info', 'Requesting camera setup mode details', Date.now()))
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control-v4/setup-mode-state` ,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_V4_FETCH_SETUP_MODE_RESPONSE_ERRORS,
                                        errors: data.errors,
                                        cameras_id: cameraId
                                    })
                            } else {
                                // Error
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_V4_FETCH_SETUP_MODE_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        },
                                        cameras_id: cameraId
                                    }
                                )
                            }

                        })
                    }
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_V4_FETCH_SETUP_MODE_RESPONSE_ERRORS,
                                    errors: data.errors,
                                    cameras_id: cameraId
                                }
                            )
                        } else {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_V4_FETCH_SETUP_MODE_RESPONSE_SUCCESS,
                                    details: data,
                                    cameras_id: cameraId
                                }
                            )
                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(appLog("error", response))
            })
    }
}

export function requestOnSetupMode(cameraId) {
    return (dispatch, getState) => {
        dispatch({ type: types.CAMERA_CONTROL_V4_START_SETUP_MODE_REQUEST, cameras_id: cameraId  })
        // Let's also call log so that it appears in the LogViewer...
        dispatch(log('Info', 'Requesting camera to enter setup mode', Date.now()))
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control-v4/setup-mode-state/request-on` ,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_V4_START_SETUP_MODE_RESPONSE_ERRORS,
                                        errors: data.errors,
                                        cameras_id: cameraId
                                    })
                            } else {
                                // Error...might be a message
                                let message = "There was an unexpected error while requesting camera setup mode"
                                if (data.message) {
                                    message = data.message
                                }

                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_V4_START_SETUP_MODE_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: message
                                            }
                                        },
                                        cameras_id: cameraId
                                    }
                                )
                            }

                        })
                    }
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_V4_START_SETUP_MODE_RESPONSE_ERRORS,
                                    errors: data.errors,
                                    cameras_id: cameraId
                                }
                            )
                        } else {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_V4_START_SETUP_MODE_RESPONSE_SUCCESS,
                                    details: data,
                                    cameras_id: cameraId
                                }
                            )
                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(appLog("error", response))
            })
    }
}

export function requestOffSetupMode(cameraId) {
    return (dispatch, getState) => {
        dispatch({ type: types.CAMERA_CONTROL_V4_STOP_SETUP_MODE_REQUEST, cameras_id: cameraId  })
        // Let's also call log so that it appears in the LogViewer...
        dispatch(log('Info', 'Requesting camera to exit setup mode', Date.now()))
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control-v4/setup-mode-state/request-off` ,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_V4_STOP_SETUP_MODE_RESPONSE_ERRORS,
                                        errors: data.errors,
                                        cameras_id: cameraId
                                    })
                            } else {
                                // Error
                                let message = "There was an unexpected error while requesting camera exiting setup mode"
                                if (data.message) {
                                    message = data.message
                                }
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_V4_STOP_SETUP_MODE_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: message
                                            }
                                        },
                                        cameras_id: cameraId
                                    }
                                )
                            }

                        })
                    }
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_V4_STOP_SETUP_MODE_RESPONSE_ERRORS,
                                    errors: data.errors,
                                    cameras_id: cameraId
                                }
                            )
                        } else {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_V4_STOP_SETUP_MODE_RESPONSE_SUCCESS,
                                    details: data,
                                    cameras_id: cameraId
                                }
                            )
                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(appLog("error", response))
            })
    }
}

export function shoot(cameraId) {
    return (dispatch, getState) => {
        dispatch({ type: types.CAMERA_CONTROL_SHOOT_REQUEST, cameras_id: cameraId  })
        // Let's also call log so that it appears in the LogViewer...
        dispatch(log('Info', 'Requesting camera shoot', Date.now()))
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control-v4/shoot` ,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_SHOOT_RESPONSE_ERRORS,
                                        errors: data.errors,
                                        cameras_id: cameraId
                                    })
                                dispatch(log('Error', 'There was an error while requesting camera shoot', Date.now()))
                            } else {
                                // Error
                                dispatch(
                                    {
                                        type: types.CAMERA_CONTROL_SHOOT_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        },
                                        cameras_id: cameraId
                                    }
                                )
                                dispatch(log('Error', 'There was an unexpected error while requesting camera shoot', Date.now()))
                            }

                        })
                    }
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_SHOOT_RESPONSE_ERRORS,
                                    errors: data.errors,
                                    cameras_id: cameraId
                                }
                            )
                            dispatch(log('Error', 'There was an error while requesting camera shoot', Date.now()))
                        } else {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_SHOOT_RESPONSE_SUCCESS,
                                    details: data,
                                    cameras_id: cameraId
                                }
                            )
                            data.cameras_id = cameraId
                            dispatch(log('Success', 'Successfully received camera shoot details ', Date.now(), data, 'ShootV4'))
                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(appLog("error", response))
            })
    }
}

export function uploadShot(cameraId) {
    return (dispatch, getState) => {
        dispatch(log('Info', 'Requesting camera upload', Date.now()))
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control-v4/upload` ,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    // Remember, we're bouncing out 401s
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(log('Error', 'There was an error while requesting camera shoot', Date.now()))
                        } else {
                            // Error
                            dispatch(log('Error', 'There was an unexpected error while requesting camera shoot', Date.now()))
                        }

                    })
                }
            } else {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(log('Error', 'There was an error while requesting camera shoot', Date.now()))
                    } else {
                        data.cameras_id = cameraId
                        dispatch(log('Success', 'Successfully received camera shoot details ', Date.now(), data, 'UploadV4'))
                    }

                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(appLog("error", response))
        })
    }
}

export function focus(cameraId, direction, step_size) {
    return (dispatch, getState) => {
        dispatch({ type: types.CAMERA_CONTROL_V4_FOCUS_REQUEST, cameras_id: cameraId  })
        // Let's also call log so that it appears in the LogViewer...
        dispatch(log('Info', 'Requesting camera focus ' + direction + ' ' + step_size, Date.now()))
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control-v4/focus`,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({'direction': direction, 'step_size' : step_size})
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    // Remember, we're bouncing out 401s
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_V4_FOCUS_RESPONSE_ERRORS,
                                    errors: data.errors,
                                    cameras_id: cameraId
                                })
                            dispatch(log('Error', 'There was an error while requesting camera focus', Date.now()))
                        } else {
                            // Error
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_V4_FOCUS_RESPONSE_ERRORS,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error"
                                        }
                                    },
                                    cameras_id: cameraId
                                }
                            )
                            dispatch(log('Error', 'There was an unexpected error while requesting camera focus', Date.now()))
                        }

                    })
                }
            } else {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.CAMERA_CONTROL_V4_FOCUS_RESPONSE_ERRORS,
                                errors: data.errors,
                                cameras_id: cameraId
                            }
                        )
                        dispatch(log('Error', 'There was an error while requesting camera focus', Date.now()))
                    } else {
                        dispatch(
                            {
                                type: types.CAMERA_CONTROL_V4_FOCUS_RESPONSE_SUCCESS,
                                details: data,
                                cameras_id: cameraId
                            }
                        )
                        data.cameras_id = cameraId
                        dispatch(log('Success', data.message, Date.now(), data, 'FocusV4'))
                        
                    }

                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(appLog("error", response))
        })
    }
}

export function auto_focus(cameraId) {
    return (dispatch, getState) => {
        dispatch({ type: types.CAMERA_CONTROL_V4_AUTO_FOCUS_REQUEST, cameras_id: cameraId  })
        // Let's also call log so that it appears in the LogViewer...
        dispatch(log('Info', 'Requesting camera auto focus', Date.now()))
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control-v4/auto-focus`,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    // Remember, we're bouncing out 401s
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_V4_AUTO_FOCUS_RESPONSE_ERRORS,
                                    errors: data.errors,
                                    cameras_id: cameraId
                                })
                            dispatch(log('Error', 'There was an error while requesting camera auto focus', Date.now()))
                        } else {
                            // Error
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_V4_AUTO_FOCUS_RESPONSE_ERRORS,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error"
                                        }
                                    },
                                    cameras_id: cameraId
                                }
                            )
                            dispatch(log('Error', 'There was an unexpected error while requesting camera auto focus', Date.now()))
                        }

                    })
                }
            } else {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.CAMERA_CONTROL_V4_AUTO_FOCUS_RESPONSE_ERRORS,
                                errors: data.errors,
                                cameras_id: cameraId
                            }
                        )
                        dispatch(log('Error', 'There was an error while requesting camera auto focus', Date.now()))
                    } else {
                        dispatch(
                            {
                                type: types.CAMERA_CONTROL_V4_AUTO_FOCUS_RESPONSE_SUCCESS,
                                details: data,
                                cameras_id: cameraId
                            }
                        )
                        dispatch(
                            addMessage(
                                "success",
                                "Success",
                                "Auto Focus was performed successfully."
                            )
                        )
                        data.cameras_id = cameraId
                        dispatch(log('Success', data.message, Date.now(), data, 'AutoFocusV4'))
                    }

                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(appLog("error", response))
        })
    }
}

export function reset() {
    return { type: types.CAMERA_CONTROL_V4_RESET }
}

/**
 *
 * @param level
 * @param message
 * @param timestamp
 * @param extras
 * @param type
 * @returns {{type, level: *, message: *, timestamp: *, extras: *, type}}
 *
 * On taking a shot, it's necessary to generate a link in the ActivityLog.
 * extras contains JSON data and type is used to determine what to do.
 *
 */
export function log(level, message, timestamp, extras = null, action = null) {
    return {
        type: types.CAMERA_CONTROL_LOG,
        level: level,
        message: message,
        timestamp: timestamp,
        extras: extras,
        action: action
    }
}

export function fetchLogs(cameraId) {
    return (dispatch, getState) => {
        dispatch( { type: types.CAMERA_CONTROL_V4_LOGS_REQUEST, cameras_id: cameraId })
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control/logs` ,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    // Remember, we're bouncing out 401s
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_V4_LOGS_RESPONSE_ERRORS,
                                    errors: data.errors,
                                    cameras_id: cameraId
                                })
                            dispatch(log('Error', 'There was an error while fetching camera logs', Date.now()))
                        } else {
                            // Error
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_V4_LOGS_RESPONSE_ERRORS,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error"
                                        }
                                    },
                                    cameras_id: cameraId
                                }
                            )
                            dispatch(log('Error', 'There was an unexpected error while fetching camera logs', Date.now()))
                        }

                    })
                }
            } else {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.CAMERA_CONTROL_V4_LOGS_RESPONSE_ERRORS,
                                errors: data.errors,
                                cameras_id: cameraId
                            }
                        )
                        dispatch(log('Error', 'There was an error while fetching camera logs', Date.now()))
                    } else {
                        dispatch(
                            {
                                type: types.CAMERA_CONTROL_V4_LOGS_RESPONSE_SUCCESS,
                                details: data,
                                cameras_id: cameraId
                            }
                        )
                    }

                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(appLog("error", response))
        })
    }
}

export function setBatterySource(cameraId, batteryValue) {
    return (dispatch, getState) => {
        dispatch({ type: types.CAMERA_CONTROL_V4_BATTERY_SOURCE_REQUEST, cameras_id: cameraId  })
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/camera-control-v4/battery-source`,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({'source': batteryValue})
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    // Remember, we're bouncing out 401s
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_V4_BATTERY_SOURCE_RESPONSE_ERRORS,
                                    errors: data.errors,
                                    cameras_id: cameraId
                                })
                            // dispatch(log('Error', 'There was an error while requesting camera APN', Date.now()))
                        } else {
                            // Error
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_V4_BATTERY_SOURCE_RESPONSE_ERRORS,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error"
                                        }
                                    },
                                    cameras_id: cameraId
                                }
                            )
                            // dispatch(log('Error', 'There was an unexpected error while requesting camera APN', Date.now()))
                        }

                    })
                }
            } else {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.CAMERA_CONTROL_V4_BATTERY_SOURCE_RESPONSE_ERRORS,
                                errors: data.errors,
                                cameras_id: cameraId
                            }
                        )
                        // dispatch(log('Error', 'There was an error while requesting camera APN', Date.now()))
                    } else {
                        dispatch(
                            {
                                type: types.CAMERA_CONTROL_V4_BATTERY_SOURCE_RESPONSE_SUCCESS,
                                details: data,
                                cameras_id: cameraId
                            }
                        )
                    }

                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(appLog("error", response))
        })
    }
}

export function fetchCameraInfo(cameraId) {
    return (dispatch, getState) => {
        dispatch({type: types.CAMERA_CONTROL_V4_FETCH_CAMERA_INFO_REQUEST, cameras_id: cameraId})
        let url = `${API_BASE_URL}/api/cameras/${cameraId}/get-info`
        return fetchWithToken(
            url,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    // Remember, we're bouncing out 401s
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_V4_FETCH_CAMERA_INFO_RESPONSE_ERRORS,
                                    errors: data.errors,
                                    cameras_id: cameraId
                                })
                        } else {
                            // Error
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_V4_FETCH_CAMERA_INFO_RESPONSE_ERRORS,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error"
                                        }
                                    },
                                    cameras_id: cameraId
                                }
                            )
                        }

                    })
                }
            } else {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.CAMERA_CONTROL_V4_FETCH_CAMERA_INFO_RESPONSE_ERRORS,
                                errors: data.errors,
                                cameras_id: cameraId
                            }
                        )
                    } else {
                        dispatch(
                            {
                                type: types.CAMERA_CONTROL_V4_FETCH_CAMERA_INFO_RESPONSE_SUCCESS,
                                details: data,
                                cameras_id: cameraId
                            }
                        )
                    }
                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}