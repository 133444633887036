import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
    Button,
    Modal,
    Loader,
    Dimmer
} from 'semantic-ui-react'

const BuyCameraModal = (props) => {

    const [isSuccess, setIsSuccess] = useState(false)

    const [prices, setPrices] = useState(null)

    useEffect(() => {
        setIsSuccess(props.success)
    }, [props.success]);

    useEffect(() => {
        if (props.prices && props.prices.length === 1) {
            setPrices(props.prices[0])
        }
    }, [props.prices]);

    const getContent = (success) => {
        if (success) {
            return showSuccess()
        } else {
            return showButtons()
        }
    }

    let formattedPrice = null

    if (prices) {
        formattedPrice = `£${((parseInt(prices.unit_amount)+parseInt(prices.subscription_amount))/100).toFixed(2)}`
    }


    const showButtons = () => {
        if (prices && prices.unit_amount && prices.subscription_amount) {
            return (
                <>
                    <Modal.Header>Buy Camera?</Modal.Header>
                    <Modal.Content>
                        <p>Please confirm you would like to purchase a camera and a subscription, charged at <strong>{formattedPrice}</strong>.</p>
                        <div className='modalButtons' style={{'position': 'relative'}}>
                            <Button
                                //loading={props.loading}
                                onClick={props.onClose}
                                negative
                                primary
                            >Close</Button>
                        
                            {(props.loading)?
                                <Dimmer active inverted>
                                    <Loader size='medium'/>
                                </Dimmer>
                            : null}
                            <Button
                                onClick={props.onSubmit}
                                //loading={props.loading}
                                positive
                                icon='checkmark'
                                labelPosition='right'
                                content='Buy a camera'
                            />
                        </div>
                    </Modal.Content>
                </>
            )
        } else {
            <Loader active inline='centered' />
        }
    }

    const showSuccess = () => {
        return (
            <>
                <Modal.Header>Success</Modal.Header>
                <Modal.Content>
                    <p>New camera and subscription added.</p>
                    <div className='modalButtons' style={{'position': 'relative'}}>
                        <Button
                            onClick={props.onSuccessClose}
                            positive
                            primary
                        >Close</Button>
                    </div>
                </Modal.Content>
            </>
        )
    }

    return (
        <Modal
            size={"large"}
            open={props.open}
            //onOpen={onOpen}
            //onMount={onOpen}
            onClose={props.onClose}
            closeIcon
        >
            {getContent(isSuccess)}
        </Modal>
    )
}

export default BuyCameraModal