import initialState from './initialState';
import {
    CAMERA_UPLOAD_REQUEST,
    CAMERA_UPLOAD_RESPONSE_SUCCESS,
    CAMERA_UPLOAD_RESPONSE_ERRORS
} from '../actions/actionTypes';

export default function fileList(state = initialState.fileList, action) {
    switch (action.type) {
        case CAMERA_UPLOAD_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    loading: true,
                    success: false,
                    error: false,
                    errors: {},
                    potId: null,
                    password: null,
                    pcid:null,
                    potVersion:null,
                    uploadHost: null,
                }
            )
        case CAMERA_UPLOAD_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    success: true,
                    error: false,
                    errors: {},
                    potId: action.data.pot_id,
                    password: action.data.password,
                    pcid: action.data.pcid,
                    potVersion: action.data.pot_version,
                    uploadHost: action.data.upload_host
                }
            )
        case CAMERA_UPLOAD_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    success: false,
                    error: true,
                    errors: action.errors,
                }
            )
        default:
            return state
    }
}
