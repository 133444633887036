import initialState from './initialState';
import {
    ROLES_CREATE_REQUEST,
    ROLES_CREATE_RESPONSE_ERRORS,
    ROLES_CREATE_RESPONSE_SUCCESS,
    ROLES_VIEW_ALL_REQUEST,
    ROLES_VIEW_ALL_RESPONSE_ERRORS,
    ROLES_VIEW_ALL_RESPONSE_SUCCESS,
    ROLES_VIEW_ONE_REQUEST,
    ROLES_VIEW_ONE_RESPONSE_ERRORS,
    ROLES_VIEW_ONE_RESPONSE_SUCCESS,
    ROLES_UPDATE_REQUEST,
    ROLES_UPDATE_RESPONSE_ERRORS,
    ROLES_UPDATE_RESPONSE_SUCCESS,
    ROLES_DELETE_REQUEST,
    ROLES_DELETE_RESPONSE_ERRORS,
    ROLES_DELETE_RESPONSE_SUCCESS,
    ROLES_ASSOCIATE_PERMISSION_WITH_ROLE_REQUEST,
    ROLES_ASSOCIATE_PERMISSION_WITH_ROLE_RESPONSE_ERRORS,
    ROLES_ASSOCIATE_PERMISSION_WITH_ROLE_RESPONSE_SUCCESS,
    ROLES_UNASSOCIATE_PERMISSION_WITH_ROLE_REQUEST,
    ROLES_UNASSOCIATE_PERMISSION_WITH_ROLE_RESPONSE_ERRORS,
    ROLES_UNASSOCIATE_PERMISSION_WITH_ROLE_RESPONSE_SUCCESS,
    ROLES_RESET_CREATE,
    ROLES_RESET,
    ROLES_RESET_UPDATE,
    ROLES_RESET_DELETE,
    ROLES_PERMISSIONS_REQUEST,
    ROLES_PERMISSIONS_RESPONSE_ERRORS,
    ROLES_PERMISSIONS_RESPONSE_SUCCESS
} from '../actions/actionTypes';

export default function roles(state = initialState.roles, action) {

    switch (action.type) {

        case ROLES_CREATE_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    create: {
                        processing: true,
                        success: false,
                        error: false,
                        errors: {},
                        details: action.details
                    }
                })

        case ROLES_CREATE_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    create: {
                        processing: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: state.create.details
                    }

                })

        case ROLES_CREATE_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    create: {
                        processing: false,
                        success: true,
                        error: false,
                        errors: {},
                        details: action.details
                    }

                })

        case ROLES_UPDATE_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    update: {
                        roles_id: action.roles_id,
                        processing: true,
                        success: false,
                        error: false,
                        errors: {},
                        details: action.details
                    }
                })

        case ROLES_UPDATE_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    update: {
                        roles_id: state.update.roles_id,
                        processing: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: state.update.details
                    }

                })

        case ROLES_UPDATE_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    update: {
                        roles_id: state.update.roles_id,
                        processing: false,
                        success: true,
                        error: false,
                        errors: {},
                        details: action.details
                    }

                })

        case ROLES_DELETE_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    remove: {
                        roles_id: action.roles_id,
                        processing: true,
                        success: false,
                        error: false,
                        errors: {}
                    }
                })

        case ROLES_DELETE_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    remove: {
                        roles_id: state.remove.roles_id,
                        processing: false,
                        success: false,
                        error: true,
                        errors: action.errors
                    }

                })

        case ROLES_DELETE_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    remove: {
                        roles_id: state.remove.roles_id,
                        processing: false,
                        success: true,
                        error: false,
                        errors: {}
                    }

                })

        case ROLES_VIEW_ONE_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    view_one: {
                        roles_id: action.roles_id,
                        loading: true,
                        success: false,
                        error: false,
                        errors: {},
                        details: {}
                    }
                })

        case ROLES_VIEW_ONE_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    view_one: {
                        roles_id: state.view_one.roles_id,
                        loading: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: {}
                    }
                })

        case ROLES_VIEW_ONE_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    view_one: {
                        roles_id: state.view_one.roles_id,
                        loading: false,
                        success: true,
                        error: false,
                        errors: {},
                        details: action.details
                    }
                })


        case ROLES_VIEW_ALL_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    view_all: {
                        page: action.page,
                        loading: true,
                        success: false,
                        error: false,
                        errors: null,
                        details: null
                    }
                })

        case ROLES_VIEW_ALL_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    view_all: {
                        page: state.view_all.page,
                        loading: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: null
                    }
                })

        case ROLES_VIEW_ALL_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    view_all: {
                        page: state.view_all.page,
                        loading: false,
                        success: true,
                        error: false,
                        errors: null,
                        details: action.details
                    }
                })

        case ROLES_ASSOCIATE_PERMISSION_WITH_ROLE_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    associate_permission_with_role: {
                        processing: true,
                        success: false,
                        error: false,
                        errors: null,
                        details: null
                    }
                })

        case ROLES_ASSOCIATE_PERMISSION_WITH_ROLE_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    associate_permission_with_role: {
                        processing: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: null
                    }
                })

        case ROLES_ASSOCIATE_PERMISSION_WITH_ROLE_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    associate_permission_with_role: {
                        processing: false,
                        success: true,
                        error: false,
                        errors: null,
                        details: action.details
                    }
                })

        case ROLES_UNASSOCIATE_PERMISSION_WITH_ROLE_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    unassociate_permission_with_role: {
                        processing: true,
                        success: false,
                        error: false,
                        errors: null,
                        details: null
                    }
                })

        case ROLES_UNASSOCIATE_PERMISSION_WITH_ROLE_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    unassociate_permission_with_role: {
                        processing: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: null
                    }
                })

        case ROLES_UNASSOCIATE_PERMISSION_WITH_ROLE_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    unassociate_permission_with_role: {
                        processing: false,
                        success: true,
                        error: false,
                        errors: null,
                        details: action.details
                    }
                })

        case ROLES_RESET_CREATE:
            return Object.assign(
                {},
                state,
                {
                    create: initialState.roles.create
                })

        case ROLES_RESET:
            return Object.assign(
                {},
                state,
                {
                    ...initialState.roles
                }
            )

        case ROLES_RESET_UPDATE:
            return Object.assign(
                {},
                state,
                {
                    update: initialState.roles.update
                })

        case ROLES_RESET_DELETE:
            return Object.assign(
                {},
                state,
                {
                    remove: initialState.roles.remove
                })


        case ROLES_PERMISSIONS_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    permissions: {
                        page: action.page,
                        loading: true,
                        success: false,
                        error: false,
                        errors: null,
                        details: null
                    }
                })

        case ROLES_PERMISSIONS_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    permissions: {
                        page: state.view_all.page,
                        loading: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: null
                    }
                })

        case ROLES_PERMISSIONS_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    permissions: {
                        page: state.permissions.page,
                        loading: false,
                        success: true,
                        error: false,
                        errors: null,
                        details: action.details
                    }
                })


        default:
            return state

    }
}
