import * as types from './actionTypes'
import { API_BASE_URL}  from '../config'
import { addMessage } from './messengerActions'
import { log } from './logActions'
import { notAuthorisedResponse } from './authActions'
import { fetchWithToken } from '../util/accessToken'

export function editClientResponse(response) {
    return dispatch => {
        if (!response.errors) {
            dispatch(
                addMessage(
                    "success",
                    "Success",
                    "Successfully updated client."
                )
            )
            dispatch(editClientResponseSuccess(response))
        } else {
            dispatch(editClientResponseErrors(response))
        }
    }
}

export function editClientResponseSuccess(response) {
    return {type: types.EDIT_CLIENT_RESPONSE_SUCCESS, editClient: response}
}

export function editClientResponseErrors(response) {
    return {type: types.EDIT_CLIENT_RESPONSE_ERRORS, editClient: response}
}

export function clientDetailsResponse(response) {
    return {type: types.EDIT_CLIENT_DETAILS_RESPONSE, editClient: response}
}

export function clientDetailsRequest(clientId) {
    return (dispatch, getState) => {
        dispatch({ type: types.EDIT_CLIENT_DETAILS_REQUEST })

        return fetchWithToken(
            API_BASE_URL + '/api/clients/' + clientId,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (response.status === 401) {
                dispatch(notAuthorisedResponse())
            } else {
                return response.json()
            }
        })
        .then((json) => {
            dispatch(clientDetailsResponse(json))
        })
        .catch((response) => {
            dispatch(clientDetailsResponseErrors(response))
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function clientDetailsResponseErrors(response) {
    return {type: types.EDIT_CLIENT_DETAILS_REQUEST_ERRORS, editClient: response }
}

export function editClientRequest(history, clientId, clientDetails) {

    return (dispatch, getState) => {

        dispatch({ type: types.EDIT_CLIENT_REQUEST })

        return fetchWithToken(
            API_BASE_URL + '/api/clients/' + clientId,
            {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(clientDetails)
            },
            dispatch,
            getState
        )
        .then(response => {
            if (response.status === 401) {
                dispatch(notAuthorisedResponse())
            } else {
                return response.json()
            }
        })
        .then((json) => {
            dispatch(editClientResponse(json))
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function adminClientRolesResponse(response) {
    if (!Array.isArray(response)) {
        return {type: types.ADMIN_CLIENT_ROLES_RESPONSE, editClient: []}
    } else {
        return {type: types.ADMIN_CLIENT_ROLES_RESPONSE, editClient: response}
    }
}

export function adminClientRolesRequest() {

    return (dispatch, getState) => {

        dispatch({ type: types.ADMIN_CLIENT_ROLES_REQUEST })

        return fetchWithToken(
            API_BASE_URL + '/api/admin/client/roles',
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (response.status === 401) {
                dispatch(notAuthorisedResponse())
            } else {
                return response.json()
            }
        })
        .then((json) => {
            dispatch(adminClientRolesResponse(json))
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })

    }
}


export function adminClientStatusesResponse(response) {
    if (!Array.isArray(response)) {
        return {type: types.ADMIN_CLIENT_STATUSES_RESPONSE, editClient: []}
    } else {
        return {type: types.ADMIN_CLIENT_STATUSES_RESPONSE, editClient: response}
    }
}

export function adminClientStatusesRequest() {

    return (dispatch, getState) => {

        dispatch({ type: types.ADMIN_CLIENT_STATUSES_REQUEST })

        return fetchWithToken(
            API_BASE_URL + '/api/admin/client/statuses',
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (response.status === 401) {
                dispatch(notAuthorisedResponse())
            } else {
                return response.json()
            }
        })
        .then((json) => {
            dispatch(adminClientStatusesResponse(json))
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })

    }
}

export function resetErrors() {
    return { type: types.EDIT_CLIENT_RESET_ERRORS }
}

export function resetSuccess() {
    return { type: types.EDIT_CLIENT_RESET_SUCCESS }
}

export function deleteClientRequest(clientId) {

    return (dispatch, getState) => {

        dispatch({ type: types.DELETE_CLIENT_REQUEST, clients_id : clientId})

        return fetchWithToken(
            API_BASE_URL + '/api/clients/' + clientId,
            {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                // might be forbidden!
                if (response.status === 401) {
                    // Remember, we're bouncing out 401s
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {

                        if (data.errors) {
                            dispatch(deleteClientResponseErrors(clientId, data))
                        } else {
                            dispatch(deleteClientResponseErrors(clientId, { errors: { users_id: { forbidden: data.message }}}))
                        }
                    })
                }
            } else {
                if (response.status === 204) {
                    // no content...but successful
                    dispatch(
                        addMessage(
                            "success",
                            "Success",
                            "Successfully deleted client."
                        )
                    )
                    dispatch(deleteClientResponseSuccess(clientId))
                } else {
                    response.json().then((data) => {
                        // Presumably, errors of some kind...
                        if (data.errors) {
                            dispatch(deleteClientResponseErrors(clientId, data))
                        } else {
                            dispatch(deleteClientResponseErrors(clientId, { errors: { users_id: { forbidden: data.message }}}))
                        }
                    })
                }
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })

    }
}

export function deleteClientResponseSuccess(clientId) {
    return { type: types.DELETE_CLIENT_RESPONSE_SUCCESS, clients_id: clientId}
}

export function deleteClientResponseErrors(clientId, response) {
    return { type: types.DELETE_CLIENT_RESPONSE_ERRORS, clients_id: clientId, errors: response.errors}
}
