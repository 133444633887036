import * as types from './actionTypes'
import { API_BASE_URL}  from '../config'
import { addMessage } from './messengerActions'
import { log } from './logActions'
import { notAuthorisedResponse } from './authActions'
import { fetchWithToken } from '../util/accessToken'

export function load(cameraId, filter, limit) {
    return (dispatch, getState) => {
        dispatch({ type: types.CAMERA_CONTROL_FETCH_DROP_REQUEST})
        let url = `${API_BASE_URL}/api/cameras/${cameraId}/camera-control/drop`
        +`?limit=${limit}`
        if (filter) {
            url += `&filter=${filter}`
        }
        return fetchWithToken(
            url,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_FETCH_DROP_ERRORS,
                                    errors: data.errors,
                                })
                            dispatch(
                                log(
                                    'Error',
                                    'There was an error while requesting the drop'
                                )
                            )
                        } else {
                            // Error
                            dispatch(
                                {
                                    type: types.CAMERA_CONTROL_FETCH_DROP_ERRORS,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error"
                                        }
                                    }
                                }
                            )
                            dispatch(
                                log(
                                    'Error',
                                    'There was an unexpected error while requesting the drop'
                                )
                            )
                        }
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to load drop",
                                "An error ocurred whilst loading the list."
                            )
                        )
                    })
                }
            } else {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.CAMERA_CONTROL_FETCH_DROP_ERRORS,
                                errors: data.errors,
                            }
                        )
                        dispatch(
                            log(
                                'Error',
                                'There was an error while loading the drop.'
                            )
                        )
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to load drop",
                                "An error ocurred whilst loading the list."
                            )
                        )
                    } else {
                        dispatch(
                            {
                                type: types.CAMERA_CONTROL_FETCH_DROP_SUCCESS,
                                ...data
                            }
                        )
                    }

                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function reset() {
    return { type: types.CAMERA_CONTROL_DROP_RESET }
}
