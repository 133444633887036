import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from 'semantic-ui-react'


class TimeZoneDropdown extends Component {

    render() {
        return (
            <div className='time-zone'>
                <Dropdown
                    fluid
                    loading={this.props.processing}
                    floating
                    search
                    selection
                    labeled
                    placeholder='Time Zone'
                    defaultValue={this.props.value}
                    onChange={ (evt, val) => {
                        const timeZone = val.value
                        this.props.onChange(timeZone)
                    }}
                    options={this.props.options}
                    data-testid="dropdown--time-zone"
                />
            </div>
        )
    }

}

TimeZoneDropdown.propTypes = {
    processing: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired
}

export default TimeZoneDropdown
