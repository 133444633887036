    import * as types from './actionTypes'
import { API_BASE_URL}  from '../config'
import { addMessage } from './messengerActions'
import { log } from './logActions'
import { notAuthorisedResponse } from './authActions'
import { fetchWithToken } from '../util/accessToken'

export function clientDetailsResponse(response) {
    return {type: types.VIEW_CLIENT_DETAILS_RESPONSE, viewClient: response}
}

export function clientDetailsRequest(clientId) {

    return (dispatch, getState) => {
        dispatch({ type: types.VIEW_CLIENT_DETAILS_REQUEST })

        return fetchWithToken(
            API_BASE_URL + '/api/clients/' + clientId,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                if (response.code === 401) {
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(json => {
                        dispatch(clientDetailsResponse(json))
                    })
                }
            } else {
                response.json().then(json => {
                    dispatch(clientDetailsResponse(json))
                })
            }
        })
        .catch((response) => {
            dispatch(clientDetailsResponseErrors(response))
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function clientDetailsResponseErrors(response) {
    return {type: types.VIEW_CLIENT_DETAILS_REQUEST_ERRORS, viewClient: response }
}
