import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'semantic-ui-react'

class Search extends Component {

    onChange = (evt, {value}) => {
        this.props.onChange(value)
    }

    render = () => {

        return (
            <div className={`search ${this.props.className}`}>
                <Input
                    key={`cam_search_${this.props.defaultValue}`}
                    placeholder={this.props.placeholder}
                    onChange={this.onChange}
                    defaultValue={this.props.defaultValue}
                    icon='search'
                />
            </div>
        )
    }

}

Search.propTypes = {
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    defaultValue: PropTypes.string.isRequired
}

export default Search
