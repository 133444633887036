import React, { useState, useEffect } from 'react';
import { VIEWER_URL } from '../config';
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
    Container,
    Dimmer,
    Loader,
    Menu,
    Message,
    Table,
    TableRow,
    TableCell,
    Button
} from 'semantic-ui-react'
import moment from 'moment';
import TableColumn from './TableColumn'
import pagination from '../util/pagination'
import { isPermitted } from "../util/acl"


/** Sortable, filterable, paginated Project List **/
const ProjectList = (props) => {
    let columns
    let rowsPerPage = props.rowsPerPage || 10
    let currentPage = props.currentPage || 1

    useEffect(() => {
        reload();
    }, [props.clientId])

    // useEffect(() => {
    //     if (props.isClientProjectList) {
    //         if (clientId !== undefined) {
    //             reload();
    //         }
    //     } else {
    //         reload();
    //     }

    // }, [clientId])

    if (props.clientId === undefined) {
        columns = [
            new TableColumn("name", "Name"),
            new TableColumn("location", "Location", false),
            new TableColumn("clientName", "Client"),
            new TableColumn("status", "Status"),
            new TableColumn("start", "Start Date"),
            new TableColumn("finish", "Completion Date"),
            new TableColumn("actions", "Actions", false)
        ]
    } else {
        // Don't show client name, as we're only showing results for one
        // single client
        columns = [
            new TableColumn("name", "Name"),
            new TableColumn("location", "Location", false),
            new TableColumn("status", "Status"),
            new TableColumn("start", "Start Date"),
            new TableColumn("finish", "Completion Date"),
            new TableColumn("actions", "Actions", false)
        ]
    }

    const reload = () => {
        if (props.isClientProjectList) {
            props.projectsActions.projectListRequest(
                rowsPerPage,
                currentPage,
                props.projects.sortColumn,
                props.projects.sortAscending,
                props.projects.searchTerm,
                props.clientId
            )
        } else {
            props.projectsActions.projectListRequest(
                rowsPerPage,
                currentPage,
                props.projects.sortColumn,
                props.projects.sortAscending,
                props.projects.searchTerm,
                undefined, //props.clientId
            )
        }
    }

    /**
     * Sort results by the selected column
     *
     * @param {string} key - Key value from TableColumn class
     */
    const handleColumnHeadingClick = (key) => {
        let ascending = props.projects.sortColumn === key ? !props.projects.sortAscending : true
        props.projectsActions.projectListRequest(
            rowsPerPage,
            props.projects.currentPage,
            key,
            ascending,
            props.projects.searchTerm,
            props.clientId
        )
    }

    /**
     * Jump to a particular page in the results set
     *
     * @param {string} name - The page number label ("1", "2", etc)
     */
    const handlePaginationPageClick = (e, { name }) => {
        const page = parseInt(name, 10)
        props.projectsActions.projectListRequest(
            rowsPerPage,
            page,
            props.projects.sortColumn,
            props.projects.sortAscending,
            props.projects.searchTerm,
            props.clientId
        )
    }

    /** Jump to the previous page in the results set */
    const handlePaginationPrevClick = (e) => {
        if (props.projects.currentPage > 1 ) {
            const prevPage = props.projects.currentPage - 1
            props.projectsActions.projectListRequest(
                rowsPerPage,
                prevPage,
                props.projects.sortColumn,
                props.projects.sortAscending,
                props.projects.searchTerm,
                props.clientId
            )
        }
    }

    /** Jump to the next page in the results set */
    const handlePaginationNextClick = (e) => {
        const totalPages = Math.ceil(props.projects.totalRows/rowsPerPage)
        if (props.projects.currentPage < totalPages) {
            const nextPage = props.projects.currentPage + 1
            props.projectsActions.projectListRequest(
                rowsPerPage,
                nextPage,
                props.projects.sortColumn,
                props.projects.sortAscending,
                props.projects.searchTerm,
                props.clientId
            )
        }
    }

    /** Render the table header row using this.columns */
    const renderHeader = () => {
        return (
            <TableRow>
                {columns.map((column, k) => {
                    let sortedBy = null
                    if (props.projects.sortColumn === column.key) {
                        sortedBy = props.projects.sortAscending ? "ascending" : "descending"
                    }
                    let click = null
                    if (column.sortable) {
                        click = (e) => {handleColumnHeadingClick(column.key)}
                    }
                    return (
                        <Table.HeaderCell sorted={sortedBy} onClick={click} key={k}>
                            {column.title}
                        </Table.HeaderCell>
                    )
                })}
            </TableRow>
        )
    }

    /**
     * Render a single table row of results
     *
     * @param {Object} row - An object containing one row of results
     * @param {number} index - The current row number being rendered
     */
    const renderBodyRow = (row, index) => {
        // Don't show client name if we're limited results to one client
        let clientName = props.clientId === undefined ?
            (<TableCell>{row.clientName}</TableCell>) : null

        const projectName = (isPermitted(props.auth.user, 'put-projects-projects')) ?
            <Link
                to={`/clients/${row.clients_id}/projects/${row.id}/edit`}
                style={{cursor: 'pointer'}}
            >
                {row.name}
            </Link>
            : row.name

        const editProjectLink = (isPermitted(props.auth.user, 'put-projects-projects')) ?
            <Button
                as={Link}
                to={`/clients/${row.clients_id}/projects/${row.id}/edit`}
                icon='cogs'
                basic
                color='blue'
                compact
                size='tiny'
                content='Edit Project'
            /> : null

        const viewAccessLink = (
            isPermitted(props.auth.user, 'get-project-getlistprojectaccess')
        ) ?
            <Button
                as={Link}
                to={`/clients/${row.clients_id}/projects/${row.id}/access`}
                icon='unordered list'
                basic
                color='blue'
                compact
                size='tiny'
                content='View Project Users'
            />
        : null

        const viewProjectLink =
            <Button
                as='a'
                href={`${VIEWER_URL}/loginvia?token=${localStorage.getItem('accessToken')}&expiry=${localStorage.getItem('accessTokenExpiry')}&refresh=${localStorage.getItem('refreshToken')}`}
                target='_blank'
                icon='cogs'
                basic
                color='blue'
                compact
                size='tiny'
                content='Launch Viewer'
            />

        return (
            <TableRow key={row.id}>
                <TableCell>{projectName}</TableCell>
                <TableCell>{row.location}</TableCell>
                {clientName}
                <TableCell>{row.status}</TableCell>
                <TableCell>{moment(row.start).isValid() ? moment(row.start).format("Do MMM YYYY") : null}</TableCell>
                <TableCell>{moment(row.finish).isValid() ? moment(row.finish).format("Do MMM YYYY") : null}</TableCell>
                <TableCell>
                    <ul style={{padding: 0, margin: 0}}>
                        {editProjectLink}
                        {viewAccessLink}
                        {viewProjectLink}
                    </ul>
                </TableCell>
            </TableRow>
        )
    }

    /** Render the table footer row */
    const renderFooter = () => {
        if (props.projects.totalRows === 0) {
            return (
                <TableRow>
                    <TableCell colSpan={columns.length}>
                        {props.projects.errors.error !== true ?
                            "No results found" : (
                                <Message
                                    error={true}
                                    visible={props.projects.errors.error}
                                    header="Error"
                                    content={props.projects.errors.message}
                                />
                            )

                        }
                    </TableCell>
                </TableRow>
            )
        }
        const totalPages = Math.ceil(props.projects.totalRows/rowsPerPage)
        const first = ((props.projects.currentPage - 1) * rowsPerPage) + 1
        let last = props.projects.currentPage * rowsPerPage
        if (last > props.projects.totalRows) last = props.projects.totalRows
        let pages = pagination(props.projects.currentPage, totalPages)
        let menuPagination = (
            <Menu pagination>
                <Menu.Item name="prev" onClick={handlePaginationPrevClick} icon="angle left" />
                {pages.map((page, key) => {
                    return (
                        <Menu.Item name={page.toString()} key={key} active={props.projects.currentPage === page} onClick={handlePaginationPageClick} />
                    )
                })}
                <Menu.Item name="next" onClick={handlePaginationNextClick} icon="angle right" />
            </Menu>
        )

        return (
            <TableRow>
                <TableCell colSpan={columns.length}>
                    <div>Showing {first} - {last} of {props.projects.totalRows} {props.projects.totalRows === 1 ? "result" : "results"}</div>
                    <div style={{textAlign: 'center'}}>
                        {totalPages > 1 ? menuPagination : null}
                    </div>
                </TableCell>
            </TableRow>
        )
    }

    return (
        <div className='projects-list' data-testid="list--projects">
            <Dimmer.Dimmable>
                <Dimmer active={props.projects.loading} inverted>
                    <Loader />
                </Dimmer>
                <Container className="content-container" fluid={true} style={{padding: '0 1.2em'}}>
                    <Table
                        renderBodyRow={renderBodyRow}
                        headerRow={renderHeader()}
                        tableData={props.projects.tableData}
                        footerRow={renderFooter()}
                        sortable
                    >
                    </Table>
                </Container>
            </Dimmer.Dimmable>
        </div>
    )
}

ProjectList.propTypes = {
    clientId: PropTypes.number,
    projects: PropTypes.shape({
        currentPage: PropTypes.number,
        errors: PropTypes.object,
        filtered: PropTypes.bool,
        loading: PropTypes.bool,
        sortAscending: PropTypes.bool,
        sortColumn: PropTypes.string,
        tableData: PropTypes.array.isRequired,
        totalRows: PropTypes.number
    }).isRequired,
    projectsActions: PropTypes.object,
    addProjectActions: PropTypes.object,
    updateProjectActions: PropTypes.object
}

export default ProjectList
