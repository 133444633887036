import React, { Component } from 'react'
import {
    Button,
    Input,
    Modal,
    Message,
    Form,
} from 'semantic-ui-react'
import { getErrorMessage } from './../../util/errors'
import PropTypes from 'prop-types'

class UpdateEmbedModal extends Component {

    constructor(props) {
        super(props)
        this.state = this.getInitialState()
    }

    getInitialState = () => {
        return {
            id: null,
            name: null,
            format: null,
            width: null,
            height: null,
            quality: null,
            crop: null,
            delay: null,
            timecode: false,
            processed: false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props !== prevProps) {
            if (!this.props.embeds.create.error && !this.props.embeds.create.processing)  {
                if (this.props.embedId !== null) {
                    const data = this.props.embeds.list.embeds[this.props.embedId]
                    this.setState(data)
                } else {
                    this.setState(this.getInitialState())
                }
            }
        }
    }

    onFormatChange = (event, {value}) => {
        this.setState({format: value})
    }

    onWidthChange = (event, {value}) => {
        this.setState({width: value})
    }

    onHeightChange = (event, {value}) => {
        this.setState({height: value})
    }

    onQualityChange = (event, {value}) => {
        this.setState({quality: value})
    }

    onCropChange = (event, {value}) => {
        this.setState({crop: value})
    }

    onDelayChange = (event, {value}) => {
        this.setState({delay: value})
    }

    onTimecodeChange = (event, {checked}) => {
        this.setState({timecode: checked})
    }

    onProcessedChange = (event, {checked}) => {
        this.setState({processed: checked})
    }

    getData = () => {
        return this.state
    }

    render() {
        const formats = [
            { key: 'fi', text: 'jpeg image', value: 'image' },
            { key: 'fm', text: 'mp4 video', value: 'mp4'},
            { key: 'fw', text: 'webm video', value: 'webm'},
            { key: 'ff', text: 'flv video', value: 'flv'}
        ]

        const qualities = [
            { key: 'q25', text: 'Low', value: 25 },
            { key: 'q50', text: 'Medium', value: 50},
            { key: 'q75', text: 'High', value: 75},
            { key: 'q100', text: 'Max', value: 100}
        ]

        const crops = [
            { key: 'ct', text: 'Top', value: 'top' },
            { key: 'cc', text: 'Centre', value: 'centre'},
            { key: 'cb', text: 'Bottom', value: 'bottom'}
        ]

        const errors = this.props.embedId !== null?
            this.props.embeds.update.errors :
            this.props.embeds.create.errors

        return (
            <Modal size={"tiny"} open={this.props.visible} onClose={this.props.onClose} closeIcon>
                <Modal.Header>Embed Settings</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Message
                            error={true}
                            visible={errors.embedName !== undefined}
                            header={errors.embedName}
                            content={getErrorMessage(errors, 'embedName')}
                        />
                        <Form.Select
                            fluid
                            width={10}
                            label='Format'
                            options={formats}
                            placeholder='Format'
                            value={this.state.format}
                            onChange={this.onFormatChange}
                        />
                        <Message
                            error={true}
                            visible={errors.format !== undefined}
                            header={errors.format}
                            content={getErrorMessage(errors, 'format')}
                        />
                        <Form.Group>
                            <Form.Field width={5}>
                                <label>Width</label>
                                <Input
                                    fluid
                                    label={{ basic: true, content: 'px' }}
                                    labelPosition='right'
                                    value={this.state.width}
                                    onChange={this.onWidthChange}
                                />
                            </Form.Field>
                            <Form.Field width={5}>
                                <label>Height</label>
                                <Input
                                    fluid
                                    label={{ basic: true, content: 'px' }}
                                    labelPosition='right'
                                    defaultValue={this.state.height}
                                    onChange={this.onHeightChange}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Message
                            error={true}
                            visible={errors.width !== undefined}
                            header={errors.width}
                            content={getErrorMessage(errors, 'width')}
                        />
                        <Message
                            error={true}
                            visible={errors.height !== undefined}
                            header={errors.height}
                            content={getErrorMessage(errors, 'height')}
                        />
                        <Form.Select
                            fluid
                            width={10}
                            label='Quality'
                            options={qualities}
                            placeholder='Quality'
                            value={this.state.quality}
                            onChange={this.onQualityChange}
                        />
                        <Message
                            error={true}
                            visible={errors.quality !== undefined}
                            header={errors.quality}
                            content={getErrorMessage(errors, 'quality')}
                        />
                        <Form.Select
                            fluid
                            width={10}
                            label='Crop'
                            options={crops}
                            placeholder='Crop'
                            value={this.state.crop}
                            onChange={this.onCropChange}
                        />
                        <Message
                            error={true}
                            visible={errors.crop !== undefined}
                            header={errors.crop}
                            content={getErrorMessage(errors, 'crop')}
                        />
                        <Form.Input
                            fluid
                            width={5}
                            label='Time Delay'
                            placeholder='Hours'
                            value={this.state.delay}
                            onChange={this.onDelayChange}
                        />
                        <Message
                            error={true}
                            visible={errors.delay !== undefined}
                            header={errors.delay}
                            content={getErrorMessage(errors, 'delay')}
                        />
                        <Form.Checkbox
                            label='Overlay timecode'
                            checked={!!this.state.timecode}
                            onChange={this.onTimecodeChange}
                        />
                        <Message
                            error={true}
                            visible={errors.timecode !== undefined}
                            header={errors.timecode}
                            content={getErrorMessage(errors, 'timecode')}
                        />
                        <Form.Checkbox
                            label='Apply processing'
                            checked={!!this.state.processed}
                            onChange={this.onProcessedChange}
                        />
                        <Message
                            error={true}
                            visible={errors.processed !== undefined}
                            header={errors.processed}
                            content={getErrorMessage(errors, 'processed')}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        loading={this.props.embeds.update.processing || this.props.embeds.create.processing}
                        onClick={this.props.onSubmit}
                        positive
                    >Submit</Button>
                    <Button
                        onClick={this.props.onClose}
                        negative
                    >Cancel</Button>
                </Modal.Actions>
            </Modal>
        )
    }
}

UpdateEmbedModal.propTypes = {
    embeds: PropTypes.object.isRequired,
    embedId: PropTypes.number.isRequired,
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
}

export default UpdateEmbedModal
