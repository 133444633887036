import * as types from './actionTypes'
import { API_BASE_URL}  from '../config'
import { addMessage } from './messengerActions'
import { log } from './logActions'
import { fetchWithToken, waitForAccessTokenRefresh } from '../util/accessToken'

export function notAuthorisedResponse() {
    return { type: types.NOT_AUTHORISED_RESPONSE }
}

export function forcedOut(showMessage) {
    return dispatch => {
        if (showMessage) {
            dispatch(
                addMessage(
                    "negative",
                    "You have been forced out of your current session",
                    "This is typically because you have tried to access a resource without the appropriate permission"
                )
            )
        }
        
        dispatch({type: types.FORCED_OUT })
    }
}

export function loggedInUserDetailsResponse(response) {
    return {type: types.LOGGED_IN_USED_DETAILS_RESPONSE, auth: response}
}

export function loggedInUserDetailsRequest() {
    return (dispatch, getState) => {
        dispatch({ type: types.LOGGED_IN_USER_DETAILS_REQUEST })
        return fetchWithToken(
            API_BASE_URL + '/auth/get-user-details',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (response.status === 401) {
                dispatch(notAuthorisedResponse())
            } else {
                return response.json()
            }
        })
        .then((json) => {
            dispatch(loggedInUserDetailsResponse(json))
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to fetch user details",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function loginResponse(history, payload) {
    return dispatch => {
        if (payload.access_token) {
            dispatch(loginSuccess(history, payload))
        } else {
            dispatch(loginErrors(payload))
        }
    }
}

export function loginSuccess(history, payload) {
    return dispatch => {
        dispatch( { type: types.SHOW_HEADER })
        dispatch( { type: types.SHOW_FOOTER })
        dispatch( {type: types.LOGIN_SUCCESS, auth: payload} )
        if (!payload.mfa_verified && payload.mfa_required) {
            history.push('/auth/setup-token')
            dispatch( { type: types.USER_MFA_SETUP })
        } else if (payload.mfa_required && payload.mfa_verified) {
            history.push('/auth/enter-token')
            dispatch( { type: types.USER_MFA_ENTER })
        } else if (payload.access_token) {
            history.push('/')
        }
    }
}

export function loginErrors(payload) {
    return { type: types.LOGIN_ERRORS, auth: payload }
}

export function loginResetErrors() {
    return { type: types.LOGIN_RESET_ERRORS }
}

export function loginRequest(history, username, password) {
    return dispatch => {
        dispatch( { type: types.LOGIN_REQUEST,
            auth:
                {
                    username: username,
                    password: password,
                    loading: true
                }
        })
        return fetch(API_BASE_URL +  '/auth/authorize', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                'grant_type' : 'password',
                'username' : username,
                'password' : password
            })
        })
        .then(response => response.json())
        .then((payload) => {
            dispatch(loginResponse(history, payload))
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process login",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
            dispatch(resetLoginCounter()) // Prevents Captcha-related issues
            dispatch(({ type: types.LOGIN_RESPONSE })) // prevents spinner
        })
    };
}

export function requestMfaToken(details) {
    return (dispatch, getState) => {
        dispatch( { type: types.SEND_MFA_TOKEN_REQUEST })
        let status
        let url = API_BASE_URL +  '/auth/send-mfa-token'
       
        return fetchWithToken(
            url,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(details)
            },
            dispatch,
            getState
        )
        .then(response => {
            status = response.status
            return response.json()
        })
        .then((json) => {
            if (status ===  201) {
                dispatch({
                    type: types.SEND_MFA_TOKEN_RESPONSE,
                    data: json
                })
            } else {
                dispatch({
                    type: types.SEND_MFA_TOKEN_ERROR,
                    errors: json
                })
            }
        })
        .catch((response) => {
            dispatch({
                type: types.SEND_MFA_TOKEN_ERROR,
                errors: {'message':'An unknown error occurred. Please try again.'}
            })
        })
    };
}

export function verifyMfaToken(details, history) {
    return (dispatch, getState) => {
        dispatch( { type: types.VERIFY_MFA_TOKEN_REQUEST })
        let status
        return fetchWithToken(
            API_BASE_URL +  '/auth/verify-mfa-token',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Accept': 'application/json'
                },
                body: JSON.stringify(details)
            },
            dispatch,
            getState
        )
        .then(response => {
            status = response.status
            return response.json()
        })
        .then((json) => {
            if (status ===  200) {
                dispatch({
                    type: types.VERIFY_MFA_TOKEN_RESPONSE,
                    data: json
                })
                history.push('/')
            } else if (status === 403){
                dispatch({
                    type: types.VERIFY_MFA_TOKEN_ERROR,
                    errors: {'message': 'Sorry, the code was incorrect'}
                })
            } else {
                dispatch({
                    type: types.VERIFY_MFA_TOKEN_ERROR,
                    errors: {'message': json.error}
                })
            }
        })
        .catch((response) => {
            dispatch({
                type: types.SEND_MFA_TOKEN_ERROR,
                errors: {'message':'An unknown error occurred. Please try again.'}
            })
        })
    };
}

export function createTotpFactor(details) {
    return (dispatch, getState) => {
        dispatch({type: types.TOTP_CREATE_FACTOR_REQUEST})

        return fetchWithToken(
            `${API_BASE_URL}/auth/create-totp-factor`,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(details)
            },
            dispatch,
            getState
        ).then(response => {
            if (!response.ok) {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.TOTP_CREATE_FACTOR_RESPONSE_ERRORS,
                                errors: data.errors
                            }
                        )
                    } else {
                        dispatch(
                            {
                                type: types.TOTP_CREATE_FACTOR_RESPONSE_ERRORS,
                                errors: {
                                    unknownError: "There was an unexpected error"
                                }
                            }
                        )
                    }
                })
            } else {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.TOTP_CREATE_FACTOR_RESPONSE_ERRORS,
                                errors: data.errors
                            }
                        )
                    } else {
                        dispatch({
                            type: types.TOTP_CREATE_FACTOR_RESPONSE_SUCCESS,
                            details: data
                        })
                    }
                })

            }
        })
        .catch((response) => {
            dispatch(
                {
                    type: types.TOTP_CREATE_FACTOR_RESPONSE_ERRORS,
                    errors: {
                        unknownError: "There was an unexpected error"
                    }
                }
            )
        })
    }
}

export function verifyTotpFactor(details) {
    return (dispatch, getState) => {
        dispatch({type: types.TOTP_VERIFY_FACTOR_REQUEST})

        return fetchWithToken(
            `${API_BASE_URL}/auth/verify-totp-factor`,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(details)
            },
            dispatch,
            getState
        ).then(response => {
            if (response.status === 400) {
                response.json().then((data) => {
                    if (data.errors) {
                        dispatch({
                            type: types.TOTP_VERIFY_FACTOR_RESPONSE_ERRORS,
                            errors: data.errors
                        })
                    } else {
                        dispatch(
                            {
                                type: types.TOTP_VERIFY_FACTOR_RESPONSE_ERRORS,
                                errors: {
                                    unknownError: "There was an unexpected error"
                                }
                            }
                        )
                    }
                })
            } else {
                return response.json()
            }
        }).then(data => {
            if (data.errors) {
                dispatch(
                    {
                        type: types.TOTP_VERIFY_FACTOR_RESPONSE_ERRORS,
                        errors: data.errors
                    }
                )
            } else {
                dispatch({
                    type: types.TOTP_VERIFY_FACTOR_RESPONSE_SUCCESS,
                    details: data
                })
            }
        })
        .catch((response) => {
            dispatch(
                {
                    type: types.TOTP_VERIFY_FACTOR_RESPONSE_ERRORS,
                    errors: {
                        unknownError: "There was an unexpected error"
                    }
                }
            )
        })
    }
}

export function resetTotpFactor() {
    return dispatch => {
        dispatch({type: types.TOTP_FACTOR_RESET })
    }
}

export function refreshAccessTokenRequest(refreshToken, history = null) {
    return (dispatch, getState) => {
        // Check if access token refresh is in progress
        if (window.refreshingAccessToken) {
            return waitForAccessTokenRefresh(getState)
        }
        dispatch({
            type: types.REFRESH_ACCESS_TOKEN_REQUEST,
            auth: { refreshToken: refreshToken }
        })
        window.refreshingAccessToken = true
        return fetch(API_BASE_URL +  '/auth/authorize', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                'grant_type' : 'refresh_token',
                'refresh_token' : refreshToken
            })
        })
        .then(response => {
            if (!response.ok) {
                // If here, we have errors
                dispatch(refreshAccessTokenResponse({}))
                if (history) {
                    history.push('/auth')
                }
            } else {
                // Might be No Content...especially on success
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(refreshAccessTokenResponse(data))
                        if (history) {
                            history.push('/auth')
                        }
                    } else {
                        dispatch(refreshAccessTokenResponse(data))
                        dispatch(loggedInUserDetailsRequest())
                    }

                })
            }
        })
        .catch((response) => {
            dispatch(log("error", response))
            window.refreshingAccessToken = false
            if (history) {
                history.push('/auth')
            }
        })
    };
}

export function refreshAccessTokenResponse(json) {
    window.refreshingAccessToken = false
    return { type: types.REFRESH_ACCESS_TOKEN_RESPONSE, auth: json }
}

export function logoutRequest(history) {
    return (dispatch, getState) => {
        dispatch( { type: types.LOGOUT_REQUEST })
        return fetchWithToken(
            API_BASE_URL +  '/auth/logout',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    'action' : 'logout'
                })
            },
            dispatch,
            getState
        )
        .then(response => {
            if (response.status === 401) {
                dispatch(notAuthorisedResponse())
            } else {
                return response.json()
            }
        })
        .then((json) => {
            dispatch(logoutResponse(history, json))
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process logout request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    };
}

export function logoutResponse(history, response) {
    return dispatch => {
        history.push('/auth')
        dispatch({type: types.LOGOUT_RESPONSE, auth: response })
    }
}

export function checkUserLockdownStatus() {
    return (dispatch, getState) => {
        dispatch({ type: types.CHECK_USER_LOCKDOWN_STATUS_REQUEST })
        return fetchWithToken(
            API_BASE_URL + '/api/status/lockdown',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                // If here, we have errors
                dispatch({type: types.CHECK_USER_LOCKDOWN_STATUS_RESPONSE, status: "ON"})
            } else {
                // Might be No Content...especially on success
                response.json().then(data => {
                    if (data.errors) {
                        dispatch({type: types.CHECK_USER_LOCKDOWN_STATUS_RESPONSE, status: "ON"})
                    } else {
                        dispatch({type: types.CHECK_USER_LOCKDOWN_STATUS_RESPONSE, status: data.status})
                    }
                })
            }
        })
        .catch((response) => {
            dispatch(log("error", response))
        })
    }
}

export function resetLoginCounter() {
    return { type: types.RESET_LOGIN_COUNTER }
}

export function resetMfa(clientsId, userId) {
    return (dispatch, getState) => {
        dispatch({type: types.RESET_MFA_REQUEST})

        return fetchWithToken(
            `${API_BASE_URL}/api/clients/${clientsId}/users/${userId}/reset-mfa`,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
            },
            dispatch,
            getState
        ).then(response => {
            if (!response.ok) {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.RESET_MFA_ERRORS,
                                errors: data.errors
                            }
                        )
                    } else {
                        dispatch(
                            {
                                type: types.RESET_MFA_ERRORS,
                                errors: {
                                    unknownError: "There was an unexpected error"
                                }
                            }
                        )
                    }
                })
            } else {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.RESET_MFA_ERRORS,
                                errors: data.errors
                            }
                        )
                    } else {
                        dispatch({
                            type: types.RESET_MFA_SUCCESS,
                            details: data
                        })
                        dispatch(
                            addMessage(
                                "positive",
                                "Success",
                                "You have successfully reset the users MFA token"
                            )
                        )
                    }
                })

            }
        })
        .catch((response) => {
            dispatch(
                {
                    type: types.RESET_MFA_ERRORS,
                    errors: {
                        unknownError: "There was an unexpected error"
                    }
                }
            )
        })
    }
}