import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
    Button,
    Modal,
    Message,
    Form,
    Select,
    Grid,
    Icon
} from 'semantic-ui-react'
import { getErrorMessage } from './../../util/errors'
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css'
import ProjectCameras from './../forms/ProjectCameras'

const CreateProjectModal = (props) => {

    const [configValues, setConfigValues] = useState({
        name: "",
        description: "",
        location: "",
        latitude: "",
        longitude: "",
        start: "",
        finish: "",
        status: "Active",
        watermark: true,
        startDate: null,
        finishDate: null,
        clients_id: "",
        add_cameras: null,
        user_configured: 1,
    })

    useEffect(() => {
        if (props.success === true) {
            onClose()
        } else {
            const start = (configValues.start) ? configValues.start : props.details.start
            const finish = (configValues.finish) ? configValues.finish : props.details.finish
            setConfigValues(prevState => {
                return {
                    name: props.details.name? props.details.name : prevState.name,
                    description: props.details.description? props.details.description : prevState.description,
                    location: props.details.location? props.details.location : prevState.description,
                    latitude: props.details.latitude? props.details.latitude: prevState.latitude,
                    longitude: props.details.longitude? props.details.longitude : prevState.longitude,
                    start: start,
                    finish: finish,
                    status: configValues.status ? configValues.status : props.details.status,
                    watermark: props.details.watermark,
                    startDate: (start) ? new moment(start).toDate() : null,
                    finishDate: (finish) ? new moment(finish).toDate() : null,
                    add_cameras: props.details.add_cameras,
                    user_configured: configValues.user_configured,
                }
            })
        }
    }, [props.success, props.details])

    // @TODO Create a utiity import to save duplicating these functions
    // between Create and Update

    const onOpen = (event, data) => {
        props.addProjectActions.resetErrors()
        props.camerasActions.fetchAvailableCameras()
    }

    const onConfirm = (event, data) => {
        const clientId = (props.clientId) ?
            props.clientId : configValues.clients_id
        props.addProjectActions.createProjectRequest(
            configValues, clientId
        )
    }

    const onStartDateChange = (date) => {
        if (date) {
            const formattedDate = moment(date, "YYMMDDHHmmss")
            setConfigValues(prevState => {
                return {...prevState, startDate: date, start: formattedDate.format("YYYY-MM-DD")}
            })
        } else {
            setConfigValues(prevState => {
                return {...prevState, startDate: "", start: ""}
            })
        }
    }

    const onFinishDateChange = (date) => {
        if (date) {
            const formattedDate = moment(date, "YYMMDDHHmmss")
            setConfigValues(prevState => {
                return {...prevState, finishDate: date, finish: formattedDate.format("YYYY-MM-DD")}
            })
        } else {
            setConfigValues(prevState => {
                return {...prevState, finishDate: "", finish: ""}
            })
        }
    }

    const onNameChange = (event, {value}) => {
        setConfigValues(prevState => {
            return {...prevState, name: value}
        })
    }

    const onDescriptionChange = (event, {value}) => {
        setConfigValues(prevState => {
           return {...prevState, description: value}
        })
   }

    const onWatermarkChange = (event, {checked}) => {
        setConfigValues(prevState => {
            return {...prevState, watermark: checked}
        })
    }

    const onLocationChange = (event, {value}) => {
        setConfigValues(prevState => {
            return {...prevState, location: value}
        })
    }

    const onLatitudeChange = (event, {value}) => {
        setConfigValues(prevState => {
            return {...prevState, latitude: value}
        })
    }

    const onLongitudeChange = (event, {value}) => {
        setConfigValues(prevState => {
            return {...prevState, longitude: value}
        })
    }

    const onClientChange = (event, value) => {
        const val = value.value
        setConfigValues(prevState => {
            return {...prevState, clients_id: val}
        })
    }

    const onAvailableCamerasChange = (event, value) => {
        const val = value.value
        setConfigValues(prevState => {
            return {...prevState, add_cameras: val}
        })
    }

    // const onAddingToProject = (cameras) => {
    //     setConfigValues(prevState => {
    //         return {...prevState, add_cameras: cameras}
    //     })
    // }

    const onClose = () => {
        props.onClose()
        //setConfigValues(initialState)
        // this.setState(this.getInitialConfig(), () => {
        //     this.props.onClose()
        // })
    }

    // const getHeader = (error, success) => {
    //     if (error) {
    //         return <h3 id='successStatus'>Error</h3>
    //     } else if (success) {
    //         return <h3 id='successStatus'>Success!</h3>
    //     } else {
    //         return ""
    //     }
    // }

    const getContent = (error, success, loading, errors) => {
        // We might have a server error, or just regular errors
        if (error) {
            const unexpectedErrorMessage = getErrorMessage(props.errors, 'unexpected')
            const addCamerasErrorMessage = getErrorMessage(props.errors, 'add_cameras')
            return (
                <div>
                    <p className='error'>{unexpectedErrorMessage}</p>
                    <p className='error'>{addCamerasErrorMessage}</p>
                    {renderForm()}
                </div>
            )
        } else if (success) {
            return <p>Successfully created project</p>
        } else if (loading) {
            return renderForm()
        } else {
            // Must be initial loading...
            return renderForm()
        }
    }

    const getClientsDropDown = () => {

        // Let's determine if it's required based on clientId...
        // if (props.clientId) {
        //     return null
        // } else {
            // we only get one shot with these forms...let's ensure the list is populated...
            if (props.clients && props.clients.length > 0) {
                return (
                    <div>
                        <Form.Field
                            id='projectClient'
                            control={Select}
                            options={props.clients}
                            label='Client'
                            placeholder='Client'
                            onChange={onClientChange}
                            error={(props.errors.clients_id) ? true : false}
                            search
                        />
                        <Message
                            error={true}
                            visible={(props.errors.clients_id) ? true : false}
                            content={getErrorMessage(props.errors, 'clients_id')}
                        />
                    </div>
                )
            } else {
                return null
            }
        //}
    }

    const renderForm = () => {
        return (
            <Form size='large'>
                    <Form.Input
                        id='projectName'
                        fluid
                        placeholder='Project Name'
                        label='Project Name'
                        onChange={onNameChange}
                        error={props.errors.name ? true : false}
                        value={configValues.name}
                    />
                    <Message
                        error={true}
                        visible={props.errors.name !== undefined}
                        header={''}
                        content={getErrorMessage(props.errors, 'name')}
                    />
                    <Form.TextArea
                        id='projectDescription'
                        placeholder='Description'
                        label='Description'
                        onChange={onDescriptionChange}
                        error={props.errors.description ? true : false}
                        value={configValues.description}
                    />
                    <Message
                        error={true}
                        visible={props.errors.description !== undefined}
                        header={''}
                        content={getErrorMessage(props.errors, 'description')}
                    />

                    {getClientsDropDown()}

                    <Message
                        error={true}
                        visible={props.errors.status}
                        header={props.errors.status}
                        content={getErrorMessage(props.errors, 'status')}
                    />

                    <Form.Checkbox
                        label={configValues.watermark ? 'Watermark Enabled (Click to disable)' : 'Watermark Disabled (Click to enable)'}
                        checked={configValues.watermark}
                        onChange={onWatermarkChange}
                        toggle
                    />
                    <Message
                        error={true}
                        visible={props.errors.watermark}
                        header={''}
                        content={getErrorMessage(props.errors, 'watermark')}
                    />

                    <Form.Input
                        id='projectLocation'
                        fluid
                        placeholder='Location'
                        label='Location'
                        onChange={onLocationChange}
                        error={props.errors.location ? true : false}
                        value={configValues.location}
                    />
                    <Message
                        error={true}
                        visible={props.errors.location !== undefined}
                        header={''}
                        content={getErrorMessage(props.errors, 'location')}
                    />

                    <Grid columns='2'>
                        <Grid.Row className='no-padding-bottom'>
                            <Grid.Column>
                                <Form.Input
                                    id='projectLatitude'
                                    fluid
                                    type="number"
                                    placeholder='Latitude'
                                    label='Latitude'
                                    onChange={onLatitudeChange}
                                    error={props.errors.latitude ? true : false}
                                    value={configValues.latitude}
                                />
                                <Message
                                    error={true}
                                    visible={props.errors.latitude !== undefined}
                                    header={''}
                                    content={getErrorMessage(props.errors, 'latitude')}
                                />
                            </Grid.Column>
                            <Grid.Column >
                                <Form.Input
                                    id='projectLongitude'
                                    fluid
                                    type="number"
                                    placeholder='Longitude'
                                    label='Longitude'
                                    onChange={onLongitudeChange}
                                    error={props.errors.longitude ? true : false}

                                    value={configValues.longitude}
                                />
                                <Message
                                    error={true}
                                    visible={props.errors.longitude !== undefined}
                                    header={''}
                                    content={getErrorMessage(props.errors, 'longitude')}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Grid columns='2'>
                        <Grid.Row className={ (props.errors.latitude || props.errors.longitude) ? '' : 'no-padding-top' }>
                            <Grid.Column>
                                <div className="ui field">
                                    <label>Start Date</label>
                                </div>
                                <div className='projectStartDateContainer'>
                                    <DatePicker
                                        placeholderText='Start'
                                        dateFormat="do MMM yy"
                                        selected={configValues.startDate}
                                        onChange={onStartDateChange}
                                    />
                                    <Icon className='datePickerIcon' name='calendar'  />
                                </div>
                                <Message
                                    className='projectDateErrors'
                                    error={true}
                                    visible={props.errors.start !== undefined}
                                    header={''}
                                    content={getErrorMessage(props.errors, 'start')}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <div className="ui field">
                                    <label>Finish Date</label>
                                </div>
                                <div className='projectFinishDateContainer'>

                                    <DatePicker
                                        placeholderText='Finish'
                                        dateFormat="do MMM yy"
                                        selected={configValues.finishDate}
                                        onChange={onFinishDateChange}
                                    />
                                    <Icon className='datePickerIcon' name='calendar'  />
                                </div>
                                <Message
                                    className='projectDateErrors'
                                    error={true}
                                    visible={props.errors.finish !== undefined}
                                    header={''}
                                    content={getErrorMessage(props.errors, 'finish')}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <ProjectCameras
                        // Existing data source
                        availableCameras={props.availableCameras}
                        deployedCameras={[]}

                        add_cameras={props.projectCameras.add_cameras}
                        remove_cameras={props.projectCameras.remove_cameras}
                        camera_weights={props.projectCameras.camera_weights}

                        // Pushing local state into Redux here
                        onAddCameras={(cameras) => {
                            props.onAddCameras(cameras)
                        }}

                        onRemoveCameras={ (cameras) => {
                            props.onRemoveCameras(cameras)}
                        }

                        onCameraWeights={ (cameras) => {
                            props.onCameraWeights(cameras)}
                        }

                        projectCameras={props.projectCameras}

                    />

                    <Message
                        error={true}
                        visible={props.errors.add_cameras}
                        header={props.errors.add_cameras}
                        content={getErrorMessage(props.errors, 'add_cameras')}
                    />
            </Form>)
    }

    const getButtons = (error, success) => {
        if (success) {
            return (
                <div>
                    <Button
                        loading={props.loading}
                        id='closeProjectModalButton'
                        onClick={onClose}
                        positive
                        primary
                    >Close</Button>
                </div>
            )
        } else {
            return (
                <div>
                    <Button
                        id='createProjectModalButton'
                        onClick={onConfirm}
                        loading={props.loading}
                        positive
                        icon='checkmark'
                        labelPosition='right'
                        content='Create'
                    />
                </div>
            )
        }
    }

    const getPreloader = () => {
        if (!isReady()) {
            return (
                <div>
                    <div className='animated-blank-line'></div>
                    <div className='animated-blank-line'></div>
                    <div className='animated-blank-line'></div>
                    <Grid columns='2'>
                        <Grid.Row>
                            <Grid.Column>
                                <div className='animated-blank-block-200'></div>
                            </Grid.Column>
                            <Grid.Column>
                                <div className='animated-blank-block-200'></div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <div className='animated-blank-line'></div>
                    <Grid columns='2'>
                        <Grid.Row className='no-padding-bottom'>
                            <Grid.Column>
                                <div className='animated-blank-line'></div>
                            </Grid.Column>
                            <Grid.Column>
                                <div className='animated-blank-line'></div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <div className='animated-blank-line'></div>
                            </Grid.Column>
                            <Grid.Column>
                                <div className='animated-blank-line'></div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <div className='animated-blank-line'></div>
                </div>
            )
        }
        return null
    }

    const isReady = () => {
        return props.statuses.length > 0
    }

    const getMessage = () => {
        if (!isReady()) {
            return null
        }
        return (
            <div>
            {getContent(props.error, props.success, props.loading, props.errors)}
            </div>
        )
    }

    return (
        <Modal
            size={"large"}
            open={props.open}
            onOpen={onOpen}
            onMount={onOpen}
            onClose={onClose}
            closeIcon
        >
            <Modal.Header>Create a project</Modal.Header>
            <Modal.Content>
                {getPreloader()}
                {getMessage()}
                <div className='modalButtons'>
                    {getButtons(props.error, props.success)}
                </div>
            </Modal.Content>
        </Modal>
    )
}

CreateProjectModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    error: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    statuses: PropTypes.array.isRequired,
    success: PropTypes.bool.isRequired,
    details: PropTypes.object.isRequired,
    clients: PropTypes.array.isRequired,
    clientId: PropTypes.number,
    availableCameras: PropTypes.array.isRequired,
    deployedCameras: PropTypes.array.isRequired,
    projectCameras: PropTypes.object.isRequired,
    onAddCameras: PropTypes.func.isRequired,
    onRemoveCameras: PropTypes.func.isRequired,
    onCameraWeights: PropTypes.func.isRequired
}

export default CreateProjectModal
