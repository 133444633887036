import React, { Component } from 'react'
import {
    Button,
    Icon,
    Label,
    Modal,
    Message,
    Form,
    Grid,
    Segment,
    Tab,
    Table
} from 'semantic-ui-react'
import { getErrorMessage } from './../../util/errors'
import { isPermitted } from '../../util/acl'
import PropTypes from 'prop-types'
import EmbedCode from '../EmbedCode'
import UpdateEmbedForm from './UpdateEmbed'
import ViewEmbedModal from './ViewEmbed'
import UpdateBIMModal from './UpdateBIM'

class UpdateCameraModal extends Component {

    //@TODO - This needs to be refactored and expanded to multiple components
    // With the goal of making this easier to read.

    constructor(props) {
        super(props)
        this.state = this.getInitialState()
    }

    componentDidMount() {
        this.props.embedActions.reset()
        this.props.processingActions.getProcessingTypes()
    }


    componentDidUpdate(prevProps, prevState) {
        if (this.props !== prevProps) {
            let newBlurStrength = null

            if (this.state.blur_strength !== null) {
                newBlurStrength = this.state.blur_strength
            } else {
                if (this.props.camera.processing && this.props.camera.processing.blur) {
                    newBlurStrength = this.props.camera.processing.blur.strength
                }
            }

            if (this.props.loading === false) {
                this.setState((prevState, props) => {
                    return {
                        pot_id: props.camera.pot_id,
                        status: props.camera.status,
                        type: props.camera.type,
                        latitude: props.camera.latitude,
                        longitude: props.camera.longitude,
                        pot_version:  props.camera.pot_version,
                        custom_name: props.camera.custom_name,
                        processing: props.camera.processing ?
                            props.camera.processing : {},
                        blur_strength: newBlurStrength,
                        disabledButtons: prevState.disabledButtons,
                        video_storage_location: props.camera.video_storage_location,
                        power_source: props.camera.power_source
                    }
                })
            }

            if (!prevProps.processing.blur.set.success
                && this.props.processing.blur.set.success) {
                // Successfully enabled blur
                this.onCloseProcessModals()
            }
    
            if (!prevProps.processing.blur.remove.success
                && this.props.processing.blur.remove.success) {
                // Successfully enabled blur
                this.onCloseProcessModals()
            }

            if (!prevProps.embeds.create.success
                && this.props.embeds.create.success) {
                this.onCloseEmbedModals()
            }

            if (!prevProps.embeds.update.success
                && this.props.embeds.update.success) {
                this.onCloseEmbedModals()
            }

            if (!prevProps.embeds.delete.success
                && this.props.embeds.delete.success) {
                this.onCloseEmbedModals()
            }
    
            if (this.props.cameras.update.success === true && prevProps.cameras.update.success === false) {
                // Let's trigger the onClose for UpdateCamera modal
                this.onClose()
            }
        }
    }

    getHeader = (error, success) => {
        if (error) {
            return <h3 id='successStatus'>Error</h3>
        } else if (success) {
            return <h3 id='successStatus'>Success!</h3>
        } else {
            return ""
        }
    }

    getContent = (error, success, loading, errors) => {

        // We might have a server error, or just regular errors
        if (error) {
            // return this.renderForm()
            // getErrorMessage()
            return null;

        } else if (success) {
            return <p>Successfully updated camera</p>
        } else if (loading) {
            return <p>Processing...</p>
        } else {
            return null;
        }
    }

    getInitialState = () => {
        return {
            pot_id: null,
            status:'Available',
            type: 'Linux',
            latitude: null,
            longitude: null,
            pot_version: null,
            custom_name: null,
            processing: {},
            blur_strength: null,
            blur_mask: null,
            show_edit_process_modal: false,
            show_remove_process_modal: false,
            show_derigged_modal: false,
            disabledButtons: false,
            show_edit_embed_modal: false,
            edit_embed_index: null,
            show_remove_embed_modal: false,
            remove_embed_id: null,
            show_view_embed_modal: false,
            view_embed_index: null,
            show_remove_bim_modal: false,
            show_edit_bim_modal: false,
            video_storage_location: 'default',
            power_source: null,
            process_type_handle: null,
        }
    }

    getGeneralSettingsForm = (loaded) => {
        if (this.state.disabledButtons) {
            this.setState({disabledButtons: false })
        }
        if (!loaded) {
            return <div>
                <div className='animated-blank-line'></div>
                <div className='animated-blank-block-300'></div>
                <div className='animated-blank-line'></div>
            </div>
        }

        const statuses = this.props.statuses.map((status) => {
            return {
                text: status,
                value: status
            }
        })
        const types = this.props.types.map((type) => {
            return {
                text: type,
                value: type
            }
        })

        const potVersions = [{ text: 1, value: 1},{ text: 2, value: 2},{ text: 3, value: 3},{ text: 4, value: 4}]

        const isLP4User = (this.props.user && this.props.user.roles_id === 5)? true : false;

        return (
            <Form size='large'>
                {!isLP4User && <>
                    <Form.Field>
                        <label>Pot ID</label>
                        <Form.Input
                            id='potId'
                            fluid
                            placeholder='Pot Id'
                            onChange={this.onPotIdChange}
                            error={false}
                            defaultValue={this.props.camera.pot_id}
                        />
                    </Form.Field>
                    <Message
                        error={true}
                        visible={this.props.errors.pot_id !== undefined}
                        header={''}
                        content={getErrorMessage(this.props.errors, 'pot_id')}
                    />
                    <Form.Field>
                        <label>Status</label>
                        <Form.Select
                            id='status'
                            fluid
                            placeholder='Status'
                            onChange={this.onStatusChange}
                            error={false}
                            options={statuses}
                            // defaultValue={this.props.camera.status}
                            value={(this.state.status) ? this.state.status : ''}
                        />
                    </Form.Field>
                    <Message
                        error={true}
                        visible={this.props.errors.status !== undefined}
                        header={''}
                        content={getErrorMessage(this.props.errors, 'status')}
                    />

                    <Form.Field>
                        <label>Type</label>
                        <Form.Select
                            id='type'
                            fluid
                            placeholder='Type'
                            onChange={this.onTypeChange}
                            error={false}
                            options={types}
                            defaultValue={this.props.camera.type}
                        />
                    </Form.Field>
                    <Message
                        error={true}
                        visible={this.props.errors.type !== undefined}
                        header={this.props.errors.type}
                        content={getErrorMessage(this.props.errors, 'type')}
                    />

                    <Form.Field disabled={this.state.type === 'Virtual'}>
                        <label>Pot Version</label>
                        <Form.Select
                            id='potVersion'
                            fluid
                            placeholder='Pot Version'
                            onChange={this.onPotVersionChange}
                            error={false}
                            options={potVersions}
                            defaultValue={this.props.camera.pot_version}
                        />
                    </Form.Field>
                    <Message
                        error={true}
                        visible={this.props.errors.type !== undefined}
                        header={this.props.errors.type}
                        content={getErrorMessage(this.props.errors, 'pot_version')}
                    />
                </>}
                <Form.Field>
                    <label>Custom Camera Name</label>
                    <Form.Input
                        id='customName'
                        fluid
                        placeholder='Custom Name'
                        onChange={this.onCustomNameChange}
                        error={false}
                        defaultValue={this.props.camera.custom_name}
                    />
                </Form.Field>
                <Message
                    error={true}
                    visible={this.props.errors.custom_name !== undefined}
                    header={''}
                    content={getErrorMessage(this.props.errors, 'custom_name')}
                />
                <Grid columns='2'>
                    <Grid.Row className='no-padding-bottom'>
                        <Grid.Column>
                            <Form.Field>
                                <label>Latitude</label>
                                <Form.Input
                                    id='latitude'
                                    fluid
                                    type="number"
                                    placeholder='Latitude'
                                    onChange={this.onLatitudeChange}
                                    error={this.props.errors.latitude}
                                    defaultValue={this.props.camera.latitude}
                                />
                            </Form.Field>
                            <Message
                                error={true}
                                visible={this.props.errors.latitude !== undefined}
                                header={this.props.errors.latitude}
                                content={getErrorMessage(this.props.errors, 'latitude')}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Form.Field>
                                <label>Longitude</label>
                                <Form.Input
                                    id='longitude'
                                    fluid
                                    type="number"
                                    placeholder='Longitude'
                                    onChange={this.onLongitudeChange}
                                    error={this.props.errors.longitude}
                                    defaultValue={this.props.camera.longitude}
                                />
                            </Form.Field>
                            <Message
                                error={true}
                                visible={this.props.errors.longitude !== undefined}
                                header={this.props.errors.longitude}
                                content={getErrorMessage(this.props.errors, 'longitude')}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Form.Field disabled={this.state.type === 'Virtual'}>
                    <label>Power Source</label>
                    <Form.Select
                        id='powerSource'
                        fluid
                        placeholder='Power Source'
                        onChange={this.onPowerSourceChange}
                        error={false}
                        options={[
                            { text: '', value: null},
                            { text: 'Solar', value: 'Solar'},
                            { text: 'Mains', value: 'Mains'},
                            { text: 'External Battery', value: 'ExternalBattery'}
                        ]}
                        defaultValue={this.props.camera.power_source}
                    />
                </Form.Field>
                <Message
                    error={true}
                    visible={this.props.errors.type !== undefined}
                    header={this.props.errors.type}
                    content={getErrorMessage(this.props.errors, 'power_source')}
                />
                {this.getDeriggedModal()}
            </Form>
        )
    }

    listProcessTypes = () => {
        let content = []
        let typeDisabled = false
        if (this.props.processing.processTypes) {
            for (const type of this.props.processing.processTypes.types) {
                if ((type.handle === 'blur' && this.state.processing.blur) ||
                (type.handle === 'detect_face_blur' && this.state.processing.detect_face_blur)) {
                    typeDisabled = true
                }
                content.push(<Button onClick={() => this.openEditProcessModal(type.handle)} color='green' disabled={typeDisabled}>
                    <Icon name='add' />Add {type.name}
                </Button>)

                typeDisabled = false
            }
        }

        return content
    }

    getGeneralProcessingPane = () => {
        if (this.state.disabledButtons) {
            this.setState({disabledButtons: false })
        }

        let blur = null
        let faceDetect = null
        //let blurActive = this.state.processing.find((blur) => blur.type === 'blur')
        if (this.state.processing.blur) {
            blur = <Segment basic>
                <h3>
                    Blur
                    <Label style={{marginLeft: '2em'}} as='span' basic color='green' size='small'>
                        Enabled
                    </Label>
                </h3>
                <Grid style={{paddingBottom:'2rem'}}>
                    <Grid.Row style={{paddingTop: 0, paddingBottom: 0}}>
                        <Grid.Column width={2}>
                            <b>Strength</b>
                        </Grid.Column>
                        <Grid.Column width={3}>
                            {this.state.processing.blur.strength}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{paddingTop: 0, paddingBottom: 0}}>
                        <Grid.Column width={2}>
                            <b>Mask</b>
                        </Grid.Column>
                        <Grid.Column width={3}>
                        <a
                            href={this.state.processing.blur.url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >{this.state.processing.blur.filename}</a>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Button onClick={() => {this.openEditProcessModal(this.state.process_type_handle)}} color='blue'>
                    <Icon name='setting' />Edit
                </Button>
                <Button onClick={()=> {this.openRemoveProcessModal(this.state.process_type_handle)}} color='red'>
                    <Icon name='remove' />Remove
                </Button>
            </Segment>
        }
        if (this.state.processing.hasOwnProperty('detect_face_blur')) {
            faceDetect = <Segment basic>
                Detect face blurring is enabled on this camera.<br /><br />
                <Button onClick={() => {this.openRemoveProcessModal('detect_face_blur')}} color='red'>
                    <Icon name='remove' />Remove
                </Button>
            </Segment>
        }
        
        if (Object.keys(this.state.processing).length === 0) {
            blur = <>
                <p>There are no active Processes on this camera.</p>
            </> 
        }
        const blurLoading = <div className="animated-blank-line" />

        return (
            <div>
                <div>
                    <h3>Processes</h3>
                    {(this.props.processing.blur.set.processing
                        || this.props.processing.blur.remove.processing)
                        ? blurLoading : blur}
                    {faceDetect}
                    <Segment basic>
                        {this.listProcessTypes()}
                    </Segment>
                </div>
                {this.getEditProcessModal()}
                {this.getRemoveProcessModal()}
            </div>
        )
    }

    getEditProcessModal = () => {
        let currentBlur = null
        if (this.props.camera.processing && this.props.camera.processing.blur) {
            currentBlur = this.props.camera.processing.blur.strength
        }

        return (
            <Modal size={"tiny"} open={this.state.show_edit_process_modal} onClose={this.onCloseProcessModals} closeIcon>
                <Modal.Header>Set Process</Modal.Header>
                <Modal.Content>
                    {(this.state.process_type_handle === 'detect_face_blur')?
                    <>
                        <p><strong>Warning</strong></p>
                        <p>Adding a Detect Face blur will incur additional charges.</p>
                        <p>Please only click submit if you are happy to pay these costs</p>
                    </>
                    
                    :
                        <Form>
                            <Form.Input
                                fluid
                                label='Strength (0 - 100)'
                                onChange={this.onBlurStrengthChange}
                                defaultValue={currentBlur}
                            />
                            <Message
                                error={true}
                                visible={this.props.processing.blur.set.errors.strength !== undefined}
                                header={this.props.processing.blur.set.errors.strength}
                                content={getErrorMessage(this.props.processing.blur.set.errors, 'strength')}
                            />
                            {(this.state.blur_mask == null) ||
                                (this.props.processing.blur.set.errors.mask !== undefined)
                                ?
                                (
                                    <Label
                                        as='label'
                                        htmlFor='upload-mask'
                                        color='blue'
                                        className='button'
                                    >
                                        Select mask file
                                    </Label>
                                ) : (
                                    <div>Filename: {this.state.blur_mask}</div>
                                )
                            }
                            <input
                                type='file'
                                id='upload-mask'
                                style={{display: 'none'}}
                                onChange={this.onBlurMaskFileChange}
                                ref={(input) => {this.maskFile = input}}
                            />
                            <Message
                                error={true}
                                visible={this.props.processing.blur.set.errors.mask !== undefined}
                                header={this.props.processing.blur.set.errors.mask}
                                content={getErrorMessage(this.props.processing.blur.set.errors, 'mask')}
                            />
                        </Form>
                    }
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={() => {this.onSetProcess(this.state.process_type_handle)}}
                        loading={this.props.processing.blur.set.processing}
                        positive
                    >Submit</Button>
                    <Button onClick={this.onCloseProcessModals} negative>Cancel</Button>
                </Modal.Actions>
            </Modal>
        )
    }

    getRemoveProcessModal = () => {
        return (
            <Modal size={"tiny"} open={this.state.show_remove_process_modal} onClose={this.onCloseProcessModals} closeIcon>
                <Modal.Header>Remove Process</Modal.Header>
                <Modal.Content>
                    Are you sure you want to remove processing from the camera?
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={() => {this.onRemoveProcess(this.state.process_type_handle)}}
                        loading={this.props.processing.blur.remove.processing}
                        positive
                    >Confirm</Button>
                    <Button onClick={this.onCloseProcessModals} negative>Cancel</Button>
                </Modal.Actions>
            </Modal>
        )
    }

    getCreateEmbedButton = () => {
        if (!isPermitted(this.props.user, 'post-embeds-embeds')) {
            return null
        }
        return (
            <Button
                color='blue'
                onClick={() => this.openEditEmbedModal(null)}
            >
                Create New Embed
            </Button>
        )
    }

    getViewEmbedButton = (index) => {
        if (!isPermitted(this.props.user, 'get-embeds-embeds')) {
            return null
        }
        return (
            <Button
                onClick={() => this.openViewEmbedModal(index)}
                color='brown'
                size='mini'
                compact
            >
                <Icon name='code' />Get Link
            </Button>
        )
    }

    getEditEmbedButton = (index) => {
        if (!isPermitted(this.props.user, 'put-embeds-embeds')) {
            return null
        }
        return (
            <Button
                onClick={() => this.openEditEmbedModal(index)}
                color='blue'
                size='mini'
                compact
            >
                <Icon name='setting' />Edit
            </Button>
        )
    }

    getDeleteEmbedButton = (id) => {
        if (!isPermitted(this.props.user, 'delete-embeds-embeds')) {
            return null
        }
        return (
            <Button
                onClick={() => this.openRemoveEmbedModal(id)}
                color='red'
                size='mini'
                compact
            >
                <Icon name='remove' />Delete
            </Button>
        )
    }

    getGeneralEmbedsPane = () => {
        if (this.props.embeds.list.loading) {
            return (
                <div>
                    <div className='animated-blank-block-300'></div>
                </div>
            )
        }
        if (this.props.embeds.list.embeds.length > 0) {
            return (
                <div>
                    <Table basic='very' celled collapsing>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>Format</Table.HeaderCell>
                            <Table.HeaderCell>Size</Table.HeaderCell>
                            <Table.HeaderCell>Quality</Table.HeaderCell>
                            <Table.HeaderCell>Timecode</Table.HeaderCell>
                            <Table.HeaderCell>Crop</Table.HeaderCell>
                            <Table.HeaderCell>Delay</Table.HeaderCell>
                            <Table.HeaderCell>Processed</Table.HeaderCell>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.props.embeds.list.embeds.map((embed, index) => {
                                const actionButtons = (
                                    <span>
                                        {this.getViewEmbedButton(index)}
                                        <br /><br />
                                        {this.getEditEmbedButton(index)}
                                        <br /><br />
                                        {this.getDeleteEmbedButton(embed.id)}
                                    </span>
                                )
                                return (
                                    <EmbedCode
                                        key={embed.id}
                                        {...embed}
                                        actionButtons={actionButtons}
                                    />)
                            })}
                        </Table.Body>
                    </Table>
                    {this.getCreateEmbedButton()}
                    {this.getEditEmbedModal()}
                    {this.getRemoveEmbedModal()}
                    {this.getViewEmbedModal()}
                </div>
            )
        } else {
            return (
                <div>
                    <p>There are currently no embeds for this camera</p>
                    {this.getCreateEmbedButton()}
                    {this.getEditEmbedModal()}
                </div>
            )
        }

    }

    getSettingsPane = () => {
        let locationOptions = [
            {key: 'vsl_1', value: 'default', text: 'Lobster Vision'},
            {key: 'vsl_2', value: 'ca', text: 'Lobster Vision (CA)'},
        ]
        return (
            <div>
                <Form>
                    <Form.Field>
                        <label>Video Storage Location</label>
                        <Form.Select
                            id='video_storage_location'
                            fluid
                            onChange={this.onVideoStorageLocationChange}
                            //error={false}
                            options={locationOptions}
                            // defaultValue={this.props.camera.status}
                            value={(this.state.video_storage_location) ? this.state.video_storage_location : 'default'}
                        />
                    </Form.Field>
                </Form>
            </div>
        )
    }

    getEditEmbedModal = () => {
        return <UpdateEmbedForm
            ref={(modal) => this.updateEmbedForm = modal}
            visible={this.state.show_edit_embed_modal}
            embeds={this.props.embeds}
            embedId={this.state.edit_embed_index}
            onSubmit={this.onSubmitEditEmbedModal}
            onClose={this.onCloseEmbedModals}
        />
    }

    getRemoveEmbedModal = () => {
        return (
            <Modal size={"tiny"} open={this.state.show_remove_embed_modal} onClose={this.onCloseEmbedModals} closeIcon>
                <Modal.Header>Remove Embed</Modal.Header>
                <Modal.Content>
                    Are you sure you want to remove this particaular embed?
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        loading={this.props.embeds.delete.processing}
                        onClick={this.onDeleteEmbed}
                        positive
                    >Confirm</Button>
                    <Button onClick={this.onCloseEmbedModals} negative>Cancel</Button>
                </Modal.Actions>
            </Modal>
        )
    }

    getViewEmbedModal = () => {
        const embed = this.props.embeds.list.embeds[this.state.view_embed_index]
        return <ViewEmbedModal
            embed={embed}
            visible={this.state.show_view_embed_modal}
            onClose={this.onCloseEmbedModals}
        />
    }

    getBIMPane = () => {
        const isBIM = this.props.camera.vc_type === 'BIM'
        if (isBIM) {
            return (
                <div>
                    <h3>BIM Model Camera</h3>
                    <Button
                        color='blue'
                        onClick={this.openEditBIMModal}
                    >Modify BIM config</Button>
                    <Button
                        color='red'
                        onClick={this.openRemoveBIMModal}
                    >Remove BIM config</Button>
                    {this.getEditBIMModal()}
                    {this.getRemoveBIMModal()}
                </div>
            )
        } else {
            return (
                <div>
                    <h3>BIM Model Camera</h3>
                    <p>This camera is not currently set to be a camera for a BIM model.</p>
                    <Button
                        color='blue'
                        onClick={this.openEditBIMModal}
                    >Set as Model Camera</Button>
                    {this.getEditBIMModal()}
                </div>
            )
        }

    }

    getEditBIMModal = () => {
        return <UpdateBIMModal
            ref={(modal) => this.updateBIMForm = modal}
            visible={this.state.show_edit_bim_modal}
            cameras={this.props.cameras}
            bimFinal3dImage={this.props.bimFinal3dImage}
            bimFinal3dImageActions={this.props.bimFinal3dImageActions}
            onSubmit={this.onSubmitEditBIMModal}
            onClose={this.onCloseBIMModals}
            />
    }

    getRemoveBIMModal = () => {
        return (
            <Modal size={"tiny"} open={this.state.show_remove_bim_modal} onClose={this.onCloseBIMModals} closeIcon>
                <Modal.Header>Remove BIM</Modal.Header>
                <Modal.Content>
                    Are you sure you want to remove the BIM settings from this camera?
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        loading={this.props.cameras.update.loading}
                        onClick={this.onDeleteBIM}
                        positive
                    >Confirm</Button>
                    <Button onClick={this.onCloseBIMModals} negative>Cancel</Button>
                </Modal.Actions>
            </Modal>
        )
    }

    onClose = () => {
        this.setState(this.getInitialState(), () => {
            this.props.onClose()
        })
    }

    onCloseDeriggedModal = () => {
        this.setState({show_derigged_modal: false})
    }

    onClick = () => {
        this.props.onClick(this.props.cameras_id, this.state)
    }

    onPotIdChange = (event, {value}) => {
        this.setState({pot_id: value})
    }

    onCustomNameChange = (event, {value}) => {
        this.setState({custom_name: value})
    }

    onStatusChange = (event, {value}) => {
        if (value === 'De-rigged') {
            this.setState({status: value, show_derigged_modal: true, status_old: this.state.status})
        } else {
            this.setState({status: value})
        }
    }

    onTypeChange = (event, {value}) => {
        if (value === 'Virtual') {
            this.setState({type: value, pot_version: null})
        } else {
            this.setState({type: value})
        }
    }

    onLatitudeChange = (event, {value}) => {
        this.setState({latitude: value})
    }

    onLongitudeChange = (event, {value}) => {
        this.setState({longitude: value})
    }

    onPotVersionChange = (event, {value}) => {
        this.setState({pot_version: value})
    }

    onPowerSourceChange = (event, {value}) => {
        this.setState({power_source: value})
    }

    onVideoStorageLocationChange = (event, {value}) => {
        this.setState({video_storage_location: value})
    }

    isReady = () => {
        return this.props.camera.pot_id
    }

    onSetProcess = (typeHandle) => {
        const formData = new FormData()
        if (typeHandle !== 'detect_face_blur') {
            formData.append('strength', parseInt(this.state.blur_strength, 10))
            if (this.maskFile.files[0]) {
                formData.append('mask', this.maskFile.files[0])
                let reader = new FileReader();
                // let rawData;
                // reader.onload = (e) => {
                //     rawData = reader.result
                // }
                reader.onloadend = () => {
                    this.props.setProcess(this.props.cameras_id, formData, typeHandle)
                }
                reader.readAsBinaryString(this.maskFile.files[0]);
            } else {
                // Only update blur strength
                this.props.setProcess(this.props.cameras_id, formData, typeHandle)
            }
        } else {
            this.props.setProcess(this.props.cameras_id, formData, typeHandle)
        }
    }

    onRemoveProcess = (typeHandle) => {
        this.props.removeProcess(this.props.cameras_id, typeHandle)
        //this.onCloseProcessModals()
    }

    onCloseProcessModals = () => {
        this.props.processingActions.reset()
        this.setState({
            blur_mask: null,
            show_edit_process_modal: false,
            show_remove_process_modal: false,
            process_type_handle: null
        })
    }

    openEditProcessModal = (typeHandle) => {
        this.setState({
            show_edit_process_modal: true,
            process_type_handle: typeHandle
        })
    }

    openRemoveProcessModal = (typeHandle) => {
        this.setState({
            show_remove_process_modal: true,
            process_type_handle: typeHandle
        })
    }

    onBlurStrengthChange = (event, {value}) => {
        this.setState({blur_strength: value})
    }

    onBlurMaskFileChange = (event) => {
        this.props.processingActions.reset()
        if (this.maskFile && this.maskFile.files[0]) {
            this.setState({blur_mask: this.maskFile.files[0].name})
        }
    }

    onCloseEmbedModals = () => {
        this.props.embedActions.createReset()
        this.props.embedActions.updateReset()
        this.setState({
            show_edit_embed_modal: false,
            show_remove_embed_modal: false,
            show_view_embed_modal: false,
            edit_embed_index: null
        })
    }

    openViewEmbedModal = (index) => {
        this.setState({
            show_view_embed_modal: true,
            view_embed_index: index
        })
    }

    openEditEmbedModal = (index) => {
        this.setState({
            show_edit_embed_modal: true,
            edit_embed_index: index
        })
    }

    openRemoveEmbedModal = (embedId) => {
        this.setState({
            show_remove_embed_modal: true,
            remove_embed_id: embedId
        })
    }

    onSubmitEditEmbedModal = () => {
        const data = this.updateEmbedForm.getData()
        if (data.id === null) {
            // No ID set, add new
            this.props.embedActions.createEmbed(this.props.cameras_id, data)
        } else {
            const embedId = data.id
            this.props.embedActions.updateEmbed(this.props.cameras_id, embedId, data)
        }
    }

    onDeleteEmbed = () => {
        const embedId = this.state.remove_embed_id
        this.props.embedActions.deleteEmbed(this.props.cameras_id, embedId)
    }

    onCloseBIMModals = () => {
        this.setState({
            show_edit_bim_modal: false,
            show_remove_bim_modal: false
        })
    }

    openEditBIMModal = () => {
        this.setState({show_edit_bim_modal: true})
    }

    openRemoveBIMModal = () => {
        this.setState({show_remove_bim_modal: true})
    }

    onSubmitEditBIMModal = () => {
        let final3dImage = {}
        if (this.props.bimFinal3dImage && this.props.bimFinal3dImage.filename) {
            final3dImage = {
                filename: this.props.bimFinal3dImage.filename,
                width: this.props.bimFinal3dImage.width,
                height: this.props.bimFinal3dImage.height
            }
        }
        let data = this.updateBIMForm.getData(final3dImage)
        let cameraDetails = Object.assign(
            {},
            this.props.cameras.camera.details,
            {
                vc_parameters: data,
                vc_type: 'BIM'
            }
        )
        this.props.camerasActions.update(this.props.cameras_id, cameraDetails)
    }

    onDeleteBIM = () => {
        let cameraDetails = Object.assign(
            {},
            this.props.cameras.camera.details,
            {
                vc_parameters: null,
                vc_type: 'Other'
            }
        )
        this.props.camerasActions.update(this.props.cameras_id, cameraDetails)
    }

    getMessage = () => {
        if (!this.isReady()) {
            return null
        }
        return (this.props.success || this.props.error) ?
            <Message
                error={this.props.error}
                success={this.props.success}
                visible={false}
                header={this.getHeader(this.props.error, this.props.success)}
                content={this.getContent(this.props.error, this.props.success, this.props.loading, this.props.errors)}
            /> : null
    }

    getButtons = () => {

        // Disable the buttons if inside tags tab
        const disabled = this.state.disabledButtons || this.isReady() === false

        return (
            <div>
                {this.getUpdateButton(disabled)}
            </div>
        )
    }

    getUpdateButton = (disabled) => {
        return <Button disabled={disabled} loading={this.props.loading} id='updateCameraButton' onClick={this.onClick} positive>Update</Button>
    }

    getCloseOrCancelButton = (disabled) => {
        return (this.props.success) ?
            <Button disabled={disabled} onClick={this.onClose} primary>Close</Button>
            : <Button disabled={disabled} onClick={this.onClose} negative>Cancel</Button>
    }

    onDeriggedModalConfirmButtonClick = () => {
        this.setState({show_derigged_modal: false})
    }

    onDeriggedModalCloseButtonClick = () => {
        this.setState({show_derigged_modal: false, status: this.state.status_old})
    }

    getDeriggedModalConfirmButton = () => {
        return <Button id='deriggedModalConfirmButton' onClick={this.onDeriggedModalConfirmButtonClick} positive>Confirm</Button>
    }

    getDeriggedModalCloseOrCancelButton = () => {
        return <Button onClick={this.onDeriggedModalCloseButtonClick} negative>Cancel</Button>
    }

    getPreloader = () => {
        if (!this.isReady()) {
            return (
                <div>
                    <div className='animated-blank-line'></div>
                    <div className='animated-blank-block-300'></div>
                    <div className='animated-blank-line'></div>
                </div>
            )
        }
        return null
    }

    getDeriggedModal = () => {
        return (
            <Modal size={"tiny"} open={this.state.show_derigged_modal} onClose={this.onCloseDeriggedModal} closeIcon>
                <Modal.Header>De-rig camera</Modal.Header>
                <Modal.Content>
                    <h4>Are you sure you want to set the camera as de-rigged?</h4>
                    <p>Doing so will remove the camera from DNS and monitoring, which is not reversible.  If in any doubt, contact your line manager for assistance.</p>
                </Modal.Content>
                <Modal.Actions>
                    {this.getDeriggedModalCloseOrCancelButton()}
                    {this.getDeriggedModalConfirmButton()}
                </Modal.Actions>
                <br/>
            </Modal>
        )
    }

    isTagBeingProcesed = (item) => {
        return (this.props.tags.associate_camera_with_tag.processing &&
            this.props.tags.associate_camera_with_tag.tagId === item.id) ||
        (this.props.tags.unassociate_camera_with_tag.processing &&
            this.props.tags.unassociate_camera_with_tag.tagId === item.id)
    }

    isTagAssociated = (cameraTags, item) => {
        if (!cameraTags) {
            return false;
        }
        return (cameraTags.tags.findIndex((t) => {
            return t.id === item.id
        }) > -1)
    }

    onToggleCameraTagAssociation = (tagId, clientId, cameraId, isAssociated, tag) => {
        if (!isAssociated) {
            this.props.tagsActions.associateCameraWithTag(clientId, cameraId, tagId, tag)
                .then(() => {
                    // Fetch camera tags again...
                    this.props.camerasActions.fetchCameraTags(clientId, cameraId)
                })
        } else {
            this.props.tagsActions.unassociateCameraWithTag(clientId, cameraId, tagId)
                .then(() => {
                    this.props.camerasActions.fetchCameraTags(clientId, cameraId)
                })
        }
    }

    getTagsContent = (loaded) => {

        if (!loaded) {
            return <div>
                <div className='animated-blank-block-300'></div>
            </div>
        }

        const cameraTags = this.props.cameras.camera_tags.cameras.find((i) => {
            return i.cameras_id === this.props.camera.id
        })

        return (<div>
            <p>By applying one or more tags to a camera, you can make it easier to search for it later on.</p>
            <p>These changes will be persisted immediately.</p>
            {this.props.tags.list.tags.tags.map((item, idx) => {
                const color = item.color
                const isAssociated = this.isTagAssociated(cameraTags, item)
                const associatedClass = (isAssociated) ? '' : 'basic'
                const loading = this.isTagBeingProcesed(item)
                return (
                    <Button
                        key={idx}
                        loading={loading}
                        onClick={() => {
                            const clientId = this.props.clients_id
                            const cameraId = this.props.camera.id
                            const tagId = item.id
                            const tag = Object.assign({}, item)
                            this.onToggleCameraTagAssociation(tagId, clientId, cameraId, isAssociated, tag)
                        }}
                        color={color}
                        className={`tags ${associatedClass}`}>{item.name}</Button>
                )
            })}

        </div>)

    }

    onTabChange = (event, data) => {
        if (data.activeIndex === 2) {
            // Embeds Selected, load data
            this.props.embedActions.fetchAllEmbeds(this.props.cameras_id)

        }
    }

    render = () => {
        let panes = []
        // Removes panes for LP4 users
        if (this.props.user && this.props.user.roles_id === 5) {
            panes = [
                { menuItem: 'General', render: () =>
                        <Tab.Pane>
                            {this.getGeneralSettingsForm(this.props.camera.id)}

                            <div className='modalButtons'>
                                {this.getButtons()}
                            </div>
                        </Tab.Pane>
                }
            ]
        } else if (!isPermitted(this.props.user, 'post-tags-associatecamerawithtag'))  {
            panes = [
                { menuItem: 'General', render: () =>
                        <Tab.Pane>
                            {this.getGeneralSettingsForm(this.props.camera.id)}
                        </Tab.Pane>
                },
                { menuItem: 'Processing', render: () =>
                        <Tab.Pane>
                            {this.getGeneralProcessingPane()}
                        </Tab.Pane>
                },
                { menuItem: 'Embeds', render: () =>
                        <Tab.Pane>
                            {this.getGeneralEmbedsPane()}
                        </Tab.Pane>
                },
                { menuItem: 'Settings', render: () =>
                        <Tab.Pane>
                            {this.getSettingsPane()}
                        </Tab.Pane>
                }
            ]
        } else {
            panes = [
                { menuItem: 'General', render: () =>
                        <Tab.Pane>
                            {this.getGeneralSettingsForm(this.props.camera.id)}

                            <div className='modalButtons'>
                                {this.getButtons()}
                            </div>
                        </Tab.Pane>
                },
                { menuItem: 'Processing', render: () =>
                        <Tab.Pane>
                            {this.getGeneralProcessingPane()}
                        </Tab.Pane>
                },
                { menuItem: 'Embeds', render: () =>
                        <Tab.Pane>
                            {this.getGeneralEmbedsPane()}
                        </Tab.Pane>
                },
                { menuItem: 'Tags', render: () =>
                        <Tab.Pane>
                            {this.getTagsContent(this.isReady())}
                        </Tab.Pane>
                },
                { menuItem: 'Settings', render: () =>
                        <Tab.Pane>
                            {this.getSettingsPane()}
                            <div className='modalButtons'>
                                {this.getButtons()}
                            </div>
                        </Tab.Pane>
                }

            ]

        }

        if (this.props.camera.type === 'Virtual') {
            panes.push({ menuItem: 'BIM', render: () =>
                    <Tab.Pane>
                        {this.getBIMPane()}
                    </Tab.Pane>
            })
        }

        return (
            <Modal open={this.props.open} onClose={this.onClose} closeIcon>
                <Modal.Header>Camera Settings</Modal.Header>
                <Modal.Content>
                    {this.getMessage()}
                    <Tab panes={panes} onTabChange={this.onTabChange}/>
                </Modal.Content>
            </Modal>
        )
    }

}

UpdateCameraModal.propTypes = {
    open: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    statuses: PropTypes.array.isRequired,
    types: PropTypes.array.isRequired,
    camera: PropTypes.object.isRequired,
    cameras_id: PropTypes.number,
    // Adding for tags functionality
    tagsActions: PropTypes.object.isRequired,
    tags: PropTypes.object.isRequired,
    camerasActions: PropTypes.object.isRequired,
    cameras: PropTypes.object.isRequired,
    clients_id: PropTypes.number.isRequired,
    user: PropTypes.object.isRequired,
    bimFinal3dImage: PropTypes.object,
    bimFinal3dImageActions: PropTypes.object.isRequired
}

export default UpdateCameraModal
