import { combineReducers } from 'redux';
import auth from './authReducer';
import security from './securityReducer'
import users from './usersReducer'
import header from './headerReducer'
import footer from './footerReducer'
import password from './passwordReducer'
import addUser from './addUserReducer'
import editUser from './editUserReducer'
import addClient from './addClientReducer'
import clients from './clientsReducer'
import captcha from './captchaReducer'
import editClient from './editClientReducer'
import viewClient from './viewClientReducer'
import bulkUsers from './bulkUsersReducer'
import messenger from './messengerReducer'
import log from './logReducer'
import projects from './projectsReducer'
import addProject from './addProjectReducer'
import updateProject from './updateProjectReducer'
import cameras from './camerasReducer'
import ucpa from './userCameraProjectAssociationReducer'
import cameraControl from './cameraControlReducer'
import cameraControlV4 from './cameraControlV4Reducer'
import schedule from './scheduleReducer'
import roles from './rolesReducer'
import tags from './tagsReducer'
import logo from './logoReducer'
import processing from './processingReducer'
import embed from './embedReducer'
import fileList from './fileListReducer'
import drop from './dropReducer'
import uploadQueue from './uploadQueueReducer'
import potAssign from './potAssignReducer'
import bimFinal3dImage from './bim3dImageReducer'
import cameraUpload from './cameraUploadReducer';
import videoEdits from './videoEditsReducer';
import subscriptions from './subscriptionsReducer';

const rootReducer = combineReducers({
    auth,
    security,
    users,
    header,
    footer,
    cameraUpload,
    addUser,
    editUser,
    password,
    addClient,
    captcha,
    editClient,
    viewClient,
    clients,
    bulkUsers,
    messenger,
    log,
    projects,
    addProject,
    updateProject,
    cameras,
    ucpa,
    cameraControl,
    cameraControlV4,
    schedule,
    roles,
    tags,
    logo,
    processing,
    embed,
    fileList,
    drop,
    uploadQueue,
    potAssign,
    bimFinal3dImage,
    videoEdits,
    subscriptions
});

export default rootReducer;
