import { useRef, useState } from 'react'
import { Button, Form, Message, Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha';
import { RECAPTCHA_SITE_KEY } from '../../config'

const userCredentials = (props) => {
    const [loginDetails, setLoginDetails] = useState({
        username: null,
        password: null
    })
    const recaptcha = useRef();
    //let username, password = null

    const locked = (props.auth.errors.message &&
        props.auth.errors.message.includes("Your account is locked."))
    const content = (locked) ? "Try again in 5 minutes" : 'Please ensure you are providing valid credentials'
    
    const onUsernameChange = (event) => {
        //username = event.target.value
        setLoginDetails((prevState) => {
            return {
                password: prevState.password,
                username: event.target.value,
            }
        })
    }

    const onPasswordChange = (event) => {
        // password = event.target.value
        setLoginDetails((prevState) => {
            return {
                username: prevState.username,
                password: event.target.value,
            }
        })
    }

    const onRecaptchaChange = (reCaptureResponse) => {
        props.captchaRecaptchaRequest(reCaptureResponse)
    }

    const onClick = () => {
        props.loginRequest(props.history, loginDetails.username, loginDetails.password)
    }

    const getCaptcha = () => {

        if (props.captcha.isHuman === false && props.auth.attempts > 3) {
            return (
                <div>
                    <br /><br />
                    <p>Please confirm you are human before trying to login again</p>
                    <ReCAPTCHA
                    ref={recaptcha}
                    sitekey={RECAPTCHA_SITE_KEY}
                    onChange={onRecaptchaChange} />
                </div>
            )
        } else {
            return null
        }
    }

    return (
        <div className="step">
            <Form size='large'>
                <Message
                    id="message"
                    error={true}
                    visible={props.auth.errors.message}
                    header={props.auth.errors.message}
                    content={content}
                />
                <Segment className='user-credentials__segment'>
                    <Form.Input
                        disabled={props.captcha.isHuman === false && props.auth.attempts > 3}
                        id="username"
                        fluid
                        icon='user'
                        iconPosition='left'
                        placeholder='Username/E-mail Address'
                        error={(props.auth.errors.username)? true : false}
                        onChange={onUsernameChange}
                        defaultValue={loginDetails.username}
                    />
                    <Form.Input
                        disabled={props.captcha.isHuman === false && props.auth.attempts > 3}
                        id="password"
                        fluid
                        icon='lock'
                        iconPosition='left'
                        placeholder='Password'
                        type='password'
                        error={(props.auth.errors.password)? true : false}
                        onChange={onPasswordChange}
                        defaultValue={loginDetails.password}
                    />
                    <Button
                        id="loginBtn"
                        loading={props.auth.loading}
                        onClick={onClick}
                        color='teal'
                        disabled={props.captcha.isHuman === false && props.auth.attempts > 3}
                        fluid size='large'>Login</Button>
                </Segment>
                <Segment style={{textAlign: 'center'}}>
                    <Link to="/resetPassword">Forgot Password</Link>
                </Segment>
                {getCaptcha()}
            </Form>
        </div>
    )
}

export default userCredentials