import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Segment } from 'semantic-ui-react'

class CameraStatusV4 extends Component {

    /*
    const wifiState = this.props.monitoring_data.wifi.state
    const wifiSignalStrength = this.props.monitoring_data.wifi.signal_strength
    {wifiState && <p><span className='key'>Wifi:</span> <span className='value'>{wifiState}</span></p>}
                    {wifiState !== 'down' && wifiSignalStrength && <p><span className='key'>Wifi Signal Strength:</span> <span className='value'>
                    {wifiSignalStrength} %</span></p>}

    const chargerFault = this.props.monitoring_data.charger.fault ? 'true' : 'false'
    {chargerFault && <p><span className='key'>Charger Fault:</span> <span className='value'>{chargerFault}</span></p>}

    const coreTemperature = this.props.monitoring_data.sensors.temperature.arm_cpu
    {coreTemperature && <p><span className='key'>Core Temperature:</span> <span className='value'>{coreTemperature} ℃</span></p>}

    const upTime = this.props.monitoring_data.system.uptime
    {upTime && <p><span className='key'>Uptime:</span> <span className='value'>{upTime}</span></p>}

    const voltageInput = this.props.monitoring_data.sensors &&
                this.props.monitoring_data.sensors.voltage &&
                this.props.monitoring_data.sensors.voltage.input
    {voltageInput && <p><span className='key'>Input Battery Voltage:</span> <span className='value'>{voltageInput} V</span></p>}

    const currentInput = this.props.monitoring_data.sensors &&
                this.props.monitoring_data.sensors.current && this.props.monitoring_data.sensors.current.input &&
                this.props.monitoring_data.sensors.current.input * 1000
    {currentInput && <p><span className='key'>Input Current:</span> <span className='value'>{currentInput} mA</span></p>}
                    {currentCharger && <p><span className='key'>Charger Current:</span> <span className='value'>{currentCharger} mA</span></p>}

const currentCharger = this.props.monitoring_data.sensors &&
                this.props.monitoring_data.sensors.current && this.props.monitoring_data.sensors.current.charger &&
                this.props.monitoring_data.sensors.current.charger * 1000

    {voltageExternal !== 0 && <p><span className='key'>External Battery Voltage:</span> <span className='value'>{voltageExternal} V</span></p>}
    const voltageExternal = this.props.monitoring_data.sensors &&
                this.props.monitoring_data.sensors.voltage &&
                this.props.monitoring_data.sensors.voltage.external_battery

    {currentSystem && <p><span className='key'>System Current:</span> <span className='value'>{currentSystem} mA</span></p>}
    const currentSystem = this.props.monitoring_data.sensors &&
                this.props.monitoring_data.sensors.current && this.props.monitoring_data.sensors.current.system &&
                this.props.monitoring_data.sensors.current.system * 1000
    */

    render() {
        let details

        if (this.props.loading) {
            details = (
                <div className="animated-blank-line" />
            )
        } else if (this.props.monitoring_data) {
            const modemSignalStrength = this.props.monitoring_data.modem ? this.props.monitoring_data.modem.signal_strength : '--'
            const operatorCode = this.props.monitoring_data.modem ? this.props.monitoring_data.modem.operator_code_string : 'Unknown'
            const ambientTemperature = this.props.monitoring_data.sensors ? this.props.monitoring_data.sensors.temperature.am2320 : '--'
            const ambientHumidity = this.props.monitoring_data.sensors ? this.props.monitoring_data.sensors.humidity.am2320 : '--'
            const internalBattery = this.props.monitoring_data.battery ? this.props.monitoring_data.battery.internal_battery : '--'

            details =  (
                <div>
                    {this.props.state && <p><span className='key'>State:</span> <span className='value'>{this.props.state}</span></p>}
                    {modemSignalStrength && <p><span className='key'>Modem Signal Strength:</span> <span className='value'>{modemSignalStrength} %</span></p>}
                    {operatorCode && <p><span className="key">Modem Operator Code:</span> <span className="value">{operatorCode}</span></p>}
                    {ambientTemperature && <p><span className='key'>Temperature:</span> <span className='value'>{ambientTemperature} ℃</span></p>}
                    {ambientHumidity && <p><span className='key'>Humidity:</span> <span className='value'>{ambientHumidity} %</span></p>}
                    {internalBattery && <p><span className='key'>Battery:</span> <span className='value'>{internalBattery}</span></p>}
                </div>
            )
        } // else {
        //     details = (
        //         <div>No information found</div>
        //     )
        // }

        if (details) {
            return (
                <Segment>
                    <h3>{this.props.cameraName} Info</h3>
                    {details}
                </Segment>
            )
        }
        
    }

}

CameraStatusV4.propTypes = {
    loading: PropTypes.bool.isRequired,
    details: PropTypes.object
}

export default CameraStatusV4
