import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Dropdown, Input, Grid } from 'semantic-ui-react'
class CameraSettings extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    onChangeImageFormat = (format) => {
        const properties = {
            image_format: format
        }
        this.props.cameraControlActions.updateProperties(this.props.cameras_id, properties).then(() => {
            // After updating properties, let's also refresh what we have locally
            this.props.cameraControlActions.fetchProperties(this.props.cameras_id)
        })
    }

    onChangeImageSize = (imageSize) => {
        const properties = {
            image_size: imageSize
        }
        this.props.cameraControlActions.updateProperties(this.props.cameras_id, properties).then(() => {
            // After updating properties, let's also refresh what we have locally
            this.props.cameraControlActions.fetchProperties(this.props.cameras_id)
        })
    }

    onChangeExposureCompensation = (exposureCompensation) => {
        const properties = {
            exposure_compensation: exposureCompensation
        }
        this.props.cameraControlActions.updateProperties(this.props.cameras_id, properties).then(() => {
            // After updating properties, let's also refresh what we have locally
            this.props.cameraControlActions.fetchProperties(this.props.cameras_id)
        })
    }

    onChangeAperture = (aperture) => {
        const properties = {
            aperture: aperture
        }
        this.props.cameraControlActions.updateProperties(this.props.cameras_id, properties).then(() => {
            // After updating properties, let's also refresh what we have locally
            this.props.cameraControlActions.fetchProperties(this.props.cameras_id)
        })
    }

    onChangeIso = (iso) => {
        const properties = {
            iso: iso
        }
        this.props.cameraControlActions.updateProperties(this.props.cameras_id, properties).then(() => {
            // After updating properties, let's also refresh what we have locally
            this.props.cameraControlActions.fetchProperties(this.props.cameras_id)
        })
    }

    onChangeMeteringMode = (meteringMode) => {
        const properties = {
            metering_mode: meteringMode
        }
        this.props.cameraControlActions.updateProperties(this.props.cameras_id, properties).then(() => {
            // After updating properties, let's also refresh what we have locally
            this.props.cameraControlActions.fetchProperties(this.props.cameras_id)
        })
    }

    onChangeWhiteBalance = (whiteBalance) => {
        const properties = {
            white_balance: whiteBalance
        }
        this.props.cameraControlActions.updateProperties(this.props.cameras_id, properties).then(() => {
            // After updating properties, let's also refresh what we have locally
            this.props.cameraControlActions.fetchProperties(this.props.cameras_id)
        })
    }

    onChangeExposureProgram = (exposureProgram) => {
        const properties = {
            exposure_program: exposureProgram
        }
        this.props.cameraControlActions.updateProperties(this.props.cameras_id, properties).then(() => {
            // After updating properties, let's also refresh what we have locally
            this.props.cameraControlActions.fetchProperties(this.props.cameras_id)
        })
    }

    getApertureOptions = () => {
        return this.props.camera_control.properties.details.aperture.options.map((item) => {
            return {  text: item, value: item }
        })
    }

    getExposureCompensationOptions = () => {
        return this.props.camera_control.properties.details.exposure_compensation_a.options.map((item) => {
            return {  text: item, value: item }
        })
    }

    getImageFormatOptions = () => {
        return this.props.camera_control.properties.details.image_format.options.map((item) => {
            return {  text: item, value: item }
        })
    }

    getImageSizeOptions = () => {
        return this.props.camera_control.properties.details.image_size.options.map((item) => {
            return { text: item, value: item }
        })
    }

    getIsoOptions = () => {
        return this.props.camera_control.properties.details.iso.options.map((item) => {
            return {  text: item, value: item }
        })
    }

    getMeteringModeOptions = () => {
        return this.props.camera_control.properties.details.metering_mode.options.map((item) => {
            return {  text: item, value: item }
        })
    }

    getWhiteBalanceOptions = () => {
        return this.props.camera_control.properties.details.white_balance.options.map((item) => {
            return {  text: item, value: item }
        })
    }

    getExposureProgramOptions = () => {
        return this.props.camera_control.properties.details.exposure_program.options.map((item) => {
            return { text: item, value: item}
        })
    }

    onChangeExternalBattery = (externalBatt) => {
        this.props.cameraControlV4Actions.setBatterySource(this.props.cameras_id, externalBatt)
    }

    getForm = () => {

        const apertureDropdown = (this.props.camera_control.properties.details && this.props.camera_control.properties.details.aperture) ?
            <Form size='small'>
                <Form.Group>
                    <Form.Field>
                        <label>Aperture</label>
                        <Dropdown
                            loading={this.props.camera_control.update_properties.processing
                            && this.props.camera_control.update_properties.properties.aperture}
                            selection
                            // labeled
                            placeholder='Aperture'
                            defaultValue={this.props.camera_control.properties.details.aperture.value}
                            onChange={ (evt, val) => {
                                const aperture = val.value
                                if (aperture === this.props.camera_control.properties.details.aperture.value) {
                                    return
                                }
                                this.onChangeAperture(aperture)

                            }}
                            options={this.getApertureOptions()}
                        />
                    </Form.Field>
                </Form.Group>
            </Form> : null

        const exposureCompensationDropdown = (this.props.camera_control.properties.details && this.props.camera_control.properties.details.exposure_compensation_a) ?
            <Form size='small'>
                <Form.Group>
                    <Form.Field>
                        <label>Exposure Compensation</label>
                        <Dropdown
                            loading={this.props.camera_control.update_properties.processing
                            && this.props.camera_control.update_properties.properties.exposure_compensation_a}
                            selection
                            // labeled
                            placeholder='Exposure Compensation'
                            defaultValue={this.props.camera_control.properties.details.exposure_compensation_a.value}
                            onChange={ (evt, val) => {
                                const exposureCompensation = val.value
                                if (exposureCompensation === this.props.camera_control.properties.details.exposure_compensation_a.value) {
                                    return
                                }
                                this.onChangeExposureCompensation(exposureCompensation)

                            }}
                            options={this.getExposureCompensationOptions()}
                        />
                    </Form.Field>
                </Form.Group>
            </Form> : null

        const meteringModeDropdown = (this.props.camera_control.properties.details && this.props.camera_control.properties.details.metering_mode) ?

            <Form size='small'>
                <Form.Group>
                    <Form.Field>
                        <label>Metering Mode</label>
                        <Dropdown
                            loading={this.props.camera_control.update_properties.processing
                            && this.props.camera_control.update_properties.properties.metering_mode}
                            selection
                            // labeled
                            placeholder='Metering Mode'
                            defaultValue={this.props.camera_control.properties.details.metering_mode.value}
                            onChange={ (evt, val) => {
                                const meteringMode = val.value
                                if (meteringMode === this.props.camera_control.properties.details.metering_mode.value) {
                                    return
                                }
                                this.onChangeMeteringMode(meteringMode)
                            }}
                            options={this.getMeteringModeOptions()}
                        />
                    </Form.Field>
                </Form.Group>
            </Form> : null

        const isoDropdown = (this.props.camera_control.properties.details && this.props.camera_control.properties.details.iso) ?
            <Form size='small'>
                <Form.Group>
                    <Form.Field>
                        <label>ISO Speed</label>
                        <Dropdown
                            loading={this.props.camera_control.update_properties.processing
                            && this.props.camera_control.update_properties.properties.iso}
                            selection
                            // labeled
                            placeholder='ISO Speed'
                            defaultValue={this.props.camera_control.properties.details.iso.value}
                            onChange={ (evt, val) => {
                                const iso = val.value
                                if (iso === this.props.camera_control.properties.details.iso.value) {
                                    return
                                }
                                this.onChangeIso(iso)

                            }}
                            options={this.getIsoOptions()}
                        />
                    </Form.Field>
                </Form.Group>
            </Form> : null

        const imageFormatDropdown = (this.props.camera_control.properties.details && this.props.camera_control.properties.details.image_format) ?
            <Form size='small'>
                <Form.Group>
                    <Form.Field>
                        <label>Image Format</label>
                        <Dropdown
                            loading={this.props.camera_control.update_properties.processing
                            && this.props.camera_control.update_properties.properties.image_format}
                            selection
                            placeholder='Image Format'
                            defaultValue={this.props.camera_control.properties.details.image_format.value}
                            onChange={ (evt, val) => {
                                const format = val.value
                                if (format === this.props.camera_control.properties.details.image_format.value) {
                                    return
                                }
                                this.onChangeImageFormat(format)

                            }}
                            options={this.getImageFormatOptions()}
                        />
                    </Form.Field>
                </Form.Group>
            </Form> : null

        const imageSizeDropdown = (this.props.camera_control.properties.details && this.props.camera_control.properties.details.image_size) ?
            <Form size='small'>
                <Form.Group>
                    <Form.Field>
                        <label>Image Size</label>
                        <Dropdown
                            loading={this.props.camera_control.update_properties.processing
                            && this.props.camera_control.update_properties.properties.image_size}
                            selection
                            placeholder='Image Size'
                            defaultValue={this.props.camera_control.properties.details.image_size.value}
                            onChange={ (evt, val) => {
                                const size = val.value
                                if (size === this.props.camera_control.properties.details.image_size.value) {
                                    return
                                }
                                this.onChangeImageSize(size)

                            }}
                            options={this.getImageSizeOptions()}
                        />
                    </Form.Field>
                </Form.Group>
            </Form> : null

        const whiteBalanceDropdown = (this.props.camera_control.properties.details && this.props.camera_control.properties.details.white_balance) ?
            <Form size='small'>
                <Form.Group>
                    <Form.Field>
                        <label>White Balance</label>
                        <Dropdown
                            loading={this.props.camera_control.update_properties.processing
                            && this.props.camera_control.update_properties.properties.white_balance}
                            selection
                            // labeled
                            placeholder='White Balance'
                            defaultValue={this.props.camera_control.properties.details.white_balance.value}
                            onChange={ (evt, val) => {
                                const whiteBalance = val.value
                                if (whiteBalance === this.props.camera_control.properties.details.white_balance.value) {
                                    return
                                }
                                this.onChangeWhiteBalance(whiteBalance)

                            }}
                            options={this.getWhiteBalanceOptions()}
                        />
                    </Form.Field>
                </Form.Group>
            </Form> : null

        const exposureProgramDropdown = (this.props.camera_control.properties.details && this.props.camera_control.properties.details.exposure_program) ?
            <Form size='small'>
                <Form.Group>
                    <Form.Field>
                        <label>Exposure Program</label>
                        <Dropdown
                            loading={this.props.camera_control.update_properties.processing
                            && this.props.camera_control.update_properties.properties.exposure_program}
                            selection
                            // labeled
                            placeholder='White Balance'
                            defaultValue={this.props.camera_control.properties.details.exposure_program.value}
                            onChange={ (evt, val) => {
                                const exposureProgram = val.value
                                if (exposureProgram === this.props.camera_control.properties.details.exposure_program.value) {
                                    return
                                }
                                this.onChangeExposureProgram(exposureProgram)

                            }}
                            options={this.getExposureProgramOptions()}
                        />
                    </Form.Field>
                </Form.Group>
            </Form> : null

        const shutterCounter = !this.props.camera_control.properties.loading ?
            <Form size='small'>
                <Form.Group>
                    <Form.Field>
                        <label>Shutter Counter</label>
                        <Input
                            disabled
                            value={this.props.shutter_count}
                            style={{ 'opacity': 1 }}
                        />
                    </Form.Field>
                </Form.Group>
            </Form> : null

        const externalBattery = this.props.battery_source ?
        <Form size='small'>
            <Form.Group>
                <Form.Field>
                    <label>Set Battery Source</label>
                    <Dropdown
                        selection
                        // labeled
                        onChange={ (evt, val) => {
                            const externalBat = val.value
                            // if (externalBat === this.props.camera_control.properties.details.exposure_program.value) {
                            //     return
                            // }
                            this.onChangeExternalBattery(externalBat)
                        }}
                        options={[
                            {key: 'none', value: 'null', text: ''},
                            {key: 'internal', value: 'internal', text: 'Internal'},
                            {key: 'external', value: 'external', text: 'External'}
                        ]}
                        defaultValue={this.props.camera_control_v4.camera_info.monitoring_data.battery_source}
                    />
                </Form.Field>
            </Form.Group>
        </Form> : null


        return (
            <div>
                {exposureProgramDropdown}
                {apertureDropdown}
                {exposureCompensationDropdown}
                {isoDropdown}
                {meteringModeDropdown}
                {whiteBalanceDropdown}
                {imageFormatDropdown}
                {imageSizeDropdown}
                {shutterCounter}
                {externalBattery}
            </div>
        )

    }

    render = () => {
        return (
            <div className='upload-settings'>
                <Grid columns='1'>
                    <Grid.Row>
                        <Grid.Column>
                            {this.getForm()}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        )
    }
}

CameraSettings.propTypes = {
    cameraControlActions: PropTypes.object.isRequired,
    cameras_id: PropTypes.string.isRequired,
    camera_control: PropTypes.object.isRequired
}

export default CameraSettings
