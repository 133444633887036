import initialState from './initialState';

import {
    SUBSCRIPTIONS_RESET,
    SUBSCRIPTIONS_REQUEST,
    SUBSCRIPTIONS_RESPONSE_ERRORS,
    SUBSCRIPTIONS_RESPONSE_SUCCESS,
    SUBSCRIPTIONS_ADD_REQUEST,
    SUBSCRIPTIONS_ADD_RESPONSE_ERRORS,
    SUBSCRIPTIONS_ADD_RESPONSE_SUCCESS,
    SUBSCRIPTIONS_REMOVE_REQUEST,
    SUBSCRIPTIONS_REMOVE_RESPONSE_ERRORS,
    SUBSCRIPTIONS_REMOVE_RESPONSE_SUCCESS,
    SUBSCRIPTIONS_PORTAL_LINK_REQUEST,
    SUBSCRIPTIONS_PORTAL_LINK_SUCCESS,
    SUBSCRIPTIONS_PORTAL_LINK_ERRORS,
    SUBSCRIPTIONS_PAYWALL_CONFIG_SUCCESS,
    SUBSCRIPTIONS_PAYWALL_CONFIG_ERRORS,
    SUBSCRIPTIONS_NEW_CAMERA_REQUEST,
    SUBSCRIPTIONS_NEW_CAMERA_SUCCESS,
    SUBSCRIPTIONS_NEW_CAMERA_ERRORS,
    
} from '../actions/actionTypes';

export default function subscriptions(state = initialState.subscriptions, action) {

    switch (action.type) {
        case SUBSCRIPTIONS_RESET:
            return Object.assign(
                {},
                state,
                {
                    ...initialState.subscriptions
                }
            )
        case SUBSCRIPTIONS_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    loading: true
                }
            )
        case SUBSCRIPTIONS_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    data: action.data.subscriptions,
                    total: action.data.quantity_total
                }
            )
        case SUBSCRIPTIONS_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    error: true
                }
            )
        case SUBSCRIPTIONS_ADD_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    loading: true,
                    addingSubscription: true
                }
            )
        case SUBSCRIPTIONS_ADD_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    errors: true
                }
            )
        case SUBSCRIPTIONS_ADD_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    addingSubscription: false,
                    addingSubscriptionSuccess: true
                }
            )
        case SUBSCRIPTIONS_REMOVE_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    loading: true,
                    removingSubscription: true
                }
            )
        case SUBSCRIPTIONS_REMOVE_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    errors: true
                }
            )
        case SUBSCRIPTIONS_REMOVE_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    removingSubscription: false,
                    removeSubscriptionSuccess: true
                }
            )
        case SUBSCRIPTIONS_PORTAL_LINK_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    loading: true,
                }
            )
        
        case SUBSCRIPTIONS_PORTAL_LINK_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    portalLink: action.data.customer_portal_url
                }
            )
        case SUBSCRIPTIONS_PORTAL_LINK_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    errors: true
                }
            )
        case SUBSCRIPTIONS_PAYWALL_CONFIG_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    prices: action.config.prices
                }
            )
        case SUBSCRIPTIONS_PAYWALL_CONFIG_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    errors: true
                }
            )
        case SUBSCRIPTIONS_NEW_CAMERA_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    newCameraLoading: true
                }
            )

        case SUBSCRIPTIONS_NEW_CAMERA_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    newCameraSuccess: true,
                    newCameraLoading: false
                }
            )
        case SUBSCRIPTIONS_NEW_CAMERA_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    errors: true,
                    newCameraLoading: false
                }
            )

        default:
            return state
    }
}