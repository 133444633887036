import initialState from './initialState';
import {
    PROCESSING_RESET,
    PROCESSING_GET_TYPES_REQUEST,
    PROCESSING_GET_TYPES_ERRORS,
    PROCESSING_GET_TYPES_SUCCESS,
    PROCESSING_SET_PROCESS_REQUEST,
    PROCESSING_SET_PROCESS_SUCCESS,
    PROCESSING_SET_PROCESS_ERRORS,
    PROCESSING_REMOVE_PROCESS_REQUEST,
    PROCESSING_REMOVE_PROCESS_SUCCESS,
    PROCESSING_REMOVE_PROCESS_ERRORS
} from '../actions/actionTypes';

export default function logo(state = initialState.processing, action) {

    switch (action.type) {
        case PROCESSING_GET_TYPES_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    processTypes: {
                        processing: true,
                        error: false,
                        types: {}
                    }
                }
            )
        case PROCESSING_GET_TYPES_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    processTypes: {
                        processing: false,
                        error: true,
                        errors: action.errors
                    }
                }
            )
        case PROCESSING_GET_TYPES_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    processTypes: {
                        processing: false,
                        types: action.types,
                        error: false,
                        errors: {}
                    }
                }
            )
        case PROCESSING_SET_PROCESS_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    blur: {
                        ...state.blur,
                        set: {
                            processing: true,
                            success: false,
                            error: false,
                            errors: {}
                        }
                    }
                }
            )
        case PROCESSING_SET_PROCESS_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    blur: {
                        ...state.blur,
                        set: {
                            processing: false,
                            success: false,
                            error: true,
                            errors: action.errors
                        }
                    }
                }
            )

        case PROCESSING_SET_PROCESS_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    blur: {
                        ...state.blur,
                        set: {
                            processing: false,
                            success: true,
                            error: false,
                            errors: {}
                        }
                    }
                }
            )

        case PROCESSING_REMOVE_PROCESS_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    blur: {
                        ...state.blur,
                        remove: {
                            processing: true,
                            success: false,
                            error: false,
                            errors: {}
                        }
                    }
                }
            )
        case PROCESSING_REMOVE_PROCESS_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    blur: {
                        ...state.blur,
                        remove: {
                            processing: false,
                            success: false,
                            error: true,
                            errors: action.errors
                        }
                    }
                }
            )

        case PROCESSING_REMOVE_PROCESS_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    blur: {
                        ...state.blur,
                        remove: {
                            processing: false,
                            success: true,
                            error: false,
                            errors: {}
                        }
                    }
                }
            )
        case PROCESSING_RESET:
            return Object.assign(
                {},
                {
                    ...initialState.processing,
                    processTypes: state.processTypes
                }
            )

        default:
            return state
    }
}
