import React from "react"

const IconCross = () => {
    return (
        <svg height="72px" id="emoji" width="72px" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
            <g id="line">
                <line fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="3.5" x1="17.5" x2="54.5" y1="17.5" y2="54.5"/>
                <line fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="3.5" x1="54.5" x2="17.5" y1="17.5" y2="54.5"/>
            </g>
        </svg>
    )
}

export default IconCross