import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroller'
import Camera from './cards/Camera'
import CameraV4 from './cards/CameraV4'
import { Grid, Label, Loader } from 'semantic-ui-react'

const CameraGridView = (props) => {
    useEffect(() => {
        props.camerasActions.resetCameraInfo()
    }, [])

    const loadMore = () => {
        props.camerasActions.fetchGridViewCameras(props.cameras.grid_view.currentPage)
    }

    const renderTotals = () => {
        if (props.cameras.grid_view.total === 0 ||
            props.cameras.grid_view.total !== props.cameras.grid_view.cameras.length
        ) {
            return null
        }
        if (props.vanity) {
            return (
                <div style={{marginTop: '2em'}}>
                    <Label color='blue'>
                        Total Cameras
                        <Label.Detail>{props.cameras.grid_view.total}</Label.Detail>
                    </Label>
                </div>
            )
        }
        let criticalCount = 0
        let warningCount = 0
        props.cameras.grid_view.cameras.map((camera) => {
            if (camera.monitoring) {
                if (camera.monitoring.errorLevel === 1) {
                    warningCount +=1
                } else if (camera.monitoring.errorLevel === 2) {
                    criticalCount +=1
                }
            }
            return null
        })
        return (
            <div style={{margin: '2em 0'}}>
                <Label color='blue'>
                    Total Cameras
                    <Label.Detail>{props.cameras.grid_view.total}</Label.Detail>
                </Label>
                <Label color='red'>
                    Critical
                    <Label.Detail>{criticalCount}</Label.Detail>
                </Label>
                <Label color='yellow'>
                    Warning
                    <Label.Detail>{warningCount}</Label.Detail>
                </Label>
            </div>
        )
    }

    const renderCameras = () => {
        return props.cameras.grid_view.cameras.map(
            (camera, idx) => {
                const info = props.cameras.camera_info.cameras.find( elem  => {
                    if (!elem) {
                        return false
                    }
                    return elem.cameras_id === camera.id
                })
                if (props.auth && props.auth.user && props.auth.user.roles_id === 5) {
                    return (
                        <CameraV4
                            camera={camera}
                            key={camera.id}
                            index={camera.id}
                            auth={props.auth}
                            onOpenModal={()=> {props.onOpenModal(camera.id)}}
                            // Supporting fetching camera info
                            onFetchCameraInfo={() => props.camerasActions.fetchCameraInfo(camera.id)}
                            info={info}
                            vanity={props.vanity}
                            antivanity={props.antivanity}
                            hideClients={props.hideClients}
                            authAsCamera={props.authAsCamera}
                            cameraIndex={idx}
                        />
                    )
                } else {
                    return (
                        <Camera
                            camera={camera}
                            key={camera.id}
                            auth={props.auth}
                            onOpenModal={()=> { props.onOpenModal(camera.id)}}
                            // Supporting fetching camera info
                            onFetchCameraInfo={() => props.camerasActions.fetchCameraInfo(camera.id)}
                            info={info}
                            vanity={props.vanity}
                            antivanity={props.antivanity}
                            hideClients={props.hideClients}
                            authAsCamera={props.authAsCamera}
                            cameraIndex={idx}
                        />
                    )
                }
                
            }
        )
    }


    return (
        <div className='camera-grid-view'>
            {
                props.cameras.grid_view.total === 0
                && !props.cameras.grid_view.loading
                && props.cameras.grid_view.success ?
                    <h3>No cameras matching your filters were found</h3>
                    : null
            }
                {
                    props.cameras.statuses.ready ?
                        <InfiniteScroll
                            className="ui grid styled-grid styled-grid--cameras"
                            hasMore={props.cameras.grid_view.hasMore}
                            loadMore={loadMore.bind(this)}
                            threshold={5000}
                        >
                            {renderCameras()}
                            {props.cameras.grid_view.loading ? (
                                <Loader
                                    active
                                    inline='centered'
                                    size='huge'
                                    style={{marginTop: '2em'}}
                                >
                                    Loading cameras
                                </Loader>
                            ): null}
                        </InfiniteScroll>: null
                }
            {renderTotals()}
        </div>
    )
}

CameraGridView.propTypes = {
    loading: PropTypes.bool.isRequired,
    authActions: PropTypes.object,
    auth: PropTypes.object,
    messengerActions: PropTypes.object,
    camerasActions: PropTypes.object,
    onOpenModal: PropTypes.func.isRequired,
    authAsCamera: PropTypes.func.isRequired,
    cameras: PropTypes.object.isRequired
}

export default CameraGridView
