import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Button,
    Modal,
    Form,
    Input,
    Dropdown,
    Icon
    } from 'semantic-ui-react'

import moment from 'moment'
import { LOBSTER_POT_4 } from '../../config'

class EditScheduleModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            schedule: {
                actions: this.props.actions,
                start: this.props.start,
                stop: this.props.stop,
                interval: this.props.interval,
                json_class: 'Period',
                id: this.props.id
            },
            invervalError: false,
        }
        
        // if (this.props.interval < 60) {
        //     this.intervalFormatted = this.props.interval
        //     this.intervalUnits = 'seconds'
        // } else if (this.props.interval < 300 && this.props.interval % 60 > 0) {
        //     this.intervalFormatted = this.props.interval
        //     this.intervalUnits = 'seconds'
        // } else
        if (this.props.interval < 3600) {
            this.intervalFormatted = this.props.interval / 60
            this.intervalUnits = 'minutes'
        } else {
            this.intervalFormatted = this.props.interval / 3600
            this.intervalUnits = 'hours'
        }
    }

    updateStart = ({value}) => {
        const seconds = moment(value, 'HH:mm').diff(moment().startOf('day'), 'seconds');
        this.setState({start: seconds})
    }

    updateStop = ({value}) => {
        let seconds
        if (value === '00:00') {
            seconds = 86399 // 23:59:59
        } else {
            seconds = moment(value, 'HH:mm').diff(moment().startOf('day'),
                                                  'seconds')+59
        }
        this.setState({stop: seconds})
    }

    updateIntervalValue = (event, {value}) => {
        this.intervalFormatted = value
        this.updateInterval()
    }

    updateIntervalUnits = (event, {value}) => {
        this.intervalUnits = value
        this.updateInterval()
    }

    updateInterval = () => {
        let multiplier
        switch (this.intervalUnits) {
            case 'seconds':
                multiplier = 1
                break
            case 'hours':
                multiplier = 3600
                break
            case 'minutes':
            default:
                multiplier = 60
        }

        this.setState((prevState) => {
            return {
                schedule: {
                    ...prevState.schedule,
                    interval: this.intervalFormatted * multiplier
                }
            }
        })
    }


    formatTime = (seconds) => {
        return moment().startOf('day').seconds(seconds).format('HH:mm');
    }

    modalSubmit = () => {
        if (this.state && this.state.schedule.interval) {
            if (this.state.schedule.interval >= 600) { // 10 minutes
                this.props.onUpdate(this.state.schedule)
            } else {
                this.setState({invervalError: true})
            }
        }
    }

    render() {
        const intervals = [
            // { key: 'seconds', text: 'seconds', value: 'seconds' },
            { key: 'minutes', text: 'minutes', value: 'minutes' },
            { key: 'hours', text: 'hours', value: 'hours' },
        ]

        return (
            <Modal size='small' open onClose={this.props.onClose} closeIcon>
                <Modal.Header>Edit Shoot &amp; Upload Period</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Group>
                             <Form.Input
                                label='Start Time'
                                type='time'
                                defaultValue={this.formatTime(this.state.schedule.start)}
                                onChange={(event, data)=>{this.updateStart(data)}}
                            />
                        </Form.Group>
                        <Form.Group>
                             <Form.Input
                                label='Stop Time'
                                type='time'
                                defaultValue={this.formatTime(this.state.schedule.stop)}
                                onChange={(event, data)=>{this.updateStop(data)}}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Field error={this.state.invervalError}>
                                <label>Shooting Interval</label>
                                <Input
                                    defaultValue={this.intervalFormatted}
                                    label={
                                        <Dropdown
                                            defaultValue={this.intervalUnits}
                                            options={intervals}
                                            onChange={this.updateIntervalUnits}
                                        />
                                    }
                                    labelPosition='right'
                                    size='small'
                                    onChange={this.updateIntervalValue}
                                />
                                <small>Minimum value: 10 minutes</small>
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={this.modalSubmit}
                        positive
                        primary
                    >Update</Button>
                    <Button onClick={this.props.onClose}>Cancel</Button>
                    <Button
                        onClick={() => {
                            this.props.onRemove()
                        }}
                        negative
                    >Remove</Button>
                </Modal.Actions>
            </Modal>
        )
    }
}

EditScheduleModal.propTypes = {
    start: PropTypes.number.isRequired,
    stop: PropTypes.number.isRequired,
    interval: PropTypes.number.isRequired,
    actions: PropTypes.array,
    onUpdate: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired
}

export default EditScheduleModal
