import { API_BASE_URL}  from './config'

var errorCounter = 0
var errorLimit = 10
window.addEventListener('error', (e) => {

    errorCounter += 1

    if (errorCounter >= errorLimit) {
        return;
    }

    const stack = e.error.stack;
    let message = e.error.toString();

    if (stack) {
        message += '\n' + stack;
    }

    fetch(API_BASE_URL +  '/api/log', {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({
            'level' : 'error',
            'message' : message
        })
    })

});
