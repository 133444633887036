import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Message, Grid, Icon } from 'semantic-ui-react'
import { getErrorMessage } from '../util/errors'

class CameraFocus extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    focus = (direction, step_size) => {
        this.props.cameraControlV4Actions.focus(this.props.cameras_id, direction, step_size)
    }

    auto_focus = () => {
        this.props.cameraControlV4Actions.auto_focus(this.props.cameras_id)
    }

    getForm = () => {
        return (
            <div>
                <Button circular icon onClick={() => this.focus('far', 'large')} >
                    <Icon
                    name="fast backward"
                    />
                </Button>
                <Button circular icon onClick={() => this.focus('far', 'medium')} >
                    <Icon
                    name="backward"
                    />
                </Button>
                <Button circular icon onClick={() => this.focus('far', 'small')} >
                    <Icon
                    name="left chevron"
                    />
                </Button>
                <Button circular icon onClick={() => this.auto_focus()} >
                    <Icon
                    name="expand"
                    />
                </Button>
                <Button circular icon onClick={() => this.focus('near', 'small')} >
                    <Icon
                    name="right chevron"
                    />
                </Button>
                <Button circular icon onClick={() => this.focus('near', 'medium')} >
                    <Icon
                    name="forward"
                    />
                </Button>
                <Button circular icon onClick={() => this.focus('near', 'large')} >
                    <Icon
                    name="fast forward"
                    />
                </Button>
            </div>
        )

    }

    getMessage = (blurb) => {
        return (this.props.camera_control_v4.focus.error || this.props.camera_control_v4.auto_focus.error) ?
            <Message negative>
                <Message.Header>Error</Message.Header>
                <p>{blurb}</p>
            </Message> : null
    }

    getBlurb = () => {
        if (this.props.camera_control_v4.focus.error) {
            return this.getFocusError()
        }
        else if (this.props.camera_control_v4.auto_focus.error) {
            return this.getAutoFocusError()
        }
    }

    getFocusError = () => {
        if (!this.props.camera_control_v4.focus.error) {
            return null
        }
        let message = getErrorMessage(this.props.camera_control_v4.focus.errors, 'cameras_id')
        if (!message) {
            message = getErrorMessage(this.props.camera_control_v4.focus.errors, 'unknownErrorType')
        }
        return (message) ? message : "There was an unexpected error"
    }

    getAutoFocusError = () => {
        if (!this.props.camera_control_v4.auto_focus.error) {
            return null
        }
        let message = getErrorMessage(this.props.camera_control_v4.auto_focus.errors, 'cameras_id')
        if (!message) {
            message = getErrorMessage(this.props.camera_control_v4.auto_focus.errors, 'unknownErrorType')
        }
        return (message) ? message : "There was an unexpected error"
    }

    isReady = () => {
        return !this.props.camera_control_v4.focus.loading && !this.props.camera_control_v4.auto_focus.loading
    }

    render = () => {
        const message = this.getMessage(this.getBlurb())
        if (!this.isReady()) {

            return (
                <div className='focus-settings'>
                    <Grid columns='1'>
                        <Grid.Row>
                            <Grid.Column>
                                <h4>Focus</h4>
                                <Grid.Column><div className="animated-blank-line" /></Grid.Column>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            )

        } else {
            return (
                <div className='focus-settings'>
                    <Grid columns='1'>
                        <Grid.Row>
                            <Grid.Column>
                                <h4>Focus</h4>
                                {message}
                                {this.getForm()}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            )
        }
    }
}

CameraFocus.propTypes = {
    cameraControlV4Actions: PropTypes.object.isRequired,
    cameras_id: PropTypes.string.isRequired,
    camera_control_v4: PropTypes.object.isRequired
}

export default CameraFocus
