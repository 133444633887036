import * as types from './actionTypes'
import { addMessage } from './messengerActions'
import { log } from './logActions'
import { API_BASE_URL }  from '../config'
import { notAuthorisedResponse } from './authActions'
import { fetchWithToken } from '../util/accessToken'

export function reset() {
    return dispatch => {
        return new Promise(function(resolve, reject) {
            dispatch({
                type: types.SUBSCRIPTIONS_RESET,
            })
            resolve()
        })
    }
}

export function fetchPaywallConfig() {
    return (dispatch, getState) => {
        //dispatch({ type: types.})
        return fetchWithToken(
            `${API_BASE_URL}/api/paywall/config`,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    // Remember, we're bouncing out 401s
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch({ type: types.SUBSCRIPTIONS_PAYWALL_CONFIG_ERRORS, errors: data.errors })
                        } else {
                            // Error
                            dispatch(
                                {
                                    type: types.SUBSCRIPTIONS_PAYWALL_CONFIG_ERRORS,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error"
                                        }
                                    }
                                }
                            )
                        }
                    })
                }
            } else {
                response.json().then((data) => {
                    // Presumably, errors of some kind...
                    if (data.errors) {
                        dispatch({
                            type: types.SUBSCRIPTIONS_PAYWALL_CONFIG_ERRORS,
                            errors: data.errors
                        })
                    } else {
                        dispatch({
                            type: types.SUBSCRIPTIONS_PAYWALL_CONFIG_SUCCESS,
                            config: data
                        })
                    }
                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function fetchSubscriptions() {
    return (dispatch, getState) => {
        dispatch({ type: types.SUBSCRIPTIONS_REQUEST})
        return fetchWithToken(
            `${API_BASE_URL}/api/subscriptions/index`,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    // Remember, we're bouncing out 401s
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch({ type: types.SUBSCRIPTIONS_RESPONSE_ERRORS, errors: data.errors })
                        } else {
                            // Error
                            dispatch(
                                {
                                    type: types.SUBSCRIPTIONS_RESPONSE_ERRORS,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error"
                                        }
                                    }
                                }
                            )
                        }
                    })
                }
            } else {
                response.json().then((data) => {
                    // Presumably, errors of some kind...
                    if (data.errors) {
                        dispatch({
                            type: types.SUBSCRIPTIONS_RESPONSE_ERRORS,
                            errors: data.errors
                        })
                    } else {
                        dispatch({
                            type: types.SUBSCRIPTIONS_RESPONSE_SUCCESS,
                            data: data
                        })
                    }
                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function addSubscription() {
    return (dispatch, getState) => {
        dispatch({ type: types.SUBSCRIPTIONS_ADD_REQUEST})
        return fetchWithToken(
            `${API_BASE_URL}/api/subscriptions/add`,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    // Remember, we're bouncing out 401s
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch({ type: types.SUBSCRIPTIONS_ADD_RESPONSE_ERRORS, errors: data.errors })
                        } else {
                            // Error
                            dispatch(
                                {
                                    type: types.SUBSCRIPTIONS_ADD_RESPONSE_ERRORS,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error"
                                        }
                                    }
                                }
                            )
                        }
                    })
                }
            } else {
                response.json().then((data) => {
                    // Presumably, errors of some kind...
                    if (data.errors) {
                        dispatch({
                            type: types.SUBSCRIPTIONS_ADD_RESPONSE_ERRORS,
                            errors: data.errors
                        })
                    } else {
                        dispatch({
                            type: types.SUBSCRIPTIONS_ADD_RESPONSE_SUCCESS,
                            data: data
                        })
                    }
                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function removeSubscription() {
    return (dispatch, getState) => {
        dispatch({ type: types.SUBSCRIPTIONS_REMOVE_REQUEST})
        return fetchWithToken(
            `${API_BASE_URL}/api/subscriptions/remove`,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    // Remember, we're bouncing out 401s
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch({ type: types.SUBSCRIPTIONS_REMOVE_RESPONSE_ERRORS, errors: data.errors })
                        } else {
                            // Error
                            dispatch(
                                {
                                    type: types.SUBSCRIPTIONS_REMOVE_RESPONSE_ERRORS,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error"
                                        }
                                    }
                                }
                            )
                        }
                    })
                }
            } else {
                response.json().then((data) => {
                    // Presumably, errors of some kind...
                    if (data.errors) {
                        dispatch({
                            type: types.SUBSCRIPTIONS_REMOVE_RESPONSE_ERRORS,
                            errors: data.errors
                        })
                    } else {
                        dispatch({
                            type: types.SUBSCRIPTIONS_REMOVE_RESPONSE_SUCCESS,
                            data: data
                        })
                    }
                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function subscribeCamera(cameraId) {
    return (dispatch, getState) => {
        dispatch({ type: types.SUBSCRIPTIONS_CAMERA_SUBSCRIBE_REQUEST})
        return fetchWithToken(
            `${API_BASE_URL}/api/subscriptions/camera/${cameraId}/subscribe`,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    // Remember, we're bouncing out 401s
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch({ type: types.SUBSCRIPTIONS_CAMERA_SUBSCRIBE_ERRORS, errors: data.errors })
                        } else {
                            // Error
                            dispatch(
                                {
                                    type: types.SUBSCRIPTIONS_CAMERA_SUBSCRIBE_ERRORS,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error"
                                        }
                                    }
                                }
                            )
                        }
                    })
                }
            } else {
                response.json().then((data) => {
                    // Presumably, errors of some kind...
                    if (data.errors) {
                        dispatch({
                            type: types.SUBSCRIPTIONS_CAMERA_SUBSCRIBE_ERRORS,
                            errors: data.errors
                        })
                    } else {
                        return new Promise(function(resolve, reject) {
                            dispatch({
                                type: types.SUBSCRIPTIONS_CAMERA_SUBSCRIBE_SUCCESS,
                                data: data
                            })
                        })
                    }
                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function unsubscribeCamera(cameraId) {
    return (dispatch, getState) => {
        dispatch({ type: types.SUBSCRIPTIONS_CAMERA_UNSUBSCRIBE_REQUEST})
        return fetchWithToken(
            `${API_BASE_URL}/api/subscriptions/camera/${cameraId}/unsubscribe`,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    // Remember, we're bouncing out 401s
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch({ type: types.SUBSCRIPTIONS_CAMERA_UNSUBSCRIBE_ERRORS, errors: data.errors })
                        } else {
                            // Error
                            dispatch(
                                {
                                    type: types.SUBSCRIPTIONS_CAMERA_UNSUBSCRIBE_ERRORS,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error"
                                        }
                                    }
                                }
                            )
                        }
                    })
                }
            } else {
                response.json().then((data) => {
                    // Presumably, errors of some kind...
                    if (data.errors) {
                        dispatch({
                            type: types.SUBSCRIPTIONS_CAMERA_UNSUBSCRIBE_ERRORS,
                            errors: data.errors
                        })
                    } else {
                        return new Promise(function(resolve, reject) {
                            dispatch({
                                type: types.SUBSCRIPTIONS_CAMERA_UNSUBSCRIBE_SUCCESS,
                                data: data
                            })
                        })
                    }
                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function fetchCustomerPortalLink() {
    return (dispatch, getState) => {
        dispatch({ type: types.SUBSCRIPTIONS_PORTAL_LINK_REQUEST})
        return fetchWithToken(
            `${API_BASE_URL}/api/subscriptions/customerPortal`,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    // Remember, we're bouncing out 401s
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch({ type: types.SUBSCRIPTIONS_PORTAL_LINK_ERRORS, errors: data.errors })
                        } else {
                            // Error
                            dispatch(
                                {
                                    type: types.SUBSCRIPTIONS_PORTAL_LINK_ERRORS,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error"
                                        }
                                    }
                                }
                            )
                        }
                    })
                }
            } else {
                response.json().then((data) => {
                    // Presumably, errors of some kind...
                    if (data.errors) {
                        dispatch({
                            type: types.SUBSCRIPTIONS_PORTAL_LINK_ERRORS,
                            errors: data.errors
                        })
                    } else {
                        dispatch({
                            type: types.SUBSCRIPTIONS_PORTAL_LINK_SUCCESS,
                            data: data
                        })
                    }
                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function buyCamera() {
    return (dispatch, getState) => {
        dispatch({ type: types.SUBSCRIPTIONS_NEW_CAMERA_REQUEST})
        return fetchWithToken(
            `${API_BASE_URL}/api/subscriptions/buyNewCamera`,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    // Remember, we're bouncing out 401s
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch({ type: types.SUBSCRIPTIONS_NEW_CAMERA_ERRORS, errors: data.errors })
                        } else {
                            // Error
                            dispatch(
                                {
                                    type: types.SUBSCRIPTIONS_NEW_CAMERA_ERRORS,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error"
                                        }
                                    }
                                }
                            )
                        }
                    })
                }
            } else {
                response.json().then((data) => {
                    // Presumably, errors of some kind...
                    if (data.errors) {
                        dispatch({
                            type: types.SUBSCRIPTIONS_NEW_CAMERA_ERRORS,
                            errors: data.errors
                        })
                    } else {
                        dispatch({
                            type: types.SUBSCRIPTIONS_NEW_CAMERA_SUCCESS,
                            data: data
                        })
                    }
                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}