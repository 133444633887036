import React, { Component } from 'react';
import PropTypes from 'prop-types'
import {Grid, Segment, Button, Form, Message, Modal} from 'semantic-ui-react';
import {CHANGE, RESET, VERIFY} from '../actions/passwordActions'

class PasswordEntry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            passwordField: null,
            comfirmPasswordField: null,
            token: null,
            privacyPolicyAccepted: false
        }
        this.prompt = ""
    }

    componentDidMount() {
        this.props.passwordActions.passwordResetErrors()
        // Customise the display message depending on the reason for resetting
        // the password (verify, reset, user change)
        switch (this.props.reason) {
            case VERIFY:
                this.prompt = `Thank you for validating your email address. `
                + `Please now enter a password for your account`
                this.props.headerActions.hideHeader()
                this.props.footerActions.hideFooter()
                break
            case RESET:
                this.prompt = "Please enter a new password for your account."
                this.props.headerActions.hideHeader()
                this.props.footerActions.hideFooter()
                break
            case CHANGE:
            default:
                this.prompt = "Please enter a new password for your account."
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.passwordActions.passwordChangeSubmit(
            this.state.passwordField,
            this.state.comfirmPasswordField,
            this.props.reason,
            this.props.match.params.hash,
            this.state.token ? this.state.token : this.props.match.params.token,
            this.state.privacyPolicyAccepted
        )
    }

    handlePasswordChange = (e, {value}) => {
        this.props.passwordActions.passwordInputChange(value, this.state.confirmPasswordField)
        this.setState({passwordField: value})
    }

    handleConfirmPasswordChange = (e, {value}) => {
        this.props.passwordActions.comfirmPasswordInputChange(this.state.passwordField, value)
        this.setState({comfirmPasswordField: value})
    }

    handleTokenChange = (e, {value}) => {
        this.setState({token: value})
    }

    handlePrivacyPolicyChange = (event) => {
        this.setState({privacyPolicyAccepted: event.target.checked})
    }

    redirectToLogin = (e) => {
        this.props.history.push('/auth')
    }

    render() {
        const {err} = this.props;
        // Show any suggestions returned by the password strength check
        let suggestion = null
        if (this.props.password.suggestions.length > 0 || this.props.password.warning) {
            suggestion = (
                <Message color='yellow'
                    icon='idea'
                    header={this.props.password.warning}
                    list={this.props.password.suggestions}
                    style={{marginBottom: '15px'}}
                />
            )
        }

        // Privacy Policy acceptance
        let privacyPolicyAcceptance
        if (this.props.reason === VERIFY) {
            privacyPolicyAcceptance = (
                <p>
                    <input
                        id="acceptPrivacyPolicy"
                        type="checkbox"
                        onClick={this.handlePrivacyPolicyChange}
                        style={{
                            display: "inline",
                            width: "auto",
                            marginRight: "0.3em",
                            borderBottom: "none"
                            }}
                    />
                    <label htmlFor="acceptPrivacyPolicy">By checking this box, I confirm that I have read and agree to the <a href="/privacy" rel="noopener noreferrer" target="_blank">Privacy Policy</a>. (Required)</label>
                </p>
            )
        }

        // If no token was included in the URL, display an input box for the
        // user to enter it
        let token = null
        if (!this.props.match.params.token) {
            token = (
                <div>
                    <p>For verfication, please enter the token that was provided
                     in your email. This will be a four-digit number.</p>
                     <Form.Input
                        label="Token"
                        name="token"
                        onChange={this.handleTokenChange}
                    />
                    <br />
                </div>
            )
        } else {
            token = <input type="hidden" name="token" value={this.props.match.params.token} />
        }
        let errors = this.props.password.errors.length > 0 ? <Message header="Error" list={this.props.password.errors} /> : null

        return (
            <>
                <Grid
                    textAlign='center'
                    style={{ height: '100%' }}
                    verticalAlign='middle'
                >
                    <Grid.Column style={{ maxWidth: 450 }}>
                        <Form size='large' onSubmit={this.handleSubmit} error={Boolean(err)} style={{marginTop: '1em'}}>
                        <p style={{clear:'both'}}>{this.prompt}</p>
                            <Segment>
                                {errors}
                                <input type="hidden" name="hash" value={this.props.match.params.hash} />
                                <Form.Input
                                    label="Password"
                                    name="password"
                                    type="password"
                                    onChange={this.handlePasswordChange}
                                    icon='lock'
                                    iconPosition='left'
                                    autoComplete="new-password"
                                />
                                { this.state.passwordField &&
                                    (<div>
                                        {suggestion}
                                    </div>)
                                }
                                <Form.Input
                                    label="Confirm Password"
                                    name="confirmPassword"
                                    type="password"
                                    onChange={this.handleConfirmPasswordChange}
                                    icon='lock'
                                    iconPosition='left'
                                    autoComplete="new-password"
                                />
                                { this.state.passwordField && !this.props.password.match &&
                                    <p>Passwords do not match</p>
                                }
                                {err &&
                                    <Message header="Error" content={err.message} error/>
                                }
                                {token}
                                {privacyPolicyAcceptance}
                                <Button
                                    type="submit"
                                    disabled={this.state.passwordField === null || this.state.confirmPasswordField === null || !this.props.password.match || this.props.password.strength < 1}>
                                    Set Password
                                </Button>
                            </Segment>
                        </Form>
                    </Grid.Column>
                </Grid>
                <Modal size="small" open={this.props.password.success} onClose={this.redirectToLogin}>
                    <Modal.Header>Your password has been successfully updated</Modal.Header>
                    <Modal.Content>
                        <p>Please click the continue button below to log in</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button positive icon='arrow circle right' labelPosition='right' content='Continue' onClick={this.redirectToLogin} />
                    </Modal.Actions>
                </Modal>
            </>
        )
    }
}

PasswordEntry.propTypes = {
    password: PropTypes.shape({
        warning: PropTypes.string,
        suggestions: PropTypes.array,
        errors: PropTypes.array,
        strength: PropTypes.number,
        match: PropTypes.bool,
        sucess: PropTypes.bool
    }).isRequired,
    passwordActions: PropTypes.object,
    match: PropTypes.shape({
        params: PropTypes.object
    }),
    reason: PropTypes.string.isRequired
}

export default PasswordEntry
