import initialState from './initialState';
import {
    SECURITY_APPLY_LOCKDOWN_REQUEST,
    SECURITY_APPLY_LOCKDOWN_RESPONSE_ERRORS,
    SECURITY_APPLY_LOCKDOWN_RESPONSE_SUCCESS,
    SECURITY_APPLY_LOCKDOWN_RESET_ERRORS,
    SECURITY_REMOVE_LOCKDOWN_REQUEST,
    SECURITY_REMOVE_LOCKDOWN_RESPONSE_ERRORS,
    SECURITY_REMOVE_LOCKDOWN_RESPONSE_SUCCESS,
    SECURITY_REMOVE_LOCKDOWN_RESET_ERRORS
} from '../actions/actionTypes';

export default function security(state = initialState.security, action) {

    switch (action.type) {
        case SECURITY_APPLY_LOCKDOWN_RESET_ERRORS:
            return Object.assign({}, initialState.security)
        case SECURITY_APPLY_LOCKDOWN_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    applyLockdown:
                    {
                        loading: true,
                        errors: {},
                        message: null,
                        success: null,
                        error: null
                    }
                }
            )
        case SECURITY_APPLY_LOCKDOWN_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    applyLockdown:
                        {
                            loading: false,
                            errors: {},
                            message: action.message,
                            details: action.details,
                            success: true,
                            error: false
                        }
                }
            )

        case SECURITY_APPLY_LOCKDOWN_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    applyLockdown:
                        {
                            loading: false,
                            errors: action.errors,
                            error: true,
                            success: false
                        }
                }
            )


        case SECURITY_REMOVE_LOCKDOWN_RESET_ERRORS:
            return Object.assign({}, initialState.security)
        case SECURITY_REMOVE_LOCKDOWN_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    removeLockdown:
                        {
                            loading: true,
                            errors: {},
                            message: null,
                            success: null,
                            error: null
                        }
                }
            )
        case SECURITY_REMOVE_LOCKDOWN_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    removeLockdown:
                        {
                            loading: false,
                            errors: {},
                            message: action.message,
                            details: action.details,
                            success: true,
                            error: false
                        }
                }
            )

        case SECURITY_REMOVE_LOCKDOWN_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    removeLockdown:
                        {
                            loading: false,
                            errors: action.errors,
                            error: true,
                            success: false
                        }
                }
            )
        default:
            return state
    }
}
