import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as authActions from '../actions/authActions'
import * as fileListActions from '../actions/fileListActions'
import FileList from '../components/pages/FileList'
import { isPermitted } from './../util/acl'
import { checkAuth } from '../util/accessToken'

class FileListContainer extends Component {
    componentDidMount () {
        window.scrollTo(0, 0)

        return checkAuth(this.props.auth, this.props.authActions, this.props.history)
        .then(() => {
            this.props.fileListActions.reset()
        })
        .catch(err => {})
    }
    

    render() {
        const fileList = (isPermitted(this.props.auth.user, 'get-images-imagelist')) ?
            <FileList {...this.props} /> :
            <p>You do not have permission to view this page</p>

        return (
            <div>
                {fileList}
            </div>)
    }

}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        fileList: state.fileList
    }
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(authActions, dispatch),
        fileListActions: bindActionCreators(fileListActions, dispatch)
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FileListContainer)
