import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { withRouter, Link } from 'react-router-dom'

import * as authActions from '../actions/authActions'
import * as logActions from '../actions/logActions'
import * as messengerActions from '../actions/messengerActions'
import * as projectsActions from '../actions/projectsActions'
import * as addProjectActions from '../actions/addProjectActions'
import * as updateProjectActions from '../actions/updateProjectActions'
import * as camerasActions from '../actions/camerasActions'
import * as ucpaActions from '../actions/userCameraProjectAssociationActions'
import * as usersActions from '../actions/usersActions'
import * as bulkUsersActions from '../actions/bulkUsersActions'
import * as logoActions from '../actions/logoActions'
import { isPermitted } from "../util/acl"
import { checkAuth } from '../util/accessToken'

import debounce from 'lodash/debounce'

import ProjectList from "../components/ProjectList"
import ProjectGrid from '../components/ProjectGrid';
import {Input, Button, Container} from 'semantic-ui-react'
import DocumentTitle from 'react-document-title'
import DeleteProjectModal from "../components/modals/DeleteProject";
import CreateProjectModal from "../components/modals/CreateProject";
import UpdateProjectModal from "../components/modals/UpdateProject";
import ProjectAccessModal from "../components/modals/ProjectAccess";


const ProjectsContainer = (props) => {
    const inputRef = useRef(null);

    useEffect(() => {
        checkAuth(props.auth, props.authActions, props.history)
        .then(() => {
            reload()
        })
        .catch(err => {})

        window.scrollTo(0, 0)
        parseURLFragment()

        // Fires when component is unmounted
        return () => {
            // Reset any filtering on the project list
            props.projectsActions.projectListReset()
            props.addProjectActions.resetErrors()
        };
    }, []);

    useEffect(() => {
        if (props.auth.user &&
            (props.auth.user.clients_id === 1 ||
            props.auth.user.clients_id === 344)) {
            props.addProjectActions.clientsRequest()
        }
    }, [props.auth.user])

    useEffect(() => {
        if (props.projects.searchTerm && props.projects.searchTerm !== "") {
            updateURLFragment()
        }
    }, [props.projects.searchTerm])

    const getClientId = () => {
        if (props.match.params.clientId) {
            return parseInt(props.match.params.clientId, 10)
        } else if (props.auth.user && (props.auth.user.clients_id !== 1 && props.auth.user.clients_id !== 344) ) {
            return parseInt(props.auth.user.clients_id)
        } else {
            return undefined
        }
    }

    const getProjectId = () => {
        if (props.match.params.projectId) {
            return parseInt(props.match.params.projectId, 10)
        } else {
            return undefined
        }
    }

    const reload = () => {
        props.projectsActions.projectListRequest(  
            props.rowsPerPage || 10,
            props.projects.currentPage,
            props.projects.sortColumn,
            props.projects.sortAscending,
            props.projects.searchTerm,
            undefined
        )
    }

    const updateURLFragment = () => {
        const preferences = []
        if (props.projects.searchTerm) {
            preferences["search"] = props.projects.searchTerm
        }

        preferences["sort"] = props.projects.sortColumn

        preferences["ascending"] = props.projects.sortAscending

        let hashPartBuffer = [];
        for (let k in preferences) {
            hashPartBuffer.push(
                encodeURIComponent(k), '=', encodeURIComponent(preferences[k]),
                '&'
            )
        }

        if (hashPartBuffer.length) {
          hashPartBuffer.pop()
        }
        window.location.hash = hashPartBuffer.join('')
    }

    const parseURLFragment = () => {
        const hash = window.location.hash.substring(1)
        let pairs = hash.split(/&/)
        const preferences = {}
        for (let i = 0; i < pairs.length; i++) {
            const keyValue = pairs[i].split(/=/)
            // Validate that this has the right structure.
            if (keyValue.length === 2) {
                const key = decodeURIComponent(keyValue[0])
                const value = decodeURIComponent(keyValue[1])
                if (value.indexOf('|') < 0) {
                    preferences[key] = value
                } else {
                    preferences[key] = value.split('|')
                }
            }
        }

        // // Update preferences
        if ('search' in preferences) {
            props.projectsActions.projectListFilterBy(preferences['search'])
        }
    }

    /**
     * Filter result set by search data
     *
     * @param {string} data - Value to search by
     */
    const onSearchInputChange = debounce((data, event) => {
        if (event.value.length > 1) {
            props.projectsActions.projectListRequest(
                props.rowsPerPage || 10,
                1,
                props.projects.sortColumn,
                props.projects.sortAscending,
                event.value,
                props.clientId)
        } else if (props.projects.filtered) {
            props.projectsActions.projectListRequest(
                props.rowsPerPage || 10,
                1,
                props.projects.sortColumn,
                props.projects.sortAscending,
                undefined,
                props.clientId
            )
        }
        updateURLFragment()
    }, 800)

    const onModalClose = (_e, _d, stepsBack = 1) => {
        const steps = 0 - stepsBack // go expects negative value§
        if (props.history.length < 3) {
            // This is needed for the link from Viewer to Admin
            // If this is removed it's not possbile to close the edit project modal
            // having navigated to that url directly
            const clientId = getClientId()
            props.history.replace(`/clients/${clientId}/projects`)
        } else {
            props.history.go(steps)
            reload()
        }
        
    }

    const getStatuses = () => {
        if (!props.addProject.statuses) {
            return []
        }
        return props.addProject.statuses.map(function (elem, idx) {
            return {
                value: elem,
                text: elem,
                key: idx
            }
        });
    }

    const getClients = () => {
        if (typeof props.addProject.clients.clients === 'object' && props.addProject.clients.clients.length > 0) {
            return props.addProject.clients.clients.map(function (elem, idx) {
                return {
                    value: elem.id,
                    text: elem.name,
                    key:  elem.id
                }
            });
        } else {
            return null
        }
    }

    const getAvailableCameras = () => {
        return props.cameras.available.cameras.map((elem, idx) => {
            return {
                value: elem.id,
                text: elem.pot_id,
                key: elem.id,
                status: elem.status,
                type: elem.type,
                default_weight: elem.default_weight,
                id: elem.id

            }
        })
    }

    const getDeployedCameras = () => {
        if (props.updateProject.details && props.updateProject.details.cameras) {
            return props.updateProject.details.cameras.map((elem, idx) => {
                return {
                    value: elem.id,
                    text: elem.pot_id,
                    key: elem.id,
                    status: elem.status,
                    type: elem.type,
                    default_weight: elem.default_weight,
                    id: elem.id
                }
            })
        } else {
            return []
        }
    }

    const createProjectButton = (
        isPermitted(props.auth.user, 'post-projects-projects')
    ) ?
        <Button
            id='createProjectButton'
            as={Link}
            to='/projects/create'
            primary
        >Create Project</Button>
    : null

    const statuses = getStatuses()
    const clients = getClients()
    const availableCameras = getAvailableCameras()
    const deployedCameras = getDeployedCameras()
    const clientId = getClientId()
    const projectId = getProjectId()
    const action = props.match.params.action

    return (
        <div className='projects-container'>
            <DocumentTitle title='Projects' />
            <header className="sub-header sub-header--projects">
                <Container fluid={true} style={{padding: '0 1.2em'}}>
                    <h1 className="page-title mobile-visible">Projects</h1>
                    <Input
                        loading={props.projects.filtered && props.projects.loading}
                        placeholder='Search...'
                        defaultValue={props.projects.searchTerm}
                        onChange={(e, data) => onSearchInputChange(e, data)}
                        ref={inputRef}
                    >
                    </Input>
                    {createProjectButton}
                </Container>
            </header>
            {props.auth.user && props.auth.user.roles_id === 5 && <ProjectGrid
                clientId={clientId}
                auth={props.auth}
                projects={props.projects}
                projectsActions={props.projectsActions}
                details={props.updateProject.details}
            />}
            {props.auth.user && props.auth.user.roles_id !== 5 && <ProjectList
                clientId={clientId}
                auth={props.auth}
                projects={props.projects}
                projectsActions={props.projectsActions}
                searchTerm={props.projects.searchTerm}
            />}
            <CreateProjectModal
                open={action === 'create'}
                error={props.addProject.error}
                errors={props.addProject.errors}
                statuses={statuses}
                loading={props.addProject.loading}
                success={props.addProject.success}
                details={props.addProject.details}
                clients={clients}
                clientId={clientId}
                availableCameras={availableCameras}
                deployedCameras={deployedCameras}
                projectCameras={props.addProject.project_cameras}
                onAddCameras={ (cameras) => { props.addProjectActions.projectCamerasAddCameras(cameras)} }
                onRemoveCameras={ (cameras) => { props.addProjectActions.projectCamerasRemoveCameras(cameras)} }
                onCameraWeights={ (cameras) => { props.addProjectActions.projectCamerasCameraWeights(cameras)} }
                addProjectActions={props.addProjectActions}
                camerasActions={props.camerasActions}
                onClose={onModalClose}
            />
            <UpdateProjectModal
                open={action === 'edit'}
                auth={props.auth}
                error={props.updateProject.error}
                errors={props.updateProject.errors}
                statuses={statuses}
                processing={props.updateProject.processing}
                project={props.updateProject}
                success={props.updateProject.success}
                details={props.updateProject.details}
                availableCameras={availableCameras}
                deployedCameras={deployedCameras}
                projectCameras={props.updateProject.project_cameras}
                onAddCameras={ (cameras) => { props.updateProjectActions.projectCamerasAddCameras(cameras)} }
                onRemoveCameras={ (cameras) => { props.updateProjectActions.projectCamerasRemoveCameras(cameras)} }
                onCameraWeights={ (cameras) => { props.updateProjectActions.projectCamerasCameraWeights(cameras)} }
                updateProjectActions={props.updateProjectActions}
                camerasActions={props.camerasActions}
                projectId={projectId}
                clientId={clientId}
                onClose={onModalClose}
                permittedToDelete={isPermitted(props.auth.user, 'delete-projects-projects')}
                user={props.auth.user}
                logo={props.logo}
                logoActions={props.logoActions}
                searchTerm={props.projects.searchTerm}
            />
            <DeleteProjectModal
                open={action === 'delete'}
                error={Object.keys(props.updateProject.delete.errors).length > 0}
                success={props.updateProject.delete.projects_id && props.updateProject.delete.projects_id > 0}
                message={props.updateProject.delete.message}
                loading={props.updateProject.delete.loading}
                errors={props.updateProject.delete.errors}
                updateProjectActions={props.updateProjectActions}
                projectId={projectId}
                clientId={clientId}
                onClose={onModalClose}
            />
            <ProjectAccessModal
                open={action === 'access'}
                projectId={projectId}
                onClose={onModalClose}
                projectsActions={props.projectsActions}
                auth={props.auth}
                clientId={clientId}
                addUser={props.addUser}
                editUser={props.editUser}
                bulkUsers={props.bulkUsers}
                users={props.users}
                ucpa={props.ucpa}
                ucpaActions={props.ucpaActions}
                usersActions={props.usersActions}
                bulkUsersActions={props.bulkUsersActions}
                isUserListLinkedFromProject={true}
                fromProjectId={projectId}
            />
        </div>
    )
}

ProjectsContainer.propTypes = {
    authActions: PropTypes.object,
    auth: PropTypes.object,
    logActions: PropTypes.object,
    messengerActions: PropTypes.object,
    camerasActions: PropTypes.object,
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        projects: state.projects,
        addProject: state.addProject,
        updateProject: state.updateProject,
        cameras: state.cameras,
        addUser: state.addUser,
        users: state.users,
        editUser: state.editUser,
        bulkUsers: state.bulkUsers,
        ucpa: state.ucpa,
        logo: state.logo
    }
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(authActions, dispatch),
        logActions: bindActionCreators(logActions, dispatch),
        messengerActions: bindActionCreators(messengerActions, dispatch),
        projectsActions: bindActionCreators(projectsActions, dispatch),
        addProjectActions: bindActionCreators(addProjectActions, dispatch),
        updateProjectActions: bindActionCreators(updateProjectActions, dispatch),
        camerasActions: bindActionCreators(camerasActions, dispatch),
        ucpaActions: bindActionCreators(ucpaActions, dispatch),
        usersActions: bindActionCreators(usersActions, dispatch),
        bulkUsersActions: bindActionCreators(bulkUsersActions, dispatch),
        logoActions: bindActionCreators(logoActions, dispatch),
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectsContainer))
