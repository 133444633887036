import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Segment, Message, Modal, Grid } from 'semantic-ui-react'
import { getErrorMessage } from './../util/errors'

class RebootCameraBody extends Component {

    constructor(props) {
        super(props)
        this.state = { openModal: false }
    }

    getMessage = (blurb) => {

        if (this.props.error) {
            return (
                <Message negative>
                    <Message.Header>Error</Message.Header>
                    <p>{blurb}</p>
                </Message>
            )
        }

        if (this.props.success) {
            return (
                <Message success>
                    <Message.Header>Success</Message.Header>
                    <p>{blurb}</p>
                </Message>
            )
        }

        if (this.props.processing) {
           return (
                <Message>
                    <Message.Header>Rebooting...</Message.Header>
                    <p>The camera body is currently being rebooted.  This might take 1-2 minutes.</p>
                </Message>
           )
        }

        return (
            <Message>
                <Message.Header>Are you sure you want to reboot the camera body?</Message.Header>
                <p>While rebooting, the camera body will be unable to take any shots. If you are happy to proceed, please continue.</p>
            </Message>
        )
    }

    getError = () => {
        if (!this.props.error) {
            return null
        }
        let message = getErrorMessage(this.props.errors, 'cameras_id')
        if (!message) {
            message = getErrorMessage(this.props.errors, 'unknownErrorType')
        }
        return (message) ? message : "There was an unexpected error"
    }

    getSuccess = () => {
        if (this.props.details && this.props.details.message) {
            return this.props.details.message
        }
    }

    getBlurb = () => {
        if (this.props.error) {
            return this.getError()
        }
        if (this.props.success) {
            return this.getSuccess()
        }
        return null
    }


    getCancelButton = () => {
        const label = (this.props.success) ? "Close" : "Cancel"
        return (
            <Button onClick={() => { this.setState({openModal:false})}}>{label}</Button>
        )
    }

    getConfirmationButton = () => {

        if (this.props.success) {
            return null
        }

        return (
            <Button
                loading={this.props.processing}
                onClick={() => {
                    this.props.onRebootCameraBody()
                }} positive icon='checkmark' labelPosition='right' content='Reboot' />
        )
    }

    getButtons = () => {
        const cancelButton = this.getCancelButton()
        const confirmationButton = this.getConfirmationButton()
        return (
            <div>
                {cancelButton}
                {confirmationButton}
            </div>

        )
    }

    isReady = () => {
        return this.props.camera_control.system.details && this.props.camera_control.system.success === true
    }

    render = () => {

        if (!this.isReady()) {
            return (
                <div className='reboot-camera-body'>
                    <Segment>
                        <h4>Reboot Camera Body</h4>
                    {/*<Dimmer active inverted><Loader content='' /></Dimmer>*/}
                        <Grid columns='1'>
                            <Grid.Row>
                                <Grid.Column>
                                    <Grid.Column><div className="animated-blank-line" /></Grid.Column>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                </div>
            )
        } else {
            return (
                <div className='reboot-camera-body'>
                    <Segment>
                        <h4>Reboot Camera Body</h4>
                        <Button
                            fluid
                            onClick={()=> {
                                this.props.onResetRebootCameraBody() // To ensure nothing appears from previous state
                                this.setState({openModal:true})
                            }}>
                            Reboot
                        </Button>
                    </Segment>
                    <Modal size={"small"} open={this.state.openModal} onClose={() => {this.setState({openModal:false})}}>
                        <Modal.Header>Reboot Camera Body</Modal.Header>
                        <Modal.Content>
                            {this.getMessage(this.getBlurb())}
                        </Modal.Content>
                        <Modal.Actions>
                            {this.getButtons()}
                        </Modal.Actions>
                    </Modal>
                </div>
            )
        }


    }
}

RebootCameraBody.propTypes = {
    processing: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    details: PropTypes.object.isRequired,
    onRebootCameraBody: PropTypes.func.isRequired,
    onResetRebootCameraBody: PropTypes.func.isRequired,
    cameras_id: PropTypes.string.isRequired
}

export default RebootCameraBody
