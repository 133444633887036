import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { Button, Icon, Loader} from 'semantic-ui-react'
import {QRCodeSVG} from 'qrcode.react';


class MfaSetup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formError: null,
            verificationError: null,
            showLoader: false,
            showTotpCode: false,
        }
    }

    componentDidMount() {
        this.props.resetErrors()
        //this.props.resetTotpFactor()

        if (!this.props.auth.user_mfa_setup) {
            this.props.history.push('/auth')
        }
    }

    // componentDidUpdate(prevProps) {
    //     if (this.props.errors !== prevProps.errors && Object.keys(this.props.errors).length > 0) {
    //         this.setState({
    //             formError: (
    //                 <section className="fieldError">
    //                     {Object.values(this.props.errors)}
    //                 </section>
    //             )
    //         })
    //     }
    // }

    onSubmit = (event) => {
        event.preventDefault()

        let authType = document.activeElement.getAttribute('data-type')
        this.setState({method: authType})
        this.props.createTotp({
            channel: authType
        })
        return false
    }

    onContinue = async () => {
        const code = document.querySelector('.verify-token').value

       try {
            await this.props.verifyTotp({token: code}).then(() => {
                if (this.props.totpStatus === 'errors') {
                    this.setState({
                        verificationError: (
                            <section className="fieldError">
                                <strong>Verification failed</strong>
                            </section>
                        )
                    })
                } else if (this.props.totpStatus === 'verified') {
                   this.setState({
                        formError: null,
                        verificationError: false,
                   })
                    // Get time
                    let time = new Date().getTime();
                    let totalWaitTime = 30000;
                    // Check gap between current time and nearest 30s block
                    let timeToWait = totalWaitTime - time % totalWaitTime
                    // show animation
                    this.setState({showLoader: true})
                    setTimeout(() => {
                        this.setState({showLoader: false})
                        this.props.history.push('/auth/enter-token');
                    }, (timeToWait + 1000));
                }
            })
        } catch(err) {
            console.log(err)
        }
    }

    getParameterByName(name, totpUrl) {
        name = name.replace(/[\[\]]/g, '\\$&');
        let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(totpUrl);
        if (!results) return null;
        if (!results[2]) return '';
        return results[2].replace(/\+/g, ' ');
    }

    toggleTotpCode = () => {
        this.setState({showTotpCode: !this.state.showTotpCode})
    }

    render() {
        let totpCode = this.props.totpUrl && this.getParameterByName("secret", this.props.totpUrl)

        return (
            <div className='step'>
                <div className='step-inner' style={{background: "white"}}>
                    <h3>Set up two-step verification</h3>
                    
                    {this.state.formError}
                    {(this.props.totpStatus !== 'created' && !this.props.totpUrl) && !this.state.showLoader ?
                        <div className="mfa-setup__button-wrap">
                        
                            <Button
                                type="submit"
                                icon
                                labelPosition='left'
                                color='blue'
                                data-type="totp"
                                onClick={this.onSubmit}
                                disabled={!this.props.auth.user_mfa_setup}
                            >
                                <Icon name='mobile alternate' size='large' />
                            Generate QR Code
                            </Button>
                        </div>
                    : null}
                    {this.state.verificationError}
                    {(['created', 'errors'].includes(this.props.totpStatus) && this.props.totpUrl) ?
                        <>
                            <p style={{marginTop: '1rem'}}>Please scan the QR code below with the authentication application of your choice</p>
                            <QRCodeSVG style={{margin: '1rem 0'}} value={this.props.totpUrl} />
                            <div style={{marginBottom: '2rem'}}>
                                {(!this.state.showTotpCode)? 
                                    <Button onClick={this.toggleTotpCode}>Unable to scan the image?</Button>
                                :
                                    <>
                                        <p>
                                            <strong style={{marginBottom: '1rem'}}>Enter the following key into your authenticator app:</strong><br />
                                            {totpCode}
                                        </p>
                                    </>
                                }
                            </div>
                            
                            <div>
                                <label htmlFor="verify">Please enter your authentication code:</label>
                                <input className="verify-token" id="verify" type="text" aria-label="Authenticator code" />
                            </div>
                            <Button
                                positive
                                fluid
                                data-type="totp"
                                onClick={() => this.onContinue(this.props.totpUrl)}
                            >Continue</Button>
                        </>
                    : null}

                    {this.state.showLoader &&
                        <>
                            <Loader active inline='centered' indeterminate style={{marginTop: '1rem'}}>Validating, please wait...</Loader>
                            <small>Validation will only occur during initial setup.</small>
                        </>
                    }
                </div>
            </div>
        )
    }
}

export default MfaSetup

MfaSetup.propTypes = {
    history: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    number: PropTypes.string,
    errors: PropTypes.object,
    resetErrors: PropTypes.func.isRequired
}
