import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { withRouter, Link } from 'react-router-dom'
import React, { Component } from 'react';
import {
    Container,
    Button,
    Message,
    Dimmer,
    Input,
    Loader,
    Menu,
    Table,
    TableRow,
    TableCell
} from 'semantic-ui-react'
import TableColumn from './TableColumn'
import pagination from '../util/pagination'
import * as authActions from '../actions/authActions'
import * as addClientActions from '../actions/addClientActions'
import * as editClientActions from '../actions/editClientActions'
import * as clientsActions from '../actions/clientsActions'
import * as bulkUsersActions from '../actions/bulkUsersActions'
import * as logActions from '../actions/logActions'
import * as logoActions from '../actions/logoActions'
import * as messengerActions from '../actions/messengerActions'
import CreateClientModal from "./modals/CreateClient"
import UpdateClientModal from "./modals/UpdateClient"
import DeleteClientModal from "./modals/DeleteClient"
import { isPermitted } from "../util/acl"
import DocumentTitle from 'react-document-title'
import { checkAuth } from '../util/accessToken'
import debounce from 'lodash/debounce'

class Client extends Component {

    constructor(props) {
        super(props)
        this.state = { type: null }

        this.rowsPerPage = props.rowsPerPage || 10
        this.currentPage = props.currentPage || 1
        this.columns = [
            new TableColumn("name", "Name"),
            new TableColumn("role", "Role"),
            new TableColumn("status", "Status"),
            new TableColumn("mfa", "MFA"),
            new TableColumn("actions", "Actions", false)
        ]
    }

    componentDidMount() {
        window.scrollTo(0, 0)

        checkAuth(this.props.auth, this.props.authActions, this.props.history)
        .then(() => {
            // Reset any filtering on the user list
            this.props.clientsActions.clientsReset()
            .then(() => {
                this.reload()
            })

            this.props.addClientActions.resetSuccess()
            this.props.addClientActions.resetErrors()

            this.props.addClientActions
                .adminClientRolesRequest()
            this.props.addClientActions
                .adminClientStatusesRequest()
            this.props.clientsActions.clientMfaRequirements()
        })
        .catch(err => {})
    }

    componentDidUpdate(prevProps, prevState) {
       
        if ((this.props.clients.searchTerm !== prevProps.clients.searchTerm) && this.props.clients.searchTerm !== null) {
            this.updateURLFragment()
        }
    }

    getClientId = () => {
        if (this.props.match.params.clientId) {
            return parseInt(this.props.match.params.clientId, 10)
        } else {
            return undefined
        }
    }

    reload = () => {
        this.props.clientsActions.clientsRequest(
            this.rowsPerPage,
            this.currentPage,
            this.props.clients.sortColumn,
            this.props.clients.sortAscending,
            this.props.clients.searchTerm
        )
    }

    handleCreateClientClick = (clientId) => {
        this.props.addClientActions.resetErrors()
        this.show("CreateClient", clientId)
    }

    handleCreateClientConfirmedClick = (args) => {
        this.props.addClientActions.addClientRequest(this.props.history, args)
    }

    handleUpdateClientConfirmedClick = (clientId, args) => {
        this.props.editClientActions.editClientRequest(null, clientId, args)
    }

    handleDeleteClientClick = () => {
        this.props.editClientActions.resetErrors()
        this.show("DeleteClient", this.state.clientId)
    }

    handleDeleteClientConfirmedClick = (clientId) => {
        this.props.editClientActions.deleteClientRequest(clientId)
    }

    closeCreateClient = () => {
        this.props.addClientActions.resetSuccess()
        this.setState({open: false, type: null})
        this.reload()
    }

    closeUpdateClient = (_e, _d, stepsBack = 1) => {
        const steps = 0 - stepsBack // go expects negative value
        this.props.history.go(steps)
        this.props.addClientActions.resetSuccess()
        this.setState({open: false, type: null})
        this.reload()
    }

    show = (type, clientId) => {
        this.setState({open: true, type: type, clientId: clientId})
    }

    /**
     * Sort results by the selected column
     *
     * @param {string} key - Key value from TableColumn class
     */
    handleColumnHeadingClick(key) {
        let ascending = this.props.clients.sortColumn === key ? !this.props.clients.sortAscending : true
        this.props.clientsActions.clientsRequest(
            this.rowsPerPage,
            this.props.clients.currentPage,
            key,
            ascending,
            this.props.clients.searchTerm
        )
    }

    /**
     * Jump to a particular page in the results set
     *
     * @param {string} name - The page number label ("1", "2", etc)
     */
    handlePaginationPageClick = (e, { name }) => {
        const page = parseInt(name, 10)
        this.props.clientsActions.clientsRequest(
            this.rowsPerPage,
            page,
            this.props.clients.sortColumn,
            this.props.clients.sortAscending,
            this.props.clients.searchTerm
        )
    }

    /** Jump to the previous page in the results set */
    handlePaginationPrevClick = (e) => {
        if (this.props.clients.currentPage > 1 ) {
            const prevPage = this.props.clients.currentPage - 1
            this.props.clientsActions.clientsRequest(
                this.rowsPerPage,
                prevPage,
                this.props.clients.sortColumn,
                this.props.clients.sortAscending,
                this.props.clients.searchTerm
            )
        }
    }

    /** Jump to the next page in the results set */
    handlePaginationNextClick = (e) => {
        const totalPages = Math.ceil(this.props.clients.totalRows/this.rowsPerPage)
        if (this.props.clients.currentPage < totalPages) {
            const nextPage = this.props.clients.currentPage + 1
            this.props.clientsActions.clientsRequest(
                this.rowsPerPage,
                nextPage,
                this.props.clients.sortColumn,
                this.props.clients.sortAscending,
                this.props.clients.searchTerm
            )
        }
    }

    /**
     * Filter result set by search data
     *
     * @param {string} data - Value to search by
     */
    onSearchInputChange = debounce((data, event) => {
        if (event.value.length > 1) {
            this.props.clientsActions.clientsRequest(
                this.rowsPerPage,
                1,
                this.props.clients.sortColumn,
                this.props.clients.sortAscending,
                event.value)
        } else if (this.props.clients.filtered) {
            this.props.clientsActions.clientsRequest(
                this.rowsPerPage,
                1,
                this.props.clients.sortColumn,
                this.props.clients.sortAscending,
                undefined
            )
        }

        this.updateURLFragment()
    }, 800)

    updateURLFragment = () => {
        const preferences = []
        if (this.props.clients.searchTerm) {
            preferences["search"] = this.props.clients.searchTerm
        }

        preferences["sort"] = this.props.clients.sortColumn

        preferences["ascending"] = this.props.clients.sortAscending

        let hashPartBuffer = [];
        for (let k in preferences) {
            hashPartBuffer.push(
                encodeURIComponent(k), '=', encodeURIComponent(preferences[k]),
                '&'
            )
        }

        if (hashPartBuffer.length) {
          hashPartBuffer.pop()
        }
        window.location.hash = hashPartBuffer.join('')
    }

    parseURLFragment = () => {
        const hash = window.location.hash.substring(1)
        let pairs = hash.split(/&/)
        const preferences = {}
        for (let i = 0; i < pairs.length; i++) {
            const keyValue = pairs[i].split(/=/)
            // Validate that this has the right structure.
            if (keyValue.length === 2) {
                const key = decodeURIComponent(keyValue[0])
                const value = decodeURIComponent(keyValue[1])
                if (value.indexOf('|') < 0) {
                    preferences[key] = value
                } else {
                    preferences[key] = value.split('|')
                }
            }
        }

        // // Update preferences
        if ('search' in preferences) {
            this.props.clientsActions.clientsFilterBy(preferences['search'])
        }
    }

    /** Render the table header row using this.columns */
    renderHeader() {
        return (
            <TableRow>
                {this.columns.map((column, k) => {
                    let sortedBy = null
                    if (this.props.clients.sortColumn === column.key) {
                        sortedBy = this.props.clients.sortAscending ? "ascending" : "descending"
                    }
                    let click = null
                    if (column.sortable) {
                        click = (e) => {this.handleColumnHeadingClick(column.key)}
                    }
                    return (
                        <Table.HeaderCell sorted={sortedBy} onClick={click} key={k}>
                            {column.title}
                        </Table.HeaderCell>
                    )
                })}
            </TableRow>
        )
    }

    /**
     * Render a single table row of results
     *
     * @param {Object} row - An object containing one row of results
     * @param {number} index - The current row number being rendered
     */
    renderBodyRow = (row, index) => {
        if (row.role === 'Lobster Pictures') {
            return null
        }

        const clientName = (isPermitted(this.props.auth.user, 'get-clients-clients')) ?
            <Link to={`/clients/${row.id}${(this.props.auth.user && this.props.auth.user.roles_id === 5)?  '/edit': '' }`}>
                {row.name}
            </Link>
            : row.name

        const viewClientLink = (isPermitted(this.props.auth.user, 'get-clients-clients')) ?
            <Button
                as={Link}
                to={`/clients/${row.id}`}
                icon='eye'
                basic
                compact
                size='tiny'
                content='View'
            /> : null

        const editClientLink = (isPermitted(this.props.auth.user, 'put-clients-clients')) ?
            <Button
                as={Link}
                to={`/clients/${row.id}/edit`}
                icon='cogs'
                basic
                color='blue'
                compact
                size='tiny'
                content='Edit'
            /> : null
        return (
            <TableRow key={row.id}>
                <TableCell>{clientName}</TableCell>
                <TableCell>{row.role}</TableCell>
                <TableCell>{row.status}</TableCell>
                <TableCell>{row.mfaRequirement}</TableCell>
                <TableCell>
                    <ul style={{padding: 0, margin: 0}}>
                        {editClientLink}
                        {this.props.auth.user && this.props.auth.user.roles_id !== 5 && viewClientLink}
                    </ul>
                </TableCell>
            </TableRow>
        )
    }

    /** Render the table footer row */
    renderFooter() {
        if (this.props.clients.totalRows === 0) {
            return (
                <TableRow>
                    <TableCell colSpan={this.columns.length}>
                        {this.props.clients.errors.error !== true ?
                            "No results found" : (
                                <Message
                                    error={true}
                                    visible={this.props.clients.errors.error}
                                    header="Error"
                                    content={this.props.clients.errors.message}
                                />
                            )

                        }
                    </TableCell>
                </TableRow>
            )
        }

        const totalPages = Math.ceil(this.props.clients.totalRows/this.rowsPerPage)
        const first = ((this.props.clients.currentPage - 1) * this.rowsPerPage) + 1
        let last = this.props.clients.currentPage * this.rowsPerPage
        if (last > this.props.clients.totalRows) last = this.props.clients.totalRows
        let pages = pagination(this.props.clients.currentPage, totalPages)
        let menuPagination = (
            <Menu pagination>
                <Menu.Item name="prev" onClick={this.handlePaginationPrevClick} icon="angle left" />
                {pages.map((page, key) => {
                    return (
                        <Menu.Item name={page.toString()} key={key} active={this.props.clients.currentPage === page} onClick={this.handlePaginationPageClick} />
                    )
                })}
                <Menu.Item name="next" onClick={this.handlePaginationNextClick} icon="angle right" />
            </Menu>
        )
        return (
            <TableRow>
                <TableCell colSpan={this.columns.length}>
                    <div>Showing {first} - {last} of {this.props.clients.totalRows} {this.props.clients.totalRows === 1 ? "result" : "results"}</div>
                    <div style={{textAlign: 'center'}}>
                        {totalPages > 1 ? menuPagination : null}
                    </div>
                </TableCell>
            </TableRow>
        )
    }


    render() {

        const {type, clientId} = this.state
        const action = this.props.match.params.action

        const roles = this.props.addClient.roles.map(function(elem, idx) {
            return {
                value: elem.name,
                text: elem.name,
                key: idx
            }
        });
        const statuses = this.props.addClient.statuses.map(function(elem, idx) {
            return {
                value: elem.name,
                text: elem.name,
                key: idx
            }
        });

        const mfaRequirements = (this.props.clients.mfaRequirements)? this.props.clients.mfaRequirements.map((elem, idx) => {
            return {
                value: elem.name,
                text: elem.name,
                key: idx
            }
        }) : null

        const createClientButton = (isPermitted(this.props.auth.user, 'post-clients-clients')) ? 
            <Button
                onClick={this.handleCreateClientClick}
                primary
                content='Create client'
            /> : null

        const removeClientLink = (isPermitted(this.props.auth.user, 'delete-clients-clients')) ?
            <Button
                style={{
                    float: 'left'
                }}
                icon='trash'
                color='red'
                onClick={() => {
                    this.handleDeleteClientClick()
                }}
                content='Delete Client'
            /> : null

        return (
            <div className='clients-container'>
            <DocumentTitle title='Clients' />
            <header className="sub-header sub-header--clients">
                <Container fluid={true} style={{padding: '0 1.2em'}}>
                    {/* <h2>Clients</h2> */}
                    <Input
                        loading={this.props.clients.filtered && this.props.clients.loading}
                        placeholder='Search...'
                        defaultValue={this.props.clients.searchTerm}
                        onChange={(e, data) => {this.onSearchInputChange(e, data)}}
                    />
                    {createClientButton}
                </Container>
            </header>
           
            <Dimmer.Dimmable>
                <Dimmer active={this.props.clients.loading} inverted>
                    <Loader />
                </Dimmer>
                <Container className="content-container" fluid={true} style={{padding: '0 1.2em'}}>
                    <Table
                        renderBodyRow={this.renderBodyRow}
                        headerRow={this.renderHeader()}
                        tableData={this.props.clients.tableData}
                        footerRow={this.renderFooter()}
                        sortable
                    />
                </Container>
            </Dimmer.Dimmable>
            <CreateClientModal
                open={type === "CreateClient"}
                onClose={this.closeCreateClient}
                error={Object.keys(this.props.addClient.errors).length > 0}
                errors={this.props.addClient.errors}
                onClick={ (args) => { this.handleCreateClientConfirmedClick(args)} }
                roles={roles}
                statuses={statuses}
                success={this.props.addClient.success}
                loading={this.props.addClient.loading}
            />
            <UpdateClientModal
                open={action === 'edit'}
                onClose={this.closeUpdateClient}
                error={Object.keys(this.props.editClient.errors).length > 0}
                errors={this.props.editClient.errors}
                onClick={ (clientId, args) => {
                    this.handleUpdateClientConfirmedClick(clientId, args)}
                }
                roles={roles}
                statuses={statuses}
                client={this.props.editClient}
                clientId={this.getClientId()}
                success={this.props.editClient.success}
                removeClientLink={removeClientLink}

                // Supporting LogoUploader
                editClientActions={this.props.editClientActions}
                logoActions={this.props.logoActions}
                logo={this.props.logo}
                loading={this.props.editClient.loading}
                user={this.props.auth.user}
                mfaOptions={mfaRequirements}
            />
            <DeleteClientModal
                open={type === "DeleteClient"}
                onClose={this.closeUpdateClient}
                error={Object.keys(this.props.editClient.errors).length > 0}
                errors={this.props.editClient.errors}
                onClick={ () => { this.handleDeleteClientConfirmedClick(clientId)}}
                success={(() => {
                    const c = this.props.editClient.deleted.find((e, idx) => {
                        return e.clients_id === clientId
                    })
                    if (!c) {
                        return false
                    }
                    return c.status === "Deleted"
                })()}
                loading={(() => {
                    const c = this.props.editClient.deleted.find((e, idx) => {
                        return e.clients_id === clientId
                    })
                    if (!c) {
                        return false
                    }
                    return c.status === "Pending"
                })()}
            />
          </div>
        )
    }
}

Client.propTypes = {
    authActions: PropTypes.object,
    auth: PropTypes.object,
    clientsActions: PropTypes.object,
    logActions: PropTypes.object,
    messengerActions: PropTypes.object,
    logoActions: PropTypes.object,
    clients: PropTypes.shape({
        currentPage: PropTypes.number,
        errors: PropTypes.object,
        filtered: PropTypes.bool,
        loading: PropTypes.bool,
        sortAscending: PropTypes.bool,
        sortColumn: PropTypes.string,
        tableData: PropTypes.array.isRequired,
        totalRows: PropTypes.number
    }).isRequired,
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        addClient: state.addClient,
        editClient: state.editClient,
        clients: state.clients,
        bulkUsers: state.bulkUsers,
        logo: state.logo
    }
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(authActions, dispatch),
        addClientActions: bindActionCreators(addClientActions, dispatch),
        editClientActions: bindActionCreators(editClientActions, dispatch),
        clientsActions: bindActionCreators(clientsActions, dispatch),
        bulkUsersActions: bindActionCreators(bulkUsersActions, dispatch),
        logActions: bindActionCreators(logActions, dispatch),
        messengerActions: bindActionCreators(messengerActions, dispatch),
        logoActions: bindActionCreators(logoActions, dispatch),
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Client))
