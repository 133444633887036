import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Segment } from 'semantic-ui-react'

class CameraPower extends Component {

    render() {
        if (this.props.details && !this.props.details.power) {
            return null
        }
        const power = this.props.details.power
        return  (
            <Segment>
                <h4>Power</h4>
                <p>Status: {power.power_state}, {power.battery_present}</p>
                <p>Charge: {power.charge}%</p>
            </Segment>
        )

    }
}

CameraPower.propTypes = {
    loading: PropTypes.bool.isRequired,
    details: PropTypes.object
}

export default CameraPower
