import React, { Component } from 'react'
import {
    Button,
    Checkbox,
    Dimmer,
    Divider,
    Form,
    Grid,
    Icon,
    Loader,
    Message,
    Modal,
    Segment,
    Statistic
} from 'semantic-ui-react'

class CameraUploadQueue extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showClearQueueConfirmation: false,
            filter: null,
            limit: 100,
            addToQueue: [],
            removeFromQueue: []
        }
        this.dropLastClicked = null
        this.queueLastClicked = null
    }

    componentDidMount() {
        this.props.dropActions.reset()
        this.props.uploadQueueActions.reset()
        this.props.uploadQueueActions.load(this.props.cameraId)
    }

    getClearQueueConfirmation = () => {
        return (
            <Modal
                open={this.state.showClearQueueConfirmation}
                onClose={this.hideClearQueueConfirmation}
            >
                <Modal.Header>
                    Delete Images
                </Modal.Header>
                <Modal.Content>
                    <p>Are you sure you want to clear the upload queue?</p>
                    <div className='modalButtons'>
                    <Button
                        onClick={this.hideClearQueueConfirmation}
                        negative
                        content='No'
                    />
                    <Button
                        onClick={this.clearQueue}
                        positive
                        loading={this.props.uploadQueue.clearing}
                        icon='checkmark'
                        labelPosition='right'
                        content='Yes'
                    />
                    </div>
                </Modal.Content>
            </Modal>
        )
    }

    hideClearQueueConfirmation = () => {
        this.setState({showClearQueueConfirmation: false})
    }

    showClearQueueConfirmation = () => {
        this.setState({showClearQueueConfirmation: true})
    }

    clearQueue = () => {
        this.props.uploadQueueActions.clear(this.props.cameraId,
                                            this.hideClearQueueConfirmation)
    }

    startQueue = () => {
        this.props.uploadQueueActions.start(this.props.cameraId)
    }

    stopQueue = () => {
        this.props.uploadQueueActions.stop(this.props.cameraId)
    }

    updateFilter = (event, {value}) => {
        this.setState({filter: value})
    }

    updateLimit = (event, {value}) => {
        this.setState({limit: value})
    }

    loadDrop = (event) => {
        this.props.dropActions.load(this.props.cameraId,
                                    this.state.filter, this.state.limit)
    }

    getDropCount = () => {
        if (this.props.drop.loading) {
            return null
        }
        if (this.props.drop.files > 0) {
            return (<Message>{this.props.drop.files} files loaded.</Message>)
        } else {
            return (<Message>No files loaded.</Message>)
        }
    }

    selectDropFile = (event, data) => {
        const file = data.label
        const checked = data.checked
        if (event.shiftKey && this.dropLastClicked) {
            // Multiselect
            // Find all in props.drop.drop between last clicked and file
            const start = this.props.drop.drop.indexOf(this.dropLastClicked)
            const end = this.props.drop.drop.indexOf(file)
            const range = this.props.drop.drop.slice(Math.min(start, end),
                                                     Math.max(start, end)+1)
            let addToQueue = this.state.addToQueue
            for (var i=0; i < range.length; i++) {
                const index = addToQueue.indexOf(range[i])
                if (checked) {
                    if (index === -1) {
                        addToQueue = addToQueue.concat([range[i]])
                    }
                } else {
                    if (index > -1) {
                        addToQueue = [
                            ...addToQueue.slice(0, index),
                            ...addToQueue.slice(index+1)
                        ]
                    }
                }
                this.setState({addToQueue: addToQueue})
            }
        } else {
            // Add / remove single file
            if (checked) {
                this.setState((state) => (
                    { addToQueue: state.addToQueue.concat([file]) }
                ))
            } else {
                const index = this.state.addToQueue.indexOf(file)
                if (index > -1) {
                    this.setState((state) => (
                        { addToQueue: [
                            ...state.addToQueue.slice(0, index),
                            ...state.addToQueue.slice(index+1)
                        ]}
                    ))
                }
            }
            this.dropLastClicked = file
        }
    }

    clearDropSelected = () => {
        this.setState({addToQueue: []})
    }

    loadQueue = (event) => {
        this.props.uploadQueueActions.load(this.props.cameraId)
    }

    addToQueue = (event) => {
        this.props.uploadQueueActions.addImages(
            this.props.cameraId, this.state.addToQueue, this.clearDropSelected
        )
    }

    removeFromQueue = (event) => {
        this.props.uploadQueueActions.removeImages(
            this.props.cameraId, this.state.removeFromQueue, this.clearQueueSelected
        )
    }

    selectQueueFile = (event, data) => {
        const file = data.label
        const checked = data.checked 
        if (event.shiftKey && this.queueLastClicked) {
            // Multiselect
            // Find all in props.drop.drop between last clicked and file
            const start = this.props.uploadQueue.queue.indexOf(this.queueLastClicked)
            const end = this.props.uploadQueue.queue.indexOf(file)
            const range = this.props.uploadQueue.queue.slice(Math.min(start, end),
                                                     Math.max(start, end)+1)
            let removeFromQueue = this.state.removeFromQueue
            for (var i=0; i < range.length; i++) {
                const index = removeFromQueue.indexOf(range[i])
                if (checked) {
                    if (index === -1) {
                        removeFromQueue = removeFromQueue.concat([range[i]])
                    }
                } else {
                    if (index > -1) {
                        removeFromQueue = [
                            ...removeFromQueue.slice(0, index),
                            ...removeFromQueue.slice(index+1)
                        ]
                    }
                }
                this.setState({removeFromQueue: removeFromQueue})
            }
        } else {
            // Add / remove single file
            if (checked) {
                this.setState((state) => (
                    { removeFromQueue: state.removeFromQueue.concat([file]) }
                ))
            } else {
                const index = this.state.removeFromQueue.indexOf(file)
                if (index > -1) {
                    this.setState((state) => (
                        { removeFromQueue: [
                            ...state.removeFromQueue.slice(0, index),
                            ...state.removeFromQueue.slice(index+1)
                        ]}
                    ))
                }
            }
            this.queueLastClicked = file
        }
    }

    clearQueueSelected = () => {
        this.setState({removeFromQueue: []})
    }

    getQueueStats = () => {
        if (this.props.uploadQueue.loading) {
            return null
        }
        const queueSizeMB = Math.floor(
                                this.props.uploadQueue.size / 1024 / 1024 * 100
                            ) / 100
        const noun = this.props.uploadQueue.files === 1 ? 'image' : 'images'
        return (
            <div style={{float: "right"}}>
                <Statistic size='mini'>
                    <Statistic.Value>
                        {this.props.uploadQueue.files}
                    </Statistic.Value>
                    <Statistic.Label>{noun}</Statistic.Label>
                </Statistic>
                <Statistic size='mini'>
                    <Statistic.Value>{queueSizeMB}</Statistic.Value>
                    <Statistic.Label>MB</Statistic.Label>
                </Statistic>
            </div>
        )
    }

    getModifyQueueSpinner = () => {
        if (!this.props.uploadQueue.modifying) {
            return null
        }
        return (
            <Dimmer active inverted>
                <Loader size='small'>Working</Loader>
            </Dimmer>
        )
    }

    getStartStopQueueButton = () => {
        if (this.props.uploadQueue.started) {
            return (
                <Form.Button
                    loading={this.props.uploadQueue.startingStopping}
                    onClick={this.stopQueue}
                >
                    Stop Queue
                </Form.Button>
            )
        } else {
            return (
                <Form.Button
                    loading={this.props.uploadQueue.startingStopping}
                    onClick={this.startQueue}
                >
                    Start Queue
                </Form.Button>
            )
        }
    }

    render() {
        const limitOptions = [
          { key: 'twenty', text: '20', value: 20 },
          { key: 'fifty', text: '50', value: 50 },
          { key: 'hunded', text: '100', value: 100 },
          { key: 'fivehundred', text: '500', value: 500 },
          { key: 'all', text: 'All', value: null }
        ]
        return (
            <div>
            {this.getClearQueueConfirmation()}
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={7}>
                            <Segment.Group>
                                <Segment inverted>
                                    <h4>Drop</h4>
                                </Segment>
                                <Segment>
                                    <Form>
                                        <Form.Group>
                                            <Form.Input
                                                width={8}
                                                fluid
                                                label='Search'
                                                placeholder='prefix'
                                                onChange={this.updateFilter}
                                            />
                                            <Form.Select
                                                width={3}
                                                value={this.state.limit}
                                                fluid
                                                label='Limit'
                                                options={limitOptions}
                                                onChange={this.updateLimit}
                                            />
                                            <Form.Button
                                                style={{marginTop: '1.7em'}}
                                                loading={this.props.drop.loading}
                                                onClick={this.loadDrop}
                                            >
                                                {this.props.drop.success ? 'Reload' : 'Load'}
                                            </Form.Button>
                                        </Form.Group>
                                    </Form>
                                    <Divider />
                                    {this.getDropCount()}
                                    {this.props.drop.drop.map((file, key) => {
                                        return (
                                            <div key={key}>
                                                <Checkbox
                                                    label={file}
                                                    onClick={this.selectDropFile}
                                                    checked={this.state.addToQueue.indexOf(file) > -1}
                                                />
                                            </div>
                                        )
                                    })}
                                </Segment>
                            </Segment.Group>
                        </Grid.Column>
                        <Grid.Column width={2} textAlign="center">
                            {this.getModifyQueueSpinner()}
                            <Icon
                                name="arrow circle right"
                                link
                                onClick={this.addToQueue}
                                size="huge"
                                color="green"
                                data-testid="button--add-to-queue"
                            />
                            <br />
                            <Icon
                                name="arrow circle left"
                                link
                                onClick={this.removeFromQueue}
                                size="huge"
                                color="red"
                            />
                        </Grid.Column>
                        <Grid.Column width={7}>
                            <Segment.Group>
                                <Segment inverted>
                                    <h4>Queue</h4>
                                </Segment>
                                <Segment>
                                    <Form>
                                        {this.getQueueStats()}
                                        <Form.Group>
                                            <Form.Button
                                                loading={this.props.uploadQueue.loading}
                                                onClick={this.loadQueue}
                                            >
                                                Reload
                                            </Form.Button>
                                            <Form.Button
                                                onClick={this.showClearQueueConfirmation}
                                            >
                                                Clear
                                            </Form.Button>
                                            {this.getStartStopQueueButton()}
                                        </Form.Group>
                                    </Form>
                                    <Divider />
                                    {this.props.uploadQueue.queue && <div data-testid="list--camera-queue">
                                        {this.props.uploadQueue.queue.map((data) => {
                                            // data is an array of filename and size
                                            const file = data[0]
                                            return (
                                                <div key={`uq_${file}`}>
                                                    <Checkbox
                                                        label={file}
                                                        onClick={this.selectQueueFile}
                                                        checked={this.state.removeFromQueue.indexOf(file) > -1}
                                                    />
                                                </div>
                                            )
                                        })}
                                    </div>}
                                </Segment>
                            </Segment.Group>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        )
    }
}

export default CameraUploadQueue
