import * as types from './actionTypes'
import { API_BASE_URL}  from '../config'
import { addMessage } from './messengerActions'
import { log } from './logActions'
import { notAuthorisedResponse } from './authActions'
import { fetchWithToken } from '../util/accessToken'

export function loadPCIDs() {
    return (dispatch, getState) => {
        dispatch({ type: types.POT_ASSIGN_FETCH_PCIDS_REQUEST})
        let url = `${API_BASE_URL}/api/pot-assign/pcids`

        return fetchWithToken(
            url,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.POT_ASSIGN_FETCH_PCIDS_ERRORS,
                                    errors: data.errors,
                                })
                            dispatch(
                                log(
                                    'Error',
                                    'There was an error while requesting the list of PCIDs'
                                )
                            )
                        } else {
                            // Error
                            dispatch(
                                {
                                    type: types.POT_ASSIGN_FETCH_PCIDS_ERRORS,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error"
                                        }
                                    }
                                }
                            )
                            dispatch(
                                log(
                                    'Error',
                                    'There was an unexpected error while requesting the list of PCIDs'
                                )
                            )
                        }
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to load pot assign",
                                "An error ocurred whilst loading the list of PCIDs."
                            )
                        )
                    })
                }
            } else {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.POT_ASSIGN_FETCH_PCIDS_ERRORS,
                                errors: data.errors,
                            }
                        )
                        dispatch(
                            log(
                                'Error',
                                'There was an error while loading the list of PCIDs.'
                            )
                        )
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to load pot assign",
                                "An error ocurred whilst loading the list of PCIDs."
                            )
                        )
                    } else {
                        dispatch(
                            {
                                type: types.POT_ASSIGN_FETCH_PCIDS_SUCCESS,
                                pcids: data
                            }
                        )
                    }

                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function identify(pcid) {
    return (dispatch, getState) => {
        dispatch({
            type: types.POT_ASSIGN_IDENTIFY_REQUEST,
            pcid: pcid
        })
        let url = `${API_BASE_URL}/api/pot-assign/pcids/${pcid}/identify`

        return fetchWithToken(
            url,
            {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.POT_ASSIGN_IDENTIFY_ERROR,
                                    errors: data.errors,
                                })
                            dispatch(
                                log(
                                    'Error',
                                    'There was an error while trying to identify the pot'
                                )
                            )
                        } else {
                            // Error
                            dispatch(
                                {
                                    type: types.POT_ASSIGN_IDENTIFY_ERROR,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error"
                                        }
                                    }
                                }
                            )
                            dispatch(
                                log(
                                    'Error',
                                    'There was an error while trying to identify the pot'
                                )
                            )
                        }
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to identify pot",
                                "There was an error while trying to identify the pot."
                            )
                        )
                    })
                }
            } else {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.POT_ASSIGN_IDENTIFY_ERROR,
                                errors: data.errors,
                            }
                        )
                        dispatch(
                            log(
                                'Error',
                                'There was an error while trying to identify the pot.'
                            )
                        )
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to identify pot",
                                "There was an error while trying to identify the pot"
                            )
                        )
                    } else {
                        dispatch(
                            {
                                type: types.POT_ASSIGN_IDENTIFY_SUCCESS,
                            }
                        )
                        dispatch(
                            addMessage(
                                "positive",
                                "Pot Identified",
                                "Please check the pot for visual indication."
                            )
                        )
                    }

                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function nextPotId(callback) {
    return (dispatch, getState) => {
        dispatch({ type: types.POT_ASSIGN_NEXT_POT_REQUEST})
        let url = `${API_BASE_URL}/api/pot-assign/pots/next`

        return fetchWithToken(
            url,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.POT_ASSIGN_NEXT_POT_ERROR,
                                    errors: data.errors,
                                })
                            dispatch(
                                log(
                                    'Error',
                                    'There was an error while trying to get the next Pot ID'
                                )
                            )
                        } else {
                            // Error
                            dispatch(
                                {
                                    type: types.POT_ASSIGN_NEXT_POT_ERROR,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error"
                                        }
                                    }
                                }
                            )
                            dispatch(
                                log(
                                    'Error',
                                    'There was an error while trying to get the next Pot ID'
                                )
                            )
                        }
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to get determine next pot",
                                "There was an error while trying to get the next Pot ID"
                            )
                        )
                    })
                }
            } else {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.POT_ASSIGN_NEXT_POT_ERROR,
                                errors: data.errors,
                            }
                        )
                        dispatch(
                            log(
                                'Error',
                                'There was an error while trying to get the next Pot ID.'
                            )
                        )
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to get determine next pot",
                                "There was an error while trying to get the next Pot ID"
                            )
                        )
                    } else {
                        dispatch(
                            {
                                type: types.POT_ASSIGN_NEXT_POT_SUCCESS,
                            }
                        )
                        callback(data.result)
                    }

                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function assign(pcid, pot, callback) {
    return (dispatch, getState) => {
        dispatch({ type: types.POT_ASSIGN_ASSIGN_REQUEST})
        let url = `${API_BASE_URL}/api/pot-assign/pots/${pot}`
            +`/pcid/${pcid}`

        return fetchWithToken(
            url,
            {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.POT_ASSIGN_ASSIGN_ERROR,
                                    errors: data.errors,
                                })
                            dispatch(
                                log(
                                    'Error',
                                    'There was an error while trying to assign a PCID to a pot'
                                )
                            )
                        } else {
                            // Error
                            dispatch(
                                {
                                    type: types.POT_ASSIGN_ASSIGN_ERROR,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error"
                                        }
                                    }
                                }
                            )
                            dispatch(
                                log(
                                    'Error',
                                    'There was an error while trying to assign a PCID to a pot'
                                )
                            )
                        }
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to assign",
                                "There was an error while trying to assign a PCID to a pot."
                            )
                        )
                    })
                }
            } else {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.POT_ASSIGN_ASSIGN_ERROR,
                                errors: data.errors,
                            }
                        )
                        dispatch(
                            log(
                                'Error',
                                'There was an error while trying to assign a PCID to a pot'
                            )
                        )
                        dispatch(
                            addMessage(
                                "negative",
                                "Unable to assign",
                                "There was an error while trying to assign a PCID to a pot."
                            )
                        )
                    } else {
                        dispatch(
                            addMessage(
                                "positive",
                                "PCID Assigned",
                                "The PCID was successfully assigned to the pot."
                            )
                        )
                        dispatch(
                            {
                                type: types.POT_ASSIGN_ASSIGN_SUCCESS,
                            }
                        )
                        callback()
                        dispatch(loadPCIDs())
                    }

                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function reset() {
    return { type: types.POT_ASSIGN_RESET }
}
