import React, {useEffect} from "react";
import { Container, Button } from 'semantic-ui-react'
import DocumentTitle from 'react-document-title'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import * as authActions from '../../actions/authActions'
import { bindActionCreators } from 'redux'
import { checkAuth } from '../../util/accessToken'

const Contact = (props) => {

    useEffect(() => {
        checkAuth(props.auth, props.authActions, props.history)
    }, [])

    return (
        <>
            <DocumentTitle title='Contact Us' />
            <header className="sub-header sub-header--users">
                <Container fluid={true} style={{padding: '0 1.2em'}}>
                    <h2>Contact Us</h2>
                </Container>
            </header>
            <Container className="content-container" fluid={true} style={{padding: '0 1.2em'}}>

            </Container>
        </>
    )
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(authActions, dispatch),
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Contact))