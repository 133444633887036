import * as types from './actionTypes'
import { addMessage } from './messengerActions'
import { log } from './logActions'
import { API_BASE_URL }  from '../config'
import { fetchWithToken } from '../util/accessToken'
import { notAuthorisedResponse } from './authActions'

/**
 *
 * Creates a new project for a client
 *
 * @param details
 * @param clientId
 * @returns {function(*)}
 */
export function createProjectRequest(projectDetails, clientId) {

    return (dispatch, getState) => {
        dispatch({ type: types.CREATE_PROJECT_REQUEST, details: projectDetails, clients_id: clientId})
        // Depending on the context....build up the URL appropriately
        const url = (clientId) ? `${API_BASE_URL}/api/clients/${clientId}/projects` : `${API_BASE_URL}/api/projects`
        return fetchWithToken(
            url,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(projectDetails)
            },
            dispatch,
            getState
        )
        .then(response => {
            if (response.status === 400) {
                response.json().then((data) => {
                    if (data.errors) {
                        dispatch(createProjectResponseErrors(data))
                    }
                })
            } else if (response.status === 401) {
                dispatch(notAuthorisedResponse())
            } else {
                return response.json()
            }
        })
        .then((data) => {
            if (data.errors) {
                dispatch(createProjectResponseErrors(data))
            } else {
                dispatch(
                    addMessage(
                        "success",
                        "Success",
                        "Successfully created new project."
                    )
                )
                dispatch(createProjectResponseSuccess(data))
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function createProjectResponseSuccess(response) {
    return { type: types.CREATE_PROJECT_RESPONSE_SUCCESS, ...response }
}

export function createProjectResponseErrors(response) {
    return { type: types.CREATE_PROJECT_RESPONSE_ERRORS, ...response }
}

export function resetErrors() {
    return { type: types.CREATE_PROJECT_RESET_ERRORS }
}

/**
 *
 * Will fetch a list of clients to pre-populate the CreateProject modal if
 * Lobster Admin
 *
 * @returns {function(*=)}
 */
export function clientsRequest() {
    return (dispatch, getState) => {
        dispatch({ type: types.CREATE_PROJECT_CLIENTS_REQUEST })

        const url =  `${API_BASE_URL}/api/admin/clients/list`
        return fetchWithToken(
            url,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (response.status === 400) {
                response.json().then((data) => {
                    if (data.errors) {
                        dispatch(clientsResponseErrors(data))
                    }
                })
            } else if (response.status === 401) {
                dispatch(notAuthorisedResponse())
            } else {
                return response.json()
            }
        })
        .then((data) => {
            if (data.errors) {
                dispatch(clientsResponseErrors(data))
            } else {
                dispatch(clientsResponseSuccess(data))
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function clientsResponseSuccess(response) {
    return { type: types.CREATE_PROJECT_CLIENTS_RESPONSE_SUCCESS, clients: response  }
}

export function clientsResponseErrors(response) {
    return { type: types.CREATE_PROJECT_CLIENTS_RESPONSE_SUCCESS, ...response }
}

export function projectCamerasAvailableCameras(availableCameras) {
    return { type: types.CREATE_PROJECT_PROJECT_CAMERAS_AVAILABLE_CAMERAS, cameras: availableCameras}
}

export function projectCamerasProjectCameras(projectCameras) {
    return { type: types.CREATE_PROJECT_PROJECT_CAMERAS_PROJECT_CAMERAS, cameras: projectCameras}
}

export function projectCamerasRemoveCameras(removeCameras) {
    return { type: types.CREATE_PROJECT_PROJECT_CAMERAS_REMOVE_CAMERAS, cameras: removeCameras}
}

export function projectCamerasAddCameras(addCameras) {
    return { type: types.CREATE_PROJECT_PROJECT_CAMERAS_ADD_CAMERAS, cameras: addCameras}
}

export function projectCamerasCameraWeights(cameraWeights) {
    return { type: types.CREATE_PROJECT_PROJECT_CAMERAS_CAMERA_WEIGHTS, cameras: cameraWeights}
}
