import React, { Component } from 'react'
import { Button, Modal, Message } from 'semantic-ui-react'
import { getErrorMessage } from './../../util/errors'

class RemoveLockdownModal extends Component {

    getHeader = (error, success) => {
        if (error) {
            return "Error"
        } else if (success) {
            return "Success"
        } else {
            return ""
        }
    }

    getContent = (error, success, loading, errors) => {
        if (error) {
            if (errors.unexpectedError) {
                return <p>{getErrorMessage(errors, "unexpectedError")}</p>
            }
        } else if (success) {
            return <p>{this.props.message}</p>
        } else if (loading) {
            return <p>Processing...</p>
        } else {
            return <p>Are you sure you want to remove the lockdown on the site?</p>
        }
    }

    getButtons = (error, success) => {
        if (error) {
            return <div><Button onClick={this.props.onClose} negative primary>Cancel</Button></div>
        } else if (success) {
            return <div><Button onClick={this.props.onClose} positive primary>Close</Button></div>
        } else {
            return <div>
                <Button onClick={this.props.onClose} negative>No</Button>
                <Button onClick={this.props.onClick} loading={this.props.loading} positive icon='checkmark' labelPosition='right' content='Yes' />
            </div>
        }
    }

    render() {
        return (
            <Modal size={"small"} open={this.props.open} onClose={this.props.onClose} closeIcon>
                <Modal.Header>Remove Lockdown</Modal.Header>
                <Modal.Content>
                    <Message
                        error={this.props.error}
                        success={this.props.success}
                        visible={true}
                        header={this.getHeader(this.props.error, this.props.success)}
                        content={this.getContent(this.props.error, this.props.success, this.props.loading, this.props.errors)}
                    />
                </Modal.Content>
                <Modal.Actions>
                    {this.getButtons(this.props.error, this.props.success)}
                </Modal.Actions>
                <br />
            </Modal>
        )
    }
}

export default RemoveLockdownModal
