import * as types from './actionTypes'
import { API_BASE_URL}  from '../config'
import { addMessage } from './messengerActions'
import { log } from './logActions'
import { notAuthorisedResponse } from './authActions'
import { fetchWithToken } from '../util/accessToken'

export function filterBy(filterBy) {
    return { type: types.TAGS_FILTER_BY, filterBy: filterBy }
}

export function fetchAllTags(clientId, currentPage = 1, status = 'Active', limit = true) {
    return (dispatch, getState) => {
        dispatch({ type: types.TAGS_FETCH_ALL_REQUEST, currentPage: currentPage })

        const state = getState()

        const sortField = state.tags.list.sortColumn
        const ascending = state.tags.list.sortAscending
        const filterBy = state.tags.list.filterBy
        const pageSize = state.tags.list.pageSize
        let url = `${API_BASE_URL}/api/clients/${clientId}/tags?currentPage=${currentPage}`
        if (limit) {
            url += `&pageSize=${pageSize}`
            url += `&orderBy=${sortField}&ascending=${ascending}`
            url += `&filterBy=${filterBy}`
            url += `&status=${status}`
        } else {
            url += `&pageSize=999`
        }

        return fetchWithToken(
            url,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    }  else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.TAGS_FETCH_ALL_RESPONSE_ERRORS,
                                        errors: data.errors,
                                    })
                            } else {
                                // Error
                                dispatch(
                                    {
                                        type: types.TAGS_FETCH_ALL_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        }
                                    }
                                )
                            }

                        })
                    }
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.TAGS_FETCH_ALL_RESPONSE_ERRORS,
                                    errors: data.errors,
                                }
                            )
                        } else {
                            dispatch(
                                {
                                    type: types.TAGS_FETCH_ALL_RESPONSE_SUCCESS,
                                    tags: data.tags,
                                    totalItems: data.totalItems
                                }
                            )
                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(log("error", response))
            })
    }
}

export function fetchOneTag(clientId, tagId) {
    return (dispatch, getState) => {
        dispatch({ type: types.TAGS_FETCH_ONE_REQUEST, tags_id: tagId })
        let url = `${API_BASE_URL}/api/clients/${clientId}/tags/${tagId}`
        return fetchWithToken(
            url,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    }  else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.TAGS_FETCH_ONE_RESPONSE_ERRORS,
                                        errors: data.errors,
                                    })
                            } else {
                                // Error
                                dispatch(
                                    {
                                        type: types.TAGS_FETCH_ONE_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        }
                                    }
                                )
                            }

                        })
                    }
                } else {

                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.TAGS_FETCH_ONE_RESPONSE_ERRORS,
                                    errors: data.errors,
                                }
                            )
                        } else {
                            dispatch(
                                {
                                    type: types.TAGS_FETCH_ONE_RESPONSE_SUCCESS,
                                    details: data
                                }
                            )
                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(log("error", response))
            })
    }
}

export function updateTag(clientId, tagId, details) {
    return (dispatch, getState) => {
        dispatch({
            type: types.TAGS_UPDATE_REQUEST,
            clients_id: clientId,
            tags_id: tagId,
            details: details
        })
        let url = `${API_BASE_URL}/api/clients/${clientId}/tags/${tagId}`
        return fetchWithToken(
            url,
            {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(details)
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    }  else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.TAGS_UPDATE_RESPONSE_ERRORS,
                                        errors: data.errors,
                                    })
                            } else {
                                // Error
                                dispatch(
                                    {
                                        type: types.TAGS_UPDATE_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        }
                                    }
                                )
                            }

                        })
                    }
                } else {

                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.TAGS_UPDATE_RESPONSE_ERRORS,
                                    errors: data.errors,
                                }
                            )
                        } else {
                            dispatch(
                                addMessage(
                                    "success",
                                    "Success",
                                    "Successfully updated tag."
                                )
                            )
                            dispatch(
                                {
                                    type: types.TAGS_UPDATE_RESPONSE_SUCCESS,
                                    details: data
                                }
                            )
                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(log("error", response))
            })
    }
}

export function deleteTag(clientId, tagId) {
    return (dispatch, getState) => {
        dispatch({
            type: types.TAGS_DELETE_REQUEST,
            clients_id: clientId,
            tags_id: tagId
        })
        let url = `${API_BASE_URL}/api/clients/${clientId}/tags/${tagId}`
        return fetchWithToken(
            url,
            {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    }  else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.TAGS_DELETE_RESPONSE_ERRORS,
                                        errors: data.errors,
                                    })
                            } else {
                                // Error
                                dispatch(
                                    {
                                        type: types.TAGS_DELETE_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        }
                                    }
                                )
                            }

                        })
                    }
                } else {
                    if (response.status === 204) {
                        dispatch(
                            addMessage(
                                "success",
                                "Success",
                                "Successfully deleted tag."
                            )
                        )
                        dispatch({ type: types.TAGS_DELETE_RESPONSE_SUCCESS })
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.TAGS_DELETE_RESPONSE_ERRORS,
                                        errors: data.errors,
                                    }
                                )
                            }
                        })
                    }
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(log("error", response))
            })
    }
}

export function createTag(clientId, details) {
    return (dispatch, getState) => {
        dispatch({
            type: types.TAGS_CREATE_REQUEST,
            clients_id: clientId,
            details: details
        })
        let url = `${API_BASE_URL}/api/clients/${clientId}/tags`
        return fetchWithToken(
            url,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(details)
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    }  else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    {
                                        type: types.TAGS_CREATE_RESPONSE_ERRORS,
                                        errors: data.errors,
                                    })
                            } else {
                                // Error
                                dispatch(
                                    {
                                        type: types.TAGS_CREATE_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        }
                                    }
                                )
                            }

                        })
                    }
                } else {

                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.TAGS_CREATE_RESPONSE_ERRORS,
                                    errors: data.errors,
                                }
                            )
                        } else {
                            dispatch(
                                addMessage(
                                    "success",
                                    "Success",
                                    "Successfully created tag."
                                )
                            )
                            dispatch(
                                {
                                    type: types.TAGS_CREATE_RESPONSE_SUCCESS,
                                    details: data
                                }
                            )
                        }

                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(log("error", response))
            })
    }
}

export function reset() {
    return { type: types.TAGS_RESET }
}

export function resetCreate() {
    return { type: types.TAGS_RESET_CREATE }
}

export function resetUpdate() {
    return { type: types.TAGS_RESET_UPDATE }
}

export function resetDelete() {
    return { type: types.TAGS_RESET_DELETE }
}

export function resetAssociateCameraWithTag() {
    return { type: types.TAGS_ASSOCIATE_CAMERA_WITH_TAG_RESET }
}

export function associateCameraWithTag(clientId, cameraId, tagId, tag = null) {
    return (dispatch, getState) => {
        dispatch(
            {
                type: types.TAGS_ASSOCIATE_CAMERA_WITH_TAG_REQUEST,
                clients_id: clientId,
                cameras_id: cameraId,
                tags_id: tagId,
            }
        )
        let url = `${API_BASE_URL}/api/clients/${clientId}/tags/associate-camera-with-tag`
        return fetchWithToken(
            url,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    cameras_id: cameraId,
                    tags_id: tagId
                })
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    addMessage(
                                        "negative",
                                        "Error",
                                        "Failed to associate camera with tag."
                                    )
                                )
                                dispatch(
                                    {
                                        type: types.TAGS_ASSOCIATE_CAMERA_WITH_TAG_RESPONSE_ERRORS,
                                        errors: data.errors,
                                        clients_id: clientId,
                                        cameras_id: cameraId,
                                        tags_id: tagId
                                    })
                            } else {
                                // Error
                                dispatch(
                                    addMessage(
                                        "negative",
                                        "Error",
                                        "Failed to associate camera with tag."
                                    )
                                )
                                dispatch(
                                    {
                                        type: types.TAGS_ASSOCIATE_CAMERA_WITH_TAG_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        },
                                        clients_id: clientId,
                                        cameras_id: cameraId,
                                        tags_id: tagId
                                    }
                                )
                            }

                        })
                    }
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                addMessage(
                                    "negative",
                                    "Error",
                                    "Failed to associate camera with tag."
                                )
                            )
                            dispatch(
                                {
                                    type: types.TAGS_ASSOCIATE_CAMERA_WITH_TAG_RESPONSE_ERRORS,
                                    errors: data.errors,
                                    clients_id: clientId,
                                    cameras_id: cameraId,
                                    tags_id: tagId
                                }
                            )
                        } else {
                            dispatch(addMessage(
                                "positive",
                                "Success",
                                "Successfully associated camera with tag"
                            ))

                            if (tag) {
                                dispatch(
                                    {
                                        type: types.TAGS_ASSOCIATE_CAMERA_WITH_TAG_RESPONSE_SUCCESS,
                                        details: data,
                                        clients_id: clientId,
                                        cameras_id: cameraId,
                                        tags_id: tagId,
                                        // Optimisation for cameras page AL
                                        tag: tag
                                    }
                                )
                            } else {
                                dispatch(
                                    {
                                        type: types.TAGS_ASSOCIATE_CAMERA_WITH_TAG_RESPONSE_SUCCESS,
                                        details: data,
                                        clients_id: clientId,
                                        cameras_id: cameraId,
                                        tags_id: tagId
                                    }
                                )
                            }


                        }
                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(log("error", response))
            })
    }
}

export function resetUnassociateCameraWithTag() {
    return { type: types.TAGS_UNASSOCIATE_CAMERA_WITH_TAG_RESET }
}

export function unassociateCameraWithTag(clientId, cameraId, tagId) {
    return (dispatch, getState) => {
        dispatch(
            {
                type: types.TAGS_UNASSOCIATE_CAMERA_WITH_TAG_REQUEST,
                clients_id: clientId,
                cameras_id: cameraId,
                tags_id: tagId
            }
        )
        let url = `${API_BASE_URL}/api/clients/${clientId}/tags/unassociate-camera-with-tag`
        return fetchWithToken(
            url,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    cameras_id: cameraId,
                    tags_id: tagId
                })
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch(
                                    addMessage(
                                        "negative",
                                        "Error",
                                        "Failed to unassociate camera with tag."
                                    )
                                )
                                dispatch(
                                    {
                                        type: types.TAGS_UNASSOCIATE_CAMERA_WITH_TAG_RESPONSE_ERRORS,
                                        errors: data.errors,
                                        clients_id: clientId,
                                        cameras_id: cameraId,
                                        tags_id: tagId
                                    })
                            } else {
                                // Error
                                dispatch(
                                    addMessage(
                                        "negative",
                                        "Error",
                                        "Failed to unassociate camera with tag."
                                    )
                                )
                                dispatch(
                                    {
                                        type: types.TAGS_UNASSOCIATE_CAMERA_WITH_TAG_RESPONSE_ERRORS,
                                        errors: {
                                            unknownErrorType: {
                                                unknownError: "There was an unexpected error"
                                            }
                                        },
                                        clients_id: clientId,
                                        cameras_id: cameraId,
                                        tags_id: tagId
                                    }
                                )
                            }

                        })
                    }
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                addMessage(
                                    "negative",
                                    "Error",
                                    "Failed to unassociate camera with tag."
                                )
                            )
                            dispatch(
                                {
                                    type: types.TAGS_UNASSOCIATE_CAMERA_WITH_TAG_RESPONSE_ERRORS,
                                    errors: data.errors,
                                    clients_id: clientId,
                                    cameras_id: cameraId,
                                    tags_id: tagId
                                }
                            )
                        } else {
                            dispatch(
                                addMessage(
                                    "positive",
                                    "Success",
                                    "Successfully unassociated camera with tag"
                                )
                            )
                            dispatch(
                                {
                                    type: types.TAGS_UNASSOCIATE_CAMERA_WITH_TAG_RESPONSE_SUCCESS,
                                    details: data,
                                    clients_id: clientId,
                                    cameras_id: cameraId,
                                    tags_id: tagId
                                }
                            )
                        }
                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(log("error", response))
            })
    }
}
