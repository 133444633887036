import React, { useState, useEffect } from 'react'
import { Segment } from 'semantic-ui-react'
import { generateWifiQRCode } from 'wifi-qr-code-generator'
import {QRCodeSVG} from 'qrcode.react';

const CameraWifiInfoV4 = (props) => {
    const [qrCode, setQrCode] = useState(null)

    useEffect(() => {
        if (props.details && props.details.ap_config) {
            let fetchValue = generateWifiQRCode({
                ssid: props.details.ap_config.ap_ssid,
                password: props.details.ap_config.ap_password,
                encryption: 'WPA',
                hiddenSSID: false,
                outputFormat: { type: 'image/png' }
            })

            fetchValue.then((url) => {
                setQrCode(url)
            })
        }
        
    }, [props.details])

    let details

    if (props.loading) {
        details = (
            <div className="animated-blank-line" />
        )
    } else if (props.details) {

        const ap_ssid = props.details.ap_config ? props.details.ap_config.ap_ssid : '--'
        const ap_password = props.details.ap_config ? props.details.ap_config.ap_password : '--'

        details = (
            <div>
                <div>
                    {ap_ssid && <p><span className='key'>Access Point SSID:</span> <span className='value'>{ap_ssid}</span></p>}
                    {ap_password && <p><span className='key'>Access Point Password:</span> <span className='value'>{ap_password}</span></p>}
                </div>
                {qrCode && <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '2rem',
                }}>  
                    <div>
                        <p><span className='key'>Access point SSID QR Code:</span><br />
                        <small>Please scan this to connect to the ad-hoc wifi</small></p>
                        <img src={qrCode} alt="Access point SSID QR Code" />
                    </div>
                    <div>
                        <p><span className='key'>Lobster Pot Admin Link:</span><br />
                        <small>Please scan this after connecting to access the control panel</small></p>
                        <QRCodeSVG value="https://lobster.pot/" alt="Admin link qr code"/>
                    </div>
                </div>}
            </div>
        )
    } else {
        details = (
            <div>No information found</div>
        )
    }
    return (
        <Segment>
            <h3>Ad-hoc Wifi Details</h3>
            {details}
        </Segment>
    )
}

export default CameraWifiInfoV4
