import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Table,
    Button,
    Icon,
    Grid,
    Message,
    Popup,
    Segment
} from 'semantic-ui-react'

class UserProjectsAndCamerasSelector extends Component {

    constructor(props) {
        super(props)
        this.state = {
            projects: []
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props !== prevProps) {
            if (this.props.projects && this.props.projects.length > 0) {
                let projects = []
                this.props.projects.forEach((project) => {
                    const key = this.state.projects.findIndex((elem) => {
                        return elem.projects_id === project.id
                    })
                    if (key === -1) {
                        // Let's see if we can pre-populate with selected_cameras based on userCameras
                        if (this.props.userCameras && this.props.userCameras.length > 0) {
    
                            let selectedCameras = []
                            this.props.userCameras.forEach((camera) => {
                                if (camera.projects_id === project.id) {
                                    selectedCameras.push(camera.id)
                                }
                            })
                            projects.push({projects_id: project.id, cameras: [], selected_cameras: selectedCameras })
                        } else {
                            // It means, it's a new project...so let's build up the structure with sensible defaults
                            projects.push({projects_id: project.id, cameras: [], selected_cameras: []})
                        }
                    }
                })
                if (projects.length > 0) {
                    this.setState((prevState, props) => {
                        return {
                            projects: [...prevState.projects, ...projects]
                        }
                    })
                }
            }
        }
    }

    handleRemoveProject = (project) => {
        // Okay, we'll need to also update the weights...
        let items = this.props.user_project_cameras.slice()

        const idx = items.findIndex((i) => {
            if ('pot_id' in project || 'pot_id' in i) {
                if ('pot_id' in i && 'pot_id' in project) {
                    return project.id === i.id
                } else {
                    return false
                }
            } else {
                return project.id === i.id
            }
        })

        items.splice(idx,1)

        let weights = items.map((project_camera, idx) => {
            return Object.assign(
                {},
                project_camera,
                {
                    weight: (1 + idx) * 10
                }
            )
        })

        // At this point, we've updated the weights, let's ensure it's reflected in Redux state
        this.props.onRemoveProject(project)
        this.props.onUpdateProjectCameraWeights(weights)
    }

    handleRemoveCamera = (item) => {
        // Okay, we'll need to also update the weights...
        let items = this.props.user_project_cameras.slice()

        const idx = items.findIndex((i) => {
            if ('pot_id' in item || 'pot_id' in i) {
                if ('pot_id' in i && 'pot_id' in item) {
                    return item.id === i.id
                } else {
                    return false
                }
            } else {
                return item.id === i.id
            }
        })

        items.splice(idx,1)

        let weights = items.map((project_camera, idx) => {
            return Object.assign(
                {},
                project_camera,
                {
                    weight: (1 + idx) * 10
                }
            )
        })

        // At this point, we've updated the weights, let's ensure it's reflected in Redux state
        this.props.onUpdateProjectCameraWeights(weights)

        // Code handling removing camera proper
        const projectId = item.projects_id
        const cameraId = item.id

        this.setState((prevState, props) => {

            let projects = prevState.projects.slice()

            let project = projects.find((elem) => {
                return elem.projects_id === projectId
            })

            if (project) {

                const idx = project.selected_cameras.indexOf(cameraId)
                if (idx !== -1) {
                    project.selected_cameras.splice(idx, 1)
                }

                const key = projects.findIndex((elem) => {
                    return elem.projects_id === projectId
                })

                projects.splice(key, 1)
                projects.push(project)

                return {
                    projects: [...projects]
                }

            }

        })

        this.props.onRemoveCamera(item)
    }

    onChangeCameraToAdd = (id, projectId) => {
        // We need to use the ids to get the entity...so that we can
        // keep displaying meaningful data
        let cameras = []
        const camera = this.props.cameras.find((elem) => {
            return elem.id === id
        })
        if (camera) {
            cameras.push(camera)
        }

        // So, we're essentially taking responsibility for managing the state
        // of the Dropdown in order to ensure the options available reflect
        // the bigger picture
        this.setState((prevState, props) => {

            // Let's make a local copy...
            let projects = prevState.projects.slice()

            let project = projects.find((elem) => {
                return elem.projects_id === projectId
            })


            // Only if not already added...
            const idx = project.selected_cameras.indexOf(id)
            if (idx === -1) {
                project.selected_cameras.push(id)
            }


            const key = projects.findIndex((elem) => {
                return elem.projects_id === projectId
            })

            projects.splice(key, 1)
            projects.push(project)

            return {
                projects: [...projects]
            }
        })

        this.props.onAddUserCameras(cameras)
    }

    onClickProjectToAdd = (project) => {

        // If the user has access to cameras
        if (this.props.user_project_cameras) {
            // Okay, we'll need to also update the weights...otherwise it will break
            let items = this.props.user_project_cameras.slice()
            items.unshift(project)

            let weights = items.map((project_camera, idx) => {
                return Object.assign(
                    {},
                    project_camera,
                    {
                        weight: (1 + idx) * 10
                    }
                )
            })
            // At this point we're going to swap rows in the array, then update the weighting
            const from = weights.findIndex((i) => {
                if ('pot_id' in project || 'pot_id' in i) {
                    if ('pot_id' in i && 'pot_id' in project) {
                        return project.id === i.id
                    } else {
                        return false
                    }
                } else {
                    return project.id === i.id
                }
            })
            const to = from + 1
            weights.splice(to, 0, weights.splice(from, 1)[0]);
            weights = weights.map((project_camera, idx) => {
                return Object.assign(
                    {},
                    project_camera,
                    {
                        weight: (1 + idx) * 10
                    }
                )
            })
            // At this point, we've updated the weights, let's ensure it's reflected in Redux state
            this.props.onUpdateProjectCameraWeights(weights)
        }

        this.props.onAddProject(project)
    }

    getSelectedCameras = (projectId) => {
        if (this.state.projects && this.state.projects.length > 0) {
            const project = this.state.projects.find((elem) => {
                return elem.projects_id === projectId
            })

            if (project) {
                return project.selected_cameras
            }
        }
        return []
    }

    onDownClick = (item) => {
        // Let's re-order the list as-is - that is, update the weights to reflect order
        let weights = this.props.user_project_cameras.map((project_camera, idx) => {
            return Object.assign(
                {},
                project_camera,
                {
                    weight: (1 + idx) * 10
                }
            )
        })
        // At this point we're going to swap rows in the array, then update the weighting
        const from = weights.findIndex((i) => {
            if ('pot_id' in item || 'pot_id' in i) {
                if ('pot_id' in i && 'pot_id' in item) {
                    return item.id === i.id
                } else {
                    return false
                }
            } else {
                return item.id === i.id
            }
        })
        const to = from + 1
        weights.splice(to, 0, weights.splice(from, 1)[0]);
        weights = weights.map((project_camera, idx) => {
            return Object.assign(
                {},
                project_camera,
                {
                    weight: (1 + idx) * 10
                }
            )
        })
        // At this point, we've updated the weights, let's ensure it's reflected in Redux state
        this.props.onUpdateProjectCameraWeights(weights)
    }

    onUpClick = (item) => {
        // Let's re-order the list as-is - that is, update the weights to reflect order
        let weights = this.props.user_project_cameras.map((project_camera, idx) => {
            return Object.assign(
                {},
                project_camera,
                {
                    weight: (1 + idx) * 10
                }
            )
        })
        // At this point we're going to swap rows in the array, then update the weighting
        const from = weights.findIndex((i) => {
            if ('pot_id' in item || 'pot_id' in i) {
                if ('pot_id' in i && 'pot_id' in item) {
                    return item.id === i.id
                } else {
                    return false
                }
            } else {
                return item.id === i.id
            }
        })
        const to = from - 1
        // Let's now update it...
        weights.splice(to, 0, weights.splice(from, 1)[0]);
        weights = weights.map((project_camera, idx) => {
            return Object.assign(
                {},
                project_camera,
                {
                    weight: (1 + idx) * 10
                }
            )
        })
        // At this point, we've updated the weights, let's ensure it's reflected in Redux state
        this.props.onUpdateProjectCameraWeights(weights)
    }


    getContent = () => {

        let cameraList = this.props.user_project_cameras

        let defaultToAllClientProjectsWarning, orderIsImportantMessage, bulkEditingWarning
        if (cameraList && cameraList.length === 0) {
            if (this.props.auth && this.props.auth.user && parseInt(this.props.auth.user.roles_id) === 4) {
                defaultToAllClientProjectsWarning = (
                    <Message color='orange'>
                        <Icon name='warning' />
                        If no projects are selected the user will inherit the project permissions from your Project Admin account
                    </Message>
                )
            } else {
                defaultToAllClientProjectsWarning = (
                    <Message color='orange'>
                        <Icon name='warning' />
                        If no projects or cameras are selected here, the user will
                        have access to all projects belonging to this client.
                    </Message>
                )
            }
        }
        if (cameraList && cameraList.length > 1) {
            orderIsImportantMessage = (
                <Message color='blue' style={{marginBottom: '1em'}}>
                    <Icon name='sort' />
                    Use the up and down arrows to set the order in which
                    projects and cameras are presented to the user.
                </Message>
            )
        }

        if (this.props.isBulkEdit) {
            bulkEditingWarning = (
                <Message color='orange'>
                    <Icon name='warning' />
                    <strong>Please Note:</strong> When editing multiple users this table only allows the creation of a project restriction.
                    If you wish to remove a user from a project, please do so on an individual basis
                </Message>
            )
        }
        

        const availableProjectsAndCameras = []
        this.props.projects.forEach(project => {
            const selectedCameras = this.getSelectedCameras(project.id)
            const camerasButton = !this.props.loadingProjectCameras && this.props.loadingProjectCamerasProjectId === project.id ?
               <Button
                   onClick={() => {
                       this.props.onResetProjectCameras()
                   }}
                   size='mini'
               >
                   <Icon name='camera' />
                   Close cameras
               </Button>
               :
               <Button
                   onClick={() => {
                       this.props.onLoadProjectCameras(project.id)
                   }}
                   size='mini'
                   loading={this.props.loadingProjectCameras && this.props.loadingProjectCamerasProjectId === project.id}
               >
                   <Icon name='camera' />
                   Select cameras
               </Button>

            const row = (
                <Table.Row key={project.id}>
                    <Table.Cell>
                        <Icon name='building' />
                        {project.name}
                    </Table.Cell>
                    <Table.Cell>
                       <Button
                           disabled={this.props.disabled}
                           onClick={() => {
                               this.onClickProjectToAdd(project)
                           }}
                           size='mini'
                       >
                           <Icon name='caret left' />
                           Add Project
                       </Button>
                       {this.props.auth && this.props.auth.user && parseInt(this.props.auth.user.roles_id) !== 4  && camerasButton}
                   </Table.Cell>
               </Table.Row>
           )
           availableProjectsAndCameras.push(row)
           // Add project cameras
           if (this.props.loadingProjectCamerasProjectId === project.id) {
               let availableCameraCount = 0
               this.props.cameras.forEach((camera) => {
                   // Don't display cameras already assigned to the user
                   if (!selectedCameras.includes(camera.id)) {
                       availableProjectsAndCameras.push(
                            <Table.Row
                                key={`${project.id}-${camera.id}`}
                                warning
                            >
                                <Table.Cell>
                                    <Icon name='camera' />
                                    {camera.pot_id}
                                </Table.Cell>
                                <Table.Cell>
                                    <Button
                                        disabled={this.props.disabled}
                                        onClick={() => {
                                            this.onChangeCameraToAdd(camera.id, project.id)
                                        }}
                                        size='mini'
                                    >
                                        <Icon name='caret left' />
                                        Add Camera
                                    </Button>
                                </Table.Cell>
                            </Table.Row>
                       )
                       availableCameraCount += 1
                   }
               })
               if (!this.props.loadingProjectCameras && availableCameraCount === 0) {
                   availableProjectsAndCameras.push(
                       <Table.Row
                           key={`${project.id}-none`}
                           warning
                       >
                           <Table.Cell colSpan='2'>
                               No more cameras available
                           </Table.Cell>
                       </Table.Row>
                   )
               }
           }
       })

        return (
            <div className='user-projects-and-cameras-selector'>
                {bulkEditingWarning}
                {defaultToAllClientProjectsWarning}
                <Grid columns='2'>
                    <Grid.Row className='no-padding-bottom'>
                        <Grid.Column>
                            <Table celled className='user-projects-and-cameras'>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Selected</Table.HeaderCell>
                                        <Table.HeaderCell>Actions</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
            
                                    { cameraList && cameraList.map((item, idx) => {
                                        let btnUp = null
                                        let btnDown = null

                                        if (idx === 0) {
                                            if (cameraList.length > 1) {
                                                btnDown = <Popup
                                                    trigger={
                                                        <Button
                                                            disabled={this.props.disabled}
                                                            className='down'
                                                            icon
                                                            onClick={() => { this.onDownClick(item, idx) }}
                                                        >
                                                            <Icon name='arrow down' />
                                                        </Button>
                                                    }
                                                    content='Move down the list'
                                                />
                                            }
                                        } else if (idx === this.props.user_project_cameras.length - 1) {
                                            btnUp = <Popup
                                                trigger = {
                                                    <Button
                                                        disabled={this.props.disabled}
                                                        className='up'
                                                        icon onClick={() => { this.onUpClick(item, idx) }}
                                                    >
                                                        <Icon name='arrow up' />
                                                    </Button>
                                                }
                                                content='Move up the list'
                                                />
                                        } else {
                                            btnUp = <Popup
                                                trigger = {
                                                    <Button
                                                        disabled={this.props.disabled}
                                                        className='up'
                                                        icon onClick={() => { this.onUpClick(item, idx) }}
                                                    >
                                                        <Icon name='arrow up' />
                                                    </Button>
                                                }
                                                content='Move up the list'
                                                />
                                            btnDown = btnDown = <Popup
                                                trigger={
                                                    <Button
                                                        disabled={this.props.disabled}
                                                        className='down'
                                                        icon
                                                        onClick={() => { this.onDownClick(item, idx) }}
                                                    >
                                                        <Icon name='arrow down' />
                                                    </Button>
                                                }
                                                content='Move down the list'
                                            />
                                        }

                                        const type = (item.pot_id) ? 'Camera' : 'Project'

                                        const icon = (type === 'Camera') ? <Icon name='camera' /> :  <Icon name='building' />

                                        return (
                                            <Table.Row key={idx}>
                                                <Table.Cell>{icon}   {item.text}</Table.Cell>
                                                <Table.Cell>
                                                    <Popup
                                                        trigger={
                                                            <Button
                                                                disabled={this.props.disabled}
                                                                icon
                                                                onClick={() => {
                                                                    if (type === 'Project') {
                                                                        this.handleRemoveProject(item)
                                                                    } else {
                                                                        // Okay, we need to update state so that option is available again...
                                                                        this.handleRemoveCamera(item)
                                                                    }
                                                                }}
                                                            >
                                                                <Icon name='trash' />
                                                            </Button>
                                                        }
                                                        content='Remove'
                                                    />
                                                    {btnUp}
                                                    {btnDown}
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })}

                                </Table.Body>
                            </Table>
                            {orderIsImportantMessage}
                        </Grid.Column>
                        <Grid.Column>
                            <Table celled className='projects-and-cameras-selector'>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Available</Table.HeaderCell>
                                        <Table.HeaderCell>Actions</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {availableProjectsAndCameras}
                                </Table.Body>
                            </Table>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        )
    }

    render() {
        // Let's return a spinner for now...
        return (
            <div className='user-projects-and-cameras-selector'>
                <Segment loading={this.props.loading}>{this.getContent()}</Segment>
            </div>)
    }
}

UserProjectsAndCamerasSelector.propTypes = {
    projects: PropTypes.array.isRequired,
    cameras: PropTypes.array.isRequired,
    user_project_cameras: PropTypes.array.isRequired,
    loadingProjectCameras: PropTypes.bool.isRequired,
    onLoadProjectCameras: PropTypes.func.isRequired,
    onResetProjectCameras: PropTypes.func.isRequired,
    onAddProject: PropTypes.func.isRequired,
    onRemoveProject: PropTypes.func.isRequired,
    onUpdateProjectCameraWeights: PropTypes.func.isRequired,
    onAddUserCameras: PropTypes.func.isRequired,
    onRemoveCamera: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    disabled: PropTypes.bool
}

export default UserProjectsAndCamerasSelector
