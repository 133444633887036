import * as types from './actionTypes'
import { API_BASE_URL}  from '../config'
import { fetchWithToken } from '../util/accessToken'

export function log(level, message) {

    return (dispatch, getState) => {
        const state = getState()
        if (!state.user) {
            return
        }
        dispatch({type: types.LOG_REQUEST, level, message})
        return fetchWithToken(
            `${API_BASE_URL}/api/log`,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    level: level,
                    message: message
                })
            },
            dispatch,
            getState
        )
    }
}
