export default function pagination(currentPage, maxPages) {
    var delta = 2,
        range = [],
        rangeWithDots = [],
        l;

    range.push(1)
    for (let i = currentPage - delta; i <= currentPage + delta; i++) {
        if (i < maxPages && i > 1) {
            range.push(i);
        }
    }
    range.push(maxPages);

    for (let i of range) {
        if (l) {
            if (i - l === 2) {
                rangeWithDots.push(l + 1);
            } else if (i - l !== 1) {
                rangeWithDots.push('...');
            }
        }
        rangeWithDots.push(i);
        l = i;
    }

    return rangeWithDots;
}
