import {Button, Modal} from 'semantic-ui-react'

const LedColourChartModal = (props) => {
    let style = {
        background: 'none',
        border: 'none',
        textDecoration: 'underline',
        margin: 0,
        padding: 0,
        fontWeight: 'normal',
    }

    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
            closeIcon
            //trigger={<Button basic compact size='small' color='blue'>Camera LED Colour Guide</Button>}
            // onClose={() => this.setState({modalOpen: false})}
            // onOpen={() => this.setState({modalOpen: 'chart'})}
            // open={this.state.modalOpen === 'chart'}
        >
            <Modal.Header>LED Colour Guide</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <ul className='camera__led-guide'>
                        <li className="header">
                            <p className="led-guide__colour">Colour</p>
                            <p className="led-guide__desc">Mode</p>
                        </li>
                        <li>
                            <p className="led-guide__colour colour--blue">Blue</p>
                            <p className="led-guide__desc">Transferring data</p>
                        </li>
                        <li>
                            <p className="led-guide__colour colour--blue-pulse">Blue - 10s pulse</p>
                            <p className="led-guide__desc">Updating firmware</p>
                        </li>
                        <li>
                            <p className="led-guide__colour colour--cyan">Cyan</p>
                            <p className="led-guide__desc">Connecting to the internet</p>
                        </li>
                        <li>
                            <p className="led-guide__colour colour--green">Green</p>
                            <p className="led-guide__desc">Setup mode - Good signal</p>
                        </li>
                        <li>
                            <p className="led-guide__colour colour--green-pulse">Green - 10s pulse</p>
                            <p className="led-guide__desc">Running - subscribed</p>
                        </li>
                        <li>
                            <p className="led-guide__colour colour--magenta">Magenta</p>
                            <p className="led-guide__desc">Waking up</p>
                        </li>
                        <li>
                            <p className="led-guide__colour colour--magenta-pulse">Magenta - 10s pulse</p>
                            <p className="led-guide__desc">Running - unsubscribed</p>
                        </li>
                        <li>
                            <p className="led-guide__colour colour--red">Red</p>
                            <p className="led-guide__desc">Watchdog reboot</p>
                        </li>
                        <li>
                            <p className="led-guide__colour colour--white">White</p>
                            <p className="led-guide__desc">Switched on, booting</p>
                        </li>
                        <li>
                            <p className="led-guide__colour colour--white-pulse">White - 10s pulse</p>
                            <p className="led-guide__desc">Deep Sleep</p>
                        </li>
                        <li>
                            <p className="led-guide__colour colour--yellow">Yellow</p>
                            <p className="led-guide__desc">Setup mode - Low signal</p>
                        </li>
                        <li>
                            <p className="led-guide__colour colour--yellow-pulse">Yellow - 10s pulse</p>
                            <p className="led-guide__desc">Failed to connect to the internet</p>
                        </li>
                    </ul>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button
                content="Close"
                onClick={props.onClose}
                primary
                />
            </Modal.Actions>
        </Modal>
    )
}

export default LedColourChartModal;