import React, { Component } from 'react'
import { Modal } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import CreateBulkUsers from "../CreateBulkUsers";
import queue from 'queue'

class CreateBulkUsersModal extends Component {
    onOpen = (event, data) => {
        this.props.ucpaActions.resetAll()
    }

    render = () => {
        return (
            <div className='create-bulk-users-modal'>
                <Modal
                    size={'fullscreen'}
                    open={this.props.open}
                    onOpen={this.onOpen}
                    onMount={this.onOpen}
                    onClose={this.props.onClose}
                    closeIcon
                >
                    <Modal.Header>Bulk create users</Modal.Header>
                    <Modal.Content>
                        <CreateBulkUsers
                            auth={this.props.auth}
                            clients={this.props.clients}
                            clientId={this.props.clientId}
                            roles={this.props.roles}
                            success={this.props.bulkUsers.success}
                            errors={this.props.bulkUsers.errors}
                            error={this.props.bulkUsers.error}
                            onUsernameChange={(email) => {
                                this.handleCreateBulkUserOnUsernameChange(email)
                            }}
                            onAddingEmailToBulkUsersList={(email) => {
                                this.handleCreateBulkUserAddingUsernameToBulkUsersList(email)
                            }}
                            validateEmailAsUsername={this.props.bulkUsers.validateEmailAsUsername}
                            errorEmails={this.props.bulkUsers.errorEmails}
                            emails={this.props.bulkUsers.emails}
                            users={this.props.bulkUsers.users}
                            processing={this.props.bulkUsers.processing}
                            completed={this.props.bulkUsers.completed}
                            onRemovingEmailToBulkUsersList={(email) => {
                                this.handleBulkUsersRemoveUsernameFromBulkUsersList(email)
                            }}
                            onUpdateTemplate={(template) => {
                                this.handleBulkUsersUpdateTemplate(template)
                            }}
                            template={this.props.bulkUsers.template}
                            ucpa_completed={this.props.bulkUsers.ucpa_completed}
                            cameras={this.props.ucpa.cameras}
                            projects={this.props.ucpa.projects}
                            user_cameras={this.props.ucpa.user_cameras}
                            user_projects={this.props.ucpa.user_projects}
                            add_user_cameras={this.props.bulkUsers.add_user_cameras}
                            remove_user_cameras={this.props.ucpa.remove_user_cameras}
                            add_user_projects={this.props.bulkUsers.add_user_projects}
                            remove_user_projects={this.props.ucpa.remove_user_projects}
                            project_camera_weights={this.props.bulkUsers.project_camera_weights}
                            unassociate_project_with_user={this.props.ucpa.unassociate_project_with_user}
                            unassociate_camera_with_user={this.props.ucpa.unassociate_camera_with_user}
                            associate_project_with_user={this.props.ucpa.associate_project_with_user}
                            associate_camera_with_user={this.props.ucpa.associate_camera_with_user}
                            onLoadProjectCameras={(clientId, projectId) => {
                                this.props.ucpaActions.fetchProjectCameras(clientId, projectId)
                            }}
                            onClose={this.props.onClose}
                            onResetProjectCameras={this.props.ucpaActions.resetProjectCameras}
                            onAddProject={() => {}}
                            onRemoveProject={() => {}}
                            onRemoveCamera={() => {}}
                            onClientChange={(clientId) => {
                                this.props.ucpaActions.fetchProjects(clientId)
                            }}
                            ucpa_processing={this.props.ucpa.processing}
                            ucpa_loading={this.props.ucpa.projects.loading}
                            resetUnassociateProjectWithUser={() => {
                                this.props.ucpaActions.resetUnassociateProjectWithUser()
                            }}
                            resetUnassociateCameraWithUser={() => {
                                this.props.ucpaActions.resetUnassociateCameraWithUser()
                            }}
                            resetAssociateProjectWithUser={() => {
                                this.props.ucpaActions.resetAssociateProjectWithUser()
                            }}
                            resetAssociateCameraWithUser={() => {
                                this.props.ucpaActions.resetAssociateCameraWithUser()
                            }}

                            onAddUserProjects={(projects) => {
                                this.props.bulkUsersActions.addUserProjects(projects)
                            }}
                            onRemoveProjects={(projects) => {}}

                            onAddUserCameras={(cameras) => {
                                this.props.bulkUsersActions.addUserCameras(cameras)
                            }}
                            onRemoveCameras={(cameras) => {
                            }}
                            onUpdateProjectCameraWeights={(projectCameraWeights) => {
                                this.props.bulkUsersActions.updateProjectCameraWeights(projectCameraWeights)
                            }}
                            onClick={
                                (template) => {
                                    this.handleCreateBulkUsersConfirmedClick(template)
                                }
                            }
                            adminUserRolesRequest={() => {
                                this.props.addUserActions.adminUserRolesRequest()
                            }}
                            adminUserStatusesRequest={() => {
                                this.props.addUserActions.adminUserStatusesRequest()
                            }}
                            adminUserClientsRequest={() => {
                                this.props.addUserActions.adminUserClientsRequest()
                            }}
                        />
                    </Modal.Content>
                </Modal>
            </div>
        )
    }

    handleBulkUsersUpdateTemplate = (template) => {
        this.props.bulkUsersActions.updateTemplate(template)
    }

    handleCreateBulkUsersConfirmedClick = (template) => {
        const q = queue({concurrency: 1})
        this.props.bulkUsers.emails.forEach(({email}) => {
            q.push(() => {
                return this.props.bulkUsersActions.addUser(template, email)
                    .then((response) => {

                        const user = this.props.bulkUsers.users.find((elem) => {
                            return elem.username === email
                        })


                        if (!user) {
                            this.props.bulkUsersActions.updateUserAssociations(email, "Success")
                            return false
                        }


                        // 1. Add new relationships between user and project and user and camera
                        // 2. Update the weights on each relationship between user and camera and user and project

                        // UCPA reducer removes on success from its own state, so we're providing our copy
                        // so that every user is associated correctly
                        //const projects = this.props.bulkUsers.add_user_projects
                        const cameras = this.props.bulkUsers.add_user_cameras
                        const weights = this.props.bulkUsers.project_camera_weights

                        const userId = user.id
                        const clientId = template.clients_id

                        // If a Project Admin has a project restriction and creates a new users
                        // the project restriction is inherited by the new user
                        // this is processed as part of the addUserRequest call
                        // So at this stage the user may have inhreited multiple projects restrictions
                        // We need to remove the restrictions that aren't relevent
                        // IF the restrictions have been overridden in the create user window
                        // I know....sorry. (restrictions)

                        // Total projects the auth'd user has access to
                        const projects = this.props.ucpa.projects.projects
                        // Projects that have been selected to add to user
                        const projectsToAddToUser = this.props.bulkUsers.add_user_projects
                        // Projects that the auth'd user has access to, minus the project to add to user
                        const projectIDs = new Set(projectsToAddToUser.map(({ id }) => id));
                        const projectsToRemoveFromUser = projects.filter(({ id }) => !projectIDs.has(id));

                        let removeUserProjects = []
                        let addUserProjects = []
                        let addUserCameras = []

                        // If the auth'd user is Project Admin then remove projects
                        if (this.props.auth.user && parseInt(this.props.auth.user.roles_id) === 4 && projectsToRemoveFromUser.length !== projects.length && (projects.length < this.props.projectsTotalRows)) {
                            removeUserProjects = projectsToRemoveFromUser.map((project) => {
                                const projectId = project.id
                                return this.props.ucpaActions.unassociateProjectWithUser(clientId, projectId, userId)
                            })
                        } else {
                            addUserProjects = this.props.bulkUsers.add_user_projects.map((project) => {
                                const projectId = project.id
                                return this.props.ucpaActions.associateProjectWithUser(clientId, projectId, userId)
                            })
                        }

                        if (cameras) {
                            addUserCameras = cameras.map((camera) => {
                                const cameraId = camera.id
                                const projectId = camera.projects_id
                                return this.props.ucpaActions.associateCameraWithUser(clientId, projectId, cameraId, userId)
                            })
                        }

                        // const addUserProjects = projects.map((project) => {
                        //     const projectId = project.id
                        //     return this.props.ucpaActions.associateProjectWithUser(clientId, projectId, userId)
                        // })

                        // const addUserCameras = cameras.map((camera) => {
                        //     const cameraId = camera.id
                        //     const projectId = camera.projects_id
                        //     return this.props.ucpaActions.associateCameraWithUser(clientId, projectId, cameraId, userId)
                        // })

                        return Promise.all([...removeUserProjects, ...addUserProjects, ...addUserCameras]).then(() => {
                            const projectCameraWeights = weights.map((item) => {
                                if ('pot_id' in item) {
                                    const cameraId = item.id
                                    const projectId = item.projects_id
                                    const weight = item.weight
                                    return this.props.ucpaActions.changeUserCameraWeight(clientId, projectId, cameraId, userId, weight)
                                } else {
                                    const projectId = item.id
                                    const weight = item.weight
                                    return this.props.ucpaActions.changeUserProjectWeight(clientId, projectId, userId, weight)
                                }
                            })
                            return Promise.all([...projectCameraWeights])
                        })
                        .then(() => {
                            this.props.editUserActions.userVerificationEmailRequest(clientId, userId)
                        })
                        .then(() => {
                            this.props.bulkUsersActions.updateUserAssociations(email, "Success")
                        })
                        .catch((err) => {
                            console.trace()
                            console.log(err)
                            this.props.bulkUsersActions.updateUserAssociations(email, "Error")
                            return false
                        })

                    })
            })
        })

        q.on('success',  (result, job) => {
        })

        this.props.bulkUsersActions.startQueue()

        q.start((err) => {
            if (err) throw err
            this.props.bulkUsersActions.finishQueue() // Updates reducer to clean up
        })

    }

    handleCreateBulkUserOnUsernameChange = (username) => {
        username = username.toLowerCase()
        // Only if not already added to the list...
        const idx = this.props.bulkUsers.emails.findIndex((elem) => {
            return elem.email === username
        })
        if (idx >= 0) {
            // If here, it's already in the list
            return;
        }
        this.username = username
        // if (this.debounced) {
        //     return;
        // } else {
        //     this.debounced = true;
        //     // Let's debounce this...
        //     setTimeout(() => {
        //         this.debounced = false;
        //         this.props.bulkUsersActions.validateEmailRequestAndAddToBulkUserList(this.username)
        //     }, 500)
        // }

        this.props.bulkUsersActions.validateEmailRequestAndAddToBulkUserList(this.username)
    }

    handleCreateBulkUserAddingUsernameToBulkUsersList = (username) => {
        this.props.bulkUsersActions.addValidatedEmailToBulkUserList(username)
    }

    handleBulkUsersRemoveUsernameFromBulkUsersList = (username) => {
        this.props.bulkUsersActions.removeEmailFromBulkUserList(username)
    }

}

CreateBulkUsersModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    bulkUsers: PropTypes.object.isRequired,
    bulkUsersActions: PropTypes.object.isRequired,
    editUserActions: PropTypes.object.isRequired,
    ucpaActions: PropTypes.object.isRequired,
    ucpa: PropTypes.object.isRequired,
    roles: PropTypes.array.isRequired,
    statuses: PropTypes.array.isRequired,
    clients: PropTypes.array.isRequired,
    clientId: PropTypes.any
}

export default CreateBulkUsersModal
