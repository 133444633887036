import initialState from './initialState';
import {
    CREATE_PROJECT_REQUEST,
    CREATE_PROJECT_RESPONSE_ERRORS,
    CREATE_PROJECT_RESPONSE_SUCCESS,
    CREATE_PROJECT_RESET_ERRORS,
    CREATE_PROJECT_CLIENTS_REQUEST,
    CREATE_PROJECT_CLIENTS_RESPONSE_ERRORS,
    CREATE_PROJECT_CLIENTS_RESPONSE_SUCCESS,

    CREATE_PROJECT_PROJECT_CAMERAS_AVAILABLE_CAMERAS,
    CREATE_PROJECT_PROJECT_CAMERAS_PROJECT_CAMERAS,
    CREATE_PROJECT_PROJECT_CAMERAS_REMOVE_CAMERAS,
    CREATE_PROJECT_PROJECT_CAMERAS_ADD_CAMERAS,
    CREATE_PROJECT_PROJECT_CAMERAS_CAMERA_WEIGHTS
} from '../actions/actionTypes';

export default function addProject(state = initialState.addProject, action) {

    switch (action.type) {
        case CREATE_PROJECT_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    loading: true,
                    errors: {},
                    error: false,
                    success: false
                },
                {
                    details: {
                        ...action.details
                    }
                }
            )
        case CREATE_PROJECT_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    errors: action.errors,
                    error: true,
                    success: false
                }
            )
        case CREATE_PROJECT_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    errors: {},
                    error: false,
                    success: true
                }
            )
        case CREATE_PROJECT_RESET_ERRORS:
            return Object.assign(
                {},
                state,
                initialState.addProject,
                // Don't want to lose this data...
                {
                    clients: state.clients
                }
            )
        case CREATE_PROJECT_CLIENTS_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    clients: {
                        clients: [],
                        loading: true,
                        errors: {}
                    }
                }
            )
        case CREATE_PROJECT_CLIENTS_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    clients: {
                        loading: false,
                        errors: {
                            ...action.errors
                        }
                    }
                }
            )
        case CREATE_PROJECT_CLIENTS_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    clients: {
                        loading: false,
                        errors: {},
                        clients: action.clients
                    }
                }
            )

        case CREATE_PROJECT_PROJECT_CAMERAS_AVAILABLE_CAMERAS:
            return Object.assign(
                {},
                state,
                {
                    project_cameras: {
                        ...state.project_cameras,
                        available_cameras: action.cameras
                    }
                }
            )
        case CREATE_PROJECT_PROJECT_CAMERAS_PROJECT_CAMERAS:
            return Object.assign(
                {},
                state,
                {
                    project_cameras: {
                        ...state.project_cameras,
                        project_cameras: action.cameras
                    }
                }
            )
        case CREATE_PROJECT_PROJECT_CAMERAS_REMOVE_CAMERAS:
            const removeCameras = action.cameras.map((camera) => {
                return camera.id
            })

            return Object.assign(
                {},
                state,
                {
                    project_cameras: {
                        ...state.project_cameras,
                        remove_cameras: action.cameras
                    },
                    details: {
                        ...state.details,
                        remove_cameras: removeCameras
                    }
                }
            )
        case CREATE_PROJECT_PROJECT_CAMERAS_ADD_CAMERAS:
            const addCameras = action.cameras.map((camera) => {
                return camera.id
            })

            return Object.assign(
                {},
                state,
                {
                    project_cameras: {
                        ...state.project_cameras,
                        add_cameras: action.cameras
                    },
                    details: {
                        ...state.details,
                        add_cameras: addCameras

                    }
                }
            )
        case CREATE_PROJECT_PROJECT_CAMERAS_CAMERA_WEIGHTS:

            const cameraWeights = action.cameras.map((camera) => {
                return {
                    cameras_id: camera.id,
                    default_weight: camera.default_weight
                }
            })

            return Object.assign(
                {},
                state,
                {
                    project_cameras: {
                        ...state.project_cameras,
                        camera_weights: action.cameras
                    },
                    details: {
                        ...state.details,
                        default_weights: cameraWeights
                    }
                }
            )
        default:
            return state
    }
}
