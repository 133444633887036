import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Segment, Loader, Transition } from 'semantic-ui-react'

class CameraPing extends Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: true
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.success && !prevProps.success) {
            setTimeout(() => this.setState({visible: false}), 4000)
        }
    }

    render() {
        if (this.props.pinging) {
            return (
                <Segment padded>
                    <Loader active inline />Pinging camera
                </Segment>
            )
        } else if (this.props.success) {
            return (
                <Transition animation='slide down' duration={1000} visible={this.state.visible}>
                    <Segment padded color='green'>
                        Ping successful. Response time {this.props.pingTime}
                    </Segment>
                </Transition>
            )
        } else if (this.props.error){
            return (
                <Segment padded inverted color='red'>
                    <b>Error:</b> Pot failed to respond to ping
                </Segment>
            )
        } else {
            return null
        }
    }
}

CameraPing.propTypes = {
    pinging: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    pingTime: PropTypes.string
}

export default CameraPing
