import React, { Component } from 'react'
import { Button, Modal, Message } from 'semantic-ui-react'
import { getErrorMessage } from './../../util/errors'

class DeleteUserModal extends Component {
    componentDidUpdate(prevProps, prevState) {
        if (this.props.success === true && prevProps.success === false) {
            this.props.onClose(null, null, 2)
        }
    }

    onOpen = (event, data) => {
        this.props.editUserActions.deleteUserResetErrors()
    }

    onConfirm = (event, data) => {
        this.props.editUserActions.deleteUserRequest(this.props.clientId, this.props.userId)
    }

    getHeader = (error, success) => {
        if (error) {
            return "Error"
        } else if (success) {
            return "Success"
        } else {
            return ""
        }
    }

    getContent = (error, success, loading, errors) => {
        if (error) {
           if (errors.clients_id) {
                return <p>{getErrorMessage(errors, "clients_id")}</p>
            } else if (errors.users_id) {
                return <p>{getErrorMessage(errors, "users_id")}</p>
            }
        } else if (success) {
            return <p>Successfully deleted this user</p>
        } else if (loading) {
            return <p>Processing...</p>
        } else {
            return <p><strong>Are you sure you want to delete this user?</strong>
                <br /><br />
                Deleting this user will remove them from all of their assigned projects
                    which may impact clients &amp; projects outside of your admin.
            </p>
        }
    }

    getButtons = (error, success) => {
        if (error) {
            return <div><Button onClick={this.props.onClose} negative primary>Cancel</Button></div>
        } else if (success) {
            return <div><Button onClick={this.props.onClose} positive primary>Close</Button></div>
        } else {
            return <div>
                <Button onClick={this.props.onClose} negative>Cancel</Button>
                <Button onClick={this.onConfirm} loading={this.props.loading} positive icon='checkmark' labelPosition='right' content='Delete' />
            </div>
        }
    }

    render() {
        return (
            <Modal
                size={"small"}
                open={this.props.open}
                onOpen={this.onOpen}
                onMount={this.onOpen}
                onClose={this.props.onClose}
                closeIcon
            >
                <Modal.Header>Delete a user</Modal.Header>
                <Modal.Content>
                    <Message
                        error={this.props.error}
                        success={this.props.success}
                        visible={true}
                        header={this.getHeader(this.props.error, this.props.success)}
                        content={this.getContent(this.props.error, this.props.success, this.props.loading, this.props.errors)}
                    />
                    <div className='modalButtons'>
                        {this.getButtons(this.props.error, this.props.success)}
                    </div>
                </Modal.Content>
            </Modal>
        )
    }
}

export default DeleteUserModal
