import initialState from './initialState';
import {
    VIDEO_EDIT_LIST_REQUEST,
    VIDEO_EDIT_LIST_RESPONSE,
    VIDEO_EDIT_LIST_ERROR,
    DELETE_VIDEO_EDIT_REQUEST,
    DELETE_VIDEO_EDIT_SUCCESS,
    DELETE_VIDEO_EDIT_ERROR,
    REMOVE_VIDEO_EDIT
} from '../actions/actionTypes';

export default function videoEdits(state = initialState.videoEdits, action) {

    switch (action.type) {
        case VIDEO_EDIT_LIST_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    loading: true,
                    error: false,
                    errors: {}
                }
            )
        case VIDEO_EDIT_LIST_RESPONSE:
            return Object.assign(
                {},
                state,
                {
                    videoEdits: action.videoEdits,
                    loading: false,
                    error: false,
                    errors: {}
                }
            )
        case VIDEO_EDIT_LIST_ERROR:
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    error: true,
                    errors: action.error
                }
            )
        case DELETE_VIDEO_EDIT_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    delete: {
                        loading: true,
                        errors: {},
                        message: null
                    }
                }
            )
        case DELETE_VIDEO_EDIT_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    delete: {
                        loading: false,
                        errors: {},
                        message: action.message
                    }
                }
            )
        case DELETE_VIDEO_EDIT_ERROR:
            return Object.assign(
                {},
                state,
                {
                    delete: {
                        loading: false,
                        errors: action.error,
                        message: action.message
                    }
                }
            )
        case REMOVE_VIDEO_EDIT:
            const id = action.videoEditId
            return Object.assign(
                {},
                state,
                {
                    videoEdits: state.videoEdits.filter(row =>
                        row["id"] !== id)
                }
            )
        default:
            return state;
    }
}
