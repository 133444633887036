import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Segment, Message, Modal } from 'semantic-ui-react'
import { getErrorMessage } from './../util/errors'

class CameraTests extends Component {

    constructor(props) {
        super(props)
        this.state = { openModal: false }
    }

    getMessage = (blurb) => {

        if (this.props.error) {
            return (
                <Message negative>
                    <Message.Header>Error</Message.Header>
                    <p>{blurb}</p>
                </Message>
            )
        }

        if (this.props.success) {
            return (
                <Message>
                    <Message.Header>Success</Message.Header>
                    <p>{blurb}</p>
                </Message>
            )
        }

        if (this.props.processing) {
            return (
                <Message>
                    <Message.Header>Running Tests...</Message.Header>
                    <p>The camera controls are currently unavailable.  This might take 1-2 minutes.</p>
                </Message>
            )
        }

        return (
            <Message>
                <Message.Header>Are you sure you want to run tests on the camera?</Message.Header>
                <p>While running tests, the camera controls will be unavailable. If you are happy to proceed, please continue.</p>
            </Message>
        )
    }

    getError = () => {
        if (!this.props.error) {
            return null
        }
        let message = getErrorMessage(this.props.errors, 'cameras_id')
        if (!message) {
            message = getErrorMessage(this.props.errors, 'unknownErrorType')
        }
        return (message) ? message : "There was an unexpected error"
    }

    getSuccess = () => {
        if (this.props.details && this.props.details.results && this.props.details.results.message) {
            return this.props.details.results.message
        }
    }

    getBlurb = () => {
        if (this.props.error) {
            return this.getError()
        }
        if (this.props.success) {
            return this.getSuccess()
        }
        return null
    }


    getCancelButton = () => {
        const label = (this.props.success) ? "Close" : "Cancel"
        return (
            <Button onClick={() => { this.setState({openModal:false})}}>{label}</Button>
        )
    }

    getConfirmationButton = () => {

        if (this.props.success) {
            return null
        }

        return (
            <Button
                loading={this.props.processing}
                onClick={() => {
                    this.props.onRunTests()
                }} positive icon='checkmark' labelPosition='right' content='Confirm'
            />
        )
    }

    getButtons = () => {
        const cancelButton = this.getCancelButton()
        const confirmationButton = this.getConfirmationButton()
        return (
            <div>
                {cancelButton}
                {confirmationButton}
            </div>

        )
    }

    render = () => {
        return (
            <div className='camera-tests'>
                <Segment>
                    <Button
                        onClick={()=> {
                            this.props.onResetTests() // To ensure nothing appears from previous state
                            this.setState({openModal:true})
                        }}>
                        Run Tests
                    </Button>
                </Segment>
                <Modal size={"small"} open={this.state.openModal} onClose={() => {this.setState({openModal:false})}}>
                    <Modal.Header>Run Tests</Modal.Header>
                    <Modal.Content>
                        {this.getMessage(this.getBlurb())}
                    </Modal.Content>
                    <Modal.Actions>
                        {this.getButtons()}
                    </Modal.Actions>
                </Modal>
            </div>
        )
    }
}

CameraTests.propTypes = {
    processing: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    details: PropTypes.object.isRequired,
    onRunTests: PropTypes.func.isRequired,
    onResetTests: PropTypes.func.isRequired,
    stream: PropTypes.array.isRequired,
    visible: PropTypes.bool.isRequired
}

export default CameraTests
