import React from 'react'

const WindSpeedUnit = (props) => {

    const handleChange = (event) => {
        props.onChange(event, event.target.value)
    }

    return (
        <div>
            <h4>Wind Speed Units</h4>
            <label
                style={{
                    fontSize: "0.88em"
                }}
            >
                <input
                    style={{
                        marginRight: "5px"
                    }}
                    type="radio"
                    name='wind_speed_unit'
                    value='MPH'
                    checked={props.checked === 'MPH'}
                    onChange={handleChange}
                    disabled={props.disabled}
                />
                mph
            </label>
            <label
                style={{
                    fontSize: "0.88em",
                }}
            >
                <input
                    style={{
                        marginRight: "5px",
                        marginLeft: "10px"
                    }}
                    type="radio"
                    name='wind_speed_unit'
                    value='KPH'
                    checked={props.checked === 'KPH'}
                    onChange={handleChange}
                    disabled={props.disabled}
                />
                kph
            </label>
        </div>
    )
}

export default WindSpeedUnit;