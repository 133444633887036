import React, { Component } from 'react';
import PropTypes from 'prop-types'
import {
    Grid,
    Button,
    Tab,
    Loader,
    Dimmer,
    Segment,
    Dropdown,
    Form,
    Container,
} from 'semantic-ui-react'
import {
    API_BASE_URL,
}  from '../../../config'
import uniqid from 'uniqid'
import LogViewer from "../../LogViewer"
import TimeZone from "../../TimeZone"
import DocumentTitle from 'react-document-title'
import WifiStatus from "../../WifiStatus";
import WifiControl from "../../WifiControl";
import RebootCameraBody from "../../RebootCameraBody";
import CameraTests from "../../CameraTests";
import DataTransferSettings from "../../DataTransferSettings";
import SignalStrength from "../../SignalStrength";
import UploadSettings from "../../UploadSettings";
import ImageRotation from "../../ImageRotation";
import Drop from "../../Drop";
import CameraStatus from "../../CameraStatus";
import CameraStorage from "../../CameraStorage";
import CameraPower from "../../CameraPower";
import Apn from "../../Apn";
import Logging from "../../Logging";
import Logs from "../../Logs";
import CameraSettings from "../../CameraSettings";
import CameraUploadQueue from "../../CameraUploadQueue";
import CameraDrop from "../../CameraDrop";
import CameraPing from "../../CameraPing"
import Schedule from '../../schedule/Schedule'
import { isPermitted } from '../../../util/acl'

import moment from 'moment'

class CameraControl extends Component {

    constructor(props) {
        super(props)
        this.state = {
            src: undefined,
            imageLoading: false,
            errorText: undefined
        }
    }

    componentDidMount() {
        this.props.cameraControlActions.reset()
        this.props.cameraControlActions.resetTests()

        const version = uniqid()
        this.props.cameraControlActions.version(version)

        return this.props.cameraControlActions.ping(this.props.cameras_id)
        .then(() => {
            return this.props.cameraControlActions.fetchCamera(this.props.cameras_id)
        })
        .then(() => {
            // Check result and break chain if no camera found
            if (this.props.camera_control.camera.errors
                && this.props.camera_control.camera.errors.cameras_id
                && this.props.camera_control.camera.errors.cameras_id.cameraDoesNotExist
            ) {
                throw new Error("Camera not found")
            }
            return this.props.cameraControlActions.fetchStatus(this.props.cameras_id)
        })
        .then(() => {
            if (this.props.camera_control.status.error) {
                throw new Error("Status error")
            }
            return this.props.cameraControlActions.fetchSystem(this.props.cameras_id)
        })
        .then(() => {
            return this.props.cameraControlActions.fetchProperties(this.props.cameras_id)
        })
        .then(() => {
            // Do some error checking
            const status = this.props.camera_control.status
            if (status && status.details && status.details.output) {
                if (
                    status.details.output.camera &&
                    status.details.output.camera.make === 'No Camera'
                ) {
                    throw new Error("No camera")
                } else if (status.details.output.camera.storage_error) {
                    throw new Error("Storage error")
                } else if (status.details.output.camera_error) {
                    throw new Error("Camera error")
                }
            }
            return this.props.cameraControlActions.fetchExif(this.props.cameras_id)
        })
        .then(() => {
            this.setState({imageLoading: true})
            const src = this.getPreviewUrl(null)
            let previewImage = new Image()
            previewImage.src = src
            previewImage.onload = () => {
                // We only propagate the changes after pre-caching the image...
                // this.props.cameraControlActions.loadingPreviewImage(false)
                // Both quality and version form part of the URL and therefore determine
                // if cached by browser, or another request is required
                // this.props.cameraControlActions.version(version)
                this.setState({
                    imageLoading: false,
                    src: src
                })
            }
        })
        .catch((err) => {
            let message
            switch(err) {
                case 'Camera not found':
                    message = "Camera cannot be reached"
                    break
                case 'Status error':
                    message = "Unable to get camera status"
                    break
                case 'No camera':
                    message = "Camera not connected"
                    break
                case 'Storage error':
                    message = "Camera storage error"
                    break
                default:
                    message = "Camera IO error"
            }
            this.setState({
                errorText: message,
                imageLoading: false
            })
            this.props.cameraControlActions.resetLoadingIndicators()
        })
    }

    getPreviewUrl = (optionalQuality = null, optionalVersion = null) => {
        const quality = (optionalQuality) ?
            optionalQuality
            : this.props.camera_control.preview_image_quality
        const version = (optionalVersion) ?
            optionalVersion
            : this.props.camera_control.version
        return `${API_BASE_URL}/api/cameras/${this.props.cameras_id}`
            + `/camera-control/preview.jpg?access_token`
            + `=${localStorage.getItem('accessToken')}&quality=${quality}&v=${version}`
    }

    onPreview = () => {
        this.props.cameraControlActions.preview(this.props.cameras_id).then(() => {
            this.reloadPreviewImage()
        })
    }

    onShoot = () => {
        this.props.cameraControlActions.shoot(this.props.cameras_id).then(() => {
            this.reloadPreviewImage()
        })
    }

    reloadPreviewImage = (optionalQuality = null) => {
        // Let's re-render the latest preview image in a controlled manner
        const version = uniqid()
        this.props.cameraControlActions.loadingPreviewImage(true)
        const src = this.getPreviewUrl(optionalQuality, version)
        let previewImage = new Image()
        previewImage.src = src
        previewImage.onload = () => {
            // We only propagate the changes after pre-caching the image...
            // Fix disappearing image between tab changes
            this.setState({src: src})

            this.props.cameraControlActions.loadingPreviewImage(false)
            // Both quality and version form part of the URL and therefore determine
            // if cached by browser, or another request is required
            if (optionalQuality) {
                this.props.cameraControlActions.previewQualityVersion(optionalQuality, version)
            } else {
                this.props.cameraControlActions.version(version)
            }
        }
    }

    getTimeZone = () => {
        return (
            <TimeZone
                cameraControlActions={this.props.cameraControlActions}
                cameras_id={this.props.cameras_id}
                camera_control={this.props.camera_control}
            />
        )
    }

    getUploadSettings = () => {
        return (
            <UploadSettings
                cameraControlActions={this.props.cameraControlActions}
                cameras_id={this.props.cameras_id}
                camera_control={this.props.camera_control}
            />
        )
    }

    getImageRotation = () => {
        return (
            <ImageRotation
                cameraControlActions={this.props.cameraControlActions}
                cameras_id={this.props.cameras_id}
                camera_control={this.props.camera_control}
            />
        )
    }

    getDrop = () => {
        return (
            <Drop cameraControlActions={this.props.cameraControlActions}
                  cameras_id={this.props.cameras_id}
                  camera_control={this.props.camera_control}
            />
        )
    }

    getApn = () => {
        return (
            <Apn  cameraControlActions={this.props.cameraControlActions}
                  cameras_id={this.props.cameras_id}
                  camera_control={this.props.camera_control}
            />
        )
    }

    getLogging = () => {
        return (
            <Logging
                  cameraControlActions={this.props.cameraControlActions}
                  cameras_id={this.props.cameras_id}
                  camera_control={this.props.camera_control}
            />
        )
    }

    getWifiControl = () => {
        if (!this.props.includeLP3Features) {
            return null
        }
        const wifiStatus = (this.props.camera_control.fetch_wifi.details
            && this.props.camera_control.fetch_wifi.details.wifi) ? this.props.camera_control.fetch_wifi.details.wifi : null

        return (
        <WifiControl
            size='big'
            color='orange'
            loading={this.props.camera_control.fetch_wifi.loading}
            processing={this.props.camera_control.wifi.processing}
            action={this.props.camera_control.wifi.action}
            status={wifiStatus}
            legend={true}
            success={this.props.camera_control.wifi.success}
            error={this.props.camera_control.wifi.error}
            errors={this.props.camera_control.wifi.errors}
            onStartClick={() => {
                this.props.cameraControlActions.startWifi(this.props.cameras_id).then(() => {
                    this.props.cameraControlActions.fetchWifi(this.props.cameras_id)
                })
            }}
            onStopClick={() => {
                this.props.cameraControlActions.stopWifi(this.props.cameras_id).then(() => {
                    this.props.cameraControlActions.fetchWifi(this.props.cameras_id)
                })
            }}
            onRestartClick={() => {
                this.props.cameraControlActions.restartWifi(this.props.cameras_id).then(() => {
                    this.props.cameraControlActions.fetchWifi(this.props.cameras_id)
                })
            }}
        />)
    }

    getRebootCameraBody = () => {
        if (!this.props.includeLP3Features) {
            return null
        }
        return (
            <RebootCameraBody
                {...this.props.camera_control.reboot_camera_body}
                onRebootCameraBody={() => {
                    this.props.cameraControlActions.rebootCameraBody(
                        this.props.cameras_id
                    )
                } }
                onResetRebootCameraBody={this.props.cameraControlActions.resetRebootCameraBody}

                // Adding these WIP
                cameraControlActions={this.props.cameraControlActions}
                cameras_id={this.props.cameras_id}
                camera_control={this.props.camera_control}
            />
        )
    }

    getBottomLabels = () => {

        if (this.props.camera_control.properties.loading) {
            return (
                <div>
                    <div className='animated-blank-line'></div>
                </div>
            )
        }

        const isoSpeed = (
            this.props.camera_control.properties.details
            && this.props.camera_control.properties.details.iso
        ) ?
            this.props.camera_control.properties.details.iso.value : null

        const shutterSpeed = (this.props.camera_control.exif.details) ?
            this.props.camera_control.exif.details.shutter_speed : null

        const focalLength = (this.props.camera_control.exif.details) ?
            this.props.camera_control.exif.details.focal_length : null

        const exposureCompensation = (
            this.props.camera_control.properties.details
            && this.props.camera_control.properties.details.exposure_compensation_a
        ) ?
            this.props.camera_control.properties.details.exposure_compensation_a.value : null

        const aperture = (
            this.props.camera_control.properties.details
            && this.props.camera_control.properties.details.aperture
        ) ?
            this.props.camera_control.properties.details.aperture.value : null

        if (this.props.camera_control.properties.details) {
            return (
                <div className='exif-details'>
                    <span>Shutter Speed: <span className='inside-span'>{shutterSpeed}</span></span>
                    <span>Aperture: <span className='inside-span'>ƒ{aperture}</span></span>
                    <span>ISO Speed: <span className='inside-span'>{isoSpeed}</span></span>
                    <span>Exposure Comp: <span className='inside-span'>{exposureCompensation}</span></span>
                    <span>Focal Length: <span className='inside-span'>{focalLength}</span></span>
                </div>
            )
        }
        return null;
    }

    getTopLabels = () => {
        if (this.props.camera_control.properties.loading) {
            return (
                <div className='top-label'>
                    <div className='animated-blank-line'></div>
                </div>
            )
        }
        const manufacturer = (this.props.camera_control.properties.details) ?
            this.props.camera_control.properties.details.manufacturer : null

        const cameraModel = (this.props.camera_control.properties.details) ?
            this.props.camera_control.properties.details.camera_model : null

        const schedulerStatus = (this.props.camera_control.system.details
            && this.props.camera_control.system.details.scheduler) ? this.props.camera_control.system.details.scheduler.status : null

        const timeZone = (this.props.camera_control.system.details
            && this.props.camera_control.system.details.time_zone) ? this.props.camera_control.system.details.time_zone.value : null

        const wifiStatus = (this.props.camera_control.fetch_wifi.details
            && this.props.camera_control.fetch_wifi.details.wifi) ? this.props.camera_control.fetch_wifi.details.wifi : null

        const topLabels = (this.props.camera_control.properties.details) ?
            <div className='top-label'>
                <span>Camera: <span className='inside-span'>{manufacturer} {cameraModel}</span></span>
                <span>Scheduler: <span className='inside-span'>{schedulerStatus}</span></span>
                <span>Time Zone:  <span className='inside-span'>{timeZone}</span></span>

                {this.props.includeLP3Feature ?
                    <span>
                        <span className='signal-strength-container-compact'>
                                    <SignalStrength
                                        {...this.props.camera_control.connection_information}
                                        compact={true} />
                                </span>
                        <span className='wifi-container'>
                            <WifiStatus
                                color='orange'
                                size='large'
                                loading={false}
                                status={wifiStatus}
                                legend={false}
                            />
                        </span>
                    </span>
                    : null
                }
            </div>: null


        return topLabels
    }

    getPotIdOrLoader = () => {
        const potId = (this.props.camera_control.camera.details) ?
            this.props.camera_control.camera.details.pot_id : null
        if (!potId) {
            return (
                <div className='animated-blank-line'></div>
            )
        }
        return (<h1>{potId}</h1>)
    }

    getSchedulerOrLoader = () => {
        if (this.props.camera_control.status.loading || this.props.camera_control.system.loading) {
            return (
                <div className='animated-blank-block-100'></div>
            )
        }
        let status = (this.props.camera_control.system.details && this.props.camera_control.system.details.scheduler) ? this.props.camera_control.system.details.scheduler.status : null
        let startButton = (status === 'Stopped') ? <Button disabled={this.props.camera_control.system.loading} loading={this.props.camera_control.scheduler.processing && this.props.camera_control.scheduler.action === 'Start'} onClick={() => {
            this.props.cameraControlActions.startScheduler(this.props.cameras_id).then(() => {
                this.props.cameraControlActions.fetchSystem(this.props.cameras_id)
            })
        }
        }>Start</Button> : <Button disabled={true}>Start</Button>
        let stopButton = (status === 'Running') ? <Button disabled={this.props.camera_control.system.loading} loading={this.props.camera_control.scheduler.processing && this.props.camera_control.scheduler.action === 'Stop'} onClick={() => {
            this.props.cameraControlActions.stopScheduler(this.props.cameras_id).then(() => {
                this.props.cameraControlActions.fetchSystem(this.props.cameras_id)
            })
        }
        }>Stop</Button>: <Button disabled={true}>Stop</Button>
        let restartButton = (status === 'Running') ? <Button disabled={this.props.camera_control.system.loading} loading={this.props.camera_control.scheduler.processing && this.props.camera_control.scheduler.action === 'Restart'} onClick={() => {
            this.props.cameraControlActions.restartScheduler(this.props.cameras_id).then(() => {
                this.props.cameraControlActions.fetchSystem(this.props.cameras_id)
            })
        }
        }>Restart</Button>: <Button disabled={true}>Restart</Button>



        return (
            <div>
                <Segment loading={this.props.camera_control.system.loading}>
                    <span>Status:
                        <span className='inside-span'>
                            {status ? status : 'Unknown'}
                        </span>
                    </span>
                </Segment>
                <Segment>
                    {startButton}
                    {stopButton}
                    {restartButton}
                </Segment>
            </div>
        )
    }

    getPreviewShootOrLoader = () => {
        if (this.props.camera_control.system.loading) {
            return (
                <div className='animated-blank-line'></div>
            )
        } else {

            const overlayValues = [
                {
                    text: 'None',
                    value: 'none'
                },
                {
                    text:  '2x2 Grid',
                    value: 'g2x2'
                },
                {
                    text: '3x3 Grid',
                    value: 'g3x3'
                },
                {
                    text: '4x4 Grid',
                    value: 'g4x4'
                },
                {
                    text: '16:9 Middle',
                    value: 'c16_9mid'
                },
                {
                    text: '16:9 Top',
                    value: 'c16_9top'
                },
                {
                    text: '16:9 Bottom',
                    value: 'c16_9bot'
                },
            ]

            return (
                <Form size='small' className='preview-controls'>
                    <Form.Group>
                        <Form.Field>
                            <label>Preview Quality</label>
                            <Dropdown
                                floating
                                selection
                                labeled
                                placeholder='Preview Quality'
                                defaultValue={this.props.camera_control.preview_image_quality}
                                onChange={(evt, val) => {
                                    const quality = val.value
                                    if (quality === this.props.camera_control.preview_image_quality) {
                                        return
                                    }
                                    this.reloadPreviewImage(quality)
                                    const qualityItem = this.props.camera_control.preview_image_quality_range.find((item) => {
                                        return item.value === quality
                                    })
                                    this.props.cameraControlActions.log('Info', `Preview quality set to ${qualityItem.text}`, Date.now())
                                }}
                                options={this.props.camera_control.preview_image_quality_range}
                            />
                        </Form.Field>

                        <Form.Field>
                            <label>Preview</label>
                            <Button circular icon='camera' color='grey'
                                    loading={this.props.camera_control.preview.processing}
                                    onClick={this.onPreview}/>
                        </Form.Field>

                        <Form.Field>
                            <label>Shoot</label>
                            <Button circular icon='camera' color='orange'
                                    loading={this.props.camera_control.shoot.processing}
                                    onClick={this.onShoot}/>
                        </Form.Field>
                        <Form.Field style={{zIndex: 200}}>
                            <label>Overlay</label>
                            <Dropdown

                                selection
                                labeled
                                placeholder='Overlay'
                                defaultValue='none'
                                onChange={this.changeOverlay}
                                options={overlayValues}
                            />
                        </Form.Field>
                    </Form.Group>
                </Form>

            )
        }
    }

    getPreviewImageOrLoader = () => {
        const src = this.state.src
        return (
            <Segment
                basic
                style={{
                    width: '750px',
                    height: '500px'
                }}
            >
                <Dimmer active={!this.state.src}>
                    {
                        this.state.imageLoading
                        || this.props.camera_control.preview.processing
                        || this.props.camera_control.shoot.processing ?
                            <Loader inline='centered'/> : null
                    }
                </Dimmer>
                <div className='camera-control-preview-image'>
                    <canvas ref={(canvas) => {this.canvas = canvas}} width={750} height={500} />
                    <img src={src} alt="Camera Preivew"/>
                </div>
            </Segment>
        )
    }

    changeOverlay = (event, {value}) => {
        const width = this.canvas.width
        const height = this.canvas.height
        const context = this.canvas.getContext('2d')
        context.clearRect(0, 0, width, height);
        switch(value) {
            case 'g2x2':
                this.drawLine(width / 2, 0, width / 2, height)
                this.drawLine(0, height / 2, width, height / 2)
                break
            case 'g3x3':
                this.drawLine(width / 3, 0, width / 3, height)
                this.drawLine(2 * width / 3, 0, 2 * width / 3, height)
                this.drawLine(0, height / 3, width, height / 3)
                this.drawLine(0, 2 * height / 3, width, 2 * height / 3)
                break
            case 'g4x4':
                this.drawLine(width / 4, 0, width / 4, height)
                this.drawLine(2 * width / 4, 0, 2 * width / 4, height)
                this.drawLine(3 * width / 4, 0, 3 * width / 4, height)
                this.drawLine(0, height / 4, width, height / 4)
                this.drawLine(0, 2 * height / 4, width, 2 * height / 4)
                this.drawLine(0, 3 * height / 4, width, 3 * height / 4)
                break
            case 'c16_9mid':
                const barHeightM = (height - width * (9 / 16)) / 2
                this.drawRectangle(0, 0, width, barHeightM)
                this.drawRectangle(0, height - barHeightM, width, barHeightM)
                break
            case 'c16_9top':
                const barHeightT = (height - width * (9 / 16))
                this.drawRectangle(0, height - barHeightT, width, barHeightT)
                break
            case 'c16_9bot':
                const barHeightB = (height - width * (9 / 16))
                this.drawRectangle(0, 0, width, barHeightB)
                break
            default:

        }
    }

    drawLine = (x1, y1, x2, y2) => {
        if (!this.canvas) {
            return null
        }
        const context = this.canvas.getContext('2d')
        context.beginPath();
        context.moveTo(x1,y1);
        context.lineTo(x2,y2);
        context.stroke();
    }

    drawRectangle = (x1, y1, w, h) => {
        if (!this.canvas) {
            return null
        }
        const context = this.canvas.getContext('2d')
        context.fillStyle = "rgba(0, 0, 0, 0.6)";
        context.fillRect(x1, y1, w, h);
    }

    getErrorMessage = () => {
        return (
            <div style={{
                width: "750px",
                height: "500px",
                backgroundColor: "#7f7f7f",
                color: "#fff",
                textAlign: "center",
                textTransform: "uppercase",
                paddingTop: "200px",
                fontSize: "4em"
            }}>
                <p>{this.state.errorText}</p>
            </div>
        )
    }

    getContainerPanes = () => {
        let panes = []

        const cameraControlPane = { menuItem: 'Camera Control', render: () => {
            const log = this.props.camera_control.log
            const bottomLabels = this.getBottomLabels()
            return(
                <Tab.Pane attached={false} style={{padding: '0 1.2em'}}>
                    <Container className="content-container" fluid={true} style={{padding: '0 1.2em'}}>
                        <Grid stackable>
                            <Grid.Row className='no-padding-bottom'>
                                <Grid.Column mobile={16} tablet={16} computer={11}>
                                    {this.getPotIdOrLoader()}
                                    <CameraPing {...this.props.camera_control.ping} />
                                    {this.getTopLabels()}
                                    {this.state.errorText ?
                                        this.getErrorMessage()
                                        : this.getPreviewImageOrLoader()}
                                    {bottomLabels}
                                    <Grid.Row className='no-padding-bottom'>
                                        <Grid.Column width='16'>
                                            {this.getPreviewShootOrLoader()}
                                            <LogViewer
                                                loading={this.props.camera_control.camera.loading}
                                                accessToken={localStorage.getItem('accessToken')}
                                                name={'Activity Log'}
                                                fromWhen={false}
                                                log={log}
                                                uploadShot={this.props.cameraControlActions.uploadShot}
                                                    />
                                            <br />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid.Column>
                                <Grid.Column mobile={16} tablet={16} computer={5}>
                                    <Tab panes={this.getCameraControlPanes()} defaultActiveIndex={0} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Tab.Pane>
            )
        }}

        const settingsPane =  { menuItem: 'Settings', render: () => {
            return (
                <Tab.Pane attached={false}>
                    <Grid columns='2'>
                        <Grid.Row className='no-padding-bottom'>
                            <Grid.Column>
                                <CameraStatus
                                    {...this.props.camera_control.system}
                                />
                                <CameraPower
                                    {...this.props.camera_control.system}
                                />
                                <Segment>
                                    <h4>Time Zone</h4>
                                    {this.getTimeZone()}
                                </Segment>
                                <Segment>
                                    <h4>Upload Settings</h4>
                                    {this.getUploadSettings()}
                                </Segment>
                                <Segment>
                                    <h4>Image Rotation</h4>
                                    {this.getImageRotation()}
                                </Segment>
                                {this.props.includeLP3Features ?
                                    <Segment>
                                        <h4>APN</h4>
                                        {this.getApn()}
                                    </Segment>
                                    : null
                                }
                                <Segment>
                                    <h4>Logging</h4>
                                    {this.getLogging()}
                                </Segment>
                            </Grid.Column>
                            <Grid.Column>
                                {this.getRebootCameraBody()}
                                {this.getWifiControl()}
                                <Segment>
                                    <h4>Drop</h4>
                                    {this.getDrop()}
                                </Segment>
                                <CameraStorage {...this.props.camera_control.status}/>
                                {
                                    this.props.includeLP3Features ?
                                        <Segment className='signal-strength-container'>
                                            <h4>Connection Information</h4>
                                            <SignalStrength
                                                {...this.props.camera_control.connection_information}
                                                compact={false} />
                                        </Segment>
                                        : null
                                }
                                <DataTransferSettings
                                    editable={isPermitted(this.props.auth.user, 'post-cameracontrol-datatransfer')}
                                    loading={this.props.camera_control.system.loading}
                                    data_transfer={this.props.camera_control.system.details && this.props.camera_control.system.details.data_transfer}
                                    update_data_transfer={this.props.camera_control.update_data_transfer}
                                    onUpdateClick={(settings) => {
                                        this.props.cameraControlActions.updateDataTransfer(this.props.cameras_id, settings).then(() => {
                                            this.props.cameraControlActions.fetchDataTransfer(this.props.cameras_id)
                                        })
                                    }}
                                    onDismissMessage={() => {
                                        this.props.cameraControlActions.resetUpdateDataTransfer()
                                    }}
                                />
                                <Segment>
                                    <h4>Logs</h4>
                                    <Logs
                                        cameraControlActions={this.props.cameraControlActions}
                                        auth={this.props.auth}
                                        cameras_id={this.props.cameras_id}
                                        camera_control={this.props.camera_control}
                                    />
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Tab.Pane>
            )
        }}

        const uploadQueuePane = { menuItem: 'Upload Queue', render: () => {
            return (
                <Tab.Pane attached={false}>
                    <CameraUploadQueue
                        cameraId={this.props.cameras_id}
                        drop={this.props.drop}
                        uploadQueue={this.props.uploadQueue}
                        dropActions={this.props.dropActions}
                        uploadQueueActions={this.props.uploadQueueActions}
                    />
                </Tab.Pane>
            )
        }}

        const dropPane = { menuItem: 'Drop', render: () => {
            return (
                <Tab.Pane attached={false}>
                    <CameraDrop
                        cameraId={this.props.cameras_id}
                        auth={this.props.auth}
                        drop={this.props.drop}
                        dropActions={this.props.dropActions}
                    />
                </Tab.Pane>
            )
        }}

        const schedulePane = { menuItem: 'Schedule', render: () => {
            return (
                <Tab.Pane attached={false}>
                    <Schedule
                        auth={this.props.auth}
                        schedule={this.props.schedule}
                        match={this.props.match}
                        authActions={this.props.authActions}
                        scheduleActions={this.props.scheduleActions}
                    />
                </Tab.Pane>
            )
        }}

        const testsPane = { menuItem: 'Tests', render: () => {
            return (
                <Tab.Pane attached={false}>

                        <CameraTests
                            processing={this.props.camera_control.tests.processing}
                            error={this.props.camera_control.tests.error}
                            success={this.props.camera_control.tests.success}
                            errors={this.props.camera_control.tests.errors}
                            details={this.props.camera_control.tests.details}
                            onRunTests={()=> {
                                this.props.cameraControlActions.runTests(this.props.cameras_id)
                            }}
                            onResetTests={() => {
                                this.props.cameraControlActions.resetTests()
                            }}
                            // stream={this.props.camera_control.tests.details.results || []}
                            stream={this.props.camera_control.tests.stream || []}
                            visible={this.props.camera_control.tests.visible}
                        />

                </Tab.Pane>
            )
        }}

        const testResultsPane = {
            menuItem: 'Test Results',
            render: () => {
                const results = this.props.camera_control.test_results.results;

                return (
                    <Tab.Pane attached={false}>
                        <Button primary onClick={() => this.props.cameraControlActions.fetchTest(this.props.cameras_id)}>Fetch Tests</Button>
                        {(this.props.camera_control.test_results.processing)? <Loader active inline size='small'></Loader> : null}

                        {(results)?
                        <Grid>
                            <Grid.Column width={6}>
                                <div className="results">
                                    {results.map((result, index) => {
                                        const formattedDate = moment(`${result.start_time.y}-${result.start_time.m}-${result.start_time.d} ${result.start_time.H}:${result.start_time.M}:${result.start_time.S}`, 'YYYYMD H:m:s ZZ').format('MMMM Do YYYY HH:mm:ss')
                                        const apiRequestDate = moment(`${result.start_time.y}-${result.start_time.m}-${result.start_time.d} ${result.start_time.H}:${result.start_time.M}:${result.start_time.S}`, 'YYYYMD H:m:s ZZ').format('YYYY-MM-DD_HH-mm-ss')
                                        return (
                                            <div key={`test-result-${index}`} className={`test-result test-result--${result.result.toLowerCase()}`} onClick={(e) => this.getTestDetails(e, this.props.cameras_id, apiRequestDate)}>
                                                <p className='test-result__result'>{result.result}</p>
                                                <p className='test-result__time'>{formattedDate}</p>
                                                <p className='test-result__summary'>{result.summary}</p>
                                                {/* <p className='test-result__person'>{result.person}</p> */}
                                            </div>
                                        )
                                    })}
                                </div>
                            </Grid.Column>
                            <Grid.Column width={10}>
                                <ul style={{margin: 0, padding: '0', listStyle: 'none'}}>
                                    {this.props.camera_control.test_result_details &&  this.props.camera_control.test_result_details.details &&
                                        this.props.camera_control.test_result_details.details.test_results.map((result, index) => {
                                        return (
                                            <li key={`test-detail-${index}`} className={`test-detail test-detail--${result.result.toLowerCase()}`}>
                                                <p>{result.result}</p>
                                                <p>{result.name}</p>
                                                {result.message && <p className='test-detail__message'>{result.message}</p>}
                                            </li>
                                        )
                                    })}
                                </ul>
                            </Grid.Column>
                        </Grid>
                            
                        : null}
                    </Tab.Pane>
                )
            }
        }

        panes.push(cameraControlPane)
        if (isPermitted(this.props.auth.user, 'get-cameracontrol-settings')) {
            panes.push(settingsPane)
        }
        if (isPermitted(this.props.auth.user, 'get-camerauploadqueue-index')) {
            panes.push(uploadQueuePane)
        }
        if (isPermitted(this.props.auth.user, 'get-cameradrop-image')) {
            panes.push(dropPane)
        }
        if (isPermitted(this.props.auth.user, 'get-scheduler-schedule')) {
            panes.push(schedulePane)
        }
        if (isPermitted(this.props.auth.user, 'post-cameracontrol-runtests')) {
            if (this.props.includeLP3Features) {
                panes.push(testsPane)
            }
        }

        if (isPermitted(this.props.auth.user, 'post-cameracontrol-runtests')) {
            if (this.props.includeLP3Features) {
                panes.push(testResultsPane)
            }
        }
        return panes
    }

    onTestClick = () => {
        this.props.cameraControlActions.fetchTest(this.props.cameras_id)
    }

    getTestDetails = (e, id, apiRequestDate) => {
        // fetch clicked test's siblings and remove active class
        let getSiblings = function (e) {
            let siblings = []; 

            if(!e.parentNode) {
                return siblings;

            }
            // first child
            let sibling  = e.parentNode.firstChild;
            while (sibling) {
                if (sibling.nodeType === 1 && sibling !== e) {
                    siblings.push(sibling);
                }
                sibling = sibling.nextSibling;
            }
            return siblings;
        };

        let siblings = getSiblings(e.currentTarget);
        siblings.forEach(item => {
           item.classList.remove('active')
        })
        // add active class to click
        e.currentTarget.classList.add('active')
        // request details
        this.props.cameraControlActions.fetchTestDetails(id, apiRequestDate)
    }

    getCameraControlPanes = () => {
        let panes = [
            {
                menuItem: 'Scheduler',
                render: () => {
                    return (
                        <Tab.Pane className='scheduler'>
                            {this.getSchedulerOrLoader()}
                        </Tab.Pane>
                    )
                }
            }
        ]

        if (this.props.camera_control.properties.details) {
            let shutterCount
            if (this.props.camera_control.properties.details.shutter_counter) {
                shutterCount = this.props.camera_control.properties.details.shutter_counter
            } else if (
                this.props.camera_control.exif.details
                && this.props.camera_control.exif.details.shutter_counter
            ) {
                shutterCount = this.props.camera_control.exif.details.shutter_counter
            } else {
                shutterCount = 'Unknown'
            }
            panes.unshift(
                {
                    menuItem: 'Settings',
                    render: () => {
                        return (
                            <Tab.Pane>
                                <CameraSettings
                                    cameraControlActions={this.props.cameraControlActions}
                                    cameras_id={this.props.cameras_id}
                                    camera_control={this.props.camera_control}
                                    shutter_count = {shutterCount}
                                />
                            </Tab.Pane>
                        )
                    }
                }
            )
        }
        return panes
    }

    render = () => {
        let title
        const potId = (this.props.camera_control.camera.details) ?
            this.props.camera_control.camera.details.pot_id : null
        if (potId) {
            title = `${potId} Camera Control`
        } else {
            title = 'Camera Control'
        }
        return (
            <div className='camera-control'>
                <DocumentTitle title={title} />
                <Tab
                    menu={{ secondary: true, className: 'camera-control__tabs' }}
                    panes={this.getContainerPanes()}
                    defaultActiveIndex={0}
                />
            </div>
        )
    }
}

CameraControl.propTypes = {
    cameras_id: PropTypes.any.isRequired,
    camera_control: PropTypes.shape({
        properties: PropTypes.object.isRequired,
        update_properties: PropTypes.object.isRequired,
        exif: PropTypes.object.isRequired,
        status: PropTypes.object.isRequired,
        preview: PropTypes.object.isRequired,
        shoot: PropTypes.object.isRequired,
        camera: PropTypes.object.isRequired,
        log: PropTypes.array.isRequired,
        preview_image_quality_range: PropTypes.array.isRequired,
        cameras_id: PropTypes.any,
        version: PropTypes.string,
        loading_preview_image: PropTypes.bool,
        preview_image_quality: PropTypes.number,
        image_formats: PropTypes.array.isRequired,
        system: PropTypes.object.isRequired
    }),
    cameraControlActions: PropTypes.shape({
        fetchStatus: PropTypes.func.isRequired,
        fetchProperties: PropTypes.func.isRequired,
        fetchCamera: PropTypes.func.isRequired,
        fetchExif: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired,
        preview: PropTypes.func.isRequired,
        shoot: PropTypes.func.isRequired,
        updateProperties: PropTypes.func.isRequired,
        loadingPreviewImage: PropTypes.func.isRequired,
        previewQualityVersion: PropTypes.func.isRequired,
        fetchSystem: PropTypes.func.isRequired,
        updateSystem: PropTypes.func.isRequired
    }),
    includeLP3Features: PropTypes.bool
}

export default CameraControl
