import initialState from './initialState'

import {
    CAMERA_CONTROL_FETCH_UPLOAD_QUEUE_REQUEST,
    CAMERA_CONTROL_FETCH_UPLOAD_QUEUE_ERRORS,
    CAMERA_CONTROL_FETCH_UPLOAD_QUEUE_SUCCESS,
    CAMERA_CONTROL_CLEAR_UPLOAD_QUEUE_REQUEST,
    CAMERA_CONTROL_CLEAR_UPLOAD_QUEUE_ERRORS,
    CAMERA_CONTROL_CLEAR_UPLOAD_QUEUE_SUCCESS,
    CAMERA_CONTROL_MODIFY_UPLOAD_QUEUE_REQUEST,
    CAMERA_CONTROL_MODIFY_UPLOAD_QUEUE_ERRORS,
    CAMERA_CONTROL_MODIFY_UPLOAD_QUEUE_SUCCESS,
    CAMERA_CONTROL_START_STOP_UPLOAD_QUEUE_REQUEST,
    CAMERA_CONTROL_START_STOP_UPLOAD_QUEUE_ERRORS,
    CAMERA_CONTROL_START_STOP_UPLOAD_QUEUE_SUCCESS,
    CAMERA_CONTROL_UPLOAD_QUEUE_RESET
} from '../actions/actionTypes'

export default function uploadQueue(state = initialState.uploadQueue, action) {

    switch (action.type) {
        case CAMERA_CONTROL_FETCH_UPLOAD_QUEUE_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    loading: true,
                    success: false,
                    error: false,
                    errors: {}
                }
            )

        case CAMERA_CONTROL_FETCH_UPLOAD_QUEUE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    success: false,
                    error: true,
                    errors: action.errors
                }
            )

        case CAMERA_CONTROL_FETCH_UPLOAD_QUEUE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    success: true,
                    error: false,
                    errors: {},
                    queue: action.queue,
                    size: action.total_size,
                    files: action.queue.length
                }
            )

        case CAMERA_CONTROL_CLEAR_UPLOAD_QUEUE_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    clearing: true
                }
            )

        case CAMERA_CONTROL_CLEAR_UPLOAD_QUEUE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    clearing: false
                }
            )

        case CAMERA_CONTROL_CLEAR_UPLOAD_QUEUE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    clearing: false,
                    queue: [],
                    size: 0,
                    files: 0
                }
            )

        case CAMERA_CONTROL_MODIFY_UPLOAD_QUEUE_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    modifying: true
                }
            )

        case CAMERA_CONTROL_MODIFY_UPLOAD_QUEUE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    modifying: false
                }
            )

        case CAMERA_CONTROL_MODIFY_UPLOAD_QUEUE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    modifying: false
                }
            )

        case CAMERA_CONTROL_START_STOP_UPLOAD_QUEUE_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    startingStopping: true
                }
            )

        case CAMERA_CONTROL_START_STOP_UPLOAD_QUEUE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    startingStopping: false
                }
            )

        case CAMERA_CONTROL_START_STOP_UPLOAD_QUEUE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    started: action.started,
                    startingStopping: false
                }
            )
        case CAMERA_CONTROL_UPLOAD_QUEUE_RESET:
            return initialState.uploadQueue
        default:
            return state
    }
}
