import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
    Container,
    Dimmer,
    Loader,
    Menu,
    Message,
    Table,
    TableRow,
    TableCell,
    Button,
    Checkbox,
    Popup
} from 'semantic-ui-react'
import { USER_STATUS_NEW } from "../util/user"
import TableColumn from './TableColumn'
import pagination from '../util/pagination'
import { isPermitted } from "../util/acl"
import { Link } from 'react-router-dom'
import IconCross from '../assets/svg/IconCross'
import IconTick from '../assets/svg/IconTick'
import '../styles/UserList.css'


/** Sortable, filterable, paginated User List **/
class UserList extends Component {
    constructor(props) {
        super(props)
        this.rowsPerPage = props.rowsPerPage || 10
        this.currentPage = props.currentPage || 1
        this.columns = null
        this.state = {
            open: false,
            type: null,
            error : null,
            success: null,
            message: null,
            isProjectAdmin: false,
            userCanFilter: true
        }
    }

    componentDidMount() {
        // Set 10 rows
        if (this.props.fetchGridOrListTotals) {
            this.props.fetchGridOrListTotals(10);
        }

        if ((this.props.auth && this.props.auth.user && this.props.auth.user.roles_id === 4)) {
            this.setState({isProjectAdmin: true})
        }
        
        this.reload();

        if (this.props.isUserListLinkedFromProject) {
            this.setState({userCanFilter: false})
        }

        this.columns = [
            new TableColumn("edit", "", false),
            new TableColumn("user.username", "Username", this.state.userCanFilter),
            new TableColumn("user.name", "Name", this.state.userCanFilter),
            new TableColumn("client.name", "Client", this.state.userCanFilter),
            new TableColumn("status", "Status", this.state.userCanFilter),
            new TableColumn("role.name", "Role", this.state.userCanFilter),
            new TableColumn("updated", "Last Updated", false),
            new TableColumn("actions", "Actions", false)
        ]

        if (this.props.clientId) {
            // Don't show client name, as we're only showing results for one
            // single client
            this.columns.splice(3, 1)

            if (this.props.isUserListLinkedFromProject) {
                this.columns.splice(5, 0, new TableColumn("comments", "Comments", false))
            }
        } else {
            if (this.props.isUserListLinkedFromProject) {
                this.columns.splice(6, 0, new TableColumn("comments", "Comments", false))
            }
        }

        if (this.props.getUserTableColumns) {
            this.props.getUserTableColumns(this.columns)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.auth && this.props.auth.user && this.props.auth.user.roles_id === 4) {
            this.columns = [
                new TableColumn("edit", "", false),
                new TableColumn("user.username", "Username", this.state.userCanFilter),
                new TableColumn("user.name", "Name", this.state.userCanFilter),
                new TableColumn("client.name", "Client", this.state.userCanFilter),
                new TableColumn("status", "Status", this.state.userCanFilter),
                new TableColumn("delay", "Time Delay", false),
                new TableColumn("save", "Allow Saving", false),
                new TableColumn("myoe", "Video Editing", false),
                new TableColumn("created", "Created", this.state.userCanFilter),
                new TableColumn("lastLogin", "Last Logged In", this.state.userCanFilter),
                new TableColumn("updated", "Last Updated", this.state.userCanFilter),
                new TableColumn("actions", "Actions", false)
            ]

            if (this.props.auth !== prevProps.auth) {
                this.setState({isProjectAdmin: true})

                if (this.props.getUserTableColumns) {
                    this.props.getUserTableColumns(this.columns)
                }
            }

            if (this.props.users.tableData !== prevProps.users.tableData) {
                //this.reload();
            }

            if (this.props.users.searchTerm && this.props.users.searchTerm !== prevProps.users.searchTerm) {
                this.updateURLFragment()
            }

            // If viewing 'Project Access' modal users hide the edit field
            // if (this.props.projectId) {
            //     this.columns.shift()
            // }
        }
    }

    updateURLFragment = () => {
        const preferences = []
        if (this.props.users.searchTerm) {
            preferences["search"] = this.props.users.searchTerm
        }

        preferences["sort"] = this.props.users.sortColumn

        preferences["ascending"] = this.props.users.sortAscending

        let hashPartBuffer = [];
        for (let k in preferences) {
            hashPartBuffer.push(
                encodeURIComponent(k), '=', encodeURIComponent(preferences[k]),
                '&'
            )
        }

        if (hashPartBuffer.length) {
          hashPartBuffer.pop()
        }
        window.location.hash = hashPartBuffer.join('')
    }

    componentWillUnmount() {
        this.props.usersActions.userListReset()
    }

    reload = () => {
        if (this.props.isUserListLinkedFromProject) {
            this.props.projectsActions.usersWithProjectAccess(
                this.props.projectId,
                this.rowsPerPage,
                this.currentPage
            )
        } else {
            let searchField = (this.props.users.searchField)? this.props.users.searchField : null
            this.props.usersActions.userListRequest(
                this.rowsPerPage,
                this.currentPage,
                this.props.users.sortColumn,
                this.props.users.sortAscending,
                this.props.users.searchTerm,
                this.props.clientId,
                searchField
            )
            //this.props.bulkUsersActions.reset()
        }
    }

    /**
    * Sort results by the selected column
    *
    * @param {string} key - Key value from TableColumn class
    */
    handleColumnHeadingClick(key) {
        let ascending = this.props.users.sortColumn === key ? !this.props.users.sortAscending : true
        if (!this.props.projectAccess) {
            this.props.usersActions.userListRequest(
                this.rowsPerPage,
                this.props.users.currentPage,
                key,
                ascending,
                this.props.users.searchTerm,
                this.props.clientId
            )
        } else {
            this.props.projectsActions.usersWithProjectAccess(
                this.props.projectId,
                this.rowsPerPage,
                this.props.users.currentPage,
            )
        }
    }

    /**
    * Jump to a particular page in the results set
    *
    * @param {string} name - The page number label ("1", "2", etc)
    */
    handlePaginationPageClick = (e, { name }) => {
        const page = parseInt(name, 10)

        if (this.props.isUserListLinkedFromProject) {
            this.props.projectsActions.usersWithProjectAccess(
                this.props.projectId,
                this.rowsPerPage,
                page
            )
        } else {
            this.props.usersActions.userListRequest(
                this.rowsPerPage,
                page,
                this.props.users.sortColumn,
                this.props.users.sortAscending,
                this.props.users.searchTerm,
                this.props.clientId
            )
        }
    }

    /** Jump to the previous page in the results set */
    handlePaginationPrevClick = (e) => {
        if (this.props.users.currentPage > 1 ) {
            const prevPage = this.props.users.currentPage - 1

            if (this.props.isUserListLinkedFromProject) {
                this.props.projectsActions.usersWithProjectAccess(
                    this.props.projectId,
                    this.rowsPerPage,
                    prevPage
                )
            } else {
                this.props.usersActions.userListRequest(
                    this.rowsPerPage,
                    prevPage,
                    this.props.users.sortColumn,
                    this.props.users.sortAscending,
                    this.props.users.searchTerm,
                    this.props.clientId
                )
            }
        }
    }

    /** Jump to the next page in the results set */
    handlePaginationNextClick = (e) => {
        const totalPages = Math.ceil(this.props.users.totalRows/this.rowsPerPage)
        if (this.props.users.currentPage < totalPages) {
            const nextPage = this.props.users.currentPage + 1

            if (this.props.isUserListLinkedFromProject) {
                this.props.projectsActions.usersWithProjectAccess(
                    this.props.projectId,
                    this.rowsPerPage,
                    nextPage
                )
            } else {
                this.props.usersActions.userListRequest(
                    this.rowsPerPage,
                    nextPage,
                    this.props.users.sortColumn,
                    this.props.users.sortAscending,
                    this.props.users.searchTerm,
                    this.props.clientId
                )
            }
        }
    }


    /** Render the table header row using this.columns */
    renderHeader() {
        return (
            <TableRow>
            {this.columns && this.columns.map((column, k) => {
                let sortedBy = null
                if (this.props.users.sortColumn === column.key) {
                    sortedBy = this.props.users.sortAscending ? "ascending" : "descending"
                }
                let click = null
                if (column.sortable && this.state.userCanFilter) {
                    click = (e) => {this.handleColumnHeadingClick(column.key)}
                }

                // Don't show client name if we're limited results to one client
                if (column.key === 'client.name' && this.props.clientId !== undefined) {
                    return false
                }
                return (
                    <Table.HeaderCell sorted={sortedBy} onClick={click} key={k}>
                    {column.title}
                    </Table.HeaderCell>
                )
            })}
            </TableRow>
        )
    }

    /**
    * Render a single table row of results
    *
    * @param {Object} row - An object containing one row of results
    * @param {number} index - The current row number being rendered
    */
    renderBodyRow = (row, index) => {
        let username
        
        if (isPermitted(this.props.auth.user, 'put-users-users')) {
            (this.props.isUserListLinkedFromProject)?
                username = <Link to={`/clients/${row.clients_id}/users/${row.id}/update/project`}>
                    {row.username}
                </Link>
            :
                username = <Link to={`/clients/${row.clients_id}/users/${row.id}/update`}>
                    {row.username}
                </Link>
        } else {
            username = row.username
        }

        const resendButton = ((row.status === USER_STATUS_NEW) && row.email) ?
            <li style={{marginTop: '10px'}}>
                <Button
                    icon='mail'
                    basic
                    color='brown'
                    compact
                    size='tiny'
                    as={Link}
                    to={`/clients/${row.clients_id}/users/${row.id}/resend-verification`}
                    content='Resend Verification Email'
                />
            </li> : null

        // Don't show client name if we're limited results to one client
        let clientName = this.props.clientId === undefined ?
            (<TableCell>{row.clientName}</TableCell>) : null

        let authAsUserLink
        // Don't auth as user for Lobster staff
        if (row.clients_id > 1 && row.api !== true) {
            authAsUserLink =(isPermitted(this.props.auth.user, 'get-authas-user')) ?
                <li>
                    <Popup
                        trigger={
                            <Button
                                icon='id badge'
                                basic
                                color='blue'
                                compact
                                size='tiny'
                                onClick={() => {
                                    this.props.usersActions.authAsUser(row.clients_id, row.id)
                                }}
                                content='Auth As User'
                            />
                        }
                        content='Open the Lobster Vision viewer with the permissions from this user'
                    />
                </li>: null
        }

        const updated = moment(row.updated).format('Do MMM YYYY, h:mm A')

        if (this.state.isProjectAdmin) {
            const lastLogin = (row.lastLogin) ? moment(row.lastLogin).format('Do MMM YYYY, h:mm A') : null
            const created = (row.created) ? moment(row.created).format('Do MMM YYYY, h:mm A') : null
            return (
                <TableRow key={row.id}>
                    {/* {!this.props.projectId && <TableCell>
                         <input type="checkbox" onChange={
                            () => {this.props.checkboxSelect(row.id)}
                    }/> */}
                    <TableCell>
                        <Checkbox
                            name={`${row.id}`}
                            onChange={
                                (e, data) => {this.props.checkboxSelect(e, data, row.id, row.clients_id)}
                            }
                            checked={this.props.usersToEdit && this.props.usersToEdit.indexOf(row.id) > -1}
                            disabled={this.props.users.selectedUserClientId && this.props.users.selectedUserClientId !== row.clients_id}
                        />
                    </TableCell>
                    <TableCell>{username}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    {clientName}
                    <TableCell>{row.status}</TableCell>
                    <TableCell>{(row.delay)? <IconTick /> : <IconCross />}</TableCell>
                    <TableCell>{(row.settings.permitted_to_save)? <IconTick /> : <IconCross />}</TableCell>
                    <TableCell>{(row.video_editor)? <IconTick /> : <IconCross />}</TableCell>
                    <TableCell>{created}</TableCell>
                    <TableCell>{lastLogin}</TableCell>
                    <TableCell>{updated}</TableCell>
                    <TableCell>
                        <ul style={{listStyle: 'none', padding: 0, margin: 0, display: 'flex', flexDirection: 'column'}}>
                            {authAsUserLink}
                            {resendButton}
                        </ul>
                    </TableCell>
                </TableRow>
            )
        } else {
            return (
                <TableRow key={row.id}>
                    
                    <TableCell>
                        <Checkbox
                            name={`${row.id}`}
                            onChange={
                                (e, data) => {this.props.checkboxSelect(e, data, row.id, row.clients_id)}
                            }
                            checked={this.props.usersToEdit && this.props.usersToEdit.indexOf(row.id) > -1}
                            disabled={this.props.users.selectedUserClientId && this.props.users.selectedUserClientId !== row.clients_id}
                        />
                    </TableCell>
                    <TableCell>{username}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    {clientName}
                    <TableCell>{row.status}</TableCell>
                    <TableCell>{row.role}</TableCell>
                    {(this.props.isUserListLinkedFromProject)? <TableCell>{row.comment}</TableCell> : false}
                    <TableCell>{updated}</TableCell>
                    <TableCell>
                        <ul style={{listStyle: 'none', padding: 0, margin: 0, display: 'flex', flexDirection: 'column'}}>
                            {authAsUserLink}
                            {resendButton}
                        </ul>
                    </TableCell>
                </TableRow>
            )
        }
    }

    /** Render the table footer row */
    renderFooter() {
        if (this.props.users.totalRows === 0) {
            return (
                <TableRow>
                {this.columns && <TableCell colSpan={this.columns.length}>
                {this.props.users.errors.error !== true ?
                    "No results found" : (
                        <Message
                        error={true}
                        visible={this.props.users.errors.error}
                        header="Error"
                        content={this.props.users.errors.message}
                        />
                    )

                }
                </TableCell>}
                </TableRow>
            )
        }
        const totalPages = Math.ceil(this.props.users.totalRows/this.rowsPerPage)
        const first = ((this.props.users.currentPage - 1) * this.rowsPerPage) + 1
        let last = this.props.users.currentPage * this.rowsPerPage
        if (last > this.props.users.totalRows) last = this.props.users.totalRows
        let pages = pagination(this.props.users.currentPage, totalPages)
        let menuPagination = (
            <Menu pagination>
            <Menu.Item name="prev" onClick={this.handlePaginationPrevClick} icon="angle left" />
                {pages.map((page, key) => {
                return (
                    <Menu.Item name={page.toString()} key={key} active={this.props.users.currentPage === page} onClick={this.handlePaginationPageClick} />
                )
            })}
            <Menu.Item name="next" onClick={this.handlePaginationNextClick} icon="angle right" />
            </Menu>
        )
        return (
            <TableRow>
            {this.columns && <TableCell colSpan={this.columns.length}>
                <div>Showing {first} - {last} of {this.props.users.totalRows} {this.props.users.totalRows === 1 ? "result" : "results"}</div>
                <div style={{textAlign: 'center'}}>
                {totalPages > 1 ? menuPagination : null}
                </div>
            </TableCell>}
            </TableRow>
        )
    }

    render() {
        return (
            <div>
                <Dimmer.Dimmable>
                    <Dimmer active={this.props.users.loading} inverted>
                        <Loader />
                    </Dimmer>
                    <Container className="content-container" fluid={true} style={{padding: '0 1.2em'}}>
                        {this.columns && <Table
                            renderBodyRow={this.renderBodyRow}
                            headerRow={this.renderHeader()}
                            tableData={this.props.users.tableData}
                            footerRow={this.renderFooter()}
                            sortable={this.state.userCanFilter}
                        >
                        </Table>}
                    </Container>
                </Dimmer.Dimmable>
            </div>
        )
    }
}

UserList.propTypes = {
    clientId: PropTypes.number,
    bulkUsers: PropTypes.object,
    ucpa: PropTypes.object,
    users: PropTypes.shape({
        currentPage: PropTypes.number,
        errors: PropTypes.object,
        filtered: PropTypes.bool,
        loading: PropTypes.bool,
        sortAscending: PropTypes.bool,
        sortColumn: PropTypes.string,
        tableData: PropTypes.array.isRequired,
        totalRows: PropTypes.number
    }).isRequired,
    usersActions: PropTypes.object,
    editUserActions: PropTypes.object,
    addUserActions: PropTypes.object,
    bulkUsersActions: PropTypes.object,
    logActions: PropTypes.object,
    messengerActions: PropTypes.object,
    ucpaActions: PropTypes.object
}

export default UserList
