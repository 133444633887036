import initialState from './initialState';
import {
    VIEW_CLIENT_DETAILS_REQUEST,
    VIEW_CLIENT_DETAILS_RESPONSE,
    VIEW_CLIENT_DETAILS_REQUEST_ERRORS
} from '../actions/actionTypes';

export default function viewClient(state = initialState.viewClient, action) {

    switch (action.type) {

        case VIEW_CLIENT_DETAILS_REQUEST:
            return Object.assign({}, state, { loading: true })
        case VIEW_CLIENT_DETAILS_RESPONSE:
            return Object.assign(
                {},
                state,
                action.viewClient,
                { loading: false },
                {
                    logo: (action.viewClient && action.viewClient.logo) ? action.viewClient.logo : null
                }
                )
        case VIEW_CLIENT_DETAILS_REQUEST_ERRORS:
            return Object.assign({}, state, action.viewClient, { errors: [true]})
        default:
            return state
    }
}
