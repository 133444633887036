import {Message, Form, Popup, Icon} from 'semantic-ui-react'
import { getErrorMessage } from '../../../util/errors'

const UpdateUserPublicInputs = (props) => {
    return (
        <div>
            <Form.Field>
                <Message color='teal'>
                    <Message.Header>
                        This account is publicly accessible
                    </Message.Header>
                    Anyone going to the public URL below will be able to see the
                    projects and cameras accociated with this account.
                </Message>
                <label>
                    Public URL
                </label>
                <p>
                    lobstervision.tv/{props.user.username}
                </p>
                <label>
                    Custom landing page URL
                    <Popup trigger={<Icon name="question circle outline" />}>
                        Redirects users to a specific camera, date or time
                        on login.
                    </Popup>
                </label>
                <input
                    onChange={(e) => {props.onInputChange(e, 'custom_landing_page')}}
                    defaultValue={props.user.custom_landing_page}
                />
            </Form.Field>
            <Message
                error={true}
                visible={(props.errors.custom_landing_page)}
                header={'Error'}
                content={getErrorMessage(props.errors, 'custom_landing_page')}
            />
        </div>
    )
}

export default UpdateUserPublicInputs