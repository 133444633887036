import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as authActions from '../actions/authActions'
import * as logActions from '../actions/logActions'
import * as messengerActions from '../actions/messengerActions'
import * as projectsActions from '../actions/projectsActions'
import PropTypes from 'prop-types'
import { withRouter, Link } from 'react-router-dom'
import React from 'react'
import { Container, Grid, Icon } from 'semantic-ui-react'
import { isPermitted } from "../util/acl"

const HomeV4 = (props) => {
    let newCamera = false
    let newCameraUrl = null;
    let newProject = false;
    let newProjectUrl = [];

    if (props.cameras) {
        props.cameras.forEach((camera) => {
            if (!camera.user_configured) {
                newCamera = true
                newCameraUrl = camera.id
            }
        })
    }

    if (props.projects && props.projects.tableData) {
        props.projects.tableData.forEach((project) => {
            if (!project.user_configured) {
                newProject = true
                newProjectUrl = [project.clients_id, project.id]
            }
        })
    }
    return (
        <Container className='homepage__button-grid'>
            <Grid>
                {newCamera && newCameraUrl &&
                    <Grid.Column className="button-grid--item">
                        <Link className={`button button--home`} to={`/cameras/${newCameraUrl}/camera-control-v4`}>
                            <Icon name="camera" />Set Up New Camera
                        </Link>
                    </Grid.Column>
                }

                {newProject && newProjectUrl.length === 2 &&
                    <Grid.Column className="button-grid--item">
                        <Link className={`button button--home`} to={`/clients/${newProjectUrl[0]}/projects/${newProjectUrl[1]}/edit`}>
                            <Icon name="building outline" />Set Up Project
                        </Link>
                    </Grid.Column>
                }
                {/* {(!newCamera && !newCameraUrl && !newProject && newProjectUrl.length === 0)?
                    <> */}
                        {(isPermitted(props.auth.user, 'get-cameras-cameras')) &&
                            <Grid.Column className="button-grid--item">
                                <Link className={`button button--home`} to="/cameras">
                                    <Icon name="camera" />View Cameras
                                </Link>
                            </Grid.Column>
                        }
                        <Grid.Column className="button-grid--item">
                            <Link className={`button button--home`} to="/account">
                                <Icon name="user" />
                                Manage Account
                            </Link>
                        </Grid.Column>
                        {(isPermitted(props.auth.user, 'get-users-users')) && <Grid.Column className="button-grid--item">
                            <Link className={`button button--home`} to="/users">
                                <Icon name="users" />
                                Manage Users
                            </Link>
                        </Grid.Column>}
                        {props.projects && props.projects.tableData.length &&
                            <Grid.Column className="button-grid--item">
                                <Link className={`button button--home`} to="/projects">
                                    <Icon name="building outline" />
                                    My Projects
                                </Link>
                            </Grid.Column>
                        }
                        {!newProject && newProjectUrl.length === 0 && <Grid.Column className="button-grid--item">
                            {props.projects.tableData.length && <Link className={`button button--home`} to="/projects/create">
                                <Icon name="edit outline" />
                                Create Project
                            </Link>}
                        </Grid.Column>}
                        {props.auth.user && props.auth.user.clients_id &&
                            <Grid.Column className="button-grid--item">
                                <Link className={`button button--home`} to={`/clients/${props.auth.user.clients_id}/edit`}>
                                    <Icon name="briefcase" />Edit My Client
                                </Link>
                            </Grid.Column>
                        }
                        <Grid.Column className="button-grid--item">
                            <Link className={`button button--home`} to="/subscriptions">
                                <Icon name="calendar alternate outline" />
                                Subscriptions
                            </Link>
                        </Grid.Column>
                    {/* </> : null
                } */}
            </Grid>
        </Container>
        
        
    )
}


HomeV4.propTypes = {
    authActions: PropTypes.object,
    auth: PropTypes.object,
    logActions: PropTypes.object,
    messengerActions: PropTypes.object
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        projects: state.projects,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(authActions, dispatch),
        logActions: bindActionCreators(logActions, dispatch),
        messengerActions: bindActionCreators(messengerActions, dispatch),
        projectsActions: bindActionCreators(projectsActions, dispatch),
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(HomeV4))
