import initialState from './initialState';
import {
    TAGS_FETCH_ALL_REQUEST,
    TAGS_FETCH_ALL_RESPONSE_ERRORS,
    TAGS_FETCH_ALL_RESPONSE_SUCCESS,
    TAGS_FETCH_ONE_REQUEST,
    TAGS_FETCH_ONE_RESPONSE_ERRORS,
    TAGS_FETCH_ONE_RESPONSE_SUCCESS,
    TAGS_UPDATE_REQUEST,
    TAGS_UPDATE_RESPONSE_ERRORS,
    TAGS_UPDATE_RESPONSE_SUCCESS,
    TAGS_CREATE_REQUEST,
    TAGS_CREATE_RESPONSE_ERRORS,
    TAGS_CREATE_RESPONSE_SUCCESS,
    TAGS_DELETE_REQUEST,
    TAGS_DELETE_RESPONSE_ERRORS,
    TAGS_DELETE_RESPONSE_SUCCESS,
    TAGS_RESET,
    TAGS_FILTER_BY,
    TAGS_RESET_CREATE,
    TAGS_RESET_UPDATE,
    TAGS_RESET_DELETE,
    TAGS_ASSOCIATE_CAMERA_WITH_TAG_RESET,
    TAGS_ASSOCIATE_CAMERA_WITH_TAG_REQUEST,
    TAGS_ASSOCIATE_CAMERA_WITH_TAG_RESPONSE_ERRORS,
    TAGS_ASSOCIATE_CAMERA_WITH_TAG_RESPONSE_SUCCESS,
    TAGS_UNASSOCIATE_CAMERA_WITH_TAG_RESET,
    TAGS_UNASSOCIATE_CAMERA_WITH_TAG_REQUEST,
    TAGS_UNASSOCIATE_CAMERA_WITH_TAG_RESPONSE_ERRORS,
    TAGS_UNASSOCIATE_CAMERA_WITH_TAG_RESPONSE_SUCCESS
} from '../actions/actionTypes';

export default function tags(state = initialState.tags, action) {

    switch (action.type) {

        case TAGS_FILTER_BY:
            return Object.assign(
                {},
                state,
                {
                    list: {
                        ...state.list,
                        filterBy: action.filterBy
                    }
                }
            )

        case TAGS_FETCH_ALL_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    list: {
                        loading: true,
                        success: false,
                        error: false,
                        errors: {},
                        tags: {
                            tags: []
                        },
                        currentPage: action.currentPage,
                        sortColumn: state.list.sortColumn,
                        sortAscending: state.list.sortAscending,
                        filterBy: state.list.filterBy,
                        pageSize: state.list.pageSize
                    }
                }
            )

        case TAGS_FETCH_ALL_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    list: {
                        loading: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        tags: {
                            tags: []
                        },
                        currentPage: state.list.currentPage,
                        sortColumn: state.list.sortColumn,
                        sortAscending: state.list.sortAscending,
                        filterBy: state.list.filterBy,
                        pageSize: state.list.pageSize
                    }
                }
            )

        case TAGS_FETCH_ALL_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    list: {
                        loading: false,
                        success: true,
                        error: false,
                        errors: {},
                        tags: { tags: action.tags, totalItems: action.totalItems },
                        currentPage: state.list.currentPage,
                        sortColumn: state.list.sortColumn,
                        sortAscending: state.list.sortAscending,
                        filterBy: state.list.filterBy,
                        pageSize: state.list.pageSize
                    }
                }
            )

        case TAGS_FETCH_ONE_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    fetch_one: {
                        tagId: action.tags_id,
                        loading: true,
                        success: false,
                        error: false,
                        errors: {},
                        details: {}
                    }
                }
            )

        case TAGS_FETCH_ONE_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    fetch_one: {
                        tagId: state.fetch_one.tagId,
                        loading: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: {}
                    }
                }
            )

        case TAGS_FETCH_ONE_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    fetch_one: {
                        tagId: state.fetch_one.tagId,
                        loading: false,
                        success: true,
                        error: false,
                        errors: {},
                        details: action.details
                    }
                }
            )

        case TAGS_UPDATE_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    update: {
                        tagId: action.tags_id,
                        clientId: action.clients_id,
                        processing: true,
                        success: false,
                        error: false,
                        errors: {},
                        details: {}
                    }
                }
            )

        case TAGS_UPDATE_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    update: {
                        tagId: state.update.tagId,
                        clientId: state.update.clientId,
                        processing: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: {}
                    }
                }
            )

        case TAGS_UPDATE_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    update: {
                        tagId: state.update.tagId,
                        clientId: state.update.clientId,
                        processing: false,
                        success: true,
                        error: false,
                        errors: {},
                        details: action.details
                    }
                }
            )

        case TAGS_CREATE_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    create: {
                        clientId: action.clients_id,
                        tagId: action.tags_id,
                        processing: true,
                        success: false,
                        error: false,
                        errors: {},
                        details: {}
                    }
                }
            )

        case TAGS_CREATE_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    create: {
                        clientId: state.create.clientId,
                        tagId: state.create.tagId,
                        processing: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: {}

                    }
                }
            )

        case TAGS_CREATE_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    create: {
                        clientId: state.create.clientId,
                        tagId: state.create.tagId,
                        processing: false,
                        success: true,
                        error: false,
                        errors: {},
                        details: action.details
                    }
                }
            )

        case TAGS_DELETE_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    remove: {
                        clientId: action.clients_id,
                        tagId: action.tags_id,
                        processing: true,
                        success: false,
                        error: false,
                        errors: {}
                    }
                }
            )

        case TAGS_DELETE_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    remove: {
                        clientId: state.remove.clientId,
                        tagId: state.remove.tagId,
                        processing: false,
                        success: false,
                        error: true,
                        errors: action.errors
                    }
                }
            )

        case TAGS_DELETE_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    remove: {
                        clientId: state.remove.clientId,
                        tagId: state.remove.tagId,
                        processing: false,
                        success: true,
                        error: false,
                        errors: {}
                    }
                }
            )

        case TAGS_RESET:
            return Object.assign(
                {},
                state,
                {
                    ...initialState.tags
                }
            )

        case TAGS_RESET_CREATE:
            return Object.assign(
                {},
                state,
                {
                    create: initialState.tags.create
                }
            )

        case TAGS_RESET_UPDATE:
            return Object.assign(
                {},
                state,
                {
                    update: initialState.tags.update
                }
            )

        case TAGS_RESET_DELETE:
            return Object.assign(
                {},
                state,
                {
                    remove: initialState.tags.remove
                }
            )
        
        case TAGS_ASSOCIATE_CAMERA_WITH_TAG_RESET:
            return Object.assign(
                {},
                state,
                {
                    associate_camera_with_tag: initialState.tags.associate_camera_with_tag
                }
            )
        
        case TAGS_ASSOCIATE_CAMERA_WITH_TAG_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    associate_camera_with_tag: {
                        clientId: action.clients_id,
                        tagId: action.tags_id,
                        cameraId: action.cameras_id,
                        processing: true,
                        success: false,
                        error: false,
                        errors: {}
                    }
                }
            )
        
        case TAGS_ASSOCIATE_CAMERA_WITH_TAG_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    associate_camera_with_tag: {
                        clientId: action.clients_id,
                        tagId: action.tags_id,
                        cameraId: action.cameras_id,
                        processing: false,
                        success: false,
                        error: true,
                        errors: action.errors
                    }
                }
            )
        
        case TAGS_ASSOCIATE_CAMERA_WITH_TAG_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    associate_camera_with_tag: {
                        clientId: action.clients_id,
                        tagId: action.tags_id,
                        cameraId: action.cameras_id,
                        processing: false,
                        success: true,
                        error: false,
                        errors: {}
                    }
                }
            )

        case TAGS_UNASSOCIATE_CAMERA_WITH_TAG_RESET:
            return Object.assign(
                {},
                state,
                {
                    unassociate_camera_with_tag: initialState.tags.unassociate_camera_with_tag
                }
            )

        case TAGS_UNASSOCIATE_CAMERA_WITH_TAG_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    unassociate_camera_with_tag: {
                            clientId: action.clients_id,
                            tagId: action.tags_id,
                            cameraId: action.cameras_id,
                            processing: true,
                            success: false,
                            error: false,
                            errors: {}
                    },
                }
            )

        case TAGS_UNASSOCIATE_CAMERA_WITH_TAG_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    unassociate_camera_with_tag: {
                            clientId: action.clients_id,
                            tagId: action.tags_id,
                            cameraId: action.cameras_id,
                            processing: false,
                            success: false,
                            error: true,
                            errors: action.errors
                    },
                }
            )

        case TAGS_UNASSOCIATE_CAMERA_WITH_TAG_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    unassociate_camera_with_tag: {
                        clientId: action.clients_id,
                        tagId: action.tags_id,
                        cameraId: action.cameras_id,
                        processing: false,
                        success: true,
                        error: false,
                        errors: {}
                    }
                }
            )

        default:
            return state
    }
}
