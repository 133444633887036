import * as types from './actionTypes'
import { API_BASE_URL }  from '../config'
import { addMessage } from './messengerActions'
import { log } from './logActions'

export function captchaRecaptchaRequest(recaptchaResponse) {

    return dispatch => {

        dispatch( { type: types.CAPTCHA_RECAPTCHA_REQUEST })

        return fetch(API_BASE_URL +  '/auth/recaptcha', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                recaptchaResponse: recaptchaResponse
            })
        })
        .then(response => {

            if (!response.ok) {
                // If here, we have errors
                response.json().then(data => {
                    dispatch(captchaRecaptchaResponseErrors(data))
                })
            } else {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(captchaRecaptchaResponseErrors(data))
                    } else {
                        dispatch(captchaRecaptchaResponseSuccess(data))
                    }
                })
            }

        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    };
}

export function captchaRecaptchaResponseErrors(payload) {
    return  {
        type: types.CAPTCHA_RECAPTCHA_RESPONSE_ERRORS,
        ...payload
    }
}

export function captchaRecaptchaResponseSuccess(payload) {
    return  {
        type: types.CAPTCHA_RECAPTCHA_RESPONSE_SUCCESS,
        ...payload
    }
}

export function captchaRecaptchaResetErrors() {
    return  {
        type: types.CAPTCHA_RECAPTCHA_RESET_ERRORS
    }
}
