import React, { Component } from 'react'
import PropTypes from 'prop-types'

import DocumentTitle from 'react-document-title'
import { Form, Dropdown } from 'semantic-ui-react'

import './ScheduleV4.css'

import ScheduleDayV4 from '../schedule/ScheduleDayV4'
import AddScheduleV4Modal from '../modals/AddScheduleV4'
import EditScheduleV4Modal from '../modals/EditScheduleV4'

class ScheduleV4 extends Component {
    constructor(props) {
        super(props)
        this.columns = []
        this.state = {
             isMobile: false,
             scheduleEdited: false,
        }
    }

    componentDidMount() {
        this.props.scheduleActions.load(this.props.match.params.cameraId)
        
        if (window.outerWidth < 768 && !this.state.isMobile) {
            this.setState({isMobile: true})
        }

        window.addEventListener('resize', () => {
            if (window.outerWidth < 768 && !this.state.isMobile) {
                this.setState({isMobile: true})
            } else if (window.outerWidth > 767 && this.state.isMobile) {
                this.setState({isMobile: false})
            }
        })
    }

    generateScheduleTemplates() {
        return [
            {key: 'standard77so', text: 'Standard: 7am-7pm 15 minute shoot', value: 'standard77so'}
        ]
    }

    pushSegmentToSchedule = (array, stop, start) => {
        array.push({
            "stop": stop,
            "start": start,
            "actions": [
                {
                "json_class": "SchedulerActionSleep"
                }
            ],
            "interval": 300,
            "json_class": "Period",            
        })
    }

    generateSegmements = (data, type) => {
        let dayProperty = null
        // Fetch the days
        switch (type) {
            case 'add':
                dayProperty = this.props.schedule.addPeriodModalDay
                break;
            case 'edit':
            case 'delete':
                dayProperty = this.props.schedule.editPeriodModalDay
                break;
            default:
                break;
        }
    
        const day = this.props.schedule.days[dayProperty]

        // Create deep copy
        let modifiedDay = [...day]
    
        // Remove Existing Sleep segments
        if (modifiedDay.length > 0) {
            modifiedDay = modifiedDay.filter((item) => {
                if (item.actions[0].json_class !== 'SchedulerActionSleep') {
                    return item
                }
            })
        }
    
        // Logic to check the new segment doesn't overlap an existing segment
        const startsDuringButEndsAfter = (item) => (data.start >= item.start && data.start <= item.stop && data.stop > item.stop)
        const startsBeforeButEndsDuring = (item) => (data.start <= item.start && data.stop > item.start && data.stop < item.stop)
        const startsDuringButEndsDuring = (item) =>(data.start >= item.start && data.stop <= item.stop)
        const startsBeforeButEndsAfter = (item) =>(data.start < item.start && data.stop > item.stop)

        // Add segment to day array
        modifiedDay.forEach((item, index) => {
            if (type === 'edit'){
                // remove segment and replace with edited version
                if (item.id === data.id) {
                    modifiedDay.splice(index, 1, data)
                }
            } else if (type === 'delete') {
                modifiedDay.splice(index, 1)
            } else if (startsDuringButEndsAfter(item) || startsDuringButEndsDuring(item) || startsBeforeButEndsDuring(item) || startsBeforeButEndsAfter(item)) {
                this.props.scheduleActions.populateScheduleDayError({
                    message: 'The camera is already shooting during this time period'
                })
            } else {
                modifiedDay.push(data)
            }
        })

        // If all the shooting segements have been removed
        if (modifiedDay.length === 0) {
            if (type === 'add') {
                modifiedDay.push(data)
            }
        }
        
        // Sort array but start value
        modifiedDay.sort((a, b) => parseInt(a.start) - parseInt(b.start));
                
        const currentDaySegmentsLength = modifiedDay.length
        const dayStart = 0
        const dayEnd = 86399
        const minimumGap = 7141 // 7141 seconds ~ 2 hours
        let prevPeriodStart = null
        let prevPeriodStop = null
        
        modifiedDay.forEach((period, index) => {
            // Check if current period is the first one in the day
            if (prevPeriodStart === null) {
                // Check current period start time is after midnight
                if (period.start > 0) {
                    prevPeriodStart = period.start
                    prevPeriodStop = period.stop
                    //create a period beginning at 12 (0) and extending to period end - minimumGap
                    let endTime = parseInt(period.start) //- parseInt(minimumGap)
                    if (endTime > 0) { // Deep sleep has to be 2 hours min
                        this.pushSegmentToSchedule(modifiedDay, endTime, 0, index)
                    }
                }

                // Check if also the last period of the day
                if (index + 1 === currentDaySegmentsLength)  {
                    // Check gap between last segment
                    if ((period.start - prevPeriodStop) > minimumGap ) {
                        let endTime = parseInt(period.start) //- parseInt(minimumGap)
                        this.pushSegmentToSchedule(modifiedDay, endTime, prevPeriodStop)
                    }
                    // Check period ends 2 hours before midnight
                    if ((period.stop + minimumGap) < dayEnd) {
                        this.pushSegmentToSchedule(modifiedDay, dayEnd, period.stop)
                    }
                }
            // Check if current period is the last one in the day 
            } else if (index + 1 === currentDaySegmentsLength)  {
                if ((period.start - prevPeriodStop) > minimumGap ) {
                    let endTime = parseInt(period.start) //- parseInt(minimumGap)
                    this.pushSegmentToSchedule(modifiedDay, endTime, prevPeriodStop)
                }
                // Check period ends 2 hours before midnight
                if ((period.stop + minimumGap) < dayEnd) {
                    this.pushSegmentToSchedule(modifiedDay, dayEnd, period.stop)
                }

                prevPeriodStart = period.start
                prevPeriodStop = period.stop
            } else {
                // Segments in the middle of the day
                if ((period.start - prevPeriodStop) > minimumGap ) {
                    let endTime = parseInt(period.start) //- parseInt(minimumGap)
                    this.pushSegmentToSchedule(modifiedDay, endTime, prevPeriodStop)
                }

                prevPeriodStart = period.start
                prevPeriodStop = period.stop
            }
        })

        // Populate whole day of sleep if schedule is empty
        if (modifiedDay.length === 0) {
            this.pushSegmentToSchedule(modifiedDay, dayEnd, dayStart)
        }

        switch (type) {
            case 'add':
                this.props.scheduleActions.populateScheduleDay(modifiedDay)
                break;
            case 'edit':
            case 'delete':
                this.props.scheduleActions.modifyScheduleDay(modifiedDay)
                break;
            default:
                break;
        }
    }

    closeAddPeriodModal = () => {
        //this.props.scheduleV4Actions.revertExistingSleep(this.props.schedule.days, this.props.schedule.addPeriodModalDay)
        this.props.scheduleActions.dismissAddPeriodModal()
    }

    onAddPeriod = (data) => {
        this.generateSegmements(data, 'add')
    }

    onEditPeriod = (data) => {
        this.generateSegmements(data, 'edit')
        this.setState({
            scheduleEdited: true
        })
    }

    onDeletePeriod = (data) => {
        this.generateSegmements(data, 'delete')
    }

    render() {

        let addModal
        if (this.props.schedule.addPeriodModalVisible) {
            const day = this.props.schedule.addPeriodModalDay
            addModal = (
                <AddScheduleV4Modal
                    onClose={this.closeAddPeriodModal}
                    onAdd={this.onAddPeriod}
                    potVersion={this.props.schedule.potVersion}
                    day={day}
                />
            )
        }

        let editModal
        if (this.props.schedule.editPeriodModalVisible) {
            const day = this.props.schedule.editPeriodModalDay
            const period = this.props.schedule.editPeriodModalPeriod
            const schedule = this.props.schedule.days[day][period]
            editModal = (
                <EditScheduleV4Modal
                    {...schedule}
                    onClose={this.props.scheduleActions.dismissEditPeriodModal}
                    onUpdate={this.onEditPeriod}
                    onRemove={this.onDeletePeriod}
                    potVersion={this.props.schedule.potVersion}
                />
            )
        }

        const scheduleTemplates = this.generateScheduleTemplates()


        const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday',
            'Thursday', 'Friday', 'Saturday', 'Sunday']

        let title
        if (this.props.schedule.potId) {
            title = `${this.props.schedule.potId} Schedule`
        } else {
            title = 'Schedule'
        }

        //const schedulerKey = this.generateSchedulerKey()

        return (
            <div>
                <DocumentTitle title={title} />
                {/* <div className='title-wrap title-wrap--schedule'> */}
                    <h2>Schedule</h2>
                {/* </div> */}
                <Form>
                    <Form.Group inline className='schedule-controls-wrap'>
                        <Form.Group className='schedule-controls'>
                            {this.state.scheduleEdited && <Form.Button
                                basic
                                content='Revert Changes'
                                onClick={
                                    () => {
                                        this.props.scheduleActions.load(
                                            this.props.match.params.cameraId
                                        )

                                        this.setState({
                                            scheduleEdited: false
                                        })
                                    }
                                }
                            />}
                            {this.state.scheduleEdited && <Form.Button
                                content='Save Schedule'
                                color='orange'
                                onClick={
                                    () => {
                                        this.props.scheduleActions.save(
                                            this.props.match.params.cameraId
                                        )

                                        this.setState({
                                            scheduleEdited: false
                                        })
                                    }
                                }
                                loading={this.props.schedule.saving}
                            />}
                        </Form.Group>
                        <Dropdown
                            placeholder='Apply template'
                            selection options={scheduleTemplates}
                            onChange={
                                (event, {value}) => {
                                    this.props.scheduleActions.applyTemplate(value)
                                }
                            }
                        />
                        {/* <div className="schedulerKey">
                            <b>Legend</b>
                            {schedulerKey}
                        </div> */}
                    </Form.Group>
                </Form>
                <div className={`schedule__wrap${(this.state.isMobile)? ' schedule__wrap--mobile' : null}`}>
                    {
                        Array(7).fill().map((a, i) => {
                            const day = (i + 1) % 7
                            return (
                                <ScheduleDayV4
                                    key={dayNames[day]}
                                    dayName={dayNames[day]}
                                    dayId={day}
                                    loading={this.props.schedule.loading}
                                    actions={this.props.scheduleActions}
                                    schedule={this.props.schedule.days && this.props.schedule.days[day]}
                                    isMobile={this.state.isMobile}
                                />
                            )
                        })
                    }
                </div>
                <div style={{clear: 'both'}} />
                {addModal}
                {editModal}
            </div>

        )
    }
}

ScheduleV4.propTypes = {
    schedule: PropTypes.object.isRequired,
    scheduleActions: PropTypes.object.isRequired
}

export default ScheduleV4
