import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as authActions from '../actions/authActions'
import * as headerActions from '../actions/headerActions'
import * as footerActions from '../actions/footerActions'
import * as logActions from '../actions/logActions'
import * as messengerActions from '../actions/messengerActions'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { withRouter } from 'react-router-dom'

class Logout extends Component {

    componentDidMount() {
        this.props.headerActions.hideHeader()
        this.props.footerActions.hideFooter()
        this.props.authActions.logoutRequest(this.props.history)
    }

    render() {
        return null
    }
}

Logout.propTypes = {
    authActions: PropTypes.object,
    auth: PropTypes.object,
    headerActions: PropTypes.object,
    header: PropTypes.object,
    footerActions: PropTypes.object,
    footer: PropTypes.object,
    logActions: PropTypes.object,
    messengerActions: PropTypes.object
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        header: state.header,
        footer: state.footer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(authActions, dispatch),
        headerActions: bindActionCreators(headerActions, dispatch),
        footerActions: bindActionCreators(footerActions, dispatch),
        logActions: bindActionCreators(logActions, dispatch),
        messengerActions: bindActionCreators(messengerActions, dispatch)
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Logout))
