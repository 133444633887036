export const ALL_CLIENTS = 'All Clients'
export const OWN_CLIENT  = 'Own Client'

export function isPermitted(user, resource, scope = null) {
    let isPermitted = false
    if (user && user.permissions && user.permissions.length > 0) {
        user.permissions.forEach((permission) => {
            if (permission.resource === resource) {
                if (scope) {
                    if  (permission.scope === scope) {
                        isPermitted = true
                    }
                } else {
                    isPermitted = true
                }
            }
        })
    }
    return isPermitted
}