import initialState from './initialState';
import {
    ADD_CLIENT_REQUEST,
    ADMIN_CLIENT_ROLES_REQUEST,
    ADMIN_CLIENT_ROLES_RESPONSE,
    ADMIN_CLIENT_STATUSES_REQUEST,
    ADMIN_CLIENT_STATUSES_RESPONSE,
    ADD_CLIENT_RESET_ERRORS,
    ADD_CLIENT_RESET_SUCCESS,
    ADD_CLIENT_RESPONSE_ERRORS,
    ADD_CLIENT_RESPONSE_SUCCESS
} from '../actions/actionTypes';

export default function addClient(state = initialState.addClient, action) {

    switch (action.type) {
        case ADD_CLIENT_REQUEST:
            return Object.assign({}, state, { loading: true })
        case ADD_CLIENT_RESPONSE_SUCCESS:
            return Object.assign({}, state, action.addClient, { loading: false, success: true })
        case ADMIN_CLIENT_ROLES_REQUEST:
            return Object.assign({}, state, { roles: [] } )
            // return state
        case ADMIN_CLIENT_ROLES_RESPONSE:
            return Object.assign({}, state, { roles: action.addClient } )
        case ADMIN_CLIENT_STATUSES_REQUEST:
            return Object.assign({}, state, { statuses: [] } )
            // return state
        case ADMIN_CLIENT_STATUSES_RESPONSE:
            return Object.assign({}, state, { statuses: action.addClient } )
        case ADD_CLIENT_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    errors : action.addClient.errors,
                },
                {
                    loading: false
                }
                )
        case ADD_CLIENT_RESET_ERRORS:
            return Object.assign({}, state, { errors: {} } )
        case ADD_CLIENT_RESET_SUCCESS:
            return Object.assign({}, state, { success: false })
        default:
            return state
    }
}
