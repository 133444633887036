import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import * as authActions from '../actions/authActions'
import * as embedActions from '../actions/embedActions'
import * as messengerActions from '../actions/messengerActions'
import * as camerasActions from '../actions/camerasActions'
import * as processingActions from '../actions/processingActions'
import * as tagsActions from '../actions/tagsActions'
import * as bimFinal3dImageActions from '../actions/bim3dImageActions'
import CameraGridView from './../components/CameraGridView'
import CameraListView from './../components/CameraListView'
import ViewToggle from "../components/ViewToggle"
import DocumentTitle from 'react-document-title'
import CreateCameraModal from "./../components/modals/CreateCamera"
import UpdateCameraModal from "./../components/modals/UpdateCamera"
import ImportCoordinatesModal from "./../components/modals/ImportCoordinates.js"
import CameraStatusFilter from './../components/CameraStatusFilter'
import CameraTypeFilter from './../components/CameraTypeFilter'
import CameraVersionFilter from './../components/CameraVersionFilter'
import CameraTagsFilter from './../components/CameraTagsFilter'
import CamerasSort from './../components/CamerasSort'
import { isPermitted } from "./../util/acl"
import { Button, Grid, Segment, Container } from 'semantic-ui-react'
import Search from "./../components/Search"
import { checkAuth } from '../util/accessToken'

import debounce from 'lodash/debounce'

const CamerasContainer = (props) => {
    const [selectedView, setSelectedView] = useState('grid')
    const [type, setType] = useState(null)

    useEffect(() => {
        window.scrollTo(0, 0)
        checkAuth(props.auth, props.authActions, props.history).then(() => {
            props.tagsActions.reset()
            props.camerasActions.reset()
            props.messengerActions.removeAllMessages()
            props.camerasActions.allCameraStatusesRequest()
            props.camerasActions.allCameraTagsRequest()
            parseURLFragment()
        }).catch(err => {})

        return () => {
            props.camerasActions.reset()
        }
    }, [])

    useEffect(() => {
        if (props.cameras.filterBy && props.cameras.filterBy !== "") {
            updateURLFragment()
        }
    }, [props.cameras.filterBy])

    const onClose = () => {
        setType(null)

        if (selectedView === 'grid') {
            props.camerasActions.fetchGridViewCameras(props.cameras.grid_view.currentPage)
        } else {
            props.camerasActions.fetchListViewCameras(props.cameras.list_view.currentPage)
        }
    }

    const onOpenModal = (cameraId) => {
        props.camerasActions.resetUpdate()
        props.camerasActions.fetchCamera(cameraId)

        const clientId = props.auth.user.clients_id
        if (props.auth.user.roles_id !== 5) {
            props.camerasActions.fetchCameraTags(clientId, cameraId)
        }

        if (isPermitted(props.auth.user, 'get-tags-tags') && props.auth.user.roles_id !== 5) {
            props.tagsActions.fetchAllTags(clientId, 1, 'Active', false)
        }

        props.processingActions.reset()
        setType('UpdateCamera')
    }

    const getSelectedCameraView = () => {
        let clientId = null
        if (props.auth.user) {
            clientId = props.auth.user.clients_id
        }
        return (selectedView === 'list') ?
            <CameraListView
                {...props}
                loading={false}
                onOpenModal={onOpenModal}
                clientId={props.auth.user.clients_id}
                authAsCamera={props.camerasActions.authAsCamera}
            />
            : <CameraGridView
                {...props}
                loading={false}
                onOpenModal={onOpenModal}
                clientId={clientId}
                vanity={props.vanity}
                antivanity={props.antivanity}
                hideClients={props.hideClients}
                authAsCamera={props.camerasActions.authAsCamera}
            />
    }

    const getCreateCameraModal = () => {
        return ( <CreateCameraModal
            loading={props.cameras.create.loading}
            open={type === "CreateCamera"}
            onClose={onClose}
            error={props.cameras.create.error}
            errors={props.cameras.create.errors}
            onClick={ (args) => {
                props.camerasActions.create(args)
            } }
            success={props.cameras.create.success}
            statuses={props.cameras.statuses.statuses}
            types={props.cameras.types}
        />)
    }

    const getImportCoordinatesModal = () => {
        return ( <ImportCoordinatesModal
            loading={props.cameras.importCoordinates.loading}
            open={type === "ImportCoordinates"}
            onClose={onClose}
            error={props.cameras.importCoordinates.error}
            errors={props.cameras.importCoordinates.errors}
            success={props.cameras.importCoordinates.success}
            importCoordinates={props.camerasActions.importCoordinatesCSV}
        />)
    }

    const getUpdateCameraModal = () => {
        let clientId = null
        if (props.auth.user) {
            clientId = props.auth.user.clients_id
        }
        return (<UpdateCameraModal
            loading={props.cameras.update.loading}
            open={type === "UpdateCamera"}
            onClose={onClose}
            error={props.cameras.update.error}
            errors={props.cameras.update.errors}
            onClick={ (cameraId, details) => {
                props.camerasActions.update(cameraId, details).then(() => {
                    props.camerasActions.fetchCamera(cameraId)
                })
            } }
            setProcess={(cameraId, details, typeHandle) => {
                props.processingActions.setProcess(cameraId, details, typeHandle)
            }}
            removeProcess={(cameraId, typeHandle) => {
                props.processingActions.removeProcess(cameraId, typeHandle)
            }}
            success={props.cameras.update.success}
            statuses={props.cameras.statuses.statuses}
            types={props.cameras.types}
            camera={props.cameras.camera.details}
            cameras_id={props.cameras.camera.cameras_id}
            processing={props.processing}
            processingActions={props.processingActions}
            embeds={props.embeds}
            embedActions={props.embedActions}
            user={props.auth.user}
            // Adding tags functionality
            tagsActions={props.tagsActions}
            tags={props.tags}
            camerasActions={props.camerasActions}
            cameras={props.cameras}
            clients_id={clientId}
            bimFinal3dImage={props.bimFinal3dImage}
            bimFinal3dImageActions={props.bimFinal3dImageActions}

        />)
    }

    const toggleFilterTag = (id) => {
        if (selectedView === 'grid') {
            props.camerasActions.resetGridView()
        } else {
            props.camerasActions.resetListView()
        }
        props.camerasActions.toggleCameraFilterTag(id, selectedView)
        delayUpdateURLFragment()
    }

    const toggleFilterStatus = (status) => {
        if (selectedView === 'grid') {
            props.camerasActions.resetGridView()
        } else {
            props.camerasActions.resetListView()
        }
        props.camerasActions.toggleCameraFilterStatus(status, selectedView)
        delayUpdateURLFragment()
    }

    const toggleFilterType = (type) => {
        if (selectedView === 'grid') {
            props.camerasActions.resetGridView()
        } else {
            props.camerasActions.resetListView()
        }
        props.camerasActions.toggleCameraFilterType(type, selectedView)
        delayUpdateURLFragment()
    }

    const toggleFilterVersion = (status) => {
        if (selectedView === 'grid') {
            props.camerasActions.resetGridView()
        } else {
            props.camerasActions.resetListView()
        }
        props.camerasActions.toggleCameraFilterVersion(status, selectedView)
        delayUpdateURLFragment()
    }

    const updateSortBy = (field) => {
        if (selectedView === 'grid') {
            props.camerasActions.resetGridView()
        } else {
            props.camerasActions.resetListView()
        }
        props.camerasActions.changeCamerasSortBy(field, selectedView)
        delayUpdateURLFragment()
    }

    const onSearchChange = debounce((term) => {
        if (selectedView === 'grid') {
            props.camerasActions.filterBy(term)
            props.camerasActions.resetGridView()
        } else {
            props.camerasActions.resetListView()
            props.camerasActions.filterBy(term)
            props.camerasActions.fetchListViewCameras(1)
        }
        updateURLFragment()
    }, 1200)

    const delayUpdateURLFragment = () => {
        setTimeout(updateURLFragment, 200)
    }

    const updateURLFragment = () => {
        const preferences = []
        if (props.cameras.filterBy) {
            preferences["search"] = props.cameras.filterBy
        }
        preferences["sort"] = props.cameras.sort.field
        preferences["ascending"] = props.cameras.sort.ascending
        if (props.cameras.statuses.filterBy.length > 0) {
            const statusString = props.cameras.statuses.filterBy.join("|")
            preferences['status'] = statusString
        }
        if (props.cameras.filterByTypes.length > 0) {
            const typeString = props.cameras.filterByTypes.join("|")
            preferences['type'] = typeString
        }
        if (props.cameras.tags.filterBy.length > 0) {
            const tagsString = props.cameras.tags.filterBy.join("|")
            preferences['tags'] = tagsString
        }
        if (props.cameras.versions.filterBy.length > 0) {
            const versionsString = props.cameras.versions.filterBy.join("|")
            preferences['version'] = versionsString
        }
        var hashPartBuffer = [];
        for (var k in preferences) {
            hashPartBuffer.push(
                encodeURIComponent(k), '=', encodeURIComponent(preferences[k]),
                '&'
            )
        }
        if (hashPartBuffer.length) {
          hashPartBuffer.pop()
        }
        window.location.hash = hashPartBuffer.join('')
    }

    const parseURLFragment = () => {
        const hash = window.location.hash.substring(1)
        var pairs = hash.split(/&/)
        const preferences = {}
        for (var i = 0; i < pairs.length; i++) {
            const keyValue = pairs[i].split(/=/)
            // Validate that this has the right structure.
            if (keyValue.length === 2) {
                const key = decodeURIComponent(keyValue[0])
                const value = decodeURIComponent(keyValue[1])
                if (value.indexOf('|') < 0) {
                    preferences[key] = value
                } else {
                    preferences[key] = value.split('|')
                }

            }
        }

        // Update preferences
        if ('search' in preferences) {
            props.camerasActions.filterBy(preferences['search'])
        }
        if ('tags' in preferences) {
            const ids = preferences['tags']
            props.camerasActions.setCameraFilterTags(ids, selectedView)
        }
        if ('status' in preferences) {
            const statuses = preferences['status']
            props.camerasActions.setCameraFilterStatuses(statuses, selectedView)
        }
        if ('version' in preferences) {
            const versions = preferences['version']
            props.camerasActions.setCameraFilterVersions(versions, selectedView)
        }
        if ('type' in preferences) {
            const types = preferences['type']
            props.camerasActions.setCameraFilterTypes(types, selectedView)
        }
        if ('sort' in preferences) {
            const field = preferences['sort']
            let ascending = true
            if ('ascending' in preferences) {
                ascending = preferences['ascending']
            }
            props.camerasActions.setCamerasSortBy(field, ascending, selectedView)
        }
        if (selectedView === 'grid') {
            props.camerasActions.resetGridView()
        } else {
            props.camerasActions.resetListView()
        }
    }

    const createCameraButton = (isPermitted(props.auth.user, 'post-cameras-cameras')) ? (
        <Button
            className='create-camera-button'
            primary
            onClick={() => {
                props.camerasActions.resetCreate()
                setType('CreateCamera')
            }}
        >Create virtual camera</Button>
    ) : null

    const potAssignButton = (isPermitted(props.auth.user, 'any-potassign-any')) ?
        <Button
            className='pot-assign-button'
            onClick={() => {
                props.history.push('/cameras/pot-assign')
            }}
            primary
        >Pot assign</Button> : null

    const importCoordinatesButton = (props.auth.user && props.auth.user.roles_id !== 5) ?
        <Button
            className = 'import-csv-coordinates'
            onClick={() => {
                setType('ImportCoordinates')
            }}
        >Import Pot Coordinates</Button> : null;

    return (
        <div className='cameras-container'>
            <DocumentTitle title='Cameras' />
            <header className="sub-header sub-header--cameras">
                <Container fluid={true} style={{padding: '0 1.2em'}}>
                    <h1 className="page-title mobile-visible">Cameras</h1>
                    <span className='cameras-container__search-container' style={{display: 'flex', flexWrap: 'wrap'}}>
                        <Search
                            className={'cameras-search'}
                            placeholder={'Search...'}
                            defaultValue={props.cameras.filterBy}
                            onChange={onSearchChange}
                        />
                        <ViewToggle
                            onClick={(view) => {
                                if (view === 'grid') {
                                    props.camerasActions.resetGridView()
                                } else {
                                    props.camerasActions.resetListView()
                                }
                                setSelectedView(view)
                            }}
                            defaultValue={selectedView}
                            userRolesId={(props.auth.user)? props.auth.user.roles_id : null}
                        />
                    </span>
                    {(createCameraButton || potAssignButton || importCoordinatesButton)?
                        <div className="sub-header__camera-buttons">
                            {createCameraButton}
                            &nbsp;
                            {potAssignButton}
                            &nbsp;
                            {importCoordinatesButton}
                        </div>
                    : null}
                </Container>
                {props.auth.user && props.auth.user.roles_id !== 5 && <Segment>
                    <Grid columns={5} stackable>
                        <Grid.Row>
                            <Grid.Column width={3}>
                                <CamerasSort
                                    sortField = {props.cameras.sort.field}
                                    ascending = {props.cameras.sort.ascending}
                                    updateSortBy = {updateSortBy}
                                />
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <CameraStatusFilter
                                    statuses = {props.cameras.statuses}
                                    toggleFilterStatus = {toggleFilterStatus}
                                />
                            </Grid.Column>
                            <Grid.Column width={3}>
                                <CameraTypeFilter
                                    types = {props.cameras.types}
                                    filterByTypes = {props.cameras.filterByTypes}
                                    toggleFilterType = {toggleFilterType}
                                />
                            </Grid.Column>
                            <Grid.Column width={2}>
                                <CameraVersionFilter
                                    versions = {props.cameras.versions}
                                    toggleFilterVersion = {toggleFilterVersion}
                                />
                            </Grid.Column>
                            <Grid.Column width={16} style={{marginTop: '1em'}}>
                                <CameraTagsFilter
                                    tags = {props.cameras.tags}
                                    toggleFilterTag = {toggleFilterTag}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>}
            </header>
            <Container className="content-container" fluid={true} style={{padding: '0 1.2em'}}>
                {getSelectedCameraView()}
            </Container>
            {getUpdateCameraModal()}
            {getCreateCameraModal()}
            {getImportCoordinatesModal()}
        </div>
    )
}

CamerasContainer.propTypes = {
    authActions: PropTypes.object,
    auth: PropTypes.object,
    messengerActions: PropTypes.object,
    camerasActions: PropTypes.object,
    tagsActions: PropTypes.object,
    tags: PropTypes.object
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        projects: state.projects,
        cameras: state.cameras,
        tags: state.tags,
        embeds: state.embed,
        processing: state.processing,
        bimFinal3dImage: state.bimFinal3dImage
    }
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(authActions, dispatch),
        messengerActions: bindActionCreators(messengerActions, dispatch),
        camerasActions: bindActionCreators(camerasActions, dispatch),
        tagsActions: bindActionCreators(tagsActions, dispatch),
        embedActions: bindActionCreators(embedActions, dispatch),
        processingActions: bindActionCreators(processingActions, dispatch),
        bimFinal3dImageActions: bindActionCreators(bimFinal3dImageActions, dispatch)
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CamerasContainer))
