import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import {
    Container,
    Form,
    Button,
    Icon
} from 'semantic-ui-react'
import DocumentTitle from 'react-document-title'
import * as usersActions from '../actions/usersActions'
import * as authActions from '../actions/authActions'
import * as editUserActions from '../actions/editUserActions'
import * as addUserActions from '../actions/addUserActions'
import * as bulkUsersActions from '../actions/bulkUsersActions'
import * as logActions from '../actions/logActions'
import * as messengerActions from '../actions/messengerActions'
import * as ucpaActions from '../actions/userCameraProjectAssociationActions'
import * as projectsActions from '../actions/projectsActions'
import { checkAuth } from '../util/accessToken'
import UserList from '../components/UserList'
import UserGrid from '../components/UserGrid';
import SuspendUserModal from '../components/modals/SuspendUser'
import DeleteUserModal from '../components/modals/DeleteUser'
import ResendEmailVerificationModal from '../components/modals/ResendEmailVerification'
import CreateUserModal from '../components/modals/CreateUser'
import UpdateUserModal from '../components/modals/UpdateUser'
import UpdateBulkUsersModal from '../components/modals/UpdateBulkUsers'
import CreateBulkUsersModal from '../components/modals/CreateBulkUsers'
import { isPermitted } from '../util/acl'

import debounce from 'lodash/debounce'

class UsersContainer extends Component
{
    constructor(props) {
        super(props)
        this.rows = null
        this.rowsPerPage = this.props.rowsPerPage || 10
        this.currentPage = this.props.currentPage || 1
        this.lastClicked = null;
        this.state = {
            userClientId: null,
            options: [],
            searchField: null,
            searchString: null,
            activeIndex: null,
            isTablet: false,
            toggleOpen: false,
        }
    }

    fetchGridOrListTotals = (value) => {
        this.rows = value;
    }

    isTabletCheck = () => {
        if (window.outerWidth < 1201 && !this.state.isTablet) {
            this.setState({isTablet: true})
        } else if (window.outerWidth > 1200 && this.state.isTablet) {
            this.setState({isTablet: false})
        }
    }

    componentDidMount() {
        this.isTabletCheck()

        window.addEventListener('resize', () => {
            this.isTabletCheck()
        })

        window.scrollTo(0, 0)
        this.parseURLFragment()

        return checkAuth(this.props.auth, this.props.authActions, this.props.history)
        .then(() => {

            // Basically, a catchAll to ensure that modals are always reset
            this.props.editUserActions.suspendUserResetErrors()
            this.props.editUserActions.deleteUserResetErrors()
            this.props.editUserActions.userVerificationEmailResetErrors()

            this.props.addUserActions.resetErrors()
            this.props.bulkUsersActions.reset()
        })
        .catch(err => {})
        
    }

    componentDidUpdate(prevProps, prevState) {
        // If project admin user/LP4 user set the client id as the users client id
        if (this.props.auth.user && this.props.auth.user !== prevProps.auth.user && this.props.auth.user.clients_id && this.props.auth.user.roles_id && (this.props.auth.user.roles_id === 4 || this.props.auth.user.roles_id === 5)) {
            this.setState({userClientId: this.props.auth.user.clients_id})
        } else if (this.props.auth.user && (this.props.auth.user.roles_id !== 4 && this.props.auth.user.roles_id !== 5 ) && this.props.users.selectedUserClientId !== prevProps.users.selectedUserClientId) {
            this.setState({userClientId: this.props.users.selectedUserClientId})
        }


        if ((this.props.users.searchTerm !== prevProps.users.searchTerm) && this.props.users.searchTerm !== null) {
            this.updateURLFragment()
        }

        if ((this.props.users.searchField !== prevProps.users.searchField) && this.props.users.searchField !== null) {
            this.updateURLFragment()
        }
    }

    reload = () => {
        // this.props.usersActions.userListRequest(
        //     this.rows || this.rowsPerPage,
        //     this.currentPage,
        //     this.props.users.sortColumn,
        //     this.props.users.sortAscending,
        //     this.props.users.searchTerm,
        //     this.props.clientId,
        //     this.state.searchField
        // )

        // this.props.bulkUsersActions.reset()
    }

    updateURLFragment = () => {
        const preferences = []
        if (this.props.users.searchTerm) {
            preferences["search"] = this.props.users.searchTerm
        }

        if (this.props.users.searchField) {
            preferences["field"] = this.props.users.searchField
        }

        preferences["sort"] = this.props.users.sortColumn
        preferences["ascending"] = this.props.users.sortAscending

        let hashPartBuffer = [];
        for (let k in preferences) {
            hashPartBuffer.push(
                encodeURIComponent(k), '=', encodeURIComponent(preferences[k]),
                '&'
            )
        }

        if (hashPartBuffer.length) {
          hashPartBuffer.pop()
        }
        window.location.hash = hashPartBuffer.join('')
    }

    parseURLFragment = () => {
        const hash = window.location.hash.substring(1)
        let pairs = hash.split(/&/)
        let preferences = {}
        for (let i = 0; i < pairs.length; i++) {
            const keyValue = pairs[i].split(/=/)
            // Validate that this has the right structure.
            if (keyValue.length === 2) {
                const key = decodeURIComponent(keyValue[0])
                const value = decodeURIComponent(keyValue[1])
                if (value.indexOf('|') < 0) {
                    preferences[key] = value
                } else {
                    preferences[key] = value.split('|')
                }
            }
        }

        // // Update preferences
        if ('search' in preferences) {
            if ('field' in preferences) {
                this.props.usersActions.userListFilterBy(preferences['search'], preferences['field'])
            } else {
                this.props.usersActions.userListFilterBy(preferences['search'])
            }
        }
       
        if ('sort' in preferences) {
            const field = preferences['sort']
            let ascending = true
            if ('ascending' in preferences) {
                ascending = preferences['ascending']
            }
            this.props.usersActions.userListSortBy(field, ascending)
        }
    }


    onSearchFormSubmit = () => {
        this.props.usersActions.userListRequest(
            this.rows || this.rowsPerPage,
            1,
            this.props.users.sortColumn,
            this.props.users.sortAscending,
            this.state.searchString,
            this.props.clientId,
            this.state.searchField
        )
        this.updateURLFragment()
    }

    /**
    * Filter result set by search data
    *
    * @param {string} data - Value to search by
    */

    onSearchFieldChange = debounce((e, { name, value }) => {
        this.setState({
            [name]: value
        }, () => {
            this.onSearchFormSubmit();
        });
    }, 800)

    getClients = () => {
        if (!this.props.addUser.clients) {
            return []
        }
        return this.props.addUser.clients.map(function(elem) {
            return {
                value: elem.id,
                text: elem.name,
                key: elem.id,
            }
        });
    }

    getRoles = () => {
        if (!this.props.addUser.roles) {
            return []
        }
        return this.props.addUser.roles.map(function(elem) {
            return {
                value: elem.id,
                text: elem.name,
                key: elem.id,
            }
        });
    }

    getStatuses = () => {
        if (!this.props.addUser.statuses) {
            return []
        }
        return this.props.addUser.statuses.map(function (elem, idx) {
            return {
                value: elem.name,
                text: elem.name,
                key: idx
            }
        });
    }

    onModalClose = (_e, _d, stepsBack = 1) => {
        const steps = 0 - stepsBack // go expects negative value§
        const checkboxes = document.querySelectorAll('table input[type="checkbox"]')
        this.props.history.go(steps)
        checkboxes.forEach(element => {
            element.checked = false
        });
        this.props.usersActions.userListBulkEditReset()
        this.props.usersActions.userListBulkEditSelect(null)
        
        if (!this.props.isUserListLinkedFromProject) {
            this.reload()
        }
    }

    onCreateUserModalClose = (_e, _d, stepsBack = 1) => {
        const steps = 0 - stepsBack // go expects negative value§
        this.props.history.go(steps)
        this.props.usersActions.userListBulkEditReset()
        this.reload()
    }

    onCheckboxSelect = (event, data, id, clientId) => {
        if (this.props.users.usersToEdit && !this.props.users.usersToEdit.includes(id)) {
            let localUsersToEdit = []
            if (event.shiftKey && this.lastClicked) {
                const start = this.lastClicked.index + 1
                const end = this.props.users.tableData.findIndex(element => element.id === id)
                const range = this.props.users.tableData.slice(Math.min(start, end), Math.max(start, end)+1)

                for (var i=0; i < range.length; i++) {
                    const index = this.props.users.tableData.indexOf(range[i])
                    if (index > -1) {
                        localUsersToEdit = [
                            ...localUsersToEdit,
                            range[i].id
                        ]
                    }
                }

                localUsersToEdit.forEach(id => {
                    this.props.usersActions.userListBulkEdit(id)
                    this.props.editUserActions.userDetailsRequest(clientId, id, true)
                });
            } else {
                this.lastClicked = {
                    id: id,
                    index: this.props.users.tableData.findIndex(element => element.id === id)
                }
                this.props.usersActions.userListBulkEditSelect(clientId)
                this.props.usersActions.userListBulkEdit(id)
                this.props.editUserActions.userDetailsRequest(clientId, id, true)
            }
        } else {
            // Remove user from the state array when unchecked
            this.props.usersActions.userListBulkEditRemove(id)
            this.props.usersActions.userListBulkEditSelect(null)
        }
    }

    getForm = () => {
        return (
            <Form className='users__search-form'>
                <Form.Group style={{margin: '0px -0.5em'}}>
                    <Form.Input
                        loading={this.props.users.filtered && this.props.users.loading}
                        placeholder='Search...'
                        defaultValue={this.props.users.searchTerm}
                        name='searchString'
                        onChange={this.onSearchFieldChange}
                    />
                    {this.state.options.length > 0 && 
                    <Form.Dropdown
                        selection
                        options={this.state.options}
                        placeholder={'Field'}
                        defaultValue={this.props.users.searchField}
                        name='searchField'
                        onChange={this.onSearchFieldChange}
                    />}
                    {/* <Button
                        primary
                        content='Search' /> */}
                </Form.Group>
            </Form>
        )
    }


    getUserTableColumns = (columns) => {
        let formattedColumns = [{text: 'All', value: '', key: ''}]
        if (columns) {
            columns.map((column) => {
                if (column.title && ['Login', 'Name', 'Client'].includes(column.title)) {
                    formattedColumns.push({
                        text: column.title,
                        value: column.key,
                        key: column.key
                    })
                }

                return true
            })
        }

        this.setState({options: formattedColumns});
    }

    getUsersSearchOrToggle = () => {
        if (!this.props.isUserListLinkedFromProject) {
            if (this.state.isTablet) {
                return (
                    <Button basic primary onClick={() => {
                        this.setState(prevState => ({
                            toggleOpen: !prevState.toggleOpen
                            }
                        ))
                    }}>Toggle Search <Icon name='search' style={{margin: '0 0 0 0.5em'}}/></Button>
                )
            } else {
                return this.getForm()
            }
        } else {
            return <div></div>
        }
        
    }

    render() {
        const { clientId, userId } = this.props.match.params
        const clients = this.getClients()
        const roles = this.getRoles()
        const statuses = this.getStatuses()
        const rolesButton =
            (isPermitted(this.props.auth.user, 'post-roles-role')) ?
                <Button
                    primary
                    as={Link}
                    to='/roles'
                >Roles and Permissions</Button>
                : null

        const createBulkUsersButton =
            (isPermitted(this.props.auth.user, 'post-users-users') && !this.props.isUserListLinkedFromProject) ?
                <Button
                    primary
                    as={Link}
                    to='/users/bulk-create'
                >Bulk Create Users</Button>
                : null

        const createUserButton =
            (isPermitted(this.props.auth.user, 'post-users-users') /*&& !this.props.isUserListLinkedFromProject*/) ?
                <Button
                    primary
                    as={Link}
                    to={{pathname: '/users/create', state: {
                        fromProject: this.props.isUserListLinkedFromProject,
                        fromProjectId: this.props.projectId
                    }}}
                >Create User</Button>
                : null

        const createBulkEditButton =
            (isPermitted(this.props.auth.user, 'post-users-users')) ?
                <Button
                    primary
                    as={Link}
                    to='/users/bulk-update'
                >Bulk Edit Users</Button>
                : null

        const action = this.props.match.params.action

        let totalRows = null
        if (this.props.projects) {
            totalRows = this.props.projects.totalRows
        }

        return (
            <div className='users-container'>
                <DocumentTitle title='Users' />
                <header className="sub-header sub-header--users">
                    <Container fluid={true} style={{padding: '0 1.2em'}}>
                        <h1 className="page-title mobile-visible">Users</h1>
                        {this.getUsersSearchOrToggle()}
                        <div className="sub-header--users__buttons">
                            {this.props.users.usersToEdit && this.props.users.usersToEdit.length > 1 ? createBulkEditButton : null}
                            {this.props.clientId === undefined ? rolesButton : null}
                            {createUserButton}
                            {this.props.clientId === undefined ? createBulkUsersButton : null}
                            {this.props.csv}
                        </div>
                        {(this.state.isTablet && this.state.toggleOpen) ? this.getForm() : null}
                    </Container>
                </header>
                {this.props.auth.user && this.props.auth.user.roles_id === 5 && <UserGrid
                    clientId={this.props.clientId}
                    auth={this.props.auth}
                    users={this.props.users}
                    usersActions={this.props.usersActions}
                    projectsActions={this.props.projectsActions}
                    bulkUsersActions={this.props.bulkUsersActions}
                    checkboxSelect={this.onCheckboxSelect}
                    isUserListLinkedFromProject={this.props.isUserListLinkedFromProject}
                    projectId={this.props.projectId}
                    usersToEdit={this.props.users.usersToEdit}
                    fetchGridOrListTotals={this.fetchGridOrListTotals}
                />}
                {this.props.auth.user && this.props.auth.user.roles_id !== 5 && <UserList
                    clientId={this.props.clientId}
                    auth={this.props.auth}
                    users={this.props.users}
                    usersActions={this.props.usersActions}
                    projectsActions={this.props.projectsActions}
                    bulkUsersActions={this.props.bulkUsersActions}
                    checkboxSelect={this.onCheckboxSelect}
                    getUserTableColumns={this.getUserTableColumns}
                    isUserListLinkedFromProject={this.props.isUserListLinkedFromProject}
                    projectId={this.props.projectId}
                    usersToEdit={this.props.users.usersToEdit}
                    fetchGridOrListTotals={this.fetchGridOrListTotals}
                />}
                <SuspendUserModal
                    open={action === 'suspend'}
                    clientId={clientId}
                    userId={userId}
                    error={Object.keys(this.props.editUser.suspend.errors).length > 0}
                    success={this.props.editUser.suspend.id && this.props.editUser.suspend.id > 0}
                    message={this.props.editUser.suspend.message}
                    loading={this.props.editUser.suspend.loading}
                    errors={this.props.editUser.suspend.errors}
                    onClose={this.onModalClose}
                    editUserActions={this.props.editUserActions}
                />
                <DeleteUserModal
                    open={action === 'delete'}
                    clientId={clientId}
                    userId={userId}
                    error={Object.keys(this.props.editUser.delete.errors).length > 0}
                    success={this.props.editUser.delete.users_id && this.props.editUser.delete.users_id > 0}
                    message={this.props.editUser.delete.message}
                    loading={this.props.editUser.delete.loading}
                    errors={this.props.editUser.delete.errors}
                    onClose={this.onModalClose}
                    editUserActions={this.props.editUserActions}
                />
                <ResendEmailVerificationModal
                    open={action === 'resend-verification'}
                    clientId={clientId}
                    userId={userId}
                    error={Object.keys(this.props.editUser.resendEmailVerification.errors).length > 0}
                    success={this.props.editUser.resendEmailVerification.users_id}
                    message={this.props.editUser.resendEmailVerification.message}
                    loading={this.props.editUser.resendEmailVerification.loading}
                    errors={this.props.editUser.resendEmailVerification.errors}
                    onClose={this.onModalClose}
                    editUserActions={this.props.editUserActions}
                />
                <CreateUserModal
                    open={action === 'create'}
                    auth={this.props.auth}
                    ucpa={this.props.ucpa}
                    clients={clients}
                    clientId={this.state.userClientId}
                    roles={roles}
                    statuses={statuses}
                    addUser={this.props.addUser}
                    onClose={this.onCreateUserModalClose}
                    addUserActions={this.props.addUserActions}
                    ucpaActions={this.props.ucpaActions}
                    projectsTotalRows={totalRows}
                />
                <UpdateUserModal
                    open={action === 'update'}
                    auth={this.props.auth}
                    users={this.props.users}
                    editUser={this.props.editUser}
                    ucpa={this.props.ucpa}
                    clients={clients}
                    roles={roles}
                    statuses={statuses}
                    clientId={clientId}
                    userId={userId}
                    onClose={this.onModalClose}
                    usersActions={this.props.usersActions}
                    addUserActions={this.props.addUserActions}
                    editUserActions={this.props.editUserActions}
                    ucpaActions={this.props.ucpaActions}
                    resetMfa={this.props.authActions.resetMfa}
                    isUserListLinkedFromProject={this.props.isUserListLinkedFromProject}
                />
                {this.state.userClientId && <UpdateBulkUsersModal
                    open={action === 'bulk-update'}
                    auth={this.props.auth}
                    editUser={this.props.editUser}
                    ucpa={this.props.ucpa}
                    clients={clients}
                    roles={roles}
                    statuses={statuses}
                    clientId={this.state.userClientId}
                    userId={userId}
                    users={this.props.users}
                    onClose={this.onModalClose}
                    editUserActions={this.props.editUserActions}
                    usersActions={this.props.usersActions}
                    ucpaActions={this.props.ucpaActions}
                    usersToEdit={this.props.users.usersToEdit}
                />}
                <CreateBulkUsersModal
                    open={action === 'bulk-create'}
                    clientId={this.state.userClientId}
                    clients={clients}
                    statuses={statuses}
                    roles={roles}
                    auth={this.props.auth}
                    bulkUsers={this.props.bulkUsers}
                    ucpa={this.props.ucpa}
                    onClose={this.onModalClose}
                    bulkUsersActions={this.props.bulkUsersActions}
                    addUserActions={this.props.addUserActions}
                    editUserActions={this.props.editUserActions}
                    ucpaActions={this.props.ucpaActions}
                    projectsTotalRows={totalRows}
                />
            </div>
        )
    }
}

UsersContainer.propTypes = {
    authActions: PropTypes.object,
    auth: PropTypes.object,
    editUserActions: PropTypes.object,
    editUser: PropTypes.object,
    addUser: PropTypes.object,
    addUserActions: PropTypes.object,
    bulkUsersActions: PropTypes.object,
    bulkUsers: PropTypes.object,
    logActions: PropTypes.object,
    messengerActions: PropTypes.object,
    ucpaActions: PropTypes.object
}

function mapStateToProps(state) {
    return {
        users: state.users,
        auth: state.auth,
        editUser: state.editUser,
        addUser: state.addUser,
        bulkUsers: state.bulkUsers,
        ucpa: state.ucpa,
        projects: state.projects
    }
}

function mapDispatchToProps(dispatch) {
    return {
        usersActions: bindActionCreators(usersActions, dispatch),
        authActions: bindActionCreators(authActions, dispatch),
        editUserActions: bindActionCreators(editUserActions, dispatch),
        addUserActions: bindActionCreators(addUserActions, dispatch),
        bulkUsersActions: bindActionCreators(bulkUsersActions, dispatch),
        logActions: bindActionCreators(logActions, dispatch),
        messengerActions: bindActionCreators(messengerActions, dispatch),
        ucpaActions: bindActionCreators(ucpaActions, dispatch),
        projectsActions: bindActionCreators(projectsActions, dispatch)
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(UsersContainer))
