import React, { Component } from 'react'
import {
    Button,
    Modal,
    Message,
    Form
} from 'semantic-ui-react'
import { getErrorMessage } from './../../util/errors'
import PropTypes from 'prop-types'
import PermissionListView from "../PermissionListView";

class CreateRoleModal extends Component {

    constructor(props) {
        super(props)
        this.state = this.getInitialState()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.success === true && prevProps.success === false) {
            this.onClose()
        }
    }

    getHeader = (error, success) => {
        if (error) {
            return <h3 id='successStatus'>Error</h3>
        } else if (success) {
            return <h3 id='successStatus'>Success!</h3>
        } else {
            return ""
        }
    }

    getContent = (error, success, loading, errors) => {

        // We might have a server error, or just regular errors
        if (error) {
            // return this.renderForm()
            // getErrorMessage()
            return null;

        } else if (success) {
            return <p>Successfully created role</p>
        } else if (loading) {
            return <p>Processing...</p>
        } else {
            return null;
        }
    }

    getInitialState = () => {
        return {
            name: '',
            description: '',
            power: 10,
            ldap_group: '',
            permissions: []
        }
    }

    onPermissionChange = (permissionId, isChecked) => {
        if (isChecked) {
            // Let's add it, if not already there...
            const idx = this.state.permissions.findIndex((elem) => {
                return elem === permissionId
            })

            if (idx === -1) {
                // It's safe to add it
                let permissions = this.state.permissions.slice()
                permissions.push(permissionId)
                this.setState({permissions: permissions})
            }

        } else {
            // Let's remove it, if not already there...
            const idx = this.state.permissions.findIndex((elem) => {
                return elem === permissionId
            })

            if (idx > -1) {
                let permissions = this.state.permissions.slice()
                permissions.splice(idx, 1)
                this.setState({permissions: permissions})
            }
        }
    }

    onNameChange = (event, {value}) => {
        this.setState({name: value})
    }

    onDescriptionChange = (event, {value}) => {
        this.setState({description: value})
    }

    onPowerChange = (event, {value}) => {
        this.setState({power: value})
    }

    onLdapGroupChange = (event, {value}) => {
        this.setState({ldap_group: value})
    }

    onClose = () => {
        this.setState(this.getInitialState(), () => {
            this.props.onClose()
        })
    }

    onClick = () => {
        this.props.onClick(this.state)
    }

    render = () => {

        const createBtn = (!this.props.success) ?
            <Button 
                id='createRoleButton'
                loading={this.props.processing}
                disabled={!this.state.permissions || this.state.permissions.length < 1}
                onClick={this.onClick}
                positive
            >Create</Button> : null

        const message = (this.props.success || this.props.error) ?
            <Message
                error={this.props.error}
                success={this.props.success}
                visible={false}
                header={this.getHeader(this.props.error, this.props.success)}
                content={this.getContent(this.props.error, this.props.success, this.props.processing, this.props.errors)}
            /> : null

        const form = (!this.props.success) ?
            <Form size='large'>
                <Form.Field>
                    <label>Name</label>
                    <Form.Input
                        id='createRoleNameField'
                        fluid
                        placeholder='Name'
                        onChange={this.onNameChange}
                        error={false}
                        defaultValue={this.state.name}
                    />
                </Form.Field>
                <Message
                    error={true}
                    visible={this.props.errors.name !== undefined}
                    header={''}
                    content={getErrorMessage(this.props.errors, 'name')}
                />
                <Form.Field>
                    <label>Description</label>
                    <Form.TextArea
                        id='createRoleDescriptionField'
                        placeholder='Description'
                        onChange={this.onDescriptionChange}
                        error={false}
                        defaultValue={this.state.description}
                    />
                </Form.Field>
                <Message
                    error={true}
                    visible={this.props.errors.description !== undefined}
                    header={''}
                    content={getErrorMessage(this.props.errors, 'description')}
                />

                <Form.Field>
                    <label>LDAP Group (optional)</label>
                    <Form.Input
                        id='createRoleLdapGroupField'
                        fluid
                        placeholder='LDAP Group'
                        onChange={this.onLdapGroupChange}
                        error={false}
                        defaultValue={this.state.ldap_group}
                    />
                </Form.Field>
                <Message
                    error={true}
                    visible={this.props.errors.ldap_group !== undefined}
                    header={''}
                    content={getErrorMessage(this.props.errors, 'ldap_group')}
                />

                <Form.Field>
                    <label>Power</label>
                    <Form.Input
                        type='number'
                        id='createRolePowerField'
                        fluid
                        placeholder='Power'
                        onChange={this.onPowerChange}
                        error={false}
                        defaultValue={this.state.power}
                    />
                </Form.Field>
                <Message
                    error={true}
                    visible={this.props.errors.power !== undefined}
                    header={''}
                    content={getErrorMessage(this.props.errors, 'power')}
                />


                <h3>Permissions</h3>
                <PermissionListView
                    rolesActions={this.props.rolesActions}
                    roles={this.props.roles}
                    auth={this.props.auth}
                    onPermissionChange={this.onPermissionChange}
                    checkedPermissions={this.state.permissions}
                />


            </Form>
            : null
        return (
            <Modal size={"large"} open={this.props.open} onClose={this.onClose} closeIcon>
                <Modal.Header>Create a role</Modal.Header>
                <Modal.Content>
                    {message}
                    {form}
                    <div className='modalButtons'>
                        {createBtn}
                    </div>
                </Modal.Content>
            </Modal>
        )
    }


}

CreateRoleModal.propTypes = {
    open: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired,
    processing: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    rolesActions: PropTypes.object.isRequired,
    roles: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
}

export default CreateRoleModal
