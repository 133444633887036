import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon, Grid, Button, Loader, Popup } from 'semantic-ui-react'
import HorizontalSlider from './forms/HorizontalSlider'
import CameraFocusV4 from './CameraFocusV4'

class CameraSettingsV4 extends Component {

    constructor(props) {
        super(props)
        this.state = {
            cameraSettings: {},
            showAdvanced: false
        }
    }

    componentDidMount() {
        this.props.cameraControlActions.fetchProperties(this.props.cameras_id)
        this.props.cameraControlActions.fetchStatus(this.props.cameras_id)
    }

    onChangeCameraSettings = (properties) => {
        this.setState({
            loaderActive: true
        })
        this.props.cameraControlActions.updateProperties(this.props.cameras_id, properties).then(() => {
            // After updating properties, let's also refresh what we have locally
            this.setState({
                cameraSettings: {},
                loaderActive: false
            })
            this.props.cameraControlActions.fetchProperties(this.props.cameras_id)
        }).then(() => {
            this.props.shootPreview()
        })
    }

    getApertureOptions = () => {
        const apertureItems =  this.props.camera_control.properties.details.aperture.options.map((item, index) => {
            return {  id: index, value: item }
        })

        return apertureItems
    }

    getExposureCompensationOptions = () => {
        return this.props.camera_control.properties.details.exposure_compensation_a.options.map((item, index) => {
            return {  id: index, value: item }
        })
    }

    getIsoOptions = () => {
        return this.props.camera_control.properties.details.iso.options.map((item, index) => {
            return {  id: index, value: item }
        })
    }

    getForm = () => {

        const apertureSlider = (this.props.camera_control.properties.details && this.props.camera_control.properties.details.aperture) ?
            <div>
                <h3>
                    Aperture &nbsp;
                    <Popup trigger={<Icon name="question circle outline" />}>
                        <p><strong>What is this?</strong></p>
                        <p>Aperture is an opening in a camera lens that can be adjusted to let in more or less light. A small aperture lets in less light and leads to a darker image, while a larger one can provide the sensors more light.  Also a lower value can add depth by blurring backgrounds whilst higher values create sharp landscape images</p>

                        <small>Recommended setting: 8</small>
                    </Popup>
                </h3>
                <HorizontalSlider
                    title="apaerture"
                    items={this.getApertureOptions()}
                    onChange={(val) => {
                        this.setState(prevState => ({
                            cameraSettings: {
                              ...prevState.cameraSettings,
                              aperture: val,
                            }
                        }))
                    }}
                    value={(this.state.cameraSettings.aperture)?
                        this.state.cameraSettings.aperture :
                        this.props.camera_control.properties.details.aperture.value
                    }
                />
            </div> : null;

        const exposureCompensationSlider = (this.props.camera_control.properties.details && this.props.camera_control.properties.details.exposure_compensation_a) ?
            <div>
                <h3>Exposure Compensation &nbsp;
                    <Popup trigger={<Icon name="question circle outline" />}>
                        <p><strong>What is this?</strong></p>
                        <p>Exposure Compensation allows you to override exposure settings, in order to darken or brighten images.</p>
                        <small>Recommended setting: 0</small>
                    </Popup>
                </h3>
                <HorizontalSlider
                    title="exposure"
                    items={this.getExposureCompensationOptions()}
                    onChange= {(val) => {
                        this.setState(prevState => ({
                            cameraSettings: {
                              ...prevState.cameraSettings,
                              exposure_compensation: val,
                            }
                        }))
                    }}
                    value={(this.state.cameraSettings.exposure_compensation)?
                        this.state.cameraSettings.exposure_compensation :
                        this.props.camera_control.properties.details.exposure_compensation_a.value
                    } 
                />
            </div> : <div><Loader inline='centered' active /></div>

        const isoSlider = (this.props.camera_control.properties.details && this.props.camera_control.properties.details.iso) ?
            <div>
                <h3>ISO Speed &nbsp;
                <Popup trigger={<Icon name="question circle outline" />}>
                        <p><strong>What is this?</strong></p>
                        <p>ISO speed controls the sensitivity of the sensor that absorbs light. The higher the ISO speed, the more sensitive the camera sensor is to light.</p>
                        <small>Recommended setting: Auto</small>
                    </Popup>
                </h3>
                <HorizontalSlider
                    title="iso"
                    items={this.getIsoOptions()}
                    onChange= {(val) => {
                        // if (val === this.props.camera_control.properties.details.iso.value) {
                        //     return
                        // }
                        this.setState(prevState => ({
                            cameraSettings: {
                              ...prevState.cameraSettings,
                              iso: val,
                            }
                        }))
                    }}
                    value={(this.state.cameraSettings.iso)?
                        this.state.cameraSettings.iso :
                        this.props.camera_control.properties.details.iso.value
                    }
                />
            </div> : null

            const updateButton = <Button primary className='button--update-camera' onClick={() => this.onChangeCameraSettings(this.state.cameraSettings)}><Icon name='camera' /> Apply Changes</Button>


        return (
            <div className='camera-control__controls'>
                <CameraFocusV4
                    cameraControlV4Actions={this.props.cameraControlV4Actions}
                    cameras_id={this.props.cameras_id}
                    camera_control_v4={this.props.camera_control_v4}
                    shootPreview={this.props.shootPreview}
                />
                {exposureCompensationSlider}
                {this.state.showAdvanced && apertureSlider}
                {this.state.showAdvanced && isoSlider}

                {this.props.camera_control.properties.details && <Button size="small" style={{display: 'block'}} onClick={() => this.setState((prevState) => (
                    {showAdvanced: !prevState.showAdvanced}))}>
                        Toggle Advanced
                    </Button>
                }

                {(Object.keys(this.state.cameraSettings).length && this.state.cameraSettings !== this.props.camera_control.camera_settings)? updateButton : null}
                <Loader active={this.state.loaderActive} inline style={{marginLeft: '10px'}}/>
            </div>
        )

    }

    render = () => {
        return (
            <div className='upload-settings'>
                <Grid columns='1'>
                    <Grid.Row>
                        <Grid.Column>
                            {(this.props.camera_control.properties.details)?
                                this.getForm()
                            : <div className="animated-blank-block-200" />}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        )
    }
}

CameraSettingsV4.propTypes = {
    cameraControlActions: PropTypes.object.isRequired,
    cameras_id: PropTypes.string.isRequired,
    camera_control: PropTypes.object.isRequired
}

export default CameraSettingsV4
