import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as authActions from '../actions/authActions'
import * as logActions from '../actions/logActions'
import * as messengerActions from '../actions/messengerActions'
import * as projectsActions from '../actions/projectsActions'
import * as camerasActions from '../actions/camerasActions'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import DocumentTitle from 'react-document-title'
import { Header, Icon, Input, Segment } from 'semantic-ui-react'
import ProjectGrid from './ProjectGrid'
import HomeV4 from './HomeV4'

const Home = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [searchValue, setSearchValue] = useState(null)
    let auth = null

    const validateUser = async () => {
        if (props.auth.access_token || localStorage.getItem('accessToken') !== null) {
            props.authActions.loggedInUserDetailsRequest()
        } else {
            const refreshToken = localStorage['refreshToken']
            if (refreshToken) {
                return props.authActions.refreshAccessTokenRequest(
                    refreshToken,
                    props.history
                ).then(() => {
                    props.authActions
                        .loggedInUserDetailsRequest()
                })
            } else {
                props.history.push('/auth')
            }
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        validateUser();

        if (props.projects) {
            props.projectsActions.projectListRequest(
                props.rowsPerPage || 10,
                props.projects.currentPage,
                props.projects.sortColumn,
                props.projects.sortAscending,
                props.projects.searchTerm,
                props.clientId
            )
        }

        props.camerasActions.fetchGridViewCameras()
    }, [])

    useEffect(() => {
        if (props.auth !== auth && props.auth.user) {
            auth = props.auth
            setIsLoading(false)
        }
    }, [props.auth])

    useEffect(() => {
        validateUser();

    }, [props.auth.access_token]);

    const handleInputChange = event => {
        setSearchValue(event.target.value)
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearchClick();
        }
    }

    const handleSearchClick = () => {
        props.history.push(`/cameras#search=${searchValue}`)
    }

    let content = () => {
        if (props.auth.user && props.auth.user.roles_id === 4) { // Project Admin
            return (
                <>
                    <br />
                        <ProjectGrid projects={props.projects} projectsActions={props.projectsActions} auth={props.auth}/>
                </>
            )
        } else if (props.auth.user && props.auth.user.roles_id === 5) { // LP4
            return (
                <HomeV4 projects={props.projects} cameras={props.cameras.grid_view.cameras}/>
            )
        } else {
            return (
                <Segment placeholder>
                    <Header icon>
                        <Icon name='search' />
                        Find Cameras
                    </Header>
                    <Segment.Inline>
                        <Input
                            className={'cameras-search'}
                            placeholder={'Search...'}
                            onChange={handleInputChange}
                            onKeyPress={handleKeyPress}
                            action={{
                                content: 'Search',
                                onClick: () => handleSearchClick(),
                                icon: 'search',
                                color: 'blue'
                            }}
                        />
                    </Segment.Inline>
                </Segment>
            )
        }
    }

    return (
        <>
        <DocumentTitle title='Home' />
        <h1 className="page-title page-title--home" style={{marginTop: '20px'}}>Welcome to Lobster Vision </h1>
        {(isLoading) ? <div className="animated-blank-block-200" /> : content()}
        </>
    )
}

Home.propTypes = {
    authActions: PropTypes.object,
    auth: PropTypes.object,
    logActions: PropTypes.object,
    messengerActions: PropTypes.object
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        projects: state.projects,
        cameras: state.cameras
    }
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(authActions, dispatch),
        logActions: bindActionCreators(logActions, dispatch),
        messengerActions: bindActionCreators(messengerActions, dispatch),
        projectsActions: bindActionCreators(projectsActions, dispatch),
        camerasActions: bindActionCreators(camerasActions, dispatch),
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Home))
