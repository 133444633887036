import * as types from './actionTypes'
import { API_BASE_URL}  from '../config'
import { addMessage } from './messengerActions'
import { log } from './logActions'
import { notAuthorisedResponse } from './authActions'
import { fetchWithToken } from '../util/accessToken'

export function reset() {
    return { type: types.BIM_FINAL_3D_IMAGE_RESET }
}

export function uploadImage(cameraId, formData) {
    return (dispatch, getState) => {
        dispatch({ type: types.BIM_FINAL_3D_IMAGE_UPLOAD_REQUEST })
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/final-3d-image`,
            {
                method: 'POST',
                mode: 'cors',
                body: formData
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    // might be forbidden!
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch({ type: types.BIM_FINAL_3D_IMAGE_UPLOAD_ERRORS, errors: data.errors })
                            } else {
                                dispatch({ type: types.BIM_FINAL_3D_IMAGE_UPLOAD_ERRORS, errors:  { upload: { unknownError: 'Unknown error' }}})
                            }
                        })
                    }
                } else {
                    response.json().then((data) => {
                        // Presumably, errors of some kind...
                        if (data.errors) {
                            dispatch({ type: types.BIM_FINAL_3D_IMAGE_UPLOAD_ERRORS, errors: data.errors })
                        } else {
                            dispatch({
                                type: types.BIM_FINAL_3D_IMAGE_UPLOAD_SUCCESS
                            })
                            dispatch({
                                type: types.BIM_FINAL_3D_SET_FILENAME,
                                ...data
                            })

                        }
                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(log("error", response))
            })
    }
}

export function removeImage(cameraId) {
    return (dispatch, getState) => {
        dispatch({ type: types.BIM_FINAL_3D_IMAGE_REMOVE_REQUEST})
        return fetchWithToken(
            `${API_BASE_URL}/api/cameras/${cameraId}/final-3d-image`,
            {
                method: 'DELETE',
                mode: 'cors'
            },
            dispatch,
            getState
        )
            .then(response => {
                if (!response.ok) {
                    // might be forbidden!
                    if (response.status === 401) {
                        // Remember, we're bouncing out 401s
                        dispatch(notAuthorisedResponse())
                    } else {
                        response.json().then(data => {
                            if (data.errors) {
                                dispatch({ type: types.BIM_FINAL_3D_IMAGE_REMOVE_ERRORS, errors: data.errors })
                            } else {
                                dispatch({ type: types.BIM_FINAL_3D_IMAGE_REMOVE_ERRORS, errors:  { remove: { unknownError: 'Unknown error' }}})
                            }
                        })
                    }
                } else {
                    response.json().then((data) => {
                        // Presumably, errors of some kind...
                        if (data.errors) {
                            dispatch({ type: types.BIM_FINAL_3D_IMAGE_REMOVE_ERRORS, errors: data.errors })
                        } else {
                            dispatch({
                                type: types.BIM_FINAL_3D_IMAGE_REMOVE_SUCCESS
                            })
                            // dispatch({
                            //     type: types.BIM_FINAL_3D_SET_FILENAME,
                            //     filename: null
                            // })
                        }
                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(log("error", response))
            })
    }
}
