import initialState from './initialState';
import {
    PASSWORD_INPUT_CHANGE,
    COMFIRM_PASSWORD_INPUT_CHANGE,
    PASSWORD_CHANGE_SUBMIT,
    PASSWORD_CHANGE_SUCCESS,
    PASSWORD_CHANGE_ERROR,
    PASSWORD_RESET_ERRORS,
    PASSWORD_RESET_REQUEST_SUBMIT,
    PASSWORD_RESET_REQUEST_SUCCESS,
    PASSWORD_RESET_REQUEST_ERROR,
    PASSWORD_VALIDATION_REQUEST,
    PASSWORD_VALIDATION_SUCCESS,
    PASSWORD_VALIDATION_ERRORS,
    LP4_PASSWORD_UPDATE_SUCCESS,
    LP4_PASSWORD_UPDATE_ERROR
} from '../actions/actionTypes';

export default function password(state = initialState.password, action) {

    switch (action.type) {
        case PASSWORD_INPUT_CHANGE:
            return Object.assign({}, state, {strength: action.strength, match: action.match, warning: action.warning, suggestions: action.suggestions})
        case COMFIRM_PASSWORD_INPUT_CHANGE:
            return Object.assign({}, state, {match: action.match})
        case PASSWORD_CHANGE_SUBMIT:
            return Object.assign({}, state, {loading: true, errors: []})
        case PASSWORD_CHANGE_SUCCESS:
            return Object.assign({}, state, {errors: [], loading: false, success: true})
        case PASSWORD_CHANGE_ERROR:
            return Object.assign({}, state, {errors: action.errors, loading: false, success: false})
        case PASSWORD_RESET_ERRORS:
            return Object.assign({}, state, {errors: [], loading: false, match: false, success: false})
        case PASSWORD_RESET_REQUEST_SUBMIT:
            return Object.assign({}, state, {errors: [], loading: true, success: false})
        case PASSWORD_RESET_REQUEST_SUCCESS:
            return Object.assign({}, state, {errors: [], loading: false, success: true})
        case PASSWORD_RESET_REQUEST_ERROR:
            return Object.assign({}, state, {errors: action.errors, loading: false, success: false})
        case PASSWORD_VALIDATION_REQUEST:
            return Object.assign({}, state, {errors: [], loading: true, valdationSuccess: false})
        case PASSWORD_VALIDATION_SUCCESS:
            return Object.assign({}, state, {errors: [], loading: false, valdationSuccess: true})
        case PASSWORD_VALIDATION_ERRORS:
            return Object.assign({}, state, {errors: action.errors, loading: false, valdationSuccess: false})
        case LP4_PASSWORD_UPDATE_SUCCESS:
            return Object.assign({}, state, {errors: [], loading: false, success: true, match: false, valdationSuccess: false})
        case LP4_PASSWORD_UPDATE_ERROR:
            return Object.assign({}, state, {errors: [], loading: false, match: false, success: false, valdationSuccess: false})
        default:
            return state
    }
}
