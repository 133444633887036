import {Message, Form, Grid} from 'semantic-ui-react'
import { getErrorMessage } from '../../../util/errors'

const UpdateUserPrivateInputs = (props) => {
    return (
        <div>
            <Form.Field>
                <label>Username</label>
                <p>{props.user.username}</p>
            </Form.Field>
            <Form.Input
                fluid
                placeholder='E-mail address'
                label='E-mail address'
                onChange={(e) => props.onInputChange(e, 'email')}
                error={props.errors.email ? true : false}
                defaultValue={props.user.email}
            />
            <Message
                error={true}
                visible={props.errors.email}
                header={''}
                content={getErrorMessage(props.errors, 'email')}
            />
            <Form.Input
                fluid
                placeholder='Job Title'
                label='Job Title'
                error={(props.errors.job_title) ? true : false }
                onChange={(e) => props.onInputChange(e, 'job_title')}
                defaultValue={props.user.job_title}
            />
            <Message
                error={true}
                visible={(props.errors.job_title) ? true : false}
                header={"Error"}
                content={getErrorMessage(props.errors, 'job_title')}
            />
            <Grid columns='2' stackable>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Input
                            fluid
                            placeholder='First Name'
                            label='First Name'
                            error={(props.errors.first_name) ? true : false}
                            onChange={(e) => props.onInputChange(e, 'first_name')}
                            defaultValue={props.user.first_name}
                        />
                        <Message
                            error={true}
                            visible={(props.errors.first_name) ? true : false}
                            header={"Error"}
                            content={getErrorMessage(props.errors, 'first_name')}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Input
                            fluid
                            placeholder='Last Name'
                            label='Last Name'
                            onChange={(e) => props.onInputChange(e, 'last_name')}
                            error={props.errors.last_name ? true : false}
                            defaultValue={props.user.last_name}

                        />
                        <Message
                            error={true}
                            visible={props.errors.last_name}
                            header={''}
                            content={getErrorMessage(props.errors, 'last_name')}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    )
}

export default UpdateUserPrivateInputs;