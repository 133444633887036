import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as authActions from '../../actions/authActions'
import * as securityActions from '../../actions/securityActions'
import * as logActions from '../../actions/logActions'
import * as messengerActions from '../../actions/messengerActions'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import ApplyLockdownModal from "../modals/ApplyLockdown";
import RemoveLockdownModal from "../modals/RemoveLockdown";
import { checkAuth } from '../../util/accessToken'

class Admin extends Component {

    constructor(props) {
        super(props)
        this.state = { open: false, type: null }
    }

    componentDidMount() {
        return checkAuth(this.props.auth, this.props.authActions, this.props.history)
        .then(() => {
            // Let's fetch the options for the drop down selects
            this.props.securityActions.resetApplyLockdownErrors()
            this.props.securityActions.resetRemoveLockdownErrors()
        })
        .catch(err => {})
    }

    handleApplyLockdownClick = () => {
        this.props.securityActions.resetApplyLockdownErrors()
        this.openModal('ApplyLockdownModal')
    }

    handleRemoveLockdownClick = () => {
        this.props.securityActions.resetRemoveLockdownErrors()
        this.openModal('RemoveLockdownModal')
    }

    handleApplyLockdownConfirmedClick = (args) => {
        this.props.securityActions.applyLockdownRequest()
    }

    handleRemoveLockdownConfirmedClick = (args) => {
        this.props.securityActions.removeLockdownRequest()
    }

    closeModal = () => {

        this.setState((prevState, props) => {
            return {
                ...prevState,
                open: false,
                type: null
            }
        })
    }

    openModal = (type) => {

        this.setState((prevState, props) => {
            return {
                ...prevState,
                open: true,
                type: type
            }
        })

    }

    render() {
        return ( <div>
                    <h1>Admin</h1>
                    <Button onClick={() => { this.handleApplyLockdownClick() }} negative disabled={false}>Apply Lockdown</Button>
                    <Button onClick={() => { this.handleRemoveLockdownClick() }} positive disabled={false}>Remove Lockdown</Button>

                    <ApplyLockdownModal

                        open={this.state.type === "ApplyLockdownModal"}
                        onClose={this.closeModal}
                        onClick={ () => { this.handleApplyLockdownConfirmedClick()}}
                        error={this.props.security.applyLockdown.error}
                        success={this.props.security.applyLockdown.success}
                        message={this.props.security.applyLockdown.message}
                        loading={this.props.security.applyLockdown.loading}
                        errors={this.props.security.applyLockdown.errors}

                    />
                    <RemoveLockdownModal

                        open={this.state.type === "RemoveLockdownModal"}
                        onClose={this.closeModal}
                        onClick={ () => { this.handleRemoveLockdownConfirmedClick()}}
                        error={this.props.security.removeLockdown.error}
                        success={this.props.security.removeLockdown.success}
                        message={this.props.security.removeLockdown.message}
                        loading={this.props.security.removeLockdown.loading}
                        errors={this.props.security.removeLockdown.errors}

                    />

                </div>
        )

    }
}

Admin.propTypes = {
    authActions: PropTypes.object,
    securityActions: PropTypes.object,
    auth: PropTypes.object,
    security: PropTypes.object,
    logActions: PropTypes.object,
    messengerActions: PropTypes.object
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        security: state.security
    }
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(authActions, dispatch),
        securityActions: bindActionCreators(securityActions, dispatch),
        logActions: bindActionCreators(logActions, dispatch),
        messengerActions: bindActionCreators(messengerActions, dispatch)
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Admin))
