import * as types from './actionTypes'
import { API_BASE_URL}  from '../config'
import { addMessage } from './messengerActions'
import { log } from './logActions'
import { fetchWithToken } from '../util/accessToken'
import { notAuthorisedResponse } from './authActions'

export function clientsResponseSuccess(
    payload,
    currentPage,
    filtered,
    sortColumn,
    sortAscending,
    searchTerm
) {
    if (payload.totalItems) {
        return {
            type: types.CLIENTS_RESPONSE_SUCCESS,
            currentPage: currentPage,
            totalRows: payload.totalItems,
            tableData: payload.clients,
            filtered: filtered,
            sortColumn: sortColumn,
            sortAscending: sortAscending,
            searchTerm: searchTerm
        }
    } else {
        return {
            type: types.CLIENTS_RESPONSE_SUCCESS,
            totalRows: 0,
            tableData: [],
            filtered: filtered
        }
    }
}

export function clientsResponseErrors(
    response,
    currentPage,
    filtered,
    sortColumn,
    sortAscending,
    searchTerm,
) {
    return {
        type: types.CLIENTS_RESPONSE_ERRORS,
        errors: response.errors,
        currentPage: currentPage,
        totalRows: 0,
        tableData: [],
        filtered: filtered,
        sortColumn: sortColumn,
        sortAscending: sortAscending,
        searchTerm: searchTerm
    }
}

export function clientsRequest(pageSize, currentPage, sortColumn, sortAscending, searchTerm) {
    return (dispatch, getState) => {
        dispatch({ type: types.CLIENTS_REQUEST })

        let pagination = `pageSize=${pageSize}&currentPage=${currentPage}`
        let filtering = (searchTerm === undefined || searchTerm == null) ? "" : `&filter=${searchTerm}`
        let sorting = sortColumn === undefined ? "" : `&orderBy=${sortColumn}&ascending=${sortAscending}`

        return fetchWithToken(
            `${API_BASE_URL}/api/clients?${pagination}${sorting}${filtering}`,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (response.status === 401) {
                dispatch(notAuthorisedResponse())
            } else {
                return response.json()
            }
        })
        .then((json) => {
            let filtered = searchTerm === undefined ? false : true
            if (json.errors) {
                dispatch(clientsResponseErrors(
                    json,
                    currentPage,
                    filtered,
                    sortColumn,
                    sortAscending,
                    searchTerm
                    )
                )
            } else {
                dispatch(clientsResponseSuccess(
                    json,
                    currentPage,
                    filtered,
                    sortColumn,
                    sortAscending,
                    searchTerm
                    )
                )
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function clientsReset() {
    return dispatch => {
        return new Promise(function(resolve, reject) {
            dispatch({ type: types.CLIENTS_RESET })
            resolve()
        })
    }
}

export function clientMfaRequirements() {
    return (dispatch, getState) => {
        dispatch({ type: types.CLIENTS_MFA_REQUIREMENTS_REQUEST })
        return fetchWithToken(
            `${API_BASE_URL}/api/admin/client/mfa-requirements`,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (response.status === 401) {
                dispatch(notAuthorisedResponse())
            } else {
                return response.json()
            }
        })
        .then((data) => {
            if (data.errors) {
                dispatch({ type: types.CLIENTS_MFA_REQUIREMENTS_ERROR })
            } else {
                dispatch({
                    type: types.CLIENTS_MFA_REQUIREMENTS_SUCCESS,
                    data: data
                 })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function clientsFilterBy(searchTerm) {
    return { type: types.CLIENTS_FILTER_BY, searchTerm: searchTerm }
}