import * as types from './actionTypes'
import { addMessage } from './messengerActions'
import { log } from './logActions'
import { API_BASE_URL }  from '../config'
import { notAuthorisedResponse } from './authActions'
import { fetchWithToken } from '../util/accessToken'



export function getDetails(cameraId) {
    return (dispatch, getState) => {
        // Set loading state for fidget spinners
        dispatch( { type: types.CAMERA_UPLOAD_REQUEST})
        const url = `${API_BASE_URL}/api/cameras/${cameraId}/upload`

        // Perfrom request
        return fetchWithToken(
            url,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    // Remember, we're bouncing out 401s
                    dispatch(notAuthorisedResponse())
                } else {
                    response.json().then(data => {
                        if (data.errors) {
                            dispatch(
                                {
                                    type: types.CAMERA_UPLOAD_RESPONSE_ERRORS,
                                    errors: data.errors,
                                })
                        } else {
                            // Error
                            dispatch(
                                {
                                    type: types.CAMERA_UPLOAD_RESPONSE_ERRORS,
                                    errors: {
                                        unknownErrorType: {
                                            unknownError: "There was an unexpected error"
                                        }
                                    }
                                }
                            )
                        }

                    })
                }
            } else {
                response.json().then(data => {
                    if (data.errors) {
                        dispatch(
                            {
                                type: types.CAMERA_UPLOAD_RESPONSE_ERRORS,
                                errors: data.errors,
                            })
                    } else {
                        dispatch(
                            {
                                type: types.CAMERA_UPLOAD_RESPONSE_SUCCESS,
                                data: data
                            })
                    }

                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}
