import moment from 'moment';
import { Icon } from 'semantic-ui-react';

const ScheduleSegmentMobileV4 = (props) => {

    const period = props.period

    return (
        <div className="schedule__segment--mobile" onClick={() => props.actions.openEditPeriodModal(props.dayId, period.periodIndex)}>
            <Icon className='edit-icon' name='edit' />
            <p><strong>Program:</strong> Shoot & Upload</p>
            <p>S<strong>tart:</strong> {moment((period.start/3600),'HH').format('HH:mm')} </p>
            <p><strong>Stop:</strong> {moment((period.stop / 3600),'HH').format('HH:mm')} </p>
            <p><strong>Shooting Interval:</strong> {moment(period.interval,'X').format('m')} minutes</p>
        </div>
    )
}

export default ScheduleSegmentMobileV4