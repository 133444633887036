import React from 'react';
import { BrowserRouter } from 'react-router-dom'
import CacheBuster from 'react-cache-buster';
import packageInfo from '../../package.json';
import Header from './Header'
import Main from './Main'
import Security from './Security'
import Messenger from './Messenger'

const App = () => {
    const isProduction = process.env.NODE_ENV === 'production';
    return (
        <CacheBuster
            currentVersion={packageInfo.version}
            isEnabled={isProduction}
        >
            <BrowserRouter>
                <div>
                    <Header />
                    <Messenger />
                    <Security />
                    <Main />
                </div>
            </BrowserRouter>
        </CacheBuster>
    )
}

export default App
