import initialState from './initialState'
import {
    CAMERA_CONTROL_FETCH_DROP_REQUEST,
    CAMERA_CONTROL_FETCH_DROP_ERRORS,
    CAMERA_CONTROL_FETCH_DROP_SUCCESS,
    CAMERA_CONTROL_DROP_MODIFY_LIST,
    CAMERA_CONTROL_DROP_RESET
} from '../actions/actionTypes'

export default function drop(state = initialState.drop, action) {
    switch (action.type) {
        case CAMERA_CONTROL_FETCH_DROP_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    loading: true,
                    success: false,
                    error: false,
                    errors: {},
                    drop: [],
                    files: 0
                }
            )
        case CAMERA_CONTROL_FETCH_DROP_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    success: false,
                    error: true,
                    errors: action.errors,
                    drop: [],
                    files: 0
                }
            )
        case CAMERA_CONTROL_FETCH_DROP_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    success: true,
                    error: false,
                    errors: {},
                    drop: action.drop,
                    files: action.drop.length
                }
            )
        case CAMERA_CONTROL_DROP_MODIFY_LIST:
            let drop = state.drop
            // add and remove are from the point of view of the queue. Therefore
            // we do the opposite to the drop
            for (const filename in action.add) {
                if (action.add[filename]['okay']) {
                    const index = drop.indexOf(filename)
                    if (index > -1) {
                        drop = [
                            ...drop.slice(0, index),
                            ...drop.slice(index+1)
                        ]
                    }
                }
            }

            for (const filename in action.remove) {
                if (action.remove[filename]['okay']) {
                    const index = drop.indexOf(filename)
                    if (index === -1) {
                        drop.unshift(filename)
                    }
                }
            }
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    success: true,
                    error: false,
                    errors: {},
                    drop: drop,
                    files: drop.length
                }
            )
        case CAMERA_CONTROL_DROP_RESET:
            return initialState.drop
        default:
            return state
    }
}
