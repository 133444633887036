import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Input, Message, Popup, Icon } from 'semantic-ui-react'
import { getErrorMessage } from './../util/errors'
import { isPermitted } from '../util/acl'
import uniqid from 'uniqid'

class LogoUploader extends Component {
    //@TODO - Tidy up if checks on state.type
    constructor(props) {
        super(props)
        this.logoInput = React.createRef();
        this.state = {
            type: null,
            logo: ''
        }
    }

    componentDidMount() {
        if (this.props.project) {
            this.setState({type: 'projects'})
        } else if (this.props.client) {
            this.setState({type: 'clients'})
        }
    }

    componentWillUnmount() {
        this.props.logoActions.reset()
    }

    isReady = () => {
        if (this.props.client && this.props.client.name || this.props.project) {
            return true
        }
    }

    isRemovingLogo = () => {
        return this.props.logo.delete.processing
    }

    isUploadingLogo = () => {
        return this.props.logo.upload.processing
    }

    hasRemovedLogoButLoading = () => {
        if (this.state.type === 'clients') {
            return this.props.logo.delete.success && this.props.client.loading
        } else if (this.state.type === 'projects') {
            return this.props.logo.delete.success && this.props.project.loading
        }
    }

    hasUploadedLogoButLoading = () => {
        if (this.state.type === 'clients') {
            return this.props.logo.upload.success && this.props.client.loading
        } else if (this.state.type === 'projects') {
            return this.props.logo.upload.success && this.props.project.loading
        }
    }

    getLogo = () => {

        if (this.isRemovingLogo()) {
            return (
                <div className='logo-container'>
                    <div className="animated-blank-block-75" />
                </div>
            )
        }
        
        if (this.isUploadingLogo()) {
            return (
                <div className='logo-container'>
                    <div className="animated-blank-block-75" />
                </div>
            )
        }
        
        if (this.hasRemovedLogoButLoading()) {
            return (
                <div className='logo-container'>
                    <p>Logo not yet uploaded</p>
                </div>
            )
        }
        
        if (this.hasUploadedLogoButLoading()) {
            return (
                <div className='logo-container'>
                    <div className="animated-blank-block-75" />
                </div>
            )
        }
        
        if (!this.isReady()) {
            return (
                <div className='logo-container'>
                    <div className="animated-blank-block-75" />
                </div>
            )
        }

        // By here, it should be safe...
        let logo = null
        if (this.props.client && this.props.client.logo) {
            logo = `${this.props.client.logo}?v=${uniqid()}`
        } else if (this.props.project && this.props.project.details && this.props.project.details.logo) {
            logo = this.props.project.details.logo
        }

        if (logo) {
            return (
                <div>
                    <div className='logo-container'>
                        <img src={logo} alt="client logo" />
                    </div>
                    {(isPermitted(this.props.user, 'post-projects-removelogo'))?
                    <Popup
                        trigger={<Icon link={true} name='trash' onClick={() => {
                            let id = null;
                            if (this.state.type && this.state.type === 'clients') {
                                id = this.props.client.id
                            } else {
                                id = this.props.project.details.id
                            }
                            this.props.logoActions.removeLogo(id, this.state.type).then(() => {
                                if (this.state.type && this.state.type === 'clients') {
                                    this.props.editClientActions.clientDetailsRequest(this.props.client.id)
                                } else if (this.state.type && this.state.type === 'projects') {
                                    this.props.updateProjectActions.projectDetailsRequest(this.props.clientId, id)
                                }
                            })
                        }}/>}
                        content='Remove'
                    /> : null}
                </div>
            )
        }

        return (
            <div className='logo-container'>
                <p><i>No logo uploaded</i></p>
            </div>
        )
    }

    render = () => {
        if (!this.isReady()) {
            return (
                <div className='logo-uploader'>
                    <div className="animated-blank-block-75" />
                    <div className="animated-blank-line" />
                    <div className="animated-blank-line" />
                </div>
            )
        } else {
            return (
                <div className='logo-uploader'>
                    <div className="field">
                        <label>Logo</label>
                        {(this.props.dimensionText)? <small>Required Dimensions: ({this.props.dimensionText})
                        <br />
                        Max File size: 250KB
                        </small> : null}
                    </div>
                    {this.getLogo()}
                    {(isPermitted(this.props.user, 'post-projects-uploadlogo')) || (isPermitted(this.props.user, 'post-clients-uploadlogo')) ?
                        <>
                            <div className='upload-input-container'>
                                <input
                                    onChange={() => {
                                        if (this.logoInput.files && this.logoInput.files.length > 0) {
                                            this.setState({logo: this.logoInput.files[0].name})
                                            if (this.props.onUploadable) {
                                                this.props.onUploadable(true)
                                            }
                                        }
                                    }}
                                    ref={(logoInput) => this.logoInput = logoInput}
                                    type='file'
                                />
                                <Input
                                    action={{
                                        color: 'blue',
                                        labelPosition: 'left',
                                        icon: 'file image outline',
                                        content: 'Select File',
                                    }}
                                    actionPosition='left'
                                    placeholder='Image file'
                                    error={this.props.logo.upload.error}
                                    value={this.state.logo}
                                    className='upload-input-faker'
                                />
                            </div>
                            <Button
                                disabled={(this.state.logo.length === 0)}
                                primary
                                fluid
                                loading={this.props.logo.upload.processing}
                                onClick={() => {
                                    let formData = new FormData()
                                    formData.append('logo', this.logoInput.files[0])
                                    let reader = new FileReader();
                                    let id = null;
                                    if (this.state.type && this.state.type === 'clients') {
                                        id = this.props.client.id
                                    } else {
                                        id = this.props.project.details.id
                                    }
                                    reader.onloadend = () => {
                                        // Here, we can perform the uploading
                                        this.props.logoActions.resetRemoveLogo(id)
                                        // this.setState({logo: ''})
                                        this.props.logoActions.uploadLogo(id, formData, this.state.type).then(() => {
                                            if (this.state.type && this.state.type === 'clients') {
                                                this.props.editClientActions.clientDetailsRequest(this.props.client.id)
                                            } else if (this.state.type && this.state.type === 'projects') {
                                                this.props.updateProjectActions.projectDetailsRequest(this.props.clientId, id)
                                            }
                                            if (this.props.onUploadable) {
                                                this.props.onUploadable(false)
                                                if ( this.logoInput) {
                                                    this.logoInput.value = ''
                                                }
                                                this.setState({logo: ''})
                                            }

                                        })
                                    }
                                    reader.readAsBinaryString(this.logoInput.files[0]);
                                }}
                            >Upload</Button>
                            <Message
                                error={true}
                                visible={this.props.logo.upload.errors.logo !== undefined}
                                header={''}
                                content={getErrorMessage(this.props.logo.upload.errors, 'logo')}
                            />
                        </>
                    : null }
                </div>
            )

        }
    }
}

LogoUploader.propTypes = {
    logoActions: PropTypes.object.isRequired,
    logo: PropTypes.object.isRequired,
    onUploadable: PropTypes.func
}

export default LogoUploader
