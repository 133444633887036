import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as authActions from '../actions/authActions'
import * as headerActions from '../actions/headerActions'
import * as footerActions from '../actions/footerActions'
import * as logActions from '../actions/logActions'
import * as messengerActions from '../actions/messengerActions'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Grid } from 'semantic-ui-react'
import { withRouter, Redirect } from 'react-router-dom'


class Auth extends Component {
    constructor() {
        super()
        this.state = {
            redirect: false,
            url: null
        }
    }

    componentDidMount() {
        const paramsMatch = window.location.href.split('?')[1];

        if (paramsMatch) {
            const params = new URLSearchParams(paramsMatch);
            
            const accessToken = params.get('token');
            const accessTokenExpiry = params.get('expiry');
            const refreshToken = params.get('refresh');
            const redirectUrl = params.get('destination_url')
            if (redirectUrl) {
                this.setState({url: redirectUrl})
            }
            if (accessToken && accessTokenExpiry && refreshToken) {
                localStorage.setItem('accessToken', accessToken);
                localStorage.setItem('accessTokenExpiry', accessTokenExpiry);
                localStorage.setItem('refreshToken', refreshToken);
                this.setState({redirect: true})
            }
        }
    }

    render() {

        return (
            <div className='login-form'>
                 { (this.state.redirect && this.state.url) ? (<Redirect push to={this.state.url} />) : null }
                {/*
                    Heads up! The styles below are necessary for the correct render of this example.
                    You can do same with CSS, the main idea is that all the elements up to the `Grid`
                    below must have a height of 100%.
                */}
                <style>{`
                    body > div,
                    body > div > div,
                    body > div > div > div.login-form {
                        height: 100%;
                    }
                    `}</style>
                <Grid
                    textAlign='center'
                    style={{ height: '100%' }}
                    verticalAlign='middle'
                >
                    <Grid.Column style={{ maxWidth: 450 }}>
                        LOGGING IN
                    </Grid.Column>
                </Grid>
            </div>
        )
    }
}

Auth.propTypes = {
    authActions: PropTypes.object,
    auth: PropTypes.object,
    headerActions: PropTypes.object,
    header: PropTypes.object,
    footerActions: PropTypes.object,
    footer: PropTypes.object,
    captcha: PropTypes.object,
    logActions: PropTypes.object,
    messengerActions: PropTypes.object
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        header: state.header,
        footer: state.footer,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(authActions, dispatch),
        headerActions: bindActionCreators(headerActions, dispatch),
        footerActions: bindActionCreators(footerActions, dispatch),
        logActions: bindActionCreators(logActions, dispatch),
        messengerActions: bindActionCreators(messengerActions, dispatch)
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Auth))
