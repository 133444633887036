import initialState from './initialState';
import {
    FILE_LIST_REQUEST,
    FILE_LIST_RESPONSE_SUCCESS,
    FILE_LIST_RESPONSE_ERRORS,
    FILE_LIST_DELETE_REQUEST,
    FILE_LIST_DELETE_SUCCESS,
    FILE_LIST_DELETE_ERRORS,
    FILE_LIST_SET_PAGE,
    FILE_LIST_SET_START_DATE,
    FILE_LIST_SET_END_DATE,
    FILE_LIST_RESET
} from '../actions/actionTypes';

export default function fileList(state = initialState.fileList, action) {
    switch (action.type) {
        case FILE_LIST_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    loading: true,
                    success: false,
                    error: false,
                    errors: {}
                }
            )
        case FILE_LIST_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    success: true,
                    error: false,
                    errors: {},
                    tableData: action.tableData,
                    totalRows: action.totalRows,
                    potId: action.potId,
                    minDate: action.minDate,
                    maxDate: action.maxDate
                }
            )
        case FILE_LIST_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    success: false,
                    error: true,
                    errors: action.errors,
                    tableData: [],
                    totalRows: 0,
                }
            )
        case FILE_LIST_SET_PAGE:
            return Object.assign(
                {},
                state,
                {
                    currentPage: action.page
                }
            )
        case FILE_LIST_SET_START_DATE:
            return Object.assign(
                {},
                state,
                {
                    start: action.start
                }
            )
        case FILE_LIST_SET_END_DATE:
            return Object.assign(
                {},
                state,
                {
                    end: action.end
                }
            )
        case FILE_LIST_RESET:
            return initialState.fileList
        case FILE_LIST_DELETE_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    loading: true,
                    success: false,
                    error: false,
                    errors: {}
                }
            )
        case FILE_LIST_DELETE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    success: true,
                    error: false,
                    errors: {}
                }
            )
        case FILE_LIST_DELETE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    success: false,
                    error: true,
                    errors: action.errors,
                    tableData: [],
                    totalRows: 0,
                }
            )
        default:
            return state
    }
}
