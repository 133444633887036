import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Button,
    Modal,
    Form,
    Input,
    Dropdown
    } from 'semantic-ui-react'

import moment from 'moment'
import { LOBSTER_POT_4 } from '../../config'

class AddScheduleV4Modal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            actions: [
                {json_class: 'SchedulerActionShoot'},
                {json_class: 'SchedulerActionUpload'},
            ],
            start: 0,
            stop: 86399,
            interval: 300,
            json_class: 'Period'
        }
        this.intervalFormatted = 5
        this.intervalUnits = 'minutes'

        if(this.props.potVersion === LOBSTER_POT_4) {
            this.actionOptions = [
                {
                    key: 'SchedulerActionShoot',
                    text: 'Shoot',
                    value: 'SchedulerActionShoot',
                },
                {
                    key: 'SchedulerActionUpload',
                    text: 'Upload',
                    value: 'SchedulerActionUpload',
                },
                {
                    key: 'SchedulerActionSleep',
                    text: 'Sleep',
                    value: 'SchedulerActionSleep',
                },
                {
                    key: 'SchedulerActionRapidShoot',
                    text: 'Rapid Shoot',
                    value: 'SchedulerActionRapidShoot',
                },
                {
                    key: 'SchedulerActionTriggerFan',
                    text: 'Fan',
                    value: 'SchedulerActionTriggerFan',
                },
                {
                    key: 'SchedulerActionTriggerHeater',
                    text: 'Heater',
                    value: 'SchedulerActionTriggerHeater',
                }
            ]
        } else {
            this.actionOptions = [
                {
                    key: 'SchedulerActionHalt',
                    text: 'Halt',
                    value: 'SchedulerActionHalt',
                },
                {
                    key: 'SchedulerActionShoot',
                    text: 'Shoot',
                    value: 'SchedulerActionShoot',
                },
                {
                    key: 'SchedulerActionShootQueue',
                    text: 'Shoot & Queue',
                    value: 'SchedulerActionShootQueue',
                },
                {
                    key: 'SchedulerActionUpload',
                    text: 'Upload',
                    value: 'SchedulerActionUpload',
                }
            ]
        }
    }

    updateStart = ({value}) => {
        const seconds = moment(value, 'HH:mm').diff(moment().startOf('day'), 'seconds');
        this.setState({
            start: seconds,
            id: `${this.props.day}-${seconds}`
        })
    }

    updateStop = ({value}) => {
        let seconds
        if (value === '00:00') {
            seconds = 86399 // 23:59:59
        } else {
            seconds = moment(value, 'HH:mm').diff(moment().startOf('day'),
                                                  'seconds')+59
        }
        this.setState({stop: seconds})
    }

    updateIntervalValue = (event, {value}) => {
        this.intervalFormatted = value
        this.updateInterval()
    }

    updateIntervalUnits = (event, {value}) => {
        this.intervalUnits = value
        this.updateInterval()
    }

    updateInterval = () => {
        let multiplier
        switch (this.intervalUnits) {
            case 'seconds':
                multiplier = 1
                break
            case 'hours':
                multiplier = 3600
                break
            case 'minutes':
            default:
                multiplier = 60
        }
        this.setState({interval: this.intervalFormatted * multiplier})
    }

    moveActionUp = (id) => {
        if (id === 0) {
            return
        }
        let actions = this.state.actions.slice(0)
        const toMove = actions[id]
        actions.splice(id, 1);
        actions.splice(id - 1, 0, toMove);
        this.setState({
            actions: actions
        })
    }

    moveActionDown = (id) => {
        if (id === this.state.actions.length - 1) {
            return
        }
        let actions = this.state.actions.slice(0)
        const toMove = actions[id]
        actions.splice(id, 1);
        actions.splice(id + 1, 0, toMove);
        this.setState({
            actions: actions
        })
    }

    deleteAction = (id) => {
        let actions = this.state.actions.slice(0)
        actions.splice(id, 1)
        this.setState({
            actions: actions
        })
    }

    addAction = () => {
        const newAction = {
            json_class: 'SchedulerActionShoot'
        }
        this.setState({
            actions: [
                ...this.state.actions,
                newAction
            ]
        })
    }

    updateAction = (id, {value}) => {
        let actions = this.state.actions
        actions[id].json_class = value
        this.setState({actions: actions})
    }

    fromSeconds = (seconds) => {
        return moment().startOf('day').seconds(seconds).format('HH:mm');
    }

    toSeconds = (time) => {
        return moment(time, 'HH:mm').diff(moment().startOf('day'), 'seconds');
    }

    render() {
        const intervals = [
            { key: 'seconds', text: 'seconds', value: 'seconds' },
            { key: 'minutes', text: 'minutes', value: 'minutes' },
            { key: 'hours', text: 'hours', value: 'hours' },
        ]
        return (
            <Modal size='small' open onClose={this.props.onClose} closeIcon>
                <Modal.Header>Add Shoot &amp; Upload Period</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Group>
                             <Form.Input
                                label='Start Time'
                                type='time'
                                defaultValue={this.fromSeconds(this.state.start)}
                                max={this.fromSeconds(this.state.stop)}
                                onChange={(event, data)=>{this.updateStart(data)}}
                            />
                        </Form.Group>
                        <Form.Group>
                             <Form.Input
                                label='Stop Time'
                                type='time'
                                defaultValue={this.fromSeconds(this.state.stop)}
                                min={this.fromSeconds(this.state.start)}
                                onChange={(event, data)=>{this.updateStop(data)}}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Field>
                                <label>Interval</label>
                                <Input
                                    defaultValue={this.intervalFormatted}
                                    label={
                                        <Dropdown
                                            defaultValue={this.intervalUnits}
                                            options={intervals}
                                            onChange={this.updateIntervalUnits}
                                        />
                                    }
                                    labelPosition='right'
                                    size='small'
                                    onChange={this.updateIntervalValue}
                                />
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={() => {this.props.onAdd(this.state)}}
                        positive
                        primary
                    >Add</Button>
                    <Button onClick={this.props.onClose}>Cancel</Button>
                </Modal.Actions>
            </Modal>
        )
    }
}

AddScheduleV4Modal.propTypes = {
    onAdd: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
}

export default AddScheduleV4Modal
