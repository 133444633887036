import React, { Component } from 'react'
import {
    Button,
    Modal,
    Message,
    Form,
    Select
    } from 'semantic-ui-react'
import { getErrorMessage } from './../../util/errors'
import flatten from './../../util/flattenObject'

class CreateClientModal extends Component {

    constructor() {
        super()
        this.state = this.getInitialConfig()
    }

    getInitialConfig() {
        return {
            name: "",
            role: "Service Client",
            status: "Active",
            mfaRequirement: 'Unavailable'
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.success === true && prevProps.success === false) {
            this.onClose()
        }
    }

    onNameChange = (event, {value}) => {
        this.setState({name: value})
    }

    onRoleChange = (event, {value}) => {
        this.setState({role: value})
    }

    onStatusChange = (event, {value}) => {
        this.setState({status: value})
    }

    onClose = () => {
        this.setState(this.getInitialConfig(), () => {
            this.props.onClose()
        })
    }

    getHeader = (error, success) => {
        if (error) {
            return <p>Error</p>
        } else if (success) {
            return <p>Success!</p>
        } else {
            return ""
        }
    }

    getContent = (error, success, loading, errors) => {

        // We might have a server error, or just regular errors
        if (error) {
            return this.renderForm()
        } else if (success) {
            return <p>Successfully created client</p>
        } else if (loading) {
            return this.renderForm()
        } else {
            // Must be initial loading...
            return this.renderForm()
        }
    }

    renderForm = () => {
        return (
            <Form size='large'>
                    <Form.Input
                        fluid
                        label='Name'
                        placeholder='Client Name'
                        onChange={this.onNameChange}
                        error={this.props.errors.name ? true: false}
                    />
                    <Message
                        error={true}
                        visible={this.props.errors.name !== undefined}
                        header={''}
                        content={getErrorMessage(this.props.errors, 'name')}
                    />

                    <Form.Field
                        label='Status'
                        control={Select}
                        options={this.props.statuses}
                        placeholder='Status'
                        onChange={this.onStatusChange}
                        error={this.props.errors.status ? true : false}
                        defaultValue='Active'

                    />
                    <Message
                        error={true}
                        visible={this.props.errors.status !== undefined}
                        header={''}
                        content={getErrorMessage(this.props.errors, 'status')}
                    />

                    <Form.Field
                        label='Relationship'
                        control={Select}
                        options={this.props.roles}
                        placeholder='Role'
                        onChange={this.onRoleChange}
                        error={this.props.errors.role ? true : false}
                        defaultValue='Service Client'
                    />
                    <Message
                        error={true}
                        visible={this.props.errors.role !== undefined}
                        header={''}
                        content={getErrorMessage(this.props.errors, 'role')}
                    />
            </Form>)
    }

    getButtons = (error, success) => {
        if (success) {
            return null
        } else {
            return <div>
                <Button onClick={() => { this.props.onClick(flatten(this.state))}} loading={this.props.loading} positive icon='checkmark' labelPosition='right' content='Create' />
            </div>
        }
    }

    render = () => {
        return (
            <Modal size={"small"} open={this.props.open} onClose={this.onClose} closeIcon>
                <Modal.Header>Create a client</Modal.Header>
                <Modal.Content>

                    {this.getContent(this.props.error, this.props.success, this.props.loading, this.props.errors)}

                    <div className='modalButtons'>
                        {this.getButtons(this.props.error, this.props.success)}
                    </div>
                </Modal.Content>
            </Modal>
        )
    }

}

export default CreateClientModal
