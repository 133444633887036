import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Segment, Label, Icon, Popup } from 'semantic-ui-react'

import EditPeriodButton from '../buttons/EditPeriod'

class ScheduleSegmentV4 extends Component {
    constructor(props) {
        super(props)
        this.state = {controlsVisible: false}
        this.backgroundColor = undefined
    }

    componentDidMount() {
        window.addEventListener('touchstart', this.processTouch)
    }

    componentWillUnmount() {
        window.removeEventListener('touchstart', this.processTouch)
    }

    processTouch = (event) => {
        // Only worry about the first touch for now
        if (event.touches[0].target === this.segment) {
            this.showControls()
        } else {
            this.hideControls()
        }
    }

    showControls = () => {
        if (!this.props.isSleep) {
            this.setState({controlsVisible: true})
        }
    }

    hideControls = () => {
        this.setState({controlsVisible: false})
    }

    intervalToTime = (interval) => {
        if (interval < 300) {
            if (interval % 60 === 0) {
                return `${interval / 60}m`
            } else {
                return `${interval}s`
            }
        }
        if (interval < 3600) {
            return `${interval / 60}m`
        } else {
            return `${interval / 3600}h`
        }
    }

    getActionIcon = (action) => {
        switch(action.json_class) {
            case 'SchedulerActionHalt':
                return (
                    <Icon.Group style={{lineHeight: 1.5}} key='SAH'>

                            <Icon
                                name='power'
                                inverted
                                color='red'
                                circular
                            />

                    </Icon.Group>
                )
            case 'SchedulerActionShoot':
                return (
                    <Icon.Group style={{lineHeight: 1.5}} key='SAS'>

                            <Icon
                                name='photo'
                                inverted
                                color='black'
                                circular
                            />

                    </Icon.Group>
                )
            case 'SchedulerActionSleep':
                return (
                    <Icon.Group style={{lineHeight: 1.5}} key='SASP'>

                            <Icon
                                name='bed'
                                inverted
                                color='black'
                                circular
                            />

                    </Icon.Group>
                )
            case 'SchedulerActionShootQueue':
                return (
                        <Icon.Group style={{lineHeight: 1.5}} key='SASQ'>
                            <Icon circular inverted color='black' name='list ul' />
                            <Icon corner inverted name='photo'
                                style={{
                                    fontSize: '0.9em',
                                    right: '0.2em'
                                }}
                            />
                        </Icon.Group>

                )
            case 'SchedulerActionUpload':
                return (
                    <Icon.Group style={{lineHeight: 1.5}} key='SAU'>

                            <Icon
                                name='upload'
                                inverted
                                color={'black'}
                                circular
                            />

                    </Icon.Group>
                )
            case 'SchedulerActionRapidShoot':
                return (
                    <Icon.Group style={{lineHeight: 1.5}} key='SRS'>

                            <Icon
                                name='fast forward'
                                inverted
                                color='black'
                                circular
                            />

                    </Icon.Group>
                )
            case 'SchedulerActionTriggerFan':
                return (
                    <Icon.Group style={{lineHeight: 1.5}} key='SAAF'>

                            <Icon
                                name='sync'
                                inverted
                                color='black'
                                circular
                            />

                    </Icon.Group>
                )
            case 'SchedulerActionTriggerHeater':
                return (
                    <Icon.Group style={{lineHeight: 1.5}} key='SAAH'>

                            <Icon
                                name='fire'
                                inverted
                                color='black'
                                circular
                            />

                    </Icon.Group>
                )
            default:
             return null
        }
    }

    generateActionDetails = (period, small, duration) => {
        let style
        if (small) {
            style = {
                width: '100%',
                textAlign: 'center',
                fontSize: '0.6em'
            }
        } else {
            style = {
                position: 'absolute',
                top: '35%',
                width: '100%',
                textAlign: 'center'
            }
        }

        style = {
            width: '100%',
            textAlign: 'center',
            position: 'absolute',
            fontSize: duration < 3 ? '0.6em' : 'inherit',
            top: `${duration / 2}em`
        }

        return (
            <Label.Group circular style={style}>
                <Label
                    className={small ? 'smallLabel' : null}
                >
                    {this.intervalToTime(period.interval)}
                </Label>
                {period.actions.map((action) => {
                    return this.getActionIcon(action)
                })}
            </Label.Group>
        )
    }

    render() {
        const period = this.props.period
        const column = this.props.column

        const duration = (period.stop - period.start)/3600

        // Work out colour by action combination
        let actionNames = []
        let backgroundColor = '#ff851b'
        if (period.actions) {
            period.actions.forEach((action) => {
                actionNames.push(action.name)
            })
        }
       
        if (actionNames.length === 1) {
            if (actionNames[0] === 'Shoot') {
                backgroundColor = '#2185D0'
            } else if (actionNames[1] === 'Halt') {
                backgroundColor = '#DB2828'
            }
        } else if (actionNames.length === 2) {
            if (actionNames[0] === 'Shoot & Queue'
                && actionNames[1] === 'Upload'
            ) {
                backgroundColor = '#31B340'
            }
         }

         const controlsLabel = (this.props.isSleep)? "Camera Sleep" : "Edit period"
         const cursor =  (this.props.isSleep)? "initial" : "pointer"

        const smallControls = (
            <div style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: '0',
                left: '0',
                textShadow: '1px 1px #333',
                opacity: this.state.controlsVisible ? 1 : 0,
                backgroundColor: backgroundColor,
                transition: '0.3s'
            }}>
                <EditPeriodButton
                    dayId={this.props.dayId}
                    period={period.periodIndex}
                    small={true}
                    onClick={() => {
                        if (!this.props.isSleep) {
                            this.props.actions.openEditPeriodModal(this.props.dayId, period.periodIndex)
                        }
                    }}
                />
                {/* <IncreasePriorityButton
                    dayId={this.props.dayId}
                    period={period.periodIndex}
                    small={true}
                    disabled={column === 0}
                    onClick={this.props.actions.increasePeriodPriority}
                />
                <DecreasePriorityButton
                    dayId={this.props.dayId}
                    period={period.periodIndex}
                    small={true}
                    disabled={column === this.props.totalColumns - 1}
                    onClick={this.props.actions.decreasePeriodPriority}
                /> */}
            </div>
        )

        const largeControls = (
            <Popup
                trigger={
                    <div
                        style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            top: '0',
                            left: '0',
                            textShadow: '1px 1px #333',
                            opacity: this.state.controlsVisible ? 1 : 0,
                            backgroundColor: backgroundColor,
                            transition: '0.3s',
                            cursor: cursor
                        }}
                        onClick={() => {
                            if (!this.props.isSleep) {
                                this.props.actions.openEditPeriodModal(this.props.dayId, period.periodIndex)
                            }
                        }}
                    >
                        <Label.Group>
                            <Icon
                                name='configure'
                                size={this.props.small ? null :'large'}
                                style={{
                                    color: '#ededed'
                                }}
                                link
                                onClick={(event) => {
                                    event.stopPropagation()
                                    if (!this.props.isSleep) {
                                        this.props.actions.openEditPeriodModal(
                                            this.props.dayId, period.periodIndex
                                        )
                                    }
                                }}
                            />
                        
                        </Label.Group>
                        {/* <div style={{clear: 'both'}} />
                        <Label.Group
                            style={{
                                position: 'absolute',
                                bottom: '0.5em',
                                width: '100%',
                            }}
                        >
                        <IncreasePriorityButton
                            dayId={this.props.dayId}
                            period={period.periodIndex}
                            disabled={column === 0}
                            onClick={this.props.actions.increasePeriodPriority}
                        />
                        <DecreasePriorityButton
                            dayId={this.props.dayId}
                            period={period.periodIndex}
                            disabled={column === this.props.totalColumns - 1}
                            onClick={this.props.actions.decreasePeriodPriority}
                        />
                        </Label.Group>
                        </div> */}
                    </div>}
                content={controlsLabel}
                position={'top center'}
                basic
            />
        )

        // const controls = (duration < 2.8) ? smallControls : largeControls
        const controls =  largeControls

        const segmentStyle = () => {
            if (this.props.isSleep) {
                return {
                    position: 'absolute',
                    top: `40px`,
                    left: `${(period.start / 3600) * 4 + 2}%`,
                    // this converts from seconds to hours, then multiplies by 4 (which is the %age width of the cols)
                    // then adds 2 for the lines being centered, so 2% offset
                    height: '2em',
                    width: `${((period.stop - period.start) / 3600 )* 4}%`,
                    padding: duration < 2 ? '0px' : '2px',
                    backgroundColor: '#a9a9a9',
                    zIndex: 0
                }
            } else {
                return {
                    position: 'absolute',
                    top: `40px`,
                    left: `${(period.start / 3600) * 4 + 2}%`,
                    // this converts from seconds to hours, then multiplies by 4 (which is the %age width of the cols)
                    // then adds 2 for the lines being centered, so 2% offset
                    height: '2em',
                    width: `${((period.stop - period.start) / 3600 )* 4}%`,
                    padding: duration < 2 ? '0px' : '2px',
                    backgroundColor: backgroundColor,
                    zIndex: 1
                }
            }
        }

        const actionsLength = (period.actions)? period.actions.length : 'sleep'

        let classNames = 'schedule__segment';
        (this.props.isSleep) ? classNames += ` 'schedule__segment--sleep` : null

        return (
            <div className={classNames} ref={(segment) => this.segment = segment}>
                <Segment
                    key={`${this.props.dayName}_${column}_${period.start}_${actionsLength}`}
                    textAlign='center'
                    className='middle'
                    style={segmentStyle()}
                    onMouseOver={this.showControls}
                    onMouseLeave={this.hideControls}
                >
                    {/* {this.generateActionDetails(period, duration < 2, duration)} */}
                    {!this.props.isSleep && controls}
                </Segment>
            </div>
        )

    }
}

ScheduleSegmentV4.propTypes = {
    period: PropTypes.object,
    column: PropTypes.number.isRequired,
    totalColumns: PropTypes.number.isRequired,
    dayId: PropTypes.number,
    actions: PropTypes.object.isRequired
}

export default ScheduleSegmentV4
