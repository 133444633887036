import * as types from './actionTypes'
import { addMessage } from './messengerActions'
import { log } from './logActions'
import { API_BASE_URL, VIEWER_URL }  from '../config'
import { notAuthorisedResponse } from './authActions'
import { fetchWithToken } from '../util/accessToken'

export function userListResponse(payload, currentPage, filtered, sortColumn, sortAscending, searchTerm, findIn) {
    if (payload.totalItems) {
        return {
            type: types.USER_LIST_RESPONSE,
            currentPage: currentPage,
            totalRows: payload.totalItems,
            tableData: payload.users,
            filtered: filtered,
            sortColumn: sortColumn,
            sortAscending: sortAscending,
            searchTerm: searchTerm,
            searchField: findIn
        }
    } else {
        return {
            type: types.USER_LIST_RESPONSE,
            totalRows: 0,
            tableData: [],
            filtered: filtered
        }
    }
}

export function userListRequest(pageSize, currentPage, sortColumn, sortAscending, searchTerm, clientId, findIn) {
    return (dispatch, getState) => {
        // Set loading state for fidget spinners
        dispatch( { type: types.USER_LIST_REQUEST, users: { loading: true } })

        // Build query string from paramters
        let pagination = `pageSize=${pageSize}&currentPage=${currentPage}`
        let filtering = (searchTerm === undefined || searchTerm == null)  ? "" : `&filter=${searchTerm}`
        let sorting = sortColumn === undefined ? "" : `&orderBy=${sortColumn}&ascending=${sortAscending}`
        let find = findIn === undefined ? "" : `&findIn=${findIn}`

        // Switch URL depending on if we're getting users from all clients or
        // just one client
        let url = null
        if (clientId === undefined) {
            // all users
            url = `${API_BASE_URL}/api/users?${pagination}${sorting}${filtering}${find}`
        } else {
            url = `${API_BASE_URL}/api/clients/${clientId}/users?${pagination}${sorting}${filtering}${find}`
        }

        // Perfrom request
        return fetchWithToken(
            url,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (response.status === 401) {
                // Remember, we're bouncing out 401s
                dispatch(notAuthorisedResponse())
                throw new Error('Not Authorised')
            }
            return response.json()
        })
        .then((payload) => {
            let filtered = searchTerm === undefined ? false : true
            dispatch(userListResponse(payload, currentPage, filtered, sortColumn, sortAscending, searchTerm, findIn))
        })
        .catch((response) => {
            let filtered = searchTerm === null ? false : true
            dispatch(userListErrors(filtered))
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    };
}

export function userListErrors(filtered) {
    return { type: types.USER_LIST_ERRORS, filtered: filtered}
}

export function userListReset() {
    return dispatch => {
        return new Promise(function(resolve, reject) {
            dispatch({ type: types.USER_LIST_RESET })
            resolve()
        })
    }
}

export function userListFilterBy(searchTerm, searchField) {
    return {
        type: types.USER_LIST_FILTER_BY,
        searchTerm: searchTerm,
        searchField: searchField,
    }
}

export function userListSortBy(sortColumn, ascending = "true") {
    return {
        type: types.USER_LIST_SORT_BY,
        sortColumn: sortColumn,
        sortAscending: ascending
    }
}

export function authAsUser(clientId, userId) {
    return (dispatch, getState) => {
        // Spawn new window with viewer URL waiting for credentials
        const viewer = window.open(`${VIEWER_URL}/auth-as`, '_blank')

        // Make API call to set projects and cameras is Redis
        return fetchWithToken(
            `${API_BASE_URL}/api/clients/${clientId}/users/${userId}/auth-as-user`,
            {
                method: 'GET',
                mode: 'cors'
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                viewer.close()
                // might be forbidden!
                if (response.status === 401) {
                    // Remember, we're bouncing out 401s
                    dispatch(notAuthorisedResponse())
                } else {
                    dispatch(
                        addMessage(
                            "negative",
                            "Error",
                            "Unable to open the viewer as the requested user"
                        )
                    )
                    dispatch(log("error", response))
                }
            } else {
                response.json()
                .then(data => {
                    // Send credentials via postMessage()
                    setTimeout(() => {
                        const state = getState()
                        const auth = Object.assign(
                            {},
                            state.auth,
                            data
                        )
                        viewer.postMessage(auth, VIEWER_URL)
                    }, 500)
                    // Second send, in case things are taking a while to load
                    setTimeout(() => {
                        const state = getState()
                        const auth = Object.assign(
                            {},
                            state.auth,
                            data
                        )
                        viewer.postMessage(auth, VIEWER_URL)
                    }, 10000)
                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function userListBulkEdit(usersToEdit) {
    return { type: types.USER_LIST_BULK_EDIT, usersToEdit: usersToEdit }
}

export function userListBulkEditRemove(userToRemove) {
    return { type: types.USER_LIST_BULK_EDIT_REMOVE, userToRemove: userToRemove }
}

export function userListBulkEditReset() {
    return { type: types.USER_LIST_BULK_EDIT_RESET }
}

export function userListBulkEditSelect(selectedUserClientId) {
    return {
        type: types.USER_LIST_BULK_EDIT_SELECT,
        selectedUserClientId: selectedUserClientId
    }
}