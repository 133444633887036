import initialState from './initialState';
import {
    EDIT_CLIENT_REQUEST,
    ADMIN_CLIENT_ROLES_REQUEST,
    ADMIN_CLIENT_ROLES_RESPONSE,
    ADMIN_CLIENT_STATUSES_REQUEST,
    ADMIN_CLIENT_STATUSES_RESPONSE,
    EDIT_CLIENT_RESET_ERRORS,
    EDIT_CLIENT_RESET_SUCCESS,
    EDIT_CLIENT_RESPONSE_ERRORS,
    EDIT_CLIENT_RESPONSE_SUCCESS,
    EDIT_CLIENT_DETAILS_REQUEST,
    EDIT_CLIENT_DETAILS_RESPONSE,
    EDIT_CLIENT_DETAILS_REQUEST_ERRORS,
    DELETE_CLIENT_REQUEST,
    DELETE_CLIENT_RESPONSE_ERRORS,
    DELETE_CLIENT_RESPONSE_SUCCESS
} from '../actions/actionTypes';

export default function editClient(state = initialState.editClient, action) {

    switch (action.type) {
        case EDIT_CLIENT_REQUEST:
            // return Object.assign({}, state, { loading: true })
            return Object.assign(
                {},
                state,
                {
                    success: false,
                    error: false,
                    errors:  {},
                    loading: true,
                }
            )
        case EDIT_CLIENT_RESPONSE_ERRORS:
            // return Object.assign(
            //     {},
            //     state,
            //     { errors : action.editClient.errors },
            //     { loading: false })
            return Object.assign(
                {},
                state,
                {
                    success: false,
                    error: true,
                    errors:  action.editClient.errors,
                    loading: false,
                }
            )
        case EDIT_CLIENT_RESPONSE_SUCCESS:
            // return Object.assign(
            //     {},
            //     state,
            //     action.editClient,
            //     {
            //         loading: false,
            //         success: true,
            //         error: false,
            //         errors: {}
            //     }
            // )

            return Object.assign(
                {},
                state,
                {
                    success: true,
                    error: false,
                    errors:  {},
                    loading: false,
                }
            )
        case EDIT_CLIENT_DETAILS_REQUEST:
            return Object.assign({}, state, { loading: true })
        case EDIT_CLIENT_DETAILS_RESPONSE:
            return Object.assign(
                {},
                state,
                action.editClient,
                {
                    loading: false
                },
                {
                    logo: (action.editClient && action.editClient.logo) ? action.editClient.logo : null
                }
                )
        case EDIT_CLIENT_DETAILS_REQUEST_ERRORS:
            return Object.assign({}, state, action.editClient, { errors: [true]})
        case ADMIN_CLIENT_ROLES_REQUEST:
            return Object.assign({}, state, { roles: [] } )
            // return state
        case ADMIN_CLIENT_ROLES_RESPONSE:
            return Object.assign({}, state, { roles: action.editClient } )
        case ADMIN_CLIENT_STATUSES_REQUEST:
            return Object.assign({}, state, { statuses: [] } )
            // return state
        case ADMIN_CLIENT_STATUSES_RESPONSE:
            return Object.assign({}, state, { statuses: action.editClient } )
        case EDIT_CLIENT_RESET_ERRORS:
            return Object.assign({}, initialState.editClient )
        case EDIT_CLIENT_RESET_SUCCESS:
            return Object.assign({}, state, { success: false })
        case DELETE_CLIENT_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    deleted: [
                        ...state.deleted,
                        {
                            clients_id: action.clients_id,
                            status: "Pending"
                        }
                    ],
                }
            )
        case DELETE_CLIENT_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    deleted: state.deleted.map((row) => {
                        if (row.clients_id === action.clients_id) {
                            return {
                                clients_id: row.clients_id,
                                status: "Failed"
                            }
                        } else {
                            return row
                        }
                    })

                },
                {
                    loading: false,
                    success: false,
                    error: true,
                    errors: action.errors
                }
            )
        case DELETE_CLIENT_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    deleted: state.deleted.map((row) => {
                        if (row.clients_id === action.clients_id) {
                            return {
                                clients_id: action.clients_id,
                                status: "Deleted"
                            }
                        } else {
                            return row
                        }
                    })

                },
                {
                    loading: false,
                    success: true,
                    error: false,
                    errors: {}
                }
            )

        default:
            return state
    }
}
