import React, { Component } from 'react'
import { Button, Modal } from 'semantic-ui-react'
import { CSVLink } from 'react-csv';
import UsersContainer from '../../containers/UsersContainer'

class ProjectAccessModal extends Component {
    constructor(props) {
        super(props)
        this.rowsPerPage = props.rowsPerPage || 10
        this.currentPage = props.currentPage || 1
        this.state = {
            usersToEdit: [],
        }
    }

    onOpen = (event, data) => {
        //this.props.projectsActions.usersWithProjectAccess(this.props.projectId, this.rowsPerPage, this.currentPage)
    }

    onClose = () => {
        this.props.onClose()
    }

    flattenObject = (ob) => {
        let toReturn = {};

        for (let i in ob) {
            if (!ob.hasOwnProperty(i)) continue;

            if ((typeof ob[i]) == 'object' && ob[i] !== null) {
                let flatObject = this.flattenObject(ob[i]);
                for (let x in flatObject) {
                    if (!flatObject.hasOwnProperty(x)) continue;

                    toReturn[i + '.' + x] = flatObject[x];
                }
            } else {
                toReturn[i] = ob[i];
            }
        }
        return toReturn;
    }

    render() {
        // setting headers and data for csv export
        const headers = [
            { label: "Username", key: "username" },
            { label: "Name", key: "name" },
            { label: "Status", key: "status"},
            { label: "Permitted To Save", key: "settings.permitted_to_save"},
            { label: "Permitted To Zoom", key: "settings.permitted_to_zoom"},
            { label: "Permitted To View Timelapse", key: "settings.permitted_to_view_timelapse"},
            { label: "Video Editor", key: "video_editor"},
            { label: "Delay", key: "delay"},
            { label: "Limit History", key: "limit_history"},
            { label: "Last Login", key: "lastLogin"},
            { label: "Permitted To View Calendar", key: "settings.permitted_to_view_calendar"},
            { label: "Show Navigation Permission", key: "settings.show_navigation_permission"},
            { label: "Show Thumbnails Permission", key: "settings.show_thumbnails_permission"},
            { label: "Hide Virtual Cameras", key: "settings.hide_virtual_cameras"},
            { label: "Loginless", key: "loginless"},
            { label: "First Name", key: "first_name"},
            { label: "Last Name", key: "last_name"},
            { label: "Client", key: "client"},
            { label: "Created", key: "created"},
            { label: "Last Updated", key: "updated" },
            { label: "Lockdown", key: "lockdown" },
            { label: "Processed", key: "processed" },
            { label: "Api", key: "api" },
            { label: "Comment", key: "comment" },
            { label: "Job Title", key: "job_title" },
            { label: "Email", key: "email" },
            { label: "Role", key: "role" }
        ];

        let data = []

        if (this.props.users !== undefined) {
            if (this.props.users.exportData) {
                let that = this
                this.props.users.exportData.forEach(function(row, index){
                    const user = that.flattenObject(row)
                    data.push(user);
                })
            }
        }

        const CSV = <CSVLink
            data={data}
            filename={"user-access.csv"}
            headers={headers}
            className='ui primary button'
            > Download User Access CSV</CSVLink>
        

        return (
            <Modal
                size={"fullscreen"}
                open={this.props.open}
                onOpen={this.onOpen}
                onMount={this.onOpen}
                onClose={this.onClose}
                closeIcon
            >
                <Modal.Header>Project Access</Modal.Header>
                <Modal.Content style={{padding: 0}}>
                    {/* <div style={{textAlign: 'right', margin: '0 0 1.4285em'}}>
                        
                    </div> */}
                    
                    <UsersContainer {...this.props} csv={CSV}/>
                    <div className='modalButtons'>
                        <Button onClick={this.props.onClose}>Close</Button>
                    </div>
                </Modal.Content>
            </Modal>
        )
    }
}

export default ProjectAccessModal
