import React, { Component } from 'react'
import {
    Button,
    Modal,
    Message,
    Form,
    Grid
} from 'semantic-ui-react'
import { getErrorMessage } from './../../util/errors'
import PropTypes from 'prop-types'

class CreateCameraModal extends Component {

    constructor(props) {
        super(props)
        this.state = this.getInitialState()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.success === true && prevProps.success === false) {
            this.onClose()
        }
    }

    getHeader = (error, success) => {
        if (error) {
            return <h3 id='successStatus'>Error</h3>
        } else if (success) {
            return <h3 id='successStatus'>Success!</h3>
        } else {
            return ""
        }
    }

    getContent = (error, success, loading, errors) => {

        // We might have a server error, or just regular errors
        if (error) {
            // return this.renderForm()
            // getErrorMessage()
            return null;

        } else if (success) {
            return <p>Successfully created camera</p>
        } else if (loading) {
            return <p>Processing...</p>
        } else {
            return null;
        }
    }

    getInitialState = () => {
        return {
            pot_id: null,
            status:'Ready',
            type: 'Virtual',
            latitude: null,
            longitude: null,
            pot_version: 2,
            custom_name: null,
            video_storage_location: 'default',
        }
    }

    onClose = () => {
        this.setState(this.getInitialState(), () => {
            this.props.onClose()
        })
    }

    onClick = () => {
        this.props.onClick(this.state)
    }

    onPotIdChange = (event, {value}) => {
        this.setState({pot_id: value})
    }

    onCustomNameChange = (event, {value}) => {
        this.setState({custom_name: value})
    }

    onStatusChange = (event, {value}) => {
        this.setState({status: value})
    }

    onTypeChange = (event, {value}) => {

        if (value === 'Virtual') {
            this.setState({type: value, pot_version: null})
        } else {
            this.setState({type: value})
        }

    }

    onLatitudeChange = (event, {value}) => {
        this.setState({latitude: value})
    }

    onLongitudeChange = (event, {value}) => {
        this.setState({longitude: value})
    }

    onPotVersionChange = (event, {value}) => {
        this.setState({pot_version: value})
    }

    onVideoStorageLocationChange = (event, {value}) => {
        this.setState({video_storage_location: value})
    }

    isReady = () => {
        return this.props.statuses.length > 0 &&  this.props.types.length > 0
    }

    getPreloader = () => {
        if (!this.isReady()) {
            return (
                <div>
                    <div className='animated-blank-line'></div>
                    <div className='animated-blank-block-300'></div>
                    <div className='animated-blank-line'></div>
                </div>
            )
        }
        return null
    }

    getForm = () => {

        const statuses = this.props.statuses.map((status) => {
            return {
                text: status,
                value: status
            }
        })
        const types = this.props.types.map((type) => {
            return {
                text: type,
                value: type
            }
        })
        const potVersions = [{ text: 1, value: 1},{ text: 2, value: 2},{ text: 3, value: 3},{ text: 4, value: 4}]

        let locationOptions = [
            {key: 'vsl_1', value: 'default', text: 'Lobster Vision'},
            {key: 'vsl_2', value: 'ca', text: 'Lobster Vision (CA)'},
        ]

        return (!this.props.success) ?
            <Form size='large'>

                <Form.Field>
                    <label>Pot number</label>
                    <Form.Input
                        id='potId'
                        fluid
                        placeholder='Pot number'
                        onChange={this.onPotIdChange}
                        error={false}
                        defaultValue={this.state.pot_id}
                    />
                </Form.Field>
                <Message
                    error={true}
                    visible={this.props.errors.pot_id !== undefined}
                    header={''}
                    content={getErrorMessage(this.props.errors, 'pot_id')}
                />
                <Form.Field>
                    <label>Status</label>
                    <Form.Select
                        id='status'
                        fluid
                        placeholder='Status'
                        onChange={this.onStatusChange}
                        error={false}
                        options={statuses}
                        defaultValue={this.state.status}
                    />
                </Form.Field>
                <Message
                    error={true}
                    visible={this.props.errors.status !== undefined}
                    header={''}
                    content={getErrorMessage(this.props.errors, 'status')}
                />

                <Form.Field>
                    <label>Type</label>
                    <Form.Select
                        id='type'
                        fluid
                        placeholder='Type'
                        onChange={this.onTypeChange}
                        error={false}
                        options={types}
                        defaultValue={this.state.type}
                    />
                </Form.Field>
                <Message
                    error={true}
                    visible={this.props.errors.type !== undefined}
                    header={this.props.errors.type}
                    content={getErrorMessage(this.props.errors, 'type')}
                />

                <Form.Field disabled={this.state.type === 'Virtual'}>
                    <label>Pot Version</label>
                    <Form.Select
                        id='potVersion'
                        fluid
                        placeholder='Pot Version'
                        onChange={this.onPotVersionChange}
                        error={false}
                        options={potVersions}
                        defaultValue={this.state.pot_version}
                    />
                </Form.Field>
                <Message
                    error={true}
                    visible={this.props.errors.type !== undefined}
                    header={this.props.errors.type}
                    content={getErrorMessage(this.props.errors, 'pot_version')}
                />
                <Form.Field>
                    <label>Custom Camera Name</label>
                    <Form.Input
                        id='customName'
                        fluid
                        placeholder='Custom Name'
                        onChange={this.onCustomNameChange}
                        error={false}
                        defaultValue={this.state.custom_name}
                    />
                </Form.Field>
                <Message
                    error={true}
                    visible={this.props.errors.custom_name !== undefined}
                    header={''}
                    content={getErrorMessage(this.props.errors, 'custom_name')}
                />
                <Grid columns='2'>
                    <Grid.Row className='no-padding-bottom'>
                        <Grid.Column>
                            <Form.Field>
                                <label>Latitude</label>
                                <Form.Input
                                    id='latitude'
                                    fluid
                                    type="number"
                                    placeholder='Latitude'
                                    onChange={this.onLatitudeChange}
                                    error={this.props.errors.latitude}
                                />
                            </Form.Field>
                            <Message
                                error={true}
                                visible={this.props.errors.latitude !== undefined}
                                header={this.props.errors.latitude}
                                content={getErrorMessage(this.props.errors, 'latitude')}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Form.Field>
                                <label>Longitude</label>
                                <Form.Input
                                    id='longitude'
                                    fluid
                                    type="number"
                                    placeholder='Longitude'
                                    onChange={this.onLongitudeChange}
                                    error={this.props.errors.longitude}
                                />
                            </Form.Field>
                            <Message
                                error={true}
                                visible={this.props.errors.longitude !== undefined}
                                header={this.props.errors.longitude}
                                content={getErrorMessage(this.props.errors, 'longitude')}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                &nbsp;
                <Form.Field disabled={this.state.type === 'Virtual'}>
                    <label>Video Storage Locations</label>
                    <Form.Select
                        id='videoStorageLocation'
                        fluid
                        placeholder='Lobster Vision'
                        onChange={this.onVideoStorageLocationChange}
                        error={false}
                        options={locationOptions}
                        defaultValue={this.state.video_storage_location}
                    />
                </Form.Field>

            </Form>
            : null
    }

    getMessage = () => {
        return (this.props.success || this.props.error) ?
            <Message
                error={this.props.error}
                success={this.props.success}
                visible={false}
                header={this.getHeader(this.props.error, this.props.success)}
                content={this.getContent(this.props.error, this.props.success, this.props.loading, this.props.errors)}
            /> : null
    }

    getCreateButton = () => {
        return (!this.props.success) ?
            <Button loading={this.props.loading} id='createCameraButton' onClick={this.onClick} positive>Create</Button> : null
    }

    getCloseOrCancelButton = () => {
        return (this.props.success) ?
            <Button onClick={this.onClose} primary>Close</Button>
            : <Button onClick={this.onClose} negative>Cancel</Button>
    }

    render = () => {
        return (
            <Modal size={"small"} open={this.props.open} onClose={this.onClose} closeIcon>
                <Modal.Header>Create a camera</Modal.Header>
                <Modal.Content>
                    {this.getPreloader()}
                    {this.getMessage()}
                    {this.getForm()}
                    <div className='modalButtons'>
                        {this.getCreateButton()}
                    </div>
                </Modal.Content>
            </Modal>
        )
    }

}

CreateCameraModal.propTypes = {
    open: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    statuses: PropTypes.array.isRequired,
    types: PropTypes.array.isRequired
}

export default CreateCameraModal
