import React from 'react'

const ShowBimModelType = (props) => {

    const handleChange = (event) => {
        props.onChange(event, event.target.value)
    }

    return (
        <div className={(props.error)? 'error' : ''}>
            <h4>Show BIM model type</h4>
            <label
                style={{
                    fontSize: "0.88em"
                }}
            >
                <input
                    style={{
                        marginRight: "5px"
                    }}
                    type="radio"
                    name='all'
                    value='All'
                    checked={props.checked === 'All'}
                    onChange={handleChange}
                    disabled={props.disabled}
                />
                All
            </label>
            <label
                style={{
                    fontSize: "0.88em",
                }}
            >
                <input
                    style={{
                        marginLeft: "10px",
                        marginRight: "5px"
                    }}
                    type="radio"
                    name='contract'
                    value='Contract'
                    checked={props.checked === 'Contract'}
                    onChange={handleChange}
                    disabled={props.disabled}
                />
                Contract
            </label>
            <label
                style={{
                    fontSize: "0.88em",
                }}
            >
                <input
                    style={{
                        marginLeft: "10px",
                        marginRight: "5px"
                    }}
                    type="radio"
                    name='delivery'
                    value='Delivery'
                    checked={props.checked === 'Delivery'}
                    onChange={handleChange}
                    disabled={props.disabled}
                />
                Delivery
            </label>
        </div>
    )
}

export default ShowBimModelType
