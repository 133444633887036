import * as types from './actionTypes'
import { API_BASE_URL}  from '../config'
import { addMessage } from './messengerActions'
import { log } from './logActions'
import { notAuthorisedResponse } from './authActions'
import { fetchWithToken } from '../util/accessToken'

export function editUserResponse(history, response) {
    return dispatch => {
        if (!response.errors) {
            dispatch(editUserResponseSuccess(response))
            dispatch(
                addMessage(
                    "success",
                    "Success",
                    "Successfully updated user."
                )
            )
        } else {
            dispatch(editUserResponseErrors(response))
        }
    }
}

export function editUserResponseSuccess(response) {
    return {type: types.EDIT_USER_RESPONSE_SUCCESS, editUser: response}
}

export function editUserResponseErrors(response) {
    return {type: types.EDIT_USER_RESPONSE_ERRORS, editUser: response}
}

export function userDetailsResponse(response) {
    return {type: types.EDIT_USER_DETAILS_RESPONSE, editUser: response}
}

export function bulkUserDetailsResponse(response) {
    return {type: types.EDIT_USER_BULK_DETAILS_RESPONSE, editUser: response}
}

export function userDetailsRequest(clientId, userId, isBulk = false) {
    return (dispatch, getState) => {

        dispatch({ type: types.EDIT_USER_DETAILS_REQUEST })

        return fetchWithToken(
            `${API_BASE_URL}/api/clients/${clientId}/users/${userId}`,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (response.status === 401) {
                dispatch(notAuthorisedResponse())
            } else {
                return response.json()
            }
        })
        .then((json) => {
            if (!isBulk) {
                dispatch(userDetailsResponse(json))
            } else {
                dispatch(bulkUserDetailsResponse(json))
            }
            
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function updateUserRequest(history, clientId, userId, userDetails) {
    return (dispatch, getState) => {

        dispatch({ type: types.EDIT_USER_REQUEST })

        return new Promise((resolve, reject) => {
            fetchWithToken(
                `${API_BASE_URL}/api/clients/${clientId}/users/${userId}`,
                {
                    method: 'PUT',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    body: JSON.stringify(userDetails)
                },
                dispatch,
                getState
            )
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        dispatch(notAuthorisedResponse())
                        reject(response)
                    } else {
                        response.json().then(data => {
                            dispatch(editUserResponse(history, data))
                            reject(response)
                        })
                    }
                } else {
                    response.json().then(data => {
                        dispatch(editUserResponse(history, data))
                        resolve(response)
                    })
                }
            })
            .catch((response) => {
                dispatch(
                    addMessage(
                        "negative",
                        "Unable to process your request",
                        "Please check your internet connection."
                    )
                )
                dispatch(log("error", response))
            })
        })
    }
}

export function editUserRolesResponse(response) {
    if (!Array.isArray(response)) {
        return {type: types.EDIT_USER_ROLES_RESPONSE, editUser: []}
    } else {
        return {type: types.EDIT_USER_ROLES_RESPONSE, editUser: response}
    }
}

export function editUserRolesRequest() {

    return (dispatch, getState) => {

        dispatch({ type: types.EDIT_USER_ROLES_REQUEST })

        return fetchWithToken(
            API_BASE_URL + '/api/admin/user/roles',
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (response.status === 401) {
                dispatch(notAuthorisedResponse())
            } else {
                return response.json()
            }
        })
        .then((json) => {
            dispatch(editUserRolesResponse(json))
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })

    }
}


export function editUserStatusesResponse(response) {
    if (!Array.isArray(response)) {
        return {type: types.EDIT_USER_STATUSES_RESPONSE, editUser: []}
    } else {
        return {type: types.EDIT_USER_STATUSES_RESPONSE, editUser: response}
    }
}

export function editUserStatusesRequest() {

    return (dispatch, getState) => {

        dispatch({ type: types.EDIT_USER_STATUSES_REQUEST })

        return fetchWithToken(
            API_BASE_URL + '/api/admin/user/statuses',
            {
            method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                return response
            } else {
                return response
            }
        })
        .then(response => response.json())
        .then((json) => {
            dispatch(editUserStatusesResponse(json))
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })

    }
}

export function editUserClientsResponse(response) {
    if (!Array.isArray(response)) {
        return {type: types.EDIT_USER_CLIENTS_RESPONSE, editUser: []}
    } else {
        return {type: types.EDIT_USER_CLIENTS_RESPONSE, editUser: response.clients}
    }
}

export function editUserClientsRequest() {

    return (dispatch, getState) => {

        dispatch({ type: types.EDIT_USER_CLIENTS_REQUEST })

        return fetchWithToken(
            API_BASE_URL + '/api/clients',
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                return response
            } else {
                return response
            }
        })
        .then(response => response.json())
        .then((json) => {
            dispatch(editUserClientsResponse(json))
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })

    }
}

export function resetErrors() {
    return { type: types.EDIT_USER_RESET_ERRORS }
}

/**
 *
 * We're passing the clientId and userId in order for it to passed both to the initial
 * request as well as the response.   The API returns 'No Content' on DELETE
 *
 * @param clientId
 * @param userId
 * @returns {Promise.<TResult>}
 */
export function deleteUserRequest(clientId, userId) {
    return (dispatch, getState) => {
        dispatch({type: types.DELETE_USER_REQUEST})
        // Let's now try to make the call
        const url = `${API_BASE_URL}/api/clients/${clientId}/users/${userId}`
        return fetchWithToken(
            url,
            {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                // If here, we have errors
                response.json().then(data => {
                    dispatch(deleteUserUnsuccessfulResponse(clientId, userId, data))
                })
            } else {
                // Might be No Content...especially on success
                if (response.status === 204) {
                    dispatch(
                        addMessage(
                            "success",
                            "Success",
                            "Successfully deleted user."
                        )
                    )
                    dispatch(deleteUserSuccessfulResponse(clientId, userId))
                }
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function deleteUserUnsuccessfulResponse(clientId, userId, json) {
    return {
        type: types.DELETE_USER_RESPONSE_ERRORS,
        ...json,
        clients_id: clientId,
        users_id: userId
    }
}

export function deleteUserSuccessfulResponse(clientId, userId) {
    return {
        type: types.DELETE_USER_RESPONSE_SUCCESS,
        clients_id: clientId,
        users_id: userId,
        message: 'Your request to suspend this user has been successful'
    }
}

export function deleteUserResetErrors() {
    return { type: types.DELETE_USER_RESET_ERRORS }
}

export function suspendUserRequest(clientId, userId) {
    return (dispatch, getState) => {
        dispatch({type: types.SUSPEND_USER_REQUEST})
        // Let's now try to make the call
        const url = `${API_BASE_URL}/api/clients/${clientId}/users/${userId}/suspend-user`
        return fetchWithToken(
            url,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                // If here, we have errors
                response.json().then(data => {
                    dispatch(suspendUserUnsuccessfulResponse(data))
                })
            } else {
                // Might be No Content...especially on success
                response.json().then(data => {
                    dispatch(
                        addMessage(
                            "success",
                            "Success",
                            "Successfully suspended user."
                        )
                    )
                    dispatch(suspendUserSuccessfulResponse(data))
                })
            }
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function suspendUserUnsuccessfulResponse(json) {
    return {
        type: types.SUSPEND_USER_RESPONSE_ERRORS,
        ...json,
    }
}

export function suspendUserSuccessfulResponse(json) {
    return {
        type: types.SUSPEND_USER_RESPONSE_SUCCESS,
        ...json,
        message: 'Your request to suspend this user has been successful'
    }
}

export function suspendUserResetErrors() {
    return { type: types.SUSPEND_USER_RESET_ERRORS }
}

export function userVerificationEmailRequest(clientId, userId) {
    return (dispatch, getState) => {
        dispatch({type: types.USER_VERIFICATION_EMAIL_REQUEST})
        // Make the call...
        const url = API_BASE_URL +
            '/api/clients/' +
            clientId +
            '/users/' +
            userId +
            '/send-verification-email'
        return fetchWithToken(
            url,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (!response.ok) {
                return response
            } else {
                return response
            }
        })
        .then(response => response.json())
        .then((json) => {
            dispatch(userVerificationEmailResponse(clientId, userId, json))
        })
        .catch((response) => {
            dispatch(
                addMessage(
                    "negative",
                    "Unable to process your request",
                    "Please check your internet connection."
                )
            )
            dispatch(log("error", response))
        })
    }
}

export function userVerificationEmailResponse(clientId, userId, response) {
    if (response.errors) {
        return {
            ...response,
            type: types.USER_VERIFICATION_EMAIL_RESPONSE_ERRORS,
            clients_id : clientId,
            users_id : userId
        }
    } else {
        return {
            ...response,
            type: types.USER_VERIFICATION_EMAIL_RESPONSE_SUCCESS,
            clients_id : clientId,
            users_id : userId
        }
    }
}

export function userVerificationEmailResetErrors() {
    return {type: types.USER_VERIFICATION_EMAIL_RESET_ERRORS}
}

export function updateLocalUserState(user) {
    return { type: types.EDIT_USER_UPDATE_LOCAL_USER_STATE, user: user }
}

export function updateLocalBulkUserState(user) {
    return { type: types.EDIT_USER_UPDATE_LOCAL_BULK_USER_STATE, bulkEditUsers: user }
}

export function setContext(context) {
    return { type: types.EDIT_USER_SET_CONTEXT, context: context }
}
