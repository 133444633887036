import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Message, Table, Menu, Icon, Popup, Input } from 'semantic-ui-react'
import { getErrorMessage } from './../util/errors'
import { isPermitted } from "./../util/acl"
import {PAGINATION_MAX_ROWS_PER_PAGE}  from './../config'
import debounce from 'lodash/debounce'

class RoleListView extends Component {

    constructor(props) {
        super(props)
        this.state = { openModal: false, roles_id: null, filterBy: ''}
    }

    componentDidMount() {
        this.props.rolesActions.fetchRoles(1,  this.state.filterBy)
        this.props.rolesActions.fetchPermissions()
    }

    onFilterByChange = debounce((filterTerm) => {
        this.setState({filterBy: filterTerm})
        this.props.rolesActions.fetchRoles(1, filterTerm)
    }, 500)

    getDeleteMessage = (blurb) => {
        if (this.props.roles.remove.error) {
            return (
                <Message negative>
                    <Message.Header>Error</Message.Header>
                    <p>{blurb}</p>
                </Message>
            )
        }
        if (this.props.roles.remove.success) {
            return (
                <Message success>
                    <Message.Header>Success</Message.Header>
                    <p>{blurb}</p>
                </Message>
            )
        }
        if (this.props.roles.remove.processing) {
            return (
                <Message>
                    <Message.Header>Processing...</Message.Header>
                    <p>This might take a few moments...</p>
                </Message>
            )
        }
        return (
            <Message>
                <Message.Header>Are you sure you want to remove this role?</Message.Header>
                <p>Please note that it is not possible to remove a role that is being used by at least one user.</p>
            </Message>
        )
    }

    getDeleteError = () => {
        if (!this.props.roles.remove.error) {
            return null
        }
        let message = getErrorMessage(this.props.roles.remove.errors, 'roles_id')
        if (!message) {
            message = getErrorMessage(this.props.roles.remove.errors, 'unknownErrorType')
        }
        return (message) ? message : "There was an unexpected error"
    }

    getDeleteSuccess = () => {
        if (this.props.roles.remove.details && this.props.roles.remove.details.message) {
            return this.props.roles.remove.details.message
        }
    }

    getDeleteBlurb = () => {
        if (this.props.roles.remove.error) {
            return this.getDeleteError()
        }
        if (this.props.roles.remove.success) {
            return this.getDeleteSuccess()
        }
        return null
    }

    getDeleteCancelButton = () => {
        const label = (this.props.roles.remove.success) ? "Close" : "Cancel"
        return (
            <Button onClick={() => {
                this.setState({openModal:false}, () => {
                    // On closing...
                    this.props.rolesActions.fetchRoles()
            })}}

            >{label}</Button>
        )
    }

    getDeleteConfirmationButton = () => {

        if (this.props.roles.remove.success) {
            return null
        }

        return (
            <div>
                <Button onClick={() => {
                    this.setState({openModal:false}, () => {
                    // On closing...
                    this.props.rolesActions.fetchRoles()
                })}} negative>Cancel</Button>
                <Button
                    loading={this.props.roles.remove.processing}
                    onClick={() => {
                        this.props.rolesActions.deleteRole(this.state.roles_id)
                    }} positive icon='checkmark' labelPosition='right' content='Delete' />
            </div>
        )
    }

    getDeleteButtons = () => {
        const confirmationButton = this.getDeleteConfirmationButton()
        return (
            <div>
                {confirmationButton}
            </div>

        )
    }

    getUpdateRoleLink = (item) => {
        let updateRoleLink = null
        if (isPermitted(this.props.auth.user, 'put-roles-role')) {
            updateRoleLink = (
                <Popup
                    offset={10}
                    trigger={<Icon className='update-role-link  action-link right' link={true} name='cogs' onClick={() => {
                        this.props.onOpenModal(item.id, 'UpdateRole')
                    }}/>}
                    content='Update'
                />
            )
        }
        return updateRoleLink
    }

    getRemoveRoleLink = (item) => {
        let removeRoleLink = null
        if (isPermitted(this.props.auth.user, 'delete-roles-role')) {
            removeRoleLink = (
                <Popup
                    offset={10}
                    trigger={<Icon className='remove-role-link  action-link right' link={true} name='trash' onClick={() => {
                        // this.props.rolesActions.resetDelete()
                        // this.setState({openModal:true, roles_id: item.id})
                        this.props.onOpenModal(item.id, 'DeleteRole')
                    }}/>}
                    content='Remove'
                />
            )
        }
        return removeRoleLink
    }

    prevPage = (page) => {
        this.props.rolesActions.fetchRoles(page, this.state.filterBy)
    }

    nextPage = (page) => {
        this.props.rolesActions.fetchRoles(page, this.state.filterBy)
    }

    jumpToPage = (page) => {
        this.props.rolesActions.fetchRoles(page, this.state.filterBy)
    }


    getRoles = () => {
        return (this.props.roles.view_all.details
            && this.props.roles.view_all.details.roles) ? this.props.roles.view_all.details.roles : []

    }

    getNumberOfPages = (numberPerPage) => {
        if (this.props.roles.view_all.details && this.props.roles.view_all.details.totalItems) {
            if (this.props.roles.view_all.details.totalItems > 0) {
                return Math.ceil(this.props.roles.view_all.details.totalItems / numberPerPage)
            } else {
                return 1;
            }
        }
        return 1;
    }

    getPrevPageNumberOrNull = () => {
        return (this.props.roles.view_all.page > 1) ?
            this.props.roles.view_all.page - 1: null
    }

    getNextPageNumberOrNull = (numberOfPages) => {
        return (this.props.roles.view_all.page < numberOfPages) ?
            this.props.roles.view_all.page + 1: null

    }

    getCurrPageNumber = () => {
        return this.props.roles.view_all.page
    }

    render = () => {

        const roles = this.getRoles()
        const numberPerPage = PAGINATION_MAX_ROWS_PER_PAGE
        const numberOfPages = this.getNumberOfPages(numberPerPage)
        const currPage = this.getCurrPageNumber()
        const prevPage = this.getPrevPageNumberOrNull()
        const nextPage = this.getNextPageNumberOrNull(numberOfPages)

        return (
            <div className='role-list-view' data-testid="role-list-view">
                <Input
                    id="filterByField"
                    placeholder="Search..."
                    onChange={(event, {value}) => {
                        this.onFilterByChange(value)
                    }}
                />
                <Table striped={true} fixed={true}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Description</Table.HeaderCell>
                            <Table.HeaderCell>Power</Table.HeaderCell>
                            <Table.HeaderCell>LDAP Group</Table.HeaderCell>
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            roles.map((item, idx) => {

                                return (
                                    <Table.Row key={idx}>
                                        <Table.Cell>{item.name}</Table.Cell>
                                        <Table.Cell>{item.description}</Table.Cell>
                                        <Table.Cell>{item.power}</Table.Cell>
                                        <Table.Cell>{item.ldap_group}</Table.Cell>
                                        <Table.Cell>
                                            {this.getUpdateRoleLink(item)}
                                            {this.getRemoveRoleLink(item)}
                                            </Table.Cell>
                                    </Table.Row>
                                )

                            })

                        }
                    </Table.Body>
                    {(numberOfPages > 1) ?
                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan='5'>
                                    <Menu floated='right' pagination>
                                        {
                                            (prevPage) ? <Menu.Item as='a' icon onClick={() => {
                                                this.prevPage(prevPage)
                                            }}>
                                                <Icon name='left chevron'/>
                                            </Menu.Item> : null
                                        }
                                        {

                                            Array(numberOfPages).fill(null).map((u, i) => {
                                                return (<Menu.Item key={i} as='a' active={currPage === (i + 1)}
                                                                   onClick={() => {
                                                                       const page = i + 1
                                                                       this.jumpToPage(page)
                                                                   }}>{i + 1}</Menu.Item>)
                                            })
                                        }
                                        {
                                            (nextPage) ?
                                                <Menu.Item as='a' icon onClick={() => {
                                                    this.nextPage(nextPage)
                                                }}>
                                                    <Icon name='right chevron'/>
                                                </Menu.Item> : null
                                        }
                                    </Menu>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer> : null
                    }
                </Table>
            </div>
        )
    }
}

RoleListView.propTypes = {
    rolesActions: PropTypes.object.isRequired,
    roles: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    onOpenModal: PropTypes.func.isRequired
}

export default RoleListView
