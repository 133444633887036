import React, {Component} from 'react'
import {Icon, Label} from 'semantic-ui-react'
import PropTypes from 'prop-types'

class CamerasSort extends Component {

    render() {
        const arrow = this.props.ascending ? (<Icon name="arrow up" />):(<Icon name="arrow down" />)



        return (
            <div>
                <h4>Sort by </h4>
                <Label
                    as="a"
                    onClick={() => {this.props.updateSortBy("potId")}}
                    color={this.props.sortField === 'potId' ? 'orange' : 'grey'}
                >
                    {this.props.sortField === 'potId' ? arrow : null}
                    Pot
                </Label>
                <Label
                    as="a"
                    onClick={() => {this.props.updateSortBy("clientName")}}
                    color={this.props.sortField === 'clientName' ? 'orange' : 'grey'}
                >
                    {this.props.sortField === 'clientName' ? arrow : null}
                    Client
                </Label>
                <Label
                    as="a"
                    onClick={() => {this.props.updateSortBy("projectName")}}
                    color={this.props.sortField === 'projectName' ? 'orange' : 'grey'}
                >
                    {this.props.sortField === 'projectName' ? arrow : null}
                    Project
                </Label>
            </div>
        )
    }
}

CamerasSort.propTypes = {
    updateSortBy: PropTypes.func.isRequired,
    sortField: PropTypes.string.isRequired,
    ascending: PropTypes.bool.isRequired
}
export default CamerasSort
