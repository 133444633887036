import initialState from './initialState'

import {
    BIM_FINAL_3D_IMAGE_UPLOAD_REQUEST,
    BIM_FINAL_3D_IMAGE_UPLOAD_ERRORS,
    BIM_FINAL_3D_IMAGE_UPLOAD_SUCCESS,
    BIM_FINAL_3D_IMAGE_REMOVE_REQUEST,
    BIM_FINAL_3D_IMAGE_REMOVE_ERRORS,
    BIM_FINAL_3D_IMAGE_REMOVE_SUCCESS,
    BIM_FINAL_3D_SET_FILENAME,
    BIM_FINAL_3D_IMAGE_RESET
} from '../actions/actionTypes'

export default function bimFinal3dImage(state = initialState.bimFinal3dImage, action) {
    switch(action.type) {
        case BIM_FINAL_3D_IMAGE_UPLOAD_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    upload: {
                        processing: true,
                        success: false,
                        error: false,
                        errors: {}
                    }
                }
            )
        case BIM_FINAL_3D_IMAGE_UPLOAD_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    upload: {
                        processing: false,
                        success: false,
                        error: true,
                        errors: action.errors
                    }
                }
            )
        case BIM_FINAL_3D_IMAGE_UPLOAD_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    upload: {
                        processing: false,
                        success: true,
                        error: false,
                        errors: {}
                    }
                }
            )
        case BIM_FINAL_3D_IMAGE_REMOVE_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    remove: {
                        processing: true,
                        success: false,
                        error: false,
                        errors: {}
                    }
                }
            )
        case BIM_FINAL_3D_IMAGE_REMOVE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    remove: {
                        processing: false,
                        success: false,
                        error: true,
                        errors: action.errors
                    }
                }
            )
        case BIM_FINAL_3D_IMAGE_REMOVE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    remove: {
                        processing: false,
                        success: true,
                        error: false,
                        errors: {}
                    }
                }
            )
        case BIM_FINAL_3D_SET_FILENAME:
            return Object.assign(
                {},
                state,
                {
                    filename: action.filename,
                    width: action.width,
                    height: action.height
                }
            )
        case BIM_FINAL_3D_IMAGE_RESET:
            return Object.assign(
                {},
                state,
                {
                    ...initialState.bimFinal3dImage
                }
            )
        default:
            return state
    }
}
