/**
 * Function to flatten a nested object
 *
 * @param Object $obj
 * @return Object
 */
export default function flatten(obj) {
    return Object.assign(
        {},
        ...function _flatten(obj) {
            return [].concat(...Object.keys(obj)
            .map(k =>
                typeof obj[k] === 'object' ? _flatten(obj[k]) : ({[k]: obj[k]})
            ));
        }(obj)
    )
}
