import React, { Component } from 'react'
import { Label } from 'semantic-ui-react'
import PropTypes from 'prop-types'

class CameraVersionFilter extends Component {
    render() {
        if (this.props.versions.versions.length < 1) {
            return null
        }
        return (
            <div>
                <h4>Filter by Version</h4>
                {this.props.versions.versions.map((version, idx) => {
                    let tagColour = 'grey'
                    if (this.props.versions.filterBy.indexOf(version) > -1) {
                        tagColour = 'blue'
                    }
                    return (
                        <Label
                            as='a'
                            key={idx}
                            onClick={() => {this.props.toggleFilterVersion(version)}}
                            color={tagColour}
                            className={'tags'}
                        >
                            {version}
                        </Label>
                    )
                })}
            </div>
        )
    }
}

CameraVersionFilter.propTypes = {
    statuses: PropTypes.shape({
        versions: PropTypes.array.isRequired,
        filterBy: PropTypes.array.isRequired
    }),
    toggleFilterVersion: PropTypes.func.isRequired
}

export default CameraVersionFilter
