import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import React, { Component } from 'react';
import * as authActions from '../actions/authActions'
import * as messengerActions from '../actions/messengerActions'
import * as rolesActions from '../actions/rolesActions'
import DocumentTitle from 'react-document-title'
import { isPermitted } from './../util/acl'
import { Button } from 'semantic-ui-react'
import CreateRoleModal from "./../components/modals/CreateRole"
import UpdateRoleModal from "./../components/modals/UpdateRole"
import DeleteRoleModal from "./../components/modals/DeleteRole"
import RoleListView from "../components/RoleListView";
import { checkAuth } from '../util/accessToken'

class RolesContainer extends Component {

    constructor(props) {
        super(props)
        this.state = { type: null, roleId: null }
    }

    componentDidMount () {
        window.scrollTo(0, 0)

        return checkAuth(this.props.auth, this.props.authActions, this.props.history)
        .then(() => {
            this.props.messengerActions.removeAllMessages()
            this.props.rolesActions.reset()
        })
        .catch(err => {})
    }

    onClose = () => {
        this.setState((prevState, props) => {
            return {
                ...prevState,
                type: null,
                roleId: null
            }
        }, () => {
            // Depends on the state...
            this.props.rolesActions.fetchRoles()

        })
    }

    getCreateRoleModal = () => {
        return (
            <CreateRoleModal
                processing={this.props.roles.create.processing}
                open={this.state.type === "CreateRole"}
                onClose={this.onClose}
                error={this.props.roles.create.error}
                errors={this.props.roles.create.errors}
                onClick={ (args) => {
                    this.props.rolesActions.createRole(args)
                } }
                success={this.props.roles.create.success}

                // Added for permissions...
                rolesActions={this.props.rolesActions}
                roles={this.props.roles}
                auth={this.props.auth}
            />)
    }

    getUpdateRoleModal = () => {
        return (
            <UpdateRoleModal
                processing={this.props.roles.update.processing}
                open={this.state.type === "UpdateRole"}
                onClose={this.onClose}
                error={this.props.roles.update.error}
                errors={this.props.roles.update.errors}
                onClick={ (roleId, args) => {
                    this.props.rolesActions.updateRole(roleId, args)
                } }
                success={this.props.roles.update.success}
                role={this.props.roles.view_one.details}

                // Added for permissions...
                rolesActions={this.props.rolesActions}
                roles={this.props.roles}
                auth={this.props.auth}
            />)
    }

    getDeleteRoleModal = () => {
        return (
            <DeleteRoleModal
                processing={this.props.roles.remove.processing}
                open={this.state.type === "DeleteRole"}
                onClose={this.onClose}
                error={this.props.roles.remove.error}
                errors={this.props.roles.remove.errors}
                onClick={ (roleId, args) => {
                    this.props.rolesActions.deleteRole(roleId, args)
                } }
                success={this.props.roles.remove.success}
                roleId={this.state.roleId}
            />)
    }

    render() {
        const createRoleButton = (isPermitted(this.props.auth.user, 'post-roles-role')) ? <Button className='create-role-button' onClick={() => {
            this.props.rolesActions.resetCreate()
            this.setState((prevState, props) => {
                return {
                    ...prevState,
                    type: "CreateRole"
                }
            })
        }} primary>Create role</Button> : null
        return (
            <div className='roles-container'>
                <DocumentTitle title='Roles' />
                <h2>Roles</h2>
                <div style={{float: 'right'}}>
                    {createRoleButton}
                </div>
                <RoleListView
                    auth={this.props.auth}
                    rolesActions={this.props.rolesActions}
                    roles={this.props.roles}
                    onOpenModal={(roleId, action) => {
                        if (action === 'UpdateRole') {
                            this.props.rolesActions.resetUpdate()
                            this.props.rolesActions.fetchRole(roleId)
                            this.setState((prevState, props) => {
                                return {
                                    ...prevState,
                                    type: "UpdateRole",
                                    roleId: roleId
                                }
                            })
                        } else if (action === 'DeleteRole') {
                            this.props.rolesActions.resetDelete()
                            this.props.rolesActions.fetchRole(roleId)
                            this.setState((prevState, props) => {
                                return {
                                    ...prevState,
                                    type: "DeleteRole",
                                    roleId: roleId
                                }
                            })
                        }

                    }}
                />
                {this.getCreateRoleModal()}
                {this.getUpdateRoleModal()}
                {this.getDeleteRoleModal()}
            </div>
        )
    }
}

RolesContainer.propTypes = {
    authActions: PropTypes.object,
    auth: PropTypes.object,
    messengerActions: PropTypes.object,
    rolesActions: PropTypes.object,
    roles: PropTypes.object
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        roles: state.roles
    }
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(authActions, dispatch),
        messengerActions: bindActionCreators(messengerActions, dispatch),
        rolesActions: bindActionCreators(rolesActions, dispatch)
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(RolesContainer))
