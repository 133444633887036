import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as authActions from '../actions/authActions'
import * as potAssignActions from '../actions/potAssignActions'
import PotAssign from '../components/pages/PotAssign'
import { isPermitted } from './../util/acl'
import { checkAuth } from '../util/accessToken'

class PotAssignContainer extends Component {

    componentDidMount() {
        return checkAuth(this.props.auth, this.props.authActions, this.props.history)
        .then(() => {
            this.props.potAssignActions.loadPCIDs()
        })
        .catch(err => {})
    }

    render() {
        const potAssign = (isPermitted(this.props.auth.user, 'any-potassign-any')) ?
            <PotAssign {...this.props} /> :
            <p>You do not have permission to view this page</p>

        return (
            <div>
                {potAssign}
            </div>)
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        potAssign: state.potAssign
    }
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(authActions, dispatch),
        potAssignActions: bindActionCreators(potAssignActions, dispatch)
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PotAssignContainer)
