import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Segment, Message, Form, Loader, Grid } from 'semantic-ui-react'
import DataTransferView from "./DataTransferView"
import { getErrorMessage } from './../util/errors'
import { BtoGB } from "./../util/converter"

const BYTE_TO_GIGABYTE = 1000000000

class DataTransferSettings extends Component {

    constructor(props) {
        super(props)
        this.state = {
            allowance_start_day: '',
            data_allowance_in_bytes : ''
        }
    }

    componentDidMount() {
        this.setState((prevState, props) => {

            if (props.data_transfer) {

                return {
                    allowance_start_day: props.data_transfer.allowance_start_day,
                    data_allowance_in_bytes: props.data_transfer.data_allowance_in_bytes,
                }

            } else {
                return { ...prevState }
            }
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.data_transfer !== prevProps.data_transfer
            && this.props.data_transfer.allowance_start_day
            && this.props.update_data_transfer.processing !== true) {
            this.setState((prevState, props) => {
                return {
                    allowance_start_day: this.props.data_transfer.allowance_start_day,
                    data_allowance_in_bytes: this.props.data_transfer.data_allowance_in_bytes,
                }
            })
        }
    }

    onDataAllowanceChange = (event) => {
        const val = event.target.value
        this.setState((prevState, props) => {
            return {
                ...prevState,
                data_allowance_in_bytes: val * BYTE_TO_GIGABYTE
            }
        })
    }

    onDayOfMonthChange = (event) => {
        const val = event.target.value
        this.setState((prevState, props) => {
            return {
                ...prevState,
                allowance_start_day: val
            }
        })
    }

    handleDismiss = () => {
        this.setState({ visible: false })

        setTimeout(() => {
            this.setState({ visible: true })
        }, 2000)
    }


    getError = () => {
        if (!this.props.update_data_transfer.error) {
            return null
        }
        let message = getErrorMessage(this.props.update_data_transfer.errors, 'cameras_id')
        if (!message) {
            message = getErrorMessage(this.props.update_data_transfer.errors, 'unknownErrorType')
        }
        return (message) ? message : "There was an unexpected error"
    }

    getMessage = (blurb) => {
        return (this.props.update_data_transfer.error || this.props.update_data_transfer.success) ?
            <Message positive={this.props.update_data_transfer.success} negative={this.props.update_data_transfer.error}

                     onDismiss={this.props.onDismissMessage}

            >
                <Message.Header>{(this.props.update_data_transfer.error) ? 'Error' : 'Success'}</Message.Header>
                <p>{blurb}</p>
            </Message> : null
    }

    getBlurb = () => {
        if (this.props.update_data_transfer.error) {
            return this.getError()
        }
        if (this.props.update_data_transfer.success) {
            return 'Successfully updated the SIM settings'
        }
        return null
    }

    getForm = () => {
        return (
            <Form>
                <p className="info">Please note that if you change the day of month the allowance starts for an existing camera, the current usage will no longer be visible.  This is due to the way the data is persisted on the camera.</p>

                {this.getMessage(this.getBlurb())}

                <Form.Field>
                    <label>Day of month the allowance starts</label>
                    <input
                        type='number'
                        name='allowance_start_day'
                        value={this.state.allowance_start_day}
                        onChange={this.onDayOfMonthChange}
                        placeholder="Day of month the allowance starts"
                        min={1}
                        max={31}
                        step={1}
                        maxLength={2}
                        readOnly={!this.props.editable}
                    />

                </Form.Field>

                <Form.Field>
                    <label>Data Allowance (GB)</label>
                    <input
                            type='number'
                            name='data_allowance_in_bytes'
                            value={BtoGB(this.state.data_allowance_in_bytes)}
                            onChange={this.onDataAllowanceChange}
                            placeholder="Data Allowance (GB)"
                            min={1}
                            max={1000}
                            step={1}
                            maxLength={20}
                            readOnly={!this.props.editable}
                        />
                </Form.Field>
                <br />
                {this.props.editable ?
                    <Button
                        id='updateSimDetailsButton'
                        fluid
                        disabled={this.props.update_data_transfer.processing}
                        loading={this.props.update_data_transfer.processing}
                        onClick={() => {
                        this.props.onUpdateClick(this.state)
                    }}>Update SIM details</Button>
                : null }

            </Form>
        )
    }

    render = () => {
        if (this.props.update_data_transfer.success === true
            && this.props.data_transfer.loading === true) {
            // We've already made a change and now we're loading the pie chart etc
            const form = this.getForm()
            return (
                <div className='data-transfer-settings'>
                    <Segment>
                        <h4>Data Transfer</h4>
                        <Loader active inline='centered' />
                        {form}
                    </Segment>
                </div>
            )

        } else if (this.props.loading === true) {

            return (
                <div className='data-transfer-settings'>
                    <Segment>
                        <h4>Data Transfer</h4>
                        {/*<Dimmer active inverted><Loader content='' /></Dimmer>*/}
                        <Grid columns='1'>
                            <Grid.Row>
                                <Grid.Column><div className="animated-blank-line" /></Grid.Column>
                                <Grid.Column><div className="animated-blank-circle" /></Grid.Column>
                                <Grid.Column><div className="animated-blank-line" /></Grid.Column>
                                <Grid.Column><div className="animated-blank-line" /></Grid.Column>
                                <Grid.Column><div className="animated-blank-block-100" /></Grid.Column>
                                <Grid.Column><div className="animated-blank-line" /></Grid.Column>
                                <Grid.Column><div className="animated-blank-block-100" /></Grid.Column>
                                <Grid.Column><div className="animated-blank-line" /></Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                </div>
            )
        } else {

            const form = this.getForm()
            const viewer = (this.props.loading === false) ?
                <DataTransferView data_transfer={this.props.data_transfer}/> : null
            return (
                <div className='data-transfer-settings'>
                    <Segment>
                        {viewer}
                        {form}
                    </Segment>
                </div>
            )

        }
    }
}

DataTransferSettings.propTypes = {
    data_transfer: PropTypes.object.isRequired,
    update_data_transfer: PropTypes.object.isRequired,
    onUpdateClick: PropTypes.func.isRequired,
    onDismissMessage: PropTypes.func.isRequired
}

export default DataTransferSettings
