import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon, Table } from 'semantic-ui-react'

class EmbedCode extends Component {

    formatYesNo = (value) => {
        if (value) {
            return (
                <Icon color='green' name='checkmark' size='large' />
            )
        } else {
            return null
        }
    }

    formatQuality = (value) => {
        switch (value) {
            case 25:
                return 'Low'
            case 50:
                return 'Medium'
            case 75:
                return 'High'
            case 100:
                return 'Max'
            default:
                return 'Unknown'
        }
    }

    render() {
        return (
            <Table.Row key={`embed_${this.props.id}`}>
                <Table.Cell>
                    {this.props.format}
                </Table.Cell>
                <Table.Cell>
                    {this.props.width}x{this.props.height}
                </Table.Cell>
                <Table.Cell>
                    {this.formatQuality(this.props.quality)}
                </Table.Cell>
                <Table.Cell>
                    {this.formatYesNo(this.props.timecode)}
                </Table.Cell>
                <Table.Cell>
                    {this.props.crop}
                </Table.Cell>
                <Table.Cell>
                    {this.props.delay}
                </Table.Cell>
                <Table.Cell>
                    {this.formatYesNo(this.props.processed)}
                </Table.Cell>
                <Table.Cell>
                    {this.props.name}
                </Table.Cell>
                <Table.Cell>
                    {this.props.actionButtons}
                </Table.Cell>
            </Table.Row>
        )
    }
}

EmbedCode.propTypes = {
    embed: PropTypes.shape({
        id: PropTypes.number.isRequired,
        format: PropTypes.string.isRequired,
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
        quality: PropTypes.number.isRequired,
        timecode: PropTypes.bool,
        crop: PropTypes.string.isRequired,
        delay: PropTypes.number,
        processed: PropTypes.bool,
        name: PropTypes.string.isRequired,
        actionButtons: PropTypes.object.isRequired
    })
}

export default EmbedCode
