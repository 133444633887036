import initialState from './initialState';
import {
    EMBED_RESET,
    EMBED_FETCH_ALL_REQUEST,
    EMBED_FETCH_ALL_RESPONSE_ERRORS,
    EMBED_FETCH_ALL_RESPONSE_SUCCESS,
    EMBED_FETCH_ONE_REQUEST,
    EMBED_FETCH_ONE_RESPONSE_ERRORS,
    EMBED_FETCH_ONE_RESPONSE_SUCCESS,
    EMBED_UPDATE_REQUEST,
    EMBED_UPDATE_RESPONSE_ERRORS,
    EMBED_UPDATE_RESPONSE_SUCCESS,
    EMBED_UPDATE_RESET,
    EMBED_CREATE_REQUEST,
    EMBED_CREATE_RESPONSE_ERRORS,
    EMBED_CREATE_RESPONSE_SUCCESS,
    EMBED_CREATE_RESET,
    EMBED_DELETE_REQUEST,
    EMBED_DELETE_RESPONSE_ERRORS,
    EMBED_DELETE_RESPONSE_SUCCESS
} from '../actions/actionTypes';

export default function embed(state = initialState.embed, action) {

    switch (action.type) {

        case EMBED_FETCH_ALL_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    list: {
                        loading: true,
                        success: false,
                        error: false,
                        errors: {},
                        embeds: {}
                    }
                }
            )

        case EMBED_FETCH_ALL_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    list: {
                        loading: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        embeds: {}
                    }
                }
            )

        case EMBED_FETCH_ALL_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    list: {
                        loading: false,
                        success: true,
                        error: false,
                        errors: {},
                        embeds: action.embeds
                    }
                }
            )

        case EMBED_FETCH_ONE_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    fetch_one: {
                        embedId: action.embed_id,
                        loading: true,
                        success: false,
                        error: false,
                        errors: {},
                        details: {}
                    }
                }
            )

        case EMBED_FETCH_ONE_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    fetch_one: {
                        embedId: state.fetch_one.embedId,
                        loading: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: {}
                    }
                }
            )

        case EMBED_FETCH_ONE_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    fetch_one: {
                        embedId: state.fetch_one.embedId,
                        loading: false,
                        success: true,
                        error: false,
                        errors: {},
                        details: action.details
                    }
                }
            )

        case EMBED_UPDATE_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    update: {
                        embedId: action.embed_id,
                        cameraId: action.clients_id,
                        processing: true,
                        success: false,
                        error: false,
                        errors: {},
                        details: {}
                    }
                }
            )

        case EMBED_UPDATE_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    update: {
                        embedId: state.update.embedId,
                        cameraId: state.update.cameraId,
                        processing: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: {}
                    }
                }
            )

        case EMBED_UPDATE_RESPONSE_SUCCESS:
            let embedList = state.list.embeds
            let replaceIndex = null
            for (var i = 0; i < embedList.length; i++) {
            	if (embedList[i]['id'] === action.details.id) {
                    replaceIndex = i
                    break
                  }
            }
            if (replaceIndex === null) {
                replaceIndex = embedList.length
            }
            embedList[replaceIndex] = action.details
            return Object.assign(
                {},
                state,
                {
                    update: {
                        embedId: state.update.embedId,
                        cameraId: state.update.cameraId,
                        processing: false,
                        success: true,
                        error: false,
                        errors: {},
                        details: action.details
                    },
                    list: {
                        ...state.list,
                        embeds: embedList
                    }
                }
            )

        case EMBED_CREATE_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    create: {
                        cameraId: action.clients_id,
                        embedId: action.embed_id,
                        processing: true,
                        success: false,
                        error: false,
                        errors: {},
                        details: {}
                    }
                }
            )

        case EMBED_CREATE_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    create: {
                        cameraId: state.create.cameraId,
                        embedId: state.create.embedId,
                        processing: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: {}

                    }
                }
            )

        case EMBED_CREATE_RESPONSE_SUCCESS:
            let createEmbedList = state.list.embeds
            createEmbedList.push(action.details)
            return Object.assign(
                {},
                state,
                {
                    create: {
                        cameraId: state.create.cameraId,
                        embedId: state.create.embedId,
                        processing: false,
                        success: true,
                        error: false,
                        errors: {},
                        details: action.details
                    },
                    list: {
                        ...state.list,
                        embeds: createEmbedList
                    }
                }
            )

        case EMBED_DELETE_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    delete: {
                        cameraId: action.clients_id,
                        embedId: action.embed_id,
                        processing: true,
                        success: false,
                        error: false,
                        errors: {}
                    }
                }
            )

        case EMBED_DELETE_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    delete: {
                        cameraId: state.delete.cameraId,
                        embedId: state.delete.embedId,
                        processing: false,
                        success: false,
                        error: true,
                        errors: action.errors
                    }
                }
            )

        case EMBED_DELETE_RESPONSE_SUCCESS:
            let deleteEmbedList = state.list.embeds
            let deleteIndex = null
            for (var j = 0; j < deleteEmbedList.length; j++) {
                if (deleteEmbedList[j]['id'] === action.embedId) {
                    deleteIndex = j
                    break
                  }
            }
            if (deleteIndex !== null) {
                deleteEmbedList.splice(deleteIndex, 1);
            }
            return Object.assign(
                {},
                state,
                {
                    delete: {
                        cameraId: state.delete.cameraId,
                        embedId: state.delete.embedId,
                        processing: false,
                        success: true,
                        error: false,
                        errors: {}
                    },
                    list: {
                        ...state.list,
                        embeds: deleteEmbedList
                    }
                }
            )

        case EMBED_RESET:
            return initialState.embed

        case EMBED_CREATE_RESET:
            return Object.assign(
                {},
                state,
                {
                    create: initialState.embed.create
                }
            )
        case EMBED_UPDATE_RESET:
            return Object.assign(
                {},
                state,
                {
                    update: initialState.embed.update
                }
            )
        default:
            return state
    }
}
