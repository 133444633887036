import * as types from './actionTypes'
import { API_BASE_URL }  from '../config'
import { fetchWithToken } from '../util/accessToken'
import { notAuthorisedResponse } from './authActions'

export function projectListResponse(payload, currentPage, filtered, sortColumn, sortAscending, searchTerm) {
    if (payload.totalItems) {
        return {
            type: types.PROJECT_LIST_RESPONSE,
            currentPage: currentPage,
            totalRows: payload.totalItems,
            tableData: payload.projects,
            filtered: filtered,
            sortColumn: sortColumn,
            sortAscending: sortAscending,
            searchTerm: searchTerm
        }
    } else {
        return {
            type: types.PROJECT_LIST_RESPONSE,
            totalRows: 0,
            tableData: [],
            filtered: filtered,
            searchTerm: searchTerm
        }
    }
}

export function projectListRequest(pageSize, currentPage, sortColumn, sortAscending, searchTerm, clientId) {
    return (dispatch, getState) => {
        // Set loading state for fidget spinners
        dispatch( { type: types.PROJECT_LIST_REQUEST, projects: { loading: true } })

        // Build query string from paramters
        let pagination = `pageSize=${pageSize}&currentPage=${currentPage}`
        let filtering = (searchTerm === undefined || searchTerm == null)  ? "" : `&filter=${searchTerm}`
        let sorting = sortColumn === undefined ? "" : `&orderBy=${sortColumn}&ascending=${sortAscending}`

        // Switch URL depending on if we're getting projects from all clients or
        // just one client
        let url = null
        if (clientId === undefined) {
            // all projects
            url = `${API_BASE_URL}/api/projects?${pagination}${sorting}${filtering}`
        } else {
            url = `${API_BASE_URL}/api/clients/${clientId}/projects?${pagination}${sorting}${filtering}`
        }

        // Perfrom request
        return fetchWithToken(
            url, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (response.status === 401) {
                dispatch(notAuthorisedResponse())
            } else {
                return response.json()
            }
        })
        .then((payload) => {
            let filtered = searchTerm === undefined ? false : true
            dispatch(projectListResponse(payload, currentPage, filtered, sortColumn, sortAscending, searchTerm))
        })
        .catch((response) => {
            let filtered = searchTerm === null ? false : true
            dispatch(projectListErrors(filtered))
        })
    };
}

export function projectListFilterBy(searchTerm) {
    return { type: types.PROJECT_LIST_FILTER_BY, searchTerm: searchTerm }
}

export function projectListErrors(filtered) {
    return { type: types.PROJECT_LIST_ERRORS, filtered: filtered}
}

export function projectListReset() {
    return dispatch => {
        return new Promise(function(resolve, reject) {
            dispatch({ type: types.PROJECT_LIST_RESET })
            resolve()
        })
    }
}

export function usersWithProjectAccess(projectId, pageSize, currentPage) {
    return (dispatch, getState) => {
        dispatch({
            type: types.USERS_WITH_PROJECT_ACCESS_REQUEST
        })

        // Build query string from paramters
        let pagination = `pageSize=${pageSize}&currentPage=${currentPage}`

        const url = `${API_BASE_URL}/api/projects/${projectId}/users-with-access?${pagination}`
        return fetchWithToken(
            url, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            },
            dispatch,
            getState
        )
        .then(response => {
            if (response.status === 401) {
                dispatch(notAuthorisedResponse())
            } else {
                return response.json().then(data => {
                    if ('error' in data) {
                        throw new Error(data['error'])
                    }
                    dispatch({
                        type: types.USERS_WITH_PROJECT_ACCESS_SUCCESS,
                        users: data.users,
                        totalRows: data.totalItems,
                        currentPage: data.currentPage,
                        exportData: data.exportData
                    })
                })
                .catch((err) => {
                    dispatch({
                        type: types.USERS_WITH_PROJECT_ACCESS_ERROR,
                        error: err.message
                    })
                })
            }
        })
        .catch((err) => {
            dispatch({
                type: types.USERS_WITH_PROJECT_ACCESS_ERROR,
                error: err.message
            })
        })
    }
}
