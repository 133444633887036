import React, { Component } from 'react'
import { Table, Button, Icon, Grid, Message, Popup } from 'semantic-ui-react'

export default class ProjectCameras extends Component {

    getProjectCameras = () => {
        return [...this.props.deployedCameras, ...this.props.add_cameras]
            .filter((camera) => {
                return this.props.remove_cameras.findIndex((c) => {
                    return c.id === camera.id
                }) === -1
            })
            .sort((a, b) => {

                const aWeight = this.props.camera_weights.find((c) => {
                    return a.id === c.id
                })

                const bWeight = this.props.camera_weights.find((c) => {
                    return b.id === c.id
                })

                const a2 = (aWeight) ? aWeight : a
                const b2 = (bWeight) ? bWeight : b

                if (a2.default_weight < b2.default_weight) {
                    return -1
                }
                if (a2.default_weight > b2.default_weight) {
                    return 1
                }
                return 0
            })
    }

    onAddCamera = (camera) => {
        let cameras = this.props.add_cameras.slice()
        // Let's ensure it isn't already in the array or an existing project camera
        if (cameras.findIndex((c) => {
            return c.id === camera.id
        }) === -1 && this.props.deployedCameras.findIndex((c) => {
                return c.id === camera.id
            }) === -1) {
            cameras.unshift(camera)
            this.props.onAddCameras(cameras)

            // Let's also update the default_weights in order to ensure it appears at the bottom
            cameras = this.getProjectCameras()
            cameras.push(camera)

            const weights = cameras.map((camera, idx) => {
                return Object.assign(
                    {},
                    camera,
                    {
                        default_weight: (1 + idx) * 10
                    }
                )
            })
            // Let's now update the weights...
            this.props.onCameraWeights(weights)
        }

        // Let's tidy up by removing from
        let camerasToBeRemoved = this.props.remove_cameras.slice()
        const key = camerasToBeRemoved.findIndex((c) => { return c.id === camera.id })
        if (key !== -1) {
            camerasToBeRemoved.splice(key, 1)
            this.props.onRemoveCameras(camerasToBeRemoved)
        }
    }

    onRemoveCamera = (camera) => {
        let cameras = this.props.remove_cameras.slice()
        // Let's ensure it isn't already in the array
        if (cameras.findIndex((c) => {
            return c.id === camera.id
        }) === -1  && this.props.availableCameras.findIndex((c) => {
            return c.id === camera.id
        }) === -1) {
            cameras.unshift(camera)
            this.props.onRemoveCameras(cameras)
        }
        // Let's tidy up by removing from
        let camerasToBeAdded = this.props.add_cameras.slice()
        const key = camerasToBeAdded.findIndex((c) => { return c.id === camera.id })
        if (key !== -1) {
            camerasToBeAdded.splice(key, 1)
            this.props.onAddCameras(camerasToBeAdded)
        }
        // Let's also remove from camera_weights
        cameras = this.props.camera_weights.slice()
        const key2 = cameras.findIndex((c) => {
            return c.id === camera.id
        })
        if (key2 !== -1) {
            cameras.splice(key2, 1)
            const weights = cameras.map((camera, idx) => {
                return Object.assign(
                    {},
                    camera,
                    {
                        default_weight: (1 + idx) * 10
                    }
                )
            })
            // Let's now update the weights...
            this.props.onCameraWeights(weights)
        }

    }

    onUp = (camera) => {
        // So, we need to get the list as-is
        let cameras = this.getProjectCameras()
        // Let's assume this is the correct order and find position of camera
        const key = cameras.findIndex((c) => {
            return c.id === camera.id
        })

        if (key !== -1) {
            const from = key
            const to = from - 1
            cameras.splice(to, 0, cameras.splice(from, 1)[0]);
            // At this point, we have swapped the two cameras...but we need to update all the weights too
            const weights = cameras.map((camera, idx) => {
                return Object.assign(
                    {},
                    camera,
                    {
                        default_weight: (1 + idx) * 10
                    }
                )
            })
            // Let's now update the weights...
            this.props.onCameraWeights(weights)
        }
    }

    onDown = (camera) => {
        // So, we need to get the list as-is
        let cameras = this.getProjectCameras()
        // Let's assume this is the correct order and find position of camera
        const key = cameras.findIndex((c) => {
            return c.id === camera.id
        })

        if (key !== -1) {
            const from = key
            const to = from + 1
            cameras.splice(to, 0, cameras.splice(from, 1)[0]);
            // At this point, we have swapped the two cameras...but we need to update all the weights too
            const weights = cameras.map((camera, idx) => {
                return Object.assign(
                    {},
                    camera,
                    {
                        default_weight: (1 + idx) * 10
                    }
                )
            })
            // Let's now update the weights...
            this.props.onCameraWeights(weights)
        }
    }

    render = () => {

        // Let's derive the 'current' list by concatenating existing cameras and ones marked for adding
        // and then filtering out ones marked for removal
        let projectCameras = this.getProjectCameras()

        // Let's derive the 'current' available list by concatenating available cameras with ones marked for removal
        // and then filtering out ones marked for adding
        let availableCameras = [...this.props.availableCameras, ...this.props.remove_cameras]
            .filter((camera) => {
                return this.props.add_cameras.findIndex((c) => {
                    return c.id === camera.id
                }) === -1
            })
            // Let's finally sort
            .sort((a, b) => {
                if (a.default_weight < b.default_weight) {
                    return -1
                }
                if (a.default_weight > b.default_weight) {
                    return 1
                }
                return 0
            })

            return (
                <>
                    <Grid>
                        <Grid.Column>
                            <Grid.Row className='no-padding-bottom'>
                                <Message
                                    size='small'
                                    color='yellow'
                                    header='Project Cameras'
                                    content={<><p>Please select any available cameras you would like to add to this project.</p><p><strong>If a camera is not showing in the Available Cameras list please remove it from its respective project.</strong></p></>}
                                />
                            </Grid.Row>
                        </Grid.Column>
                    </Grid>

                    <Grid columns='2'>
                        <Grid.Row>
                            <Grid.Column>
                                <h4>Project Cameras</h4>
                                <Table celled>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Camera</Table.HeaderCell>
                                            <Table.HeaderCell>Status</Table.HeaderCell>
                                            <Table.HeaderCell>Type</Table.HeaderCell>
                                            <Table.HeaderCell>Actions</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>

                                        {

                                            projectCameras.map((camera, idx) => {

                                            let btnUp = null
                                            let btnDown = null

                                            if (idx === 0) {
                                                btnDown = <Popup content='Decrease order position' trigger={<Button icon onClick={() => {
                                                    this.onDown(camera)}}><Icon name='arrow down' /></Button>
                                                } />
                                            } else if (idx === projectCameras.length - 1) {
                                                btnUp = <Popup content='Increase order position' trigger={<Button icon onClick={() => { this.onUp(camera) }} ><Icon name='arrow up' /></Button>} />
                                            } else {
                                                btnUp =  <Popup content='Increase order position' trigger={<Button icon onClick={() => { this.onUp(camera) }}><Icon name='arrow up' /></Button>} />
                                                btnDown =  <Popup content='Decrease order position' trigger={<Button icon onClick={() => { this.onDown(camera) }}><Icon name='arrow down' /></Button>} />
                                            }

                                                return (
                                                    <Table.Row key={idx}>
                                                        <Table.Cell>{camera.text}</Table.Cell>
                                                        <Table.Cell>{camera.status}</Table.Cell>
                                                        <Table.Cell>{camera.type}</Table.Cell>
                                                        <Table.Cell>
                                                            <Popup content='Remove from this project' trigger={<Button icon onClick={
                                                                () => { this.onRemoveCamera(camera) }
                                                                }>
                                                                    <Icon name='trash' />
                                                                </Button>
                                                            } />
                                                            {btnUp}
                                                            {btnDown}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )
                                            })
                                    }

                                    </Table.Body>
                                </Table>
                            </Grid.Column>
                            <Grid.Column>
                                <h4>Available cameras</h4>
                                <Table celled>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Camera</Table.HeaderCell>
                                            <Table.HeaderCell>Status</Table.HeaderCell>
                                            <Table.HeaderCell>Type</Table.HeaderCell>
                                            <Table.HeaderCell>Actions</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                    {(availableCameras.length > 0)? <>
                                        {
                                            availableCameras.map((camera, idx) => {

                                            return (
                                                <Table.Row key={idx}>
                                                    <Table.Cell>{camera.text}</Table.Cell>
                                                    <Table.Cell>{camera.status}</Table.Cell>
                                                    <Table.Cell>{camera.type}</Table.Cell>
                                                    <Table.Cell>
                                                    <Popup content='Add to this project' trigger={<Button icon onClick={() => this.onAddCamera(camera)}><Icon name='plus circle' /></Button>} />
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        })}
                                    </> : <Table.Row>
                                            <Table.Cell colSpan="4">All of your cameras are assigned to projects</Table.Cell>
                                        </Table.Row>
                                    }

                                    </Table.Body>
                                </Table>
                                
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </>
        )
    }
}
