import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types'
import { Card, Icon, Popup, Label, Button, Grid } from 'semantic-ui-react'
import Photo from '../Photo'
import { isPermitted } from "../../util/acl"
import { Link } from 'react-router-dom'
import { LOBSTER_POT_4 } from '../../config'
import ConditionalWrapper from '../../util/ConditionalWrapper';

/**
 * Class representing a card displaying a single camera
 *
 * Used in the camera grid
 */

const CameraV4 = (props) => {
    const [info, setInfo] = useState(false)

    useEffect(() => {
        if (info === true) {
            props.onFetchCameraInfo(props.camera.id)
        }
    }, [info])

    const getEditCameraLink = (item) => {
        let editCameraLink = null
        if (isPermitted(props.auth.user, 'put-cameras-cameras')) {
            editCameraLink = (
                <Button
                    as={Link}
                    to={'#'}
                    onClick={props.onOpenModal}
                    icon='pencil'
                    basic
                    color='blue'
                    compact
                    size='small'
                    content="Edit"
                ></Button>
            )
        }

        return editCameraLink
    }

    const getCameraControlLink = (item) => {
        if (item.type === 'Virtual' || item.type === 'Windows') {
            return null
        }

        let cameraControlLink = null
        if (isPermitted(props.auth.user, 'get-cameracontrol-status')) {
            if (item.pot_version === LOBSTER_POT_4) {
                cameraControlLink = (
                    <Button
                        as={Link}
                        to={`/cameras/${item.id}/camera-control-v4`}
                        icon='cog'
                        color='blue'
                        compact
                        size='small'
                        content="Settings"
                        target='_blank'
                        style={{marginTop: '15px'}}
                    ></Button>
                )
            }
            else {
                cameraControlLink = (
                    <Button
                        as={Link}
                        to={`/cameras/${item.id}/camera-control`}
                        icon='cog'
                        basic
                        color='blue'
                        compact
                        size='small'
                        content="Settings"
                        target='_blank'
                        style={{marginTop: '15px'}}
                    ></Button>
                )
            }

        }
        return cameraControlLink
    }

    const getSetupModeInfo = () => {
        if (props.camera.pot_version !== LOBSTER_POT_4) {
            return null
        }

        let cameraSetupMode = null

        switch(props.camera.setup_mode_state) {
            case 'On':
                cameraSetupMode = {color: 'green', name:'Camera On'}
                break;
            case 'OffRequested':
                cameraSetupMode = {color: 'orange', name:'Turning Off'}
                break;
            case 'OnRequested':
                cameraSetupMode = {color: 'yellow', name:'Turning On'}
                break;
            default:
                cameraSetupMode = null
        }

        if (cameraSetupMode === null) {
            return null
        }

        return (
            <div>
                <Label style={{marginTop: '15px'}} color={cameraSetupMode.color}>{cameraSetupMode.name}</Label>
            </div>
        )
    }

    const getPreloader = () => {
        return (
            <div className='camera-info-overlay'>
                <div className='centered-container'>
                    <p className='centered'>Loading...</p>
                </div>
            </div>
        )
    }

    const getStatusColor = () => {
        if (props.vanity || props.camera.type === 'Virtual') {
            return null
        }
        if (!props.camera.monitoring) {
            return 'black'
        }
        switch (props.camera.monitoring.errorLevel) {
            case 0:
                return null
            case 1:
                return 'yellow'
            case 2:
                return 'red'
            default:
                return null
        }
    }

    const getLabel = () => {
        if (props.vanity || props.camera.type === 'Virtual') {
            return null
        }
        if (!props.camera.monitoring) {
            return {
                content: 'No response from monitoring',
                color: 'black',
                ribbon: true
            }
        }
        if (props.camera.monitoring.errorLevel === 0) {
            return null
        } else {
            return {
                content: props.camera.monitoring.message,
                color: getStatusColor(),
                ribbon: true
            }
        }
    }

    const toggleOverlay = (e) => {
        // e.preventDefault()
        // this.setState(prevState => {
        //     return {
        //          ...prevState,
        //          overlay: !prevState.overlay
        //     }
        //  })

        e.preventDefault()
        const parent = e.target.parentNode.parentNode.parentNode;
        const overlay = parent.querySelector('.grid-item__image-overlay')
        const allOverlays = document.querySelectorAll('.grid-item__image-overlay')
        for (const item of allOverlays) {
            item.classList.remove('active');
        }
        overlay.classList.add('active')
    }

    // If it's the vanity grid, hide any non-functioning cameras
    if (props.vanity && props.camera.monitoring) {
        if (props.camera.monitoring.errorLevel > 0) {
            return null
        }
    }

    if (props.antivanity && props.camera.monitoring) {
        if (props.camera.monitoring.errorLevel < 1 || props.camera.monitoring.level < 1) {
            return null
        }
    }

    let cameraControlLink = getCameraControlLink(props.camera)
    let editCameraLink = getEditCameraLink(props.camera)
    let gridItemInfo = null

    if (props.info) {
        // Okay, so we need to determine if loading, errors, success...
        if (!props.info || (props.info && props.info.loading)) {
            gridItemInfo = getPreloader()
        } else if (props.info && props.info.success && props.camera.pot_version === LOBSTER_POT_4) {
            let cameraState = null
            if (props.info.details.state !== undefined) {
                cameraState = props.info.details.state
            }
            gridItemInfo = <p>{cameraState}</p>
        }
    }

    // Format card header
    let projectName, cameraName;

    if (props.camera && props.camera.projects_name) {
        projectName = props.camera.projects_name
    } else {
        projectName = 'Not assigned to a project'
    }

    if (props.camera && props.camera.custom_name) {
        cameraName = props.camera.custom_name
    } else {
        cameraName = props.camera.pot_id
    }

    let gridItemHeader = <div className="project__header">
        <div className="project__header--left">
            <p className="project__title">{cameraName}</p>
            <p className='project__location'>{projectName}</p>
            {(props.camera.latitude !== null && props.camera.longitude !== null)?
                <p style={{marginTop: '-1rem'}}><small>Lat: <strong>{props.camera.latitude}</strong>  Long: <strong>{props.camera.longitude}</strong></small></p>
            :null}
        </div>
        <div className="project__header--right">
            {editCameraLink}
        </div>
    </div>

    const statusColor = getStatusColor()

    return (
        <Grid.Column key={`${props.index}-${props.cameraIndex}`}>
            <div
                key={`camera_${props.camera.id}`}
                className={`card-${statusColor} card--camera-grid`}
            >
                <Card.Content>
                    <Card.Header className={'camera-grid__camera-header'}>
                        {gridItemHeader}
                        {gridItemInfo}
                    </Card.Header>
                    <ConditionalWrapper
                        condition={isPermitted(props.auth.user, 'get-authas-camera')}
                        wrapper={children => <a href="#" onClick={(e) => toggleOverlay(e)}>{children}</a>}
                    >
                        <div className="grid-item__image-wrap">
                            <Photo
                                src={props.camera.thumbnail_url}
                                label={getLabel()}
                                cameraId={props.camera.id}
                                authAsCamera={props.authAsCamera}
                            />
                            <div className="grid-item__image-overlay">
                                <button onClick={() => { props.authAsCamera(props.camera.id)}}>Launch <br />Viewer</button>
                            </div>
                        </div>
                    </ConditionalWrapper>

                    <Card.Meta style={{display: 'flex', justifyContent: 'space-between'}}>
                        {cameraControlLink}
                        {getSetupModeInfo()}
                    </Card.Meta>
                </Card.Content>
            </div>
        </Grid.Column>
    )
}

CameraV4.propTypes = {
    camera: PropTypes.shape({
        id: PropTypes.number.isRequired,
        pot_id: PropTypes.string.isRequired,
        clients_name: PropTypes.string,
        projects_name: PropTypes.string,
        projects_location: PropTypes.string
    }).isRequired,
    onOpenModal: PropTypes.func.isRequired,
    authAsCamera: PropTypes.func.isRequired,
    info: PropTypes.object,
    cameraIndex: PropTypes.number
}

export default CameraV4
