import { isPermitted } from "../../../util/acl"
import { getErrorMessage } from '../../../util/errors'
import UpdateUserPublicInputs from "./UpdateUserPublicInputs";
import UpdateUserPrivateInputs from "./UpdateUserPrivateInputs";
import {Button, Message, Form, Select, Checkbox,
    Grid, Popup, Icon} from 'semantic-ui-react'
import TemperatureUnit from "../checkboxes/TemperatureUnit";
import WindSpeedUnit from "../checkboxes/WindSpeedUnit";
import ShowBimModelType from '../checkboxes/ShowBimModelType'
import { USER_STATUS_SUSPENDED } from "../../../util/user"
import { Link } from 'react-router-dom'
import debounce from 'lodash/debounce'

const UpdateUserForm = (props) => {

    const formatSettingsMarkup = (key, val, settings) => {
        let returnContent

        switch (settings) {
            case 'weather':
                returnContent = {
                    settings: {
                        weather: {
                            [key]: val
                        }
                    }
                }
                break;

            case 'settings':
                returnContent = {
                    settings: {
                        [key]: val
                    }
                }
                break;
        
            default:
                returnContent = {[key]: val}
                break;
        }

        return returnContent
    }

    const onFormChange = (event, key, value, settings) => {
        let markup = formatSettingsMarkup(key, value, settings)
        props.onUpdateLocalUserState(markup)
    }

    // Could combine the 3 events as the only difference is the val const
    // However debounce functionality only needed on input so going to leave seperate

    const onInputChange = debounce((event, key, settings) => {
        const val = event.target.value
        onFormChange(event, key, val, settings)
    }, 500)

    const onSelectChange = (event, value, key) => {
        const val = value.value
        onFormChange(event, key, val)
    }

    const onCheckboxChange = (event, value, key, settings = false) => {
        const val = value.checked
        onFormChange(event, key, val, settings)
    }

    const isReady = () => {
        return props.user.username
    }

    const suspendButton = (
        isPermitted(props.auth.user, 'post-suspenduser-index')
        && props.user.status !== USER_STATUS_SUSPENDED
    ) ?
        <Button
            color='red'
            content='Suspend User'
            icon='pause circle outline'
            as={Link}
            to={`/clients/${props.clientId}/users/${props.userId}/suspend`}
        /> : null

    const deleteButton = (
        isPermitted(props.auth.user, 'delete-users-users')
    ) ?
        <Button
            color = 'red'
            style = {{marginTop: '1.7em'}}
            content = 'Delete User'
            icon = 'trash'
            as = {Link}
            to={`/clients/${props.clientId}/users/${props.userId}/delete`}
        /> : null

    const resetMfaButton = (props.user.mfa_verified &&
        props.auth.user_mfa_reset &&
        props.auth.user_mfa_reset !== 'success'
    )?
        <Button
            color='blue'
            content='Reset MFA'
            icon='lock'
            onClick={resetMfa}
        ></Button> : null;

    const resetMfa = () => {
        props.resetMfa(props.clientId, props.userId)
    }

    if (!isReady()) {
        return (<div>
            <div>
                <div className='animated-blank-line'></div>
                <div className='animated-blank-line'></div>
                <div className='animated-blank-line'></div>
                <div className='animated-blank-line'></div>
                <div className='animated-blank-line'></div>
                <div className='animated-blank-line'></div>
                <div className='animated-blank-line'></div>
                <div className='animated-blank-block-75'></div>
                <div className='animated-blank-line'></div>
                <div className='animated-blank-block-75'></div>
                <div className='animated-blank-line'></div>
                <div className='animated-blank-block-75'></div>
                <div className='animated-blank-line'></div>
                <div className='animated-blank-line'></div>
                <div className='animated-blank-line'></div>
            </div>
        </div>)
    } else {
        let isLobsterUser = false
        if (props.auth && props.auth.user && props.auth.user.roles_id !== 4 && props.auth.user.roles_id !== 5) {
            isLobsterUser = true;
        }

        //let statuses = this.props.statuses.slice()
        // Handles the case for a Suspended user
        // if (!statuses.find(status => status.name === this.props.user.status)) {
        //     statuses.push(
        //         {
        //             value: this.props.user.status,
        //             text: this.props.user.status,
        //             key: this.props.user.status.length
        //         }
        //     )
        // }

        return (
            <div>
                <Form size='large'>
                    <Grid columns='2' stackable>
                        <Grid.Row>
                            <Grid.Column>
                                <h3>User Account Details</h3>
                                {props.user.loginless ?
                                    <UpdateUserPublicInputs
                                        user={props.user}
                                        errors={props.errors}
                                        onInputChange={onInputChange}
                                    />
                                :
                                    <UpdateUserPrivateInputs
                                        user={props.user}
                                        errors={props.errors}
                                        onInputChange={onInputChange}
                                    />
                                }

                                <Form.Select
                                    options={props.clients}
                                    placeholder='Client'
                                    label='Client'
                                    onChange={(e, value) => onSelectChange(e, value, 'clients_id')}
                                    error={props.errors.clients_id}
                                    defaultValue={props.user.clients_id}
                                    search
                                />
                                <Message
                                    error={true}
                                    visible={props.errors.clients_id}
                                    header={props.errors.clients_id}
                                    content={getErrorMessage(props.errors, 'clients_id')}
                                />
                                <Grid columns='2' stackable>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Form.Group inline={false} className="form-group--block">
                                                <Form.Field
                                                    control={Select}
                                                    options={props.statuses}
                                                    placeholder='Status'
                                                    label='Status'
                                                    onChange={(e, value) => onSelectChange(e, value, 'status')}
                                                    error={props.errors.status}
                                                    defaultValue={props.user.status}

                                                />
                                                <Form.Field style={{margin: '1.5rem 0'}}>
                                                    {resetMfaButton}
                                                </Form.Field>
                                            </Form.Group>
                                            <Message
                                                error={true}
                                                visible={props.errors.status}
                                                header={props.errors.status}
                                                content={getErrorMessage(props.errors, 'status')}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Form.Group>
                                                <Form.Field
                                                    control={Select}
                                                    options={props.roles}
                                                    placeholder='User Type'
                                                    label='User Type'
                                                    onChange={(e, value) => onSelectChange(e, value, 'roles_id')}
                                                    error={props.errors.roles_id}
                                                    defaultValue={props.user.roles_id}
                                                    disabled={!isLobsterUser}

                                                />
                                            </Form.Group>
                                            <Message
                                                error={true}
                                                visible={props.errors.roles_id}
                                                header={props.errors.roles_id}
                                                content={getErrorMessage(props.errors, 'roles_id')}
                                            />
                                            <Form.Field style={{margin: '1.5rem 0'}}>
                                                {suspendButton}
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>

                                <Form.TextArea
                                    label = 'Comments'
                                    placeholder='Comments here'
                                    onChange={(e) => onInputChange(e, 'comment')}
                                    error={(props.errors.comment) ? true : false}
                                    defaultValue={props.user.comment}
                                />

                                {(isLobsterUser) ?
                                    <>
                                        <h3>Lockdown</h3>
                                        <p>If the account is in lockdown mode, the user will not be able to log in.</p>
                                        <Checkbox
                                            label={props.user.lockdown === 'ON' ? 'Enabled' : 'Disabled'}
                                            defaultChecked={props.user.lockdown === 'ON'}
                                            onChange={(e, value) => onCheckboxChange(e, value, 'lockdown')}
                                            data-testid='update-user--lockdown'
                                            toggle
                                        />
                                    </>
                                : null }
                            </Grid.Column>
                            <Grid.Column>
                                <h3>Overrides</h3>
                                <p>Time delay (in hours)</p>
                                <Form.Group>
                                    <Form.Input
                                        type="number"
                                        placeholder='Time delay'
                                        onChange={(e) => onInputChange(e, 'delay')}
                                        error={(props.errors.delay) ? true : false}
                                        defaultValue={props.user.delay}
                                    />
                                </Form.Group>
                                <Message
                                    error={true}
                                    visible={(props.errors.delay) ? true : false}
                                    header={'Error'}
                                    content={getErrorMessage(props.errors, 'delay')}
                                />

                                
                                <p>Limit access to historical images (in days)</p>
                                <Form.Group>
                                    <Form.Input
                                        type="number"
                                        placeholder='Limit access (in days)'
                                        onChange={(e) => onInputChange(e, 'limit_history')}
                                        error={(props.errors.limit_history) ? true : false}
                                        defaultValue={props.user.limit_history}
                                    />
                                </Form.Group>
                                <Message
                                    error={true}
                                    visible={(props.errors.limit_history) ? true : false}
                                    header={'Error'}
                                    content={getErrorMessage(props.errors, 'limit_history')}
                                />

                                {(isLobsterUser) ?
                                    <>
                                        <h3>Kiosk Mode</h3>
                                        <p>Disables logout and the downloading of images and videos</p>
                                        <Checkbox
                                            label={props.user.settings.kiosk_mode_enabled ? 'Enabled' :'Disabled'}
                                            checked={props.user.settings.kiosk_mode_enabled}
                                            onChange={(e, value) => onCheckboxChange(e, value, 'kiosk_mode_enabled', 'settings')}
                                            toggle />
                                    </> : null
                                }

                                {(props.auth.user && props.auth.user.roles_id !== 5)?
                                <>
                                    <h3>Show Blurred Images?</h3>
                                    <p>Show the blurred version of images to this user if set against camera</p>
                                    <Checkbox
                                        label={props.user.processed ? 'Enabled' : 'Disabled'}
                                        checked={props.user.processed}
                                        onChange={(e, value) => onCheckboxChange(e, value, 'processed')}
                                        toggle
                                    />
                                </> : null}

                                {(isLobsterUser && (props.auth.user && props.auth.user.roles_id !== 5)) ?
                                    <>
                                        <h3>Weather</h3>
                                        <Checkbox
                                            label={props.user.settings.weather.enabled ? 'Enabled (Click to disable weather settings)' : 'Disabled (Click to enable weather settings)'}
                                            checked={props.user.settings.weather.enabled}
                                            onChange={(e, value) => onCheckboxChange(e, value, 'enabled', 'weather')}
                                            toggle />
                                        <p></p><p></p>
                                        <Grid columns='2' stackable>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <TemperatureUnit
                                                        checked={props.user.settings && props.user.settings.weather.temperature_unit}
                                                        disabled={props.user.settings && !props.user.settings.weather.enabled}
                                                        onChange={(e) => {onInputChange(e, 'temperature_unit', 'weather')}}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <WindSpeedUnit
                                                        checked={props.user.settings && props.user.settings.weather.wind_speed_unit}
                                                        disabled={props.user.settings && !props.user.settings.weather.enabled}
                                                        onChange={(e) => {onInputChange(e, 'wind_speed_unit', 'weather')}}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </> : null
                                }
                                
                                <h3>Permissions</h3>
                                <Grid columns='2' stackable>
                                    <Grid.Row>
                                        <Grid.Column>
                                            {(isLobsterUser) ?
                                                <>
                                                    <p className="permissions__title">Show Navigation</p>
                                                    <Checkbox
                                                        label={props.user.settings && props.user.settings.show_navigation_permission === 'YES'? 'Enabled' : 'Disabled'}
                                                        checked={props.user.settings && props.user.settings.show_navigation_permission === 'YES'}
                                                        onChange={(e, value) => onCheckboxChange(e, value, 'show_navigation_permission', 'settings')}
                                                        toggle />

                                                    <p className="permissions__title">Show Calendar</p>
                                                    <Checkbox
                                                        label={props.user.settings && props.user.settings.permitted_to_view_calendar? 'Enabled' : 'Disabled'}
                                                        checked={props.user.settings && props.user.settings.permitted_to_view_calendar}
                                                        onChange={(e, value) => onCheckboxChange(e, value, 'permitted_to_view_calendar', 'settings')}
                                                        toggle />
                                                </> : null
                                            }

                                            <p className="permissions__title">
                                                Image Download
                                                <Popup trigger={<Icon name="question circle outline" style={{marginLeft: '5px'}} />}>
                                                    <p>Allow user to download images via a Download button</p>
                                                </Popup>
                                            </p>
                                            <Checkbox
                                                label={props.user.settings && props.user.settings.permitted_to_save ? 'Enabled' : 'Disabled'}
                                                checked={props.user.settings && props.user.settings.permitted_to_save}
                                                onChange={(e, value) => onCheckboxChange(e, value, 'permitted_to_save', 'settings')}
                                                data-testid='update-user--download'
                                                toggle />

                                            {(isLobsterUser) ?
                                                <>
                                                    <p className="permissions__title">Allow Zooming</p>
                                                    <Checkbox
                                                        label={props.user.settings && props.user.settings.permitted_to_zoom ? 'Enabled' : 'Disabled'}
                                                        checked={props.user.settings && props.user.settings.permitted_to_zoom}
                                                        onChange={(e, value) => onCheckboxChange(e, value, 'permitted_to_zoom', 'settings')}
                                                        toggle />

                                                    <p className="permissions__title">Show Thumbnails</p>
                                                    <Checkbox
                                                        label={props.user.settings && props.user.settings.show_thumbnails_permission? 'Enabled' : 'Disabled'}
                                                        checked={props.user.settings && props.user.settings.show_thumbnails_permission}
                                                        onChange={(e, value) => onCheckboxChange(e, value, 'show_thumbnails_permission', 'settings')}
                                                        toggle />
                                                </> : null
                                            }
                                        </Grid.Column>
                                        <Grid.Column>
                                            {(isLobsterUser) ?
                                                <>
                                                    <p className="permissions__title">Enable Auto Refresh</p>
                                                    <Checkbox
                                                        label={props.user.settings && props.user.settings.permitted_to_auto_refresh ? 'Enabled' : 'Disabled'}
                                                        checked={props.user.settings && props.user.settings.permitted_to_auto_refresh}
                                                        onChange={(e, value) => onCheckboxChange(e, value, 'show_thumbnails_permission', 'settings')}
                                                        toggle />

                                                    <p className="permissions__title">View Timelapse Video</p>
                                                    <Checkbox
                                                        label={props.user.settings && props.user.settings.permitted_to_view_timelapse ? 'Enabled' : 'Disabled'}
                                                        checked={props.user.settings && props.user.settings.permitted_to_view_timelapse}
                                                        onChange={(e, value) => onCheckboxChange(e, value, 'permitted_to_view_timelapse', 'settings')}
                                                        toggle />

                                                    <p className="permissions__title">Hide Virtual Cameras</p>
                                                    <Checkbox
                                                        label={props.user.settings && props.user.settings.hide_virtual_cameras ? 'Enabled' : 'Disabled'}
                                                        checked={props.user.settings && props.user.settings.hide_virtual_cameras}
                                                        onChange={(e, value) => onCheckboxChange(e, value, 'hide_virtual_cameras', 'settings')}
                                                        toggle />
                                                    <p></p>
                                                </> : null
                                            }

                                            <p className="permissions__title">
                                                Make A Film
                                                <Popup trigger={<Icon name="question circle outline" style={{marginLeft: '5px'}} />}>
                                                    <p>Allow user to create and download timelaspe film</p>
                                                </Popup>
                                            </p>
                                            <Checkbox
                                                label={props.user.video_editor ? 'Enabled' : 'Disabled'}
                                                checked={props.user.video_editor}
                                                onChange={(e, value) => onCheckboxChange(e, value, 'video_editor')}
                                                toggle />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                {(isLobsterUser) ?
                                    <>
                                        <ShowBimModelType
                                            checked={props.user.settings && props.user.settings.show_bim_model_type}
                                            onChange={(e) => {onInputChange(e, 'show_bim_model_type', 'settings')}}
                                            error={(props.errors.settings && props.errors.settings.missingShowBimTypesSetting)}
                                        />
                                        <p></p>

                                        <h3>Public API</h3>
                                        <p>Allow API access</p>
                                        <Checkbox
                                            label={props.user.api ? 'Enabled' : 'Disabled'}
                                            checked={props.user.api}
                                            onChange={(e, value) => onCheckboxChange(e, value, 'api')}
                                            toggle />
                                        <br />
                                    </> : null
                                }
                                {deleteButton}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </div>
        )
    }
}

export default UpdateUserForm;