import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon, Popup, Loader, Dimmer } from 'semantic-ui-react'

class WifiStatus extends Component {

    getWifiStatusClass = () => {
        return (this.props.status === 'On') ? 'active' : 'inactive'
    }

    getLegend = () => {
        return (this.props.legend) ?
            <div className='legend'>
                <span>
                    <Icon size='small' name='wifi' color={this.props.color} />
                    <span className="text">WiFi On</span>
                </span>
                <span>
                    <span className='inactive'>
                        <Icon size='small' name='wifi' color={this.props.color} />
                    </span>
                    <span className="text">WiFi Off</span>
                </span>
            </div> : null
    }

    getContent = (wifiStatusClass, legend) => {
        return (this.props.loading) ? <Dimmer active inverted><Loader content='Loading' /></Dimmer> :
            <div>
                <div className={`wifi  ${wifiStatusClass}`}>
                    <Popup
                        trigger={<Icon loading={this.props.loading} size={this.props.size} name='wifi' color={this.props.color} />}
                        content={`Wi-Fi Status: ${this.props.status}`}
                        size='mini'
                    />
                </div>
                {legend}
            </div>
    }

    render = () => {
        const content = this.getContent(this.getWifiStatusClass(), this.getLegend())
        return (
            <div className='wifi-status'>
                {content}
            </div>
        )
    }
}

WifiStatus.propTypes = {
    loading: PropTypes.bool.isRequired,
    status: PropTypes.string,
    legend: PropTypes.bool.isRequired,
    size: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
}

export default WifiStatus
