import initialState from './initialState';
import {
    CAMERA_CONTROL_STATUS_REQUEST,
    CAMERA_CONTROL_STATUS_RESPONSE_ERRORS,
    CAMERA_CONTROL_STATUS_RESPONSE_SUCCESS,
    CAMERA_CONTROL_LOG,
    CAMERA_CONTROL_LOG_RESET,
    CAMERA_CONTROL_SETTINGS_REQUEST,
    CAMERA_CONTROL_SETTINGS_RESPONSE_ERRORS,
    CAMERA_CONTROL_SETTINGS_RESPONSE_SUCCESS,
    CAMERA_CONTROL_CAMERA_REQUEST,
    CAMERA_CONTROL_CAMERA_RESPONSE_ERRORS,
    CAMERA_CONTROL_CAMERA_RESPONSE_SUCCESS,
    CAMERA_CONTROL_RESET,
    CAMERA_CONTROL_SHOOT_REQUEST,
    CAMERA_CONTROL_SHOOT_RESPONSE_ERRORS,
    CAMERA_CONTROL_SHOOT_RESPONSE_SUCCESS,
    CAMERA_CONTROL_VERSION,
    CAMERA_CONTROL_EXIF_REQUEST,
    CAMERA_CONTROL_EXIF_RESPONSE_ERRORS,
    CAMERA_CONTROL_EXIF_RESPONSE_SUCCESS,
    CAMERA_CONTROL_PREVIEW_REQUEST,
    CAMERA_CONTROL_PREVIEW_RESPONSE_ERRORS,
    CAMERA_CONTROL_PREVIEW_RESPONSE_SUCCESS,
    CAMERA_CONTROL_LOADING_PREVIEW_IMAGE,
    CAMERA_CONTROL_PREVIEW_QUALITY,
    CAMERA_CONTROL_PREVIEW_VERSION_QUALITY,
    CAMERA_CONTROL_UPDATE_PROPERTIES_REQUEST,
    CAMERA_CONTROL_UPDATE_PROPERTIES_RESPONSE_ERRORS,
    CAMERA_CONTROL_UPDATE_PROPERTIES_RESPONSE_SUCCESS,
    CAMERA_CONTROL_PROPERTIES_REQUEST,
    CAMERA_CONTROL_PROPERTIES_RESPONSE_ERRORS,
    CAMERA_CONTROL_PROPERTIES_RESPONSE_SUCCESS,
    CAMERA_CONTROL_SYSTEM_REQUEST,
    CAMERA_CONTROL_SYSTEM_RESPONSE_ERRORS,
    CAMERA_CONTROL_SYSTEM_RESPONSE_SUCCESS,
    CAMERA_CONTROL_UPDATE_SYSTEM_REQUEST,
    CAMERA_CONTROL_UPDATE_SYSTEM_RESPONSE_ERRORS,
    CAMERA_CONTROL_UPDATE_SYSTEM_RESPONSE_SUCCESS,
    CAMERA_CONTROL_LOGS_REQUEST,
    CAMERA_CONTROL_LOGS_RESPONSE_ERRORS,
    CAMERA_CONTROL_LOGS_RESPONSE_SUCCESS,
    CAMERA_CONTROL_SCHEDULER_START_REQUEST,
    CAMERA_CONTROL_SCHEDULER_START_RESPONSE_ERRORS,
    CAMERA_CONTROL_SCHEDULER_START_RESPONSE_SUCCESS,
    CAMERA_CONTROL_SCHEDULER_STOP_REQUEST,
    CAMERA_CONTROL_SCHEDULER_STOP_RESPONSE_ERRORS,
    CAMERA_CONTROL_SCHEDULER_STOP_RESPONSE_SUCCESS,
    CAMERA_CONTROL_SCHEDULER_RESTART_REQUEST,
    CAMERA_CONTROL_SCHEDULER_RESTART_RESPONSE_ERRORS,
    CAMERA_CONTROL_SCHEDULER_RESTART_RESPONSE_SUCCESS,
    CAMERA_CONTROL_FETCH_APN_REQUEST,
    CAMERA_CONTROL_FETCH_APN_RESPONSE_ERRORS,
    CAMERA_CONTROL_FETCH_APN_RESPONSE_SUCCESS,
    CAMERA_CONTROL_UPDATE_APN_REQUEST,
    CAMERA_CONTROL_UPDATE_APN_RESPONSE_ERRORS,
    CAMERA_CONTROL_UPDATE_APN_RESPONSE_SUCCESS,
    CAMERA_CONTROL_FETCH_WIFI_REQUEST,
    CAMERA_CONTROL_FETCH_WIFI_RESPONSE_ERRORS,
    CAMERA_CONTROL_FETCH_WIFI_RESPONSE_SUCCESS,
    CAMERA_CONTROL_START_WIFI_REQUEST,
    CAMERA_CONTROL_START_WIFI_RESPONSE_ERRORS,
    CAMERA_CONTROL_START_WIFI_RESPONSE_SUCCESS,
    CAMERA_CONTROL_STOP_WIFI_REQUEST,
    CAMERA_CONTROL_STOP_WIFI_RESPONSE_ERRORS,
    CAMERA_CONTROL_STOP_WIFI_RESPONSE_SUCCESS,
    CAMERA_CONTROL_RESTART_WIFI_REQUEST,
    CAMERA_CONTROL_RESTART_WIFI_RESPONSE_ERRORS,
    CAMERA_CONTROL_RESTART_WIFI_RESPONSE_SUCCESS,
    CAMERA_CONTROL_REBOOT_CAMERA_BODY_REQUEST,
    CAMERA_CONTROL_REBOOT_CAMERA_BODY_RESPONSE_ERRORS,
    CAMERA_CONTROL_REBOOT_CAMERA_BODY_RESPONSE_SUCCESS,
    CAMERA_CONTROL_REBOOT_CAMERA_BODY_RESET,
    CAMERA_CONTROL_RUN_TESTS_REQUEST,
    CAMERA_CONTROL_RUN_TESTS_RESPONSE_ERRORS,
    CAMERA_CONTROL_RUN_TESTS_RESPONSE_SUCCESS,
    CAMERA_CONTROL_TESTS_VISIBILITY,
    CAMERA_CONTROL_TEST_OUTPUT_STREAM,
    CAMERA_CONTROL_TEST_PROCESSING,
    CAMERA_CONTROL_RUN_TESTS_RESET,
    CAMERA_CONTROL_DATA_TRANSFER_REQUEST,
    CAMERA_CONTROL_DATA_TRANSFER_RESPONSE_ERRORS,
    CAMERA_CONTROL_DATA_TRANSFER_RESPONSE_SUCCESS,
    CAMERA_CONTROL_DATA_TRANSFER_UPDATE_REQUEST,
    CAMERA_CONTROL_DATA_TRANSFER_UPDATE_RESPONSE_ERRORS,
    CAMERA_CONTROL_DATA_TRANSFER_UPDATE_RESPONSE_SUCCESS,
    CAMERA_CONTROL_DATA_TRANSFER_UPDATE_RESET,
    CAMERA_CONTROL_SIGNAL_STRENGTH_REQUEST,
    CAMERA_CONTROL_SIGNAL_STRENGTH_RESPONSE_ERRORS,
    CAMERA_CONTROL_SIGNAL_STRENGTH_RESPONSE_SUCCESS,
    CAMERA_CONTROL_UPDATE_SYSTEM_RESET,
    CAMERA_CONTROL_UPDATE_APN_RESET,
    CAMERA_CONTROL_RESET_LOADING_INDICATORS,
    CAMERA_CONTROL_UPDATE_EXIF,
    CAMERA_CONTROL_PING_REQUEST,
    CAMERA_CONTROL_PING_RESPONSE_ERRORS,
    CAMERA_CONTROL_PING_RESPONSE_SUCCESS,
    CAMERA_CONTROL_TEST_RESULTS_REQUEST,
    CAMERA_CONTROL_TEST_RESULTS_ERRORS,
    CAMERA_CONTROL_TEST_RESULTS_SUCCESS,
    CAMERA_CONTROL_TEST_RESULT_DETAILS_REQUEST,
    CAMERA_CONTROL_TEST_RESULT_DETAILS_ERRORS,
    CAMERA_CONTROL_TEST_RESULT_DETAILS_SUCCESS
} from '../actions/actionTypes';

export default function cameraControl(state = initialState.camera_control, action) {

    switch (action.type) {
        case CAMERA_CONTROL_STATUS_REQUEST:

            return Object.assign(
                {},
                state,
                {
                    status: {
                        loading: true,
                        success: false,
                        error: false,
                        errors: null,
                        details: null
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_STATUS_RESPONSE_ERRORS:

            return Object.assign(
                {},
                state,
                {
                    status: {
                        loading: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: null
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_STATUS_RESPONSE_SUCCESS:

            return Object.assign(
                {},
                state,
                {
                    status: {
                        loading: false,
                        success: true,
                        error: false,
                        errors: null,
                        details: action.details
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_LOG:

            return Object.assign(
                {},
                state,
                {
                    log: [
                        ...state.log,
                        {
                            level: action.level,
                            message: action.message,
                            timestamp: action.timestamp,
                            extras: action.extras,
                            action: action.action
                        }
                    ]
                }
            )

        case CAMERA_CONTROL_LOG_RESET:

            return Object.assign(
                {},
                state,
                {
                    log: initialState.camera_control.log
                }
            )


        case CAMERA_CONTROL_SETTINGS_REQUEST:

            return Object.assign(
                {},
                state,
                {
                    settings: {
                        loading: true,
                        success: false,
                        error: false,
                        errors: null,
                        details: null
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_SETTINGS_RESPONSE_ERRORS:

            return Object.assign(
                {},
                state,
                {
                    settings: {
                        loading: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: null
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_SETTINGS_RESPONSE_SUCCESS:

            return Object.assign(
                {},
                state,
                {
                    settings: {
                        loading: false,
                        success: true,
                        error: false,
                        errors: null,
                        details: action.details
                    },
                    cameras_id: action.cameras_id
                }
            )


        case CAMERA_CONTROL_CAMERA_REQUEST:

            return Object.assign(
                {},
                state,
                {
                    camera: {
                        loading: true,
                        success: false,
                        error: false,
                        errors: null,
                        details: null
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_CAMERA_RESPONSE_ERRORS:

            return Object.assign(
                {},
                state,
                {
                    camera: {
                        loading: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: null
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_CAMERA_RESPONSE_SUCCESS:

            return Object.assign(
                {},
                state,
                {
                    camera: {
                        loading: false,
                        success: true,
                        error: false,
                        errors: null,
                        details: action.details
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_RESET:

            return Object.assign(
                {},
                state,
                {
                    ...initialState.camera_control
                }
            )

        case CAMERA_CONTROL_SHOOT_REQUEST:

            return Object.assign(
                {},
                state,
                {
                    shoot: {
                        processing: true,
                        success: false,
                        error: false,
                        errors: null,
                        details: null
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_SHOOT_RESPONSE_ERRORS:

            return Object.assign(
                {},
                state,
                {
                    shoot: {
                        processing: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: null
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_SHOOT_RESPONSE_SUCCESS:

            return Object.assign(
                {},
                state,
                {
                    shoot: {
                        processing: false,
                        success: true,
                        error: false,
                        errors: null,
                        details: action.details
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_VERSION:

            return Object.assign(
                {},
                state,
                {
                    version: action.version
                }
            )

        case CAMERA_CONTROL_EXIF_REQUEST:

            return Object.assign(
                {},
                state,
                {
                    exif: {
                        loading: true,
                        success: false,
                        error: false,
                        errors: null,
                        details: null
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_EXIF_RESPONSE_ERRORS:

            return Object.assign(
                {},
                state,
                {
                    exif: {
                        loading: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: null
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_EXIF_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    exif: {
                        loading: false,
                        success: true,
                        error: false,
                        errors: null,
                        details: action.details
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_PREVIEW_REQUEST:

            return Object.assign(
                {},
                state,
                {
                    preview: {
                        processing: true,
                        success: false,
                        error: false,
                        errors: null,
                        details: null
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_PREVIEW_RESPONSE_ERRORS:

            return Object.assign(
                {},
                state,
                {
                    preview: {
                        processing: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: null
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_PREVIEW_RESPONSE_SUCCESS:

            return Object.assign(
                {},
                state,
                {
                    preview: {
                        processing: false,
                        success: true,
                        error: false,
                        errors: null,
                        details: action.details
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_LOADING_PREVIEW_IMAGE:

            return Object.assign(
                {},
                state,
                {
                    loading_preview_image: action.status
                }
            )

        case CAMERA_CONTROL_PREVIEW_QUALITY:

            return Object.assign(
                {},
                state,
                {
                    preview_image_quality: action.quality
                }
            )


        case CAMERA_CONTROL_PREVIEW_VERSION_QUALITY:

            return Object.assign(
                {},
                state,
                {
                    preview_image_quality: action.quality,
                    version: action.version
                }
            )

        case CAMERA_CONTROL_UPDATE_PROPERTIES_REQUEST:

            return Object.assign(
                {},
                state,
                {
                    update_properties: {
                        properties: action.properties,
                        processing: true,
                        success: false,
                        error: false,
                        errors: null,
                        details: null
                    }
                }
            )

        case CAMERA_CONTROL_UPDATE_PROPERTIES_RESPONSE_ERRORS:

            return Object.assign(
                {},
                state,
                {
                    update_properties: {
                        properties: { ...state.update_properties.properties },
                        processing: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: null
                    }
                }
            )

        case CAMERA_CONTROL_UPDATE_PROPERTIES_RESPONSE_SUCCESS:

            return Object.assign(
                {},
                state,
                {
                    update_properties: {
                        properties: { ...state.update_properties.properties },
                        processing: false,
                        success: true,
                        error: false,
                        errors: null,
                        details: action.details
                    }
                }
            )


        case CAMERA_CONTROL_PROPERTIES_REQUEST:

            return Object.assign(
                {},
                state,
                {
                    properties: {
                        processing: true,
                        success: false,
                        error: false,
                        errors: null,
                        details: { ...state.properties.details },
                    }
                }
            )

        case CAMERA_CONTROL_PROPERTIES_RESPONSE_ERRORS:

            return Object.assign(
                {},
                state,
                {
                    properties: {
                        processing: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: null
                    }
                }
            )

        case CAMERA_CONTROL_PROPERTIES_RESPONSE_SUCCESS:

            return Object.assign(
                {},
                state,
                {
                    properties: {
                        processing: false,
                        success: true,
                        error: false,
                        errors: null,
                        details: action.details
                    }
                }
            )

        case CAMERA_CONTROL_SYSTEM_REQUEST:

            return Object.assign(
                {},
                state,
                {
                    system: {
                        loading: true,
                        success: false,
                        error: false,
                        errors: null,
                        details: { ...state.system.details },
                    }
                }
            )

        case CAMERA_CONTROL_SYSTEM_RESPONSE_ERRORS:

            return Object.assign(
                {},
                state,
                {
                    system: {
                        loading: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: null
                    }
                }
            )

        case CAMERA_CONTROL_SYSTEM_RESPONSE_SUCCESS:

            return Object.assign(
                {},
                state,
                {
                    system: {
                        loading: false,
                        success: true,
                        error: false,
                        errors: null,
                        details: action.details
                    }
                }
            )

        case CAMERA_CONTROL_UPDATE_SYSTEM_REQUEST:

            return Object.assign(
                {},
                state,
                {
                    update_system: {
                        properties: action.properties,
                        processing: true,
                        success: false,
                        error: false,
                        errors: null,
                        details: null
                    }
                }
            )

        case CAMERA_CONTROL_UPDATE_SYSTEM_RESPONSE_ERRORS:

            return Object.assign(
                {},
                state,
                {
                    update_system: {
                        properties: { ...state.update_system.properties },
                        processing: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: null
                    }
                }
            )

        case CAMERA_CONTROL_UPDATE_SYSTEM_RESPONSE_SUCCESS:

            return Object.assign(
                {},
                state,
                {
                    update_system: {
                        properties: { ...state.update_system.properties },
                        processing: false,
                        success: true,
                        error: false,
                        errors: null,
                        details: action.details
                    }
                }
            )

        case CAMERA_CONTROL_LOGS_REQUEST:

            return Object.assign(
                {},
                state,
                {
                    logs: {
                        loading: true,
                        success: false,
                        error: false,
                        errors: null,
                        details: null
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_LOGS_RESPONSE_ERRORS:

            return Object.assign(
                {},
                state,
                {
                    logs: {
                        loading: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: null
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_LOGS_RESPONSE_SUCCESS:

            return Object.assign(
                {},
                state,
                {
                    logs: {
                        loading: false,
                        success: true,
                        error: false,
                        errors: null,
                        details: action.details
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_SCHEDULER_START_REQUEST:

            return Object.assign(
                {},
                state,
                {
                    scheduler: {
                        processing: true,
                        action: 'Start',
                        success: false,
                        error: false,
                        errors: null,
                        details: null
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_SCHEDULER_START_RESPONSE_ERRORS:

            return Object.assign(
                {},
                state,
                {
                    scheduler: {
                        processing: false,
                        action: 'Start',
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: null
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_SCHEDULER_START_RESPONSE_SUCCESS:

            return Object.assign(
                {},
                state,
                {
                    scheduler: {
                        processing: false,
                        action: 'Start',
                        success: true,
                        error: false,
                        errors: null,
                        details: action.details
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_SCHEDULER_STOP_REQUEST:

            return Object.assign(
                {},
                state,
                {
                    scheduler: {
                        processing: true,
                        action: 'Stop',
                        success: false,
                        error: false,
                        errors: null,
                        details: null
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_SCHEDULER_STOP_RESPONSE_ERRORS:

            return Object.assign(
                {},
                state,
                {
                    scheduler: {
                        processing: false,
                        action: 'Stop',
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: null
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_SCHEDULER_STOP_RESPONSE_SUCCESS:

            return Object.assign(
                {},
                state,
                {
                    scheduler: {
                        processing: false,
                        action: 'Stop',
                        success: true,
                        error: false,
                        errors: null,
                        details: action.details
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_SCHEDULER_RESTART_REQUEST:

            return Object.assign(
                {},
                state,
                {
                    scheduler: {
                        processing: true,
                        action: 'Restart',
                        success: false,
                        error: false,
                        errors: null,
                        details: null
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_SCHEDULER_RESTART_RESPONSE_ERRORS:

            return Object.assign(
                {},
                state,
                {
                    scheduler: {
                        processing: false,
                        action: 'Restart',
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: null
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_SCHEDULER_RESTART_RESPONSE_SUCCESS:

            return Object.assign(
                {},
                state,
                {
                    scheduler: {
                        processing: false,
                        action: 'Restart',
                        success: true,
                        error: false,
                        errors: null,
                        details: action.details
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_FETCH_APN_REQUEST:

            return Object.assign(
                {},
                state,
                {
                    fetch_apn: {
                        details: null,
                        loading: true,
                        success: false,
                        error: false,
                        errors: null
                    }
                }
            )

        case CAMERA_CONTROL_FETCH_APN_RESPONSE_ERRORS:

            return Object.assign(
                {},
                state,
                {
                    fetch_apn: {
                        details: null,
                        loading: false,
                        success: false,
                        error: true,
                        errors: action.errors
                    }
                }
            )

        case CAMERA_CONTROL_FETCH_APN_RESPONSE_SUCCESS:

            return Object.assign(
                {},
                state,
                {
                    fetch_apn: {
                        details: action.details,
                        loading: false,
                        success: true,
                        error: false,
                        errors: null
                    }
                }
            )

        case CAMERA_CONTROL_UPDATE_APN_REQUEST:

            return Object.assign(
                {},
                state,
                {
                    update_apn: {
                        apn: action.apn,
                        details: null,
                        loading: true,
                        success: false,
                        error: false,
                        errors: null
                    }
                }
            )

        case CAMERA_CONTROL_UPDATE_APN_RESPONSE_ERRORS:

            return Object.assign(
                {},
                state,
                {
                    update_apn: {
                        apn: state.update_apn.apn,
                        details: null,
                        loading: false,
                        success: false,
                        error: true,
                        errors: action.errors
                    }
                }
            )

        case CAMERA_CONTROL_UPDATE_APN_RESPONSE_SUCCESS:

            return Object.assign(
                {},
                state,
                {
                    update_apn: {
                        apn: state.update_apn.apn,
                        details:  action.details,
                        loading: false,
                        success: true,
                        error: false,
                        errors: null
                    }
                }
            )

        case CAMERA_CONTROL_FETCH_WIFI_REQUEST:

            return Object.assign(
                {},
                state,
                {
                    fetch_wifi: {
                        details: null,
                        loading: true,
                        success: false,
                        error: false,
                        errors: null
                    }
                }
            )

        case CAMERA_CONTROL_FETCH_WIFI_RESPONSE_ERRORS:

            return Object.assign(
                {},
                state,
                {
                    fetch_wifi: {
                        details: null,
                        loading: false,
                        success: false,
                        error: true,
                        errors: action.errors
                    }
                }
            )

        case CAMERA_CONTROL_FETCH_WIFI_RESPONSE_SUCCESS:

            return Object.assign(
                {},
                state,
                {
                    fetch_wifi: {
                        details: action.details,
                        loading: false,
                        success: true,
                        error: false,
                        errors: null
                    }
                }
            )


        case CAMERA_CONTROL_START_WIFI_REQUEST:

            return Object.assign(
                {},
                state,
                {
                    wifi: {
                        processing: true,
                        action: 'Start',
                        success: false,
                        error: false,
                        errors: null,
                        details: null
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_START_WIFI_RESPONSE_ERRORS:

            return Object.assign(
                {},
                state,
                {
                    wifi: {
                        processing: false,
                        action: 'Start',
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: null
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_START_WIFI_RESPONSE_SUCCESS:

            return Object.assign(
                {},
                state,
                {
                    wifi: {
                        processing: false,
                        action: 'Start',
                        success: true,
                        error: false,
                        errors: null,
                        details: action.details
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_STOP_WIFI_REQUEST:

            return Object.assign(
                {},
                state,
                {
                    wifi: {
                        processing: true,
                        action: 'Stop',
                        success: false,
                        error: false,
                        errors: null,
                        details: null
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_STOP_WIFI_RESPONSE_ERRORS:

            return Object.assign(
                {},
                state,
                {
                    wifi: {
                        processing: false,
                        action: 'Stop',
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: null
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_STOP_WIFI_RESPONSE_SUCCESS:

            return Object.assign(
                {},
                state,
                {
                    wifi: {
                        processing: false,
                        action: 'Stop',
                        success: true,
                        error: false,
                        errors: null,
                        details: action.details
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_RESTART_WIFI_REQUEST:

            return Object.assign(
                {},
                state,
                {
                    wifi: {
                        processing: true,
                        action: 'Restart',
                        success: false,
                        error: false,
                        errors: null,
                        details: null
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_RESTART_WIFI_RESPONSE_ERRORS:

            return Object.assign(
                {},
                state,
                {
                    wifi: {
                        processing: false,
                        action: 'Restart',
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: null
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_RESTART_WIFI_RESPONSE_SUCCESS:

            return Object.assign(
                {},
                state,
                {
                    wifi: {
                        processing: false,
                        action: 'Restart',
                        success: true,
                        error: false,
                        errors: null,
                        details: action.details
                    },
                    cameras_id: action.cameras_id
                }
            )
        case CAMERA_CONTROL_REBOOT_CAMERA_BODY_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    reboot_camera_body: {
                        processing: true,
                        success: false,
                        error: false,
                        errors: {},
                        details: {}
                    },
                    cameras_id: action.cameras_id
                }
            )
        case CAMERA_CONTROL_REBOOT_CAMERA_BODY_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    reboot_camera_body: {
                        processing: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: {}
                    },
                    cameras_id: action.cameras_id
                }
            )
        case CAMERA_CONTROL_REBOOT_CAMERA_BODY_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    reboot_camera_body: {
                        processing: false,
                        success: true,
                        error: false,
                        errors: {},
                        details: action.details
                    },
                    cameras_id: action.cameras_id
                }
            )
        case CAMERA_CONTROL_REBOOT_CAMERA_BODY_RESET:
            return Object.assign(
                {},
                state,
                {
                    reboot_camera_body: initialState.camera_control.reboot_camera_body
                }
            )
        case CAMERA_CONTROL_RUN_TESTS_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    tests: {
                        processing: true,
                        // output: "Procesing...",
                        success: false,
                        error: false,
                        errors: {},
                        details: {},
                        stream: [],
                        visible: true
                    }
                }
            )
        case CAMERA_CONTROL_RUN_TESTS_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    tests: {
                        processing: false,
                        // output: "...Errors",
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: {},
                        stream: [],
                        visible: true
                    }
                }
            )
        case CAMERA_CONTROL_RUN_TESTS_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    tests: {
                        processing: false,
                        success: true,
                        error: false,
                        errors: {},
                        details: {
                           results: action.details
                        },
                        stream: [],
                        visible: true
                    }
                }
            )
        case CAMERA_CONTROL_TESTS_VISIBILITY:
            return Object.assign(
                {},
                state,
                {
                    tests: {
                        ...state.tests,
                        visible: action.visibility,
                    }
                }
            )

        case CAMERA_CONTROL_TEST_OUTPUT_STREAM:
            return Object.assign(
                {},
                state,
                {
                    tests: {
                        ...state.tests,
                        stream: [...state.tests.stream, action.stream]
                    }
                }
            )

        case CAMERA_CONTROL_TEST_PROCESSING:
            return Object.assign(
                {},
                state,
                {
                    tests: {
                        ...state.tests,
                        processing: action.processing,
                    }
                }
            )

        case CAMERA_CONTROL_RUN_TESTS_RESET:
            return Object.assign(
                {},
                state,
                {
                    tests: initialState.camera_control.tests
                }
            )



        case CAMERA_CONTROL_DATA_TRANSFER_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    data_transfer: {
                        loading: true,
                        success: false,
                        error: false,
                        errors: null,
                        details: null
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_DATA_TRANSFER_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    data_transfer: {
                        loading: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: null
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_DATA_TRANSFER_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    data_transfer: {
                        loading: false,
                        success: true,
                        error: false,
                        errors: null,
                        details: action.details
                    },
                    cameras_id: action.cameras_id
                }
            )

        case CAMERA_CONTROL_DATA_TRANSFER_UPDATE_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    update_data_transfer: {
                        settings: action.settings,
                        details: null,
                        processing: true,
                        success: false,
                        error: false,
                        errors: null
                    }
                }
            )

        case CAMERA_CONTROL_DATA_TRANSFER_UPDATE_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    update_data_transfer: {
                        settings: state.update_data_transfer.settings,
                        details: null,
                        processing: false,
                        success: false,
                        error: true,
                        errors: action.errors
                    }
                }
            )

        case CAMERA_CONTROL_DATA_TRANSFER_UPDATE_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    update_data_transfer: {
                        settings: state.update_data_transfer.settings,
                        details:  action.details,
                        processing: false,
                        success: true,
                        error: false,
                        errors: null
                    }
                }
            )

        case CAMERA_CONTROL_DATA_TRANSFER_UPDATE_RESET:
            return Object.assign(
                {},
                state,
                {
                    update_data_transfer: initialState.camera_control.update_data_transfer
                }
            )

        case CAMERA_CONTROL_SIGNAL_STRENGTH_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    connection_information: {
                        loading: true,
                        success: false,
                        error: false,
                        errors: null,
                        details: state.connection_information.details // so it works better with polling
                    }
                },
            )

        case CAMERA_CONTROL_SIGNAL_STRENGTH_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    connection_information: {
                        loading: false,
                        success: false,
                        error: true,
                        errors: action.errors,
                        details: null
                    }
                },
            )

        case CAMERA_CONTROL_SIGNAL_STRENGTH_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    connection_information: {
                        loading: false,
                        success: true,
                        error: false,
                        errors: null,
                        details: action.details
                    }
                },
            )

        case CAMERA_CONTROL_UPDATE_SYSTEM_RESET:
            return Object.assign(
                {},
                state,
                {
                    update_system : initialState.camera_control.update_system
                }
            )

        case CAMERA_CONTROL_UPDATE_APN_RESET:
            return Object.assign(
                {},
                state,
                {
                    update_apn : initialState.camera_control.update_apn
                }
            )

        case CAMERA_CONTROL_RESET_LOADING_INDICATORS:
            return Object.assign(
                {},
                state,
                {
                    loading_preview_image: false,
                    status: {
                        ...state.status,
                        loading: false
                    },
                    system: {
                        ...state.system,
                        loading: false
                    },
                    camera: {
                        ...state.camera,
                        loading: false
                    }
                }
            )
        case CAMERA_CONTROL_UPDATE_EXIF:
            return Object.assign(
                {},
                state,
                {
                    exif: {
                        details: action.details
                    }
                }
            )
        case CAMERA_CONTROL_PING_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    ping: {
                        pinging: true,
                        success: false,
                        error: false,
                        pingTime: null
                    }
                }
            )
        case CAMERA_CONTROL_PING_RESPONSE_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    ping: {
                        pinging: false,
                        success: false,
                        error: true,
                        pingTime: null
                    }
                }
            )
        case CAMERA_CONTROL_PING_RESPONSE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    ping: {
                        pinging: false,
                        success: true,
                        error: false,
                        pingTime: action.response
                    }
                }
            )
        case CAMERA_CONTROL_TEST_RESULTS_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    test_results: {
                        processing: true,
                    }
                }
            )
        case CAMERA_CONTROL_TEST_RESULTS_ERRORS:
            return Object.assign(
                {},
                state,
                {
                    test_results: {
                        processing: false,
                        error: true,
                        errors: action.errors
                    }
                }
            )
        case CAMERA_CONTROL_TEST_RESULTS_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    test_results: {
                        processing: false,
                        results: action.tests.results
                    }
                }
            )
        case CAMERA_CONTROL_TEST_RESULT_DETAILS_REQUEST:
            return Object.assign(
                {},
                state,
                {
                    test_result_details: {
                        processing: true
                    }
                }
            )
        case CAMERA_CONTROL_TEST_RESULT_DETAILS_ERRORS:      
            return Object.assign(
                {},
                state,
                {
                    test_result_details: {
                        processing: false,
                        details: action.errors
                    }
                }
            )         
        case CAMERA_CONTROL_TEST_RESULT_DETAILS_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    test_result_details: {
                        processing: false,
                        details: action.testDetails.results
                    }
                }
            )
        default:
            return state
    }

}
