import React, { Component } from 'react';
import PropTypes from 'prop-types'
import {Grid, Tab, Segment, Dimmer, Loader, Form, Button, Dropdown, Modal} from 'semantic-ui-react'
import {
    API_BASE_URL,
}  from '../../../config'
import uniqid from 'uniqid'
import DocumentTitle from 'react-document-title'
import SetupModeControl from "../../SetupModeControl";
import Schedule from '../../schedule/Schedule'
import CameraSettings from '../../CameraSettings'
import CameraFocus from '../../CameraFocus'
import CameraStatusV4 from '../../CameraStatusV4'
import CameraWifiInfoV4 from  '../../CameraWifiInfoV4'
import CameraTests from "../../CameraTests";
import TimeZone from "../../TimeZone"
import Logging from "../../Logging";
import Logs from '../../LogsV4';
import ImageRotation from "../../ImageRotation";
import LogViewer from "../../LogViewer"
import placeholder from '../../../assets/placeholder.png'
import { isPermitted } from '../../../util/acl'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import moment from 'moment'
import LedColourChartModal from '../../modals/LedColourChart'

class CameraControlV4Admin extends Component {

    constructor(props) {
        super(props)
        this.interval = null
        this.state = {
            liveviewImage: null,
            liveviewSrc: undefined,
            liveview: false,
            previewSrc: undefined,
            imageLoading: false,
            errorText: undefined,
            modalOpen: false,
            overlayValues: [
                {
                    text: 'None',
                    value: 'none'
                },
                {
                    text:  '2x2 Grid',
                    value: 'g2x2'
                },
                {
                    text: '3x3 Grid',
                    value: 'g3x3'
                },
                {
                    text: '4x4 Grid',
                    value: 'g4x4'
                },
                {
                    text: '16:9 Middle',
                    value: 'c16_9mid'
                },
                {
                    text: '16:9 Top',
                    value: 'c16_9top'
                },
                {
                    text: '16:9 Bottom',
                    value: 'c16_9bot'
                },
            ]
        }
    }

    fetchCameraDetails() {
        return this.props.cameraControlActions.fetchSystem(this.props.cameras_id)
        .then(() => {
            return this.props.cameraControlActions.fetchProperties(this.props.cameras_id)
        })
        .then(() => {
            return this.props.cameraControlActions.fetchStatus(this.props.cameras_id)
        })
        .then(() => {
            return this.props.cameraControlV4Actions.fetchLogs(this.props.cameras_id)
        })
    }

    componentDidMount() {
        this.props.cameraControlV4Actions.reset()

        const version = uniqid()
        this.props.cameraControlActions.version(version)

        return this.props.cameraControlActions.fetchCamera(this.props.cameras_id)
        .then(() => {
            this.props.cameraControlV4Actions.fetchCameraInfo(this.props.cameras_id)
        })
        .then(() => {
            // Check result and break chain if no camera found
            if (this.props.camera_control.camera.errors
                && this.props.camera_control.camera.errors.cameras_id
                && this.props.camera_control.camera.errors.cameras_id.cameraDoesNotExist
            ) {
                throw new Error("Camera not found")
            }
            return this.props.cameraControlV4Actions.fetchSetupMode(this.props.cameras_id)
        })
        .then(() => {
            if (this.props.camera_control.status.error) {
                throw new Error("Status error")
            }
            this.fetchCameraDetails()
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.camera_control_v4.fetch_setup_mode.details && this.props.camera_control_v4.fetch_setup_mode.details !== prevProps.camera_control_v4.fetch_setup_mode.details) {
            if (this.props.camera_control_v4.fetch_setup_mode.details.setup_mode_state === 'OnRequested' && !this.interval) {
                this.pollCameraStatusEndpoint('On')
            } else if (this.props.camera_control_v4.fetch_setup_mode.details.setup_mode_state === 'OffRequested' && !this.interval) {
                this.pollCameraStatusEndpoint('Off')
            }
        }
    }

    isInSetupMode = () => {
        const setupModeStatus = (this.props.camera_control_v4.fetch_setup_mode.details
            && this.props.camera_control_v4.fetch_setup_mode.details.setup_mode_state === 'On') ? true : false

        return setupModeStatus
    }

    getTimeZone = () => {
        return (
            <TimeZone
                cameraControlActions={this.props.cameraControlActions}
                cameras_id={this.props.cameras_id}
                camera_control={this.props.camera_control}
            />
        )
    }

    getImageRotation = () => {
        return (
            <ImageRotation
                cameraControlActions={this.props.cameraControlActions}
                cameras_id={this.props.cameras_id}
                camera_control={this.props.camera_control}
            />
        )
    }

    pollCameraStatusEndpoint = (option) => {
        let done = false
        this.interval = setInterval(async () => {
            if (done) {
              return;
            }

            this.props.cameraControlV4Actions.fetchSetupMode(this.props.cameras_id, true)
            if (this.props.camera_control_v4.fetch_setup_mode.details.setup_mode_state === option) {
                done = true;
                clearInterval(this.interval);
                if (option === 'On') {
                    this.fetchCameraDetails()
                }
            }
        }, 30000)
    }

    getSetupModeControl = () => {
        const setupModeStatus = (this.props.camera_control_v4.fetch_setup_mode.details
            && this.props.camera_control_v4.fetch_setup_mode.details.setup_mode_state) ? this.props.camera_control_v4.fetch_setup_mode.details.setup_mode_state : 'Unknown'

        return (
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
            {this.getPotIdOrLoader()}
            <SetupModeControl
                size='big'
                loading={this.props.camera_control_v4.fetch_setup_mode.loading}
                processing={this.props.camera_control_v4.setup_mode.processing}
                action={this.props.camera_control_v4.setup_mode.action}
                status={setupModeStatus}
                success={this.props.camera_control_v4.setup_mode.success}
                error={this.props.camera_control_v4.setup_mode.error}
                errors={this.props.camera_control_v4.setup_mode.errors}
                onStartClick={() => {
                    this.props.cameraControlV4Actions.requestOnSetupMode(this.props.cameras_id).then(() => {
                        this.pollCameraStatusEndpoint('On')
                    })
                }}
                onStopClick={() => {
                    this.props.cameraControlV4Actions.requestOffSetupMode(this.props.cameras_id).then(() => {
                        this.pollCameraStatusEndpoint('Off')
                    })
                }}
            />
        </div>)
    }

    cameraControlPane = () => {
        const log = this.props.camera_control.log
        const bottomLabels = this.getBottomLabels()
        return (
            <Grid stackable>
                <Grid.Row className='no-padding-bottom'>
                    <Grid.Column mobile={16} tablet={16} computer={8} largeScreen={10} widescreen={11} >
                       
                        {/*<CameraPing {...this.props.camera_control.ping} />*/}
                        {this.getTopLabels()}
                        {this.state.errorText ?
                            this.getErrorMessage()
                        : this.getPreviewImageOrLoader()}
                        {/* <div style={{height:'10px', width:'750px'}}/> */}
                        {bottomLabels}
                        {this.getPreviewOrLoader()}
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={8} largeScreen={6} widescreen={5}>
                        {(this.props.camera_control.properties.details)?
                            <Tab panes={this.getCameraControlPanes()} defaultActiveIndex={0} />
                        : <Loader active />}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className='no-padding-bottom'>
                    <Grid.Column width='16'>
                        {<LogViewer
                            loading={this.props.camera_control.camera.loading}
                            accessToken={localStorage.getItem('accessToken')}
                            name={'Activity Log'}
                            fromWhen={false}
                            log={log}
                            uploadShot={this.props.cameraControlV4Actions.uploadShot}
                        />}
                        <br />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }

    getTopLabels = () => {
        const manufacturer = (this.props.camera_control.properties.details) ?
            this.props.camera_control.properties.details.manufacturer : null

        const cameraModel = (this.props.camera_control.properties.details) ?
            this.props.camera_control.properties.details.camera_model : null

        const topLabels = (this.props.camera_control.properties.details) ?
            <div className='top-label'>
                <span>Manufacturer: <span className='inside-span'>{manufacturer}</span>
                &nbsp;Model: <span className='inside-span'>{cameraModel}</span></span>
            </div>: null

        return topLabels
    }

    getBottomLabels = () => {
        if (this.props.camera_control.loading_preview_image) {
            return (
                <div>
                    <div className='animated-blank-line'></div>
                </div>
            )
        }

        const isoSpeed = (
            this.props.camera_control.exif.details
            && this.props.camera_control.exif.details.iso_speed
        ) ?
            this.props.camera_control.exif.details.iso_speed : null

        const shutterSpeed = (this.props.camera_control.exif.details) ?
            this.props.camera_control.exif.details.shutter_speed : null

        const focalLength = (this.props.camera_control.exif.details) ?
            this.props.camera_control.exif.details.focal_length : null

        const exposureCompensation = (
            this.props.camera_control.exif.details
            && this.props.camera_control.exif.details.exposure_compensation
        ) ?
            this.props.camera_control.exif.details.exposure_compensation : null

        const aperture = (
            this.props.camera_control.exif.details
            && this.props.camera_control.exif.details.aperture
        ) ?
            this.props.camera_control.exif.details.aperture : null

        if (this.props.camera_control.exif.details) {
            return (
                <div className='exif-details'>
                    <span>Shutter Speed: <span className='inside-span'>{shutterSpeed}</span></span>
                    <span>Aperture: <span className='inside-span'>ƒ{aperture}</span></span>
                    <span>ISO Speed: <span className='inside-span'>{isoSpeed}</span></span>
                    {exposureCompensation && <span>Exposure Comp: <span className='inside-span'>{exposureCompensation}</span></span>}
                    <span>Focal Length: <span className='inside-span'>{focalLength}</span></span>
                </div>
            )
        }
        return null;
    }

    getPotIdOrLoader = () => {
        const potId = (this.props.camera_control.camera.details) ?
            this.props.camera_control.camera.details.pot_id : null

        const lastCheckIn = (this.props.camera_control.camera.details) ?
            this.props.camera_control.camera.details.last_check_in : null
        if (!potId) {
            return (
                <div className='animated-blank-line'></div>
            )
        }
        return (<div>
            <h1>{potId}</h1>
            <p>PCID: {this.props.camera_control.camera.details.pcid}</p>
            <p>Last Check In: {lastCheckIn}</p>
            <Button basic compact size='small' color='blue' onClick={() => this.setState({modalOpen: 'chart'})}>Camera LED Colour Guide</Button>
            <br/><br />
        </div>)
    }

    reloadPreviewImage = (optionalQuality = null) => {
        // Let's re-render the latest preview image in a controlled manner
        const version = uniqid()
        this.props.cameraControlActions.loadingPreviewImage(true)
        const src = this.getPreviewUrl(optionalQuality, version)
        let previewImage = new Image()
        previewImage.src = src
        previewImage.onload = () => {
            // We only propagate the changes after pre-caching the image...
            // Fix disappearing image between tab changes
            this.setState({previewSrc: src})
            this.props.cameraControlActions.extractExif(this.props.cameras_id, previewImage)
            this.props.cameraControlActions.loadingPreviewImage(false)
        }
    }

    onPreview = () => {
        this.reloadPreviewImage()
    }

    onShoot = () => {
        this.props.cameraControlV4Actions.shoot(this.props.cameras_id).then(() => {
            //this.reloadPreviewImage()
        })
    }

    getCameraControlPanes = () => {
        let panes = []
        if (this.props.camera_control.properties.details) {
            panes.push(
                {
                    menuItem: 'Settings',
                    render: () => {
                        return (
                            <Tab.Pane>
                                <CameraSettings
                                    cameraControlActions={this.props.cameraControlActions}
                                    cameraControlV4Actions={this.props.cameraControlV4Actions}
                                    cameras_id={this.props.cameras_id}
                                    camera_control={this.props.camera_control}
                                    camera_control_v4={this.props.camera_control_v4}
                                    shutter_count = {this.props.camera_control.properties.details.shutter_counter}
                                    battery_source={true}
                                    isReady={this.props.camera_control.properties}
                                />
                            </Tab.Pane>
                        )
                    }
                }
            )
            panes.push(
                {
                    menuItem: 'Focus',
                    render: () => {
                        return (
                            <Tab.Pane>
                                <CameraFocus
                                    cameraControlV4Actions={this.props.cameraControlV4Actions}
                                    cameras_id={this.props.cameras_id}
                                    camera_control_v4={this.props.camera_control_v4}
                                />
                            </Tab.Pane>
                        )
                    }
                }
            )
        }

        return panes
    }

    getErrorMessage = () => {
        return (
            <div style={{
                width: "1056px",
                height: "704px",
                backgroundColor: "#7f7f7f",
                color: "#fff",
                textAlign: "center",
                textTransform: "uppercase",
                paddingTop: "200px",
                fontSize: "4em"
            }}>
                <p>{this.state.errorText}</p>
            </div>
        )
    }

    getLiveviewUrl = () => {
        return `${API_BASE_URL}/api/cameras/${this.props.cameras_id}`
            + `/camera-control-v4/live-view?access_token`
            + `=${localStorage.getItem('accessToken')}`
    }

    getLogging = () => {
        return (
            <Logging
                  cameraControlActions={this.props.cameraControlActions}
                  cameras_id={this.props.cameras_id}
                  camera_control={this.props.camera_control}
            />
        )
    }

    getLogs = () => {
        return (
            <Logs
                cameraControlActions={this.props.cameraControlActions}
                auth={this.props.auth}
                cameras_id={this.props.cameras_id}
                camera_control={this.props.camera_control}
                camera_control_v4={this.props.camera_control_v4}
            />
        )
    }

    getPreviewUrl = (optionalQuality = null, optionalVersion = null) => {
        const quality = (optionalQuality) ?
            optionalQuality
            : this.props.camera_control.preview_image_quality
        const version = (optionalVersion) ?
            optionalVersion
            : this.props.camera_control.version
        return `${API_BASE_URL}/api/cameras/${this.props.cameras_id}`
            + `/camera-control/preview.jpg?access_token`
            + `=${localStorage.getItem('accessToken')}&quality=${quality}&version=${version}`
    }

   getPreviewImageOrLoader = () => {
        const src = this.state.previewSrc ? this.state.previewSrc : placeholder

        const style = {
            width: '100%',
        }

        return (
            <Segment
                basic
                className='lp4-admin-preview-image'
                style={{
                    width: '100%',
                    height: 'auto',
                    padding: 0
                }}
            >
            <div>
                <TransformWrapper>
                    <TransformComponent wrapperStyle={style} contentStyle={style}>
                        <div className='camera-control-v4-preview-image'>
                            <img id="preview-image" src={src} alt="Camera Preview"/>
                            <canvas ref={(canvas) => {this.canvas = canvas}} width={1056} height={704}/>
                        </div>
                    </TransformComponent>
                </TransformWrapper>
            </div>
            </Segment>
        )
    }

    getPreviewOrLoader = () => {
        if (this.props.camera_control.system.loading) {
            return (
                <div className='animated-blank-line'></div>
            )
        } else {
            return (
                <Form size='small' className='preview-controls'>
                    <Form.Group>
                        <Form.Field>
                            <label>Preview Quality</label>
                            <Dropdown
                                floating
                                selection
                                labeled
                                placeholder='Preview Quality'
                                defaultValue={this.props.camera_control.preview_image_quality}
                                onChange={(evt, val) => {
                                    const quality = val.value
                                    if (quality === this.props.camera_control.preview_image_quality) {
                                        return
                                    }
                                    this.reloadPreviewImage(quality)
                                    const qualityItem = this.props.camera_control.preview_image_quality_range.find((item) => {
                                        return item.value === quality
                                    })
                                    this.props.cameraControlActions.log('Info', `Preview quality set to ${qualityItem.text}`, Date.now())
                                }}
                                options={this.props.camera_control.preview_image_quality_range}
                            />
                            </Form.Field>

                        <Form.Field>
                            <label>Preview</label>
                            <Button circular icon='camera' color='grey'
                                    loading={this.props.camera_control.loading_preview_image}
                                    onClick={this.onPreview}/>
                        </Form.Field>

                        <Form.Field>
                            <label>Full Res</label>
                            <Button circular icon='camera' color='blue'
                                //loading={this.props.camera_control.loading_preview_image}
                                onClick={() => this.setState({modalOpen: 'preview'})}/>
                        </Form.Field>
                        <Form.Field style={{zIndex: 10000}}>
                            <label>Overlay</label>
                            <Dropdown

                                selection
                                labeled
                                placeholder='Overlay'
                                defaultValue='none'
                                onChange={this.changeOverlay}
                                options={this.state.overlayValues}
                            />
                        </Form.Field>
                    </Form.Group>
                </Form>

            )
        }
    }

    getLiveviewImageOrLoader = () => {
        const src = this.state.liveviewSrc ? this.state.liveviewSrc : placeholder
        return (
            <Segment
                basic
                style={{
                    width: '100%',
                    height: 'auto',
                    padding: 0
                }}
            >
                <Dimmer active={!this.state.liveviewSrc}>
                    {
                        this.state.imageLoading ?
                        <Loader inline='centered'/> : null
                    }
                </Dimmer>

                <div className='camera-control-v4-liveview-image'>
                    <img src={src} alt="Camera Liveview"/>
                    <canvas ref={(canvas) => {this.canvas = canvas}} width={1056} height={704} />
                </div>
            </Segment>
        )
    }

    onLiveviewChange = () => {
        const image = document.querySelector(".camera-control-v4-liveview-image img")

        if (this.state.liveview) {
            this.setState({
                imageLoading: false,
                liveview: false,
                liveviewSrc: null //placeholder,
            })
            image.removeAttribute('onload')
        } else {
            this.setState({
                imageLoading: true,
                liveview: true
            })

            const src = this.getLiveviewUrl(null)
            image.src = src

            image.addEventListener('load', () => {
                if (this.state.liveview) {
                    this.setState({
                        imageLoading: false,
                        liveviewSrc: src
                    })
                }
            })
        }
    }

    getLiveviewOrLoader = () => {
        if (this.props.camera_control.system.loading) {
            return (
                <div className='animated-blank-line'></div>
            )
        } else {

            return (
                <div>

                    <Form size='small' className='preview-controls'>
                        <Form.Group>
                            <Form.Field>
                                <label>LiveView</label>
                                <Form.Checkbox
                                    checked={this.state.liveview}
                                    onChange={this.onLiveviewChange}
                                    toggle
                                />
                            </Form.Field>
                            <Form.Field style={{zIndex: 10000}}>
                                <label>Overlay</label>
                                <Dropdown
                                    selection
                                    labeled
                                    placeholder='Overlay'
                                    defaultValue='none'
                                    onChange={this.changeOverlay}
                                    options={this.state.overlayValues}
                                />
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </div>
            )
        }
    }

    changeOverlay = (event, {value}) => {
        const width = this.canvas.width
        const height = this.canvas.height
        const context = this.canvas.getContext('2d')
        context.clearRect(0, 0, width, height);

        console.log(width, height, context)
        switch(value) {
            case 'g2x2':
                this.drawLine(width / 2, 0, width / 2, height)
                this.drawLine(0, height / 2, width, height / 2)
                break
            case 'g3x3':
                this.drawLine(width / 3, 0, width / 3, height)
                this.drawLine(2 * width / 3, 0, 2 * width / 3, height)
                this.drawLine(0, height / 3, width, height / 3)
                this.drawLine(0, 2 * height / 3, width, 2 * height / 3)
                break
            case 'g4x4':
                this.drawLine(width / 4, 0, width / 4, height)
                this.drawLine(2 * width / 4, 0, 2 * width / 4, height)
                this.drawLine(3 * width / 4, 0, 3 * width / 4, height)
                this.drawLine(0, height / 4, width, height / 4)
                this.drawLine(0, 2 * height / 4, width, 2 * height / 4)
                this.drawLine(0, 3 * height / 4, width, 3 * height / 4)
                break
            case 'c16_9mid':
                const barHeightM = (height - width * (9 / 16)) / 2
                this.drawRectangle(0, 0, width, barHeightM)
                this.drawRectangle(0, height - barHeightM, width, barHeightM)
                break
            case 'c16_9top':
                const barHeightT = (height - width * (9 / 16))
                this.drawRectangle(0, height - barHeightT, width, barHeightT)
                break
            case 'c16_9bot':
                const barHeightB = (height - width * (9 / 16))
                this.drawRectangle(0, 0, width, barHeightB)
                break
            default:

        }
    }

    drawLine = (x1, y1, x2, y2) => {
        if (!this.canvas) {
            return null
        }
        const context = this.canvas.getContext('2d')
        context.beginPath();
        context.moveTo(x1,y1);
        context.lineTo(x2,y2);
        context.stroke();
    }

    drawRectangle = (x1, y1, w, h) => {
        if (!this.canvas) {
            return null
        }
        const context = this.canvas.getContext('2d')
        context.fillStyle = "rgba(0, 0, 0, 0.6)";
        context.fillRect(x1, y1, w, h);
    }

    fullResModal = () => {
        return (
            <Modal
                onClose={() => this.setState({modalOpen: false})}
                onOpen={() => this.setState({modalOpen: 'preview'})}
                open={this.state.modalOpen === 'preview'}
                size='small'
                >
                <Modal.Header>Are you sure?</Modal.Header>
                <Modal.Content>
                    <p>
                        The full resolution preview is a large file and fetching it will take several minutes to resolve. Are you sure you would like to continue?
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content="Close"
                        onClick={() => this.setState({modalOpen: false})}
                        primary
                    />
                    <Button
                        as='a'
                        href={`${API_BASE_URL}/api/cameras/${this.props.cameras_id}/camera-control/preview.jpg?access_token=${localStorage.getItem('accessToken')}&size=full&version=${this.version}`}
                        target='_blank'
                        rel='noopener noreferrer'
                        content="Fetch Preview"
                        onClick={() => {
                            this.setState({modalOpen: false})

                        }}
                        positive
                    />
                </Modal.Actions>
            </Modal>
        )
    }

    getContainerPanes = () => {
        let panes = []

        const setupModePane =  { menuItem: 'Camera Control', render: () => {
            return (
                <Tab.Pane attached={false}>
                    <Grid columns='1'>
                        <Grid.Row className='no-padding-bottom'>
                            <Grid.Column>
                                {this.getSetupModeControl()}
                                {(this.isInSetupMode() && isPermitted(this.props.auth.user, 'get-cameracontrol-settings'))? this.cameraControlPane() : null}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    {this.fullResModal()}
                </Tab.Pane>
            )
        }}

        const liveViewPane = { menuItem: 'Live View', render: () => {
            const log = this.props.camera_control.log
            /*const bottomLabels = this.getBottomLabels()*/
            return(
                <Tab.Pane attached={false}>
                     <Grid stackable>
                         <Grid.Row className='no-padding-bottom'>
                             <Grid.Column mobile={16} tablet={16} computer={11}>
                                 {this.getPotIdOrLoader()}
                                 {this.getTopLabels()}
                                 {this.state.errorText ?
                                     this.getErrorMessage()
                                 : this.getLiveviewImageOrLoader()}
                                 <Grid.Row className='no-padding-bottom'>
                                     <Grid.Column width='16'>
                                         {this.getLiveviewOrLoader()}
                                         {<LogViewer
                                             loading={this.props.camera_control.camera.loading}
                                             accessToken={localStorage.getItem('accessToken')}
                                             name={'Activity Log'}
                                             fromWhen={false}
                                             log={log}
                                             uploadShot={this.props.cameraControlV4Actions.uploadShot}
                                         />}
                                         <br />
                                     </Grid.Column>
                                 </Grid.Row>
                             </Grid.Column>
                         </Grid.Row>
                     </Grid>
                </Tab.Pane>
            )
        }}

        const settingsPane =  { menuItem: 'Settings', render: () => {
            return (
                <Tab.Pane attached={false}>
                    <Grid columns='2'>
                        <Grid.Row className='no-padding-bottom'>
                            <Grid.Column>
                                {this.props.camera_control_v4 && this.props.camera_control_v4.camera_info && <CameraStatusV4
                                    {...this.props.camera_control_v4.camera_info}
                                />}
                                {<CameraWifiInfoV4
                                    {...this.props.camera_control.status}
                                />}
                                {/*<CameraPower
                                    {...this.props.camera_control.system}
                                />*/}
                                {<Segment>
                                    <h4>Time Zone</h4>
                                    {this.getTimeZone()}
                                </Segment>}
                                {<Segment>
                                    <h4>Logging</h4>
                                    {this.getLogging()}
                                </Segment>}
                                <Segment>
                                    <h4>Logs</h4>
                                    {this.getLogs()}
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Tab.Pane>
            )
        }}

        const schedulePane = { menuItem: 'Schedule', render: () => {
            return (
                <Tab.Pane attached={false}>
                    <Schedule
                        auth={this.props.auth}
                        schedule={this.props.schedule}
                        match={this.props.match}
                        authActions={this.props.authActions}
                        scheduleActions={this.props.scheduleActions}
                    />
                </Tab.Pane>
            )
        }}

        const testsPane = { menuItem: 'Tests', render: () => {
            return (
                <Tab.Pane attached={false}>

                        <CameraTests
                            processing={this.props.camera_control.tests.processing}
                            error={this.props.camera_control.tests.error}
                            success={this.props.camera_control.tests.success}
                            errors={this.props.camera_control.tests.errors}
                            details={this.props.camera_control.tests.details}
                            onRunTests={()=> {
                                this.props.cameraControlActions.runTests(this.props.cameras_id)
                            }}
                            onResetTests={() => {
                                this.props.cameraControlActions.resetTests()
                            }}
                            // stream={this.props.camera_control.tests.details.results || []}
                            stream={this.props.camera_control.tests.stream || []}
                            visible={this.props.camera_control.tests.visible}
                        />

                </Tab.Pane>
            )
        }}

        const testResultsPane = {
            menuItem: 'Test Results',
            render: () => {
                const results = this.props.camera_control.test_results.results;

                return (
                    <Tab.Pane attached={false}>
                        <Button primary onClick={() => this.props.cameraControlActions.fetchTest(this.props.cameras_id)}>Fetch Tests</Button>
                        {(this.props.camera_control.test_results.processing)? <Loader active inline size='small'></Loader> : null}

                        {(results)?
                        <Grid>
                            <Grid.Column width={6}>
                                <div className="results">
                                    {results.map((result, index) => {
                                        const formattedDate = moment(`${result.start_time.y}-${result.start_time.m}-${result.start_time.d} ${result.start_time.H}:${result.start_time.M}:${result.start_time.S}`, 'YYYYMD H:m:s ZZ').format('MMMM Do YYYY HH:mm:ss')
                                        const apiRequestDate = moment(`${result.start_time.y}-${result.start_time.m}-${result.start_time.d} ${result.start_time.H}:${result.start_time.M}:${result.start_time.S}`, 'YYYYMD H:m:s ZZ').format('YYYY-MM-DD_HH-mm-ss')
                                        return (
                                            <div key={`test-result-${index}`} className={`test-result test-result--${result.result.toLowerCase()}`} onClick={(e) => this.getTestDetails(e, this.props.cameras_id, apiRequestDate)}>
                                                <p className='test-result__result'>{result.result}</p>
                                                <p className='test-result__time'>{formattedDate}</p>
                                                <p className='test-result__summary'>{result.summary}</p>
                                                {/* <p className='test-result__person'>{result.person}</p> */}
                                            </div>
                                        )
                                    })}
                                </div>
                            </Grid.Column>
                            <Grid.Column width={10}>
                                <ul style={{margin: 0, padding: '0', listStyle: 'none'}}>
                                    {this.props.camera_control.test_result_details &&  this.props.camera_control.test_result_details.details &&
                                        this.props.camera_control.test_result_details.details.test_results.map((result, index) => {
                                        return (
                                            <li key={`test-detail-${index}`} className={`test-detail test-detail--${result.result.toLowerCase()}`}>
                                                <p>{result.result}</p>
                                                <p>{result.name}</p>
                                                {result.message && <p className='test-detail__message'>{result.message}</p>}
                                            </li>
                                        )
                                    })}
                                </ul>
                            </Grid.Column>
                        </Grid>

                        : null}
                    </Tab.Pane>
                )
            }
        }

        if (isPermitted(this.props.auth.user, 'get-cameracontrol-settings')) {
            panes.push(setupModePane)
        }
        if (this.isInSetupMode() && isPermitted(this.props.auth.user, 'get-cameracontrol-settings')) {
            panes.push(liveViewPane)
        }
        // if (this.isInSetupMode() && isPermitted(this.props.auth.user, 'get-cameracontrol-settings')) {
        //     panes.push(cameraControlPane)
        // }
        if (this.isInSetupMode() && isPermitted(this.props.auth.user, 'get-cameracontrol-settings')) {
            panes.push(settingsPane)
        }
        if (isPermitted(this.props.auth.user, 'get-scheduler-schedule')) {
            panes.push(schedulePane)
        }

        if (this.isInSetupMode() && isPermitted(this.props.auth.user, 'post-cameracontrol-runtests')) {
            panes.push(testsPane)
        }

        if (isPermitted(this.props.auth.user, 'get-cameracontrol-settings') && isPermitted(this.props.auth.user, 'post-cameracontrol-runtests')) {
            panes.push(testResultsPane)
        }

        return panes
    }

    getTestDetails = (e, id, apiRequestDate) => {
        // fetch clicked test's siblings and remove active class
        let getSiblings = function (e) {
            let siblings = [];

            if(!e.parentNode) {
                return siblings;

            }
            // first child
            let sibling  = e.parentNode.firstChild;
            while (sibling) {
                if (sibling.nodeType === 1 && sibling !== e) {
                    siblings.push(sibling);
                }
                sibling = sibling.nextSibling;
            }
            return siblings;
        };

        let siblings = getSiblings(e.currentTarget);
        siblings.forEach(item => {
           item.classList.remove('active')
        })
        // add active class to click
        e.currentTarget.classList.add('active')
        // request details
        this.props.cameraControlActions.fetchTestDetails(id, apiRequestDate)
    }

    render = () => {
        let title
        const potId = (this.props.camera_control.camera.details) ?
            this.props.camera_control.camera.details.pot_id : null
        if (potId) {
            title = `${potId} Camera Control V4`
        } else {
            title = 'Camera Control V4'
        }
        return (
            <div className='camera-control'>
                <DocumentTitle title={title} />
                <Tab
                    menu={{ secondary: true }}
                    panes={this.getContainerPanes()}
                    defaultActiveIndex={0}
                />
                <LedColourChartModal open={this.state.modalOpen === 'chart'} onClose={() => this.setState({modalOpen: false})} />
            </div>
        )
    }
}

CameraControlV4Admin.propTypes = {
    cameras_id: PropTypes.any.isRequired,
    cameraControlActions: PropTypes.shape({
        fetchStatus: PropTypes.func.isRequired,
        fetchProperties: PropTypes.func.isRequired,
        fetchCamera: PropTypes.func.isRequired,
        fetchExif: PropTypes.func.isRequired,
        fetchLogs: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired,
        preview: PropTypes.func.isRequired,
        shoot: PropTypes.func.isRequired,
        updateProperties: PropTypes.func.isRequired,
        loadingPreviewImage: PropTypes.func.isRequired,
        previewQualityVersion: PropTypes.func.isRequired,
        fetchSystem: PropTypes.func.isRequired,
        updateSystem: PropTypes.func.isRequired
    })
}

export default CameraControlV4Admin
