import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import Uppy from '@uppy/core';
import Tus from '@uppy/tus';
import { Dashboard } from '@uppy/react';
import { TUSD_URL } from '../../config';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

class CameraUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lobsterEndpoint: TUSD_URL,
            potId: null,
            password: null,
            pcid: null,
            potVersion: null,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.uploadHost &&
            this.props.uploadHost !== prevState.lobsterEndpoint
        ) {
            this.setState({ lobsterEndpoint: this.props.uploadHost });
        }

        if (this.props.potId && this.props.potId !== prevState.potId) {
            this.setState({ potId: this.props.potId });
        }

        if (this.props.password && this.props.password !== prevState.password) {
            this.setState({ password: this.props.password });
        }

        if (this.props.pcid && this.props.pcid !== prevState.pcid) {
            this.setState({ pcid: this.props.pcid });
        }

        if (this.props.potVersion && this.props.potVersion !== prevState.potVersion) {
            this.setState({ potVersion: this.props.potVersion });
        }

        if (Uppy) {
            this.uppy = new Uppy({
                id: 'uppy1',
                autoProceed: false,
                debug: true,
                meta: {},
            }).use(Tus, { endpoint: this.state.lobsterEndpoint });
            
            this.uppy.setMeta({
                id: 'name',
                name: 'Name',
                placeholder: 'File name',
                pot: this.state.potId,
                passwd: this.state.password,
                pcid: this.state.pcid,
                pot_version: this.state.potVersion,
            });
        }
    }

    componentWillUnmount() {
        this.uppy.close();
    }

    render() {
        return (
            <div>
                <DocumentTitle title="Image Upload" />
                <div>
                    <h1>
                        Camera Upload{' '}
                        {this.props.potId == 'lp4'
                            ? this.props.pcid
                            : this.props.potId}
                    </h1>

                    {this.uppy && (
                        <Dashboard
                            uppy={this.uppy}
                            plugins={['Tus']}
                            metaFields={[
                                {
                                    id: 'name',
                                    name: 'Name',
                                    placeholder: 'File name',
                                    pot: this.state.potId,
                                    passwd: this.state.password,
                                    pcid: this.state.pcid,
                                    pot_version: this.state.potVersion,
                                }
                            ]}
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default CameraUpload;
