import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Icon } from 'semantic-ui-react'

class ViewToggle extends Component {

    constructor(props) {
        super(props)

        this.state = {
            selected: props.defaultValue
        }
    }

    render = () => {
        return (
            <span className='view-toggle'>
                <Button.Group>
                    <Button className='grid-toggle-button' onClick={(event) => {
                        if (this.state.selected !== 'grid') {
                            this.setState({selected: 'grid'}, () => {
                                this.props.onClick('grid')
                            })
                        }
                    }} icon active={this.state.selected === 'grid'}>
                        <Icon name='grid layout' />&nbsp;
                        Grid
                    </Button>
                    {this.props.userRolesId !== 5 &&
                        <Button className='list-toggle-button' onClick={(event) => {
                            if (this.state.selected !== 'list') {
                                this.setState({selected: 'list'}, () => {
                                    this.props.onClick('list')
                                })
                            }
                        }} icon active={this.state.selected === 'list'}>
                            <Icon name='list layout' />&nbsp;
                            List
                        </Button>
                    }
                </Button.Group>
            </span>
        )
    }
}

ViewToggle.propTypes = {
    onClick: PropTypes.func.isRequired,
    defaultValue: PropTypes.string.isRequired
}

export default ViewToggle
