import React, { Component } from 'react'
import DocumentTitle from 'react-document-title'
import {
    Table,
    TableRow,
    TableCell,
    Menu,
    Button,
    Icon,
    Dimmer,
    Loader,
    Modal,
    Segment
} from 'semantic-ui-react'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import TableColumn from '../TableColumn'
import pagination from '../../util/pagination'
import { isPermitted } from '../../util/acl'

class FileList extends Component {
    constructor(props) {
        super(props)
        this.rowsPerPage = 600
        this.ascending = false
        this.cameraId = null
        this.columns = [
            new TableColumn("image", "Filename", false),
            new TableColumn("date", "Created", false),
            new TableColumn("actions", "Actions", false)
        ]
        if (isPermitted(this.props.auth.user, 'delete-images-image')) {
            let deleteHeader = (
                <span>
                    <input
                        type="checkbox"
                        onChange={this.toggleAllImagesForDeletion}
                    />&nbsp;
                    Delete
                </span>
            )
            this.columns.unshift(
                new TableColumn("delete", deleteHeader, false)
            )
        }
        this.state = {
            previewOpen: false,
            previewFilename: null,
            pendingDelete: [],
            showDeleteConfirmation: false,
            forceDelete: false
        }
        this.lastFileClicked = null
        moment.locale('en-GB')
    }

    componentDidMount() {
        this.props.fileListActions.reset()
        this.cameraId = this.props.match.params.cameraId
        this.props.fileListActions.fileListRequest(this.cameraId)
    }

    /**
    * Jump to a particular page in the results set
    *
    * @param {string} name - The page number label ("1", "2", etc)
    */
    handlePaginationPageClick = (e, { name }) => {
        const page = parseInt(name, 10)
        this.props.fileListActions.setPage(this.cameraId, page)
    }

    /** Jump to the previous page in the results set */
    handlePaginationPrevClick = (e) => {
        if (this.props.fileList.currentPage > 1 ) {
            const page = this.props.fileList.currentPage - 1
            this.props.fileListActions.setPage(this.cameraId, page)
        }
    }

    /** Jump to the next page in the results set */
    handlePaginationNextClick = (e) => {
        const totalPages = Math.ceil(this.props.fileList.totalRows/this.rowsPerPage)
        if (this.props.fileList.currentPage < totalPages) {
            const page = this.props.fileList.currentPage + 1
            this.props.fileListActions.setPage(this.cameraId, page)
        }
    }

    setStartDate = (date) => {
        this.props.fileListActions.setStartDate(this.cameraId, date)
    }

    setEndDate = (date) => {
        this.props.fileListActions.setEndDate(this.cameraId, date)
    }

    toggleAllImagesForDeletion = (event) => {
        if (event.target.checked) {
            // Check All
            const allImages = []
            for (var i = 0; i < this.props.fileList.tableData.length; i++) {
                allImages.push(this.props.fileList.tableData[i].image)
            }
            this.setState({pendingDelete: allImages})
        } else {
            // Uncheck All
            this.setState({pendingDelete: []})
        }
    }

    toggleImageForDeletion = (event, image) => {
        const checked = event.target.checked
        if (event.shiftKey && this.lastFileClicked) {
            // Multiselect!!
            // Find all in props.drop.drop between last clicked and file
            const start = this.props.fileList.tableData.findIndex(file => file.image === this.lastFileClicked)
            const end = this.props.fileList.tableData.findIndex(file => file.image === image)
            const range = this.props.fileList.tableData.slice(Math.min(start, end),
                                                     Math.max(start, end)+1)
            let pendingDelete = this.state.pendingDelete
            for (var i=0; i < range.length; i++) {
                const index = pendingDelete.indexOf(range[i].image)
                if (checked) {
                    if (index === -1) {
                        pendingDelete = pendingDelete.concat([range[i].image])
                    }
                } else {
                    if (index > -1) {
                        pendingDelete = [
                            ...pendingDelete.slice(0, index),
                            ...pendingDelete.slice(index+1)
                        ]
                    }
                }
            }
            this.setState({pendingDelete: pendingDelete})
        } else {
            const index = this.state.pendingDelete.indexOf(image)
            if (index === -1){
                // Add image to array
                this.setState((state) => (
                    { pendingDelete: state.pendingDelete.concat([image]) }
                ))
            } else {
                // Remove existing image from array
                this.setState((state) => (
                    { pendingDelete: [
                        ...state.pendingDelete.slice(0, index),
                        ...state.pendingDelete.slice(index+1)
                    ]}
                ))
            }
            this.lastFileClicked = image
        }
    }

    showDeleteConfirmation = () => {
        this.setState({showDeleteConfirmation: true})
    }

    hideDeleteConfirmation = (success = false) => {
        if (success) {
            this.setState({
                showDeleteConfirmation: false,
                pendingDelete: []
            })
        } else {
            this.setState({showDeleteConfirmation: false})
        }
    }

    toggleForceDelete = (event) => {
        this.setState({forceDelete: event.target.checked})
    }

    /** Render the table header row using this.columns */
    renderHeader() {
        return (
            <TableRow>
            {this.columns.map((column, k) => {
                return (
                    <Table.HeaderCell sorted={null} onClick={null} key={k} data-testid='table-header--filelist'>
                    {column.title}
                    </Table.HeaderCell>
                )
            })}
            </TableRow>
        )
    }

    /**
    * Render a single table row of results
    *
    * @param {Object} row - An object containing one row of results
    * @param {number} index - The current row number being rendered
    */
    renderBodyRow = (row, index) => {
        const viewButton = <Button
            icon='play'
            content='View'
            basic
            color='blue'
            compact
            key={row.image}
            onClick={() => this.showPreview(row.thumbnailURL)}

        />
        const date = moment.unix(row.date_unix)
        date.utc()
        let deleteCheckbox = null
        if (isPermitted(this.props.auth.user, 'delete-images-image')) {
            deleteCheckbox = (
                <TableCell>
                    <input
                        type="checkbox"
                        id={`check_${row.image}`}
                        checked = {this.state.pendingDelete.indexOf(row.image) > -1}
                        onClick={(event) => {this.toggleImageForDeletion(event, row.image)}}
                        onChange={() => { /* Empty function to supress linting errors */}}
                    />
                </TableCell>
            )
        }
        return (
            
            <TableRow key={row.image} className="table-row">
                {deleteCheckbox}
                <TableCell>{row.image}</TableCell>
                <TableCell>{date.format('Do MMMM YYYY, HH:mm:ss')}</TableCell>
                <TableCell>{viewButton}</TableCell>
            </TableRow>
        )
    }

    /** Render the table footer row */
    renderFooter() {
        if (this.props.fileList.totalRows === 0) {
            return (
                <TableRow>
                    <TableCell colSpan={this.columns.length}>
                        No results found
                    </TableCell>
                </TableRow>
            )
        }
        const totalPages = Math.ceil(this.props.fileList.totalRows/this.rowsPerPage)
        const first = ((this.props.fileList.currentPage - 1) * this.props.fileList.pageSize) + 1
        let last = this.props.fileList.currentPage * this.props.fileList.pageSize
        if (last > this.props.fileList.totalRows) last = this.props.fileList.totalRows
        let pages = pagination(this.props.fileList.currentPage, totalPages)
        let menuPagination = (
            <Menu pagination>
                <Menu.Item name="prev" onClick={this.handlePaginationPrevClick} icon="angle left" />
                    {pages.map((page, key) => {
                    return (
                        <Menu.Item
                            name={page.toString()}
                            key={key}
                            active={this.props.fileList.currentPage === page}
                            onClick={this.handlePaginationPageClick}
                        />
                    )
                })}
                <Menu.Item name="next" onClick={this.handlePaginationNextClick} icon="angle right" />
            </Menu>
        )
        return (
            <TableRow data-testid='filelist__footer'>
            <TableCell colSpan={this.columns.length}>
            <div>Showing {first} - {last} of {this.props.fileList.totalRows} {this.props.fileList.totalRows === 1 ? "result" : "results"}</div>
            <div style={{textAlign: 'center'}}>
                {totalPages > 1 ? menuPagination : null}
            </div>
            </TableCell>
            </TableRow>
        )
    }

    getLoader = () => {
        if (this.props.fileList.loading) {
            return (
                <TableRow>
                    <TableCell colSpan={this.columns.length}>
                        <Dimmer active inverted>
                           <Loader inverted content='Loading' data-testid="file-list--loader" />
                         </Dimmer>
                    </TableCell>
                </TableRow>
            )
        }
    }

    getPotId = () => {
        if (this.props.fileList.potId) {
            return (
                <h2>File list for Pot {this.props.fileList.potId}</h2>
            )
        } else {
            return (
                <h2>File list</h2>
            )
        }
    }

    getPreviewImage = () => {
        if (this.state.previewURL) {
            return (
                <img
                    className="preview-image"
                    src={this.state.previewURL}
                    alt="Camera Preview"
                />
            )
        } else {
            return null
        }
    }

    showPreview = (url) => {
        this.setState({
            previewURL: url,
            previewOpen: true
        })
    }

    closePreview = () => {
        this.setState({
            previewURL: null,
            previewOpen: false
        })
    }

    render() {
        let start = null, end = null
        if (typeof this.props.fileList.start === 'string') {
            start = moment(this.props.fileList.start)
        } else {
            start = this.props.fileList.start
        }
        if (typeof this.props.fileList.end === 'string') {
            end = moment(this.props.fileList.end)
        } else {
            end = this.props.fileList.end
        }

        let deleteButton = null
        if (isPermitted(this.props.auth.user, 'delete-images-image')) {
            deleteButton = (
                <div>
                    <Button
                        color="red"
                        onClick={this.showDeleteConfirmation}
                        disabled={this.state.pendingDelete.length === 0}
                    >
                        Delete Images
                    </Button><br />
                    <label htmlFor = "force">
                        <input
                            id = "force"
                            type = "checkbox"
                            checked = {this.state.forceDelete}
                            onChange = {this.toggleForceDelete}
                        />
                        Force delete
                    </label>
                </div>
            )
        }
        return (
            <div>
                <DocumentTitle title='File List' />
                {this.getPotId()}
                <Modal
                    open={this.state.previewOpen}
                    onClose={this.closePreview}
                    dimmer="blurring"
                    style={{textAlign: 'center'}}
                >
                    <Modal.Content>
                        {this.getPreviewImage()}
                    </Modal.Content>
                </Modal>
                <Modal
                    open={this.state.showDeleteConfirmation}
                    onClose={this.hideDeleteConfirmation}
                >
                    <Modal.Header>
                        Delete Images
                    </Modal.Header>
                    <Modal.Content>
                        <p>Are you sure you want to delete&nbsp;
                        {this.state.pendingDelete.length}&nbsp;
                        {this.state.pendingDelete.length === 1 ? 'image' : 'images'}?</p>
                        <p>This operation cannot be undone.</p>
                        <div className='modalButtons'>
                        <Button
                            onClick={this.hideDeleteConfirmation}
                            negative
                            content='No'
                        />
                        <Button
                            positive
                            icon='checkmark'
                            labelPosition='right'
                            content='Yes'
                            loading={this.props.fileList.loading}
                            onClick={() => {
                                this.props.fileListActions.deleteImages(
                                    this.cameraId,
                                    this.state.pendingDelete,
                                    this.state.forceDelete,
                                    this.hideDeleteConfirmation
                                )
                            }}
                        />
                        </div>
                    </Modal.Content>
                </Modal>
                <div>
                    {this.getLoader()}
                    <Segment.Group horizontal compact>
                        <Segment compact>
                            <div className='projectStartDateContainer'>
                                <DatePicker
                                    placeholderText='Start'
                                    selected={start}
                                    onChange={this.setStartDate}
                                    dateFormat="do MMM yy"
                                    minDate={moment.unix(this.props.fileList.minDate)}
                                    maxDate={moment.unix(this.props.fileList.maxDate)}
                                />
                                <Icon
                                    className='datePickerIcon'
                                    name='calendar'
                                    style={{top: '2px'}}
                                />
                            </div>
                        </Segment>
                        <Segment compact>
                            <div className='projectFinishDateContainer'>
                                <DatePicker
                                    placeholderText='End'
                                    selected={end}
                                    onChange={this.setEndDate}
                                    dateFormat="do MMM yy"
                                    minDate={moment.unix(this.props.fileList.minDate)}
                                    maxDate={moment.unix(this.props.fileList.maxDate)}
                                />
                                <Icon
                                    className='datePickerIcon'
                                    name='calendar'
                                    style={{top: '2px'}}
                                />
                            </div>
                        </Segment>
                    </Segment.Group>
                    <Table
                        compact="very"
                        renderBodyRow={this.renderBodyRow}
                        headerRow={this.renderHeader()}
                        tableData={this.props.fileList.tableData}
                        footerRow={this.renderFooter()}
                    >
                    </Table>
                    {deleteButton}
                </div>
            </div>
        )
    }
}

export default FileList
