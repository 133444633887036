import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Message, Grid, Input } from 'semantic-ui-react'

import { getErrorMessage } from './../util/errors'

class Apn extends Component {

    constructor(props) {
        super(props)
        this.state = { apn: null}
    }

    onChange = (evt, {value}) => {
        this.setState({apn: value})
    }

    isProcessing = () => {
        return this.props.camera_control.update_apn.loading
    }

    isButtonDisabled = () => {
        if (this.isProcessing()) {
            return true
        }
        if (!this.state.apn) {
            return true
        }
        if (this.state.apn && this.state.apn === this.props.camera_control.fetch_apn.details.apn) {
            return true
        }
        return false
    }

    onClick = () => {
        const apn = this.state.apn
        this.props.cameraControlActions.updateApn(this.props.cameras_id, apn).then(() => {
            this.props.cameraControlActions.fetchApn(this.props.cameras_id)
        })
    }

    isReady = () => {
        return this.props.camera_control.fetch_apn.details
            && this.props.camera_control.fetch_apn.details.apn
    }

    handleDismiss = () => {
        this.setState({ visible: false })

        setTimeout(() => {
            this.setState({ visible: true })
        }, 2000)
    }

    getError = () => {
        if (!this.props.camera_control.update_apn.error) {
            return null
        }
        let message = getErrorMessage(this.props.camera_control.update_apn.errors, 'cameras_id')
        if (!message) {
            message = getErrorMessage(this.props.camera_control.update_apn.errors, 'unknownErrorType')
        }
        return (message) ? message : "There was an unexpected error"
    }

    isMessageRelatedToApn = () => {
        return (this.props.camera_control.update_apn.error || this.props.camera_control.update_apn.success)
    }

    getMessage = (blurb) => {
        return (this.isMessageRelatedToApn()) ?
            <Message positive={this.props.camera_control.update_apn.success} negative={this.props.camera_control.update_apn.error}

                     onDismiss={this.onDismissMessage}

            >
                <Message.Header>{(this.props.camera_control.update_apn.error) ? 'Error' : 'Success'}</Message.Header>
                <p>{blurb}</p>
            </Message> : null
    }

    getBlurb = () => {
        if (this.props.camera_control.update_apn.error) {
            return this.getError()
        }
        if (this.props.camera_control.update_apn.success) {
            return 'Successfully updated APN setting'
        }
        return null
    }

    onDismissMessage = () => {
        this.props.cameraControlActions.resetUpdateApn()
    }

    render = () => {

        if (!this.isReady()) {

            return (
                <div className='apn'>
                    {/*<Dimmer active inverted><Loader content='' /></Dimmer>*/}
                    <Grid columns='1'>
                        <Grid.Row>
                            <Grid.Column>
                                <Grid.Column><div className="animated-blank-line" /></Grid.Column>
                                <Grid.Column><div className="animated-blank-line" /></Grid.Column>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            )

        } else {

            const apnInput =
                    <Input
                        fluid
                        // loading={this.props.camera_control.update_apn.loading}
                        defaultValue={this.props.camera_control.fetch_apn.details.apn}
                        onChange={this.onChange}
                    />

            return (
                <div className='apn'>

                    {this.getMessage(this.getBlurb())}

                    <Grid columns='1'>
                        <Grid.Row>
                            <Grid.Column>

                                {apnInput}

                                <Button
                                    fluid
                                    loading={this.isProcessing()}
                                    disabled={this.isButtonDisabled()}
                                    onClick={this.onClick}>Update APN</Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            )

        }
    }
}

Apn.propTypes = {
    cameraControlActions: PropTypes.object.isRequired,
    cameras_id: PropTypes.string.isRequired,
    camera_control: PropTypes.object.isRequired
}

export default Apn
